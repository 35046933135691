import React, { useState, useEffect, useRef, createRef } from "react";
import { Field, FormikProps } from "formik";
import { useMediaQuery, Hidden } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReCAPTCHA from "react-google-recaptcha";

import { breakpoints } from "style/constants";
import { Button, StyledGrid, TextField } from "components/simple";
import { StyledForgotPasswordLink } from "./styled";
import { LoginFormValues } from "models/user";

type FormProps = FormikProps<LoginFormValues>;

const Form: React.FC<FormProps> = ({
  values: { email, password, captchaToken },
  handleBlur,
  handleChange,
  handleSubmit,
  errors,
  isValid,
  isSubmitting,
  setFieldValue
}: FormProps) => {
  const captchaRef: any = createRef<ReCAPTCHA>();
  const siteKey: any = process.env.REACT_APP_RECAPTCHA_KEY;
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const savedEmail = localStorage.getItem("email");
  const [rememberMe, setRememberMe] = useState(savedEmail ? true : false);

  useEffect(() => {
    if (savedEmail) setFieldValue("email", savedEmail);
  }, []); // eslint-disable-line

  return (
    <div>
      <form
        onSubmit={async (e: any) => {
          if (rememberMe) {
            localStorage.setItem("email", email);
          } else {
            localStorage.removeItem("email");
          }
          captchaRef.current?.reset();
          handleSubmit(e);
        }}
      >
        <StyledGrid
          container
          direction="column"
          marginTop="30px"
          maxWidth={mobile ? "100%" : "422px"}
        >
          <input type="hidden" name="captchaToken" value={captchaToken} />
          <StyledGrid container direction="column" item spacing={1}>
            <StyledGrid item>
              <TextField
                id="email-input"
                error={!!errors.email}
                fullWidth
                helperText={errors.email}
                label="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={email}
              />
            </StyledGrid>
            <StyledGrid item>
              <TextField
                id="password-input"
                fullWidth
                label="Password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                type="password"
                value={password}
              />
            </StyledGrid>
            <Hidden mdDown>
              <StyledGrid item textAlign="right">
                <StyledForgotPasswordLink to="/forgot_password">
                  Forgot Password?
                </StyledForgotPasswordLink>
              </StyledGrid>
            </Hidden>
          </StyledGrid>
          <StyledGrid
            container
            direction="column"
            item
            mobilePadding={"20px 0 0 0"}
          >
            <StyledGrid
              alignSelf={mobile ? "center" : "left"}
              item
              xs
              marginBottom={"1rem"}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="rememberMe"
                    checked={rememberMe}
                    onChange={() => {
                      setRememberMe(!rememberMe);
                    }}
                  />
                }
                label="Remember Me"
              />
            </StyledGrid>
            <StyledGrid
              container
              direction="column"
              item
              mobilePadding={"20px 0 0 0"}
            >
              <ReCAPTCHA
                //  key={process.env.RECAPTCHA_KEY}
                sitekey={siteKey}
                size="normal"
                ref={captchaRef}
                onChange={e => {
                  setFieldValue("captchaToken", e);
                }}
              />
            </StyledGrid>
            <StyledGrid item padding={"20px 0 0 0"}>
              <Button
                disabled={!isValid || isSubmitting}
                fullWidth={mobile}
                type="submit"
              >
                Sign In
              </Button>
            </StyledGrid>
            <Hidden lgUp>
              <StyledGrid item textAlign="center">
                <StyledForgotPasswordLink to="/forgot_password">
                  Forgot Password?
                </StyledForgotPasswordLink>
              </StyledGrid>
            </Hidden>
          </StyledGrid>
        </StyledGrid>
      </form>
    </div>
  );
};

export default Form;
