import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";

import { ReactComponent as TreeFooter } from "assets/small-group-trees.svg";
import { FactorsBanner, Loading, StyledGrid } from "components/simple";
import { GetFactors } from "graphql/index/index.gql";
import BrainHealthIndex from "./BrainHealthIndex";
import Header from "./Header";
import { FactorInfo, GetFactorsData, NewCycle, PillarName } from "./types";
import { breakpoints } from "style/constants";
import BhiProductTour from "../BhiProductTour";
import { UserData } from "models/user";
import { UserLastLogin } from "graphql/user/user.gql";
import { BHIChartType } from "components/simple/BHIChart";
import { FindProductTourResponse } from "hooks/useProductTour";
import { FindProductTour } from "graphql/productTours/productTours.gql";
import { isAfter } from "date-fns";
import { getUserAssessmentNotes } from "graphql/userAssessments/userAssessments.gql";

export const getNewPillarScore = (
  cycle: NewCycle,
  pillarName: string
): number => {
  const pillarScoreValue =
    cycle.bhiRefactorPillarScores.find(
      pillar => pillar.pillarName === pillarName
    )?.value || 0;

  return Math.round(pillarScoreValue);
};

interface CycleSummaryProps {
  participantAssessmentCycles: NewCycle[];
  selectedCycleIndex: number;
  setSelectedCycleIndex: React.Dispatch<React.SetStateAction<number>>;
}

const CycleSummary: React.FC<CycleSummaryProps> = ({
  participantAssessmentCycles,
  selectedCycleIndex,

  setSelectedCycleIndex
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const cycles = participantAssessmentCycles.sort(
    (a: NewCycle, b: NewCycle) => a.cycleNumber - b.cycleNumber
  );

  const [selectedFactor, setSelectedFactor] = useState<
    PillarName | undefined
  >();
  const [factorInfo, setFactorInfo] = useState<FactorInfo[]>([]);
  const [connectedness, setConnectednessScore] = useState<number>(0);
  const [clarity, setClarityScore] = useState<number>(0);
  const [emotionalBalance, setEmotionalBalanceScore] = useState<number>(0);
  const [newIndex, setNewIndex] = useState<number>(0);
  const [indexDelta, setIndexDelta] = useState<number>();
  const [scoresLoaded, setScoresLoaded] = useState<boolean>(false);

  const [userLastLogin, setUserLastLogin] = useState<Date | undefined | null>(
    undefined
  );
  const [existingBhiUser, setExistingBhiUser] = useState<boolean | undefined>(
    undefined
  );
  const [bhiTourName, setBhiTourName] = useState<string>("");
  const [runIndexTourExistingUser, setRunIndexTourExistingUser] = useState<
    boolean
  >(false);
  const [runIndexTourNewUser, setRunIndexTourNewUser] = useState<boolean>(
    false
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [chartType, setChartType] = useState<BHIChartType>(BHIChartType.Tree);
  const [bhiIndexTourFinished, setBhiIndexTourFinished] = useState(false);
  const [notes, setNotes] = useState<string>("");
  const [date, setDate] = useState<string>("");

  const { loading: factorInfoLoading } = useQuery<GetFactorsData>(GetFactors, {
    onCompleted: data => setFactorInfo(data.factors)
  });

  const { loading: notesLoading } = useQuery(getUserAssessmentNotes, {
    variables: {
      input: {
        userAssessmentCycleId:
          participantAssessmentCycles[selectedCycleIndex].id
      }
    },
    onCompleted(data) {
      if (data) {
        setNotes(data.fetchUserAssessmentNotes.notes);
        setDate(data.fetchUserAssessmentNotes.dateAdded);
      }
    }
  });

  const { loading: userLoginLoading } = useQuery<UserData>(UserLastLogin, {
    onCompleted: data => {
      setUserLastLogin(data.me.lastLogin || null);
    }
  });

  const [getIndexTour] = useLazyQuery<FindProductTourResponse>(
    FindProductTour,
    {
      fetchPolicy: "cache-and-network",
      variables: { input: { name: bhiTourName } },
      onCompleted(data) {
        if (data) {
          const completed = !!data.findProductTour?.id;
          setBhiIndexTourFinished(completed);

          if (!completed) {
            if (existingBhiUser) {
              setRunIndexTourExistingUser(true);
            } else {
              setRunIndexTourNewUser(true);
            }
          }
        }
      }
    }
  );

  const [getAssessmentNotes] = useLazyQuery(getUserAssessmentNotes, {
    variables: {
      input: {
        userAssessmentCycleId:
          participantAssessmentCycles[selectedCycleIndex].id
      }
    },
    onCompleted(data) {
      if (data) {
        setNotes(data.fetchUserAssessmentNotes.notes);
        setDate(data.fetchUserAssessmentNotes.dateAdded);
      }
    }
  });

  useEffect(() => {
    if (
      participantAssessmentCycles.length >= 0 &&
      selectedCycleIndex > -1 &&
      userLastLogin != undefined
    ) {
      const mostRecentScoreFinalizedDate =
        participantAssessmentCycles[participantAssessmentCycles.length - 1]
          .statusDates.score_finalized;

      if (mostRecentScoreFinalizedDate) {
        const existingBhi =
          participantAssessmentCycles?.length > 1 ||
          (participantAssessmentCycles.length === 1 &&
            isAfter(
              new Date(userLastLogin),
              new Date(mostRecentScoreFinalizedDate)
            ));
        setExistingBhiUser(existingBhi);

        let indexProductTour = existingBhi
          ? "BrainHealth Index Existing User"
          : "BrainHealth Index New User";

        if (mobile) {
          indexProductTour += " Mobile";
        }
        setBhiTourName(indexProductTour);
        getIndexTour();
        getAssessmentNotes();
      }
    }
  }, [userLastLogin]);

  useEffect(() => {
    if (selectedCycleIndex > -1) {
      const selectedCycle = cycles[selectedCycleIndex];
      setConnectednessScore(
        getNewPillarScore(selectedCycle, PillarName.CONNECTEDNESS)
      );

      setClarityScore(getNewPillarScore(selectedCycle, PillarName.CLARITY));
      setEmotionalBalanceScore(
        getNewPillarScore(selectedCycle, PillarName.EMOTIONAL_BALANCE)
      );

      const newIndexScore = getNewPillarScore(
        selectedCycle,
        PillarName.NEW_INDEX
      );
      setNewIndex(Math.round(newIndexScore));

      const oldIndexScore =
        selectedCycleIndex != 0
          ? getNewPillarScore(
              cycles[selectedCycleIndex - 1],
              PillarName.NEW_INDEX
            )
          : undefined;
      setIndexDelta(
        oldIndexScore
          ? parseFloat((newIndexScore - oldIndexScore).toFixed(1))
          : undefined
      );

      setScoresLoaded(true);
      getAssessmentNotes();
    }
  }, [selectedCycleIndex]);

  function resetSelectedFactors() {
    setSelectedFactor(undefined);
  }

  function toggleSelectedFactor(pillarName: PillarName) {
    setSelectedFactor(selectedFactor === pillarName ? undefined : pillarName);
    setStepIndex(stepIndex + 1);
  }

  if (factorInfoLoading || !scoresLoaded || userLoginLoading) {
    return <Loading />;
  }

  return (
    <>
      {bhiTourName.length > 0 && (
        <>
          <BhiProductTour
            bhiTourName={bhiTourName}
            runIndexTourExistingUser={runIndexTourExistingUser}
            runIndexTourNewUser={runIndexTourNewUser}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            resetSelectedFactors={resetSelectedFactors}
            setBhiChartType={setChartType}
            toggleSelectedFactor={toggleSelectedFactor}
          />
        </>
      )}
      <StyledGrid container direction="column">
        <Header
          selectedCycleIndex={selectedCycleIndex}
          selectedCycleDate={cycles[selectedCycleIndex].cycleCompletionDate}
          setSelectedCycleIndex={setSelectedCycleIndex}
          isPreviousDisabled={selectedCycleIndex === 0}
          isNextDisabled={
            selectedCycleIndex === participantAssessmentCycles.length - 1
          }
        />
        <StyledGrid item marginTop={mobile ? "4rem" : "5.5rem"} xs={12}>
          <FactorsBanner
            clarity={String(clarity)}
            emotionalBalance={String(emotionalBalance)}
            index={String(newIndex)}
            indexDelta={indexDelta}
            connectedness={String(connectedness)}
            selectedFactor={selectedFactor}
            factors={factorInfo}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            resetSelectedFactors={resetSelectedFactors}
            setBhiChartType={setChartType}
            toggleSelectedFactor={toggleSelectedFactor}
          />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <BrainHealthIndex
            currentCycleIndex={selectedCycleIndex}
            cycles={participantAssessmentCycles}
            selectedFactor={selectedFactor}
            factorInfo={factorInfo}
            stepIndex={stepIndex}
            setStepIndex={setStepIndex}
            chartType={chartType}
            setChartType={setChartType}
            notes={notes}
            notesDate={date}
          />
        </StyledGrid>
        <StyledGrid alignSelf="center" item marginTop="1rem" xs={12}>
          <TreeFooter />
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export default CycleSummary;
