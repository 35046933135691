import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";

import {
  addBackgroundColorToRoot,
  breakpoints,
  color,
  fontFamily,
  fontSize,
  removeBackgroundColorFromRoot,
  spacingDefaults
} from "style/constants";
import { BackButton, Button, Loading, StyledGrid } from "components/simple";
import TreeHeader from "components/simple/TreeHeader";
import RecommendedHabits from "./RecommendedHabits";
import HabitCard from "../HabitCard";
import { AllUserHabitsData, Habit, UserHabit } from "models/userHabits";
import {
  GetAllUserHabits,
  TogglePinnedHabit
} from "graphql/userHabits/userHabits.gql";
import { toLowerDashCase } from "components/pages/Contentful/utils";
import PinnedHabitCard from "../PinnedHabits";
import PinningHabitModal from "../PinningHabitModal";
import { ReactComponent as PinIcon } from "assets/pin-icon.svg";
import { ReactComponent as UnPinIcon } from "assets/unpin-icon.svg";
import { useDispatch } from "react-redux";
import { showErrorNotification } from "state";

const TrackYourHabits: React.FC = () => {
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const dispatch = useDispatch();

  const [recommendedHabits, setRecommendedHabits] = useState<UserHabit[]>([]);
  const [pinnedHabits, setPinnedHabits] = useState<UserHabit[]>([]);
  const [unlockedHabits, setUnlockedHabits] = useState<UserHabit[]>([]);
  const [lockedHabits, setLockedHabits] = useState<Habit[]>([]);
  const [showMoreUnlocked, setShowMoreUnlocked] = useState<boolean>(false);
  const [showMoreLocked, setShowMoreLocked] = useState<boolean>(false);
  const [showHabitSurvey, setShowHabitSurvey] = useState<boolean>(false);
  const [togglePinHabitModal, setTottlePinHabitModal] = useState(false);
  const [selectedHabitToPin, setSelectedHabitToPin] = useState<UserHabit>();

  const [togglePinnedHabit] = useMutation(TogglePinnedHabit);

  const { loading, refetch } = useQuery<AllUserHabitsData>(GetAllUserHabits, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setRecommendedHabits(data?.allUserHabitsV2?.userHabitsBasedOnTraining);
      setUnlockedHabits(data?.allUserHabitsV2?.allUnlockedUserHabits);
      setLockedHabits(data?.allUserHabitsV2?.lockedHabits);
      setShowHabitSurvey(data?.allUserHabitsV2?.showHabitSurvey);
      setPinnedHabits(data?.allUserHabitsV2?.pinnedHabits);
    }
  });

  addBackgroundColorToRoot(color.WHITE);
  useEffect(() => {
    return () => {
      removeBackgroundColorFromRoot();
    };
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <BackButton />
      <TreeHeader topHeader={"Habits"} />
      <StyledGrid container centerContent>
        <div
          style={{
            maxWidth: "600px",
            width: "100%",
            padding: `0 ${spacingDefaults.normal}`
          }}
        >
          <Box fontFamily={fontFamily.secondary} fontSize={fontSize.header}>
            Brain Healthy Habits
          </Box>
          <Box
            fontSize={fontSize.normal}
            color={color.GRAPHITE}
            fontWeight="300"
            margin={`${spacingDefaults.normal} 0`}
          >
            Habits help you apply your training to everyday life.
          </Box>

          {pinnedHabits.length > 0 && (
            <>
              <Box
                fontSize={fontSize.medium}
                textAlign={mobile ? "left" : "center"}
              >
                Pinned Habits
              </Box>
              <StyledGrid container justifyContent="space-between">
                {pinnedHabits.map((userHabit: UserHabit, index: number) => {
                  if (userHabit?.habit)
                    return (
                      <PinnedHabitCard
                        key={"pinned" + index}
                        habit={userHabit.habit}
                        userHabit={userHabit}
                        listSize={pinnedHabits.length}
                        mobile={mobile}
                        setSelectedHabit={(habit: UserHabit) => {
                          setTottlePinHabitModal(true);
                          setSelectedHabitToPin(habit);
                        }}
                        onClick={() =>
                          history.push(
                            `/track-your-habits/${toLowerDashCase(
                              userHabit.habit?.name
                            )}`,
                            {
                              habit: userHabit,
                              showHabitSurvey,
                              pinnedHabits: pinnedHabits.length
                            }
                          )
                        }
                      />
                    );
                })}
              </StyledGrid>
            </>
          )}

          {recommendedHabits.length > 0 && (
            <>
              <Box fontSize={fontSize.medium}>Recommended Habits</Box>
              <RecommendedHabits
                recommendedHabits={recommendedHabits}
                showHabitSurvey={showHabitSurvey}
              />
            </>
          )}

          {unlockedHabits.length > 0 && (
            <>
              <Box
                fontSize={fontSize.medium}
                marginTop={spacingDefaults.xxlarge}
                marginBottom={spacingDefaults.large}
              >
                Unlocked Habits
              </Box>
              {unlockedHabits
                .slice(0, showMoreUnlocked ? unlockedHabits.length : 5)
                .map((item: UserHabit, idx: number) => {
                  if (item?.habit)
                    return (
                      <HabitCard
                        key={idx}
                        habit={item.habit}
                        userHabit={item}
                        onClick={() =>
                          history.push(
                            `/track-your-habits/${toLowerDashCase(
                              item.habit?.name
                            )}`,
                            {
                              habit: item,
                              showHabitSurvey,
                              pinnedHabits: pinnedHabits.length
                            }
                          )
                        }
                      />
                    );
                })}
              {unlockedHabits.length > 5 && (
                <Button onClick={() => setShowMoreUnlocked(!showMoreUnlocked)}>
                  {showMoreUnlocked ? "Show Less" : "Show More"}
                </Button>
              )}
            </>
          )}

          {lockedHabits.length > 0 && (
            <>
              <Box
                fontSize={fontSize.medium}
                marginTop={spacingDefaults.xxlarge}
              >
                Locked Habits
              </Box>
              <Box
                fontSize={fontSize.medium}
                fontWeight="300"
                color={color.GRAPHITE}
              >
                Complete{" "}
                <Link to="/training" style={{ color: color.DARKBLUE }}>
                  training courses
                </Link>{" "}
                to <br /> unlock more habits
              </Box>
              {lockedHabits
                .slice(0, showMoreLocked ? lockedHabits.length : 5)
                .map((item: Habit, idx: number) => (
                  <HabitCard key={idx} habit={item} disabled />
                ))}
              {lockedHabits.length > 5 && (
                <Button onClick={() => setShowMoreLocked(!showMoreLocked)}>
                  {showMoreLocked ? "Show Less" : "Show More"}
                </Button>
              )}
            </>
          )}
        </div>
      </StyledGrid>
      {togglePinHabitModal && (
        <PinningHabitModal
          icon={selectedHabitToPin ? <UnPinIcon /> : <PinIcon />}
          title={`Are you sure you want to ${
            selectedHabitToPin ? "unpin" : "pin"
          } this habit?`}
          content={
            selectedHabitToPin
              ? "This will remove this habit from the top of your habits list."
              : "This habit will appear at the top of your habits list."
          }
          confirmText={`${selectedHabitToPin ? "Unpin" : "Pin"} Habit`}
          cancelText="Cancel"
          onConfirm={async () => {
            if (selectedHabitToPin) {
              try {
                await togglePinnedHabit({
                  variables: { input: { userHabitId: selectedHabitToPin.id } }
                });
                const response = await refetch();
                if (response.data) {
                  setRecommendedHabits(
                    response.data.allUserHabitsV2?.userHabitsBasedOnTraining
                  );
                  setUnlockedHabits(
                    response.data.allUserHabitsV2?.allUnlockedUserHabits
                  );
                  setLockedHabits(response.data.allUserHabitsV2?.lockedHabits);
                  setShowHabitSurvey(
                    response.data.allUserHabitsV2?.showHabitSurvey
                  );
                  setPinnedHabits(response.data.allUserHabitsV2?.pinnedHabits);
                }
              } catch (e) {
                dispatch(showErrorNotification("Error", e));
              }
            }
            setTottlePinHabitModal(false);
          }}
          onCancel={() => setTottlePinHabitModal(false)}
        />
      )}
    </>
  );
};
export default TrackYourHabits;
