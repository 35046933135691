import React, { ReactNode } from "react";
import { useMediaQuery } from "@material-ui/core";

import {
  appWidth,
  breakpoints,
  color,
  iconSizes,
  spacingDefaults
} from "style/constants";
import {
  Button,
  QuestionPrompt,
  StyledGrid,
  Subtext,
  MoreContentBanner
} from "components/simple";
import { ReactComponent as Chevron } from "assets/chevron-right.svg";

interface Props {
  additionalInfo?: string | string[];
  children: ReactNode;
  question?: string | JSX.Element;
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
  subtext?: string;
  timer?: ReactNode;
  skipText?: string;
}

interface SkipButtonProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  callback: React.Dispatch<any>;
  skipText?: string;
}

const SkipButton: React.FC<SkipButtonProps> = ({
  callback,
  skipText
}: SkipButtonProps) => (
  <StyledGrid
    container
    item
    justifyContent="center"
    alignItems="center"
    spacing={1}
    marginTop={spacingDefaults.normal}
  >
    <Button compressed plain onClick={callback} zIndex={50}>
      {!!skipText ? skipText : "Skip, this does not apply to me"}
    </Button>
    <StyledGrid item>
      <Chevron
        strokeWidth="2px"
        stroke={color.BLACK}
        height={iconSizes.xsmall}
        width={iconSizes.xsmall}
      />
    </StyledGrid>
  </StyledGrid>
);

const AssessmentCard: React.FC<Props> = ({
  additionalInfo,
  children,
  question,
  skipCallback,
  subtext,
  timer,
  skipText
}: Props) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const isGroupPage = window.location.pathname === "/assessments/group";

  return (
    <StyledGrid
      alignItems="center"
      alignSelf="center"
      backgroundColor={color.WHITE}
      border={mobile ? "none" : `1px solid ${color.CARDGRAY}`}
      borderRadius="8px"
      container
      direction="column"
      fillRemainingHeight={mobile}
      marginTop="2.5rem"
      maxWidth={appWidth.max}
      minHeight="700px"
      mobileMarginTop="0"
      mobilePadding="2rem 1rem 4rem 1rem"
      padding="2rem"
      textAlign="center"
      wrap="nowrap"
    >
      {timer && (
        <StyledGrid
          item
          marginBottom={spacingDefaults.normal}
          mobileMarginTop="0"
        >
          {timer}
        </StyledGrid>
      )}

      {question && <QuestionPrompt question={question} />}
      {subtext && <Subtext subtext={subtext} additionalInfo={additionalInfo} />}
      {children}
      {skipCallback && (
        <SkipButton callback={skipCallback} skipText={skipText} />
      )}
      <MoreContentBanner mobileBottom={isGroupPage ? "0px" : undefined} />
    </StyledGrid>
  );
};

export default AssessmentCard;
