import { IconButton, Modal, useMediaQuery } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

import { StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { CourseProgress } from "../types";
import CourseStatus from "./CourseStatus";

interface CoursesModalProps {
  show: boolean;
  onClose(): void;
  courses: CourseProgress[];
}

const CoursesModal: React.FC<CoursesModalProps> = ({
  show,
  onClose,
  courses
}: CoursesModalProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <Modal onClose={onClose} open={show}>
      <StyledGrid
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        direction="column"
        left="50%"
        position="absolute"
        top="50%"
        transform="translateX(-50%) translateY(-50%)"
        width={mobile ? "360px" : "800px"}
      >
        <StyledGrid
          item
          position="absolute"
          right={spacingDefaults.xsmall}
          top={spacingDefaults.xsmall}
        >
          <IconButton aria-label="close" key="close" onClick={onClose}>
            <Close />
          </IconButton>
        </StyledGrid>

        <StyledGrid
          item
          marginBottom={spacingDefaults.normal}
          marginTop={spacingDefaults.xlarge}
        >
          <StyledP
            color={color.BLACK}
            fontFamily={fontFamily.secondary}
            fontSize={mobile ? fontSize.large : "30px"}
            textAlign="center"
          >
            Your Current Training Courses
          </StyledP>
        </StyledGrid>

        <StyledGrid
          item
          maxHeight={mobile ? "600px" : "750px"}
          overflow="scroll"
          padding={mobile ? spacingDefaults.small : spacingDefaults.xlarge}
        >
          {courses.map((course, index) => (
            <StyledGrid
              key={index}
              marginTop={index === 0 ? undefined : spacingDefaults.normal}
            >
              <CourseStatus
                name={course.name}
                category={course.categoryName}
                pillars={course.pillars}
                locked={course.locked || false}
                completionPercentage={course.completionPercentage}
              />
            </StyledGrid>
          ))}
        </StyledGrid>
      </StyledGrid>
    </Modal>
  );
};

export default CoursesModal;
