import React, { useState, Dispatch, SetStateAction, useRef } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Box } from "@material-ui/core";
import {
  StyledImage,
  StyledTabContent,
  StyledVideo,
  TabWrapper
} from "./styled";
import TabPanel from "components/pages/Dashboard/CycleSummary/BrainHealthIndex/TabPanel";
import { Media, MediaType, TabsData } from "graphql/types/Contentful";
import RichText from "components/pages/Contentful/Unit/RichText";
import StyledGrid from "../StyledGrid";
// New Training component with tabs and content
// Tabs may contain section data or image with section data or video with section data.
// This component is currently set for demo purpose only which can be extended to fully functional component.
interface TabProps {
  data: TabsData[];
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  setHasVideoEnded: Dispatch<SetStateAction<boolean>>;
}

const TabComponent: React.FC<TabProps> = ({
  data,
  setHasVideo,
  setHasVideoEnded
}) => {
  const [value, setValue] = useState(0);
  const videoEl = useRef<HTMLVideoElement>(null);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  let supposedCurrentTime = 0;

  //Here we are rendering a tab's content for the Text type of data.
  const renderTextTab = (item: any) => {
    return (
      <StyledTabContent>
        {<RichText data={item.data} textAlign={item.textAlignment} />}
      </StyledTabContent>
    );
  };
  //Here we are rendering a tab's content for the Media type of data, both Images and Videos.
  const renderMediaTab = (item: any) => {
    return (
      <>
        {item.media?.map((media: Media, idx: number) => (
          <StyledGrid key={idx} container centerContent={true}>
            {media.mediaType === MediaType.videoMp4 ? (
              <>
                {setHasVideo(true)}
                <StyledVideo
                  key={media?.fileUrl}
                  controls
                  onEnded={() => setHasVideoEnded(true)}
                  onTimeUpdate={() => {
                    if (!videoEl?.current?.seeking) {
                      supposedCurrentTime = videoEl?.current?.currentTime || 0;
                    }
                  }}
                  onSeeking={(e: any) => {
                    if (videoEl?.current?.currentTime) {
                      const delta =
                        videoEl?.current?.currentTime - supposedCurrentTime;
                      if (delta > 0.01) {
                        videoEl.current.currentTime = supposedCurrentTime;
                      }
                    }
                  }}
                >
                  <source src={media?.fileUrl} type="video/mp4" />
                </StyledVideo>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    width: "inherit",
                    maxWidth: "80%",
                    margin: "20px auto",
                    height: "auto"
                  }}
                >
                  <RichText data={item.data} textAlign={item.textAlignment} />
                </Box>
              </>
            ) : (
              <>
                <StyledImage src={media?.fileUrl} alt={media?.title} />
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    width: "inherit",
                    maxWidth: "80%",
                    margin: "20px auto",
                    height: "auto"
                  }}
                >
                  <RichText data={item.data} textAlign={item.textAlignment} />
                </Box>
              </>
            )}
          </StyledGrid>
        ))}
      </>
    );
  };

  //The data field would be null for when there is no text attached to the incoming content.
  //However, the media tab would be an empty array if there is no media attached.

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        maxWidth: "80%",
        border: "1px solid #DFDFDF",
        margin: "20px auto",
        height: "auto",
        width: "fit-content"
      }}
    >
      <TabWrapper>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple-tabs"
          indicatorColor="secondary"
          textColor="secondary"
        >
          {data &&
            data.map((item: any, index: number) => {
              return <Tab label={item.tabText} key={index} />;
            })}
        </Tabs>
        <div
          className="tab-content"
          style={{ overflow: "scroll", height: "40vh", padding: "10px 50px" }}
        >
          {data &&
            data.map((item: any, index: number) => {
              return (
                <TabPanel value={value} index={index} key={index}>
                  {item.media.length == 0
                    ? renderTextTab(item)
                    : renderMediaTab(item)}
                </TabPanel>
              );
            })}
        </div>
      </TabWrapper>
    </Box>
  );
};

export default TabComponent;
