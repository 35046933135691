import React, { useState } from "react";
import SpouseModalView from "./ModalView";
import {
  inviteSpouseWelcomeOneModalData,
  inviteSpouseWelcomeTwoModalData,
  inviteSpouseData,
  inviteSpouseSuccessData,
  unlinkPartnerData
} from "./ModalData";
import { useHistory } from "react-router";
import { SendSpouseReferralEmail, UnlinkPartner } from "graphql/user/user.gql";
import { useMutation } from "@apollo/react-hooks";

interface SpouseModalsProps {
  isWelcomeModalOneOpen?: boolean;
  isWelcomeModalTwoOpen?: boolean;
  setIsWelcomeModalOneOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsWelcomeModalTwoOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isDesignateSpouseModalOpen?: boolean;
  setIsDesignateSpouseModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isInviteSpouseModalOpen?: boolean;
  setIsInviteSpouseModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isUnlinkPartnerModalOpen?: boolean;
  setIsUnlinkPartnerModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  partnerName?: string;
  userId?: string;
  isNewUser: boolean;
  profilePage?: boolean;
  refetchMe?: Function;
}

function Index({
  isWelcomeModalOneOpen,
  isWelcomeModalTwoOpen,
  setIsWelcomeModalOneOpen,
  setIsWelcomeModalTwoOpen,
  isDesignateSpouseModalOpen,
  isInviteSpouseModalOpen,
  setIsDesignateSpouseModalOpen,
  setIsInviteSpouseModalOpen,
  isUnlinkPartnerModalOpen,
  setIsUnlinkPartnerModalOpen,
  partnerName,
  userId,
  isNewUser,
  profilePage = false,
  refetchMe
}: SpouseModalsProps) {
  const [
    isDesignateSuccessModalOpen,
    setIsDesignateSuccessModalOpen
  ] = useState(false);

  const [isInviteSuccessModalOpen, setIsInviteSuccessModalOpen] = useState(
    false
  );
  const [sendSpouseReferralemail] = useMutation(SendSpouseReferralEmail);
  const [unlinkPartner] = useMutation(UnlinkPartner);

  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [designateEmail, setDesignateEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const history = useHistory();
  function checkEmailErrors(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  async function onSubmitDesignateSpouse() {
    const isCorrect: boolean = checkEmailErrors(designateEmail);

    if (!!setIsDesignateSpouseModalOpen && isCorrect) {
      try {
        await sendSpouseReferralemail({
          variables: { input: { spouseEmail: designateEmail, userId } }
        });
      } catch (error) {
        console.error("Error when sending spouse email.");
      }
      setIsDesignateSpouseModalOpen(false);
      setIsDesignateSuccessModalOpen(true);
    } else {
      setEmailError(true);
    }
  }
  async function onSubmitInviteSpouse() {
    const isCorrect: boolean = checkEmailErrors(inviteEmail);

    if (!!setIsInviteSpouseModalOpen && isCorrect) {
      try {
        await sendSpouseReferralemail({
          variables: { input: { spouseEmail: inviteEmail, userId } }
        });
      } catch (error) {
        console.error("Error when sending spouse email.");
      }
      setIsInviteSpouseModalOpen(false);
      setIsInviteSuccessModalOpen(true);
    } else {
      setEmailError(true);
    }
  }

  async function submitUnlinkPartner() {
    try {
      await unlinkPartner();
      refetchMe && refetchMe();
    } catch (error) {
      console.error("Error when unlinking partner.");
    }
    closeModal(setIsUnlinkPartnerModalOpen);
  }

  function closeModal(closeFunc: Function | undefined) {
    closeFunc && closeFunc(false);
  }

  function openModal(closeFunc: Function | undefined) {
    closeFunc && closeFunc(true);
  }
  return (
    <>
      {isWelcomeModalOneOpen && (
        <SpouseModalView
          data={inviteSpouseWelcomeOneModalData}
          onSkip={() => closeModal(setIsWelcomeModalOneOpen)}
          isNewUser={isNewUser}
          forwardArrow={() => {
            closeModal(setIsWelcomeModalOneOpen);
            openModal(setIsWelcomeModalTwoOpen);
          }}
          onCancel={() => {
            closeModal(setIsWelcomeModalOneOpen);
          }}
        />
      )}
      {isWelcomeModalTwoOpen && (
        <SpouseModalView
          data={inviteSpouseWelcomeTwoModalData}
          onSubmit={() => {
            closeModal(setIsWelcomeModalTwoOpen);
            openModal(setIsInviteSpouseModalOpen);
          }}
          onSkip={() => closeModal(setIsWelcomeModalTwoOpen)}
          isNewUser={isNewUser}
          onCancel={() => {
            closeModal(setIsWelcomeModalTwoOpen);
          }}
        />
      )}
      {/* {isDesignateSpouseModalOpen && (
        <SpouseModalView
          data={designateSpouseData}
          email={designateEmail}
          setEmail={setDesignateEmail}
          onSubmit={onSubmitDesignateSpouse}
          onSkip={() => {
            routeTo(setIsDesignateSpouseModalOpen);
          }}
          isNewUser={isNewUser}
          onCancel={() => {
            routeTo(setIsDesignateSpouseModalOpen);
          }}
        />
      )} */}
      {/* {isDesignateSuccessModalOpen && (
        <SpouseModalView
          data={designateSpouseSuccessData}
          onSubmit={routeTo}
          isNewUser={isNewUser}
          onCancel={() => {
            routeTo(setIsDesignateSuccessModalOpen);
          }}
        />
      )} */}
      {isInviteSpouseModalOpen && (
        <SpouseModalView
          data={inviteSpouseData}
          email={inviteEmail}
          setEmail={setInviteEmail}
          onSubmit={onSubmitInviteSpouse}
          onSkip={() => {
            closeModal(setIsInviteSpouseModalOpen);
          }}
          profilePage={profilePage}
          emailError={emailError}
          isNewUser={isNewUser}
          onCancel={() => {
            closeModal(setIsInviteSpouseModalOpen);
          }}
        />
      )}
      {isInviteSuccessModalOpen && (
        <SpouseModalView
          data={inviteSpouseSuccessData}
          onSubmit={() => {
            closeModal(setIsInviteSuccessModalOpen);
          }}
          noButton={profilePage}
          isNewUser={isNewUser}
          onCancel={() => {
            closeModal(setIsInviteSuccessModalOpen);
          }}
        />
      )}
      {isUnlinkPartnerModalOpen && (
        <SpouseModalView
          data={unlinkPartnerData}
          onSubmit={submitUnlinkPartner}
          cancelButton
          onCancel={() => {
            closeModal(setIsUnlinkPartnerModalOpen);
          }}
          partnerName={partnerName}
        />
      )}
    </>
  );
}

export default Index;
