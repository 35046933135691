import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { StyledGrid, WidgetTitle } from "components/simple";
import { breakpoints, spacingDefaults } from "style/constants";

import { CoachingSession, ImagingSession, SessionType } from "../types";
import { IGroupSession } from "../GroupCoachingSession";
import UpcomingSessionCard, { UpcomingSession } from "./UpcomingSessionCard";

interface UpcomingSessionsProps {
  groupSessionList: IGroupSession[];
  imagingSessionList: ImagingSession[];
  coachingSessionList: CoachingSession[];
}

const UpcomingSessions: React.FC<UpcomingSessionsProps> = ({
  groupSessionList,
  imagingSessionList,
  coachingSessionList
}: UpcomingSessionsProps) => {
  const allUpcomingSessions: UpcomingSession[] = [];

  groupSessionList?.map(gs => {
    const upcoming: UpcomingSession = {
      id: gs.id,
      sessionTime: gs.sessionTime,
      status: gs.status,
      type: SessionType.GROUP
    };
    allUpcomingSessions.push(upcoming);
  });

  imagingSessionList?.map(is => {
    const upcoming: UpcomingSession = {
      id: is.id,
      sessionTime: is.sessionTime,
      status: is.status,
      type: SessionType.IMAGING,
      acuityConfirmationNumber: is.acuityConfirmationNumber
    };
    allUpcomingSessions.push(upcoming);
  });

  coachingSessionList?.map(cs => {
    const upcoming: UpcomingSession = {
      id: cs.id,
      sessionTime: cs.sessionTime,
      status: cs.status,
      type: SessionType.COACHING,
      acuityConfirmationNumber: cs.acuityConfirmationNumber
    };
    allUpcomingSessions.push(upcoming);
  });

  allUpcomingSessions.sort(
    (a, b) =>
      new Date(a.sessionTime).getTime() - new Date(b.sessionTime).getTime()
  );

  return (
    <>
      <StyledGrid item xs={12} margin={`${spacingDefaults.normal} 0`}>
        <WidgetTitle id="upcoming-sessions">Upcoming Sessions</WidgetTitle>
      </StyledGrid>
      <UpcomingSessionCard upcomingSessions={allUpcomingSessions} />
    </>
  );
};

export default UpcomingSessions;
