import { Hidden, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as NoAccessImage } from "assets/no-access.svg";
import { Button, StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";

interface InactiveBannerProps {
  showButton?: boolean;
}

const InactiveBanner: React.FC<InactiveBannerProps> = ({
  showButton = false
}: InactiveBannerProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.WHITE}
      borderRadius="8px"
      container
      height="96px"
      mobilePadding="0"
      onClick={
        mobile && showButton
          ? () => window.open("mailto:support@thebrainhealthproject.org")
          : undefined
      }
      overflow={mobile ? "hidden" : undefined}
      padding="20px"
    >
      <Hidden mdDown>
        <StyledGrid item position="relative" width="275px">
          <StyledGrid position="absolute" transform="translateY(-50%)">
            <NoAccessImage />
          </StyledGrid>
        </StyledGrid>

        <StyledGrid item xs>
          <StyledGrid marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize="26px"
              letterSpacing="-0.5px"
            >
              Your account has been <strong>frozen</strong>
            </StyledP>
          </StyledGrid>
          <StyledP>
            {"Please contact "}
            <a
              href="mailto:support@thebrainhealthproject.org"
              style={{ color: color.DARKERGRAY }}
            >
              support@thebrainhealthproject.org
            </a>
            {" to re-activate."}
          </StyledP>
        </StyledGrid>

        {showButton && (
          <StyledGrid item>
            <Link
              to="#"
              onClick={() =>
                window.open("mailto:support@thebrainhealthproject.org")
              }
            >
              <Button longText width="175px">
                Send Email
              </Button>
            </Link>
          </StyledGrid>
        )}
      </Hidden>

      <Hidden lgUp>
        <StyledGrid item position="relative" width="115px">
          <StyledGrid
            left="-50px"
            position="absolute"
            transform="translateY(-50%)"
          >
            <NoAccessImage width={135} />
          </StyledGrid>
        </StyledGrid>

        <StyledGrid item xs>
          <StyledGrid>
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize="20px"
              lineHeight="23px"
              width="200px"
            >
              Your account has been <strong>frozen</strong>
            </StyledP>
          </StyledGrid>

          {showButton && (
            <StyledGrid marginTop={spacingDefaults.xsmall}>
              <StyledP color={color.BLACK} fontSize="16px">
                <strong>Send Email &gt;</strong>
              </StyledP>
            </StyledGrid>
          )}
        </StyledGrid>
      </Hidden>
    </StyledGrid>
  );
};

export default InactiveBanner;
