import styled from "styled-components";
import { breakpoints, color, fontFamily } from "style/constants";

export const StyledCard = styled.div`
  align-items: center;
  border: 1px solid #a4a4a4;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin: 5px;
  max-width: calc(100vw - 1rem);
  padding: 0.75rem 0.9rem;
  position: relative;
  svg {
    cursor: pointer;
    position: absolute;
    right: 1rem;
  }
  width: fit-content;
`;

export const StyledCards = styled.div`
  @media (max-width: ${breakpoints.lg}px) {
    align-items: center;
    flex-direction: column;
    height: auto;
    margin-bottom: 70px;
  }
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 322px;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
`;

export const StyledH1 = styled.h1`
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 1.875rem;
  font-weight: normal;
  line-height: 2.125rem;
  margin: 0 0 22px;
  text-align: center;
`;

export const StyledH2 = styled.h2`
  @media (max-width: ${breakpoints.lg}px) {
    margin: 0 0 30px;
  }
  color: ${color.DARKERGRAY};
  font-family: ${fontFamily.primary};
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.1875rem;
  text-align: center;
`;

export const StyledH3 = styled.h3`
  color: ${color.BLACK};
  font-family: ${fontFamily.primary};
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.375rem;
  margin: 0 2rem 0 0;
  overflow: scroll;
`;
