import React from "react";
import { Grid, Hidden } from "@material-ui/core";

import { ReactComponent as Workout } from "assets/workout.svg";
import { StyledText } from "../../../styled";
import { BackButton } from "components/simple";

const Begin: React.FC = () => {
  return (
    <>
      <Hidden lgUp>
        <BackButton />
      </Hidden>
      <Grid item>
        <Workout />
      </Grid>
      <Grid item>
        <Hidden lgUp>
          <StyledText>
            Before you begin make sure you are <b>well rested</b> with a full
            night&apos;s sleep.
          </StyledText>
        </Hidden>
        <Hidden mdDown>
          <StyledText>
            Make sure you are <b>well rested</b> with a full night&apos;s sleep.
          </StyledText>
        </Hidden>
      </Grid>
    </>
  );
};

export default Begin;
