import React from "react";
import { Box } from "@material-ui/core";
import UpArrow from "@material-ui/icons/KeyboardArrowUp";

import DashboardHeader from "assets/dashboard-header.svg";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";
import { useHistory } from "react-router";

const Header: React.FC = () => {
  const history = useHistory();
  return (
    <StyledGrid
      alignItems="center"
      backgroundImage={DashboardHeader}
      container
      direction="column"
      justifyContent="space-between"
      minHeight="200px"
      textAlign="center"
    >
      <StyledGrid item marginTop={spacingDefaults.normal}>
        <StyledGrid
          alignItems="center"
          backgroundColor={color.WHITE}
          borderRadius="50%"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
          centerContent
          className="my-journey-button"
          container
          height="30px"
          margin={`${spacingDefaults.xsmall} auto`}
          onClick={() => {
            // KV TODO: temporary until smooth scroll implemented
            history.push("/myjourney");
          }}
          style={{ cursor: "pointer" }}
          width="30px"
        >
          <UpArrow />
        </StyledGrid>
        <Box fontSize={fontSize.normal} fontFamily={fontFamily.primary}>
          My Journey
        </Box>
      </StyledGrid>
      <StyledGrid item>
        <Box fontFamily={fontFamily.primary} fontWeight="bold" fontSize="18px">
          {`Today's BrainHealthy Plan`}
        </Box>
        <Box fontSize="18px" fontFamily={fontFamily.primary}>
          {`Let's Grow!`}
        </Box>
      </StyledGrid>
    </StyledGrid>
  );
};
export default Header;
