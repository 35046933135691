import React from "react";

import SuccessCoin from "assets/successCoin.svg";
import SuccessHabit from "assets/successHabit.svg";
import SuccessTraining from "assets/successTraining.svg";
import SucessResource from "assets/successResource.svg";

interface OrnamentSymbolProps {
  ornament: string;
}

enum OrnamentTypes {
  nut = "nut",
  flower = "flower",
  fruit = "fruit"
}
export const TaskRewardImage = ({ ornament }: OrnamentSymbolProps) => {
  const height = "300px";
  switch (ornament) {
    case OrnamentTypes.fruit:
      return <img src={SuccessHabit} height={height} />;
    case OrnamentTypes.nut:
      return <img src={SucessResource} height={height} />;
    case OrnamentTypes.flower:
      return <img src={SuccessTraining} height={height} />;
    default:
      return <img src={SuccessCoin} height={height} />;
  }
};
