import styled from "styled-components";
import { breakpoints, color } from "style/constants";

export const StyledImage = styled.img`
  height: 80px;
  margin-left: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
    margin-left: 0;
  }
`;

export const StyledImageDoD = styled.img`
  height: 80px;
  margin-left: 38px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: nowrap;
  @media (max-width: ${breakpoints.lg}px) {
    height: 55px;
    flex-wrap: nowrap;
    margin-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

export const StyledImagePDFSmall = styled.img`
  height: 40px;
`;

export const StyledImagePDFLarge = styled.img`
  width: 400px;
`;

export const StyledImageCoachingCall = styled.img`
  height: 36px;
  width: 36px;
`;

export const StyledImageWhite = styled.img`
  height: 100px;
  width: 230px;
  margin-left: 30px;
  margin-bottom: 30px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
    margin-left: 0;
  }
`;

export const StyledImageDark = styled.img`
  height: 90px;
  width: 350px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
    margin-left: 0;
  }
`;
