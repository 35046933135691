import React from "react";
import { Link, RouteProps } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import get from "lodash/get";
import track from "react-tracking";

import { color, breakpoints } from "style/constants";
import { Subtitle, Paragraph, CompleteImage } from "./styled";
import { Button, Confetti, StyledGrid } from "components/simple";
import routesConfig from "utils/routesConfig";

interface PartOutroProps {
  partNumber: number;
  groupName: string;
  lastAssessment: boolean;
  hasIncompleteRequiredSurveys: boolean;
}

const url: string = routesConfig.assessments.partComplete.path;

const PartOutro: React.FC<RouteProps> = (props: RouteProps) => {
  // The third paramter in get() is the default value if it returns undefined, so the any is a little misleading
  const location: any = get(props, "location", {
    state: {
      props: {
        assessmentNames: [],
        partNumber: 0,
        lastAssessment: false,
        hasIncompleteRequiredSurveys: false
      }
    }
  });
  const {
    groupName,
    partNumber,
    lastAssessment,
    hasIncompleteRequiredSurveys
  }: PartOutroProps = location.state.props;
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      centerContent
      container
      direction="column"
      fillRemainingHeight
      itemMargin={mobile ? "1rem" : "2rem"}
      marginTop="4rem"
      backgroundColor={color.WHITE}
    >
      <Confetti />
      <StyledGrid item>
        <CompleteImage />
      </StyledGrid>
      <StyledGrid item>
        {lastAssessment && hasIncompleteRequiredSurveys ? (
          <Subtitle>Assessment Complete!</Subtitle>
        ) : (
          <Subtitle>Part {partNumber} Complete!</Subtitle>
        )}
      </StyledGrid>
      <StyledGrid item marginLeft="auto" marginRight="auto">
        {lastAssessment && hasIncompleteRequiredSurveys ? (
          <Paragraph>
            You have completed all assessments. Please take at least a
            five-minute break to recharge your brain before continuing to the
            next required survey.
          </Paragraph>
        ) : (
          <Paragraph>
            You have completed the {groupName} assessments. Please take at least
            a five-minute break to recharge your brain before continuing to the
            next assessment.
          </Paragraph>
        )}
      </StyledGrid>
      <StyledGrid container item itemMargin="0.75rem">
        <StyledGrid item width="284px">
          <Link to="/dashboard">
            <Button fullWidth>Return to Dashboard</Button>
          </Link>
        </StyledGrid>
        <StyledGrid item width="284px">
          {lastAssessment && hasIncompleteRequiredSurveys ? (
            <Link to="/assessment-overview">
              <Button fullWidth inverted>
                Start next Survey
              </Button>
            </Link>
          ) : (
            <Link to="/assessments/group">
              <Button fullWidth inverted>
                Start next Assessment
              </Button>
            </Link>
          )}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default track({
  url
})(PartOutro);
