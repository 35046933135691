import React, { useState, useRef } from "react";
import Fade from "@material-ui/core/Fade";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useOnScreen, useDelay } from "hooks";
import { StyledDiv } from "./styled";

interface Props {
  message?: string;
  bottom?: string;
  mobileBottom?: string;
  seconds?: number;
}

const MoreContentBanner: React.FC<Props> = ({
  message,
  bottom,
  mobileBottom,
  seconds = 2000
}: Props) => {
  const ref = useRef(null);
  const endOfScreen = useOnScreen(ref);
  const [showBanner, setShowBanner] = useState(false);
  useDelay(seconds, () => setShowBanner(true));

  if (showBanner && endOfScreen) setShowBanner(false);

  return (
    <>
      <Fade in={showBanner && !endOfScreen}>
        <StyledDiv bottom={bottom} mobileBottom={mobileBottom}>
          <ExpandMoreIcon />
          {message || "Scroll for more content"}
        </StyledDiv>
      </Fade>
      <div ref={ref}></div>
    </>
  );
};

export default MoreContentBanner;
