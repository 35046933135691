import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import track from "react-tracking";

import { getUser } from "state";
import routesConfig from "utils/routesConfig";
import Intro from "./Intro/index";
import Disqualify from "./Qualification/Disqualify";
import Qualify from "./Qualification/Qualify";

const url: string = routesConfig.screener.path;

const Screener: React.FC = () => {
  const user = useSelector(getUser);

  if (user?.eligibleForStudy !== null) {
    return <Redirect push to="/dashboard" />;
  }

  const SCREENER_BASE = "/screener";

  return (
    <>
      <Route component={Intro} exact path={`${SCREENER_BASE}`} />
      <Route component={Qualify} path={`${SCREENER_BASE}/qualify`} />
      <Route component={Disqualify} path={`${SCREENER_BASE}/disqualify`} />
    </>
  );
};

export default track({
  url
})(Screener);
