import { useSelector } from "react-redux";

import { getUserStatus, UserStatus } from "state";

const useUserStatus = (): UserStatus => {
  const userStatus = useSelector(getUserStatus);
  return userStatus;
};

export default useUserStatus;
