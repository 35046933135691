import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from "@material-ui/core";
import qs from "qs";

import { ReactComponent as PathRight } from "assets/course-path-right.svg";
import { ReactComponent as PathNewRow } from "assets/course-path-new-row.svg";
import { Loading, StyledGrid } from "components/simple";
import {
  GetBrainHqDietIdTraining,
  GetContentfulCourses,
  GetTeaserContentfulCourses
} from "graphql/training/training.gql";
import {
  ContentfulCourse,
  ContentfulSection,
  ContenfulTraining,
  ContenfulTeaserTraining,
  CourseStatus
} from "graphql/types/Contentful";
import CourseCard from "./CourseCard";
import CoursesHeader from "./CoursesHeader";
import { Course, CourseContainer } from "./styled";
import { color, spacingDefaults } from "style/constants";
//import BrainHqLink from "components/pages/Training/BrainHqLink";
import DietIDButton from "components/pages/Training/DietIdLink";
import { useUserStatus } from "hooks";
import { UserStatus } from "state";
import { useDispatch } from "react-redux";

import { setNativeExperience } from "state";

interface TrainingProgressData {
  trainingProgress: ContenfulTraining;
  teaserTrainingProgress: ContenfulTeaserTraining;
}

const Courses: React.FC = (props: any) => {
  const { location } = props;
  const queryParams = qs.parse(location?.search || "", {
    ignoreQueryPrefix: true
  });
  const isNative = "native" in queryParams;
  const dispatch = useDispatch();
  if (isNative) {
    dispatch(setNativeExperience());
  }

  const [eventFromNative, setEventFromNative] = useState("initial value");
  useEffect(() => {
    window.addEventListener("customevent", customEventHandler);

    return () => window.removeEventListener("customevent", customEventHandler);
  }, []);

  const customEventHandler = useCallback(
    e => {
      setEventFromNative(e.detail.data);
    },
    [setEventFromNative]
  );

  const singleColumn = useMediaQuery(`(max-width:1397px)`);

  const userStatus = useUserStatus();
  const shouldDisable = userStatus !== UserStatus.QUALIFIED_AND_CONSENTED;

  const [training, setTraining] = useState<ContenfulTraining>();
  const [teaserTraining, setTeaserTraining] = useState<
    ContenfulTeaserTraining
  >();
  const [courses, setCourses] = useState<ContentfulCourse[]>([]);
  const [teaserCourses, setTeaserCourses] = useState<ContentfulCourse[]>([]);
  const [contentfulError, setContentfulError] = useState<boolean>(false);
  const [hasBhqAccess, setHasBhqAccess] = useState<boolean>(false);
  const [dietidAccessCode, setDietidAccessCode] = useState<string>();

  const { loading: trainingLoading } = useQuery<TrainingProgressData>(
    GetContentfulCourses,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: data => {
        setTraining(data.trainingProgress);
        setCourses(data.trainingProgress.courses);
      },
      onError: () => setContentfulError(true)
    }
  );

  const { loading: teaserTrainingLoading } = useQuery<TrainingProgressData>(
    GetTeaserContentfulCourses,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: data => {
        setTeaserTraining(data?.teaserTrainingProgress);
        setTeaserCourses(data?.teaserTrainingProgress?.teaserCourses);
      },
      onError: () => setContentfulError(true)
    }
  );

  const { loading: accessCodesLoading } = useQuery(GetBrainHqDietIdTraining, {
    onCompleted: data => {
      setHasBhqAccess(
        data.training.bhqUserId !== null && data.training.isBrainHqActive
      );
      setDietidAccessCode(data.training.dietidAccessCode);
    }
  });

  const isTrainingComplete =
    courses.length > 0 && courses.length === training?.completedCourseCount;

  const isTeaserTrainingComplete =
    teaserCourses.length > 0 &&
    teaserCourses.length === teaserTraining?.completedTeaserCourseCount;

  if (trainingLoading || teaserTrainingLoading || accessCodesLoading)
    return <Loading />;

  const currentSection = training?.currentCourse?.sections?.find(
    (section: ContentfulSection) =>
      section.active && section.status !== CourseStatus.completed
  );

  const currentTeaserSection = teaserTraining?.currentTeaserCourse?.sections?.find(
    (section: ContentfulSection) =>
      section.active && section.status !== CourseStatus.completed
  );

  const FillerCourse: React.FC = () => {
    const style = {
      width: "342px",
      height: "0"
    };
    return <li style={style} />;
  };

  const onClickHandler = () => {
    (window as any)?.webkit?.messageHandlers?.SOME_BRIDGE?.postMessage({
      message: "Hello! I'm React."
    });
  };

  return (
    <>
      <CoursesHeader
        coursesCompleted={
          teaserTraining?.completedTeaserCourseCount &&
          teaserTraining?.completedTeaserCourseCount < 1
            ? teaserTraining?.completedTeaserCourseCount
            : training?.completedCourseCount || 0
        }
        currentSection={
          currentTeaserSection ? currentTeaserSection : currentSection
        }
        currentCourse={
          teaserTraining?.currentTeaserCourse
            ? teaserTraining?.currentTeaserCourse
            : training?.currentCourse
        }
        isTrainingComplete={
          teaserTraining?.completedTeaserCourseCount &&
          teaserTraining?.completedTeaserCourseCount < 1
            ? isTeaserTrainingComplete
            : isTrainingComplete
        }
      />
      {(hasBhqAccess || !!dietidAccessCode) && (
        <StyledGrid
          container
          justifyContent="center"
          marginTop={spacingDefaults.xxlarge}
        >
          <StyledGrid
            container
            item
            direction="column"
            maxWidth="1250px"
            padding={`0 ${spacingDefaults.normal}`}
          >
            {/* {hasBhqAccess && (
              <StyledGrid item>
                <BrainHqLink disabled={shouldDisable} />
              </StyledGrid>
            )} */}
            {!!dietidAccessCode && (
              <StyledGrid item>
                <DietIDButton
                  disabled={shouldDisable}
                  accessCode={dietidAccessCode}
                />
              </StyledGrid>
            )}
          </StyledGrid>
        </StyledGrid>
      )}
      <CourseContainer>
        {teaserCourses.length > 0 && courses.length < 1
          ? teaserCourses
              .sort((a, b) => a.order - b.order)
              .map((item: ContentfulCourse, idx: number) => {
                const nextItem: ContentfulCourse | null =
                  teaserCourses?.[idx + 1];
                const fill = nextItem?.active ? color.GREEN : color.PALEGRAY;
                return (
                  <Course key={idx}>
                    <CourseCard course={item} />
                    <PathRight fill={fill} />
                    {(idx + 1) % 3 == 0 && <PathNewRow fill={fill} />}
                  </Course>
                );
              })
          : courses
              .sort((a, b) => a.order - b.order)
              .map((item: ContentfulCourse, idx: number) => {
                const nextItem: ContentfulCourse | null = courses?.[idx + 1];
                const fill = nextItem?.active ? color.GREEN : color.PALEGRAY;
                return (
                  <Course key={idx}>
                    <CourseCard course={item} />
                    <PathRight fill={fill} />
                    {(idx + 1) % 3 == 0 && <PathNewRow fill={fill} />}
                  </Course>
                );
              })}
        {!singleColumn &&
          courses.length > 3 &&
          Array.from(Array(3), (_, idx) => <FillerCourse key={idx} />)}
      </CourseContainer>
    </>
  );
};

export default Courses;
