import { gql } from "apollo-boost";

export const articleFragment = gql`
  fragment article on Article {
    id
    createdAt
    bhComponents
    coverImage {
      description
      url
    }
    hasVideo
    publicationDate
    readingTime
    slug
    source
    summary
    target
    title
    url
  }
`;

export const GetResources = gql`
  query GetResources {
    userResources {
      waitListed
      featured {
        ...article
      }
      recommended {
        ...article
      }
      articles {
        ...article
      }
    }
  }
  ${articleFragment}
`;

export const GetArticleContent = gql`
  query getArticleContent($input: ArticleInput!) {
    getArticleContent(input: $input) {
      content
      ...article
    }
  }
  ${articleFragment}
`;

export const MarkResourceAsViewed = gql`
  mutation markResourceAsViewed($input: UserResourcesInput!) {
    markResourceAsViewed(input: $input) {
      success
      coinsAwarded {
        title
        coinsAwarded
        ornament
      }
    }
  }
`;

export const GetResourceSurvey = gql`
  query ResourceSurvey {
    resourceSurvey {
      id
      title
      questions {
        id
        question
        instructions
        possibleAnswers {
          id
          answer
          iconImage
        }
      }
    }
  }
`;

export const userResourcesInsightsByParticipant = gql`
  query userResourcesInsightsByParticipant {
    userResourcesInsightsByParticipant {
      id
      name
      factors
      mappedFactors
      createdAt
      contentfulResourceId
    }
  }
`;

export const SaveResourceSurveyAnswers = gql`
  mutation saveResourceSurveyAnswers($input: SaveResourceSurveyAnswersInput!) {
    saveResourceSurveyAnswers(input: $input) {
      success
    }
  }
`;
