import React, { memo } from "react";

import Notifier from "components/simple/Notifier";

interface PushNotificationProps {
  body: string;
  createdAt: string;
  dismissed: boolean;
  title: string;
  onClick: () => void;
}

const PushNotification: React.FC<PushNotificationProps> = memo(
  ({ body, createdAt, dismissed, title, onClick }: PushNotificationProps) => {
    return (
      <Notifier
        description={body}
        forDrawer
        hideNotification={() => {}}
        open
        sentAt={createdAt}
        status={dismissed ? "dismissed" : "info"}
        title={title}
        onClick={onClick}
      />
    );
  }
);

export default PushNotification;
