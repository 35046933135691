import React from "react";
import { FormikProps } from "formik";

import { Button, StyledGrid, TextField } from "components/simple";

interface ForgotPasswordForm {
  email: string;
}

type RequestFormProps = FormikProps<ForgotPasswordForm>;

export const RequestForm: React.FC<RequestFormProps> = ({
  values: { email },
  handleBlur,
  handleChange,
  handleSubmit,
  errors,
  isValid,
  isSubmitting
}: RequestFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <StyledGrid container direction="column" spacing={3}>
        <StyledGrid item>
          <TextField
            inputProps={{
              "data-testid": "email-input"
            }}
            error={!!errors.email}
            fullWidth
            helperText={errors.email}
            label="Email"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={email}
          />
        </StyledGrid>
        <StyledGrid item md={5} xs={12}>
          <Button
            data-testid="submit-button"
            disabled={!isValid || isSubmitting}
            fullWidth
            longText
            type="submit"
          >
            Reset
          </Button>
        </StyledGrid>
      </StyledGrid>
    </form>
  );
};

interface ResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

type ResetFormProps = FormikProps<ResetPasswordForm>;

export const ResetForm: React.FC<ResetFormProps> = ({
  values: { newPassword, confirmPassword },
  handleBlur,
  handleChange,
  handleSubmit,
  errors,
  isValid,
  isSubmitting
}: ResetFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <StyledGrid container direction="column" spacing={3}>
        <StyledGrid container direction="column" item spacing={1}>
          <StyledGrid item>
            <TextField
              inputProps={{
                "data-testid": "new-password"
              }}
              error={!!errors.newPassword}
              fullWidth
              helperText={errors.newPassword}
              label="New Password"
              name="newPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="password"
              value={newPassword}
            />
          </StyledGrid>
          <StyledGrid item>
            <TextField
              inputProps={{
                "data-testid": "confirm-password"
              }}
              error={!!errors.confirmPassword}
              fullWidth
              helperText={errors.confirmPassword}
              label="Confirm New Password"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              type="password"
              value={confirmPassword}
            />
          </StyledGrid>
        </StyledGrid>
        <StyledGrid item md={5} xs={12}>
          <Button
            data-testid="submit-button"
            disabled={!isValid || isSubmitting}
            fullWidth
            longText
            type="submit"
          >
            Set Password
          </Button>
        </StyledGrid>
      </StyledGrid>
    </form>
  );
};
