import { createReducer } from "typesafe-actions";

import * as actions from "./actions";
import { Action, NotificationType, State } from "./types";

const initialState: State = {
  forNativeExperience: false,
  loader: {
    show: false
  },
  notifier: {
    show: false,
    type: NotificationType.SUCCESS,
    title: "Success!",
    description: ""
  },
  user: null,
  subdomain: null
};

const reducer = createReducer<State, Action>(initialState)
  .handleAction(actions.showLoader, state => ({
    ...state,
    loader: {
      show: true
    }
  }))
  .handleAction(actions.hideLoader, state => ({
    ...state,
    loader: {
      show: false
    }
  }))
  .handleAction(actions.showInfoNotification, (state, action) => ({
    ...state,
    notifier: {
      show: true,
      type: NotificationType.INFO,
      ...action.payload
    }
  }))
  .handleAction(actions.showSuccessNotification, (state, action) => ({
    ...state,
    notifier: {
      show: true,
      type: NotificationType.SUCCESS,
      ...action.payload
    }
  }))
  .handleAction(actions.showErrorNotification, (state, action) => ({
    ...state,
    notifier: {
      show: true,
      type: NotificationType.ERROR,
      ...action.payload
    }
  }))
  .handleAction(actions.hideNotification, state => ({
    ...state,
    notifier: {
      ...state.notifier,
      show: false
    }
  }))
  .handleAction(actions.setUser, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      ...action.payload
    }
  }))
  .handleAction(actions.setSubdomain, (state, action) => ({
    ...state,
    subdomain: action.payload
  }))
  .handleAction(actions.setNativeExperience, state => ({
    ...state,
    forNativeExperience: true
  }));

export default reducer;
