import React from "react";
import { CheckCircle, LockOutlined } from "@material-ui/icons";
import { Box } from "@material-ui/core";

import { color, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";
import { CourseStatus } from "graphql/types/Contentful";

export interface ItemStatusProps {
  status: CourseStatus | undefined;
  active: boolean | undefined;
}

const ItemStatus: React.FC<ItemStatusProps> = ({
  status,
  active
}: ItemStatusProps) => {
  const isCompleted = status === CourseStatus.completed;
  const isInProgress = status === CourseStatus.inProgress;
  const isDisabled = !active;
  const progressStatus = isCompleted
    ? "Completed"
    : isInProgress
    ? "In Progress"
    : "Not Started";

  const statusColor = isCompleted ? color.GREEN : color.BLACK;

  return (
    <>
      {!isDisabled ? (
        <StyledGrid container>
          <StyledGrid item marginRight={spacingDefaults.xsmall}>
            <Box color={statusColor} fontStyle="italic">
              {progressStatus}
            </Box>
          </StyledGrid>
          {isCompleted && (
            <StyledGrid item>
              <CheckCircle fontSize="small" style={{ color: color.GREEN }} />
            </StyledGrid>
          )}
        </StyledGrid>
      ) : (
        <StyledGrid container>
          <StyledGrid item>
            <LockOutlined
              fontSize="small"
              style={{ color: color.DARKERGRAY }}
            />
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default ItemStatus;
