import { color } from "style/constants";

export default {
  root: {
    paddingLeft: "13px",
    "&&$error": {
      color: color.DARKRED
    }
  }
};
