import React, { useRef, useState } from "react";

import { StyledIconButton } from "lib/twilio-video/components/Buttons/styled";
import SettingsIcon from "../../../icons/SettingsIcon";
import DeviceSelectionDialog from "../../DeviceSelectionDialog/DeviceSelectionDialog";

export default function SettingsButton() {
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <StyledIconButton
        ref={anchorRef}
        onClick={() => setDeviceSettingsOpen(true)}
      >
        <SettingsIcon />
      </StyledIconButton>

      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
    </>
  );
}
