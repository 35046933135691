import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { Button, StyledGrid } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary
} from "./styled";
import BrainGaugeMetrics from "./BrainGaugeMetrics";
import { BrainGaugeData } from "./index";

const BrainGaugeResults: React.FC<BrainGaugeData> = ({
  results,
  accessCode
}: BrainGaugeData) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <StyledGrid
      container
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      padding={spacingDefaults.large}
      xs={12}
    >
      <StyledAccordion>
        <StyledGrid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledGrid item width={mobile ? "100%" : "70%"}>
            <Box fontFamily={fontFamily.secondary} fontSize="22px">
              Your Brain Gauge Assessment Results
            </Box>
            <StyledGrid item marginTop={spacingDefaults.normal}>
              Click View Results to view how your Brain fitness scored on the
              most accurate sensory fitness gauge - the Brain Gauge! Your access
              code is <b>{accessCode}</b> for future assessments.
            </StyledGrid>
            <StyledGrid
              container
              marginTop={spacingDefaults.normal}
              alignItems="center"
            >
              <Link
                to="/braingauge"
                style={{
                  color: "black"
                }}
              >
                Learn More
              </Link>
              <ChevronRight />
            </StyledGrid>
          </StyledGrid>
          <StyledGrid item margin={spacingDefaults.normal}>
            <StyledAccordionSummary>
              <Button onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? "Hide Results" : "View Results"}
              </Button>
            </StyledAccordionSummary>
          </StyledGrid>
        </StyledGrid>
        <StyledAccordionDetails>
          <BrainGaugeMetrics results={results} />
        </StyledAccordionDetails>
      </StyledAccordion>
    </StyledGrid>
  );
};

export default BrainGaugeResults;
