import React from "react";
import ReactHtmlParser from "react-html-parser";

interface Props {
  html: string;
}

const allowedHtmlTags = ["sup", "sub", "small"];

const transform = (node: any) => {
  if (node.type === "tag" && !allowedHtmlTags.includes(node.name)) return null;
};

export const ParseHtml: React.FC<Props> = ({ html }: Props) => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open("GET", html, false); // false for synchronous request
  xmlHttp.send(null);
  return <>{ReactHtmlParser(xmlHttp.responseText)}</>;
};

export default ParseHtml;
