import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { ButtonProps } from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import Button from "components/simple/Button";
import StyledGrid from "components/simple/StyledGrid";
import { spacingDefaults } from "style/constants";

interface CustomProps {
  location?: string;
  state?: any;
}

type BackButtonProps = CustomProps & ButtonProps;

const BackButton: React.FC<BackButtonProps> = ({
  location = "",
  state = undefined
}: BackButtonProps) => {
  const appHistory = useHistory();

  const backButtonClicked = (): void => {
    if (location === "") {
      appHistory.goBack();
      return;
    }
    appHistory.push(location, { state });
    return;
  };

  return (
    <StyledGrid
      container
      item
      position="fixed"
      marginLeft={spacingDefaults.xlarge}
      mobileMarginLeft="0"
      top={"120px"}
      mobileTop={"90px"}
    >
      <Button onClick={backButtonClicked} plain>
        <ChevronLeft />
        <Hidden mdDown>
          <StyledGrid marginLeft={spacingDefaults.normal}>Back</StyledGrid>
        </Hidden>
      </Button>
    </StyledGrid>
  );
};

export default BackButton;
