import React from "react";

import { ReactComponent as MailBalloon } from "assets/confirmRegistration.svg";
import { StyledGrid } from "components/simple";
import { color } from "style/constants";
import { StyledHeader } from "./styled";

const ThankYou: React.FC = () => {
  return (
    <>
      <StyledGrid
        backgroundColor={color.WHITE}
        centerContent
        container
        direction="column"
        fillRemainingHeight
        itemMargin="3rem"
      >
        <StyledGrid item>
          <MailBalloon />
        </StyledGrid>
        <StyledGrid container item>
          <StyledHeader maxWidth="435px">
            Thank you for your feedback.
          </StyledHeader>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export default ThankYou;
