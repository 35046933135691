import React from "react";
import { Link } from "react-router-dom";

import { Body, Title } from "./styled";
import defaultImage from "assets/strategy.svg";
import { Button, MarkdownContent, StyledGrid } from "components/simple";
import { color } from "style/constants";

interface Props {
  props: Information;
}

interface Information {
  body?: string;
  buttonText: string;
  callback?: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  imageAlt?: string;
  imageSrc?: string;
  title?: string;
}

const Information: React.FC<Props> = ({
  props: { body, buttonText, callback, imageAlt, imageSrc, title }
}: Props) => {
  const fallbackAlt =
    "4 indiscernible speech bubbles float in front of a blue cloud";
  const image = {
    alt: imageAlt && imageSrc ? imageAlt : fallbackAlt,
    src: imageAlt && imageSrc ? imageSrc : defaultImage
  };
  const { src, alt } = image;
  return (
    <>
      <StyledGrid
        alignSelf="center"
        backgroundColor={color.WHITE}
        container
        maxWidth="574px"
        paddingLeft="2rem"
        paddingRight="2rem"
        textAlign="center"
      >
        <StyledGrid item marginTop="5.5rem" xs={12}>
          <img alt={alt} src={src} />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Title>{title}</Title>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Body>
            <MarkdownContent>{body}</MarkdownContent>
          </Body>
        </StyledGrid>
        <StyledGrid item xs={12}>
          {callback && (
            <Button
              onClick={() => {
                callback({});
              }}
            >
              {buttonText}
            </Button>
          )}
          {!callback && (
            <Link to="assessment">
              <Button>{buttonText}</Button>
            </Link>
          )}
        </StyledGrid>
      </StyledGrid>
      <StyledGrid
        backgroundColor={color.WHITE}
        height="100%"
        position="fixed"
        width="100%"
        zIndex="-1"
      />
    </>
  );
};

export default Information;
