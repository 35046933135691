import { useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { Avatar, StyledGrid } from "components/simple";
import http from "http/client";
import { fetchUser, getUser, showErrorNotification } from "state";
import { breakpoints, color } from "style/constants";

const ProfilePicture: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [showEditState, setShowEditState] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(getUser);

  return (
    <StyledGrid
      borderRadius="50%"
      height={mobile ? "125px" : "150px"}
      onMouseOver={() => setShowEditState(true)}
      onMouseLeave={() => setShowEditState(false)}
      width={mobile ? "125px" : "150px"}
    >
      <Dropzone onDrop={(images: File[]) => saveImage(images[0])}>
        {({ getRootProps, getInputProps }) => (
          <StyledGrid
            height={mobile ? "125px" : "150px"}
            position="absolute"
            width={mobile ? "125px" : "150px"}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            {!mobile && showEditState && (
              <StyledGrid
                alignItems="center"
                backgroundColor={color.BLACK}
                borderRadius="50%"
                container
                height="100%"
                justifyContent="center"
                opacity="0.5"
                width="100%"
              >
                <PlusIcon
                  height={mobile ? "2rem" : "2.5rem"}
                  stroke={color.WHITE}
                  width={mobile ? "2rem" : "2.5rem"}
                />
              </StyledGrid>
            )}
          </StyledGrid>
        )}
      </Dropzone>

      <Avatar
        profileImageKey={user?.profileImageKey ?? undefined}
        firstName={user?.firstName}
        lastName={user?.lastName}
        backgroundColor={color.LIGHTBLUE}
        height="100%"
        width="100%"
      />
    </StyledGrid>
  );

  async function saveImage(image: File): Promise<void> {
    const formData = new FormData();
    formData.append("picture", image);

    try {
      await http.post("/profilePicture", formData);

      dispatch(fetchUser());
    } catch (error) {
      dispatch(
        showErrorNotification(
          "Uh oh, something went wrong",
          error.response.data?.message
        )
      );
    }
  }
};

export default ProfilePicture;
