import React from "react";
import bhpLogoPlain from "assets/brainhealth-logo-brain.svg";
import bhpLogoPDF from "assets/brainhealth-project-logo-pdf.png";
import bhpLogoWhite from "assets/brainhealth-project-logo-white.svg";
import dodLogo from "assets/Asset-1Warfighter.svg";
import dodLogoWhite from "assets/Asset-1WarfighterWhite.svg";
import dodLogoPDF from "assets/dod-logo-pdf.png";
import bhpLogoDark from "assets/brainhealth-project-logo-dark.svg";

import {
  StyledImage,
  StyledImagePDFLarge,
  StyledImagePDFSmall,
  StyledImageCoachingCall,
  StyledImageWhite,
  StyledImageDark
} from "./styled";

import { getSubdomain } from "state";
import { useSelector } from "react-redux";

interface ILogoProps {
  height?: string;
  width?: string;
  styleType?: "pdfSmall" | "pdfLarge" | "coachingCall" | "white" | "dark";
}

export function Logo({ height, width, styleType }: ILogoProps) {
  const subdomain = useSelector(getSubdomain);

  const showDodLogo = subdomain?.name === "dod";
  const PDFSmall = showDodLogo ? dodLogoPDF : bhpLogoPDF;
  const PDFLarge = showDodLogo ? dodLogoPDF : bhpLogoPDF;
  const coachingLogo = showDodLogo ? dodLogo : bhpLogoPlain;
  const whiteLogo = showDodLogo ? dodLogoWhite : bhpLogoWhite;

  switch (styleType) {
    case "pdfSmall":
      return (
        <StyledImagePDFSmall
          src={PDFSmall}
          alt="The Brain Health Project Logo"
        />
      );
    case "pdfLarge":
      return (
        <StyledImagePDFLarge
          src={PDFLarge}
          alt="The Brain Health Project Logo"
        />
      );
    case "coachingCall":
      if (height && width) {
        return (
          <StyledImageCoachingCall
            src={coachingLogo}
            height={height}
            width={width}
          />
        );
      } else {
        return <StyledImageCoachingCall src={coachingLogo} />;
      }
    case "white":
      return (
        <StyledImageWhite src={whiteLogo} alt="The Brain Health Project Logo" />
      );
    case "dark":
      return (
        <StyledImageDark
          src={bhpLogoDark}
          alt="The Brain Health Project Logo"
        />
      );
    default:
      return showDodLogo ? (
        <StyledImage src={dodLogo} alt="The Brain Health Project Logo" />
      ) : (
        <StyledImage src={bhpLogoDark} alt="The Brain Health Project Logo" />
      );
  }
}

export default Logo;
