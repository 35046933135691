import React, { useEffect, useState } from "react";
import {
  AssessmentCard,
  RadioCard,
  StyledGrid,
  StyledP
} from "components/simple";
import { color, fontFamily, spacingDefaults } from "style/constants";
import { Divider, useMediaQuery } from "@material-ui/core";
import { DigitSymbolQuestionData, Platform } from "..";

interface DigitSymbolPracticeData {
  callback: (
    e: { target?: any; keyCode: any },
    question: DigitSymbolQuestionData
  ) => void;
  platform: Platform;
  question: DigitSymbolQuestionData;
  showAnswerStatusModal: boolean;
}

export const Practice: React.FC<DigitSymbolPracticeData> = ({
  callback,
  platform,
  question,
  showAnswerStatusModal
}) => {
  const reference = question.reference;
  const digitSymbol = question.digitSymbol;
  const absoluteReferenceDisplay = !useMediaQuery("(min-height:730px)");

  const [digitSymbolWidth, setDigitSymbolWidth] = useState(0);

  useEffect(() => {
    if (!showAnswerStatusModal && platform == Platform.Keyboard) {
      function handleKeyDown(e: { target: any; keyCode: any }) {
        callback(e, question);
      }

      document.addEventListener("keydown", handleKeyDown);

      return function cleanup() {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [showAnswerStatusModal, question]);

  useEffect(() => {
    setDigitSymbolWidth(
      document.querySelector(".digit-symbol")?.scrollWidth || 0
    );
  }, []);

  return (
    <AssessmentCard>
      <StyledGrid item marginTop="1rem" marginBottom="1rem" width="100%">
        <StyledGrid
          container
          justifyContent="center"
          marginBottom="0px"
          wrap={"nowrap"}
        >
          {reference.symbols.map((symbol, index) => (
            <img
              className="digit-symbol"
              key={index}
              src={encodeURI(`https://bhp-images.s3.amazonaws.com/${symbol}`)}
              style={{ maxWidth: "80px" }}
              width="100%"
            />
          ))}
        </StyledGrid>
        <StyledGrid
          container
          justifyContent="center"
          marginTop="0px"
          wrap={"nowrap"}
        >
          {reference.digits.map((digit, index) => (
            <img
              key={index}
              src={encodeURI(`https://bhp-images.s3.amazonaws.com/${digit}`)}
              style={{ maxWidth: "80px" }}
              width="100%"
            />
          ))}
        </StyledGrid>
        <StyledGrid
          marginTop={spacingDefaults.large}
          marginBottom={spacingDefaults.large}
          width="100%"
        >
          <Divider style={{ color: color.BLACK, height: "2px" }} />
        </StyledGrid>
        <StyledGrid
          alignContent="space-between"
          alignItems="center"
          container
          justifyContent="center"
          mobileMarginTop={spacingDefaults.xxsmall}
        >
          <StyledGrid container item justifyContent="space-between">
            <StyledGrid alignContent="center" container direction="column">
              <img
                src={encodeURI(
                  `https://bhp-images.s3.amazonaws.com/${digitSymbol.symbol}`
                )}
                width={`${digitSymbolWidth}px`}
              />

              <img
                src={encodeURI(
                  `https://bhp-images.s3.amazonaws.com/${digitSymbol.digit}`
                )}
                width={`${digitSymbolWidth}px`}
              />
            </StyledGrid>
          </StyledGrid>
          {platform == Platform.Keyboard && (
            <StyledGrid
              bottom={spacingDefaults.xlarge}
              container
              justifyContent="center"
              marginTop={spacingDefaults.xxlarge}
              position={absoluteReferenceDisplay ? "absolute" : "initial"}
            >
              <StyledGrid item>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="22px"
                  textAlign="center"
                >
                  Yes
                </StyledP>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="16px"
                  textAlign="center"
                >
                  (Press &quot;f&quot;)
                </StyledP>
              </StyledGrid>
              <StyledGrid item marginLeft={"10rem"}>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="22px"
                  textAlign="center"
                >
                  No
                </StyledP>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="16px"
                  textAlign="center"
                >
                  (Press &quot;j&quot;)
                </StyledP>
              </StyledGrid>
            </StyledGrid>
          )}
          {platform == Platform.Touchscreen && (
            <StyledGrid
              container
              justifyContent="center"
              width="100%"
              alignContent="center"
              margin="3rem 0"
            >
              <RadioCard
                touchscreen={true}
                selections={["Yes", "No"]}
                onChange={(_, value) => {
                  const e = { keyCode: 0 };
                  if (value == "Yes") {
                    e.keyCode = 70;
                  } else {
                    e.keyCode = 74;
                  }
                  callback(e, question);
                }}
              />
            </StyledGrid>
          )}
        </StyledGrid>
      </StyledGrid>
    </AssessmentCard>
  );
};
