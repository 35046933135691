import React, { useState, useMemo, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import ConfirmRegistration from "components/pages/ConfirmRegistration";
import {
  ForgotPasswordForm,
  PasswordReset
} from "components/pages/ForgotPassword";
import Login from "components/pages/Login";
import UpdateEmail from "components/pages/UpdateEmailAddress";
import Registration from "components/pages/Registration";
import Confirmation from "components/pages/Registration/Confirmation";
import { Footer, Loading, StyledGrid } from "components/simple";
import Notifier from "components/simple/Notifier";
import AppMenu from "components/simple/AppMenu";
import {
  getUserStatus,
  hideNotification,
  setSubdomain,
  State,
  Subdomains
} from "state";
import ProtectedRoutes from "utils/ProtectedRoutes";
import routesConfig from "utils/routesConfig";
import Feedback from "components/pages/Feedback";
import {
  addBackgroundColorToRoot,
  color,
  removeBackgroundColorFromRoot
} from "style/constants";
import {
  getOrgConfig,
  isFeatureEnabled,
  isRefactorEnabled as isRefactorGlobalEnabled
} from "graphql/featureFlags/featureFlags.gql";
import { checkAuth } from "./auth";

export const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = checkAuth();

  const isLoading = useSelector((state: State) => state.loader.show);
  const notification = useSelector((state: State) => state.notifier);
  const userStatus = useSelector(getUserStatus);
  const headerPathsToHide = [
    routesConfig.login.path,
    routesConfig.register.path
  ];
  const footerPathsToHide = [routesConfig.myjourney.path];

  const [is404, setIs404] = useState(false);
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(false);
  const [isFooterShown, setIsFooterShown] = useState<boolean>(false);
  const [isRefactorEnabled, setIsRefactorEnabled] = useState<
    boolean | undefined
  >(undefined);
  const [isResourceEnabled, setIsResourceEnabled] = useState<boolean>(false);
  const [isTrainingEnabled, setIsTrainingEnabled] = useState<boolean>(false);

  const { data } = useQuery(getOrgConfig, {
    onCompleted: d => {
      setIsResourceEnabled(d.getOrgConfig.isResourceEnabled);
      setIsTrainingEnabled(d.getOrgConfig.isTrainingEnabled);
    }
  });

  useMemo(() => {
    const subdomain = window.location.host.split(".")[0];
    dispatch(setSubdomain(subdomain === "mtec" ? Subdomains.DOD : null));
  }, [window]);

  useMemo(() => {
    if (
      headerPathsToHide.some(
        path => path === location.pathname || location.pathname.includes(path)
      )
    ) {
      setIsHeaderShown(false);
    } else {
      setIsHeaderShown(true);
    }
  }, [location.pathname]);

  useMemo(() => {
    if (
      footerPathsToHide.some(
        path => path === location.pathname || location.pathname.includes(path)
      )
    ) {
      setIsFooterShown(false);
    } else {
      setIsFooterShown(true);
    }
  }, [location.pathname]);

  const [refactorQuery] = useLazyQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Refactor" } },
    onCompleted: data => setIsRefactorEnabled(data.isFeatureEnabled.enabled)
  });

  const [refactorGlobalQuery] = useLazyQuery(isRefactorGlobalEnabled, {
    onCompleted: data => setIsRefactorEnabled(data.isRefactorEnabled.enabled)
  });

  useEffect(() => {
    if (isLoggedIn) {
      refactorQuery();
    } else {
      refactorGlobalQuery();
    }
  }, [location, isLoggedIn]);

  addBackgroundColorToRoot(color.WHITE);
  useEffect(() => {
    return () => {
      removeBackgroundColorFromRoot();
    };
  }, []);

  useEffect(() => {
    if (data && data.getOrgConfig) {
      setIsResourceEnabled(data.getOrgConfig.isResourcesEnabled);
      setIsTrainingEnabled(data.getOrgConfig.isTrainingEnabled);
    }
  }, [data]);

  return (
    <>
      {isLoading && isRefactorEnabled === undefined && <Loading />}
      <Notifier
        status={notification.type}
        title={notification.title}
        description={notification.description}
        open={notification.show}
        hideNotification={() => dispatch(hideNotification())}
      />
      <AppMenu
        isRefactorEnabled={!!isRefactorEnabled}
        isResourceEnabled={isResourceEnabled}
        isTrainingEnabled={isTrainingEnabled}
        isHeaderShown={isHeaderShown}
        showAll={is404}
        userStatus={userStatus}
      />
      <StyledGrid container direction="column" fillRemainingHeight>
        <Switch>
          <Route path={routesConfig.register.path} component={Registration} />
          <Route path={routesConfig.verify.path} component={Confirmation} />
          <Route
            path={routesConfig.confirm.path}
            component={ConfirmRegistration}
          />
          <Route path={routesConfig.login.path} component={Login} />
          <Route
            path={routesConfig.forgotPassword.path}
            component={ForgotPasswordForm}
          />
          <Route
            path={routesConfig.resetPassword.path}
            component={PasswordReset}
          />

          <Route path={routesConfig.updateEmail.path} component={UpdateEmail} />
          <Route path={routesConfig.feedback.path} component={Feedback} />
          <ProtectedRoutes setIs404={setIs404} />
        </Switch>
      </StyledGrid>
      {isHeaderShown && isFooterShown && <Footer />}
    </>
  );
};

export default Routes;
