import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { showErrorNotification, showSuccessNotification } from "state";
import {
  addDays,
  endOfDay,
  format,
  isSameDay,
  startOfDay,
  subDays
} from "date-fns";
import {
  useMediaQuery,
  Drawer as MUIDrawer,
  makeStyles,
  IconButton,
  Divider
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StyledGrid from "../StyledGrid";
import { breakpoints, color, spacingDefaults } from "style/constants";
import { UserStreak, UserStreakForPeriodData } from "models/userStreak";
import { UserStreakForPeriod } from "graphql/userStreak/userStreak.gql";
import {
  GetUserFreeDayPass,
  RedeemScreenBreak,
  RedeemFreeDayPass,
  GetSubHeaderData
} from "graphql/user/user.gql";
import { JourneyDrawerTree } from "./JourneyDrawerTree";
import JourneyCurve from "assets/journeytray-graycurve.svg";
import PreviousDayButton from "./PreviousDayButton";
import NextDayButton from "./NextDayButton";
import EmptyJourneyDayState from "./JourneyDrawerStates/EmptyJourneyDayState";
import StreakItemJourneyDayState from "./JourneyDrawerStates/StreakItemJourneyDayState";
import { UserData } from "models/user";
import { CanBuyItem } from "graphql/userCoins/userCoins.gql";
import { CanBuyItemData } from "models/userCoins";

const singleColumnBreakpoint = "1397px";
interface JourneyDrawerProps {
  inputDate: Date;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateWeeklyStreak: (updatedUserStreak: UserStreak) => void;
}

const JourneyDrawer: React.FC<JourneyDrawerProps> = ({
  inputDate,
  drawerOpen,
  setDrawerOpen,
  updateWeeklyStreak
}) => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const useStyles = makeStyles({
    paper: {
      background: color.WHITE,
      padding: "0px",
      height: `calc(100% - ${mobile ? "145px" : "65px"})`,
      top: mobile ? "100px" : "115px",
      width: mobile ? "100%" : "400px"
    }
  });
  const classes = useStyles();

  const [currentDate, setCurrentDate] = useState<Date>(inputDate);
  const [isToday, setIsToday] = useState(isSameDay(inputDate, new Date()));
  const [userStreak, setUserStreak] = useState<UserStreak>();
  const [hasFreeDayPass, setHasFreeDayPass] = useState<boolean>(false);
  const [canBuyScreenBreak, setCanBuyScreenBreak] = useState<boolean>(false);
  const [coinsTotal, setUserCoinsTotal] = useState<number>(0);
  const [streakDataLoaded, setStreakDataLoaded] = useState<boolean>(false);

  const [redeemFreeDayPass] = useMutation(RedeemFreeDayPass, {
    variables: {
      input: { redemptionDate: currentDate }
    },
    refetchQueries: [{ query: GetSubHeaderData }],
    onCompleted: data => {
      const updatedUserStreak = data.redeemFreeDayPass;

      setUserStreak(updatedUserStreak);
      updateWeeklyStreak(updatedUserStreak);

      dispatch(
        showSuccessNotification(
          "Success",
          "Successfully redeemed Free Day Pass!"
        )
      );
    },
    onError: error => {
      dispatch(
        showErrorNotification(
          "Error",
          (error as any)?.graphQLErrors[0]?.message
        )
      );
    }
  });

  const [redeemScreenBreak] = useMutation(RedeemScreenBreak, {
    variables: {
      input: { redemptionDate: currentDate }
    },
    refetchQueries: [{ query: GetSubHeaderData }],
    onCompleted: data => {
      const updatedUserStreak = data.redeemScreenBreak;

      setUserStreak(updatedUserStreak);
      updateWeeklyStreak(updatedUserStreak);

      dispatch(
        showSuccessNotification(
          "Success",
          "Successfully purchased Screen Break!"
        )
      );
    },
    onError: error => {
      dispatch(
        showErrorNotification(
          "Error",
          (error as any)?.graphQLErrors[0]?.message
        )
      );
    }
  });

  const { refetch: refetchUserStreak } = useQuery<UserStreakForPeriodData>(
    UserStreakForPeriod,
    {
      variables: {
        input: {
          periodStart: startOfDay(currentDate),
          periodEnd: endOfDay(currentDate)
        }
      },
      skip: !currentDate,
      fetchPolicy: "no-cache",
      onCompleted: data => {
        const streak = data?.userStreakForPeriod.sort(
          (a: UserStreak, b: UserStreak) =>
            new Date(a.date).getTime() - new Date(b.date).getTime()
        )[0];
        setUserStreak(streak);
        setStreakDataLoaded(true);
      },
      onError: () =>
        dispatch(
          showErrorNotification("", "Unable to pull journey data for today.")
        )
    }
  );

  const [userFreeDayPass] = useLazyQuery<UserData>(GetUserFreeDayPass, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setHasFreeDayPass(data?.me?.hasFreeDayPass || false);
    }
  });

  const [userCanBuyScreenBreak] = useLazyQuery<CanBuyItemData>(CanBuyItem, {
    variables: {
      input: { category: "Tree Revival", subCategory: "Screen Break" }
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setCanBuyScreenBreak(data.canBuyItem && !isToday);
    }
  });

  const [userCoinsTotal] = useLazyQuery<UserData>(GetSubHeaderData, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setUserCoinsTotal(data.me.totalCoins);
    }
  });

  useEffect(() => {
    if (inputDate !== currentDate) {
      setCurrentDate(inputDate);
      setIsToday(isSameDay(inputDate, new Date()));
      refetchUserStreak();
    }
  }, [inputDate]);

  useEffect(() => {
    userFreeDayPass();
    userCanBuyScreenBreak();
    userCoinsTotal();
  }, [userStreak]);

  const hasCompletedTaskStreak =
    !userStreak?.freeDayPassApplied &&
    !userStreak?.screenBreakApplied &&
    (userStreak?.habitCompleted ||
      userStreak?.trainingCompleted ||
      userStreak?.resourceViewed);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  function handlePreviousDayClick(): void {
    if (currentDate) {
      const previousDay = subDays(currentDate, 1);
      setCurrentDate(previousDay);
      setIsToday(isSameDay(previousDay, new Date()));
      refetchUserStreak();
    }
  }

  function handleNextDayClick(): void {
    if (currentDate) {
      const nextDay = addDays(currentDate, 1);
      setCurrentDate(nextDay);
      setIsToday(isSameDay(nextDay, new Date()));
      refetchUserStreak();
    }
  }

  if (!streakDataLoaded || !currentDate) return null;

  return (
    <>
      {streakDataLoaded && currentDate && (
        <MUIDrawer
          anchor="right"
          classes={{ paper: classes.paper }}
          onClose={closeDrawer}
          open={drawerOpen}
          variant="temporary"
        >
          {drawerOpen && (
            <StyledGrid
              container
              alignItems="center"
              direction="column"
              padding={spacingDefaults.medium}
            >
              <img
                src={JourneyCurve}
                width="100%"
                style={{ position: "absolute", top: "-25px", zIndex: -1 }}
              />

              <IconButton
                size="medium"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0
                }}
                onClick={closeDrawer}
              >
                <CloseIcon />
              </IconButton>

              <StyledGrid item>
                <p
                  style={{
                    fontWeight: "bold",
                    margin: spacingDefaults.xlarge
                  }}
                >
                  {format(currentDate, "EEEE, MMM do")}
                </p>
              </StyledGrid>

              <StyledGrid item alignSelf="center">
                <StyledGrid
                  container
                  alignContent="center"
                  alignItems="center"
                  flexDirection="row"
                  wrap="nowrap"
                >
                  <PreviousDayButton
                    shouldDisable={
                      !userStreak ||
                      isSameDay(
                        currentDate,
                        new Date(userStreak.userRegistrationDate)
                      )
                    }
                    onPreviousDayClick={handlePreviousDayClick}
                  />
                  <JourneyDrawerTree
                    userStreak={userStreak}
                    isToday={isToday}
                  />
                  <NextDayButton
                    shouldDisable={!userStreak || isToday}
                    onNextDayClick={handleNextDayClick}
                  />
                </StyledGrid>
              </StyledGrid>
              <p
                style={{
                  fontStyle: "italic",
                  fontWeight: "bold",
                  margin: spacingDefaults.medium
                }}
              >
                {isToday ? `Today So Far...` : `On This Day...`}
                {!userStreak?.habitCompleted &&
                  !userStreak?.trainingCompleted &&
                  !userStreak?.resourceViewed &&
                  " No Tasks Completed"}
              </p>
              <StyledGrid
                marginTop={spacingDefaults.xsmall}
                marginBottom={spacingDefaults.medium}
                width="80%"
              >
                <Divider />
              </StyledGrid>
            </StyledGrid>
          )}
          <div className="journey-drawer-day">
            <EmptyJourneyDayState
              isToday={isToday}
              userStreak={userStreak}
              hasFreeDayPass={hasFreeDayPass}
              canBuyScreenBreak={canBuyScreenBreak}
              coinsTotal={coinsTotal}
              closeDrawer={closeDrawer}
              redeemFreeDayPass={redeemFreeDayPass}
              purchaseScreenBreak={redeemScreenBreak}
            />
            {hasCompletedTaskStreak && (
              <StreakItemJourneyDayState
                currentDate={currentDate}
                userStreak={userStreak}
                hasFreeDayPass={hasFreeDayPass}
                canBuyScreenBreak={canBuyScreenBreak}
                coinsTotal={coinsTotal}
                closeDrawer={closeDrawer}
                redeemFreeDayPass={redeemFreeDayPass}
                purchaseScreenBreak={redeemScreenBreak}
              />
            )}
          </div>
        </MUIDrawer>
      )}
    </>
  );
};

export default JourneyDrawer;
