import { Box, MenuItem, Tabs, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";

import { Ruler, StyledA, StyledGrid } from "components/simple";
import { UserAssessment } from "graphql/types/User";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import { Cycle, Pillar } from "../types";
import BarGraph from "./BarGraph";
import MoreInfo from "./MoreInfo";
import Overview from "./Overview";
import PillarLabel from "./PillarLabel";
import Stepper from "./Stepper";
import { StyledSelect, StyledTab } from "./styled";
import TabPanel from "./TabPanel";
import { mapScores } from "./utils";

interface BrainHealthIndexProps {
  cycles: Cycle[];
  selectedCycleIndex: number;
}

const BrainHealthIndex: React.FC<BrainHealthIndexProps> = ({
  cycles,
  selectedCycleIndex
}: BrainHealthIndexProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [stepperValue, setStepperValue] = useState(0);

  const selectedCycle = cycles[selectedCycleIndex];

  if (!selectedCycle) {
    return null;
  }

  const pillars: Pillar[] = [];
  let bhi: Pillar = mapScores("index", 0, []);

  selectedCycle.pillarScores.forEach(pillarScore => {
    if (pillarScore.pillarName === "index") {
      bhi = mapScores(pillarScore.pillarName, pillarScore.value, []);
    } else {
      pillars.push(
        mapScores(
          pillarScore.pillarName,
          pillarScore.value,
          pillarScore.userAssessments as Partial<UserAssessment>[]
        )
      );
    }
  });

  pillars.sort((a, b) => a.order - b.order);

  return (
    <StyledGrid container>
      <StyledGrid item xs={12}>
        {mobile ? (
          <Box margin={spacingDefaults.small}>
            <StyledSelect
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedTabIndex(parseInt(event.target.value));
              }}
              value={selectedTabIndex.toString()}
              variant="outlined"
            >
              <MenuItem value={0}>
                <PillarLabel name="Overview" color={color.DARKBLUE} />
              </MenuItem>

              {pillars.map(pillar => (
                <MenuItem value={pillar.order} key={pillar.pillarName}>
                  <PillarLabel
                    name={pillar.pillarName}
                    color={pillar.color as color}
                  />
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>
        ) : (
          <>
            <Tabs
              aria-label="pillar score tabs"
              centered
              onChange={(_, value) => setSelectedTabIndex(value)}
              value={selectedTabIndex}
            >
              <StyledTab
                label={<PillarLabel name="Overview" color={color.DARKBLUE} />}
                {...a11yProps(0)}
              />

              {pillars.map(pillar => {
                return (
                  <StyledTab
                    key={pillar.order}
                    label={
                      <PillarLabel
                        name={pillar.pillarName}
                        color={pillar.color as color}
                      />
                    }
                    {...a11yProps(pillar.order)}
                  />
                );
              })}
            </Tabs>

            <Ruler margins={`0 ${spacingDefaults.xxlarge}`} />
          </>
        )}
      </StyledGrid>

      <StyledGrid
        container
        item
        justifyContent="center"
        padding={mobile ? spacingDefaults.normal : spacingDefaults.xlarge}
        xs={12}
      >
        <TabPanel index={0} value={selectedTabIndex}>
          <Overview
            cycles={cycles}
            selectedCycleIndex={selectedCycleIndex}
            currentScore={bhi.value}
            pillars={pillars}
          />
        </TabPanel>

        {pillars.map((pillar, index) => {
          return (
            <TabPanel index={index + 1} key={index} value={selectedTabIndex}>
              <BarGraph
                pillar={pillar}
                isMobile={mobile}
                stepperValue={stepperValue}
              />

              <Stepper
                isMobile={mobile}
                pillar={pillar}
                callback={setStepperValue}
              />

              <Ruler margins={spacingDefaults.normal} />

              <StyledGrid marginBottom={spacingDefaults.normal}>
                <Box
                  color={color.BLACK}
                  component="p"
                  fontSize={fontSize.medium}
                  margin="0"
                >
                  More Information on {pillar.pillarName}
                </Box>

                <StyledGrid container>
                  <MoreInfo
                    data={pillar.moreInfo}
                    pillarColor={pillar.color as color}
                    pillarName={pillar.pillarName}
                    mobile={mobile}
                  />
                </StyledGrid>
              </StyledGrid>
            </TabPanel>
          );
        })}

        {selectedTabIndex !== 0 && (
          <Box marginY={spacingDefaults.normal}>
            <StyledA
              color={color.DARKBLUE}
              href="https://brainhealth.utdallas.edu/what-is-brain-health/"
            >
              View the interactive BrainHealth Wheel
            </StyledA>
          </Box>
        )}
      </StyledGrid>
    </StyledGrid>
  );

  function a11yProps(index: number): Record<string, string> {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`
    };
  }
};

export default BrainHealthIndex;
