import React from "react";

import { ReactComponent as Qualified } from "assets/qualified.svg";
import { Button, StyledGrid, Heading, StyledP } from "components/simple";
import { color, spacingDefaults, fontSize } from "style/constants";
import { GetOrganizationConsentFormInfo } from "graphql/user/user.gql";
import { useQuery } from "@apollo/react-hooks";

const Qualify: React.FC = () => {
  const { data } = useQuery(GetOrganizationConsentFormInfo, {
    fetchPolicy: "no-cache"
  });

  const shouldHideConsentForm = data?.me?.organization?.hideConsentForm;
  return (
    <StyledGrid
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      backgroundColor={color.WHITE}
      container
      direction="column"
      fillRemainingHeight
      minHeight="700px"
      wrap="nowrap"
      textAlign="center"
      padding={spacingDefaults.normal}
      itemMargin={spacingDefaults.normal}
    >
      <StyledGrid item>
        <Qualified />
      </StyledGrid>
      <StyledGrid item>
        <Heading tag="h1" fontSize={fontSize.header}>
          Congratulations!
        </Heading>
      </StyledGrid>
      <StyledGrid maxWidth="600px" mobileMarginBottom={spacingDefaults.normal}>
        <StyledP textAlign="center">
          {`You're ready to start using the BrainHealth App. Just one more step to
        complete your registration`}
        </StyledP>
      </StyledGrid>
      <StyledGrid item>
        <Button
          wide
          href={
            shouldHideConsentForm ? "/consent/join" : "/consent/form?page=1"
          }
        >
          Continue
        </Button>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Qualify;
