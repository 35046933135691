import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Divider, useMediaQuery } from "@material-ui/core";

import {
  StyledGrid,
  WheelIcons,
  WidgetTitle,
  StyledA,
  StyledP
} from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";
import { StyledButton } from "../styled";
import { CompletionStatus, DetailedCourseProgress } from "../types";
import { useTlmsLink } from "../utils";
import UnitStatus from "./UnitStatus";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";

interface CourseStatusProps {
  courseProgress: DetailedCourseProgress | null;
  handleViewAllCourses(): void;
  disabled?: boolean;
  isActive?: boolean;
}

const CourseStatus: React.FC<CourseStatusProps> = ({
  courseProgress,
  handleViewAllCourses,
  disabled = false,
  isActive = false
}: CourseStatusProps) => {
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [isContentfulEnabled, setIsContentfulEnabled] = useState<boolean>(
    false
  );

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Contentful" } },
    onCompleted: data => setIsContentfulEnabled(data?.isFeatureEnabled?.enabled)
  });

  const courseNotStarted =
    courseProgress?.id === "160" &&
    courseProgress.completionStatus === CompletionStatus.NotAttempted;

  const { connectToTlms } = useTlmsLink();

  let timeEstimate: number | undefined = undefined;

  if (courseProgress && courseProgress.timeEstimate) {
    const completedUnits = courseProgress.unitProgress.filter(
      x => x.completionStatus === CompletionStatus.Completed
    ).length;

    if (completedUnits < courseProgress.unitProgress.length) {
      const unitsRemaining =
        courseProgress.unitProgress.length - completedUnits;

      timeEstimate = Math.round(
        (courseProgress.timeEstimate *
          (1 - courseProgress.completionPercentage / 100)) /
          unitsRemaining
      );
    }
  }

  return (
    <StyledGrid container>
      <StyledGrid container item justifyContent="space-between">
        <WidgetTitle>Current Course</WidgetTitle>

        <StyledA
          aria-disabled={disabled}
          onClick={disabled ? undefined : handleViewAllCourses}
        >
          View All Courses
        </StyledA>
      </StyledGrid>

      <StyledGrid
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        marginTop={spacingDefaults.normal}
        filter={isActive ? undefined : "saturate(0)"}
      >
        <StyledGrid
          alignItems="center"
          backgroundColor={color.DARKBLUE}
          borderRadius="8px 8px 0 0"
          container
          height="37px"
          item
          justifyContent="space-between"
          padding="0 20px"
          width="100%"
        >
          {courseProgress ? (
            <StyledP color={color.WHITE} fontSize="14px">
              {courseProgress.categoryName}
            </StyledP>
          ) : (
            <StyledGrid
              backgroundColor={color.WHITE}
              borderRadius="8px"
              height="15px"
              opacity="0.1"
              width="100px"
            />
          )}

          <StyledGrid item>
            <WheelIcons
              pillars={courseProgress ? courseProgress.pillars : []}
            />
          </StyledGrid>
        </StyledGrid>

        <StyledGrid
          alignItems="center"
          backgroundColor={color.LIGHTERBLUE}
          borderBottom={`1px solid ${color.MUTEDGRAY}`}
          container
          height="66px"
          item
          justifyContent="space-between"
          padding="0 20px"
          width="100%"
          wrap="nowrap"
        >
          {courseProgress ? (
            <StyledP
              color={color.BLACK}
              fontSize={mobile ? "14px" : "16px"}
              letterSpacing="-0.75px"
            >
              <strong>{courseProgress.name}</strong>
            </StyledP>
          ) : (
            <StyledGrid
              backgroundColor={color.LIGHTERGRAY}
              borderRadius="8px"
              height="25px"
              opacity="0.5"
              width="200px"
            />
          )}

          {courseProgress && (
            <StyledButton
              disabled={disabled}
              height="32px"
              width="132px"
              onClick={() => connectToTlms(courseProgress.id)}
            >
              {courseNotStarted ? "Begin Training" : "Keep Training"}
            </StyledButton>
          )}
        </StyledGrid>

        {courseProgress ? (
          <StyledGrid
            maxHeight="400px"
            overflow="scroll"
            padding={mobile ? "0 20px" : "0 40px"}
            width="100%"
          >
            {courseProgress.unitProgress.map((unitProgress, index) => (
              <Fragment key={index}>
                <UnitStatus
                  number={index + 1}
                  name={unitProgress.name}
                  completed={
                    unitProgress.completionStatus === CompletionStatus.Completed
                  }
                  timeEstimate={timeEstimate}
                />
                {index !== courseProgress.unitProgress.length - 1 && (
                  <Divider />
                )}
              </Fragment>
            ))}
          </StyledGrid>
        ) : (
          <StyledGrid centerContent container height="223px" item>
            <StyledP
              color={color.BLACK}
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              width="280px"
            >
              When you get further instructions from your coach, you will see
              your next training course to dive into.
            </StyledP>
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default CourseStatus;
