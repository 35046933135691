import { gql } from "apollo-boost";

export const FindProductTour = gql`
  query FindProductTour($input: UserProductToursInput!) {
    findProductTour(input: $input) {
      id
    }
  }
`;

export const GetProductTour = gql`
  query GetProductTour($input: UserProductToursInput!) {
    getProductTour(input: $input) {
      title
      steps {
        title
        description
      }
    }
  }
`;

export const SaveProductTour = gql`
  mutation SaveProductTour($input: UserProductToursInput!) {
    saveProductTour(input: $input) {
      userProductTour {
        id
      }
    }
  }
`;
