import React, { Dispatch, SetStateAction } from "react";
import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { StyledGrid } from "components/simple";
import {
  PillarName,
  SelectedSubFactor,
  SubFactor
} from "components/pages/Index/CycleSummary/types";
import { color, spacingDefaults } from "style/constants";
import { getFactorColor } from "../BHIChart/radar";

interface FactorCardProps {
  selectedFactor?: PillarName;
  selectedSubFactor: SelectedSubFactor;
  setSelectedSubFactor: Dispatch<SetStateAction<SelectedSubFactor>>;
  subFactors: SubFactor[];
}

const FactorCardFooter: React.FC<FactorCardProps> = ({
  selectedFactor,
  selectedSubFactor,
  setSelectedSubFactor,
  subFactors
}) => {
  return (
    <StyledGrid
      container
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
    >
      <StyledGrid
        backgroundColor={color.WHITE}
        borderRadius="50%"
        item
        boxShadow="0px 4px 13px rgba(0, 0, 0, 0.15)"
      >
        <IconButton
          aria-label="Previous"
          color="secondary"
          onClick={() => {
            if (selectedSubFactor) {
              const index =
                selectedSubFactor.index === 0
                  ? subFactors.length - 1
                  : selectedSubFactor?.index - 1;
              setSelectedSubFactor({
                ...subFactors[index],
                index
              });
            }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </StyledGrid>
      <StyledGrid item>
        <StyledGrid
          container
          style={{ gap: spacingDefaults.small }}
          margin={`0 ${spacingDefaults.small}`}
          wrap="nowrap"
        >
          {subFactors.map((item: SubFactor, idx: number) => (
            <StyledGrid key={idx} item>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor:
                    selectedSubFactor.name === item.name
                      ? getFactorColor(selectedFactor)
                      : color.INDEX_BG,
                  borderRadius: "50%"
                }}
              />
            </StyledGrid>
          ))}
        </StyledGrid>
      </StyledGrid>
      <StyledGrid
        backgroundColor={color.WHITE}
        borderRadius="50%"
        item
        boxShadow="0px 4px 13px rgba(0, 0, 0, 0.15)"
      >
        <IconButton
          aria-label="Next"
          color="secondary"
          onClick={() => {
            if (selectedSubFactor) {
              const index = (selectedSubFactor?.index + 1) % subFactors.length;
              setSelectedSubFactor({
                ...subFactors[index],
                index
              });
            }
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default FactorCardFooter;
