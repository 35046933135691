import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { NativeSelectProps } from "@material-ui/core/NativeSelect";
import { Theme } from "@material-ui/core";

import {
  StyledSelect,
  StyledMenuItem,
  StyledDown,
  overrideTheme,
  selectedTheme
} from "./styled";

interface CustomProps {
  callback: React.Dispatch<any>;
  customTheme?: Theme;
  defaultText?: string;
  options: Record<any, any>[];
  value?: string;
}

type DropdownProps = CustomProps & NativeSelectProps;

const DropdownSelect: React.FC<DropdownProps> = ({
  callback,
  customTheme,
  defaultText,
  options,
  value,
  ...otherProps
}: DropdownProps) => {
  return (
    <ThemeProvider
      theme={
        value && customTheme
          ? customTheme
          : value
          ? selectedTheme
          : overrideTheme
      }
    >
      <StyledSelect
        IconComponent={StyledDown}
        onChange={(event: React.ChangeEvent<unknown>) => {
          callback((event.target as HTMLInputElement).value);
        }}
        value={value}
        variant={"outlined"}
        {...otherProps}
      >
        <StyledMenuItem value="">
          {defaultText || "Select an Option"}
        </StyledMenuItem>
        {options.map((option: Record<any, any>) => (
          <StyledMenuItem
            key={option[Object.keys(option)[0]]}
            value={option[Object.keys(option)[0]]}
          >
            {Object.keys(option)[0]}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </ThemeProvider>
  );
};

export default DropdownSelect;
