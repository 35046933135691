import { gql } from "apollo-boost";

export const CoinsRequiredForItem = gql`
  query CoinsRequiredForItem($input: CoinsCatalogCoinsRequiredInput!) {
    coinsRequiredForItem(input: $input) {
      id
      amount
    }
  }
`;
