import { useMediaQuery } from "@material-ui/core";
import React from "react";

import { StyledGrid } from "components/simple";
import { breakpoints, spacingDefaults } from "style/constants";
import { ImagingSession } from "../../CycleSummary/types";
import ImagingSessionSummary from "./Summary";

interface ImagingSessionsProps {
  imagingSessions: ImagingSession[];
}

const ImagingSessions: React.FC<ImagingSessionsProps> = ({
  imagingSessions
}: ImagingSessionsProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid container>
      <StyledGrid container item xs={12}>
        {imagingSessions.map((session, index) => {
          return (
            <StyledGrid
              key={index}
              item
              paddingRight={mobile ? undefined : spacingDefaults.xsmall}
              paddingBottom={mobile ? undefined : spacingDefaults.xsmall}
              xs={12}
            >
              <ImagingSessionSummary
                index={index}
                imagingSession={session}
                acuityConfirmationNumber={session.acuityConfirmationNumber}
              />
            </StyledGrid>
          );
        })}
      </StyledGrid>
    </StyledGrid>
  );
};

export default ImagingSessions;
