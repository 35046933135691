import React from "react";
import { Link } from "react-router-dom";
import { CheckCircle, LockOutlined } from "@material-ui/icons";
import { Box } from "@material-ui/core";

import { ReactComponent as BlueCurveBG } from "assets/blue-curve-bg.svg";
import { ReactComponent as GreenCurveBG } from "assets/green-curve-bg.svg";
import { ReactComponent as DisabledCurveBG } from "assets/disabled-curve-bg.svg";
import { ReactComponent as StarPurple } from "assets/star-purple.svg";
import { ReactComponent as SquareGreen } from "assets/square-green.svg";
import { ReactComponent as DiamondYellow } from "assets/diamond-yellow.svg";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";
import { Ruler, StyledGrid } from "components/simple";
import { toLowerDashCase } from "../utils";
import {
  ContentfulCourse,
  CourseStatus,
  FactorTypes
} from "graphql/types/Contentful";
export interface CardProps {
  course: ContentfulCourse;
}

const ProgressBar = ({ value }: { value: number | undefined }) => {
  return (
    <StyledGrid container>
      <StyledGrid backgroundColor={color.GRAY_PROGESS_BAR} item xs={12}>
        <StyledGrid
          backgroundColor={color.BLUE}
          height="100%"
          width={`${value || 0}%`}
        >
          <Box
            textAlign="left"
            marginLeft={spacingDefaults.xsmall}
            fontSize={fontSize.xsmall}
            color={color.WHITE}
          >
            {value || 0}%
          </Box>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export const Factors = ({ factors }: { factors: FactorTypes[] }) => {
  return (
    <StyledGrid container alignItems="center">
      <StyledGrid item margin="0 3px">
        {factors.includes(FactorTypes.clarity) && <StarPurple />}
      </StyledGrid>
      <StyledGrid item margin="0 3px">
        {factors.includes(FactorTypes.connectedness) && <DiamondYellow />}
      </StyledGrid>
      <StyledGrid item margin="0 3px">
        {factors.includes(FactorTypes.emotionalBalance) && <SquareGreen />}
      </StyledGrid>
    </StyledGrid>
  );
};

const getBorder = (
  isCompleted: boolean | undefined,
  isInProgress: boolean | undefined,
  isNotStarted: boolean | undefined,
  isDisabled: boolean | undefined
): string => {
  let borderColor = color.BLACK;
  if (isCompleted) borderColor = color.GREEN;
  else if (isInProgress || isNotStarted) borderColor = color.BLUE;
  else if (isDisabled) borderColor = color.GRAY;
  return `1px solid ${borderColor}`;
};

const CourseCard: React.FC<CardProps> = ({ course }: CardProps) => {
  const isCompleted = course?.status === CourseStatus.completed;
  const isInProgress = course?.status === CourseStatus.inProgress;
  const isNotStarted =
    course?.status === CourseStatus.notStarted && course?.active;
  const isDisabled = !course?.active;
  const progressStatus = isCompleted
    ? "Completed"
    : isInProgress
    ? "In Progress"
    : "Not Started";

  return (
    <StyledGrid container justifyContent="center">
      <Link
        to={{
          pathname: course?.active
            ? `/course/${toLowerDashCase(course?.title)}`
            : "/training"
        }}
        style={{ textDecoration: "none", color: color.BLACK }}
      >
        <StyledGrid
          item
          container
          width="342px"
          height="220px"
          borderRadius="8px"
          backgroundColor={color.WHITE}
          border={getBorder(
            isCompleted,
            isInProgress,
            isNotStarted,
            isDisabled
          )}
          style={{ cursor: "pointer" }}
        >
          <StyledGrid
            container
            item
            width="100%"
            justifyContent="center"
            position="relative"
            height="28%"
          >
            {isCompleted && (
              <StyledGrid position="absolute" top="-49px" left="-10px">
                <GreenCurveBG />
              </StyledGrid>
            )}
            {(isInProgress || isNotStarted) && (
              <StyledGrid position="absolute" top="-49px" left="-10px">
                <BlueCurveBG />
              </StyledGrid>
            )}
            {isDisabled && (
              <StyledGrid position="absolute" top="-49px" left="-10px">
                <DisabledCurveBG />
              </StyledGrid>
            )}
            <StyledGrid
              item
              zIndex="1"
              marginTop={spacingDefaults.xsmall}
              filter={isDisabled ? "grayscale(1)" : undefined}
            >
              <img src={course?.thumbnailImage} height={"80px"} />
            </StyledGrid>
          </StyledGrid>
          <StyledGrid container>
            <StyledGrid item xs={12} centerContent>
              <StyledGrid container alignItems="center">
                <StyledGrid item>
                  <Box
                    fontSize={fontSize.large}
                    fontFamily={fontFamily.secondary}
                    fontWeight="500"
                  >
                    {course?.title}
                  </Box>
                </StyledGrid>

                <StyledGrid item>
                  {course?.factors && <Factors factors={course?.factors} />}
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item xs={12} centerContent>
              <Ruler margins={`0 ${spacingDefaults.normal}`} />
            </StyledGrid>
            <StyledGrid container centerContent height="22px">
              {course?.active && (
                <StyledGrid container alignItems="center">
                  <StyledGrid item marginRight={spacingDefaults.xsmall}>
                    <Box
                      color={isCompleted ? color.GREEN : color.BLUE}
                      fontStyle="italic"
                    >
                      {progressStatus}
                    </Box>
                  </StyledGrid>
                  {isCompleted && (
                    <StyledGrid item>
                      <CheckCircle
                        fontSize="small"
                        style={{ color: color.GREEN }}
                      />
                    </StyledGrid>
                  )}
                  {isInProgress && (
                    <StyledGrid item xs={6}>
                      <ProgressBar value={course?.percentageCompletion} />
                    </StyledGrid>
                  )}
                </StyledGrid>
              )}
              {isDisabled && (
                <StyledGrid container>
                  <StyledGrid item marginRight={spacingDefaults.xsmall}>
                    <Box color={color.DARKERGRAY} fontStyle="italic">
                      Locked
                    </Box>
                  </StyledGrid>
                  <StyledGrid item>
                    <LockOutlined
                      fontSize="small"
                      style={{ color: color.DARKERGRAY }}
                    />
                  </StyledGrid>
                </StyledGrid>
              )}
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </Link>
    </StyledGrid>
  );
};

export default CourseCard;
