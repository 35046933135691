import { Hidden, useMediaQuery, Link } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";

import DietLogo from "assets/dietid-logo.png";
import { ReactComponent as BannerLeft } from "assets/dietid-banner-left.svg";
import { ReactComponent as BannerRight } from "assets/dietid-banner-right.svg";

import { StyledGrid, StyledP } from "components/simple";
import {
  color,
  spacingDefaults,
  breakpoints,
  fontSize,
  fontFamily
} from "style/constants";
import routesConfig from "utils/routesConfig";

interface DietIDButtonProps {
  disabled?: boolean;
  accessCode?: string;
}

const DietIDButton: React.FC<DietIDButtonProps> = ({
  disabled = false,
  accessCode
}: DietIDButtonProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  if (!accessCode) return null;

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      container
      marginTop={spacingDefaults.normal}
      mobilePadding={spacingDefaults.medium}
      overflow="hidden"
      padding={spacingDefaults.large}
      position="relative"
      height={mobile ? undefined : "183px"}
      alignContent={"center"}
    >
      <StyledGrid item position="absolute" left={mobile ? "-65px" : "0"}>
        <BannerLeft height={233} />
      </StyledGrid>

      <Hidden mdDown>
        <StyledGrid item position="absolute" right="0" top="-42px">
          <BannerRight height={252} width={295} />
        </StyledGrid>
      </Hidden>

      <StyledGrid
        height={mobile ? "46px" : "63px"}
        item
        overflow="hidden"
        width={mobile ? "120px" : "168px"}
        zIndex="1"
        top={"0px"}
        marginTop={mobile ? "0" : "10px"}
        marginLeft={mobile ? "-5px" : "0"}
        marginRight={"10px"}
      >
        <img
          alt="DietID Logo"
          src={DietLogo}
          height={mobile ? 46 : 63}
          width={mobile ? 120 : 168}
        />
      </StyledGrid>

      <StyledGrid item>
        {mobile ? (
          <StyledGrid item>
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.medium}
              margin={`0 0 ${spacingDefaults.xsmall} 0`}
            >
              Click here
              <br />
              to access <b>DietID</b>
            </StyledP>

            <Link
              aria-disabled={disabled}
              component="button"
              disabled={disabled}
              onClick={
                disabled
                  ? undefined
                  : () => history.push(routesConfig.dietid.path)
              }
            >
              <StyledP
                color={color.BLACK}
                margin={`${spacingDefaults.xsmall} 0 ${spacingDefaults.xsmall} 0`}
              >
                <b>Learn More &gt;</b>
              </StyledP>
            </Link>
          </StyledGrid>
        ) : (
          <StyledGrid item marginLeft={"21px"} marginTop={"5px"}>
            <StyledGrid
              item
              marginBottom={mobile ? spacingDefaults.normal : "0"}
              paddingTop={mobile ? "25px" : "0"}
            >
              <StyledP
                color={color.BLACK}
                weight={"400"}
                fontSize={"26px"}
                lineHeight={"30px"}
                letterSpacing={"-0.9PX"}
                fontFamily={fontFamily.secondary}
              >
                Click here to access <strong>DietID</strong>
              </StyledP>
              <StyledP
                weight={"400"}
                fontSize={"20PX"}
                color={color.BLACK}
                lineHeight={"30px"}
                letterSpacing={"-0.9PX"}
                fontFamily={fontFamily.secondary}
              >
                Use passcode <strong>{accessCode}</strong>
              </StyledP>
            </StyledGrid>

            <StyledGrid item marginTop={spacingDefaults.xsmall}>
              <StyledP color={color.BLACK} width="495px">
                DietID is a digital toolkit that assesses and supports healthy
                eating.
              </StyledP>
            </StyledGrid>
            <Link
              aria-disabled={disabled}
              component="button"
              disabled={disabled}
              onClick={
                disabled
                  ? undefined
                  : () => history.push(routesConfig.dietid.path)
              }
            >
              <StyledP
                color={color.BLACK}
                margin={`${spacingDefaults.xsmall} 0 ${spacingDefaults.xsmall} 0`}
              >
                <b>Learn More &gt;</b>
              </StyledP>
            </Link>
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default DietIDButton;
