import { color } from "style/constants";

export default {
  root: {
    backgroundColor: "transparent",
    border: `1px solid ${color.PALEGRAY}`,
    borderRadius: "8px",
    padding: "1rem",
    width: "100%"
  },
  label: {
    fontSize: "18px",
    lineHeight: "22px",
    paddingLeft: "0",
    paddingRight: "0",
    overflow: "auto",
    width: "100%"
  },
  deleteIcon: {
    height: "1rem",
    marginLeft: "1rem",
    maxWidth: ".75rem",
    marginRight: "0",
    minWidth: ".75rem",
    stroke: color.BLACK
  }
};
