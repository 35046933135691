import React from "react";

import { StyledGrid } from "components/simple";
import { color, spacingDefaults } from "style/constants";
import PillarDot from "../PillarDot";

interface PillarLabelProps {
  color: color;
  name: string;
}

const PillarLabel: React.FC<PillarLabelProps> = ({
  color,
  name
}: PillarLabelProps) => (
  <StyledGrid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    itemMargin={spacingDefaults.xsmall}
  >
    <PillarDot color={color} />
    {name}
  </StyledGrid>
);

export default PillarLabel;
