import styled from "styled-components";

import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

export const BHITitle = styled.h2`
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.large};
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: ${fontSize.large};
  margin: 0;
`;

export const Body = styled.p`
  color: ${color.DARKERGRAY};
`;

export const Index = styled.h1`
  color: ${color.DARKBLUE};
  font-size: 68px;
  font-weight: 300;
  letter-spacing: -3px;
  line-height: 13px;
`;

export const LineWrapper = styled.div`
  aspect-ratio: 2 / 1;
  margin: ${spacingDefaults.normal} auto;
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

export const InfoCardWrapper = styled.div`
  background-color: ${color.WHITE};
  border-radius: 8px;
  border: 1px solid rgba(35, 31, 32, 0.2);
  padding: 2rem;

  @media (max-width: ${breakpoints.lg}px) {
    padding: ${spacingDefaults.normal};
  }
`;

export const LegendLabel = styled.p`
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.small};
`;

export const Legend = styled.div`
  .legend {
    &:before {
      content: "";
      height: 8px;
      left: 1rem;
      position: absolute;
      width: 8px;
    }

    &:hover {
      cursor: pointer;
    }

    &.index:before {
      background-color: ${color.DARKBLUE};
      border-radius: 50%;
    }

    &.emotional-balance:before {
      background-color: ${color.FUSCHIA};
    }

    &.connectedness:before {
      border-bottom: 9px solid ${color.GREEN};
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      height: 0px;
      width: 0px;
    }

    &.clarity:before {
      background-color: ${color.PURPLE};
      transform: rotate(0.125turn);
    }
  }
`;
