import React, { useState } from "react";
import { useDispatch } from "react-redux";
import track, { useTracking } from "react-tracking";
import { useMediaQuery } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";

import {
  CoachMarks,
  HabitsBanner,
  PageTitle,
  StyledGrid,
  WaitlistBanner,
  WidgetTitle
} from "components/simple";
import {
  GetResources,
  userResourcesInsightsByParticipant
} from "graphql/userResources/userResources.gql";
import { useQueryCustom } from "hooks";
import { appWidth, breakpoints, spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import ArticleCard from "./ArticleCard";
import FeaturedArticle from "./FeaturedArticle";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { useHistory } from "react-router";
import { UserResources } from "models/userResource";

const url: string = routesConfig.resources.path;

const Resources: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const tracking = useTracking();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isRefactorEnabled, setIsRefactorEnabled] = useState<boolean>(false);
  const [userResources, setUserResources] = useState<UserResources[]>([]);

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Refactor" } },
    onCompleted: data => setIsRefactorEnabled(data.isFeatureEnabled.enabled)
  });

  const { data } = useQueryCustom({
    input: GetResources
  });

  useQuery(userResourcesInsightsByParticipant, {
    onCompleted: (resources: {
      userResourcesInsightsByParticipant: UserResources[];
    }) => {
      setUserResources(resources.userResourcesInsightsByParticipant);
    }
  });

  if (!data) return null;

  const isWaitlisted = data.userResources.waitListed;
  const featured = data.userResources.featured[0];
  const articles = data.userResources.articles;
  const recommended = data.userResources.recommended;
  function trackClick(resourceTitle: string): void {
    tracking.trackEvent({
      url,
      actions: ["Resources", "View Resource", resourceTitle]
    });
  }

  function getCurrentBanner(): JSX.Element | null {
    if (isWaitlisted) {
      return (
        <StyledGrid item marginBottom={spacingDefaults.large} xs={12}>
          <WaitlistBanner />
        </StyledGrid>
      );
    }

    return null;
  }

  function isCompleted(articleId: String): boolean {
    return userResources.some(item => item.contentfulResourceId === articleId);
  }

  return (
    <>
      {!isRefactorEnabled && <HabitsBanner />}
      <StyledGrid
        alignSelf="center"
        container
        maxWidth={appWidth.max}
        mobilePadding={spacingDefaults.normal}
        spacing={3}
      >
        <StyledGrid item xs={12}>
          <PageTitle>Welcome to Your BrainHealth® Resources!</PageTitle>
        </StyledGrid>

        {getCurrentBanner()}

        {featured && (
          <StyledGrid item marginBottom={spacingDefaults.medium} xs={12}>
            <StyledGrid alignItems="center" container item>
              <StyledGrid item xs={11}>
                <WidgetTitle>Featured Article</WidgetTitle>
              </StyledGrid>
              <StyledGrid container item justifyContent="flex-end" xs={1}>
                <CoachMarks coachMarksName="Explore Resources" />
              </StyledGrid>
            </StyledGrid>
            <FeaturedArticle
              id={featured.id}
              createdAt={featured.createdAt}
              callback={() => {
                trackClick(featured.title);
                history.push("/resource-survey", {
                  contentfulResourceId: featured.id,
                  resourceTitle: featured.title
                });
              }}
              coverImage={featured.coverImage}
              hasVideo={featured.hasVideo}
              pillars={featured.bhComponents}
              readingTime={featured.readingTime}
              slug={featured.slug}
              source={featured.source}
              summary={featured.summary}
              target={featured.target}
              title={featured.title}
              url={featured.url}
              completed={isCompleted(featured.id)}
            />
          </StyledGrid>
        )}

        <StyledGrid item xs={12}>
          <WidgetTitle>
            {articles.length > 0 || recommended.length > 0
              ? "Your BrainHealth Education"
              : "There are no more resources to show. Please refresh the page or come back later."}
          </WidgetTitle>
        </StyledGrid>

        {recommended && (
          <StyledGrid container item spacing={2}>
            {recommended
              .sort((a: any, b: any) => b.createdAt - a.createdAt)
              .map((article: any, index: number) => (
                <StyledGrid item xs={12} lg={6} key={index}>
                  <ArticleCard
                    id={article.id}
                    createdAt={article.createdAt}
                    callback={() => {
                      trackClick(article.title);
                      history.push("/resource-survey", {
                        contentfulResourceId: article.id,
                        resourceTitle: article.title
                      });
                    }}
                    coverImage={article.coverImage}
                    hasVideo={article.hasVideo}
                    mediaLeft
                    publicationDate={article.publicationDate}
                    pillars={article.bhComponents}
                    readingTime={article.readingTime}
                    slug={article.slug}
                    source={article.source}
                    summary={article.summary}
                    target={article.target}
                    title={article.title}
                    url={article.url}
                    completed={isCompleted(article.id)}
                  />
                </StyledGrid>
              ))}
          </StyledGrid>
        )}

        {articles && (
          <StyledGrid
            marginTop={spacingDefaults.large}
            overflowX="hidden"
            overflowY="hidden"
          >
            <StyledGrid
              container
              item
              minWidth={mobile ? "" : "1040px"}
              mobileMaxWidth="1040px"
              padding={spacingDefaults.normal}
              spacing={2}
            >
              {articles
                .sort((a: any, b: any) => b.createdAt - a.createdAt)
                .map((article: any, index: number) => (
                  <StyledGrid item xs={mobile ? 12 : 3} key={index}>
                    <ArticleCard
                      id={article.id}
                      createdAt={article.createdAt}
                      callback={() => {
                        trackClick(article.title);
                        history.push("/resource-survey", {
                          contentfulResourceId: article.id,
                          resourceTitle: article.title
                        });
                      }}
                      coverImage={article.coverImage}
                      hasVideo={article.hasVideo}
                      publicationDate={article.publicationDate}
                      pillars={article.bhComponents}
                      readingTime={article.readingTime}
                      slug={article.slug}
                      source={article.source}
                      summary={article.summary}
                      target={article.target}
                      title={article.title}
                      url={article.url}
                      completed={isCompleted(article.id)}
                    />
                  </StyledGrid>
                ))}
            </StyledGrid>
          </StyledGrid>
        )}
      </StyledGrid>
    </>
  );
};

export default track({
  url
})(Resources);
