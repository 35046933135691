import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import { ReactComponent as GrayTree } from "assets/bhi-tree-disabled.svg";
import { Loading, StyledGrid } from "components/simple";
import { GetCycles, GetIndexData, GetSessions } from "graphql/index/index.gql";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";
import {
  addBackgroundColorToRoot,
  appWidth,
  color,
  fontFamily,
  fontSize,
  removeBackgroundColorFromRoot,
  spacingDefaults
} from "style/constants";
import BrainGauge from "./BrainGauge";
import CycleSummary from "./CycleSummary";
import {
  AssessmentCycle,
  CoachingSession,
  GetCyclesData,
  GetSessionsData,
  NewCycle,
  FactorInfo,
  GetFactorsData
} from "./CycleSummary/types";
import PastSessions from "./PastSessions";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { differenceInDays } from "date-fns";
import { GetFactors } from "graphql/index/index.gql";
import { convertToTitleCase } from "components/simple/FactorCard/FactorCardHeader";
import RichText from "../Contentful/Unit/RichText";

const Index: React.FC = () => {
  const [selectedCycleIndex, setSelectedCycleIndex] = useState<number>(-1);
  const [selectedCycleId, setSelectedCycleId] = useState<string>("");
  const [cycleStatus, setCycleStatus] = useState<UserAssessmentCycleStatus>();
  const [hasBrainGaugeAccess, setHasBrainGaugeAccess] = useState<boolean>(
    false
  );
  const [hasAceEnabled, setHasAceEnabled] = useState<boolean>(false);
  const [hasAceAccess, setHasAceAccess] = useState<boolean>(false);
  const [assessmentCycles, setUserAssessmentCycles] = useState<NewCycle[]>();
  const [completedCycles, setCompletedCycles] = useState<NewCycle[]>();
  const [factorInfo, setFactorInfo] = useState<FactorInfo[]>([]);

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "ACE" } },
    onCompleted: data => setHasAceEnabled(data?.isFeatureEnabled?.enabled)
  });

  const { error: indexError, loading: indexLoading } = useQuery(GetIndexData, {
    onCompleted: data => {
      setHasBrainGaugeAccess(data?.me?.brainGaugeAccessCode);
      setCycleStatus(data?.me?.currentCycle?.userAssessmentCycle?.status);
    }
  });

  const { loading: factorInfoLoading } = useQuery<GetFactorsData>(GetFactors, {
    onCompleted: data => setFactorInfo(data.factors)
  });

  const assessmentStatusFactor = factorInfo.find(
    factor => factor.name == "Assessment Status"
  );

  const subFactorLabels =
    factorInfo
      .find(factor => factor.name === "Assessment Status")
      ?.subFactors.map(c => c.name) || [];

  const scoringInProgress = subFactorLabels?.indexOf("scoring_in_progress");
  const readyToScore = subFactorLabels?.indexOf("ready_to_score");
  const readyForReview = subFactorLabels?.indexOf("ready_for_review");
  const notStarted = subFactorLabels?.indexOf("assessments_not_started");
  const inProgress = subFactorLabels?.indexOf("assessments_in_progress");
  const assessmentsNull = subFactorLabels?.indexOf("assessments_null");

  const { data, error: cycleError, loading: cycleLoading } = useQuery<
    GetCyclesData
  >(GetCycles, {
    onCompleted: data => {
      const finalizedCycles =
        data.participantAssessmentCycles?.filter(
          cycle => cycle.status === UserAssessmentCycleStatus.SCORE_FINALIZED
        ) || [];
      finalizedCycles?.sort(
        (a: NewCycle, b: NewCycle) => a.cycleNumber - b.cycleNumber
      );

      setCompletedCycles(finalizedCycles);

      setSelectedCycleIndex(
        (finalizedCycles && finalizedCycles?.length - 1) || 0
      );

      setUserAssessmentCycles(finalizedCycles);
    }
  });

  addBackgroundColorToRoot(color.INDEX_BG);

  useEffect(() => {
    return () => {
      removeBackgroundColorFromRoot();
    };
  }, []);

  useEffect(() => {
    if (
      selectedCycleIndex > -1 &&
      assessmentCycles &&
      assessmentCycles?.length > 0 &&
      hasAceEnabled
    ) {
      const currentCycle = assessmentCycles[selectedCycleIndex];
      const hasAceCompleted = currentCycle.aceCompleted;
      const hasCycleCompletedWithinTwoWeeks =
        differenceInDays(
          new Date(),
          new Date(assessmentCycles[selectedCycleIndex].cycleCompletionDate)
        ) <= 14;

      // We only show the ACE link when the following criteria are met:
      //
      // 1. User is enabled for ACE based on feature flags (DOD orgs do not have ACE)
      // 2. User has not completed ACE assessment.
      // 3. User has completed assessments within 14 days.
      setHasAceAccess(
        hasAceEnabled && !hasAceCompleted && hasCycleCompletedWithinTwoWeeks
      );
    }
  }, [selectedCycleIndex, assessmentCycles, hasAceEnabled]);

  if (factorInfoLoading || indexLoading || cycleLoading) {
    return <Loading />;
  }

  if (
    indexError ||
    cycleError ||
    !data ||
    !data.participantAssessmentCycles.length
  ) {
    return <></>;
  }

  const EmptyState: React.FC<{ cycleStatus: UserAssessmentCycleStatus }> = ({
    cycleStatus
  }) => {
    let body = -1;
    let summary = "";
    switch (cycleStatus) {
      case UserAssessmentCycleStatus.SCORING_IN_PROGRESS:
        summary = assessmentStatusFactor?.subFactors[scoringInProgress]
          ?.description
          ? assessmentStatusFactor?.subFactors[scoringInProgress]?.description
          : "";
        body = scoringInProgress;
        break;
      case UserAssessmentCycleStatus.READY_TO_SCORE:
        summary = assessmentStatusFactor?.subFactors[readyToScore]?.description
          ? assessmentStatusFactor?.subFactors[readyToScore]?.description
          : "";
        body = readyToScore;
        break;
      case UserAssessmentCycleStatus.READY_FOR_REVIEW:
        summary = assessmentStatusFactor?.subFactors[readyForReview]
          ?.description
          ? assessmentStatusFactor?.subFactors[readyForReview]?.description
          : "";
        body = readyForReview;
        break;
      case UserAssessmentCycleStatus.NOT_STARTED:
        summary = assessmentStatusFactor?.subFactors[notStarted]?.description
          ? assessmentStatusFactor?.subFactors[notStarted]?.description
          : "";
        body = notStarted;
        break;
      case UserAssessmentCycleStatus.IN_PROGRESS:
        summary = assessmentStatusFactor?.subFactors[inProgress]?.description
          ? assessmentStatusFactor?.subFactors[inProgress]?.description
          : "";
        body = inProgress;
        break;
      case UserAssessmentCycleStatus.ASSESSMENTS_NULL:
        summary = assessmentStatusFactor?.subFactors[assessmentsNull]
          ?.description
          ? assessmentStatusFactor?.subFactors[assessmentsNull]?.description
          : "";
        body = assessmentsNull;
        break;
    }

    const Title = styled.h1`
      margin: 0;
      color: ${color.BLACK};
      font-family: ${fontFamily.secondary};
      font-size: ${fontSize.large};
    `;

    return (
      <StyledGrid
        container
        item
        maxWidth="356px"
        margin="4rem auto"
        textAlign="center"
      >
        <StyledGrid item xs={12}>
          <GrayTree />
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Title>Your BrainHealth® Index</Title>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <p
            style={{
              fontWeight: "bold"
            }}
          >
            {summary}
          </p>
        </StyledGrid>
        <StyledGrid item xs={12}>
          {body > -1 ? (
            <RichText
              data={assessmentStatusFactor?.subFactors[body]?.longDescription}
            />
          ) : (
            ""
          )}
        </StyledGrid>
      </StyledGrid>
    );
  };

  return (
    <>
      {assessmentCycles && cycleStatus && (
        <StyledGrid container direction="column">
          <StyledGrid item>
            {assessmentCycles.length == 0 ? (
              <EmptyState
                cycleStatus={
                  cycleStatus || UserAssessmentCycleStatus.NOT_STARTED
                }
              />
            ) : (
              <>
                {selectedCycleIndex >= 0 && cycleStatus && (
                  <CycleSummary
                    participantAssessmentCycles={assessmentCycles}
                    selectedCycleIndex={selectedCycleIndex}
                    setSelectedCycleIndex={setSelectedCycleIndex}
                  />
                )}
              </>
            )}
          </StyledGrid>
          <StyledGrid
            alignSelf="center"
            item
            maxWidth={appWidth.max}
            xs={12}
            width="100%"
            padding={`0 ${spacingDefaults.xsmall}`}
          >
            {cycleStatus === UserAssessmentCycleStatus.SCORE_FINALIZED &&
              hasBrainGaugeAccess && <BrainGauge />}
            <PastSessions
              selectedCycleIndex={selectedCycleIndex}
              finalizedCycles={completedCycles ?? []}
            />
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default Index;
