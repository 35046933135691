import styled from "styled-components";
import { color } from "style/constants";

interface Props {
  color?: color;
  display?: string;
  fontSize?: string;
}

const StyledA = styled.a<Props>`
  cursor: pointer;
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  letter-spacing: -0.75px;
  line-height: 24px;
  text-decoration: underline;
  ${props => (props.color ? `color: ${props.color};` : "")}
  ${props => (props.display ? `display: ${props.display};` : "")}
`;

export default StyledA;
