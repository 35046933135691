import React from "react";
import styled from "styled-components";

import { ReactComponent as Logo } from "assets/animation_logo.svg";
import { color } from "style/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(${color.BLUEGRAY_RGBA});

  .gear-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 250px;
    width: 250px;
    transform-origin: 50% 50%;
    animation: rotate 4.5s linear infinite;
  }

  .animate {
    animation: dash 4s linear infinite;
  }

  .size0 {
    stroke-dasharray: 150;
    stroke-dashoffset: 150;
  }

  .size1 {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
  }
  .size2 {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
  }

  .size3 {
    stroke-dasharray: 420;
    stroke-dashoffset: 420;
  }

  .size4 {
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const Loading: React.FC = () => {
  return (
    <Container>
      <Logo />
    </Container>
  );
};

export default Loading;
