import React, { useEffect } from "react";
import { Box, useMediaQuery } from "@material-ui/core";

import { BarChart, StyledGrid } from "components/simple";
import {
  FactorInfo,
  PillarName,
  SelectedSubFactor
} from "components/pages/Index/CycleSummary/types";
import { InfoCardWrapper } from "../ScoreHistory/styled";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import FactorCardFooter from "./FactorCardFooter";
import { convertToTitleCase } from "./FactorCardHeader";
import RichText from "components/pages/Contentful/Unit/RichText";
import EmergingFactorImage from "assets/emerging-factor-image.svg";

interface FactorCardProps {
  selectedFactor: PillarName;
  selectedSubFactor: SelectedSubFactor;
  setSelectedSubFactor: React.Dispatch<React.SetStateAction<SelectedSubFactor>>;
  factorInfo: FactorInfo;
  subFactorScores: number[];
}

const FooterGroup: React.FC<Omit<FactorCardProps, "subFactorScores">> = ({
  selectedFactor,
  selectedSubFactor,
  setSelectedSubFactor,
  factorInfo
}) => {
  return (
    <StyledGrid container centerContent marginTop={spacingDefaults.large}>
      <FactorCardFooter
        selectedFactor={selectedFactor}
        selectedSubFactor={selectedSubFactor}
        setSelectedSubFactor={setSelectedSubFactor}
        subFactors={factorInfo.subFactors}
      />
      <Box
        fontFamily={fontFamily.primary}
        fontSize={fontSize.small}
        color={color.GRAPHITE}
        margin={`${spacingDefaults.small} 0`}
      >
        Toggle through the sub-factors <br />
        to see additional details
      </Box>
    </StyledGrid>
  );
};

const FactorCard: React.FC<FactorCardProps> = ({
  selectedFactor,
  selectedSubFactor,
  setSelectedSubFactor,
  factorInfo,
  subFactorScores
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  useEffect(() => {
    setSelectedSubFactor({ ...factorInfo.subFactors[0], index: 0 });
  }, [selectedFactor]);

  const title =
    selectedSubFactor.index === 0
      ? `${convertToTitleCase(selectedFactor)} Overview`
      : selectedSubFactor.name;

  const description =
    selectedSubFactor.index === 0
      ? factorInfo.description
      : selectedSubFactor.description;

  const labels = factorInfo.subFactors
    .filter(x => x.name !== "Overall")
    .map(x => x.name);
  return (
    <StyledGrid container>
      <StyledGrid item width="100%" textAlign="center">
        <InfoCardWrapper>
          <StyledGrid
            container
            justifyContent="space-between"
            height="100%"
            spacing={4}
          >
            <StyledGrid item xs={12} lg={6}>
              <StyledGrid
                className="subfactor-bar-graph"
                container
                centerContent
              >
                <Box
                  fontFamily={fontFamily.secondary}
                  fontSize={fontSize.large}
                >
                  {`${convertToTitleCase(selectedFactor)} Sub-Factors`}
                </Box>
                {selectedFactor === PillarName.EMERGING_FACTOR ? (
                  <StyledGrid item paddingTop="1.5rem">
                    <img
                      src={EmergingFactorImage}
                      height="197px"
                      width={"100%"}
                    />
                  </StyledGrid>
                ) : (
                  <BarChart
                    labels={labels}
                    scores={subFactorScores}
                    selectedFactor={selectedFactor}
                    selectedSubFactor={selectedSubFactor}
                  />
                )}
              </StyledGrid>
            </StyledGrid>
            {mobile && selectedFactor !== PillarName.EMERGING_FACTOR && (
              <FooterGroup
                factorInfo={factorInfo}
                selectedFactor={selectedFactor}
                selectedSubFactor={selectedSubFactor}
                setSelectedSubFactor={setSelectedSubFactor}
              />
            )}
            <StyledGrid item xs={12} lg={6}>
              <Box fontFamily={fontFamily.secondary} fontSize={fontSize.large}>
                {title}
              </Box>
              <Box
                color={color.GRAPHITE}
                margin={`${spacingDefaults.xsmall} 0`}
                textAlign="left"
                fontWeight="600"
              >
                {description}
              </Box>
              {selectedSubFactor.longDescription && (
                <StyledGrid
                  textAlign="left"
                  margin={`${spacingDefaults.small} 0`}
                >
                  <RichText
                    data={selectedSubFactor.longDescription}
                    textColor={color.GRAPHITE}
                  />
                </StyledGrid>
              )}
            </StyledGrid>
          </StyledGrid>
          {!mobile && selectedFactor !== PillarName.EMERGING_FACTOR && (
            <FooterGroup
              factorInfo={factorInfo}
              selectedFactor={selectedFactor}
              selectedSubFactor={selectedSubFactor}
              setSelectedSubFactor={setSelectedSubFactor}
            />
          )}
        </InfoCardWrapper>
      </StyledGrid>
    </StyledGrid>
  );
};

export default FactorCard;
