import React, { ChangeEvent, SetStateAction } from "react";
import { Link } from "react-router-dom";
import track from "react-tracking";

import { Title, Subtitle, Paragraph, BottomImage } from "./styled";
import { Button, MobileNav } from "components/simple";
import { StyledGrid } from "components/simple";
import { Hidden } from "@material-ui/core";

interface Props {
  setIs404: React.Dispatch<React.SetStateAction<boolean>>;
  isRefactorEnabled: boolean;
  isResourceEnabled: boolean;
  isTrainingEnabled: boolean;
}

const PageNotFound: React.FC<Props> = ({
  setIs404,
  isRefactorEnabled,
  isResourceEnabled,
  isTrainingEnabled
}: Props) => {
  setIs404(true);
  return (
    <>
      <StyledGrid
        alignItems="center"
        container
        direction="column"
        itemMargin="2rem"
        marginTop="4rem"
      >
        <StyledGrid item>
          <Title>404</Title>
        </StyledGrid>
        <StyledGrid item>
          <Subtitle>This page was not found</Subtitle>
        </StyledGrid>
        <StyledGrid item>
          <Paragraph>
            The page you’re looking for isn’t available. Try to search again or
            use the Go Back button below.
          </Paragraph>
        </StyledGrid>
        <StyledGrid item>
          <Link to="/">
            <Button>Go back</Button>
          </Link>
        </StyledGrid>
      </StyledGrid>
      <BottomImage />
      <Hidden lgUp>
        <MobileNav
          isRefactorEnabled={isRefactorEnabled}
          isResourceEnabled={isResourceEnabled}
          isTrainingEnabled={isTrainingEnabled}
          mobileTab={0}
          setMobileTab={function (): void {}}
          handleMobileNavChange={function (_event: ChangeEvent<{}>): void {}}
        />
      </Hidden>
    </>
  );
};

export default track({
  url: "/404"
})(PageNotFound);
