import React from "react";
import { StyledGrid, StyledP } from "components/simple";
import { ReactComponent as CoachingCallComputer } from "assets/schedule-session-computer.svg";
import { ReactComponent as ImagingSession } from "assets/imaging-session-image.svg";
import { color, fontSize, spacingDefaults } from "style/constants";

import BannerBackground from "assets/schedule-banner-bg.png";

interface Props {
  appointmentType: string;
}

const SchedulingBanner: React.FC<Props> = ({ appointmentType }) => {
  return (
    <StyledGrid
      container
      direction="column"
      alignItems="center"
      backgroundImage={BannerBackground}
      backgroundPosition="center bottom"
      padding={spacingDefaults.large}
    >
      <StyledP
        fontSize={fontSize.large}
        color={color.BLACK}
        marginbottom={spacingDefaults.large}
      >
        {appointmentType === "coaching"
          ? "Schedule a Coaching Call"
          : "Schedule an Imaging Session"}
      </StyledP>
      {appointmentType === "coaching" ? (
        <CoachingCallComputer />
      ) : (
        <ImagingSession />
      )}
    </StyledGrid>
  );
};

export default SchedulingBanner;
