import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import Logo from "assets/brain-gauge-schedule.svg";
import { StyledGrid } from "components/simple";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { BrainGaugeData } from "../Dashboard/BrainGauge";

const PageTwo: React.FC<BrainGaugeData> = ({
  accessCode,
  siteCoordinator,
  userLocation
}: BrainGaugeData) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      padding={spacingDefaults.xlarge}
    >
      <img
        alt="Brain Gauge Schedule Logo"
        src={Logo}
        width={mobile ? "90%" : undefined}
      />

      <Box
        fontFamily={fontFamily.secondary}
        fontSize={mobile ? fontSize.large : fontSize.xlarge}
        letterSpacing="-0.45px"
        margin={`${spacingDefaults.large} 0`}
        textAlign="center"
      >
        What do I do?
      </Box>

      <Box
        fontSize={mobile ? fontSize.small : fontSize.normal}
        lineHeight="180%"
        maxWidth="620px"
        textAlign="center"
      >
        Each session is approximately 15 minutes. Take a Brain Gauge assessment
        every three months in conjunction with the BrainHealth Index.
        <br />
        <br />
        When possible, you may take the assessment weekly. The additional data
        will help researchers gain insights into the best approach for long-term
        delivery of cognitive training within the DoD.
        <br />
        <br />
        To find a Brain Gauge sensor, please contact your site coordinator.
        Brain Gauge is compatible with non-government computers and with tablets
        that can be provided with the gauge, as needed.
        <br />
        <br />
        Use the following Access code{" "}
        <Box component="span" fontWeight="bold">
          {accessCode}
        </Box>{" "}
        .
        <br />
        {siteCoordinator && (
          <>
            <StyledGrid item marginTop={spacingDefaults.small}>
              <Box fontWeight="bold">
                Site coordinator{userLocation && ` for ${userLocation}`}:
              </Box>
              <address>
                <Box>{siteCoordinator.name}</Box>
                <Box>{siteCoordinator.email}</Box>
                <Box>{siteCoordinator.phone}</Box>
              </address>
            </StyledGrid>
          </>
        )}
      </Box>
    </StyledGrid>
  );
};

export default PageTwo;
