import { Box } from "@material-ui/core";
import { Button, StyledGrid, StyledP } from "components/simple";
import { format, parseISO } from "date-fns";
import React from "react";
import { fontSize, spacingDefaults } from "style/constants";

interface FormattedTime {
  time: Date;
  formattedTime: string;
}

interface Props {
  timeOfDay: string;
  selectedTime: Date | undefined;
  times: FormattedTime[];
  onClick: (time: Date) => void;
}

const TimeSlots: React.FC<Props> = ({
  timeOfDay,
  selectedTime,
  times,
  onClick
}) => {
  // If no times available during that period of the day, then this
  // component will not render
  if (times.length === 0) return <></>;

  return (
    <Box marginBottom={spacingDefaults.large}>
      <StyledP fontSize={fontSize.small} margin="5px 0">
        {timeOfDay}
      </StyledP>
      <StyledGrid container spacing={3}>
        {times.map((t: FormattedTime, idx: number) => (
          <StyledGrid item sm={3} key={idx}>
            <Button
              style={{
                fontSize: "14px",
                paddingTop: "5px",
                paddingBottom: "5px"
              }}
              longText
              squared
              inverted={t.time !== selectedTime}
              onClick={() => onClick(t.time)}
            >
              {format(parseISO(t.time.toString()), "h:mmaa")}
            </Button>
          </StyledGrid>
        ))}
      </StyledGrid>
    </Box>
  );
};

export default TimeSlots;
