import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";

import ClarityTree from "assets/clarity-tree.svg";
import ConnectednessTree from "assets/connectedness-tree.svg";
import EmotionalBalanceTree from "assets/emotional-balance-tree.svg";
import EmergingFactorTree from "assets/emerging-factor-tree.svg";
import { StyledGrid } from "components/simple";
import { PillarName } from "components/pages/Index/CycleSummary/types";
import { InfoCardWrapper } from "../ScoreHistory/styled";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { getFactorColor } from "../BHIChart/radar";

interface FactorCardHeaderProps {
  score: number;
  selectedFactor: PillarName;
}

export const convertToTitleCase = (wordToConvert: PillarName) => {
  const result = wordToConvert.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getFactorTree = (factor?: PillarName) => {
  switch (factor) {
    case PillarName.CLARITY:
      return ClarityTree;
    case PillarName.CONNECTEDNESS:
      return ConnectednessTree;
    case PillarName.EMOTIONAL_BALANCE:
      return EmotionalBalanceTree;
    case PillarName.EMERGING_FACTOR:
      return EmergingFactorTree;
  }
};

const FactorCardHeader: React.FC<FactorCardHeaderProps> = ({
  score,
  selectedFactor
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid container padding={`${spacingDefaults.normal} 0`}>
      <StyledGrid item width="100%" textAlign="center">
        <InfoCardWrapper>
          <StyledGrid
            container
            alignItems="center"
            justifyContent="center"
            style={{ gap: spacingDefaults.xlarge }}
          >
            <StyledGrid item>
              <img src={getFactorTree(selectedFactor)} height="100px" />
            </StyledGrid>
            <StyledGrid item>
              {selectedFactor === PillarName.EMERGING_FACTOR ? (
                <StyledGrid
                  container
                  centerContent
                  direction="column"
                  style={{ gap: spacingDefaults.small }}
                >
                  <Box
                    fontFamily={fontFamily.secondary}
                    fontSize={fontSize.large}
                    // fontWeight={500}
                  >
                    Emerging Factor
                  </Box>
                </StyledGrid>
              ) : (
                <StyledGrid
                  container
                  centerContent
                  direction="column"
                  style={{ gap: spacingDefaults.small }}
                >
                  <Box
                    fontFamily={fontFamily.secondary}
                    fontSize={fontSize.large}
                  >
                    {convertToTitleCase(selectedFactor)}
                  </Box>
                  <Box
                    fontFamily={fontFamily.primary}
                    fontSize={fontSize.header}
                    fontWeight="600"
                    color={getFactorColor(selectedFactor)}
                  >
                    {score}
                  </Box>
                </StyledGrid>
              )}
            </StyledGrid>
          </StyledGrid>
        </InfoCardWrapper>
      </StyledGrid>
    </StyledGrid>
  );
};

export default FactorCardHeader;
