import { ReactComponent as PreviousDayButtonEnabled } from "assets/chevron-left-roundbutton.svg";
import { ReactComponent as PreviousDayButtonDisabled } from "assets/chevron-left-roundbutton-disabled.svg";
import React from "react";
import { IconButton } from "@material-ui/core";

interface StreakPaginationProps {
  shouldDisable: boolean;
  onPreviousDayClick(): void;
}

const PreviousDayButton: React.FC<StreakPaginationProps> = ({
  shouldDisable,
  onPreviousDayClick
}: StreakPaginationProps) => {
  return (
    <>
      <IconButton
        aria-label="previous day"
        onClick={onPreviousDayClick}
        style={{ outline: "none" }}
        disabled={shouldDisable}
      >
        {shouldDisable && <PreviousDayButtonDisabled />}
        {!shouldDisable && <PreviousDayButtonEnabled />}
      </IconButton>
    </>
  );
};

export default PreviousDayButton;
