import { useApolloClient } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";

import { GetTalentLmsLink } from "graphql/training/training.gql";
import { showErrorNotification } from "state";

export const useTlmsLink = (): {
  connectToTlms(tlmsCourseId?: string): Promise<void>;
} => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const connectToTlms = async (tlmsCourseId?: string): Promise<void> => {
    const response = await client.query({
      query: GetTalentLmsLink,
      variables: { tlmsCourseId }
    });

    if (response.data?.training?.tlmsLink) {
      window.location.assign(response.data.training.tlmsLink);
    } else {
      dispatch(
        showErrorNotification("", "Unable to connect you to Talent LMS.")
      );
    }
  };

  return { connectToTlms };
};
