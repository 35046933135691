import React from "react";
import { useHistory } from "react-router";
import { Box } from "@material-ui/core";
import DownArrow from "@material-ui/icons/KeyboardArrowDown";

import FooterImage from "assets/my-journey-footer.svg";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";
import { formatWeekRange } from "../utils";
import { MyJourneyProps } from "..";
import { isAfter, isSameWeek, isToday, addWeeks } from "date-fns";

const MyJourneyFooter: React.FC<MyJourneyProps> = ({
  periodStart,
  periodEnd,
  setPeriodStart,
  setPeriodEnd
}) => {
  const history = useHistory();
  const nextWeekFormatted = formatWeekRange(
    addWeeks(periodStart, 1),
    addWeeks(periodEnd, 1)
  );
  const isNextWeekFuture = isAfter(periodEnd, new Date()) || isToday(periodEnd);
  const isThisWeek = isSameWeek(new Date(), addWeeks(periodStart, 1), {
    weekStartsOn: 1
  });

  function handleNextWeekClick() {
    if (isNextWeekFuture) {
      history.push("/dashboard");
    } else {
      setPeriodStart(addWeeks(periodStart, 1));
      setPeriodEnd(addWeeks(periodEnd, 1));
    }
  }

  return (
    <StyledGrid
      alignItems="center"
      backgroundImage={FooterImage}
      bottom="0"
      centerContent
      container
      minHeight="200px"
      position="fixed"
    >
      <StyledGrid item textAlign="center">
        <StyledGrid
          alignItems="center"
          backgroundColor={color.WHITE}
          borderRadius="50%"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
          centerContent
          className="back-to-work"
          container
          height="30px"
          margin={`${spacingDefaults.xsmall} auto`}
          onClick={handleNextWeekClick}
          style={{ cursor: "pointer" }}
          width="30px"
        >
          <DownArrow />
        </StyledGrid>
        <Box fontSize={fontSize.normal} fontFamily={fontFamily.primary}>
          {isNextWeekFuture
            ? "Today's BrainHealthy Plan"
            : isThisWeek
            ? `This Week | ${nextWeekFormatted}`
            : `${nextWeekFormatted}`}
        </Box>
      </StyledGrid>
    </StyledGrid>
  );
};
export default MyJourneyFooter;
