import React from "react";
import { BackButton, ContentCarousel, StyledGrid } from "components/simple";
import PageOne from "./PageOne";
import PageTwo from "./PageTwo";
import PageThree from "./PageThree";
import { GetUserBrainGaugeData } from "graphql/user/user.gql";
import { useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { getUser } from "state";

const BrainGauge: React.FC = () => {
  const user = useSelector(getUser);
  const { data } = useQuery(GetUserBrainGaugeData, {
    variables: { input: { bhpUserId: user?.id } },
    onError: (e: any) => console.log(e)
  });
  const brainGaugeData = data?.retrieveBrainGaugeDataForUser;

  const content = [
    {
      component: <PageOne />,
      buttonText: "Continue",
      navigationArrows: false
    },
    {
      component: <PageTwo {...brainGaugeData} />,
      buttonText: "Continue",
      navigationArrows: false
    },
    {
      component: <PageThree />,
      buttonText: "Back to Dashboard",
      link: "/dashboard",
      navigationArrows: false
    }
  ];

  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      fillRemainingHeight
      justifyContent="center"
    >
      <BackButton />
      <ContentCarousel content={content} />
    </StyledGrid>
  );
};

export default BrainGauge;
