import { Hidden, useMediaQuery } from "@material-ui/core";
import React from "react";

import { ReactComponent as TrainingIcon } from "assets/training-logo.svg";
import { StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const AwaitInstructionsBanner: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      backgroundColor={color.DARKBLUE}
      borderRadius="8px"
      container
      height={mobile ? "130px" : "96px"}
    >
      <Hidden mdDown>
        <StyledGrid
          height="96px"
          item
          marginLeft={spacingDefaults.large}
          marginRight={spacingDefaults.large}
          marginTop="-15px"
        >
          <TrainingIcon height={130} />
        </StyledGrid>

        <StyledGrid
          justifyContent="center"
          container
          direction="column"
          item
          xs
        >
          <StyledGrid marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.WHITE}
              fontFamily={fontFamily.secondary}
              fontSize="26px"
            >
              You&apos;re <strong>almost</strong> ready to begin training!
            </StyledP>
          </StyledGrid>
          <StyledP color={color.WHITE} fontSize={fontSize.medium}>
            You must wait for instructions from your coach before you start
            training
          </StyledP>
        </StyledGrid>
      </Hidden>

      <Hidden lgUp>
        <StyledGrid item position="relative" xs>
          <StyledGrid
            alignItems="center"
            container
            height="100%"
            position="absolute"
          >
            <TrainingIcon height={90} />
          </StyledGrid>
        </StyledGrid>

        <StyledGrid
          justifyContent="center"
          container
          direction="column"
          item
          xs
        >
          <StyledGrid marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.WHITE}
              fontFamily={fontFamily.secondary}
              fontSize="20px"
            >
              <strong>Almost ready!</strong>
            </StyledP>
          </StyledGrid>
          <StyledP color={color.WHITE} lineHeight="20px">
            You must wait for instructions from your coach before starting
          </StyledP>
        </StyledGrid>
      </Hidden>
    </StyledGrid>
  );
};

export default AwaitInstructionsBanner;
