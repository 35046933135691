import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { color } from "style/constants";

interface TimerProps {
  seconds: number;
  paused?: boolean;
  onDone: React.Dispatch<boolean>;
  onPause?: React.Dispatch<boolean>;
  activeQuestion?: number;
  hidden?: boolean;
}

interface Time {
  h?: number;
  m?: number;
  s?: number;
  ms?: number;
  showMilliseconds?: boolean;
  done?: boolean;
  hidden?: boolean;
}

function getTime(
  seconds: number,
  showMilliseconds: boolean,
  hidden: boolean | undefined
): Time {
  const time: Time = {
    h: 0,
    m: 0,
    s: 0,
    ms: 0,
    showMilliseconds,
    done: false,
    hidden
  };
  if (seconds <= 0) {
    time.done = true;
    return time;
  }

  time.h = Math.floor(seconds / (60 * 60));
  time.m = Math.floor((seconds % (60 * 60)) / 60);
  time.s = (Math.floor(seconds) % (60 * 60)) % 60;
  time.ms = Math.floor((seconds * 1000) % 1000);

  return time;
}

function numToString(num: number): string {
  let str = "" + num;
  if (num < 10) {
    str = "0" + str;
  }
  return str;
}

const StyledTime = styled(
  ({ h, m, s, ms, done, showMilliseconds, hidden, ...otherProps }) => {
    return (
      <>
        {!hidden && (
          <p {...otherProps}>
            {numToString(h)}:{numToString(m)}:{numToString(s)}
            {showMilliseconds && `.${ms}`}
          </p>
        )}
      </>
    );
  }
)`
  && {
    color: ${props => (props.done ? color.RED : color.DARKBLUE)};
  }
` as React.FC<Time>;

const Timer: React.FC<TimerProps> = ({
  seconds,
  paused,
  onDone,
  activeQuestion,
  hidden
}: TimerProps) => {
  const [secondsLeft, setSecondsLeft] = useState(seconds);
  const showMilliseconds = !Number.isInteger(seconds);

  // reset timer when props are the same between questions
  useEffect(() => {
    setSecondsLeft(seconds);
    onDone(false);
  }, [activeQuestion]); // eslint-disable-line

  useEffect(() => {
    const timer: number = setInterval(() => {
      if (secondsLeft > 0 && !paused) {
        setSecondsLeft(secondsLeft => secondsLeft - 0.1);
      }
    }, 100);

    return () => clearInterval(timer);
  }, [onDone, secondsLeft]);

  const time = getTime(secondsLeft, showMilliseconds, hidden);
  if (time.done) {
    onDone(true);
  }

  return <StyledTime {...time} />;
};

export default Timer;
