import React, { useState } from "react";
import track from "react-tracking";
import { useMediaQuery } from "@material-ui/core";

import {
  HabitsBanner,
  InactiveBanner,
  NotConsentedBanner,
  PageTitle,
  StyledGrid,
  WaitlistBanner,
  WidgetTitle
} from "components/simple";
import { GetTraining, GetTeaserTraining } from "graphql/training/training.gql";
import { useQueryCustom, useUserStatus } from "hooks";
import { UserStatus, getUser } from "state";
import { spacingDefaults, breakpoints, appWidth } from "style/constants";
import routesConfig from "utils/routesConfig";
import AwaitInstructionsBanner from "./AwaitInstructionsBanner";
//import BrainHqLink from "./BrainHqLink";
import CoursesModal from "./CoursesModal";
import CourseStatus from "./CourseStatus";
import TrainingComplete from "./TrainingComplete";
import TrainingStatus from "./TrainingStatus";
import CourseBanner from "./CourseBanner";
import DietIDButton from "./DietIdLink";
import { GetOrganizationTeaserInfo, Me } from "graphql/user/user.gql";
import { useQuery } from "@apollo/react-hooks";
import { UserData } from "models/user";

const url: string = routesConfig.training.path;

const Training: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [showCoursesModal, setShowCoursesModal] = useState<boolean>(false);

  const userStatus = useUserStatus();
  const { data: user } = useQuery<UserData>(Me, {
    fetchPolicy: "no-cache"
  });
  const shouldDisable = userStatus !== UserStatus.QUALIFIED_AND_CONSENTED;

  const { data } = useQueryCustom({
    input: GetTraining
  });

  const { data: teaserTraining } = useQueryCustom({
    input: GetTeaserTraining
  });
  console.log("teaser: ", teaserTraining);

  const { data: requiredTeaser } = useQueryCustom({
    input: GetOrganizationTeaserInfo
  });

  if (!data) return null;

  const isWaitlisted = data.training.waitListed;
  const isUnconsented = userStatus === UserStatus.QUALIFIED_AND_UNCONSENTED;
  const isInactive = userStatus === UserStatus.INACTIVE;

  const hasTlmsAccess = data.training.tlmsUserId !== null;
  const hasBhqAccess = data.training.bhqUserId !== null;
  const hasDietidAccess = data.training.dietidAccessCode !== null;

  const dietidAccessCode = data.training.dietidAccessCode;
  const trainingProgress = data.training.trainingProgress;

  const teaserTrainingProgress = teaserTraining?.teaserTrainingProgress;
  console.log("teaser: ", teaserTraining);
  const hasCompletedTraining = trainingProgress
    ? trainingProgress.completedCourses === trainingProgress.totalCourses
    : false;

  const currentCourseProgress = trainingProgress
    ? trainingProgress.currentCourseProgress
    : null;

  const overallCourseProgress = trainingProgress
    ? trainingProgress.overallCourseProgress
    : null;

  const hasCompletedTeaserTraining = user?.me?.completedTeaser;

  const currentTeaserCourseProgress = teaserTrainingProgress
    ? teaserTrainingProgress.currentTeaserCourseProgress
    : null;

  const overallTeaserCourseProgress = trainingProgress
    ? teaserTrainingProgress?.overallTeaserCourseProgress
    : null;

  function getCurrentBanner(): JSX.Element | null {
    if (isWaitlisted) {
      return (
        <StyledGrid item marginBottom={spacingDefaults.large} xs={12}>
          <WaitlistBanner showButton />
        </StyledGrid>
      );
    }

    if (isUnconsented) {
      return (
        <StyledGrid item marginBottom={spacingDefaults.large} xs={12}>
          <NotConsentedBanner />
        </StyledGrid>
      );
    }

    if (isInactive) {
      return (
        <StyledGrid item marginBottom={spacingDefaults.large} xs={12}>
          <InactiveBanner showButton />
        </StyledGrid>
      );
    }

    if (!hasTlmsAccess && !hasBhqAccess) {
      return (
        <StyledGrid item marginBottom={spacingDefaults.large} xs={12}>
          <AwaitInstructionsBanner />
        </StyledGrid>
      );
    }

    return null;
  }

  return (
    <>
      <HabitsBanner />

      <StyledGrid
        alignSelf="center"
        container
        maxWidth={appWidth.max}
        mobilePadding={spacingDefaults.normal}
        spacing={3}
      >
        <StyledGrid item mobileMarginBottom={spacingDefaults.large} xs={12}>
          <PageTitle>Welcome to Your BrainHealth® Training!</PageTitle>
        </StyledGrid>

        {getCurrentBanner()}

        <StyledGrid container item spacing={3}>
          {requiredTeaser && !hasCompletedTeaserTraining && (
            <StyledGrid item marginBottom={spacingDefaults.large} xs={12}>
              <CourseBanner
                courseProgress={currentTeaserCourseProgress} //need progress for training courses
                disabled={shouldDisable}
              />
            </StyledGrid>
          )}

          {(!requiredTeaser ||
            (requiredTeaser && hasCompletedTeaserTraining)) &&
            !hasCompletedTraining &&
            currentCourseProgress && (
              <StyledGrid item marginBottom={spacingDefaults.large} xs={12}>
                <CourseBanner
                  courseProgress={currentCourseProgress}
                  disabled={shouldDisable}
                />
              </StyledGrid>
            )}

          {hasCompletedTraining ? (
            <StyledGrid item xs={12}>
              <TrainingComplete
                handleViewAllCourses={() => setShowCoursesModal(true)}
                disabled={!hasTlmsAccess}
              />
            </StyledGrid>
          ) : (
            <>
              <StyledGrid item lg={6} xs={12}>
                <TrainingStatus
                  trainingProgress={trainingProgress}
                  teaserTrainingProgress={teaserTrainingProgress}
                  isActive={!shouldDisable && hasTlmsAccess}
                />
              </StyledGrid>

              <StyledGrid item lg={6} xs={12}>
                <CourseStatus
                  courseProgress={
                    teaserTraining
                      ? currentTeaserCourseProgress
                      : currentCourseProgress
                  }
                  handleViewAllCourses={() => setShowCoursesModal(true)}
                  disabled={shouldDisable}
                  isActive={!shouldDisable && hasTlmsAccess}
                />
              </StyledGrid>
            </>
          )}
          {(hasBhqAccess || hasDietidAccess) && (
            <StyledGrid container item direction={mobile ? "row" : "column"}>
              <StyledGrid item>
                <WidgetTitle>More Brain Practice</WidgetTitle>
              </StyledGrid>
              {/* {hasBhqAccess && (
                <StyledGrid item xs={12} lg>
                  <BrainHqLink disabled={shouldDisable} />
                </StyledGrid>
              )} */}
              {hasDietidAccess && (
                <StyledGrid item xs={12} lg>
                  <DietIDButton
                    disabled={shouldDisable}
                    accessCode={dietidAccessCode}
                  />
                </StyledGrid>
              )}
            </StyledGrid>
          )}
        </StyledGrid>

        {(overallCourseProgress || overallTeaserCourseProgress) && (
          <CoursesModal
            show={showCoursesModal}
            onClose={() => setShowCoursesModal(false)}
            courses={
              teaserTraining
                ? overallTeaserCourseProgress
                : overallCourseProgress
            }
          />
        )}
      </StyledGrid>
    </>
  );
};

export default track({
  url
})(Training);
