import { parse } from "qs";
import { GetOAuthLink } from "graphql/social/linkedIn";
import { ApolloClient } from "apollo-boost";

export const handleLinkedInClick = async (
  client: ApolloClient<object>,
  badgeImageKey: string,
  referralLink: string
): Promise<void> => {
  const response = await client.query({ query: GetOAuthLink });
  const encodedUri = encodeURI(referralLink);

  if (response.data?.linkedInOAuthUrl) {
    const params = parse(response.data.linkedInOAuthUrl, {
      ignoreQueryPrefix: true
    });

    const state = params.state;

    sessionStorage.setItem(
      state,
      JSON.stringify({
        action: "shareBadge",
        data: {
          platform: "linkedIn",
          badgeImageKey: badgeImageKey.split("?")[0],
          referralLink: encodedUri
        }
      })
    );

    window.location.assign(response.data.linkedInOAuthUrl);
  } else {
    throw new Error("Unable to connect you to LinkedIn");
  }
};

export const handleFacebookClick = async (
  referralLink: string
): Promise<void> => {
  const encodedUri = encodeURI(referralLink);
  const path = `https://www.facebook.com/sharer/sharer.php?u=${encodedUri}&amp;src=sdkpreparse`;
  const features = "width=800, height=500, left=300, top=200";
  window.open(path, "", features);
};

export const handleTwitterClick = async (
  referralLink: string
): Promise<void> => {
  const encodedUri = encodeURI(referralLink);
  const path = `https://twitter.com/intent/tweet?text=I%27m%20proactive%20about%20my%20brain%27s%20health%20and%20performance%20--%20you%20can%20be%20too%2C%20by%20joining%20The%20BrainHealth%20Project!&url=${encodedUri}`;
  const features = "width=800, height=500, left=300, top=200";
  window.open(path, "", features);
};
