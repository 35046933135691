import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import merge from "lodash/merge";
import Joyride, {
  CallBackProps,
  EVENTS,
  Step as JoyrideStep
} from "react-joyride";

import { useProductTour } from "hooks";
import { breakpoints, color, fontFamily } from "style/constants";
import { SaveProductTour } from "graphql/productTours/productTours.gql";
import "./style.css";
import { useMediaQuery } from "@material-ui/core";

export type Step = JoyrideStep & {
  callback?: () => void;
  endTourOnClickThrough: boolean;
  hideTitleProgress: boolean;
};

export const writeStepProgressText = (
  currentStep: number,
  totalSteps: number
) => {
  const stepTitle = document.querySelector<HTMLElement>(
    ".react-joyride__tooltip h4"
  );
  if (stepTitle) {
    stepTitle.setAttribute("progress-text", `${currentStep} of ${totalSteps}`);
  }
};

const ProductTour: React.FC<{
  productTourName: string;
  run?: boolean;
  stepIndex?: number;
  setStepIndex?: React.Dispatch<React.SetStateAction<number>>;
  stepMetadata: Partial<Step>[];
  scrollOffset?: number;
}> = ({
  productTourName,
  run,
  stepIndex,
  setStepIndex,
  stepMetadata,
  scrollOffset
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  if (!scrollOffset) {
    // default scrollOffset to account for app header
    scrollOffset = mobile ? 400 : 100;
  }
  const styles = {
    options: {
      backgroundColor: color.WHITE,
      fontFamily: fontFamily.primary,
      primaryColor: color.DARKBLUE,
      textColor: color.LIGHTGRAY,
      zIndex: 1301
    }
  };

  const { productTour } = useProductTour(productTourName);
  const [steps, setSteps] = useState<Step[]>([]);
  const [saveProductTour] = useMutation(SaveProductTour);

  useEffect(() => {
    if (productTour) {
      const stepContent: Partial<Step>[] = productTour.steps.map(step => {
        return {
          content: step.description,
          disableBeacon: true,
          title: step.title
        };
      });
      setSteps(merge(stepContent, stepMetadata) as Step[]);
    }
  }, [productTour]);

  if (productTour) {
    const callback = (data: CallBackProps) => {
      writeStepProgressText(data.index + 1, steps.length);
      if (
        data.step.event &&
        [
          "my-journey-step-1",
          "index-toggle-factor-select",
          "index-switch-bhi-chart"
        ].includes(data.step.event)
      ) {
      } else if (data.type == EVENTS.STEP_AFTER) {
        if (setStepIndex) {
          setStepIndex(data.index + 1);
        }
        steps[data.index].callback?.();
      }
      if (
        data.type == EVENTS.TOUR_END ||
        (data.type == EVENTS.TOOLTIP &&
          (data.step as Step).endTourOnClickThrough)
      ) {
        saveProductTour({
          variables: { input: { name: productTourName } }
        });
      }
    };

    return (
      <Joyride
        callback={callback}
        continuous
        disableCloseOnEsc
        disableOverlayClose
        hideBackButton
        hideCloseButton
        locale={{
          last: "Done"
        }}
        run={run}
        scrollOffset={scrollOffset}
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        styles={styles}
      />
    );
  }
  return <></>;
};

export default ProductTour;
