import React from "react";
import { Slider, Grid, Typography, withStyles } from "@material-ui/core";
import { range } from "lodash";
import { SliderProps, Mark } from "@material-ui/core/Slider";

import { color, fontSize } from "style/constants";

const StyledText = withStyles({
  root: {
    color: color.DARKERGRAY,
    fontSize: fontSize.medium
  }
})(Typography);

const labelItemStyle = {
  padding: "8px 12px 14px 12px"
};

const getMarks = (max: number, min: number): Mark[] | true => {
  if (max < 50 && max % 4 !== 0) {
    return true;
  }
  const increment = Math.floor((max - min) / 4);

  return range(1, 4).map((value: number) => ({
    value: min + increment * value
  })) as Mark[];
};

const DesktopSlider: React.FC<SliderProps> = ({
  min = 0,
  max = 100,
  marks,
  ...props
}: SliderProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item style={labelItemStyle}>
        <StyledText> {min} </StyledText>
      </Grid>
      <Grid item lg md sm xs>
        <Slider
          valueLabelDisplay="on"
          {...props}
          min={min}
          max={max}
          marks={getMarks(max, min)}
        />
      </Grid>
      <Grid item style={labelItemStyle}>
        <StyledText> {max} </StyledText>
      </Grid>
    </Grid>
  );
};

export default DesktopSlider;
