import styled from "styled-components";

import { breakpoints, color, fontFamily } from "style/constants";

const PageTitle = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    margin-bottom: 0px;
    text-align: center;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 30px;
  font-weight: normal;
  line-height: 27px;
  margin: 3rem 0;
`;

export default PageTitle;
