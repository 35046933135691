import React from "react";

import { Heading, StyledGrid, StyledP } from "components/simple";
import { fontSize, spacingDefaults } from "style/constants";

interface ClinicianFeedbackProps {
  notes: string;
}

const ClinicianFeedback: React.FC<ClinicianFeedbackProps> = ({
  notes
}: ClinicianFeedbackProps) => (
  <StyledGrid padding={spacingDefaults.xlarge}>
    <Heading tag="h3" letterSpacing="-0.5px" lineHeight={fontSize.large}>
      Coach Feedback
    </Heading>

    {notes ? (
      <>
        <StyledP margin={`${spacingDefaults.normal} 0`}>{notes}</StyledP>
        <br />
        <StyledP margin={`${spacingDefaults.normal} 0`}>
          The BrainHealth Project Coaches
        </StyledP>
      </>
    ) : (
      <StyledP margin={`${spacingDefaults.normal} 0`}>
        Feedback coming soon.
      </StyledP>
    )}
  </StyledGrid>
);

export default ClinicianFeedback;
