import { useQuery } from "@apollo/react-hooks";
import React from "react";
import track from "react-tracking";

import { BackButton, PageTitle, StyledGrid } from "components/simple";
import { GetBadges } from "graphql/user/user.gql";
import { appWidth, spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import BadgeGroup from "./BadgeGroup";
import { UserData } from "models/user";
import { UserBadge } from "models/badge";

const url = routesConfig.profile.path;

const Badges: React.FC = () => {
  const { data } = useQuery<UserData>(GetBadges, { fetchPolicy: "no-cache" });

  const badges = data?.me.badges?.sort(
    (x: UserBadge, y: UserBadge) =>
      new Date(y.achievedAt).getTime() - new Date(x.achievedAt).getTime()
  );

  const referralLink = data?.me.referralLink || "";
  return (
    <>
      <BackButton location="/profile" />
      <StyledGrid
        container
        direction="column"
        alignSelf="center"
        maxWidth={appWidth.max}
        mobilePadding={`${spacingDefaults.xlarge} ${spacingDefaults.large}`}
      >
        <PageTitle>Your Badges</PageTitle>
        {badges && (
          <BadgeGroup
            userBadges={badges}
            groupTitle="Earned"
            referralLink={referralLink}
          />
        )}
      </StyledGrid>
    </>
  );
};

export default track({
  url
})(Badges);
