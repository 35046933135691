import { gql } from "apollo-boost";

export const GetUpcomingCoachingSessions = gql`
  query GetUpcomingCoachingSessions {
    upcomingCoachingSessionsAsParticipant {
      id
      sessionTime
      status
      acuityConfirmationNumber
    }
  }
`;

export const GetCoachingSession = gql`
  query GetCoachingSession($id: ID!) {
    coachingSession(id: $id) {
      id
      participant {
        id
        firstName
        lastName
        profileImageKey
        showFirstVideoCallInfo
      }
      sessionTime
      durationMinutes
    }
  }
`;

export const GetCoachingCalendarEvents = gql`
  query GetCoachingCalendarEvents($id: ID!) {
    coachingSession(id: $id) {
      id
      sessionTime
      googleCalendarLink
      icalFileDetails
      icsFileDetails
    }
  }
`;

export const GetImagingSession = gql`
  query GetImagingSession($id: ID!) {
    imagingSession(id: $id) {
      id
      status
      participant {
        id
      }
      description
      sessionTime
      acuityConfirmationNumber
    }
  }
`;

export const GetImagingCalendarEvents = gql`
  query GetImagingCalendarEvents($id: ID!) {
    imagingSession(id: $id) {
      id
      sessionTime
      googleCalendarLink
      icalFileDetails
      icsFileDetails
    }
  }
`;

export const GetVideoToken = gql`
  query GetVideoToken($id: ID!, $input: VideoTokenInput!) {
    coachingSession(id: $id) {
      id
      videoToken(input: $input) {
        token
        roomType
      }
    }
  }
`;

export const EnableVideoRecording = gql`
  mutation EnableVideoRecording($id: ID!) {
    enableVideoRecording(id: $id) {
      success
    }
  }
`;

export const DisableVideoRecording = gql`
  mutation DisableVideoRecording($id: ID!) {
    disableVideoRecording(id: $id) {
      success
    }
  }
`;

export const GetFirstAvailability = gql`
  query getFirstAvailability($input: AcuityGetFirstAvailabilityInput!) {
    getFirstAvailability(input: $input) {
      date
    }
  }
`;

export const GetAvailableTimeSlots = gql`
  query getAvailabilityForDate($input: AcuityAvailabilityForDateInput!) {
    getAvailabilityForDate(input: $input) {
      timeOfDay
      times {
        time
        formattedTime
      }
    }
  }
`;

export const GetAvailabilityForMonth = gql`
  query getAvailabilityForMonth($input: AcuityAvailabilityForMonthInput!) {
    getAvailabilityForMonth(input: $input) {
      date
    }
  }
`;

export const GetAppointmentDuration = gql`
  query getAppointmentDuration {
    getAppointmentDuration {
      duration
      name
    }
  }
`;

export const GetIsEligibleForCoachingSession = gql`
  query isEligibleForCoachingSession {
    isEligibleForCoachingSession
  }
`;

export const GetIsEligibleForImagingSession = gql`
  query isEligibleForImagingSession {
    isEligibleForImagingSession
  }
`;

export const ScheduleCoachingSession = gql`
  mutation createCoachingSessionAsParticipant(
    $input: CreateCoachingSessionInput!
  ) {
    createCoachingSessionAsParticipant(input: $input) {
      id
      resource
      sessionTime
      durationMinutes
    }
  }
`;

export const ScheduleImagingSession = gql`
  mutation createImagingSessionAsParticipant(
    $input: CreateImagingSessionInput!
  ) {
    createImagingSessionAsParticipant(input: $input) {
      id
      resource
      sessionTime
      durationMinutes
    }
  }
`;

export const RescheduleCoachingSession = gql`
  mutation rescheduleCoachingSessionAsParticipant(
    $input: RescheduleCoachingSessionInput!
  ) {
    rescheduleCoachingSessionAsParticipant(input: $input) {
      id
      resource
      sessionTime
      durationMinutes
    }
  }
`;

export const RescheduleImagingSession = gql`
  mutation rescheduleImagingSessionAsParticipant(
    $input: RescheduleImagingSessionInput!
  ) {
    rescheduleImagingSessionAsParticipant(input: $input) {
      id
      resource
      sessionTime
      durationMinutes
    }
  }
`;

export const CancelCoachingSession = gql`
  mutation cancelCoachingSessionAsParticipant(
    $input: CancelCoachingSessionInput!
  ) {
    cancelCoachingSessionAsParticipant(input: $input) {
      id
    }
  }
`;

export const CancelImagingSession = gql`
  mutation cancelImagingSessionAsParticipant(
    $input: CancelImagingSessionInput!
  ) {
    cancelImagingSessionAsParticipant(input: $input) {
      id
    }
  }
`;

export const LoginUserForCoachScheduling = gql`
  mutation loginUserForCoachScheduling($token: String!) {
    loginUserForCoachScheduling(token: $token) {
      token
    }
  }
`;
