import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Document } from "@contentful/rich-text-types";

import { color, fontSize, spacingDefaults } from "style/constants";
import { RadioCard, StyledGrid, TextField } from "components/simple";
import {
  SurveyAnswers,
  SurveyQuestion,
  SurveyType
} from "graphql/types/Contentful";
import RichText from "../RichText";

interface SurveyProps {
  surveyQuestion: SurveyQuestion;
  surveyInstructions?: Document;
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  setHasVideoEnded: Dispatch<SetStateAction<boolean>>;
  setHasSurveyBeenAnswered: Dispatch<SetStateAction<boolean>>;
  surveyAnswers: SurveyAnswers[];
  setSurveyAnswers: Dispatch<SetStateAction<SurveyAnswers[]>>;
}

const Survey: React.FC<SurveyProps> = ({
  surveyQuestion,
  surveyInstructions,
  setHasVideo,
  setHasVideoEnded,
  setHasSurveyBeenAnswered,
  surveyAnswers,
  setSurveyAnswers
}: SurveyProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");

  useEffect(() => {
    setHasSurveyBeenAnswered(false);
    setSelectedAnswer("");
    setAnswer("");
  }, [surveyQuestion]);

  const saveQuestion = (value: string) => {
    const previouslySavedSurveyAnswer = surveyAnswers.find(
      x => x.questionId === surveyQuestion.id
    );
    if (previouslySavedSurveyAnswer) {
      previouslySavedSurveyAnswer.answer = value;
      setSurveyAnswers(
        surveyAnswers.map(x =>
          x.questionId === previouslySavedSurveyAnswer?.questionId
            ? previouslySavedSurveyAnswer
            : x
        )
      );
    } else {
      setSurveyAnswers([
        ...surveyAnswers,
        {
          questionId: surveyQuestion.id,
          answer: value
        }
      ]);
    }
  };

  return (
    <StyledGrid container centerContent padding={`0 ${spacingDefaults.normal}`}>
      <StyledGrid
        maxWidth="500px"
        container
        margin={`${spacingDefaults.normal} 0`}
      >
        <StyledGrid>
          <StyledGrid>
            {surveyInstructions && (
              <RichText
                data={surveyInstructions}
                setHasVideoEnded={setHasVideoEnded}
                setHasVideo={setHasVideo}
              />
            )}
            <Box fontSize={fontSize.large}>{surveyQuestion.question}</Box>
          </StyledGrid>
          <StyledGrid margin={`${spacingDefaults.large} 0`}>
            <RichText
              data={surveyQuestion?.instructions}
              setHasVideoEnded={setHasVideoEnded}
              setHasVideo={setHasVideo}
            />
          </StyledGrid>
          {surveyQuestion.type === SurveyType.openEnded && (
            <TextField
              fullWidth
              multiline
              rows={8}
              variant={"outlined"}
              style={{ backgroundColor: color.WHITE }}
              placeholder="Add Response"
              onChange={({ currentTarget }) => {
                setAnswer(currentTarget.value);
                setHasSurveyBeenAnswered(!!currentTarget.value);
                saveQuestion(currentTarget.value);
              }}
              value={answer}
            />
          )}
          {surveyQuestion.type === SurveyType.multipleChoice && (
            <RadioCard
              column
              selections={surveyQuestion.possibleAnswers?.map(
                item => item.answer
              )}
              onChange={(_, value) => {
                setSelectedAnswer(value);
                setHasSurveyBeenAnswered(!!value);
                saveQuestion(value);
              }}
              value={selectedAnswer}
            />
          )}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Survey;
