import React, { useState } from "react";
import Confetti from "react-confetti";

const ConfettiComponent: React.FC = () => {
  const [animation, setAnimation] = useState(true);
  const wrapper = document.getElementById("root");
  const width = wrapper?.offsetWidth;
  const height = wrapper?.offsetHeight;

  if (!wrapper) return null;

  return (
    <Confetti
      style={{ pointerEvents: "none" }}
      width={width}
      height={height}
      numberOfPieces={animation ? 500 : 0}
      recycle={false}
      onConfettiComplete={confetti => {
        setAnimation(false);
        if (confetti) confetti.reset();
      }}
    />
  );
};

export default ConfettiComponent;
