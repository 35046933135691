import React from "react";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import styled from "styled-components";

import MediaIndicator from "../MediaIndicator";

import { StyledGrid, WheelIcons, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { TextOverflowP } from "./styled";
import { useMediaQuery } from "@material-ui/core";

interface ArticleCardProps {
  id: string;
  createdAt: string;
  callback?: any;
  coverImage?: {
    description: string;
    url: string;
  };
  hasVideo?: boolean;
  mediaLeft?: boolean;
  publicationDate?: string;
  pillars?: any;
  readingTime?: number;
  slug?: string;
  source?: string;
  summary?: string;
  target: string;
  title: string;
  url: string;
  completed: boolean;
}

const ArticleCardLink = styled(Link)`
  && {
    background-color: ${color.WHITE};
    cursor: pointer;
    display: block;
    transition: background-color 0.5s ease;
    &:hover {
      background-color: ${color.CARDGRAY};
    }
    &:focus {
      background-color: ${color.CARDGRAY};
    }
  }
`;

const ArticleCard: React.FC<ArticleCardProps> = ({
  id,
  createdAt,
  callback,
  coverImage,
  hasVideo = false,
  mediaLeft,
  publicationDate,
  pillars,
  readingTime,
  slug,
  source,
  summary,
  target,
  title,
  url,
  completed
}: ArticleCardProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      boxShadow="0px 4px 24px rgba(0, 0, 0, 0.05)"
      borderRadius="8px"
      backgroundColor={color.WHITE}
      overflow="hidden"
    >
      <ArticleCardLink
        to={{
          pathname: target === "internal" ? `/resources/${slug}/${id}` : url
        }}
        onClick={callback}
        target={target === "internal" ? "_self" : "_blank"}
        data-testid="article-link"
      >
        <StyledGrid container direction="row">
          {coverImage && (
            <StyledGrid
              item
              backgroundImage={coverImage.url + "?w=300&h=300"}
              height={mediaLeft ? "250px" : "140px"}
              title={coverImage.description}
              position="relative"
              width="100%"
              xs={mediaLeft ? 6 : 12}
            >
              <StyledGrid
                display="inline-block"
                position="absolute"
                right="10px"
                top="10px"
              >
                <MediaIndicator
                  readingTime={readingTime}
                  hasVideo={hasVideo}
                  createdAt={createdAt}
                  completed={completed}
                />
              </StyledGrid>
            </StyledGrid>
          )}
          <StyledGrid item xs={mediaLeft && coverImage ? 6 : 12}>
            <StyledGrid
              container
              direction="column"
              justifyContent="center"
              padding={spacingDefaults.medium}
            >
              <StyledGrid item>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.secondary}
                  fontSize={mobile ? "18px" : "20px"}
                  fontWeight="bold"
                  marginbottom={
                    mobile ? spacingDefaults.small : spacingDefaults.large
                  }
                  whiteSpace="break-spaces"
                  overflow="hidden"
                >
                  {title}
                </StyledP>
              </StyledGrid>
              {summary && (
                <StyledGrid item>
                  {mobile ? (
                    <TextOverflowP fontSize={fontSize.xsmall}>
                      {summary}
                    </TextOverflowP>
                  ) : (
                    <StyledP fontSize={fontSize.xsmall}>{summary}</StyledP>
                  )}
                </StyledGrid>
              )}
            </StyledGrid>
            <StyledGrid
              alignItems="center"
              container
              position="relative"
              borderTop={`1px solid ${color.CARDGRAY}`}
              padding={`
                ${spacingDefaults.small}
                ${spacingDefaults.medium}
              `}
              justifyContent="space-between"
            >
              {pillars && (
                <StyledGrid
                  position="absolute"
                  top="0"
                  marginTop="-16px"
                  right="0"
                >
                  <WheelIcons pillars={pillars} />
                </StyledGrid>
              )}
              {source && (
                <StyledGrid item>
                  <StyledP
                    color={color.BLACK}
                    fontSize={fontSize.xsmall}
                    lineHeight="16px"
                  >
                    {source}
                  </StyledP>
                </StyledGrid>
              )}
              {publicationDate && (
                <StyledGrid item>
                  <StyledP fontSize={fontSize.xsmall}>
                    <i>{format(new Date(publicationDate), "MMM d, yyyy")}</i>
                  </StyledP>
                </StyledGrid>
              )}
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </ArticleCardLink>
    </StyledGrid>
  );
};

export default ArticleCard;
