import { Box, IconButton, Modal, useMediaQuery } from "@material-ui/core";
import { addHours, format, isAfter } from "date-fns";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as CloseButton } from "assets/close.svg";
import { ReactComponent as ImagingSessionImage } from "assets/mrimachine.svg";
import { Avatar, Button, StyledGrid, StyledP } from "components/simple";
import Logo from "components/simple/Logo";
import { getUser, showErrorNotification, showSuccessNotification } from "state";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import { CoachingSessionStatus, ImagingSession } from "../../types";
import { useMutation } from "@apollo/react-hooks";
import { CancelImagingSession } from "graphql/sessions/sessions.gql";
import { useDispatch } from "react-redux";
import { CycleSummaryQuery } from "graphql/user/user.gql";

export interface ImagingSessionSummaryProps {
  index: number;
  imagingSession: ImagingSession;
  acuityConfirmationNumber: number;
}

const ImagingSessionSummary: React.FC<ImagingSessionSummaryProps> = ({
  index,
  imagingSession,
  acuityConfirmationNumber
}: ImagingSessionSummaryProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [cancelImagingSession] = useMutation(CancelImagingSession, {
    variables: { input: { acuityConfirmationNumber } },
    onCompleted: () => {
      dispatch(
        showSuccessNotification(
          "Success",
          "Successfully cancelled imaging appointment"
        )
      );
    },
    onError: (error: any) => {
      dispatch(
        showErrorNotification(
          "Unable to cancel imaging appointment",
          error?.graphQLErrors[0]?.message
        )
      );
    },
    refetchQueries: [{ query: CycleSummaryQuery }]
  });

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const user = useSelector(getUser);

  const isScheduled = imagingSession.status === CoachingSessionStatus.SCHEDULED;

  const sessionDate = new Date(imagingSession.sessionTime);

  const timezoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const showSummary = imagingSession.status === CoachingSessionStatus.COMPLETED;

  const summaryDate = format(
    new Date(imagingSession.updatedAt),
    "MMMM d, yyyy"
  );
  const summary = imagingSession.participantNotes;

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      container
      overflow="hidden"
    >
      {imagingSession.status === CoachingSessionStatus.MISSED ? (
        <StyledGrid container>
          <StyledGrid
            alignItems="center"
            backgroundColor={color.LIGHTERGRAY}
            container
            item
            padding={spacingDefaults.medium}
            style={{ gap: spacingDefaults.medium }}
            xs={12}
          >
            <ImagingSessionImage
              display="flex"
              height="100%"
              style={{ flex: "1" }}
            />
            <StyledGrid>
              <Box
                color={color.BLACK}
                component="p"
                fontSize={fontSize.medium}
                fontWeight="600"
                lineHeight={fontSize.large}
                margin="0"
              >
                Imaging Session
              </Box>
              <Box
                color={color.BLACK}
                component="p"
                fontWeight="400"
                lineHeight={fontSize.large}
                margin="0"
              >
                Skipped
              </Box>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      ) : (
        <StyledGrid>
          <StyledGrid
            alignItems="center"
            backgroundColor={color.LIGHTERBLUE}
            container
            item
            padding={spacingDefaults.medium}
            style={{ gap: spacingDefaults.medium }}
            xs={12}
          >
            <ImagingSessionImage
              display="flex"
              height="100%"
              style={{ flex: "1" }}
            />
            <StyledGrid item xs={9}>
              <Box
                color={color.BLACK}
                component="p"
                fontSize={fontSize.medium}
                fontWeight="600"
                lineHeight={fontSize.large}
                margin="0"
              >
                Imaging Session
              </Box>

              <Box
                color={color.LIGHTGRAY}
                component="p"
                fontSize={fontSize.small}
                margin="0"
              >
                {`${isScheduled ? "Scheduled for" : ""} ${format(
                  sessionDate,
                  "MMMM d, yyyy"
                )}`}
              </Box>
            </StyledGrid>
          </StyledGrid>

          <StyledGrid
            container
            item
            padding={mobile ? spacingDefaults.medium : spacingDefaults.xlarge}
            xs={12}
          >
            {isAfter(new Date(), addHours(sessionDate, 2)) ||
            imagingSession.status === CoachingSessionStatus.COMPLETED ? (
              <>
                <StyledGrid container item xs={12}>
                  <StyledGrid item width="45px">
                    <Logo styleType="coachingCall" height="5px" width="20px" />
                  </StyledGrid>

                  <StyledGrid item paddingTop="7px" xs>
                    <Box
                      color={color.BLACK}
                      component="p"
                      fontSize={fontSize.normal}
                      fontWeight="600"
                      lineHeight={fontSize.large}
                      margin="0"
                    >
                      Thank you for completing your imaging session!
                    </Box>

                    {showSummary && (
                      <>
                        <Box
                          color={color.LIGHTGRAY}
                          component="p"
                          fontSize={fontSize.small}
                          margin={`0 0 ${spacingDefaults.normal} 0`}
                        >
                          {summaryDate}
                        </Box>

                        <Box
                          color={color.BLACK}
                          component="p"
                          fontSize={fontSize.small}
                          lineHeight={fontSize.large}
                          margin="0"
                        >
                          {summary}
                        </Box>
                      </>
                    )}
                  </StyledGrid>

                  {!showSummary && (
                    <StyledGrid container item justifyContent="center" xs={12}>
                      <Box
                        component="p"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="25px"
                        maxWidth="250px"
                        textAlign="center"
                      >
                        <em>
                          You will receive an email when your imaging summary is
                          available.
                        </em>
                      </Box>
                    </StyledGrid>
                  )}

                  <StyledGrid
                    container
                    item
                    marginTop={spacingDefaults.large}
                    xs={12}
                  >
                    <StyledGrid item width="45px">
                      <Avatar
                        profileImageKey={user?.profileImageKey || undefined}
                        firstName={user?.firstName}
                        lastName={user?.lastName}
                      />
                    </StyledGrid>
                  </StyledGrid>
                </StyledGrid>
              </>
            ) : (
              <StyledGrid container item xs={12}>
                <StyledGrid item width="45px">
                  <Avatar
                    profileImageKey={user?.profileImageKey || undefined}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                  />
                </StyledGrid>

                <StyledGrid item paddingTop="7px" xs>
                  <Box
                    color={color.BLACK}
                    component="p"
                    fontSize={fontSize.normal}
                    fontWeight="600"
                    lineHeight={fontSize.large}
                    margin="0"
                  >
                    Your Imaging Session
                  </Box>
                </StyledGrid>

                <StyledGrid container item justifyContent="center" xs={12}>
                  <StyledGrid
                    alignItems="center"
                    backgroundColor={color.LIGHTERBLUE}
                    borderRadius="8px"
                    container
                    item
                    justifyContent="center"
                    marginTop={spacingDefaults.large}
                    padding={spacingDefaults.normal}
                  >
                    <Box
                      component="p"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="25px"
                      margin="0"
                      textAlign="center"
                    >
                      Your imaging session is scheduled for{" "}
                      {format(sessionDate, "MMMM d")} at{" "}
                      {format(sessionDate, "h:mm aa")} {timezoneAbbreviation}.
                      Please ensure that your assessments have been completed by
                      that time.
                    </Box>

                    <StyledGrid
                      container
                      spacing={1}
                      marginTop={spacingDefaults.normal}
                    >
                      <StyledGrid item md={6} xs={12}>
                        <Button
                          aria-label="cancel-imaging"
                          longText
                          fullWidth
                          onClick={() => setShowModal(true)}
                        >
                          Cancel Session
                        </Button>
                      </StyledGrid>
                      <StyledGrid item md={6} xs={12}>
                        <Link
                          to={`/scheduling/imaging?reschedule=${acuityConfirmationNumber}`}
                        >
                          <Button
                            aria-label="reschedule-imaging"
                            longText
                            fullWidth
                          >
                            Reschedule Session
                          </Button>
                        </Link>
                      </StyledGrid>
                    </StyledGrid>
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            )}
          </StyledGrid>
        </StyledGrid>
      )}
      <Modal open={showModal}>
        <>
          <StyledGrid
            container
            absoluteCenter
            backgroundColor={color.WHITE}
            borderRadius="8px"
            direction="column"
            maxWidth="500px"
            mobileMaxWidth="98%"
            padding={spacingDefaults.normal}
          >
            <StyledGrid item alignSelf="flex-end" position="fixed">
              <IconButton
                aria-label="close-modal"
                onClick={() => setShowModal(false)}
              >
                <CloseButton strokeWidth="1px" height="15px" width="15px" />
              </IconButton>
            </StyledGrid>
            <StyledGrid container item height="100%" width="100%">
              <StyledGrid item width="100%" textAlign="center">
                <StyledP
                  textAlign="center"
                  fontSize={fontSize.large}
                  fontWeight="500"
                  marginbottom={spacingDefaults.normal}
                >
                  Cancel Imaging Session
                </StyledP>
              </StyledGrid>
              <StyledGrid
                item
                width="100%"
                textAlign="center"
                marginBottom={spacingDefaults.normal}
              >
                <StyledGrid paddingBottom="16px">
                  <StyledP width="100%" textAlign="center">
                    Are you sure you want to cancel this imaging session?
                  </StyledP>
                </StyledGrid>
              </StyledGrid>
              <StyledGrid item width="100%" textAlign="center">
                <Button
                  aria-label="cancel-call"
                  fullWidth={mobile}
                  onClick={() => {
                    cancelImagingSession(), setShowModal(false);
                  }}
                >
                  Cancel Imaging Session
                </Button>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </>
      </Modal>
    </StyledGrid>
  );
};

export default ImagingSessionSummary;
