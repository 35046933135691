import React from "react";
import { StyledGrid, StyledP } from "components/simple";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import { useMediaQuery } from "@material-ui/core";

import BasicBadge from "./BasicBadge";
import FullBadge from "./FullBadge";
import { UserBadge } from "models/badge";

interface BadgeGroupProps {
  userBadges: UserBadge[] | null;
  groupTitle: string;
  referralLink: string;
}

const BadgeGroup: React.FC<BadgeGroupProps> = ({
  userBadges,
  groupTitle,
  referralLink
}: BadgeGroupProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      container
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      direction="column"
      padding="26px 44px"
      mobileMarginTop={spacingDefaults.large}
    >
      <StyledP
        color={color.BLACK}
        fontSize={fontSize.small}
        fontWeight="500"
        margin={`0 0 ${spacingDefaults.normal} 0`}
      >
        {groupTitle}
      </StyledP>
      <StyledGrid
        alignItems="stretch"
        justifyContent="flex-start"
        container
        direction="row"
        spacing={3}
        item
      >
        {userBadges &&
          userBadges.map((userBadge, index) => (
            <StyledGrid key={index} container item lg={6} xs={12}>
              {mobile ? (
                <BasicBadge
                  badgeImageKey={userBadge.badge.badgeImageKey}
                  displayName={userBadge.badge.displayName}
                  referralLink={referralLink}
                />
              ) : (
                <FullBadge
                  achievedAt={userBadge.achievedAt}
                  badgeImageKey={userBadge.badge.badgeImageKey}
                  displayName={userBadge.badge.displayName}
                  description={userBadge.badge.description}
                  backgroundColor={userBadge.badge.backgroundColor}
                  name={userBadge.badge.name}
                  userHabit={userBadge.badge.userHabit}
                  key={index}
                  referralLink={referralLink}
                />
              )}
            </StyledGrid>
          ))}
      </StyledGrid>
    </StyledGrid>
  );
};

export default BadgeGroup;
