import { gql } from "apollo-boost";

export const isFeatureEnabled = gql`
  query isFeatureEnabled($input: FeatureFlagInput!) {
    isFeatureEnabled(input: $input) {
      enabled
    }
  }
`;

export const isRefactorEnabled = gql`
  query isRefactorEnabled {
    isRefactorEnabled {
      enabled
    }
  }
`;

export const isMtec = gql`
  query isMtec($input: FeatureFlagInput!) {
    isMtec(input: $input) {
      enabled
    }
  }
`;

export const getOrgConfig = gql`
  query getOrgConfig {
    getOrgConfig {
      isResourcesEnabled
      isTrainingEnabled
    }
  }
`;
