import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { StyledGrid, WidgetTitle } from "components/simple";
import { spacingDefaults } from "style/constants";
import CoachingSessions from "./CoachingSessions";
import ImagingSessions from "./ImagingSessions";
import { GetSessions } from "graphql/index/index.gql";
import {
  AssessmentCycle,
  CoachingSession,
  CoachingSessionStatus,
  GetSessionsData,
  ImagingSession,
  NewCycle
} from "../CycleSummary/types";

interface PastSessionProps {
  selectedCycleIndex: number;
  finalizedCycles: NewCycle[];
}

const filterToOnlyPastSessions = (
  sessions: CoachingSession[] | ImagingSession[]
) => {
  // returns empty array if nothing found
  return sessions.filter(session => {
    const isAScheduledSession =
      session.status === CoachingSessionStatus.SCHEDULED;
    const isACanceledSessionInTheFuture =
      session.status === CoachingSessionStatus.CANCEL &&
      new Date(session.sessionTime) > new Date();

    return !isAScheduledSession && !isACanceledSessionInTheFuture;
  });
};

const PastSessions: React.FC<PastSessionProps> = ({
  selectedCycleIndex,
  finalizedCycles
}) => {
  const [allCycles, setAllCycles] = useState<AssessmentCycle[]>();
  const [coachingSessions, setCoachingSessions] = useState<CoachingSession[]>();
  const [imagingSessions, setImagingSessions] = useState<ImagingSession[]>();
  const [selectedCycleId, setSelectedCycleId] = useState<string>("");

  useQuery<GetSessionsData>(GetSessions, {
    onCompleted: data => {
      setAllCycles(data.participantAssessmentCycles);
    }
  });

  useEffect(() => {
    if (selectedCycleIndex >= 0 && finalizedCycles) {
      setSelectedCycleId(finalizedCycles[selectedCycleIndex]?.id);
    }
  }, [selectedCycleIndex, finalizedCycles]);

  useEffect(() => {
    if (selectedCycleId && allCycles) {
      const selectedCycle = allCycles.find(cycle => {
        return cycle.id === selectedCycleId;
      }) as AssessmentCycle;

      const coaching = filterToOnlyPastSessions(
        selectedCycle.coachingSessions
      ) as CoachingSession[];
      setCoachingSessions(coaching);

      const imaging = filterToOnlyPastSessions(selectedCycle.imagingSessions);
      setImagingSessions(imaging);
    }
  }, [selectedCycleId, allCycles]);

  useEffect(() => {
    if (selectedCycleIndex >= 0 && finalizedCycles) {
      setSelectedCycleId(finalizedCycles[selectedCycleIndex]?.id);
    }
  }, [selectedCycleIndex, finalizedCycles]);

  if (!selectedCycleId || !imagingSessions || !coachingSessions) {
    return <></>;
  }

  return (
    <>
      {imagingSessions && coachingSessions && (
        <StyledGrid container>
          {(imagingSessions.length > 0 || coachingSessions.length > 0) && (
            <StyledGrid item xs={12} margin={`${spacingDefaults.normal} 0`}>
              <WidgetTitle>Past Sessions</WidgetTitle>
            </StyledGrid>
          )}
          <StyledGrid item xs={12} container>
            <ImagingSessions imagingSessions={imagingSessions} />
            <CoachingSessions coachingSessions={coachingSessions} />
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default PastSessions;
