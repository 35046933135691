import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";

import {
  AssessmentCard,
  Button,
  StyledGrid,
  DropdownSelect
} from "components/simple";
import { breakpoints } from "style/constants";

interface Props {
  answer?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  callback: React.Dispatch<any>;
  choices: string[];
  dropdownDefault?: string;
  prompt: string;
}

const DropdownQuestion: React.FC<Props> = ({
  answer,
  callback,
  choices,
  dropdownDefault,
  prompt
}: Props) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const DropdownOptions = choices.map(text => {
    return { [text]: text };
  });
  const [response, setResponse] = useState(answer ? answer : "");

  useEffect(() => {
    setResponse(answer ? answer : "");
  }, [prompt]); // eslint-disable-line

  return (
    <AssessmentCard question={prompt}>
      <StyledGrid item width="100%" marginBottom="15.3125rem" marginTop="1rem">
        <DropdownSelect
          callback={setResponse}
          defaultText={dropdownDefault}
          options={DropdownOptions}
          value={response}
        />
      </StyledGrid>
      <StyledGrid item marginBottom="1.6875rem">
        <Button
          data-testid="next-btn"
          wide={!mobile}
          stickyFooter={mobile}
          fullWidth={mobile}
          disabled={response ? false : true}
          onClick={() => callback({ response })}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default DropdownQuestion;
