import React, { useState } from "react";
import { useHistory } from "react-router";
import { Box } from "@material-ui/core";

import coinIcon from "assets/coin-icon.svg";
import dayStreakIcon from "assets/day-streak-icon.svg";
import { appWidth, color, fontSize, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";
import { StyledCoin } from "components/pages/DashboardV2/TaskList/styled";
import UserStreakDrawer from "components/simple/UserStreakDrawer";

interface SubHeaderProps {
  dayStreak: number;
  userCoinAmount: number;
  streakDrawerOpen: boolean;
  setStreakDrawer: (isOpen: boolean) => void;
}

export const SUB_HEADER_HEIGHT = 35;

const SubHeader: React.FC<SubHeaderProps> = ({
  dayStreak,
  userCoinAmount,
  streakDrawerOpen,
  setStreakDrawer
}) => {
  const history = useHistory();
  const location = history.location.pathname;

  const pathsToShow = [
    "/dashboard",
    "/profile",
    "/resources",
    "/index",
    "/training",
    "/faq",
    "/myjourney"
  ];

  const shouldShow = pathsToShow.some(
    path => path === location || location.includes(path + "/")
  );

  function openStats() {
    setStreakDrawer(false);
    history.push("/stats");
  }

  return (
    <>
      {shouldShow && (
        <StyledGrid
          item
          height={`${SUB_HEADER_HEIGHT}px`}
          boxShadow="0px 2px 12px rgb(0 0 0 / 10%)"
          backgroundColor={color.WHITE}
        >
          <StyledGrid
            container
            height="100%"
            alignItems="center"
            margin="0 auto"
            maxWidth={appWidth.max}
            padding={`0 ${spacingDefaults.normal}`}
            justifyContent="space-between"
          >
            <UserStreakDrawer
              inputDate={new Date()}
              drawerOpen={streakDrawerOpen}
              setStreakDrawer={setStreakDrawer}
            />
            <StyledGrid
              item
              onClick={() => setStreakDrawer(true)}
              style={{ cursor: "pointer" }}
            >
              <StyledGrid container>
                <img
                  src={dayStreakIcon}
                  style={{ marginRight: spacingDefaults.xsmall }}
                />
                <Box fontSize={fontSize.small}>{`${dayStreak} Day Streak`}</Box>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid
              item
              onClick={() => openStats()}
              style={{ cursor: "pointer" }}
            >
              <StyledGrid container>
                <img
                  src={coinIcon}
                  style={{ marginRight: spacingDefaults.xsmall }}
                />
                <StyledCoin
                  clickable={false}
                  color="primary"
                  cursorPointer
                  label={`${userCoinAmount} coins`}
                  size="small"
                />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default SubHeader;
