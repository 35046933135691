import styled from "styled-components";
import { breakpoints } from "style/constants";

export const TabWrapper = styled.div`
  .MuiTabs-flexContainer {
    border-bottom: #dfdfdf 1px solid;
    .MuiTab-root {
      max-width: none;
      min-width: 0px;
      flex: 1 0 0;
      outline: #dfdfdf solid 1px;
      .MuiTab-wrapper {
        text-transform: none;
        font-weight: 500;
      }
    }
  }
`;

export const StyledTabContent = styled.div`
  .slide-item-content {
    font-family: Museo Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 20px 0px 0px 20px;
    white-space: normal;
  }
`;

export const StyledImage = styled.img`
  height: 350px;
  object-fit: contain;
  @media (max-width: ${breakpoints.lg}px) {
    height: 300px;
  }
  @media (max-width: ${breakpoints.md}px) {
    height: 210px;
  }
`;

export const StyledVideo = styled.video`
  height: 350px;
  display: block;
  margin: auto;
  @media (max-width: ${breakpoints.lg}px) {
    height: 300px;
    width: 100%;
  }
  @media (max-width: ${breakpoints.md}px) {
    height: 210px;
    width: 100%;
  }
`;
