import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Drawer as MUIDrawer, useMediaQuery, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { daysToWeeks, differenceInDays, subDays } from "date-fns";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Message from "assets/not_found.svg";
import {
  PushNotification,
  Heading,
  StyledGrid,
  Button
} from "components/simple";
import {
  GetPushNotifications,
  MarkPushNotificationAsAcknowledged,
  MarkPushNotificationAsDismissed
} from "graphql/pushNotifications/pushNotifications.gql";
import { PushNotification as IPushNotification } from "hooks/useNotifications";
import { showInfoNotification } from "state";
import {
  breakpoints,
  color,
  spacingDefaults,
  fontFamily,
  fontSize
} from "style/constants";

import { SUB_HEADER_HEIGHT } from "../SubHeader";

interface NotificationsDrawerProps {
  drawerOpen: boolean;
  pushNotifications: IPushNotification[];
  subHeader: boolean;
  setNotificationsDrawer: (isOpen: boolean) => void;
}
const NO_OF_NOTIFICATIONS_IN_A_PAGE = 10;
const NotificationsDrawer: React.FC<NotificationsDrawerProps> = ({
  drawerOpen,
  pushNotifications,
  subHeader,
  setNotificationsDrawer
}) => {
  const empty = pushNotifications.length == 0;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [markAsAcknowledged] = useMutation(MarkPushNotificationAsAcknowledged);
  const [markAsDismissed] = useMutation(MarkPushNotificationAsDismissed);
  const [page, setPage] = useState<number>(1);
  const [lastIndex, setLastIndex] = useState<number>(0);

  let top = mobile ? 65 : 80;
  if (subHeader) {
    top += SUB_HEADER_HEIGHT;
  }
  const useStyles = makeStyles({
    paper: {
      background: color.BLUEGRAY,
      padding: spacingDefaults.large,
      top,
      height: mobile ? "calc(100vh - 130px)" : "calc(100vh - 100px)",
      width: mobile ? "100%" : "400px"
    }
  });
  const classes = useStyles();

  const markNotificationAsDismissed = (notificationId: string) => {
    markAsDismissed({
      variables: { input: { pushNotificationId: notificationId } },
      refetchQueries: [{ query: GetPushNotifications }]
    });
    setLastIndex(NO_OF_NOTIFICATIONS_IN_A_PAGE * page);
  };

  const handleNewNotifications = (notifications: IPushNotification[]) => {
    if (notifications.length > 0) {
      let i = 0;
      const interval = setInterval(function () {
        handleNewNotification(notifications[i]);
        if (i++ >= notifications.length - 1) {
          clearInterval(interval);
        }
      }, 2000);
    }
  };

  const handleNewNotification = (notification: IPushNotification) => {
    if (!drawerOpen) {
      dispatch(showInfoNotification(notification.title, notification.body));
    }
    markAsAcknowledged({
      variables: { input: { pushNotificationId: notification.id } }
    });
  };

  const onPushNotificationClick = ({
    dismissed,
    id,
    redirectUrl
  }: IPushNotification) => {
    if (!dismissed) {
      markNotificationAsDismissed(id);
    }
    if (location.pathname !== redirectUrl) {
      history.push(redirectUrl);
    }
    setNotificationsDrawer(true);
  };

  const loadMoreNotifications = () => {
    setPage(page + 1);
    setLastIndex(NO_OF_NOTIFICATIONS_IN_A_PAGE * page);
  };

  const onPushNotificationClickDismissOnly = ({
    dismissed,
    id
  }: IPushNotification) => {
    if (!dismissed) {
      markNotificationAsDismissed(id);
    }
    setNotificationsDrawer(false);
  };

  const dismissAllNotifications = () => {
    for (const pn of pushNotifications) {
      onPushNotificationClickDismissOnly(pn);
    }
  };

  useEffect(() => {
    handleNewNotifications(pushNotifications.filter(n => !n.acknowledged));
    setLastIndex(NO_OF_NOTIFICATIONS_IN_A_PAGE * page);
  }, [pushNotifications, page, drawerOpen, !drawerOpen]);

  return (
    <MUIDrawer
      anchor="right"
      classes={{ paper: classes.paper }}
      onClose={() => setNotificationsDrawer(false)}
      open={drawerOpen}
      variant="temporary"
    >
      <Heading>Notifications</Heading>
      {drawerOpen && !empty && (
        <StyledGrid
          item
          onClick={dismissAllNotifications}
          style={{ cursor: "pointer" }}
          alignSelf="flex-end"
        >
          <Box fontSize={fontSize.small}>Dismiss All</Box>
        </StyledGrid>
      )}
      {drawerOpen &&
        pushNotifications
          .slice(0, lastIndex)
          .map((n, index) => (
            <PushNotification
              body={n.body}
              createdAt={convertDateToString(new Date(n.createdAt))}
              dismissed={n.dismissed}
              key={index}
              title={n.title}
              onClick={() => onPushNotificationClick(n)}
            />
          ))}
      {drawerOpen && !empty && lastIndex + 1 < pushNotifications.length && (
        <StyledGrid padding={spacingDefaults.medium} centerContent>
          <Button onClick={loadMoreNotifications}>Load More</Button>
        </StyledGrid>
      )}
      {drawerOpen && !empty && lastIndex + 1 >= pushNotifications.length && (
        <StyledGrid padding={spacingDefaults.medium} centerContent>
          You’re all caught up 🎉
        </StyledGrid>
      )}
      {drawerOpen && empty && (
        <StyledGrid
          container
          direction="column"
          padding={spacingDefaults.xlarge}
          marginTop={spacingDefaults.xxlarge}
        >
          <StyledGrid container justifyContent={"center"}>
            <img
              alt="No messages in your inbox"
              height={233}
              src={Message}
              width={300}
            />
          </StyledGrid>
          <StyledGrid centerContent>
            <StyledGrid
              style={{
                fontSize: spacingDefaults.large,
                fontFamily: fontFamily.secondary,
                lineHeight: "41px"
              }}
            >
              All caught up!
            </StyledGrid>
            <StyledGrid>
              <p
                style={{
                  color: color.LIGHTGRAY,
                  fontSize: spacingDefaults.normal,
                  fontFamily: fontFamily.primary,
                  lineHeight: "22px"
                }}
              >
                You have no messages in your inbox. Check back soon!
              </p>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      )}
    </MUIDrawer>
  );
};

export const convertDateToString = (date: Date): string => {
  // difference in days returns a negative value for days in the past
  // multiply by negative 1 to account for past dates
  const daysAgo = differenceInDays(date, new Date()) * -1;
  switch (true) {
    case daysAgo === 0:
      return "Today";
    case daysAgo === 1:
      return `${daysAgo} day ago`;
    case daysAgo < 7:
      return `${daysAgo} days ago`;
    case daysToWeeks(daysAgo) === 1:
      return `${daysToWeeks(daysAgo)} week ago`;
    default:
      return `${daysToWeeks(daysAgo)} weeks ago`;
  }
};

export default NotificationsDrawer;
