import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";

import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { Confetti, StyledGrid } from "components/simple";
import { TaskType } from "graphql/types/Contentful";

interface ThankYouPageProps {
  taskType?: TaskType;
  taskTitle?: string;
}

const ThankYouPage: React.FC<ThankYouPageProps> = ({
  taskType,
  taskTitle
}: ThankYouPageProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  return (
    <StyledGrid container centerContent padding={`0 ${spacingDefaults.normal}`}>
      <Confetti />
      <StyledGrid>
        <StyledGrid marginTop="30%" marginBottom={spacingDefaults.medium}>
          <CheckIcon fill={color.GREEN} height={120} width={120} />
        </StyledGrid>
        <StyledGrid margin={`${spacingDefaults.xsmall} 0`}>
          <Box
            color={color.BLACK}
            fontFamily={fontFamily.secondary}
            fontSize={fontSize.header}
          >
            <strong>
              {taskType === TaskType.quiz
                ? "Quiz Completed!"
                : "Survey Completed!"}
            </strong>
          </Box>
        </StyledGrid>
        <StyledGrid margin={`${spacingDefaults.normal} 0`}>
          <Box
            fontSize={fontSize.medium}
            lineHeight={fontSize.large}
            color={color.GRAPHITE}
            width={mobile ? "290px" : "420px"}
          >
            {taskType === TaskType.quiz
              ? `Congratulations on completing ${
                  taskTitle ? taskTitle : "this quiz"
                }! You're one-step closer to completing your training.`
              : `We appreciate you taking the time to provide feedback on your training.`}
          </Box>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ThankYouPage;
