import { Hidden, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as NoAccessImage } from "assets/no-access.svg";
import { Button, StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";

const NotConsentedBanner: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.WHITE}
      borderRadius="8px"
      container
      height="96px"
      mobilePadding="0"
      onClick={mobile ? () => history.push("/consent/form?page=1") : undefined}
      overflow={mobile ? "hidden" : undefined}
      padding="20px"
    >
      <Hidden mdDown>
        <StyledGrid item position="relative" width="275px">
          <StyledGrid position="absolute" transform="translateY(-50%)">
            <NoAccessImage />
          </StyledGrid>
        </StyledGrid>

        <StyledGrid item xs>
          <StyledGrid marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize="26px"
              letterSpacing="-0.5px"
            >
              You have <strong>opted out</strong> of the BrainHealth Project
            </StyledP>
          </StyledGrid>
          <StyledP>
            Join the project to find out your BrainHealth Index!
          </StyledP>
        </StyledGrid>

        <StyledGrid item>
          <Link to="/consent/form?page=1">
            <Button longText width="175px">
              Join The Project
            </Button>
          </Link>
        </StyledGrid>
      </Hidden>

      <Hidden lgUp>
        <StyledGrid item position="relative" width="115px">
          <StyledGrid
            left="-50px"
            position="absolute"
            transform="translateY(-50%)"
          >
            <NoAccessImage width={135} />
          </StyledGrid>
        </StyledGrid>

        <StyledGrid item xs>
          <StyledGrid marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize="18px"
              letterSpacing="-0.5px"
              lineHeight="23px"
            >
              You have <strong>opted out</strong> of the BrainHealth Project
            </StyledP>
          </StyledGrid>
          <StyledP color={color.BLACK} fontSize="16px">
            <strong>Join the Project &gt;</strong>
          </StyledP>
        </StyledGrid>
      </Hidden>
    </StyledGrid>
  );
};

export default NotConsentedBanner;
