import styled from "styled-components";
import {
  color,
  fontFamily,
  fontSize,
  spacingDefaults,
  breakpoints
} from "style/constants";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";

export const JoinHeader = styled.h3`
  @media (max-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.large};
    margin: 20px auto 16px;
    max-width: 260px;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.header};
  margin: ${spacingDefaults.xlarge} 0 0;
  max-width: 680px;
`;

export const Header = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.large};
    margin: 32px auto 16px;
    max-width: 260px;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.header};
  margin: 64px auto auto;
  max-width: 680px;
`;

export const Subheader = styled.h2`
  @media (max-width: ${breakpoints.lg}px) {
    max-width: 274px;
    margin: 0 auto 40px;
  }
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.normal};
  margin-bottom: 40px;
`;

export const Paragraph = styled.p`
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.small};
  margin: 0 auto;
  text-align: left;
`;

export const Anchor = styled(Link)`
  color: ${color.BLACK};
  display: block;
  &.active {
    color: ${color.DARKBLUE};
    font-weight: bold;
  }
`;

export const Break = styled.div`
  @media (max-width: ${breakpoints.lg}px) {
    span {
      display: none;
    }
  }
  margin-bottom: 1rem;
  position: relative;
  span {
    font-family: ${fontFamily.secondary};
    left: -40px;
    position: absolute;
    top: -15px;
  }
`;

export const Info = styled.p`
  @media (max-width: ${breakpoints.lg}px) {
    margin: 5px 0;
  }
  color: ${color.DARKERGRAY};
  margin: 0px;
  margin-bottom: 5px;
`;

export const NavTitle = styled.h2`
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  margin-bottom: 6px;
`;

export const NavNums = styled.h2`
  font-family: ${fontFamily.secondary};
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 6px;
`;

export const NavWhiteSpace = styled.div`
  height: 250px;
`;

export const Note = styled.p`
  color: ${color.DARKERGRAY};
  margin: 0px;
  margin-bottom: 8px;
`;

export const Ruler = styled.hr`
  border: 1px solid ${color.CARDGRAY};
`;

export const SideNavRoot = styled.div`
  ul {
    padding-bottom: 0px;
    & + hr {
      margin-top: 18px;
      width: 160px;
    }
  }
  ul div {
    padding: 0;
    & + div {
      margin-top: 0px;
    }
    &:hover {
      background-color: transparent;
    }
    p {
      margin: 0;
      &:hover {
        cursor: default;
      }
    }
  }
  li {
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 0;
    a > button {
      width: 229px;
    }
  }
  text-align: left;
`;

export const StyledList = styled(List)`
  && {
    line-height: 1.5;
    a {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }
`;

export const Subtitle = styled.h2`
  color: ${color.BLACK};
  font-size: 18px;
  line-height: 26px;
  margin: 0px;
  margin-bottom: 5px;
`;

export const SectionSubtitle = styled(Subtitle)`
  @media (max-width: ${breakpoints.lg}px) {
    padding: 0 16px;
    margin: 0px;
    font-size: 14px;
  }
  margin-top: -112px;
  padding-top: 112px;
  padding-left: 40px;
`;

export const SubsectionTitle = styled(SectionSubtitle)`
  @media (max-width: ${breakpoints.lg}px) {
    margin-bottom: -15px;
  }
  margin-bottom: -15px;
`;

export const SectionBody = styled.p`
  @media (max-width: ${breakpoints.lg}px) {
    padding: 0 16px;
  }
  font-size: 14px;
  padding-left: 40px;
  color: ${color.DARKERGRAY};
`;

export const SectionList = styled.ul`
  @media (max-width: ${breakpoints.lg}px) {
    padding: 0 16px;
    padding-left: 32px;
  }
  font-size: 14px;
  padding-left: 60px;
  color: ${color.DARKERGRAY};
  li {
    margin-bottom: 5px;
  }
`;

export const Title = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.header};
    margin-bottom: ${spacingDefaults.normal};
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 40px;
  line-height: 38px;
  margin: 0px;
  margin-bottom: 36px;
`;

export const WhiteSpace = styled.div`
  @media (max-width: ${breakpoints.lg}px) {
    height: 32px;
  }
  height: 70vh;
`;
