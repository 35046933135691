import StyledGrid from "components/simple/StyledGrid";
import { spacingDefaults } from "style/constants";
import styled from "styled-components";

export const StyledEmptyStreakDrawerGrid = styled(StyledGrid)`
  align-items: center;
  flex-direction: row;
  gap: ${spacingDefaults.large};
  justify-content: center;

  padding: 0 ${spacingDefaults.xxlarge} 0 ${spacingDefaults.xxlarge};
  rowgap: ${spacingDefaults.normal};
`;

export const StyledCompletedStreakTask = styled(StyledGrid)`
  padding: 0 ${spacingDefaults.xxlarge} 0 ${spacingDefaults.xxlarge};
  align-items: center;
  justify-content: space-between;
`;
