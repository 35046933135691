import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Grid, { GridProps } from "@material-ui/core/Grid";

import backgroundImage from "assets/registration.jpg";

import {
  backgroundImageStyle,
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";

export const Callout = styled.p`
  color: ${color.DARKERGRAY};
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const AccountCallout = styled(Callout)`
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const StyledForgotPasswordLink = styled(Link)`
  color: ${color.DARKERGRAY};
  font-size: 12px;
`;

export const ImageOverlay = styled.div`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  height: 25%;
  position: relative;
  top: 75%;
` as React.FC;

export const LeftImage = styled(({ ...otherProps }) => (
  <Grid {...otherProps} />
))`
  && {
    ${backgroundImageStyle(backgroundImage)}
    position: relative;
  }
` as React.FC<GridProps>;

export const StyledHr = styled.hr`
  @media (max-width: ${breakpoints.lg}px) {
    margin: ${spacingDefaults.medium} 0;
  }
  border: 1px solid ${color.MUTEDGRAY};
  border-bottom: none;
  margin: 3rem 0;
`;

export const Title = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    font-size: 1.875rem;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 2.25rem;
  font-weight: normal;
  line-height: 2.5625rem;
  margin: 0;
`;

export const Image = styled.img`
  height: 100px;
  width: 230px;
  margin-left: 30px;
  margin-bottom: 30px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
    margin-left: 0;
  }
`;
