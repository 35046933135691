import React from "react";

import { ReactComponent as AudioOff } from "assets/audio-off.svg";
import { ReactComponent as AudioOn } from "assets/audio-on.svg";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { StyledIconButton } from "../styled";

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === "audio");

  return (
    <StyledIconButton
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
    >
      {hasAudioTrack && isAudioEnabled ? <AudioOn /> : <AudioOff />}
    </StyledIconButton>
  );
}
