import React from "react";
import { color } from "style/constants";
import Styled from "styled-components";
import { deleteToken } from "utils/auth";

const StyledDiv = Styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0;
  height: 50px;
  width: 100%;
  border-bottom: 5px solid ${color.LIGHTRED};
  z-index: 9999;
  text-align: center;
  overflow: hidden;
  background: ${color.GRAPHITE};
  color: ${color.WHITE};
  transform: translateY(-40px);
  transition: transform .15s ease-in-out;
  a {
    color: ${color.LIGHTBLUE};
    margin-left: 10px;
  }
  &:hover {
    transform: translateY(0);
  }
`;
interface Props {
  name: string;
}
const LoggedInAs: React.FC<Props> = ({ name }: Props) => {
  const isAssumedIdentity = !!localStorage.getItem("assumedIdentityToken");
  return (
    <>
      {isAssumedIdentity && (
        <StyledDiv>
          Logged in as {name}
          <a
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
              deleteToken();
              window.location.reload();
            }}
          >
            Logout
          </a>
        </StyledDiv>
      )}
    </>
  );
};

export default LoggedInAs;
