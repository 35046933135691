import { Box, useMediaQuery } from "@material-ui/core";
import RichText from "components/pages/Contentful/Unit/RichText";
import { StyledGrid } from "components/simple";
import { Media, MediaType, SlideData } from "graphql/types/Contentful";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import VideoThumbnail from "assets/consent-video-thumbnail.png";
import { breakpoints } from "style/constants";
interface SlideItemProps {
  item: SlideData;
  width: string;
  setHasVideoEnded: Dispatch<SetStateAction<boolean>>;
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  title?: string;
  tabText?: string;
}

export const SlideItem: React.FC<SlideItemProps> = ({
  item,
  width,
  setHasVideoEnded,
  setHasVideo,
  title,
  tabText
}) => {
  const videoEl = useRef<HTMLVideoElement>(null);
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const media = item?.media;
  const mediaAvailable = media?.length ?? false;
  let supposedCurrentTime = 0;
  return (
    <div className="slide-item" style={{ width: width }}>
      {mediaAvailable ? (
        <>
          <div className="slide-item-image">
            {item?.media?.map((media: Media, idx: number) => (
              <StyledGrid key={idx} container centerContent={true}>
                {media?.mediaType === MediaType.videoMp4 ? (
                  <>
                    {setHasVideo(true)}
                    <video
                      key={media?.fileUrl}
                      controls
                      style={{
                        width: mobile ? "80vw" : "35vw",
                        height: "25vh"
                      }}
                      poster={VideoThumbnail}
                      onEnded={() => setHasVideoEnded(true)}
                      onTimeUpdate={() => {
                        if (!videoEl?.current?.seeking) {
                          supposedCurrentTime =
                            videoEl?.current?.currentTime || 0;
                        }
                      }}
                      onSeeking={(e: any) => {
                        if (videoEl?.current?.currentTime) {
                          const delta =
                            videoEl?.current?.currentTime - supposedCurrentTime;
                          if (delta > 0.01) {
                            videoEl.current.currentTime = supposedCurrentTime;
                          }
                        }
                      }}
                    >
                      <source src={media?.fileUrl} type="video/mp4" />
                    </video>
                  </>
                ) : (
                  <img
                    src={media?.fileUrl}
                    style={{
                      width: mobile ? "80vw" : "35vw",
                      height: "25vh"
                    }}
                  />
                )}
              </StyledGrid>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="slide-item-heading">
            <StyledGrid textAlign="center">
              <Box>{item?.tabText}</Box>
            </StyledGrid>
          </div>
        </>
      )}

      <div className="slide-item-heading">
        <StyledGrid textAlign="left">
          <Box> {item?.title}</Box>
        </StyledGrid>
      </div>

      <div className="slide-item-content">
        <StyledGrid textAlign={"left"}>
          {<RichText data={item?.data} />}
        </StyledGrid>
      </div>
    </div>
  );
};
