import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useAppState } from "../../state";
import IntroContainer from "../IntroContainer/IntroContainer";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import RecordingConsentScreen from "./RecordingConsentScreen/RecordingConsentScreen";

export enum Steps {
  recordingConsentStep,
  deviceSelectionStep
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { coachingSessionId } = useParams<{ coachingSessionId: string }>();
  const [step, setStep] = useState(Steps.recordingConsentStep);

  const [name] = useState<string>(user!.displayName || "");
  const [roomName, setRoomName] = useState<string>("");

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (coachingSessionId) {
      setRoomName(coachingSessionId);

      if (!user!.askRecordingConsent) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, coachingSessionId]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log("Error acquiring local media:");
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />

      {step === Steps.recordingConsentStep && (
        <RecordingConsentScreen
          showFirstVideoCallInfo={user!.showFirstVideoCallInfo}
          setStep={setStep}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={name}
          roomName={roomName}
          setStep={setStep}
        />
      )}
    </IntroContainer>
  );
}
