import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import Logo from "assets/brain-gauge-logo.svg";
import { StyledGrid } from "components/simple";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const PageOne: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      padding={spacingDefaults.xlarge}
    >
      <img alt="Brain Gauge Logo" height={185} src={Logo} width={225} />

      <Box
        fontFamily={fontFamily.secondary}
        fontSize={mobile ? fontSize.large : fontSize.xlarge}
        letterSpacing="-0.45px"
        margin={`${spacingDefaults.large} 0`}
        textAlign="center"
      >
        What is Brain Gauge?
      </Box>

      <Box
        fontSize={mobile ? fontSize.small : fontSize.normal}
        lineHeight="180%"
        maxWidth="620px"
        textAlign="center"
      >
        Brain Gauge is a cognitive assessment tool that measures brain health by
        testing sensory perceptions in your fingertips. Our tests let you
        measure several essential components of brain health: Speed, Focus,
        Fatigue, Accuracy, Sequencing, Timing Perception, Plasticity, and
        Connectivity. {` You'll`} also get a comprehensive mental fitness score
        that we call your corticalmetric.
        <br />
        <br />
      </Box>
    </StyledGrid>
  );
};

export default PageOne;
