import React, { createContext, useContext, useReducer, useState } from "react";
import { TwilioError } from "twilio-video";

import client from "graphql/client";
import {
  DisableVideoRecording,
  EnableVideoRecording,
  GetVideoToken
} from "graphql/sessions/sessions.gql";
import { RoomType } from "../types";
import {
  initialSettings,
  Settings,
  SettingsAction,
  settingsReducer
} from "./settings/settingsReducer";
import useActiveSinkId from "./useActiveSinkId/useActiveSinkId";

type User = {
  displayName: string;
  profileImageUrl: string;
  askRecordingConsent: boolean;
  showFirstVideoCallInfo: boolean;
};

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(
    name: string,
    room: string,
    passcode?: string
  ): Promise<{ room_type: RoomType; token: string }>;
  user?: null | User;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  setAllowRecording: React.Dispatch<boolean>;
  toggleRecording(room_sid: string, value: boolean): Promise<void>;
  shouldRedirectOnDisconnect: boolean;
  setShouldRedirectOnDisconnect: React.Dispatch<boolean>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(
  props: React.PropsWithChildren<{ user: User }>
) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [roomType, setRoomType] = useState<RoomType>();
  const [allowRecording, setAllowRecording] = useState<boolean>(true);
  const [shouldRedirectOnDisconnect, setShouldRedirectOnDisconnect] = useState<
    boolean
  >(false);

  const contextValue = {
    error,
    setError,
    user: props.user,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    shouldRedirectOnDisconnect,
    setShouldRedirectOnDisconnect,
    getToken: async (_, room_name) => {
      const response = await client.query({
        query: GetVideoToken,
        fetchPolicy: "no-cache",
        variables: { id: room_name, input: { allowRecording, isCoach: false } }
      });

      const { token, roomType } = response.data.coachingSession.videoToken;

      return {
        token,
        room_type: roomType
      };
    },
    setAllowRecording,
    toggleRecording: async (room_name, value) => {
      await client.mutate({
        mutation: value ? EnableVideoRecording : DisableVideoRecording,
        fetchPolicy: "no-cache",
        variables: { id: room_name }
      });
    }
  } as StateContextType;

  const getToken: StateContextType["getToken"] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setRoomType(res.room_type);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const toggleRecording: StateContextType["toggleRecording"] = (
    room,
    value
  ) => {
    setIsFetching(true);
    return contextValue
      .toggleRecording(room, value)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{ ...contextValue, getToken, toggleRecording }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
