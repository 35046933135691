import React from "react";

import { StyledGrid, WidgetTitle } from "components/simple";
import { spacingDefaults } from "style/constants";
import ScheduleCard from "./ScheduleCard";

interface ScheduleSessionsProps {
  allowCoachScheduling?: boolean;
  allowImageScheduling?: boolean;
}

const ScheduleSessions: React.FC<ScheduleSessionsProps> = ({
  allowCoachScheduling = false,
  allowImageScheduling = false
}: ScheduleSessionsProps) => {
  return (
    <StyledGrid container>
      {(allowCoachScheduling || allowImageScheduling) && (
        <StyledGrid item marginBottom={spacingDefaults.normal}>
          <WidgetTitle>Schedule a Session</WidgetTitle>
        </StyledGrid>
      )}
      <StyledGrid item container spacing={2}>
        {allowCoachScheduling && (
          <StyledGrid item xs={12}>
            <ScheduleCard appointmentType="coaching" />
          </StyledGrid>
        )}
        {allowImageScheduling && (
          <StyledGrid item xs={12}>
            <ScheduleCard appointmentType="imaging" />
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default ScheduleSessions;
