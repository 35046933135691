import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import styled from "styled-components";

import FlowerIcon from "assets/flower-icon.svg";
import FruitIcon from "assets/fruit-icon.svg";
import NutIcon from "assets/nut-icon.svg";
import { StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";

export const StyledTile = styled(StyledGrid)`
  && {
    align-items: center;
    background-color: ${color.WHITE};
    border-radius: 8px;
    border: 1px solid ${color.MUTEDGRAY};
    border-top: ${spacingDefaults.xsmall} solid ${color.DARKBLUE};
    min-height: 200px;
    gap: ${spacingDefaults.normal};
  }
`;

const StatsTile: React.FC = () => {
  return (
    <StyledTile centerContent container direction="column" item xs={12}>
      <StyledGrid item>
        <Box fontSize={fontSize.large} color={color.GRAPHITE}>
          Stats
        </Box>
      </StyledGrid>
      <StyledGrid item style={{ gap: spacingDefaults.normal }}>
        <img src={FlowerIcon} height="50px" />
        <img
          src={FruitIcon}
          height="50px"
          style={{ padding: `0 ${spacingDefaults.xsmall}` }}
        />
        <img src={NutIcon} height="50px" />
      </StyledGrid>
      <StyledGrid item>
        <Link
          to="/stats"
          style={{ color: color.DARKBLUE }}
        >{`View All >`}</Link>
      </StyledGrid>
    </StyledTile>
  );
};

export default StatsTile;
