import React from "react";
import Modal from "@material-ui/core/Modal";
import { IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";

import { Button, StyledGrid } from "components/simple";
import { color, iconSizes, breakpoints } from "style/constants";
import { ReactComponent as Close } from "assets/close.svg";
import { Header, Paragraph } from "../../styled";

import { ReactComponent as Warning } from "assets/warning-red.svg";
import { SendUpdateEmailAddressEmail } from "graphql/user/user.gql";
import { useMutation } from "@apollo/react-hooks";
import { showErrorNotification, showSuccessNotification } from "state";

interface ModalProps {
  openModal: boolean;
  closeModal(): void;
  oldEmail: string;
  newEmail: string;
  turnOffEditMode(): void;
  refetchUser(): void;
}

const UpdateEmailModal: React.FC<ModalProps> = ({
  openModal,
  closeModal,
  oldEmail,
  newEmail,
  turnOffEditMode,
  refetchUser
}: ModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [sendUpdateEmailAddressEmail] = useMutation(
    SendUpdateEmailAddressEmail
  );
  const submitForm = async (): Promise<true | null> => {
    try {
      const { data } = await sendUpdateEmailAddressEmail({
        variables: { input: { newEmail: newEmail } }
      });
      const success = data.sendUpdateEmailAddressEmail.success;
      if (!success) {
        dispatch(
          showErrorNotification(
            "Failed",
            `Failed to change your email address. Please check its availability.`
          )
        );
      } else {
        dispatch(
          showSuccessNotification(
            "Success",
            `We have sent a verification email to ${newEmail}. Please check your inbox.`
          )
        );
      }
    } catch (error) {
      dispatch(
        showErrorNotification(
          "Failed",
          `Failed to change your email address. Please check its availability.`
        )
      );
    }
    turnOffEditMode();
    refetchUser();
    closeModal();
    return true;
  };

  return (
    <Modal
      aria-labelledby="confirm-action"
      aria-describedby="assessment-modal-description"
      open={openModal}
      onClose={closeModal}
    >
      <StyledGrid
        absoluteCenter
        backgroundColor={color.BLACK_RGBA}
        container
        centerContent
        height={"100%"}
        width={"100%"}
        style={{ zIndex: 1100 }}
        position="fixed"
      >
        <StyledGrid
          absoluteCenter
          backgroundColor={color.WHITE}
          borderRadius={"8px"}
          boxShadow={"0px 4px 14px rgba(223, 223, 223, 0.85);"}
          centerContent
          container
          direction={"column"}
          item
          padding={"1em"}
          width={"100%"}
          maxWidth={mobile ? "342px" : "550px"}
        >
          <StyledGrid
            item
            position={"absolute"}
            style={{ top: "1em", right: "1em" }}
          >
            <IconButton aria-label="close" key="close" onClick={closeModal}>
              <Close height={iconSizes.smaller} width={iconSizes.smaller} />
            </IconButton>
          </StyledGrid>
          <StyledGrid item marginBottom={"-3.5em"}>
            <Warning width={"150px"} height={"150px"} />
          </StyledGrid>
          <StyledGrid
            item
            width={"100%"}
            maxWidth={mobile ? "250px" : "450px"}
            marginTop={"1.5em"}
            marginBottom={"-1em"}
          >
            <Header>Confirm Your New Email Address</Header>
          </StyledGrid>
          <StyledGrid item width={"100%"} maxWidth={mobile ? "250px" : "450px"}>
            <Paragraph>
              You are going to change your email address from
              <br />
              <b>
                <i>{oldEmail}</i>
              </b>
              <br />
              {" to "}
              <br />
              <b>
                <i>{newEmail}</i>
              </b>
              .<br />
              Once you have clicked the confirm button below, we will send you a
              verification email. In order for this change to take effect, you
              will have 1 day to click the link in the verification email.
            </Paragraph>
          </StyledGrid>
          <StyledGrid item marginBottom={"2em"}>
            <Button onClick={submitForm}>Confirm</Button>
          </StyledGrid>
          <StyledGrid item marginTop={"-1.25em"} marginBottom={"2em"}>
            <Button inverted onClick={closeModal}>
              Cancel
            </Button>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </Modal>
  );
};

export default UpdateEmailModal;
