import React from "react";
import { Divider, DividerProps } from "@material-ui/core";
import styled from "styled-components";

import { color as colors } from "style/constants";

interface Ruler {
  color?: string;
  margins?: string;
  thickness?: string;
}

type RulerProps = Ruler & DividerProps;

const Ruler = styled(
  ({ color, margins, thickness, ...otherProps }: RulerProps) => (
    <Divider {...otherProps} />
  )
)`
  && {
    background-color: ${props => props.color || colors.MUTEDGRAY};
    ${props =>
      props.orientation === "vertical" ? "width" : "height"}: ${props =>
      props.thickness || "1px"};
    margin: ${props =>
        props.orientation === "vertical" ? "0" : props.margins || "0"}
      ${props =>
        props.orientation === "vertical" ? props.margins || "0" : "0"};
  }
` as React.FC<RulerProps>;

export default Ruler;
