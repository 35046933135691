import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import Data from "assets/brain-hq-data.png";
import { StyledGrid } from "components/simple";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const PageThree: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      padding={spacingDefaults.xlarge}
    >
      <img alt="A generic line chart." height={135} src={Data} width={310} />

      <Box
        fontFamily={fontFamily.secondary}
        fontSize={mobile ? fontSize.large : fontSize.xlarge}
        letterSpacing="-0.45px"
        margin={`${spacingDefaults.large} 0`}
        textAlign="center"
      >
        How is my data being used and protected?
      </Box>

      <Box
        fontSize={mobile ? fontSize.small : fontSize.normal}
        lineHeight="180%"
        maxWidth="620px"
        textAlign="center"
      >
        Through your dashboard, you will be provided access to an anonymous
        BrainHQ account. Your data on the BrainHQ site is fully anonymized and
        does not contain personally identifiable information. Through your
        interactions with BrainHQ, your anonymous data will be funneled into a
        single database at BrainHQ. Separately, we will also transfer your data
        to The BrainHealth Project.
        <br />
        <br />
        All servers and databases are hosted on Amazon Web Services (AWS).
        BrainHQ has the technical, physical and administrative safeguards in
        place and they adhere to the HIPAA Privacy Rule to protect the integrity
        of Protected Health Information.
        <br />
        <br />
        <a
          href="https://www.brainhq.com/security"
          rel="noopener noreferrer"
          target="_blank"
        >
          Find a detailed description of our training policies here.
        </a>
      </Box>
    </StyledGrid>
  );
};

export default PageThree;
