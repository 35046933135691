import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import track from "react-tracking";

import { getUser } from "state";
import routesConfig from "utils/routesConfig";
import IntroVideo from "./IntroVideo";
import ConsentVideo from "./ConsentVideo";
import ConsentForm from "./Form";
import Join from "./Join";

const url: string = routesConfig.consent.path;

export interface FormProps {
  enrollmentConsent?: boolean | null;
}

const Consent: React.FC = () => {
  const user = useSelector(getUser);

  return (
    <>
      <Route component={ConsentVideo} exact path={"/consent"} />
      <Route component={ConsentVideo} path={"/consent/consent-video"} />
      <Route
        path={"/consent/form"}
        render={() => (
          <ConsentForm enrollmentConsent={user?.enrollmentConsent} />
        )}
      />
      <Route
        path={"/consent/questions"}
        render={() => (
          <ConsentForm enrollmentConsent={user?.enrollmentConsent} />
        )}
      />
      <Route component={Join} path={"/consent/join"} />
      <Route component={IntroVideo} path={"/consent/intro-video"} />
    </>
  );
};

export default track({
  url
})(Consent);
