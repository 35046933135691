import React from "react";
import track from "react-tracking";

import { ReactComponent as Clock } from "assets/uncertain-clock.svg";
import { Button, StyledGrid } from "components/simple";
import routesConfig from "utils/routesConfig";
import { RegisterCallout, Title } from "../../Registration/styled";

const url = routesConfig.waitlist.path;

const FullOrganization: React.FC = () => {
  return (
    <>
      <StyledGrid
        centerContent
        container
        direction="column"
        item
        itemMargin="1rem"
        margin="auto"
        maxWidth="370px"
        mobileMaxWidth="80%"
      >
        <Clock
          height="275px"
          width="100%"
          aria-label="Clock with woman shrugging"
        />
        <Title>You are on the waitlist</Title>
        <RegisterCallout>
          You qualified for the BrainHealth Project, but the group you
          registered for is at capacity. We will let you know when the next
          opportunity to participate opens up. In the meantime, you can learn
          about brain-healthy practices.
        </RegisterCallout>
        <StyledGrid item>
          <Button wide href={"/resources"}>
            Explore Resources
          </Button>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export default track({
  url
})(FullOrganization);
