import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";

import { DigitSymbol } from "./DigitSymbol";
import Intro from "./Intro";
import { Ready } from "./Intro/Ready";
import { Start } from "./Intro/Start";
import Heading from "../Heading";
import StyledP from "../StyledP";
import StyledGrid from "../StyledGrid";

const MINIMUM_SUPPORTED_DEVICE_WIDTH = 375;
export interface DigitSymbolQuestionData {
  reference: { digits: string[]; symbols: string[] };
  digitSymbol: { digit: string; symbol: string };
  isCorrect: boolean;
}

export enum Platform {
  "Keyboard" = "Keyboard",
  "Touchscreen" = "Touchscreen"
}

interface DigitSymbolQuestionProps {
  callback: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  prompt: string;
  question: DigitSymbolQuestionData[];
  isPractice: boolean;
  inputDevice: any;
}

export const enum AnswerOptions {
  CORRECT = "correct",
  INCORRECT = "incorrect",
  NO_ANSWER = "noAnswer",
  INVALID = "invalid"
}

export interface AnswerOptionTime {
  answer: AnswerOptions;
  responseTime: number;
}

const DigitSymbolQuestion: React.FC<DigitSymbolQuestionProps> = ({
  callback: saveAnswersCallback,
  prompt,
  question: digitSymbols,
  isPractice,
  inputDevice
}: DigitSymbolQuestionProps) => {
  const [answers, setAnswers] = useState<AnswerOptionTime[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [readyVisible, setReadyVisible] = useState<boolean>(false);
  const [startVisible, setStartVisible] = useState<boolean>(false);

  const platform: Platform =
    inputDevice.answerData.response ?? Platform.Touchscreen;

  const deviceTooNarrow = useMediaQuery(
    `(max-width:${MINIMUM_SUPPORTED_DEVICE_WIDTH - 1}px)`
  );

  function instructionsCompleted() {
    saveAnswersCallback({ response: platform });
    setStartVisible(true);
  }

  function saveDigitSymbolAnswer(answer: AnswerOptions, responseTime: number) {
    const updatedAnswers = [...answers, { answer, responseTime }];

    const nextIndex = currentIndex + 1;
    if (nextIndex === digitSymbols.length) {
      saveAnswersCallback({
        values: updatedAnswers,
        platform
      });
    } else {
      setAnswers(updatedAnswers);
      setCurrentIndex(nextIndex);
    }
  }

  function handleNextStep() {
    setStartVisible(false);
    setAnswers([]);
    setCurrentIndex(0);
  }

  return (
    <>
      {deviceTooNarrow ? (
        <StyledGrid padding="1rem">
          <Heading>Device width too narrow</Heading>
          <StyledP>
            Device width not supported for this assessment. Please switch to a
            device with a wider view.
          </StyledP>
        </StyledGrid>
      ) : isPractice ? (
        <Intro
          callback={instructionsCompleted}
          platform={platform}
          question={digitSymbols}
        />
      ) : readyVisible ? (
        <Ready
          callback={() => {
            setReadyVisible(false);
            setStartVisible(true);
          }}
        />
      ) : startVisible ? (
        <Start prompt={prompt} callback={handleNextStep} />
      ) : (
        currentIndex < digitSymbols.length &&
        digitSymbols[currentIndex] && (
          <DigitSymbol
            saveAnswerCallback={saveDigitSymbolAnswer}
            platform={platform}
            prompt={prompt}
            question={digitSymbols[currentIndex]}
            currentIndex={currentIndex}
          />
        )
      )}
    </>
  );
};

export default DigitSymbolQuestion;
