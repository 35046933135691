import React from "react";
import get from "lodash/get";
import track from "react-tracking";

import { CurrentAssessment } from "graphql/userAssessments/userAssessments.gql";
import { UserAssessment } from "graphql/types/User";
import { useQueryCustom as useQuery } from "hooks";
import Questions from "./Questions";
import routesConfig from "utils/routesConfig";

const url: string = routesConfig.assessments.assessment.path;

const QuestionsPage: React.FC = () => {
  const { data: queryData } = useQuery({ input: CurrentAssessment });
  const userAssessment: UserAssessment = get(queryData, "getCurrentAssessment");

  const [data, setData] = React.useState<Partial<UserAssessment>>({});

  React.useEffect(() => {
    setData(userAssessment);
  }, [userAssessment]);
  return <Questions userAssessment={data} />;
};

export default track({
  url
})(QuestionsPage);
