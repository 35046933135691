import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import { StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";

interface ExploreButtonProps {
  link: string;
  backgroundColor: color;
  title: string;
  subtitle: string;
  image: JSX.Element;
  disabled?: boolean;
}

const ExploreButton: React.FC<ExploreButtonProps> = ({
  link,
  backgroundColor,
  title,
  subtitle,
  image,
  disabled = false
}: ExploreButtonProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <Link
      aria-disabled={disabled}
      onClick={disabled ? x => x.preventDefault() : undefined}
      to={link}
    >
      <StyledGrid
        alignItems="center"
        backgroundColor={backgroundColor}
        borderRadius="8px"
        container
        height="140px"
        justifyContent="space-between"
        padding="0 40px"
      >
        <StyledGrid item>
          <Box
            color={color.WHITE}
            component="p"
            fontSize="24px"
            letterSpacing="-0.9px"
            lineHeight="30px"
            margin={`0 0 ${spacingDefaults.xsmall} 0`}
            maxWidth={mobile ? "150px" : undefined}
          >
            {title}
          </Box>

          <Box
            color={color.WHITE}
            component="p"
            fontSize="14px"
            lineHeight="17px"
            margin="0"
          >
            {subtitle}
          </Box>
        </StyledGrid>

        <StyledGrid item>{image}</StyledGrid>
      </StyledGrid>
    </Link>
  );
};

export default ExploreButton;
