import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";

import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import { ReactComponent as LegendUnchecked } from "assets/legend-unchecked.svg";
import {
  NewCycle,
  PillarName
} from "components/pages/Index/CycleSummary/types";
import { StyledGrid } from "components/simple";
import { breakpoints, color } from "style/constants";
import CustomLine from "./Line";
import SingleCycle from "./SingleCycle";
import { InfoCardWrapper, Legend, LegendLabel } from "./styled";

interface ScoreHistoryProps {
  cycles: NewCycle[];
  selectedCycleIndex: number;
}

const ScoreHistory: React.FC<ScoreHistoryProps> = ({
  cycles,
  selectedCycleIndex
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const numberOfPointsToDisplay = 5;
  const midPoint = Math.ceil(numberOfPointsToDisplay / 2);

  const startPoint = Math.max(
    selectedCycleIndex - (numberOfPointsToDisplay - midPoint),
    0
  );
  const endPoint = Math.min(
    startPoint + numberOfPointsToDisplay - 1,
    cycles.length - 1
  );

  const cyclesToDisplay = cycles.slice(startPoint, endPoint + 1);

  const activeIndex = Math.floor(
    (selectedCycleIndex / cycles.length) * cyclesToDisplay.length
  );

  const oneCycle = cycles.length == 1;
  const indexScore = cycles[0].bhiRefactorPillarScores[0]?.value ?? 0;

  const getBorderForFactor = (pillarName: PillarName) => {
    switch (pillarName) {
      case PillarName.NEW_INDEX:
        return `5px solid ${color.DARKBLUE}`;
      case PillarName.EMOTIONAL_BALANCE:
        return `5px solid ${color.FUSCHIA}`;
      case PillarName.CONNECTEDNESS:
        return `5px solid ${color.GREEN}`;
      case PillarName.CLARITY:
        return `5px solid ${color.PURPLE}`;
    }
  };

  const [pillars, setPillars] = useState<Set<PillarName>>(new Set());

  const togglePillar = (pillarName: PillarName) => {
    const finalPillars = pillars;
    if (pillars.has(pillarName) && [...pillars].length > 1) {
      finalPillars.delete(pillarName);
    } else {
      finalPillars.add(pillarName);
    }
    return finalPillars;
  };

  return (
    <StyledGrid container direction="column">
      <InfoCardWrapper>
        <CustomLine
          activeIndex={activeIndex}
          cycles={cyclesToDisplay}
          pillars={pillars}
        />

        <StyledGrid container direction="column" item>
          <Legend>
            <StyledGrid item xs={12}>
              <LegendLabel>Select to view details</LegendLabel>
            </StyledGrid>
            <StyledGrid
              alignItems="center"
              className="legend index"
              container
              item
              justifyContent="space-between"
              margin="0.5rem 0"
              onClick={() => {
                setPillars(new Set(togglePillar(PillarName.NEW_INDEX)));
              }}
              position="relative"
              xs={12}
            >
              <StyledGrid
                borderLeft={getBorderForFactor(PillarName.NEW_INDEX)}
                padding="0.63rem 2rem"
                item
              >
                Index
              </StyledGrid>
              <StyledGrid height="26px" width="26px" item>
                {pillars.has(PillarName.NEW_INDEX) ? (
                  <CheckIcon fill={color.GREEN} height="100%" width="100%" />
                ) : (
                  <LegendUnchecked />
                )}
              </StyledGrid>
            </StyledGrid>
            <StyledGrid
              borderBottom="1px solid rgba(200, 200, 200, 0.6)"
              item
            />
            <StyledGrid
              alignItems="center"
              className="legend emotional-balance"
              container
              item
              justifyContent="space-between"
              margin="0.5rem 0"
              onClick={() => {
                setPillars(new Set(togglePillar(PillarName.EMOTIONAL_BALANCE)));
              }}
              position="relative"
              xs={12}
            >
              <StyledGrid
                borderLeft={getBorderForFactor(PillarName.EMOTIONAL_BALANCE)}
                padding="0.63rem 2rem"
                item
              >
                Emotional Balance
              </StyledGrid>
              <StyledGrid height="26px" width="26px" item>
                {pillars.has(PillarName.EMOTIONAL_BALANCE) ? (
                  <CheckIcon fill={color.GREEN} height="100%" width="100%" />
                ) : (
                  <LegendUnchecked />
                )}
              </StyledGrid>
            </StyledGrid>
            <StyledGrid
              borderBottom="1px solid rgba(200, 200, 200, 0.6)"
              item
            />
            <StyledGrid
              alignItems="center"
              className="legend connectedness"
              container
              item
              justifyContent="space-between"
              margin="0.5rem 0"
              onClick={() => {
                setPillars(new Set(togglePillar(PillarName.CONNECTEDNESS)));
              }}
              position="relative"
              xs={12}
            >
              <StyledGrid
                borderLeft={getBorderForFactor(PillarName.CONNECTEDNESS)}
                padding="0.63rem 2rem"
                item
              >
                Connectedness
              </StyledGrid>
              <StyledGrid height="26px" width="26px" item>
                {pillars.has(PillarName.CONNECTEDNESS) ? (
                  <CheckIcon fill={color.GREEN} height="100%" width="100%" />
                ) : (
                  <LegendUnchecked />
                )}
              </StyledGrid>
            </StyledGrid>
            <StyledGrid
              borderBottom="1px solid rgba(200, 200, 200, 0.6)"
              item
            />
            <StyledGrid
              alignItems="center"
              className="legend clarity"
              container
              item
              justifyContent="space-between"
              margin="0.5rem 0"
              onClick={() => {
                setPillars(new Set(togglePillar(PillarName.CLARITY)));
              }}
              position="relative"
              xs={12}
            >
              <StyledGrid
                borderLeft={getBorderForFactor(PillarName.CLARITY)}
                item
                padding="0.63rem 2rem"
              >
                Clarity
              </StyledGrid>
              <StyledGrid height="26px" width="26px" item>
                {pillars.has(PillarName.CLARITY) ? (
                  <CheckIcon fill={color.GREEN} height="100%" width="100%" />
                ) : (
                  <LegendUnchecked />
                )}
              </StyledGrid>
            </StyledGrid>
          </Legend>
        </StyledGrid>
      </InfoCardWrapper>
    </StyledGrid>
  );
};

export default ScoreHistory;
