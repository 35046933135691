import React from "react";

import { color } from "style/constants";
import { StyledGrid } from "components/simple";

interface Props {
  numberCompleted: number;
  numberOfQuestions: number;
}

const ResourceSurveyProgressBar: React.FC<Props> = ({
  numberCompleted,
  numberOfQuestions
}) => {
  return (
    <StyledGrid container wrap="nowrap" style={{ gap: "5px" }}>
      {new Array(numberOfQuestions).fill(0).map((_, idx) => {
        let borderRadius;
        if (idx === 0) borderRadius = "0 8px 8px 0";
        else if (idx === numberOfQuestions - 1) borderRadius = "8px 0 0 8px";
        else borderRadius = "8px";
        return (
          <StyledGrid
            backgroundColor={
              idx <= numberCompleted ? color.DARKBLUE : color.GRAY_PROGESS_BAR
            }
            borderRadius={borderRadius}
            height="8px"
            item
            key={idx}
            width="100%"
          />
        );
      })}
    </StyledGrid>
  );
};
export default ResourceSurveyProgressBar;
