export const absoluteCenter = `
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const centerContent = `
  align-content: center;
  justify-content: center;
  text-align: center;

  & > * {
    justify-content: center;
  }
`;

export const setItemMargin = (
  direction: string | undefined,
  margin: string | undefined
): string => `
  & > * + * {
    ${direction === "column" ? `margin-top: ${margin}` : ""}
  }
  & > * {
    ${direction === "row" || direction === undefined ? `margin ${margin}` : ""}
  }
`;
