import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { breakpoints } from "style/constants";
import DeskTopReferralBanner from "./Desktop";
import MobileReferralBanner from "./Mobile";

interface ReferralBannerProps {
  referralLink: string;
  totalReferrals: number;
}

const ReferralBanner: React.FC<ReferralBannerProps> = ({
  referralLink,
  totalReferrals
}: ReferralBannerProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  if (mobile) {
    return (
      <MobileReferralBanner
        referralLink={referralLink}
        totalReferrals={totalReferrals}
      />
    );
  } else {
    return (
      <DeskTopReferralBanner
        referralLink={referralLink}
        totalReferrals={totalReferrals}
      />
    );
  }
};

export default ReferralBanner;
