import { Box } from "@material-ui/core";
import React, { useState } from "react";

import { Button, StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import { useAppState } from "../../../state";
import { Steps } from "../PreJoinScreens";

type Props = {
  showFirstVideoCallInfo: boolean;
  setStep: (step: Steps) => void;
};

const RecordingConsentScreen: React.FC<Props> = ({
  showFirstVideoCallInfo,
  setStep
}: Props) => {
  const [page, setPage] = useState(showFirstVideoCallInfo ? 0 : 3);
  const { setAllowRecording } = useAppState();

  return (
    <StyledGrid container direction="column" alignItems="center" centerContent>
      <StyledGrid
        container
        direction="column"
        item
        marginBottom={spacingDefaults.normal}
        textAlign="center"
        alignItems="center"
      >
        {page === 0 && (
          <>
            <Box
              component="p"
              fontSize={fontSize.medium}
              color={color.WHITE}
              fontWeight="bold"
            >
              May we record this coaching session for training purposes?
            </Box>
            <Box
              component="p"
              fontSize={fontSize.small}
              color={color.WHITE}
              maxWidth="350px"
            >
              Read through these slides for more information, and you can select
              YES or NO.
            </Box>
          </>
        )}

        {page === 1 && (
          <>
            <Box
              component="p"
              fontSize={fontSize.medium}
              color={color.WHITE}
              fontWeight="bold"
            >
              What’s the purpose of recording?
            </Box>
            <Box
              component="p"
              fontSize={fontSize.small}
              color={color.WHITE}
              maxWidth="350px"
            >
              The purpose of recording the session is to help our team train,
              monitor, and equip coaches to be as effective as possible. Before
              each session, you can accept or decline the recording option.
            </Box>
          </>
        )}

        {page === 2 && (
          <>
            <Box
              component="p"
              fontSize={fontSize.medium}
              color={color.WHITE}
              fontWeight="bold"
            >
              How is this recording being used and protected?
            </Box>
            <Box
              component="p"
              fontSize={fontSize.small}
              color={color.WHITE}
              maxWidth="350px"
            >
              Only certified and approved research personnel can access this
              recording. Similar to other data in the platform, this recording
              will be stored digitally in a centralized database that is being
              hosted on Amazon Web Services.
            </Box>
          </>
        )}

        {page === 3 && (
          <>
            <Box
              component="p"
              fontSize={fontSize.medium}
              color={color.WHITE}
              fontWeight="bold"
            >
              May we record this coaching session for training purposes?{" "}
            </Box>
            <Box
              component="p"
              fontSize={fontSize.small}
              color={color.WHITE}
              maxWidth="350px"
            >
              This decision will not impact your coaching call.
            </Box>
          </>
        )}
      </StyledGrid>

      {page < 3 && (
        <StyledGrid
          container
          item
          direction="row"
          alignItems="center"
          spacing={1}
        >
          {page > 0 && (
            <StyledGrid item>
              <Button inverted onClick={() => setPage(page - 1)} width="150px">
                Previous
              </Button>
            </StyledGrid>
          )}
          <StyledGrid item>
            <Button onClick={() => setPage(page + 1)} width="150px">
              Next
            </Button>
          </StyledGrid>
        </StyledGrid>
      )}

      {page === 3 && (
        <StyledGrid
          container
          item
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <StyledGrid item>
            <Button
              onClick={() => {
                setAllowRecording(false);
                setStep(Steps.deviceSelectionStep);
              }}
              inverted
              width="150px"
            >
              No
            </Button>
          </StyledGrid>
          <StyledGrid item>
            <Button
              onClick={() => {
                setAllowRecording(true);
                setStep(Steps.deviceSelectionStep);
              }}
              width="150px"
            >
              Yes
            </Button>
          </StyledGrid>
        </StyledGrid>
      )}
    </StyledGrid>
  );
};

export default RecordingConsentScreen;
