import React from "react";
import { Video } from "components/simple";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { breakpoints } from "style/constants";
import { GetOrganizationConsentFormInfo } from "graphql/user/user.gql";
import { useQuery } from "@apollo/react-hooks";

const ConsentVideo: React.FC = () => {
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const { data } = useQuery(GetOrganizationConsentFormInfo, {
    fetchPolicy: "no-cache"
  });

  const shouldHideConsentForm = data?.me?.organization?.hideConsentForm;

  return (
    <Video
      assessmentTitle={mobile ? "Inside the BrainHealth Project" : ""}
      title={mobile ? "" : "Inside the BrainHealth Project"}
      url="https://videos.ctfassets.net/n7r832fbv3mz/665nmX3OKRvFCYXl9YDJy5/aa3d1ff37a84a3751b6e50c7abd80fc8/Inside_the_BrainHealth_Project.mp4"
      timeLabel="03:35 min"
      actionLabel="Continue"
      onAction={
        shouldHideConsentForm
          ? () => history.push("/consent/join")
          : () => history.push("/consent/form?page=1")
      }
    />
  );
};

export default ConsentVideo;
