import React from "react";

interface Props {
  pillarColor: string;
  activePillar: string;
  width?: string;
  height?: string;
}
const PillarGraphIcon: React.FC<Props> = (props: Props) => {
  const activePillar = props.activePillar.toLowerCase().replace(" ", "-");

  function getOpacity(activePillar: string, pillarName: string): string {
    return activePillar === pillarName ? "100%" : "50%";
  }

  return (
    <svg
      width={props.width ? props.width : "162"}
      height={props.height ? props.width : "162"}
      viewBox="0 0 162 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="cognition"
        fill={props.pillarColor}
        fillOpacity={getOpacity(activePillar, "cognition")}
        d="M139.705 79.339C139.705 63.7862 133.526 48.8704 122.529 37.8729C111.531 26.8754 96.6157 20.6971 81.0629 20.6971C65.5101 20.6971 50.5943 26.8754 39.5968 37.8729C28.5993 48.8704 22.4209 63.7862 22.4209 79.339L52.1833 79.339C52.1833 71.6797 55.226 64.334 60.6419 58.9181C66.0579 53.5021 73.4035 50.4594 81.0629 50.4594C88.7222 50.4595 96.0678 53.5021 101.484 58.9181C106.9 64.3341 109.942 71.6797 109.942 79.339H139.705Z"
      />
      <path
        id="interaction"
        fill={props.pillarColor}
        fillOpacity={getOpacity(activePillar, "interaction")}
        d="M22.4215 81.5303C22.4215 91.906 25.1527 102.099 30.3405 111.085C35.5284 120.07 42.9902 127.532 51.9759 132.72L67.0745 106.568C62.6794 104.031 59.0297 100.381 56.4921 95.986C53.9546 91.5909 52.6187 86.6053 52.6187 81.5303L22.4215 81.5303Z"
      />
      <path
        id="well-being"
        fill={props.pillarColor}
        fillOpacity={getOpacity(activePillar, "well-being")}
        d="M53.7852 132.512C62.1626 137.349 71.6655 139.895 81.3388 139.895C91.0121 139.895 100.515 137.349 108.892 132.512L94.1266 106.937C90.2386 109.182 85.8283 110.364 81.3388 110.364C76.8494 110.364 72.439 109.182 68.551 106.937L53.7852 132.512Z"
      />
      <path
        id="daily-life"
        fill={props.pillarColor}
        fillOpacity={getOpacity(activePillar, "daily-life")}
        d="M110.298 131.934C119.284 126.746 126.746 119.284 131.934 110.298C137.122 101.313 139.853 91.1199 139.853 80.7441L109.656 80.7441C109.656 85.8191 108.32 90.8048 105.782 95.1999C103.245 99.595 99.5949 103.245 95.1998 105.782L110.298 131.934Z"
      />
    </svg>
  );
};

export default PillarGraphIcon;
