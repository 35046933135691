import { Box, Modal, IconButton, useMediaQuery } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { StyledGrid, StyledP, Button } from "components/simple";
import { Link } from "react-router-dom";
import { format, isSameDay } from "date-fns";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import { showErrorNotification, showSuccessNotification } from "state";
import { ReactComponent as CloseButton } from "assets/close.svg";
import CoachingCallImage from "assets/coachingcallblob.svg";

import { UpcomingSession } from "..";
import { CancelCoachingSession } from "graphql/sessions/sessions.gql";
import { CycleSummaryQuery } from "graphql/user/user.gql";
import { canJoinVideoCall } from "../../../utils";

interface UpcomingSessionProps {
  session: UpcomingSession;
  index: number;
}

const UpcomingCoachingSession: React.FC<UpcomingSessionProps> = ({
  session,
  index
}: UpcomingSessionProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showJoinCall, setShowJoinCall] = useState(canJoinVideoCall(session));

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const date = format(new Date(session.sessionTime), "MMMM d, yyyy");
  const time = format(new Date(session.sessionTime), "p");
  const timezoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const [cancelCoachingSession] = useMutation(CancelCoachingSession, {
    variables: {
      input: { acuityConfirmationNumber: session.acuityConfirmationNumber }
    },
    onCompleted: () => {
      dispatch(
        showSuccessNotification("Success", "Successfully cancelled appointment")
      );
    },
    onError: error => {
      dispatch(
        showErrorNotification(
          "Error",
          (error as any)?.graphQLErrors[0]?.message
        )
      );
    },
    refetchQueries: [{ query: CycleSummaryQuery }]
  });

  useEffect(() => {
    if (isSameDay(new Date(), new Date(session.sessionTime))) {
      const handle = setInterval(() => {
        setShowJoinCall(canJoinVideoCall(session));
      }, 1000 * 30); // Check every 30 seconds.

      return () => clearInterval(handle);
    }
  }, [session]);

  const message = `You have a video coaching session scheduled for ${time} ${timezoneAbbreviation} on ${date}. A button to join will appear here 30 minutes before the start.`;

  return (
    <StyledGrid item key={index} xs={mobile ? 12 : 6}>
      <StyledGrid
        alignItems="center"
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        height="100%"
        justifyContent="center"
        key={index}
        marginTop={spacingDefaults.xsmall}
        padding={spacingDefaults.large}
      >
        <StyledGrid item md={4}>
          <img
            alt="CoachingCallImage"
            src={CoachingCallImage}
            style={{ maxHeight: "200px" }}
            width="100%"
          />
        </StyledGrid>
        <StyledGrid item md={8} paddingLeft={spacingDefaults.normal}>
          <Box
            color={color.GRAPHITE}
            fontSize={fontSize.normal}
            component="p"
            margin="0"
          >
            {message}
          </Box>

          {showJoinCall ? (
            <Box margin={`${spacingDefaults.normal} 0 0 0`}>
              <Link to={`/coaching-sessions/${session.id}`}>
                <Button aria-label="go-to-coaching-call" longText fullWidth>
                  Join Video Session
                </Button>
              </Link>
            </Box>
          ) : (
            session.acuityConfirmationNumber && (
              <StyledGrid
                container
                spacing={1}
                marginTop={spacingDefaults.normal}
              >
                <StyledGrid item md={6} xs={12}>
                  <Button
                    aria-label="cancel-session"
                    longText
                    fullWidth
                    inverted
                    onClick={() => setShowModal(true)}
                  >
                    Cancel
                  </Button>
                </StyledGrid>
                <StyledGrid item md={6} xs={12}>
                  <Link
                    to={`/scheduling/${session.type}?reschedule=${session.acuityConfirmationNumber}`}
                  >
                    <Button aria-label="reschedule-session" longText fullWidth>
                      Reschedule
                    </Button>
                  </Link>
                </StyledGrid>
                <Modal open={showModal}>
                  <>
                    <StyledGrid
                      container
                      absoluteCenter
                      backgroundColor={color.WHITE}
                      borderRadius="8px"
                      direction="column"
                      maxWidth="500px"
                      mobileMaxWidth="98%"
                      padding={spacingDefaults.normal}
                    >
                      <StyledGrid item alignSelf="flex-end" position="fixed">
                        <IconButton
                          aria-label="close-modal"
                          onClick={() => setShowModal(false)}
                        >
                          <CloseButton
                            strokeWidth="1px"
                            height="15px"
                            width="15px"
                          />
                        </IconButton>
                      </StyledGrid>
                      <StyledGrid container item height="100%" width="100%">
                        <StyledGrid item width="100%" textAlign="center">
                          <StyledP
                            textAlign="center"
                            fontSize={fontSize.large}
                            fontWeight="500"
                            marginbottom={spacingDefaults.normal}
                          >
                            Cancel {session.type} session
                          </StyledP>
                        </StyledGrid>
                        <StyledGrid
                          item
                          width="100%"
                          textAlign="center"
                          marginBottom={spacingDefaults.normal}
                        >
                          <StyledGrid paddingBottom="16px">
                            <StyledP width="100%" textAlign="center">
                              Are you sure you want to cancel this{" "}
                              {session.type} session?
                            </StyledP>
                          </StyledGrid>
                        </StyledGrid>
                        <StyledGrid item width="100%" textAlign="center">
                          <Button
                            aria-label="cancel-session"
                            fullWidth={mobile}
                            onClick={() => {
                              cancelCoachingSession(), setShowModal(false);
                            }}
                          >
                            Cancel Session
                          </Button>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledGrid>
                  </>
                </Modal>
              </StyledGrid>
            )
          )}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default UpcomingCoachingSession;
