import React from "react";
import { useMediaQuery } from "@material-ui/core";

import { breakpoints } from "style/constants";
import { CoachingSession } from "../types";
import { StyledGrid } from "components/simple";
import CoachingSessionSummary from "./Summary";

interface CoachingSessionsProps {
  coachingSessions: CoachingSession[];
}

const CoachingSessions: React.FC<CoachingSessionsProps> = ({
  coachingSessions
}: CoachingSessionsProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid container spacing={2}>
      {coachingSessions.map((session, index) => {
        return (
          <StyledGrid key={index} item xs={mobile ? 12 : 6}>
            <CoachingSessionSummary
              index={index}
              coachingSession={session}
              acuityConfirmationNumber={session.acuityConfirmationNumber}
            />
          </StyledGrid>
        );
      })}
    </StyledGrid>
  );
};

export default CoachingSessions;
