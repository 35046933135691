import React from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { StyledGrid, StyledP, Button } from "components/simple";
import { color, spacingDefaults, fontSize } from "style/constants";
import { FaqData } from "./index";

interface Props {
  mobile: boolean;
  data: FaqData[];
  activeSection: string;
  setActiveSection: React.Dispatch<React.SetStateAction<string>>;
}
const Nav: React.FC<Props> = ({
  data,
  mobile,
  activeSection,
  setActiveSection
}: Props) => {
  return (
    <StyledGrid
      item
      xs={mobile ? 12 : 3}
      margin={mobile ? undefined : spacingDefaults.normal}
    >
      <StyledGrid>
        {!mobile && (
          <StyledGrid marginTop={spacingDefaults.medium} paddingLeft="0.5rem">
            <StyledP color={color.BLACK} fontSize={fontSize.large}>
              Table of Contents
            </StyledP>
          </StyledGrid>
        )}
        {data.map(section => {
          const isActive = activeSection === section.title;
          return (
            <StyledGrid
              key={section.title}
              marginTop={spacingDefaults.normal}
              paddingRight={spacingDefaults.normal}
              backgroundColor={color.WHITE}
              border={mobile ? `1px solid ${color.MUTEDGRAY}` : undefined}
              borderRadius="8px"
            >
              <Button
                plain
                fullWidth
                onClick={() => setActiveSection(section.title)}
                endIcon={mobile ? <ChevronRightIcon /> : null}
              >
                <StyledGrid
                  container
                  padding={
                    mobile ? spacingDefaults.normal : spacingDefaults.xsmall
                  }
                >
                  <StyledP
                    color={isActive ? color.DARKBLUE : color.BLACK}
                    fontSize={fontSize.medium}
                  >
                    {section.title}
                  </StyledP>
                </StyledGrid>
              </Button>
            </StyledGrid>
          );
        })}
      </StyledGrid>
    </StyledGrid>
  );
};

export default Nav;
