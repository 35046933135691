import React from "react";

import { StyledGrid } from "components/simple";
import { CoachingSessionStatus, SessionType } from "../../types";
import UpcomingGroupSession from "./UpcomingGroupSession";
import UpcomingImagingSession from "./UpcomingImagingSession";
import UpcomingCoachingSession from "./UpcomingCoachingSession";
export interface UpcomingSession {
  id: string;
  type: SessionType;
  title?: string;
  sessionTime: string;
  status: CoachingSessionStatus;
  acuityConfirmationNumber?: number;
  groupCoachingJoinLink?: string;
}

interface UpcomingSessionsProps {
  upcomingSessions: UpcomingSession[];
}

const UpcomingSessionCard: React.FC<UpcomingSessionsProps> = ({
  upcomingSessions
}: UpcomingSessionsProps) => {
  return (
    <StyledGrid container spacing={2}>
      {upcomingSessions.map((session, index) => {
        if (session.type === SessionType.GROUP) {
          return (
            <UpcomingGroupSession index={index} key={index} session={session} />
          );
        }

        if (session.type === SessionType.COACHING) {
          return (
            <UpcomingCoachingSession
              index={index}
              key={index}
              session={session}
            />
          );
        }

        if (session.type === SessionType.IMAGING) {
          return (
            <UpcomingImagingSession
              index={index}
              key={index}
              session={session}
            />
          );
        }
      })}
    </StyledGrid>
  );
};

export default UpcomingSessionCard;
