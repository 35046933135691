import styled from "styled-components";

export const StyledDiv = styled.div`
  && {
    .slides-controls {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 3%;
    }
    .arrow-indicators {
      background: none;
      border: none;
      cursor: pointer;
      margin-top: 15px;
    }
  }
`;
