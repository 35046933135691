import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledP } from "components/simple";
import React, { useCallback, useState } from "react";
import { color, spacingDefaults } from "style/constants";
import DateFnsUtils from "@date-io/date-fns";
import { addMonths, format, parseISO } from "date-fns";
import { createTheme, ThemeProvider } from "@material-ui/core";
import globalTheme from "style/theme";
import { ReactComponent as ChevronLeftIcon } from "assets/calendar-chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/calendar-chevron-right.svg";
import { useQuery } from "@apollo/react-hooks";
import { GetAvailabilityForMonth } from "graphql/sessions/sessions.gql";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MonthlyAvailablity } from "..";
import { isSameDay } from "date-fns";
import { showErrorNotification } from "state";
import { useDispatch } from "react-redux";

interface Props {
  currentDate: string;
  minDate: string;
  appointmentType: string;
  initialAppointments: MonthlyAvailablity[];
  onDateChange: (e: any) => void;
}

const SchedulingCalendarTheme = createTheme({
  ...globalTheme,
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: "100%"
      }
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        justifyContent: "space-between",
        border: `1px solid ${color.GRAY}`,
        borderRadius: "10px"
      }
    },
    MuiTypography: {
      root: {
        color: color.BLUE,
        textAlign: "center"
      }
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        width: "40%"
      },
      switchHeader: {
        justifyContent: "center"
      },
      iconButton: {
        "& svg": {
          "& path": {
            stroke: color.DARKBLUE,
            strokeOpacity: 1
          }
        },
        "&:disabled": {
          opacity: 0.3,
          "& svg": {
            "& path": {
              display: "none"
            }
          }
        }
      },
      daysHeader: {
        justifyContent: "space-around"
      }
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: "space-around"
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: color.DARKBLUE,
        color: color.WHITE,
        "&:hover": {
          backgroundColor: color.BLUE
        }
      },
      current: {
        color: color.BLACK
      }
    }
  }
} as any);

const Calendar: React.FC<Props> = ({
  currentDate,
  minDate,
  appointmentType,
  initialAppointments,
  onDateChange
}) => {
  const dispatch = useDispatch();
  const [currentMonth, setCurrentMonth] = useState<MaterialUiPickersDate>();
  const [nextMonthsAvailability, setNextMonthsAvailability] = useState<
    MonthlyAvailablity[]
  >([]);

  useQuery(GetAvailabilityForMonth, {
    variables: {
      input: {
        monthWithYear: currentMonth,
        appointmentType,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    fetchPolicy: "cache-and-network",
    skip: !currentMonth,
    onCompleted: nextMonthAvailability => {
      setNextMonthsAvailability(nextMonthAvailability.getAvailabilityForMonth);
    },
    onError() {
      dispatch(
        showErrorNotification("", "Error retrieving next month's availability")
      );
    }
  });

  // Sets the dates without available times to be disabled
  const findUnavailableDates = useCallback(
    (e: MaterialUiPickersDate) => {
      const initialDates = initialAppointments.find((t: MonthlyAvailablity) =>
        isSameDay(new Date(t.date), new Date(e as Date))
      );

      const nextMonthDates = nextMonthsAvailability.find(
        (t: MonthlyAvailablity) =>
          isSameDay(new Date(t.date), new Date(e as Date))
      );

      if (initialDates || nextMonthDates) {
        return false;
      } else {
        return true;
      }
    },
    [initialAppointments, nextMonthsAvailability]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={SchedulingCalendarTheme}>
        <StyledP textAlign="center" marginbottom={spacingDefaults.medium}>
          Select a Date:
        </StyledP>
        <DatePicker
          disablePast
          disableToolbar
          variant="static"
          leftArrowIcon={<ChevronLeftIcon height={20} />}
          rightArrowIcon={<ChevronRightIcon height={20} />}
          value={currentDate}
          minDate={minDate}
          maxDate={addMonths(new Date(minDate), 6)}
          onChange={e => onDateChange(e)}
          onMonthChange={e => setCurrentMonth(e)}
          shouldDisableDate={e => findUnavailableDates(e)}
          type={"date"}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default Calendar;
