import React from "react";
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme, SnackbarContent } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { color, spacingDefaults } from "style/constants";
import globalTheme from "style/theme";
import {
  NotificationBody,
  NotificationCard,
  NotificationFooter,
  NotificationTitle
} from "./styled";

interface CustomProps {
  description?: string;
  forDrawer?: boolean;
  sentAt?: string;
  status: "dismissed" | "error" | "info" | "success";
  title?: string;
  hideNotification: React.Dispatch<React.SetStateAction<void>>;
  onClick?: () => void;
}

type NotifierProps = CustomProps & SnackbarProps;

const Notifier: React.FC<NotifierProps> = ({
  description,
  open,
  sentAt,
  status,
  title,
  forDrawer = false,
  hideNotification,
  onClick
}: NotifierProps) => {
  const notifierColor = {
    dismissed: color.GRAY,
    error: color.DARKRED,
    info: color.BLUE,
    success: color.GREEN
  };

  const notifierTheme = createTheme({
    // TODO: move this to style/themes
    ...globalTheme,
    overrides: {
      MuiSnackbar: {
        root: {
          borderRadius: "8px",
          cursor: forDrawer ? "pointer" : "default",
          marginTop: forDrawer ? spacingDefaults.xsmall : "0",
          position: forDrawer ? "revert" : "fixed"
        },
        anchorOriginTopRight: {
          justifyContent: forDrawer ? "flex-start" : "flex-end"
        }
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: color.WHITE,
          borderBottom: forDrawer ? `1px solid ${color.GRAY}` : "none",
          borderLeft: `10px solid ${notifierColor[status]}`,
          borderRight: forDrawer ? `1px solid ${color.GRAY}` : "none",
          borderTop: forDrawer ? `1px solid ${color.GRAY}` : "none",
          borderRadius: "8px",
          boxShadow: forDrawer ? "none" : "0px 2px 8px rgba(0, 0, 0, 0.2)",
          color: color.BLACK,
          width: forDrawer ? "100%" : "auto"
        }
      }
    }
  });

  const action = forDrawer
    ? []
    : [
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={() => {
            hideNotification();
          }}
        >
          <CloseIcon />
        </IconButton>
      ];

  return (
    <ThemeProvider theme={notifierTheme}>
      <Snackbar
        onClick={onClick}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        autoHideDuration={5000}
        onClose={() => {
          hideNotification();
        }}
        open={open}
      >
        <SnackbarContent
          action={action}
          message={
            <NotificationCard>
              <NotificationTitle>{title}</NotificationTitle>
              <NotificationBody>{description}</NotificationBody>
              {forDrawer && <NotificationFooter>{sentAt}</NotificationFooter>}
            </NotificationCard>
          }
        />
      </Snackbar>
    </ThemeProvider>
  );
};

export default Notifier;
