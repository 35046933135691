import { Box } from "@material-ui/core";
import React from "react";

import { ReactComponent as EmailIcon } from "assets/email.svg";
import { StyledGrid, StyledP, Switch } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";

interface EmailPreferenceProps {
  checked: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  required?: boolean;
}

const EmailPreference: React.FC<EmailPreferenceProps> = ({
  checked,
  handleChange,
  required = false
}: EmailPreferenceProps) => (
  <StyledGrid
    alignItems="center"
    container
    height="60px"
    padding={`0 ${spacingDefaults.large}`}
  >
    <StyledGrid alignItems="center" container item xs>
      <EmailIcon />

      <Box
        color={color.DARKERGRAY}
        component="p"
        fontSize={fontSize.small}
        lineHeight="150%"
        margin={`0 0 0 ${spacingDefaults.xsmall}`}
      >
        Email
      </Box>
    </StyledGrid>
    {required ? (
      <StyledP style={{ fontStyle: "italic" }}> Required</StyledP>
    ) : (
      <Switch checked={checked} onChange={handleChange} />
    )}
  </StyledGrid>
);

export default EmailPreference;
