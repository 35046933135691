import styled from "styled-components";

import { breakpoints, color, fontFamily, fontSize } from "style/constants";

export const PlayButton = styled.img`
  cursor: pointer;
`;

export const TimeText = styled.p`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.small};
  color: ${color.WHITE};
  text-align: center;
  margin: 0;
  padding: 5px 8px;
  letter-spacing: -0.44px;
`;

export const Title = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    color: ${color.BLACK};
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.header};
  font-weight: 600;
  line-height: 2.5625rem;
`;

export const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const AssessmentTitle = styled("h1")`
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  margin: 2rem 0 1rem 0;
`;

export const Body = styled("div")`
  color: ${color.DARKERGRAY};
  font-family: ${fontFamily.primary};
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  margin: 0 0 2rem 0;
`;
