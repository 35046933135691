import { IconButton, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { Facebook, LinkedIn, Twitter, Link } from "@material-ui/icons";
import { useApolloClient } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { parse } from "qs";

import { StyledGrid, StyledP } from "components/simple";
import { color, fontFamily, spacingDefaults } from "style/constants";
import { Button } from "components/simple";
import { GetOAuthLink } from "graphql/social/linkedIn";
import { showErrorNotification } from "state";
import { ReactComponent as ReferralImage } from "assets/referral-banner-desktop.svg";

interface ReferralBannerProps {
  referralLink: string;
  totalReferrals: number;
}

const DeskTopReferralBanner: React.FC<ReferralBannerProps> = ({
  referralLink,
  totalReferrals
}: ReferralBannerProps) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  let numberToNextBadge = 0;
  if (totalReferrals < 1) {
    numberToNextBadge = 1;
  } else if (totalReferrals < 5) {
    numberToNextBadge = 5 - totalReferrals;
  } else if (totalReferrals < 10) {
    numberToNextBadge = 10 - totalReferrals;
  } else if (totalReferrals < 25) {
    numberToNextBadge = 25 - totalReferrals;
  } else if (totalReferrals < 50) {
    numberToNextBadge = 50 - totalReferrals;
  } else {
    numberToNextBadge = totalReferrals;
  }

  const [showReferralTooltip, setShowReferralTooltip] = useState(false);
  const encodedUri = encodeURI(referralLink);

  const handleLinkedInClick = async (): Promise<void> => {
    const response = await client.query({ query: GetOAuthLink });

    if (response.data?.linkedInOAuthUrl) {
      const params = parse(response.data.linkedInOAuthUrl, {
        ignoreQueryPrefix: true
      });

      const state = params.state;

      sessionStorage.setItem(
        state,
        JSON.stringify({
          action: "shareReferral",
          data: {
            platform: "linkedIn",
            referralLink: encodedUri
          }
        })
      );

      window.location.assign(response.data.linkedInOAuthUrl);
    } else {
      dispatch(showErrorNotification("", "Unable to connect you to LinkedIn"));
    }
  };

  const handleFacebookClick = async (): Promise<void> => {
    const path = `https://www.facebook.com/sharer/sharer.php?u=${encodedUri}`;
    const features = "width=800, height=500, left=300, top=200";
    window.open(path, "", features);
  };

  const handleTwitterClick = async (): Promise<void> => {
    const path = `https://twitter.com/intent/tweet?text=I%27m%20proactive%20about%20my%20brain%27s%20health%20and%20performance%20--%20you%20can%20be%20too%2C%20by%20joining%20The%20BrainHealth%20Project!&url=${encodedUri}`;
    const features = "width=800, height=500, left=300, top=200";
    window.open(path, "", features);
  };

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      borderRadius="8px"
      container
      alignItems="center"
      justifyContent="space-around"
      height="96px"
    >
      <StyledGrid
        height="96px"
        item
        marginLeft={spacingDefaults.large}
        marginRight={spacingDefaults.large}
        marginTop="-30px"
      >
        <ReferralImage height={160} />
      </StyledGrid>
      <StyledGrid justifyContent="center" container direction="column" item xs>
        <StyledGrid marginBottom={spacingDefaults.xsmall} item>
          <StyledP
            color={color.BLACK}
            fontFamily={fontFamily.secondary}
            fontSize="26px"
          >
            Share the Gift of BrainHealth
          </StyledP>
        </StyledGrid>
        <StyledP
          color={color.DARKERGRAY}
          fontSize="16px"
          padding={"0 10px 0 0"}
        >
          {numberToNextBadge < 50
            ? `Recruit ${numberToNextBadge} friend${
                numberToNextBadge === 1 ? "" : "s"
              } to earn your next badge`
            : `You've invited ${numberToNextBadge} friends. Consider yourself a BrainHealth Ambassador!`}
        </StyledP>
      </StyledGrid>
      <StyledGrid
        marginTop={spacingDefaults.small}
        item
        display="flex"
        marginRight="30px"
      >
        <IconButton size="small" onClick={handleLinkedInClick}>
          <LinkedIn style={{ color: color.DARKBLUE, fontSize: 28 }} />
        </IconButton>
        <IconButton size="small" onClick={handleFacebookClick}>
          <Facebook style={{ color: color.DARKBLUE, fontSize: 28 }} />
        </IconButton>
        <IconButton size="small" onClick={handleTwitterClick}>
          <Twitter style={{ color: color.DARKBLUE, fontSize: 28 }} />
        </IconButton>
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={showReferralTooltip}
          onClose={() => setShowReferralTooltip(false)}
          placement="right"
          title="Copied to clipboard!"
          style={{ marginLeft: "7px" }}
        >
          <div>
            <Button
              compressed
              longText
              onClick={() => {
                setShowReferralTooltip(true);
                setTimeout(() => setShowReferralTooltip(false), 2000);

                navigator.clipboard.writeText(referralLink);
              }}
            >
              <StyledGrid
                alignItems="center"
                container
                padding={spacingDefaults.normal}
              >
                <Link />
                <StyledGrid item marginLeft={spacingDefaults.xsmall}>
                  Referral Link
                </StyledGrid>
              </StyledGrid>
            </Button>
          </div>
        </Tooltip>
      </StyledGrid>
    </StyledGrid>
  );
};

export default DeskTopReferralBanner;
