import { State, Subdomain, User, UserStatus } from "./types";

export const getUserStatus = (state: State): UserStatus => {
  const user = state.user;

  // NOTE - The order below provides the precedence for different user
  // statuses. For example, if a user has opted out and is also inactive, then
  // the opt-out status takes precedence.

  if (user?.eligibleForStudy === true && user?.waitlisted === true) {
    return UserStatus.WAITLISTED;
  }

  if (user?.eligibleForStudy === true && user?.enrollmentConsent === false) {
    return UserStatus.QUALIFIED_AND_UNCONSENTED;
  }

  if (user?.active === false) {
    return UserStatus.INACTIVE;
  }

  if (user?.eligibleForStudy === true && user?.enrollmentConsent === null) {
    return UserStatus.QUALIFIED;
  }

  if (user?.eligibleForStudy === true && user?.enrollmentConsent === true) {
    return UserStatus.QUALIFIED_AND_CONSENTED;
  }

  if (user?.eligibleForStudy === false) {
    return UserStatus.DISQUALIFIED;
  }

  return UserStatus.UNKNOWN;
};

export const getUser = (state: State): Partial<User> | null => state.user;
export const getSubdomain = (state: State): Subdomain | null => state.subdomain;

export const getNativeExperience = (state: State): Boolean =>
  state.forNativeExperience;
