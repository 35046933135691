import { useQuery } from "@apollo/react-hooks";
import { Grid, Hidden } from "@material-ui/core";
import {
  getOrgConfig,
  isFeatureEnabled
} from "graphql/featureFlags/featureFlags.gql";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { StyledTab as Tab, StyledTabs as Tabs } from "./styled";

interface NavigationProps {
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

const getTab = (location: string): number | boolean => {
  switch (location) {
    case "/dashboard":
      return 0;
    case "/":
      return 0;
    case "/index":
      return 1;
    case "/training":
      return 2;
    case "/resources":
      return 3;
    default:
      return false;
  }
};

const Navigation: React.FC<NavigationProps> = ({
  setActive,
  show
}: NavigationProps) => {
  const history = useHistory();
  const location = history.location.pathname;

  const [isRefactorEnabled, setIsRefactorEnabled] = useState<boolean>(false);
  const [isResourcesEnabled, setIsResourcesEnabled] = useState<boolean>(false);
  const [isTrainingsEnabled, setIsTrainingsEnabled] = useState<boolean>(false);

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Refactor" } },
    onCompleted: data => setIsRefactorEnabled(data.isFeatureEnabled.enabled)
  });

  const { data } = useQuery(getOrgConfig, {
    onCompleted: d => {
      setIsResourcesEnabled(d.getOrgConfig.isResourceEnabled);
      setIsTrainingsEnabled(d.getOrgConfig.isTrainingEnabled);
    }
  });

  const [pathsToShow, setPathsToShow] = useState<String[]>([
    "/dashboard",
    "/profile",
    "/resources",
    "/training",
    "/faq",
    "/courses",
    "/myjourney"
  ]);

  const [value, setValue] = useState<number | boolean>(false);

  useEffect(() => {
    const tab = getTab(location);
    if (!tab) {
      setActive(false);
    }
    setValue(tab);
  }, [location, setValue, setActive]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setActive(true);
    setValue(newValue);
  };

  useEffect(() => {
    if (isRefactorEnabled) {
      setPathsToShow([...pathsToShow, "/index"]);
    }
  }, [isRefactorEnabled]);

  useEffect(() => {
    if (data && data.getOrgConfig) {
      setIsResourcesEnabled(data.getOrgConfig.isResourcesEnabled);
      setIsTrainingsEnabled(data.getOrgConfig.isTrainingEnabled);
    }
  }, [data]);

  return (
    <>
      {(pathsToShow.some(
        path => path === location || location.includes(path + "/")
      ) ||
        show) && (
        <Grid item>
          <Hidden mdDown>
            <Tabs
              aria-label="tabs navigation"
              onChange={handleChange}
              value={value}
              variant="fullWidth"
            >
              <Tab component={Link} to="/dashboard" label="Dashboard" />
              {isRefactorEnabled && (
                <Tab component={Link} to="/index" label="Index" />
              )}
              {isTrainingsEnabled && (
                <Tab component={Link} to="/training" label="Training" />
              )}
              {isResourcesEnabled && (
                <Tab component={Link} to="/resources" label="Resources" />
              )}
            </Tabs>
          </Hidden>
        </Grid>
      )}
    </>
  );
};

export default Navigation;
