import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import { ReactComponent as BrainImage } from "assets/amorphous-brain.svg";
import { ReactComponent as FaceImage } from "assets/faceless-head.svg";
import { StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import ExploreButton from "./Button";

interface ExploreProps {
  disabled?: boolean;
}

const Explore: React.FC<ExploreProps> = ({
  disabled = false
}: ExploreProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid container>
      <StyledGrid item marginBottom={spacingDefaults.normal}>
        <Box
          color={color.BLACK}
          component="h2"
          fontSize="20px"
          fontWeight="normal"
          lineHeight="24px"
          margin="0"
        >
          Explore
        </Box>
      </StyledGrid>

      <StyledGrid container item spacing={2}>
        <StyledGrid item lg={6} xs={12}>
          <ExploreButton
            link={routesConfig.profile.path}
            backgroundColor={color.GREEN}
            title="Update your Profile"
            subtitle="View My Profile"
            image={<FaceImage />}
            disabled={disabled}
          />
        </StyledGrid>

        <StyledGrid item lg={6} xs={12}>
          <ExploreButton
            link={routesConfig.training.path}
            backgroundColor={color.DARKERBLUE}
            title="Explore your Training"
            subtitle="View My Training"
            image={<BrainImage />}
            disabled={disabled}
          />
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Explore;
