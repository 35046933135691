import React from "react";
import { CircularProgress } from "@material-ui/core";

import styled from "styled-components";

import { color } from "style/constants";
import { Habit, UserHabit } from "models/userHabits";
import MiniCard from "./MiniCard";
import LongCard from "./LongCard";

export const CircularProgressBackground = styled(CircularProgress)`
  && {
    color: ${color.MUTEDGRAY};
  }
`;

interface HabitCardProps {
  habit: Habit;
  listSize: number;
  userHabit: UserHabit;
  disabled?: boolean;
  onClick?: () => void;
  mobile: boolean;
  setSelectedHabit: (habit: UserHabit) => void;
}

const PinnedHabitCard: React.FC<HabitCardProps> = props => {
  const { listSize } = props;

  const isOneCard = listSize == 1;
  if (isOneCard) return <LongCard {...props} />;
  return <MiniCard {...props} />;
};
export default PinnedHabitCard;
