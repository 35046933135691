import { Hidden, IconButton, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { ReactComponent as PartyImage } from "assets/party.svg";
import { ReactComponent as PartyConfettiImage } from "assets/party-confetti.svg";
import { StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  iconSizes,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";

interface SurveyBannerProps {
  dismiss(): Promise<void>;
  years: number;
}

const SurveyBanner: React.FC<SurveyBannerProps> = ({
  dismiss,
  years
}: SurveyBannerProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.WHITE}
      borderRadius="8px"
      container
      overflow={mobile ? "hidden" : undefined}
      padding="20px"
      position="relative"
      onClick={
        mobile
          ? () => history.push(routesConfig.surveys.survey.path)
          : undefined
      }
    >
      <Hidden mdDown>
        <StyledGrid item height="50px" position="relative">
          <PartyImage
            height={350}
            style={{
              position: "absolute",
              top: "-150px"
            }}
            width={200}
          />
        </StyledGrid>

        <StyledGrid
          container
          direction="column"
          item
          marginLeft="220px"
          marginRight="120px"
          xs
        >
          <StyledGrid item marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.large}
              aria-label="update"
            >
              It&#39;s your <strong>brain-iversary!</strong> A lot can change in
              a year. Please update your records.
            </StyledP>
          </StyledGrid>

          <StyledP color={color.BLACK} aria-label="years">
            Thank you for being part of the Project for {years} year(s).
          </StyledP>
          {/* </StyledGrid>

        //<StyledGrid item marginRight="30px"> */}
          <Link to={routesConfig.surveys.survey.path} aria-label="go-to-survey">
            {/* <Button  inverted longText width="200px"> */}
            <StyledP color={color.BLACK} margintop={spacingDefaults.small}>
              <strong>Update (2 min) &gt;</strong>
            </StyledP>
            {/* </Button> */}
          </Link>
        </StyledGrid>
        <StyledGrid item height="50px" position="relative">
          <PartyConfettiImage
            height={220}
            style={{
              position: "absolute",
              top: "-80px",
              right: "0px"
            }}
            width={150}
          />
        </StyledGrid>
      </Hidden>
      <Hidden lgUp>
        <StyledGrid item height="0px" position="relative" width="60px">
          <PartyImage
            height={100}
            style={{
              left: "-10px",
              position: "absolute",
              top: "-30px"
            }}
            width={80}
          />
        </StyledGrid>

        <StyledGrid container direction="column" item marginLeft="80px">
          <StyledP
            color={color.BLACK}
            fontSize={fontSize.medium}
            aria-label="update-mobile"
          >
            It&#39;s your <strong>brain-iversary!</strong>
          </StyledP>

          <StyledP color={color.BLACK} aria-label="go-to-survey-mobile">
            <strong>Please update your records. &gt;</strong>
          </StyledP>
        </StyledGrid>
      </Hidden>

      <StyledGrid item position="absolute" right="5px" top="5px">
        <IconButton
          aria-label="close-notification"
          onClick={event => {
            event.stopPropagation();
            dismiss();
          }}
        >
          <CloseIcon
            stroke={color.WHITE}
            height={iconSizes.smaller}
            width={iconSizes.smaller}
          />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default SurveyBanner;
