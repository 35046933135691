import styled from "styled-components";

import { breakpoints, color, spacingDefaults } from "style/constants";
import { BHIChartType } from ".";
import StyledButton from "../Button";

interface BHIChart {
  animationDuration: number;
  clarity: number;
  emotionalBalance: number;
  connectedness: number;
  type: BHIChartType;
}

interface RadarLabel {
  flipText: boolean;
}

interface LabelContainer {
  angle: number;
  originX: number;
  originY: number;
}

export const ChartContainer = styled.div<BHIChart>`
  aspect-ratio: 1 / 1;
  background-color: ${color.WHITE};
  border: ${({ type }) => type == BHIChartType.Tree && "23.75px solid white"};
  border-radius: 50%;
  color: ${color.BLACK};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: auto;
  max-height: 400px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition-timing-function: linear;
  transition: transform ${({ animationDuration }) => animationDuration / 2}s;
  width: 100%;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin: 30px auto;
    padding: 0;
  }

  svg {
    left: 50%;
    max-height: 108%;
    position: absolute;
    transform: translateX(-50%);
  }

  #leaves path {
    transform-box: fill-box;
    transform-origin: unset;
    transition: transform 0.5s;
  }

  --min-leaf-size: scale(0.25635);

  #clarity {
    transform: var(--min-leaf-size);
  }

  #emotionalBalance {
    transform: var(--min-leaf-size);
  }

  #connectedness {
    transform: var(--min-leaf-size);
  }

  &.rotateIn {
    transform: rotateY(90deg);

    & > div,
    canvas,
    svg {
      visibility: hidden;
    }
  }

  &.scaleIn {
    #clarity {
      transform: scale(${({ clarity }) => clarity});
    }
    #emotionalBalance {
      transform: scale(${({ emotionalBalance }) => emotionalBalance});
    }
    #connectedness {
      transform: scale(${({ connectedness }) => connectedness});
    }
  }
`;

export const ChartContainerWrapper = styled.div`
  margin: auto;
  position: relative;
  max-width: 400px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 30px;
  }
`;

export const ChartSwitchButton = styled(StyledButton)`
  && {
    background-size: unset;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 50px;
    z-index: 0;

    &:focus {
      outline: none;
    }
  }
`;

export const LabelContainer = styled.div<LabelContainer>`
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: ${({ originX }) => originX}px ${({ originY }) => originY}px;
  transform: rotateZ(${({ angle }) => angle}deg) translateY(30px);
`;

export const RadarLabel = styled.p<RadarLabel>`
  color: ${color.BLACK_RGBA};
  font-size: ${spacingDefaults.normal};
  margin: 0;
  transform: ${({ flipText }) => flipText && "scale(-1)"};
`;
