import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";

import theme from "style/theme";
import { ConfirmationModal } from "components/simple";
import { ReactComponent as Warning } from "assets/warning-red.svg";
import routesConfig from "utils/routesConfig";
import { trackEvent } from "analytics";

const customUserConfirmation = (
  message: string,
  callback: (ok: boolean) => void
): void => {
  const modal = document.createElement("div");
  modal.setAttribute("custom-confirmation-navigation", "");
  document.body.appendChild(modal);

  const withCleanup = (answer: boolean): void => {
    ReactDOM.unmountComponentAtNode(modal);
    document.body.removeChild(modal);
    callback(answer);
  };

  function getModal(choice: string): JSX.Element {
    switch (choice) {
      case "quit-assessment": {
        return (
          <ConfirmationModal
            icon={<Warning width={"150px"} height={"150px"} />}
            title={"Are you sure you want to quit your assessment?"}
            content={
              "You will lose all of your data from this assessment if you exit now."
            }
            confirmText={"Yes, exit assessment"}
            onConfirm={() => {
              const url = routesConfig.assessments.assessment.path;

              trackEvent(window._paq, {
                url,
                actions: ["Assessments", "Quit Assessment"]
              });

              withCleanup(true);
            }}
            cancelText={"Oh, wait. Never mind"}
            onCancel={() => withCleanup(false)}
          />
        );
      }

      case "quit-survey": {
        return (
          <ConfirmationModal
            icon={<Warning width={"150px"} height={"150px"} />}
            title={"Are you sure you want to exit this survey?"}
            content={
              "You will lose all of your progress in this survey if you exit now."
            }
            confirmText={"Yes, exit survey"}
            onConfirm={() => {
              const url = routesConfig.surveys.survey.path;

              trackEvent(window._paq, {
                url,
                actions: ["Surveys", "Quit Survey"]
              });

              withCleanup(true);
            }}
            cancelText={"Oh, wait. Never mind"}
            onCancel={() => withCleanup(false)}
          />
        );
      }

      default:
        return <></>;
    }
  }

  ReactDOM.render(
    <ThemeProvider theme={theme}>{getModal(message)}</ThemeProvider>,
    modal
  );
};

export default customUserConfirmation;
