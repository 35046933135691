import { Box, IconButton } from "@material-ui/core";
import { format } from "date-fns";
import React, { useState } from "react";

import { ReactComponent as ChevronDownIcon } from "assets/chevron-down.svg";
import { Button, StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";

interface NotificationTimePreferenceProps {
  prompt: string;
  value: Date;
  handleChange: (value: string) => void;
}

const NotificationTimePreference: React.FC<NotificationTimePreferenceProps> = ({
  prompt,
  value,
  handleChange
}: NotificationTimePreferenceProps) => {
  const [showTimeSelection, setShowTimeSelection] = useState(false);

  const [hours, setHours] = useState(value.getHours() % 12);
  const [minutes, setMinutes] = useState(value.getMinutes());
  const [period, setPeriod] = useState(value.getHours() < 12 ? "AM" : "PM");

  return showTimeSelection ? (
    <StyledGrid
      container
      item
      justifyContent="center"
      padding={spacingDefaults.large}
    >
      <Box
        component="h3"
        fontSize={fontSize.small}
        lineHeight="150%"
        margin="0"
      >
        <strong>{prompt}</strong>
      </Box>

      <StyledGrid
        alignItems="center"
        container
        item
        justifyContent="center"
        margin={`${spacingDefaults.normal} 0`}
        xs={12}
      >
        <StyledGrid item>
          <IconButton
            aria-label="increase-hours"
            onClick={() => setHours(hours < 12 ? hours + 1 : 1)}
          >
            <ChevronDownIcon
              stroke={color.BLACK}
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>

          <StyledGrid
            border={`1px solid ${color.PALEGRAY}`}
            borderRadius="8px"
            centerContent
            container
            height="35px"
          >
            <Box color={color.DARKBLUE}>{hours < 10 ? `0${hours}` : hours}</Box>
          </StyledGrid>

          <IconButton
            aria-label="decrease-hours"
            onClick={() => setHours(hours > 1 ? hours - 1 : 12)}
          >
            <ChevronDownIcon stroke={color.BLACK} />
          </IconButton>
        </StyledGrid>

        <StyledGrid item margin={`0 4px`}>
          :
        </StyledGrid>

        <StyledGrid item marginRight={spacingDefaults.xsmall}>
          <IconButton
            aria-label="increase-minutes"
            onClick={() => setMinutes(minutes < 59 ? minutes + 1 : 0)}
          >
            <ChevronDownIcon
              stroke={color.BLACK}
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>

          <StyledGrid
            border={`1px solid ${color.PALEGRAY}`}
            borderRadius="8px"
            centerContent
            container
            height="35px"
          >
            <Box color={color.DARKBLUE}>
              {minutes < 10 ? `0${minutes}` : minutes}
            </Box>
          </StyledGrid>

          <IconButton
            aria-label="decrease-minutes"
            onClick={() => setMinutes(minutes > 0 ? minutes - 1 : 59)}
          >
            <ChevronDownIcon stroke={color.BLACK} />
          </IconButton>
        </StyledGrid>

        <StyledGrid item>
          <IconButton onClick={() => setPeriod(period === "AM" ? "PM" : "AM")}>
            <ChevronDownIcon
              aria-label="increase-time-period"
              stroke={color.BLACK}
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>

          <StyledGrid
            border={`1px solid ${color.PALEGRAY}`}
            borderRadius="8px"
            centerContent
            container
            height="35px"
          >
            <Box color={color.DARKBLUE}>{period}</Box>
          </StyledGrid>

          <IconButton
            aria-label="decrease-time-period"
            onClick={() => setPeriod(period === "AM" ? "PM" : "AM")}
          >
            <ChevronDownIcon stroke={color.BLACK} />
          </IconButton>
        </StyledGrid>
      </StyledGrid>

      <StyledGrid container item justifyContent="center" xs={12}>
        <Button
          compressed
          onClick={() => {
            handleChange(`${period === "AM" ? hours : hours + 12}:${minutes}`);
            setShowTimeSelection(false);
          }}
        >
          Update Time
        </Button>
      </StyledGrid>

      <StyledGrid container item justifyContent="center" xs={12}>
        <Button
          onClick={() => {
            reset();
            setShowTimeSelection(false);
          }}
          plain
        >
          <Box component="span" fontSize="12px" lineHeight="14px">
            Cancel
          </Box>
        </Button>
      </StyledGrid>
    </StyledGrid>
  ) : (
    <StyledGrid
      alignItems="center"
      container
      height="60px"
      padding={`0 ${spacingDefaults.normal} 0 ${spacingDefaults.large}`}
    >
      <StyledGrid alignItems="center" container item xs>
        <Box
          color={color.BLACK}
          component="p"
          fontSize={fontSize.small}
          lineHeight="150%"
          margin="0"
        >
          <strong>Notification Time:</strong>
        </Box>

        <Box
          color={color.DARKBLUE}
          component="p"
          fontSize={fontSize.small}
          fontWeight="300"
          lineHeight="150%"
          margin={`0 0 0 ${spacingDefaults.xsmall}`}
        >
          {value ? format(value, "hh:mm aa") : "10:30 AM"}
        </Box>
      </StyledGrid>

      <StyledGrid item>
        <Button onClick={() => setShowTimeSelection(true)} plain>
          <Box
            component="span"
            fontSize="12px"
            lineHeight="14px"
            style={{ textDecoration: "underline" }}
          >
            Edit
          </Box>
        </Button>
      </StyledGrid>
    </StyledGrid>
  );

  function reset(): void {
    setHours(value.getHours() % 12);
    setMinutes(value.getMinutes());
    setPeriod(value.getHours() < 12 ? "AM" : "PM");
  }
};

export default NotificationTimePreference;
