import { StyledGrid } from "components/simple";
import { breakpoints, spacingDefaults } from "style/constants";
import styled from "styled-components";

export const StyledTreesWeeklyGrid = styled(StyledGrid)`
    align-content: center;
    align-items: center;
    flex: flex-grow;
    flex-direction: column;
    flex-wrap: nowrap
    gap: 0 ${spacingDefaults.medium};
    height: calc(100% - "546px"),
    justify-content: center;
    margin-top: ${spacingDefaults.xsmall};
    margin-bottom: 150px;
    padding: ${spacingDefaults.large} 0;
    width: 100%;


    @media (max-width: ${breakpoints.lg}px) {
      gap: ${spacingDefaults.xsmall};
      padding: ${spacingDefaults.normal} 0px;
    }

    @media (max-height: ${breakpoints.lg}px) {
      gap: 0px;
      height: calc(100% - "587px");
      margin-top: 0px;
      margin-bottom: 200px;
      padding: 0 ${spacingDefaults.xsmall};
    }
`;

export const StyledTreesDaysRow = styled(StyledGrid)`
  align-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap
  gap: 5rem;
  height: 100%;
  justify-content: center;
  padding: 0px ${spacingDefaults.large}
  width: 100%;


  @media (max-width: ${breakpoints.lg}px) {
    gap: ${spacingDefaults.large};
    padding-left: ${spacingDefaults.normal};
    padding-right: ${spacingDefaults.normal};
  }

  @media (max-height: ${breakpoints.lg}px) {
    padding-top: ${spacingDefaults.xsmall};
    padding-bottom: ${spacingDefaults.xsmall};
  }
`;

export const StyledTreeCard = styled(StyledGrid)`
    align-items: center;
    flex: flex-grow;
    flex-direction: column;
    flex-wrap: nowrap
    max-height: 175px;
    justify-content: center;
    padding: ${spacingDefaults.normal} 0;

    @media (max-height: ${breakpoints.lg}px) {
      padding: ${spacingDefaults.xsmall} 0;
    }
  }
`;

export const JourneyTreeImg = styled.img`
  align-self: center;

  @media (max-width: ${breakpoints.md}px) {
    height: 100px;
    padding: ${spacingDefaults.normal} 0;
  }

  @media (max-height: ${breakpoints.lg}px) {
    height: 100px;
    padding: ${spacingDefaults.small} 0;
  }
`;
