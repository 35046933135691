import { Box } from "@material-ui/core";
import React from "react";

import { ReactComponent as FinishLineImage } from "assets/finish-line.svg";
import { color, fontFamily, spacingDefaults } from "style/constants";

const AssessmentsComplete: React.FC = () => (
  <>
    <Box marginBottom={spacingDefaults.medium}>
      <FinishLineImage height={160} width={240} />
    </Box>

    <Box
      component="h3"
      fontFamily={fontFamily.secondary}
      fontWeight="normal"
      fontSize="26px"
      letterSpacing="-0.5px"
      lineHeight="30px"
      margin={`0 0 ${spacingDefaults.normal} 0`}
      textAlign="center"
    >
      You&apos;re done!
    </Box>

    <Box
      color={color.DARKERGRAY}
      component="p"
      fontSize="14px"
      lineHeight="21px"
      margin="0"
      maxWidth="340px"
      textAlign="center"
    >
      Trained clinicians are now preparing your personalized results and
      recommendations. You will receive an email/text when they are ready to
      view.
      <br />
      <br />
      You may need to check your junk or spam folder.
    </Box>
  </>
);

export default AssessmentsComplete;
