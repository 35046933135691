import { applyMiddleware, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import reducer from "./reducer";
import { Action, State } from "./types";

const getStore = (): Store<State, Action> =>
  createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export * from "./actions";
export * from "./selectors";
export * from "./thunks";
export * from "./types";

export default getStore;
