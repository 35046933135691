import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import { Button, StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";

interface ShuffleHabitConfirmationProps {
  setShowShuffleConfirmation(value: boolean): void;
  onAccept(): Promise<void>;
  streakCount: number;
}

const ShuffleHabitConfirmation: React.FC<ShuffleHabitConfirmationProps> = ({
  setShowShuffleConfirmation,
  onAccept,
  streakCount
}: ShuffleHabitConfirmationProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      backgroundColor={color.ICON}
      borderRadius="8px"
      container
      justifyContent="center"
      padding={spacingDefaults.normal}
      width={mobile ? "95%" : "400px"}
    >
      <Box
        fontSize="14px"
        fontWeight="600"
        marginBottom={spacingDefaults.normal}
        textAlign="center"
      >
        Are you sure you want a different habit?
      </Box>

      <Box
        fontSize="13px"
        fontWeight="400"
        marginBottom={spacingDefaults.small}
        textAlign="center"
      >
        You will lose your {streakCount} day streak if you switch habits. Do you
        want to start a different habit?
      </Box>

      <StyledGrid
        container
        item
        itemMargin={spacingDefaults.small}
        justifyContent="center"
      >
        <StyledGrid item>
          <Button
            background={color.WHITE}
            textColor={color.BLACK}
            onClick={() => {
              onAccept();
              setShowShuffleConfirmation(false);
            }}
            squared
            shadow
          >
            Yes
          </Button>
        </StyledGrid>

        <StyledGrid item>
          <Button
            background={color.WHITE}
            textColor={color.BLACK}
            onClick={() => setShowShuffleConfirmation(false)}
            squared
            shadow
          >
            No
          </Button>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ShuffleHabitConfirmation;
