import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { showErrorNotification } from "state";
import { useDispatch } from "react-redux";
import { Box, IconButton } from "@material-ui/core";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import CloseIcon from "@material-ui/icons/Close";

import BlueHeaderCurve from "assets/blue-header-curve.svg";
import { ReactComponent as Warning } from "assets/warning-red.svg";
import {
  Button,
  ConfirmationModal,
  Loading,
  StyledGrid
} from "components/simple";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";
import {
  awardCoinType,
  ContentfulContent,
  ContentfulCourse,
  ContentfulTask,
  QuizContent,
  SurveyAnswers,
  SurveyContent,
  TaskType
} from "graphql/types/Contentful";
import {
  GetLessonContent,
  GetTeaserLessonContent,
  GetQuizContent,
  GetTeaserQuizContent,
  GetSurveyContent,
  GetTeaserSurveyContent,
  GetUnitProgress,
  MarkAsCompleted,
  MarkAsStarted,
  MarkTeaserAsCompleted,
  MarkTeaserAsStarted,
  SaveSurveyAnswers,
  GetTeaserContentfulUnits
} from "graphql/training/training.gql";
import Lesson from "./Lesson";
import Quiz from "./Quiz";
import Survey from "./Survey";
import ThankYouPage from "./ThankYouPage";
import { toLowerDashCase } from "../utils";
import CoinModal from "components/simple/CoinModal";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { GetSubHeaderData } from "graphql/user/user.gql";
import { detectMob } from "../../../../App";

interface StateType {
  allTasks: ContentfulTask[];
  pageNumber: number;
  percentageCompletion: number;
  isUnitCompleted?: boolean;
}
interface LessonContentResponse {
  getLessonContent: ContentfulContent;
}

interface TeaserLessonContentResponse {
  getTeaserLessonContent: ContentfulContent;
}

interface QuizContentResponse {
  getQuizContent: QuizContent;
}

interface TeaserQuizContentResponse {
  getTeaserQuizContent: QuizContent;
}
interface SurveyContentResponse {
  getSurveyContent: SurveyContent;
}

interface TeaserSurveyContentResponse {
  getTeaserSurveyContent: SurveyContent;
}

interface Props {
  percentageComplete: number;
}

const UnitProgressBar = ({ percentageComplete }: Props) => {
  return (
    <StyledGrid item width="100%" marginRight="5%">
      <StyledGrid backgroundColor={color.GRAY_PROGESS_BAR}>
        <StyledGrid
          backgroundColor={color.BLUE}
          height={spacingDefaults.xsmall}
          width={`${percentageComplete || 0}%`}
        ></StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

const Unit: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { unitTitle } = useParams<{
    unitTitle: string;
  }>();
  const { state, search } = useLocation<StateType>();
  const params = new URLSearchParams(search);
  const unitUrl = match.url.split("/");
  unitUrl.pop();
  const currentPage = state?.pageNumber;
  const currentTaskId = params.get("task-id");
  const currentTeaserTaskId = history.location.pathname.includes(
    "training-jumpstart"
  )
    ? currentTaskId
    : "";
  const currentTaskType = state?.allTasks?.find(
    (task: ContentfulTask) => task.id === currentTaskId
  )?.taskType;

  const nextTask =
    state?.allTasks[
      state?.allTasks?.findIndex(
        (task: ContentfulTask) => task.id === currentTaskId
      ) + 1
    ];

  const [showLeavingModal, setShowLeavingModal] = useState<boolean>(false);
  const [showExitTrainingModal, setShowExitTrainingModal] = useState(false);
  const [hasVideoEnded, setHasVideoEnded] = useState<boolean>(false);
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const [isLastSlideInLesson, setIsLastSlideInLesson] = useState<boolean>(
    false
  );
  const [historyObj, setHistoryObj] = useState<{
    pathname: string;
    search: string | undefined;
    state: StateType;
  }>();

  const [unitProgress, setUnitProgress] = useState<number>();
  const [lessonContent, setLessonContent] = useState<ContentfulContent>();
  const [teaserLessonContent, setTeaserLessonContent] = useState<
    ContentfulContent
  >();
  const [quizContent, setQuizContent] = useState<QuizContent>();
  const [teaserQuizContent, setTeaserQuizContent] = useState<QuizContent>();
  const [surveyContent, setSurveyContent] = useState<SurveyContent>();
  const [teaserSurveyContent, setTeaserSurveyContent] = useState<
    SurveyContent
  >();

  const [passedQuiz, setPassedQuiz] = useState<boolean>(false);
  const [hasSurveyBeenAnswered, setHasSurveyBeenAnswered] = useState<boolean>(
    false
  );
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswers[]>([]);
  const [isLastPageOfTask, setIsLastPageOfTask] = useState<boolean>(false);
  const [hasCompletedSurveyOrQuiz, setHasCompletedSurveyOrQuiz] = useState<
    boolean
  >(false);

  const [isRefactorEnabled, setIsRefactorEnabled] = useState<boolean>(false);
  const [isOpen, setModal] = useState<boolean>(false);
  const [coinAward, setcoinAward] = useState<awardCoinType[]>([]);
  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Refactor" } },
    onCompleted: data => setIsRefactorEnabled(data.isFeatureEnabled.enabled)
  });

  const [
    UnitProgressQuery,
    { data, loading: unitProgressLoading }
  ] = useLazyQuery(GetUnitProgress);

  useEffect(() => {
    UnitProgressQuery();
  }, []);

  const links = document.querySelectorAll("a");
  const isMobile = detectMob();
  const [clickedLink, setClickedLink] = useState("");

  // Attach onclick event to each <a> tag
  if (isMobile) {
    links.forEach((link: HTMLAnchorElement) => {
      link.addEventListener("click", (event: Event) => {
        event.preventDefault();
        const actualLink = link.getAttribute("href");
        setClickedLink(actualLink || "");
        setShowLeavingModal(true);
      });
    });
  }

  const { loading: lessonContentLoading } = useQuery<LessonContentResponse>(
    GetLessonContent,
    {
      variables: { input: { taskId: currentTaskId } },
      onCompleted: data => setLessonContent(data?.getLessonContent),
      skip: currentTeaserTaskId != "" || currentTaskType !== TaskType.lesson,
      onError: () => {
        history.push({
          pathname: `${unitUrl.join("/")}`
        });
        dispatch(showErrorNotification("Error", currentTeaserTaskId));
      }
    }
  );

  const { loading: teaserLessonContentLoading } = useQuery<
    TeaserLessonContentResponse
  >(GetTeaserLessonContent, {
    variables: { input: { taskId: currentTaskId } },
    onCompleted: data => setTeaserLessonContent(data?.getTeaserLessonContent),
    skip: currentTeaserTaskId == "" || currentTaskType !== TaskType.lesson,
    onError: () => {
      history.push({
        pathname: `${unitUrl.join("/")}`
      });
      dispatch(showErrorNotification("Error", "Failed to start unit"));
    }
  });

  const { loading: quizContentLoading } = useQuery<QuizContentResponse>(
    GetQuizContent,
    {
      variables: { input: { taskId: currentTaskId } },
      onCompleted: data => setQuizContent(data?.getQuizContent),
      skip: currentTeaserTaskId != "" || currentTaskType !== TaskType.quiz,
      onError: () => {
        history.push({
          pathname: `${unitUrl.join("/")}`
        });
        dispatch(showErrorNotification("Error", "Failed to start unit"));
      }
    }
  );

  const { loading: teaserQuizContentLoading } = useQuery<
    TeaserQuizContentResponse
  >(GetTeaserQuizContent, {
    variables: { input: { taskId: currentTaskId } },
    onCompleted: data => setTeaserQuizContent(data?.getTeaserQuizContent),
    skip: currentTeaserTaskId == "" || currentTaskType !== TaskType.quiz,
    onError: () => {
      history.push({
        pathname: `${unitUrl.join("/")}`
      });
      dispatch(showErrorNotification("Error", "Failed to start unit"));
    }
  });

  const { loading: surveyContentLoading } = useQuery<SurveyContentResponse>(
    GetSurveyContent,
    {
      variables: { input: { taskId: currentTaskId } },
      onCompleted: data => setSurveyContent(data?.getSurveyContent),
      skip: currentTeaserTaskId != "" || currentTaskType !== TaskType.survey,
      onError: () => {
        history.push({
          pathname: `${unitUrl.join("/")}`
        });
        dispatch(showErrorNotification("Error", "Failed to start unit"));
      }
    }
  );

  const { loading: teaserSurveyContentLoading } = useQuery<
    TeaserSurveyContentResponse
  >(GetTeaserSurveyContent, {
    variables: { input: { taskId: currentTaskId } },
    onCompleted: data => setTeaserSurveyContent(data?.getTeaserSurveyContent),
    skip: currentTeaserTaskId == "" || currentTaskType !== TaskType.survey,
    onError: () => {
      history.push({
        pathname: `${unitUrl.join("/")}`
      });
      dispatch(showErrorNotification("Error", "Failed to start unit"));
    }
  });

  const [markAsStarted] = useMutation(MarkAsStarted);

  const [markAsCompleted, { loading: mutationLoading }] = useMutation(
    MarkAsCompleted,
    {
      onCompleted: data => {
        if (historyObj) history.push(historyObj);
        setcoinAward(data?.markAsCompleted?.coinsAwarded);
        if (data?.markAsCompleted?.coinsAwarded.length > 0) setModal(true);
      },
      refetchQueries: [{ query: GetUnitProgress }, { query: GetSubHeaderData }]
    }
  );
  const [saveSurveyAnswers] = useMutation(SaveSurveyAnswers, {
    onCompleted: () => {
      setSurveyAnswers([]);
    }
  });
  const [markTeaserAsStarted] = useMutation(MarkTeaserAsStarted);
  const [
    markTeaserAsCompleted,
    { loading: mutationTeaserLoading }
  ] = useMutation(MarkTeaserAsCompleted, {
    onCompleted: data => {
      if (historyObj) history.push(historyObj);
      //setcoinAward(data?.markTeaserAsCompleted?.coinsAwarded);
      // if (data?.markTeaserAsCompleted?.coinsAwarded.length > 0) setModal(true);
    },
    refetchQueries: [{ query: GetUnitProgress }, { query: GetSubHeaderData }]
  });
  const [saveTeaserSurveyAnswers] = useMutation(SaveSurveyAnswers, {
    onCompleted: () => {
      setSurveyAnswers([]);
    }
  });

  useEffect(() => {
    if (currentTaskType === TaskType.lesson)
      setIsLastPageOfTask(
        teaserLessonContent
          ? currentPage === teaserLessonContent?.contentModule?.length
          : currentPage === lessonContent?.contentModule?.length
      );
    else if (currentTaskType === TaskType.quiz)
      setIsLastPageOfTask(
        teaserQuizContent
          ? currentPage === teaserQuizContent?.questions?.length
          : currentPage === quizContent?.questions?.length
      );
    else if (currentTaskType === TaskType.survey)
      setIsLastPageOfTask(
        teaserSurveyContent
          ? currentPage === teaserSurveyContent?.questions?.length
          : currentPage === surveyContent?.questions?.length
      );
    else setIsLastPageOfTask(true);
  }, [params]);

  useEffect(() => {
    setSurveyAnswers([]);
    if (!state?.isUnitCompleted) {
      try {
        teaserLessonContent ||
        teaserQuizContent ||
        teaserSurveyContent ||
        currentTeaserTaskId != ""
          ? markTeaserAsStarted({
              variables: { input: { taskId: currentTaskId } }
            })
          : markAsStarted({
              variables: { input: { taskId: currentTaskId } }
            });
      } catch (e) {
        dispatch(
          showErrorNotification("Error", "Failed to mark unit as started")
        );
      }
    }
  }, [currentTaskId, data]);

  useEffect(() => {
    if (!state?.isUnitCompleted) {
      if (
        // TODO: waiting for backend query
        toLowerDashCase(
          data?.trainingProgress?.currentCourse?.currentSection?.currentUnit
            ?.title
        ) !== unitTitle
      ) {
        setUnitProgress(100);
      } else {
        setUnitProgress(
          data?.trainingProgress?.currentCourse?.currentSection?.currentUnit
            .percentageCompletion
        );
      }
    }
  }, [data]);

  const resetState = () => {
    setHasVideoEnded(false);
    setHasVideo(false);
    setPassedQuiz(false);
  };

  const markTaskAsCompleted = async () => {
    if (!state?.isUnitCompleted) {
      try {
        currentTeaserTaskId != ""
          ? await markTeaserAsCompleted({
              variables: { input: { taskId: currentTaskId } }
            })
          : await markAsCompleted({
              variables: { input: { taskId: currentTaskId } }
            });
      } catch (e) {
        dispatch(
          showErrorNotification("Error", "Failed to mark unit as completed")
        );
      }
    } else {
      setHasCompletedSurveyOrQuiz(false);
      history.push({
        pathname: nextTask ? `${match.url}` : `${unitUrl.join("/")}`,
        search: nextTask ? `?task-id=${nextTask?.id}` : undefined,
        state: {
          allTasks: state?.allTasks,
          pageNumber: 1,
          percentageCompletion: state?.percentageCompletion,
          isUnitCompleted: state?.isUnitCompleted
        }
      });
    }
  };

  const continueTask = () => {
    resetState();
    setSurveyAnswers(surveyAnswers);
    history.push({
      pathname: `${match.url}`,
      search: `?task-id=${currentTaskId}`,
      state: {
        allTasks: state?.allTasks,
        pageNumber: currentPage + 1,
        percentageCompletion: state?.percentageCompletion,
        isUnitCompleted: state?.isUnitCompleted
      }
    });
  };

  // move to preve task
  const previousTask = () => {
    resetState();
    history.push({
      pathname: `${match.url}`,
      search: `?task-id=${currentTaskId}`,
      state: {
        allTasks: state?.allTasks,
        pageNumber: currentPage - 1,
        percentageCompletion: state?.percentageCompletion,
        isUnitCompleted: state?.isUnitCompleted
      }
    });
  };

  const onComplete = (taskType?: TaskType) => {
    switch (taskType) {
      case TaskType.lesson:
        return completeLesson;
      case TaskType.quiz:
        return completeQuiz;
      case TaskType.survey:
        return completeSurvey;
      default:
        return undefined;
    }
  };

  const completeLesson = () => {
    resetState();
    markTaskAsCompleted();
    setHistoryObj({
      pathname: nextTask ? `${match.url}` : `${unitUrl.join("/")}`,
      search: nextTask ? `?task-id=${nextTask?.id}` : undefined,
      state: {
        allTasks: state?.allTasks,
        pageNumber: 1,
        percentageCompletion: state?.percentageCompletion,
        isUnitCompleted: state?.isUnitCompleted
      }
    });
  };

  const completeQuiz = () => {
    resetState();
    setHasCompletedSurveyOrQuiz(true);
    setHistoryObj({
      pathname: `${match.url}`,
      search: `?task-id=${currentTaskId}`,
      state: {
        allTasks: state?.allTasks,
        pageNumber: 0,
        percentageCompletion: state?.percentageCompletion,
        isUnitCompleted: state?.isUnitCompleted
      }
    });
    markTaskAsCompleted();
  };

  const completeSurvey = async () => {
    resetState();
    setHasCompletedSurveyOrQuiz(true);
    // save survey answers
    try {
      currentTeaserTaskId != ""
        ? await saveTeaserSurveyAnswers({
            variables: { input: { taskId: currentTaskId, surveyAnswers } }
          })
        : await saveSurveyAnswers({
            variables: { input: { taskId: currentTaskId, surveyAnswers } }
          });
    } catch (e) {
      dispatch(showErrorNotification("Error", "Failed to save survey answers"));
    }
    markTaskAsCompleted();
    setHistoryObj({
      pathname: `${match.url}`,
      search: `?task-id=${currentTaskId}`,
      state: {
        allTasks: state?.allTasks,
        pageNumber: 0,
        percentageCompletion: state?.percentageCompletion,
        isUnitCompleted: state?.isUnitCompleted
      }
    });
  };

  const afterThankyou = () => {
    setHasCompletedSurveyOrQuiz(false);
    history.push({
      pathname: nextTask ? `${match.url}` : `${unitUrl.join("/")}`,
      search: nextTask ? `?task-id=${nextTask?.id}` : undefined,
      state: {
        allTasks: state?.allTasks,
        pageNumber: 1,
        percentageCompletion: state?.percentageCompletion,
        isUnitCompleted: state?.isUnitCompleted
      }
    });
  };

  const getContentTitle = (taskType?: TaskType) => {
    switch (taskType) {
      case TaskType.lesson:
        return teaserLessonContent
          ? teaserLessonContent?.title
          : lessonContent?.title;
      case TaskType.quiz:
        return teaserQuizContent
          ? teaserQuizContent?.title
          : quizContent?.title;

      case TaskType.survey:
        return teaserSurveyContent
          ? teaserSurveyContent?.title
          : surveyContent?.title;

      default:
        return undefined;
    }
  };

  const getDisabledLogic = (taskType?: TaskType) => {
    switch (taskType) {
      case TaskType.lesson:
        if (
          teaserLessonContent
            ? teaserLessonContent?.title === "Slides"
            : lessonContent?.title === "Slides"
        )
          return !isLastSlideInLesson
            ? true
            : hasVideo
            ? !hasVideoEnded
            : false;
        return hasVideo ? !hasVideo : false;
      case TaskType.quiz:
        return false;
      case TaskType.survey:
        return !hasSurveyBeenAnswered;
      default:
        return false;
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  if (
    lessonContentLoading ||
    teaserLessonContentLoading ||
    quizContentLoading ||
    teaserQuizContentLoading ||
    surveyContentLoading ||
    teaserSurveyContentLoading ||
    unitProgressLoading
  )
    return <Loading />;

  return (
    <>
      <StyledGrid
        container
        position="fixed"
        alignItems="center"
        id="progress-with-close"
        height="35px"
        zIndex="1"
        backgroundColor={color.BLUEGRAY}
      >
        <StyledGrid
          container
          item
          xs={2}
          lg={1}
          centerContent
          alignItems="center"
          id="close-button-wrapper"
          width="35px"
          height="35px"
        >
          <StyledGrid item>
            <IconButton
              size="medium"
              onClick={() => setShowExitTrainingModal(true)}
            >
              <CloseIcon />
            </IconButton>
          </StyledGrid>
        </StyledGrid>
        <StyledGrid
          container
          item
          xs={10}
          lg={11}
          id="progress"
          alignItems="center"
          height="35px"
          backgroundColor={color.BLUEGRAY}
        >
          <UnitProgressBar
            percentageComplete={
              state?.isUnitCompleted ? 100 : unitProgress || 0
            }
          />
        </StyledGrid>
      </StyledGrid>
      {hasCompletedSurveyOrQuiz && state.percentageCompletion !== 100 ? (
        <ThankYouPage
          taskType={currentTaskType}
          taskTitle={getContentTitle(currentTaskType)}
        />
      ) : (
        <>
          {/* Header */}
          <StyledGrid
            container
            centerContent
            alignItems="center"
            minHeight="250px"
            id="blue-header-curve"
            backgroundImage={BlueHeaderCurve}
            backgroundPosition="bottom"
          >
            <StyledGrid item padding={`0 ${spacingDefaults.normal}`}>
              <Box fontSize={fontSize.normal} fontFamily={fontFamily.secondary}>
                {unitTitle?.replaceAll("-", " ").toUpperCase()}
              </Box>
              <Box fontSize={fontSize.large}>
                {getContentTitle(currentTaskType)}
              </Box>
            </StyledGrid>
          </StyledGrid>
          {/* Content */}
          <StyledGrid container centerContent marginTop={spacingDefaults.large}>
            {teaserLessonContent
              ? currentTaskType === TaskType.lesson &&
                teaserLessonContent && (
                  <Lesson
                    content={teaserLessonContent.contentModule[currentPage - 1]}
                    setHasVideoEnded={setHasVideoEnded}
                    setHasVideo={setHasVideo}
                    setIsLastSlideInLesson={setIsLastSlideInLesson}
                  />
                )
              : currentTaskType === TaskType.lesson &&
                lessonContent && (
                  <Lesson
                    content={lessonContent.contentModule[currentPage - 1]}
                    setHasVideoEnded={setHasVideoEnded}
                    setHasVideo={setHasVideo}
                    setIsLastSlideInLesson={setIsLastSlideInLesson}
                  />
                )}
          </StyledGrid>
          {teaserQuizContent
            ? currentTaskType === TaskType.quiz &&
              teaserQuizContent && (
                <Quiz
                  content={teaserQuizContent.questions[currentPage - 1]}
                  setPassedQuiz={setPassedQuiz}
                  passedQuiz={passedQuiz}
                  setHasVideoEnded={setHasVideoEnded}
                  setHasVideo={setHasVideo}
                  handleContinue={continueTask}
                  handlePrev={previousTask}
                  totalQuestions={teaserQuizContent.questions.length}
                  hasCompletedTraining={state.percentageCompletion === 100}
                />
              )
            : currentTaskType === TaskType.quiz &&
              quizContent && (
                <Quiz
                  content={quizContent.questions[currentPage - 1]}
                  setPassedQuiz={setPassedQuiz}
                  passedQuiz={passedQuiz}
                  setHasVideoEnded={setHasVideoEnded}
                  setHasVideo={setHasVideo}
                  handleContinue={continueTask}
                  handlePrev={previousTask}
                  totalQuestions={quizContent.questions.length}
                  hasCompletedTraining={state.percentageCompletion === 100}
                />
              )}
          {teaserSurveyContent
            ? currentTaskType === TaskType.survey &&
              teaserSurveyContent && (
                <Survey
                  surveyQuestion={
                    teaserSurveyContent.questions[currentPage - 1]
                  }
                  surveyInstructions={teaserSurveyContent?.instructions}
                  setHasVideoEnded={setHasVideoEnded}
                  setHasVideo={setHasVideo}
                  setHasSurveyBeenAnswered={setHasSurveyBeenAnswered}
                  surveyAnswers={surveyAnswers}
                  setSurveyAnswers={setSurveyAnswers}
                />
              )
            : currentTaskType === TaskType.survey &&
              surveyContent && (
                <Survey
                  surveyQuestion={surveyContent.questions[currentPage - 1]}
                  surveyInstructions={surveyContent?.instructions}
                  setHasVideoEnded={setHasVideoEnded}
                  setHasVideo={setHasVideo}
                  setHasSurveyBeenAnswered={setHasSurveyBeenAnswered}
                  surveyAnswers={surveyAnswers}
                  setSurveyAnswers={setSurveyAnswers}
                />
              )}
        </>
      )}
      {(currentTaskType !== TaskType.quiz ||
        passedQuiz ||
        hasCompletedSurveyOrQuiz ||
        state.percentageCompletion === 100) && (
        <StyledGrid centerContent marginTop="auto">
          {isLastPageOfTask && (
            <Button
              onClick={onComplete(currentTaskType)}
              disabled={
                getDisabledLogic(currentTaskType) ||
                mutationTeaserLoading ||
                mutationLoading
              }
            >
              Complete
            </Button>
          )}
          {!isLastPageOfTask && (
            <Button
              onClick={hasCompletedSurveyOrQuiz ? afterThankyou : continueTask}
              disabled={getDisabledLogic(currentTaskType)}
            >
              Continue
            </Button>
          )}
        </StyledGrid>
      )}
      {showExitTrainingModal && (
        <ConfirmationModal
          icon={<Warning width={"150px"} height={"150px"} />}
          title={"Are you sure you want to exit this training?"}
          content={`You will lose all of your progress in ${
            getContentTitle(currentTaskType) || "this task"
          } if you exit now.`}
          confirmText={"Yes, exit training"}
          onConfirm={() => {
            history.push(unitUrl.join("/"));
          }}
          cancelText={"Oh, wait. Never mind"}
          onCancel={() => setShowExitTrainingModal(false)}
        />
      )}

      {showLeavingModal && isMobile && (
        <ConfirmationModal
          icon={<Warning width={"150px"} height={"150px"} />}
          title={"You are about to leave our platform via an external link"}
          content={`In order to return swipe back on the page`}
          confirmText={"Go to link"}
          onConfirm={() => {
            window.location.href = clickedLink.toString();
          }}
          onCancel={() => setShowLeavingModal(false)}
        />
      )}

      {isRefactorEnabled && (
        <CoinModal
          onClose={handleClose}
          openModal={isOpen}
          coinsAwarded={coinAward}
        />
      )}
    </>
  );
};

export default Unit;
