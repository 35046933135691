import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { kebabCase } from "lodash";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import qs from "qs";

import { useMediaQuery } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

import {
  GetConsentForm,
  GetUserInExpiredOrgStatus,
  GetOrganizationConsentFormInfo
} from "graphql/user/user.gql";
import { FormProps } from "../index";
import { breakpoints, color, spacingDefaults } from "style/constants";
import { BackButton, Button, ProgressBar, StyledGrid } from "components/simple";
import {
  Break,
  Ruler,
  SubsectionTitle,
  SectionSubtitle,
  SectionBody,
  SectionList
} from "../styled";

import SideNav from "./SideNav";
import Questions from "../Questions";
import routesConfig from "utils/routesConfig";

const options = {
  renderNode: {
    // eslint-disable-next-line
    [BLOCKS.HEADING_2]: (node: any, children: any) => {
      return (
        <SectionSubtitle id={kebabCase(children[0])}>
          {children[0]}
        </SectionSubtitle>
      );
    },
    // eslint-disable-next-line
    [BLOCKS.HEADING_3]: (node: any, children: any) => {
      return <SubsectionTitle>{children[0]}</SubsectionTitle>;
    },
    // eslint-disable-next-line
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return <SectionBody>{children[0]}</SectionBody>;
    },
    // eslint-disable-next-line
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return (
        <SectionList>
          {children.map((item: any, i: number) => (
            <li key={i}>{item}</li>
          ))}
        </SectionList>
      );
    }
  }
};

const Form: React.FC<FormProps> = ({ enrollmentConsent }: FormProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [showQuestions, setShowQuestions] = useState(false);
  const [allowTransfertoBHP, setAllowTransfertoBHP] = useState(false);
  const [isUserInExpiredOrg, setIsUserInExpiredOrg] = useState(false);
  useQuery(GetUserInExpiredOrgStatus, {
    onCompleted: data => {
      setIsUserInExpiredOrg(data?.me?.isInExpiredOrg);
    }
  });

  const { data, loading } = useQuery(GetConsentForm, {
    fetchPolicy: "no-cache"
  });

  const { data: consentFormInfo } = useQuery(GetOrganizationConsentFormInfo, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if (queryParams.page) {
      setCurrentPage(parseInt(queryParams.page, 10));
    }

    if (queryParams?.allowTransfer === "True") {
      setAllowTransfertoBHP(true);
    }

    if (history.location.hash) {
      document.querySelector(history.location.hash)?.scrollIntoView();
    }

    if (history.location.pathname === "/consent/questions") {
      setShowQuestions(true);
    } else {
      setShowQuestions(false);
    }
  }, [history.location]); // eslint-disable-line

  if (loading) return null;

  const form = data?.me?.organization?.consentForm;
  const formId = data?.me?.organization?.consentFormId;

  const questionsPageNumber = 1;
  const pages = form.fields.consentPage;
  const heading = form.fields.heading;
  const pagesCount: number = pages.length;
  const steps =
    enrollmentConsent || (isUserInExpiredOrg && !allowTransfertoBHP)
      ? pagesCount
      : pagesCount + questionsPageNumber;
  const isLastPage = currentPage === pagesCount;

  const shouldShowLink = () => {
    if (isUserInExpiredOrg) {
      if (allowTransfertoBHP) return true;
      return !isLastPage;
    } else {
      return !enrollmentConsent || (enrollmentConsent && !isLastPage);
    }
  };

  const shouldShowQuestionsInSide = () => {
    if (isUserInExpiredOrg) {
      return allowTransfertoBHP;
    } else {
      return !enrollmentConsent;
    }
  };

  const showLink = shouldShowLink();
  const showQuestionInSideNav = shouldShowQuestionsInSide() || showQuestions;
  const shouldHidePostConsentQuestions =
    consentFormInfo?.me?.organization?.hidePostConsentQuestions;
  const handleNextClick = (): void => {
    if (isLastPage && !shouldHidePostConsentQuestions) {
      history.push("/consent/questions");
    } else if (isLastPage && shouldHidePostConsentQuestions) {
      history.push("/consent/join");
    } else {
      history.push(`/consent/form?page=${currentPage + 1}`);
      window.scrollTo(0, 0);
    }
  };

  if (showQuestions && currentPage !== steps) {
    setCurrentPage(steps);
  }

  return (
    <>
      <Hidden lgUp>
        <ProgressBar
          variant="steps"
          steps={steps}
          activeStep={
            showQuestions ? currentPage + questionsPageNumber : currentPage
          }
        />
        <BackButton />
      </Hidden>
      <StyledGrid
        container
        direction={mobile ? "column" : "row"}
        justifyContent="flex-start"
        mobilePadding="0"
        padding="55px"
        minHeight="100vh"
        backgroundColor={mobile ? color.WHITE : undefined}
      >
        <Hidden mdDown>
          <StyledGrid item>
            <SideNav
              pages={pages}
              currentPage={currentPage}
              showQuestions={showQuestionInSideNav}
            >
              {showLink ? (
                <Button stickyFooter={mobile} onClick={handleNextClick}>
                  Next Section
                </Button>
              ) : (
                <></>
              )}
            </SideNav>
          </StyledGrid>
        </Hidden>

        <StyledGrid item container height="100%" justifyContent="center" xs>
          <StyledGrid
            backgroundColor={color.WHITE}
            border={mobile ? "0" : `1px solid ${color.CARDGRAY}`}
            borderRadius="8px"
            container
            item
            maxWidth="955px"
            mobilePadding={`${spacingDefaults.xxlarge} ${spacingDefaults.normal}`}
            padding="90px 125px"
          >
            <article>
              <StyledGrid>
                {currentPage === 1 && documentToReactComponents(heading)}
              </StyledGrid>

              {!mobile && (
                <StyledGrid
                  item
                  margin={showQuestions ? "0px" : "50px 0"}
                  mobileMarginTop="25px"
                  mobileMarginBottom="25px"
                >
                  <Break>
                    <span>
                      {currentPage.toString().length === 1 ? "0" : ""}
                      {currentPage}
                    </span>
                    <Ruler />
                  </Break>
                </StyledGrid>
              )}
              {showQuestions ? (
                <Questions />
              ) : (
                <>
                  {documentToReactComponents(
                    pages[currentPage - 1].fields.content,
                    options
                  )}
                  <StyledGrid
                    container
                    direction="column"
                    alignItems="center"
                    marginTop="2rem"
                  >
                    {showLink && (
                      <Button
                        data-testid="next-section-btn"
                        stickyFooter={mobile}
                        onClick={handleNextClick}
                        width={mobile ? undefined : "300px"}
                        zIndex={1000}
                      >
                        Next Section
                      </Button>
                    )}
                    {((enrollmentConsent && isLastPage) ||
                      (!enrollmentConsent &&
                        isUserInExpiredOrg &&
                        !allowTransfertoBHP &&
                        isLastPage)) && (
                      <Link to={routesConfig.profile.path}>
                        <Button width="300px">Return to Profile</Button>
                      </Link>
                    )}
                  </StyledGrid>
                </>
              )}
            </article>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export default Form;
