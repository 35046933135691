import { useMediaQuery } from "@material-ui/core";
import React from "react";

import { ReactComponent as WinterBreakImage } from "assets/winter-break.svg";
import { StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const RetakeAssessmentsBanner: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  return (
    <StyledGrid
      container
      backgroundColor={color.WHITE}
      borderRadius="8px"
      alignContent="center"
      alignItems="center"
      itemMargin={spacingDefaults.xsmall}
      padding={spacingDefaults.xsmall}
      maxHeight={mobile ? "none" : "96px"}
      justifyContent={mobile ? "center" : "flex-start"}
      textAlign={mobile ? "center" : "left"}
      marginBottom="2rem"
      mobileMarginBottom="0"
    >
      <StyledGrid item>
        <WinterBreakImage />
      </StyledGrid>
      <StyledGrid item lg>
        <StyledP
          color={color.BLACK}
          fontFamily={fontFamily.secondary}
          fontSize={fontSize.large}
          letterSpacing="-0.5px"
          margin="0.5rem 0"
        >
          It’s Winter (Brain) Break!
        </StyledP>
        <StyledP color={color.DARKERGRAY} margin={"0.5rem 0"}>
          Our team is taking a brain healthy break and we encourage you to do
          the same. See you in 2024!
        </StyledP>
      </StyledGrid>
    </StyledGrid>
  );
};

export default RetakeAssessmentsBanner;
