import React from "react";
import { useMediaQuery } from "@material-ui/core";

import { StyledGrid } from "components/simple";
import { breakpoints, spacingDefaults } from "style/constants";
import Badges from "./Badges";
import Rank from "./Rank";
import Stats from "./Stats";
import { UserBadge } from "models/badge";

interface ProfileTilesProps {
  currentUserBadge?: UserBadge;
  isRanksEnabled: boolean;
  isRefactorEnabled: boolean;
}
const ProfileTiles: React.FC<ProfileTilesProps> = ({
  currentUserBadge,
  isRanksEnabled,
  isRefactorEnabled
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      container
      style={{ gap: spacingDefaults.normal }}
      wrap={mobile ? "wrap" : "nowrap"}
    >
      {isRefactorEnabled && <Stats />}
      {currentUserBadge && <Badges currentUserBadge={currentUserBadge} />}
      {isRanksEnabled && <Rank />}
    </StyledGrid>
  );
};

export default ProfileTiles;
