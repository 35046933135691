import React, { useEffect, useState } from "react";
import Joyride, { CallBackProps, EVENTS } from "react-joyride";

import useCoachMarks from "hooks/useCoachMarks";
import { StyledLightbulb } from "components/simple/CoachMarks";
import { breakpoints, color, fontFamily } from "style/constants";
import "../ProductTour/style.css";
import "./style.css";
import { Step, writeStepProgressText } from "components/simple/ProductTour";
import { useMediaQuery } from "@material-ui/core";
import { PillarName } from "components/pages/Index/CycleSummary/types";
import { BHIChartType } from "../BHIChart";
import { merge } from "lodash";

interface IndexCoachMarkProps {
  stepIndex: number;
  resetSelectedFactors: () => void;
  toggleSelectedFactor: (pillarName: PillarName) => void;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setBhiChartType: React.Dispatch<React.SetStateAction<BHIChartType>>;
}

const IndexCoachMark: React.FC<IndexCoachMarkProps> = ({
  stepIndex,
  resetSelectedFactors,
  toggleSelectedFactor,
  setStepIndex,
  setBhiChartType
}) => {
  const styles = {
    options: {
      backgroundColor: color.WHITE,
      fontFamily: fontFamily.primary,
      primaryColor: color.DARKBLUE,
      textColor: color.LIGHTGRAY,
      zIndex: 1
    }
  };

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const { productTour: coachMarks } = useCoachMarks(
    mobile
      ? "BrainHealth Index Existing User Mobile"
      : "BrainHealth Index Existing User"
  );

  const [steps, setSteps] = useState<Step[]>([]);
  const [run, setRun] = useState(false);

  const stepsMetadata: Partial<Step>[] = [
    {
      // existing user intro BHI 2.0
      placement: "center",
      target: "body"
    },
    {
      // factors
      callback: () => {
        if (mobile) {
          toggleSelectedFactor(PillarName.CLARITY);
          setStepIndex(2);
        }
      },
      placement: "bottom",
      target: mobile ? ".bhi-factor-dropdown" : ".bhi-factors-banner"
    },
    {
      // toggle
      event: "index-toggle-factor-select",
      placement: "bottom",
      hideFooter: true,
      target: ".clarity-factor",
      spotlightClicks: true
    },
    {
      // measures
      callback: () => {
        resetSelectedFactors();
        setStepIndex(4);
      },
      placement: "top",
      target: ".subfactor-bar-graph"
    },
    {
      // tree
      placement: "top",
      target: ".bhi-tree"
    },
    {
      // switch views
      event: "index-switch-bhi-chart",
      placement: "top",
      hideFooter: true,
      target: ".bhi-chart-switch-button",
      spotlightClicks: true
    },
    {
      // data views
      callback: () => {
        setBhiChartType(BHIChartType.Tree);
        setStepIndex(7);
      },
      placement: "top",
      target: ".bhi-radar"
    },
    {
      // rounds
      placement: "bottom",
      target: ".cycle-select-banner",
      spotlightClicks: true
    }
  ];

  const stepsMetadataMobile: Partial<Step>[] = [
    {
      // existing user intro BHI 2.0
      placement: "center",
      target: "body"
    },
    {
      // factors
      callback: () => {
        if (mobile) {
          toggleSelectedFactor(PillarName.CLARITY);
          setStepIndex(2);
        }
      },
      placement: "bottom",
      target: mobile ? ".bhi-factor-dropdown" : ".bhi-factors-banner"
    },
    {
      // measures
      callback: () => {
        resetSelectedFactors();
        setStepIndex(3);
      },
      placement: "top",
      target: ".subfactor-bar-graph"
    },
    {
      // tree
      placement: "top",
      target: ".bhi-tree"
    },
    {
      // switch views
      event: "index-switch-bhi-chart",
      placement: "top",
      hideFooter: true,
      target: ".bhi-chart-switch-button",
      spotlightClicks: true
    },
    {
      // data views
      callback: () => {
        setBhiChartType(BHIChartType.Tree);
        setStepIndex(6);
      },
      placement: "top",
      target: ".bhi-radar"
    },
    {
      // rounds
      placement: "bottom",
      target: ".cycle-select-banner",
      spotlightClicks: true
    }
  ];

  useEffect(() => {
    if (coachMarks) {
      const stepContent: Partial<Step>[] = coachMarks.steps.map(step => {
        return {
          content: step.description,
          disableBeacon: true,
          title: step.title
        };
      });

      if (mobile) {
        setSteps(merge(stepsMetadataMobile, stepContent) as Step[]);
      } else {
        setSteps(merge(stepContent, stepsMetadata) as Step[]);
      }
    }
  }, [coachMarks]);

  useEffect(() => {
    if (run) {
      return () => {
        return setRun(false);
      };
    }
  }, [run]);

  if (coachMarks) {
    const callback = (data: CallBackProps) => {
      writeStepProgressText(data.index + 1, steps.length);
      if (
        data.step.event &&
        [
          "my-journey-step-1",
          "index-toggle-factor-select",
          "index-switch-bhi-chart"
        ].includes(data.step.event)
      ) {
      } else if (data.type == EVENTS.STEP_AFTER) {
        if (setStepIndex) {
          setStepIndex(data.index + 1);
        }
        steps[data.index].callback?.();
      }
      if (
        data.type == EVENTS.TOUR_END ||
        (data.type == EVENTS.TOOLTIP &&
          (data.step as Step).endTourOnClickThrough)
      ) {
        setRun(false);
        setStepIndex(0);
      }
    };

    return (
      <>
        <StyledLightbulb
          className="lightbulb"
          onClick={() => {
            setRun(true);
            console.log("run", run);
            console.log("stepIndex", stepIndex);
          }}
        />
        <Joyride
          callback={callback}
          continuous
          disableCloseOnEsc
          disableOverlayClose
          hideBackButton
          hideCloseButton
          locale={{
            last: "Done"
          }}
          run={run}
          scrollOffset={mobile ? 400 : 100}
          showSkipButton
          stepIndex={stepIndex}
          steps={steps}
          styles={styles}
        />
      </>
    );
  }
  return <></>;
};

export default IndexCoachMark;
