import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core";
import ChatWindow from "../ChatWindow/ChatWindow";
import ParticipantList from "../ParticipantList/ParticipantList";
import MainParticipant from "../MainParticipant/MainParticipant";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import { isMobile } from "lib/twilio-video/utils";

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${90 + 8 * 2 + 2}px`;
  return {
    container: {
      position: "relative",
      height: "100%",
      display: "grid",
      gridTemplateColumns: `1fr ${300}px`,
      gridTemplateRows: "100%",

      [theme.breakpoints.down("sm")]: {
        display: "flex",
        gridTemplateColumns: undefined,
        gridTemplateRows: undefined,
        flexDirection: "column"
      }
    },
    rightDrawerOpen: {
      gridTemplateColumns: isMobile ? undefined : `1fr ${300}px ${320}px`
    }
  };
});

export default function Room() {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]: isChatWindowOpen
      })}
    >
      <MainParticipant />
      <ParticipantList />
      {!isMobile && <ChatWindow />}
    </div>
  );
}
