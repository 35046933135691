import React, { useEffect } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery as apolloQuery
} from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import track from "react-tracking";
import routesConfig from "utils/routesConfig";
import { gql } from "apollo-boost";

export const UpdateGoogleFitPreferenceMutation = gql`
  mutation saveAuthToken($input: SaveGoogleFitTokensRequest!) {
    saveGoogleFitTokens(input: $input) {
      success
    }
  }
`;

export const UpdateNotificationPreferencesMutation = gql`
  mutation UpdateNotificationPreferences(
    $input: UserNotificationPreferencesInput!
  ) {
    updateUserNotificationPreferences(input: $input) {
      success
    }
  }
`;

const GoogleFit: React.FC = () => {
  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  const scope = queryParameters.get("scope");
  const state = queryParameters.get("state");

  const [updateGoogleFitPreferenceMutation] = useMutation(
    UpdateGoogleFitPreferenceMutation
  );

  const [updateNotificationPreferencesMutation] = useMutation(
    UpdateNotificationPreferencesMutation
  );
  useEffect(() => {
    updateGoogleFitPreferences({
      code: code,
      scope: scope,
      state: state
    });
    const googleFitPreference = code && scope && state ? true : false;
    updateNotificationPreferences({
      googleFit: code && scope && state ? true : false
    });
    history.push(routesConfig.profile.path);
    history.push(routesConfig.notificationPreferences.path, {
      googleFitPreference
    });
  });

  return <>.</>;

  async function updateGoogleFitPreferences(values: any): Promise<void> {
    await updateGoogleFitPreferenceMutation({
      variables: {
        input: {
          ...values
        }
      }
    });
  }

  async function updateNotificationPreferences(values: any): Promise<void> {
    await updateNotificationPreferencesMutation({
      variables: {
        input: {
          ...values
        }
      }
    });
  }
};

export default track({
  url: "/google-fit"
})(GoogleFit);
