import { Hidden } from "@material-ui/core";
import { useNotifications } from "hooks";
import React, { useEffect, useState } from "react";
import { UserStatus } from "state";
import Header from "../Header";
import NotificationsDrawer from "../Header/NotificationsDrawer";
import { getTab } from "../Header/IconTray";
import MobileNav from "../MobileNav";
import { POLL_INTERVAL_SECONDS } from "hooks/useNotifications";
import { getNativeExperience } from "state";
import { useSelector } from "react-redux";

interface AppMenuProps {
  isRefactorEnabled: boolean;
  isResourceEnabled: boolean;
  isTrainingEnabled: boolean;
  isHeaderShown: boolean;
  showAll: boolean;
  userStatus: UserStatus;
}
const AppMenu: React.FC<AppMenuProps> = ({
  isRefactorEnabled,
  isResourceEnabled,
  isTrainingEnabled,
  isHeaderShown,
  showAll,
  userStatus
}: AppMenuProps) => {
  const [notificationsDrawerOpen, setNotificationsDrawerOpen] = useState<
    boolean
  >(false);
  const [streakDrawerOpen, setStreakDrawerOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<number | boolean>(false);
  const [mobileTab, setMobileTab] = useState<number>(0);
  const { pushNotifications, startPolling, stopPolling } = useNotifications();
  useEffect(() => {
    if (isHeaderShown) {
      startPolling(POLL_INTERVAL_SECONDS * 1000);
    } else {
      stopPolling();
    }
  }, [isHeaderShown]);

  const handleAppBarClick = () => {
    setNotificationsDrawerOpen(false);
    setStreakDrawerOpen(false);
    setTab(getTab(window.location.pathname));
  };

  const handleMobileNavChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setNotificationsDrawerOpen(false);
    setStreakDrawerOpen(false);
    setMobileTab(newValue);
  };

  function toggleNotificationsDrawer() {
    setStreakDrawerOpen(false);
    setNotificationsDrawerOpen(prevState => {
      if (prevState) {
        setTab(getTab(window.location.pathname));
      }
      return !prevState;
    });
  }

  function setNotificationsDrawer(isOpen: boolean) {
    setStreakDrawerOpen(false);
    setNotificationsDrawerOpen(isOpen);
    if (!isOpen) {
      setTab(getTab(window.location.pathname));
    }
  }

  function setStreakDrawer(isOpen: boolean) {
    setNotificationsDrawerOpen(false);
    setStreakDrawerOpen(isOpen);
  }

  const isNativeExperience = useSelector(getNativeExperience);

  if (isNativeExperience) {
    return <></>;
  }

  return (
    <>
      {isHeaderShown && (
        <Header
          showAll={showAll}
          userStatus={userStatus}
          pushNotifications={pushNotifications}
          isRefactorEnabled={isRefactorEnabled}
          handleAppBarClick={handleAppBarClick}
          tab={tab}
          streakDrawerOpen={streakDrawerOpen}
          toggleNotificationsDrawer={toggleNotificationsDrawer}
          setTab={setTab}
          setStreakDrawer={setStreakDrawer}
        />
      )}
      <Hidden lgUp>
        <MobileNav
          show={showAll}
          isRefactorEnabled={isRefactorEnabled}
          isResourceEnabled={isResourceEnabled}
          isTrainingEnabled={isTrainingEnabled}
          mobileTab={mobileTab}
          setMobileTab={setMobileTab}
          handleMobileNavChange={handleMobileNavChange}
        />
      </Hidden>
      <NotificationsDrawer
        subHeader={isRefactorEnabled}
        drawerOpen={notificationsDrawerOpen}
        pushNotifications={pushNotifications}
        setNotificationsDrawer={setNotificationsDrawer}
      />
    </>
  );
};
export default AppMenu;
