import React, { useState } from "react";

import { Button, RadioCard, StyledGrid } from "components/simple";
import { Header, Subheader } from "../styled";
import { useMediaQuery } from "@material-ui/core";
import { breakpoints } from "style/constants";

interface QuestionProps {
  setActiveQuestion: React.Dispatch<React.SetStateAction<number>>;
  content: consentQuestions;
  isLastQuestion: boolean;
}

export interface consentQuestions {
  order: number;
  title: string;
  description: string;
  answers: string[];
  answerIndex: number;
}

const ConsentQuestion: React.FC<QuestionProps> = ({
  setActiveQuestion,
  content,
  isLastQuestion
}: QuestionProps) => {
  const [disabled, setDisabled] = useState(true);
  const [incorrect, setIncorrect] = useState(false);
  const [answer, setAnswer] = useState("");
  const matches = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const correctAnswer = content.answerIndex;
  const selections = content.answers;

  return (
    <StyledGrid container justifyContent="center">
      <StyledGrid item>
        <Header>{content.title}</Header>
        <Subheader>{content.description}</Subheader>
      </StyledGrid>
      <StyledGrid item>
        <RadioCard
          onChange={(event, value) => {
            //If value is wrong trigger incorrect state
            setDisabled(false);
            setIncorrect(false);
            setAnswer(value);
          }}
          selections={selections}
          incorrect={incorrect}
          column
        />
      </StyledGrid>
      <StyledGrid item container marginTop="40px" justifyContent="center">
        <Button
          wide={!matches}
          disabled={disabled}
          stickyFooter={matches}
          fullWidth={matches}
          onClick={() => {
            if (answer !== selections[correctAnswer]) {
              setIncorrect(true);
              setDisabled(true);
            } else {
              if (!isLastQuestion) setActiveQuestion(content.order + 1);
              else {
                window.location.assign("/consent/join");
              }
            }
          }}
        >
          {isLastQuestion ? "Submit" : "Next"}
        </Button>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ConsentQuestion;
