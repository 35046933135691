import styled from "styled-components";

import Time from "assets/time.svg";
import { color, fontFamily } from "style/constants";

export const GroupTitle = styled("h1")`
  font-family: ${fontFamily.secondary};
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
`;

export const AssessmentTitle = styled("h2")`
  color: ${color.BLACK};
  font-size: 20px;
  font-weight: normal;
  line-height: 26px;
  margin: 0;
`;

export const AssessmentTime = styled("p")`
  :before {
    background-image: url("${Time}");
    background-size: cover;
    content: " ";
    display: inline-block;
    height: 10px;
    margin-right: 7px;
    width: 10px;
  }
  :after {
    content: " min";
  }
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
`;

export const CheckWrapper = styled("div")`
  svg {
    fill: ${color.GREEN};
    height: 32px;
    margin-top: 6px;
    width: 32px;
  }
`;

export const LockWrapper = styled("div")`
  background-color: ${color.MUTEDGRAY};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  svg {
    color: ${color.BLACK};
    height: 19px;
    margin: 6px 9px;
    stroke-width: 2px;
    width: auto;
  }
`;
