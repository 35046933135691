import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";

import styled from "styled-components";

export const StyledAccordion = styled(Accordion)`
  && {
    margin: 0 !important;
    position: initial;
    box-shadow: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    padding: 0;
    font-weight: bold;
    min-height: initial !important;
    .MuiAccordionSummary-content {
      margin: 0 !important;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    margin: 0 !important;
    padding: 0;
  }
`;
