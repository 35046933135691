import ApolloClient from "apollo-boost";
import { deleteToken, getToken } from "utils/auth";

import routesConfig from "utils/routesConfig";

const client = new ApolloClient({
  uri: op =>
    `${process.env.REACT_APP_GRAPHQL_URI}${
      op.variables.notifications ? "?notifications" : ""
    }`,
  request: operation => {
    const token = getToken();
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, path, extensions }) => {
        if (extensions && extensions.code === "UNAUTHENTICATED") {
          const token = getToken();
          if (token) {
            deleteToken();
            const toPath = `${window.location.pathname}${window.location.search}`;
            window.location.assign(
              routesConfig.login.path + `?expired=true&to=${toPath}`
            );
          }
        }

        console.error(`[GraphQL Error]: ${message} on operation: ${path}`);
      });
    }

    if (networkError) {
      console.error(`[Network Error]: ${networkError}`);
    }
  }
});

export default client;
