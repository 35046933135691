import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import LockIcon from "assets/lock-filled.svg";
import { color, fontSize, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";
import { StyledCard } from "components/pages/DashboardV2/styled";
import { Habit, UserHabit } from "models/userHabits";

export const CircularProgressBackground = styled(CircularProgress)`
  && {
    color: ${color.MUTEDGRAY};
  }
`;

interface HabitCardProps {
  habit: Habit;
  userHabit?: UserHabit;
  disabled?: boolean;
  onClick?: () => void;
}

const HabitCard: React.FC<HabitCardProps> = ({
  habit,
  userHabit = undefined,
  disabled = false,
  onClick = undefined
}) => {
  return (
    <StyledCard
      alignItems={disabled ? "center" : undefined}
      container
      filter={disabled ? "grayscale(1)" : "none"}
      margin={`${spacingDefaults.medium} 0`}
      minHeight="100px"
      onClick={onClick}
      padding={spacingDefaults.normal}
      style={{
        cursor: disabled ? "default" : "pointer",
        gap: spacingDefaults.normal,
        opacity: disabled ? 0.8 : "none"
      }}
      textAlign="left"
      wrap="nowrap"
    >
      <StyledGrid item position="relative">
        {!disabled && userHabit && userHabit?.maxStreakCount && (
          <>
            <div style={{ position: "absolute" }}>
              <CircularProgressBackground
                color="secondary"
                size={"75px"}
                thickness={3}
                value={100}
                variant="determinate"
              />
            </div>
            <CircularProgress
              color="secondary"
              size={"75px"}
              thickness={3}
              value={
                userHabit.streakCount > userHabit.maxStreakCount //circle should show full if max streak is reached or surpassed
                  ? 100
                  : (userHabit.streakCount / userHabit.maxStreakCount) * 100
              }
              variant="determinate"
            />
            {userHabit?.streakLevel && (
              <div style={{ position: "absolute", top: "10px", left: "10px" }}>
                <img
                  src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                    userHabit.streakLevel +
                    (userHabit.streakCount > userHabit.maxStreakCount //badge should show complete if max streak is reached or surpassed
                      ? 0
                      : userHabit?.streakCount === userHabit?.maxStreakCount
                      ? 0
                      : -1)
                  }.png`}
                  height="55px"
                />
              </div>
            )}
          </>
        )}
        {disabled && <img src={LockIcon} height="40px" />}
      </StyledGrid>
      <StyledGrid item>
        <StyledGrid
          container
          direction="column"
          height="100%"
          justifyContent="space-evenly"
        >
          <StyledGrid item>
            <Box fontSize={fontSize.normal}>{habit.name}</Box>
          </StyledGrid>
          {!disabled && (
            <StyledGrid item>
              <Box fontSize={fontSize.small} color={color.GRAPHITE}>
                {habit.content}
              </Box>
            </StyledGrid>
          )}
        </StyledGrid>
      </StyledGrid>
    </StyledCard>
  );
};
export default HabitCard;
