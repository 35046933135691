import { Box, useMediaQuery } from "@material-ui/core";
import React, { useRef } from "react";
import { StyledGrid } from "components/simple";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import VideoThumbnail from "assets/consent-video-thumbnail.png";

const PageThree: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const videoEl = useRef<HTMLVideoElement>(null);
  return (
    <StyledGrid
      paddingTop={spacingDefaults.large}
      alignItems="center"
      container
      direction="column"
    >
      <video
        ref={videoEl}
        width={mobile ? "95%" : "850px"}
        height={mobile ? "100%" : "480px"}
        controls
        style={{
          maxWidth: "850px",
          maxHeight: "480px"
        }}
        poster={VideoThumbnail}
      >
        <source
          src="https://videos.ctfassets.net/n7r832fbv3mz/5c7eYUcce642d1OReKhTLK/525e9c20b92d936b77a963b8bb12ad49/BG_setup_and_installation.mp4"
          type="video/mp4"
        ></source>
      </video>
      <Box
        fontFamily={fontFamily.primary}
        fontSize={mobile ? fontSize.medium : fontSize.medium}
        letterSpacing="-0.45px"
        margin={`${spacingDefaults.large} ${spacingDefaults.xsmall}`}
        textAlign="center"
      >
        Watch this quick video to learn more about how to use Brain Gauge by
        Cortical Metrics.
      </Box>
    </StyledGrid>
  );
};

export default PageThree;
