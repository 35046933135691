import { PillarScore, UserAssessmentCycleStatus } from "graphql/types/Cycle";

export interface Cycle {
  status: UserAssessmentCycleStatus;
  cycleNumber: number;
  cycleCompletionDate: string;
  aceCompleted: boolean;
  notes: string;
  pillarScores: PillarScore[];
  coachingSessions: CoachingSession[];
  imagingSessions: ImagingSession[];
}

export interface Pillar {
  color: string;
  description: string;
  pillarName: string;
  value: number;
  order: number;
  weight: number;
  index?: number;
  assessments: PillarAssessment[];
  moreInfo: Record<any, any>[];
}

export interface PillarAssessment {
  name: string;
  score: number;
  description: string;
}

export interface CoachingSession {
  id: string;
  coach: {
    firstName: string;
  };
  status: CoachingSessionStatus;
  acuityConfirmationNumber: number;
  sessionTime: string;
  updatedAt: string;
  participantNotes: string | null;
  userAssessmentCycleId: string;
  reflectionSurvey: {
    completionDate: string | null;
    dismissalDate: string | null;
    answers: {
      answerData: {
        response: string;
      };
    }[];
  } | null;
}

export interface ImagingSession {
  id: string;
  status: CoachingSessionStatus;
  acuityConfirmationNumber: number;
  sessionTime: string;
  updatedAt: string;
  participantNotes: string | null;
}

export enum CoachingSessionStatus {
  SCHEDULED = "scheduled",
  COMPLETED = "completed",
  CANCEL = "cancel",
  MISSED = "missed"
}

export enum SessionType {
  COACHING = "coaching",
  IMAGING = "imaging",
  GROUP = "group"
}
