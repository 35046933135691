import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { Document } from "@contentful/rich-text-types";
import { showErrorNotification } from "state";

import BlueHeaderCurve from "assets/blue-header-curve.svg";
import { fontFamily, fontSize, spacingDefaults } from "style/constants";
import { StyledGrid, Loading, Button } from "components/simple";
import RichText from "components/pages/Contentful/Unit/RichText";
import { GetArticleContent } from "graphql/userResources/userResources.gql";

interface ArticleParams {
  id: string;
}

interface ArticleContentResponse {
  getArticleContent: Article;
}

interface Article {
  id: string;
  bhComponents?: string[];
  target: string;
  hasVideo: boolean;
  slug?: string;
  content?: Document;
  coverImage: {
    description?: string;
    url: string;
  };
  publicationDate: string;
  readingTime: number;
  source: string;
  summary: string;
  title: string;
  url?: string;
  completed: boolean;
}

const Article: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<ArticleParams>();
  const [article, setArticle] = useState<Article>();

  const { loading } = useQuery<ArticleContentResponse>(GetArticleContent, {
    variables: { input: { contentfulResourceId: id } },
    onCompleted: data => setArticle(data?.getArticleContent),
    onError: () => {
      history.push({
        pathname: "/resources"
      });
      dispatch(showErrorNotification("Error", "Failed to retrieve article"));
    }
  });

  if (loading) return <Loading />;
  return (
    <>
      {/* header */}
      <StyledGrid
        container
        centerContent
        alignItems="center"
        minHeight="250px"
        backgroundImage={BlueHeaderCurve}
        backgroundPosition="bottom"
      >
        <StyledGrid item padding={`0 ${spacingDefaults.normal}`}>
          <Box fontSize={fontSize.normal} fontFamily={fontFamily.secondary}>
            {article?.source}
          </Box>
          <Box fontSize={fontSize.large}>{article?.title}</Box>
        </StyledGrid>
      </StyledGrid>
      {/* content */}
      <StyledGrid
        container
        centerContent
        padding={`0 ${spacingDefaults.normal}`}
      >
        <StyledGrid maxWidth="1100px" centerContent>
          <StyledGrid container margin={`${spacingDefaults.xlarge} 0`}>
            <StyledGrid item>
              <Box fontSize={fontSize.medium}>{article?.summary}</Box>
            </StyledGrid>
          </StyledGrid>
          <RichText data={article?.content} />
        </StyledGrid>
      </StyledGrid>
      <StyledGrid centerContent marginTop="auto">
        <Link to="/resources">
          <Button>Back to resources</Button>
        </Link>
      </StyledGrid>
    </>
  );
};

export default Article;
