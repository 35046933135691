import React, { useEffect, useState } from "react";
import { AssessmentCard, Button, StyledGrid } from "components/simple";
import { spacingDefaults } from "style/constants";
import { DigitSymbolInstructionData } from ".";
import { Platform } from "..";

interface InstructionsData {
  imgSrc: string;
  question: string;
}

export const Instructions: React.FC<DigitSymbolInstructionData> = ({
  callback,
  platform
}) => {
  const instructionsData: Array<InstructionsData> = [
    {
      imgSrc: encodeURI(
        `https://bhp-images.s3.amazonaws.com/svg/cogb/ref-only.svg`
      ),
      question: "You are going to see symbols paired with digits."
    },
    {
      imgSrc: encodeURI(
        `https://bhp-images.s3.amazonaws.com/svg/cogb/cog-b-ref1-1.svg`
      ),
      question: "You will then be shown a digit-symbol pair."
    },
    {
      imgSrc: encodeURI(
        `https://bhp-images.s3.amazonaws.com/svg/cogb/cog-b-practice-correct.svg`
      ),
      question: `If the digit-symbol pair matches the example, ${
        platform == Platform.Touchscreen
          ? 'tap "Yes"'
          : 'press "f" on your keyboard'
      }.`
    },
    {
      imgSrc: encodeURI(
        `https://bhp-images.s3.amazonaws.com/svg/cogb/cog-b-practice-wrong.svg`
      ),
      question: `If the digit-symbol pair DOES NOT match the example, ${
        platform == Platform.Touchscreen
          ? 'tap "No"'
          : 'press "j" on your keyboard'
      }.`
    }
  ];

  const [questionNumber, setQuestionNumber] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(
    instructionsData[questionNumber]
  );

  const buttonClicked = () => {
    const lastQuestion = questionNumber + 1 == instructionsData.length;
    if (lastQuestion) {
      callback(null);
    } else {
      setQuestionNumber(questionNumber + 1);
    }
  };

  useEffect(() => {
    setCurrentQuestion(instructionsData[questionNumber]);
  }, [questionNumber]);

  return (
    <AssessmentCard question={currentQuestion.question}>
      <StyledGrid
        item
        marginTop="1rem"
        marginBottom="1.5rem"
        maxWidth="680px"
        mobileMarginTop="0"
        mobileMarginBottom={spacingDefaults.large}
        width="100%"
      >
        <img src={currentQuestion.imgSrc} width="100%" />
      </StyledGrid>
      <StyledGrid
        item
        marginTop="1.6875rem"
        mobileMarginTop={spacingDefaults.xsmall}
      >
        <Button data-testid="next-btn" onClick={buttonClicked} zIndex={100}>
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};
