import { color, fontFamily } from "style/constants";

export default {
  root: {
    "&:hover": {
      "&$disabled": {
        backgroundColor: color.GRAY
      }
    },
    "&$disabled": {
      backgroundColor: color.GRAY,
      color: color.BLACK
    }
  },
  text: {
    backgroundColor: color.DARKBLUE,
    border: 0,
    borderRadius: 23,
    boxShadow: "none",
    color: color.WHITE,
    fontFamily: fontFamily.primary,
    fontWeight: 500,
    fontSize: "1rem",
    minHeight: 32,
    lineHeight: "19px",
    padding: "14px 48px",
    "&:hover": {
      backgroundColor: color.DARKBLUE
    }
  }
};
