import React from "react";

import StyledGrid from "../StyledGrid";
import WheelIcon from "../WheelIcon";

interface WheelIconsProps {
  pillars: string[];
}

const pillarNames = [
  "cognition",
  "cognitive",
  "interaction",
  "social",
  "well-being",
  "daily life",
  "real-life"
];

const WheelIcons: React.FC<WheelIconsProps> = ({
  pillars
}: WheelIconsProps) => {
  return (
    <StyledGrid alignItems="center" container height="32px">
      {pillars.map((pillar: string, index) =>
        pillarNames.includes(pillar.toLowerCase()) ? (
          <StyledGrid
            key={index}
            marginLeft="-16px"
            zIndex={String(pillars.length - index)}
          >
            <WheelIcon pillar={pillar} />
          </StyledGrid>
        ) : null
      )}
    </StyledGrid>
  );
};

export default WheelIcons;
