import { IconButton, Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import {
  color,
  fontFamily,
  fontSize,
  iconSizes,
  spacingDefaults
} from "style/constants";
import { StyledGrid, Button } from "components/simple";
import { ReactComponent as Close } from "assets/close-white.svg";
import { ReactComponent as CircleOk } from "assets/circle-ok.svg";
import { ReactComponent as WorkTime } from "assets/work-time.svg";
import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
import { ReactComponent as WalkInThePark } from "assets/walk-in-the-park.svg";
import { ReactComponent as DotsFirst } from "assets/dots-first.svg";
import { ReactComponent as DotsSecond } from "assets/dots-second.svg";
import {
  Dispatch,
  SetStateAction
} from "hoist-non-react-statics/node_modules/@types/react";

interface ModalProps {
  data: {
    icon?: JSX.Element;
    image?: string;
    title?: string;
    cancel?: boolean;
    content?: string;
    confirmText?: string;
    onConfirm?: React.Dispatch<any>;
    cancelText?: string;
    successIcon?: boolean;
    onSubmitText?: string;
    successText?: string;
    divideLine?: boolean;
    contentTwo?: string;
    skip?: boolean;
    dotsFirst?: boolean;
    dotsSecond?: boolean;
    spaceBottom?: boolean;
    contentLarger?: string;
    extraSpace?: boolean;
  };
  onCancel: () => void;
  cancelButton?: boolean;
  email?: string;
  setEmail?: Dispatch<SetStateAction<string>>;
  onSubmit?: () => void;
  forwardArrow?: () => void;
  onSkip?: () => void;
  emailError?: boolean;
  isNewUser?: boolean;
  profilePage?: boolean;
  noButton?: boolean;
  partnerName?: string;
}

const StyledInput = styled("input")`
  height: 3rem;
  width: 350px;
  padding-left: 1rem;
  border: solid 2px ${color.GRAY};
  font-size: ${fontSize.medium};
  color: ${color.DARKERGRAY};
  border-radius: 8px;
  font-size: fontSize.normal;
`;

const Ruler = styled("div")`
  border-bottom: 2px solid ${color.GRAY};
  width: 80%;
  align-self: center;
  margin-bottom: ${spacingDefaults.xlarge};
`;

const SpouseModal: React.FC<ModalProps> = ({
  data: {
    title,
    image,
    content,
    onSubmitText,
    successText,
    successIcon,
    divideLine,
    contentTwo,
    skip,
    dotsFirst,
    dotsSecond,
    spaceBottom,
    contentLarger,
    extraSpace
  },
  onCancel,
  cancelButton,
  email,
  setEmail,
  onSubmit,
  onSkip,
  emailError,
  isNewUser,
  forwardArrow,
  profilePage,
  noButton,
  partnerName
}: ModalProps) => (
  <StyledGrid
    absoluteCenter
    backgroundColor={color.BLACK_RGBA}
    container
    centerContent
    height={"100%"}
    width={"100%"}
    style={{ zIndex: 1100 }}
    position="fixed"
    title="spouse-modal"
  >
    <StyledGrid
      absoluteCenter
      backgroundColor={color.WHITE}
      borderRadius={"8px"}
      boxShadow={"0px 4px 14px rgba(223, 223, 223, 0.85);"}
      centerContent
      container
      direction={"column"}
      item
      maxWidth={"800px"}
      width="90%"
    >
      <StyledGrid
        backgroundColor={color.DARKBLUE}
        height="8rem"
        width="100%"
        centerContent
        container
        borderTopRadius="8px"
        marginBottom={spacingDefaults.xlarge}
      >
        <StyledGrid
          item
          position={"absolute"}
          style={{ top: "1em", right: "1em" }}
        >
          <IconButton
            data-testid="close-button"
            aria-label="close"
            key="close"
            onClick={onCancel}
          >
            <Close height={iconSizes.smaller} width={iconSizes.smaller} />
          </IconButton>
        </StyledGrid>
        <StyledGrid item>
          <Box
            color={color.WHITE}
            fontFamily={fontFamily.secondary}
            fontSize={fontSize.xlarge}
          >
            {title}
          </Box>
        </StyledGrid>
      </StyledGrid>
      {image && image === "WalkInThePark" && (
        <StyledGrid
          width="60vw"
          maxWidth="400px"
          maxHeight="300px"
          alignSelf="center"
          marginBottom={spacingDefaults.large}
        >
          <WalkInThePark width="100%" height="100%" />
        </StyledGrid>
      )}
      {image && image === "WorkTime" && (
        <StyledGrid
          width="60vw"
          maxWidth="350px"
          maxHeight="300px"
          alignSelf="center"
          marginBottom={spacingDefaults.large}
        >
          <WorkTime width="100%" height="100%" />
        </StyledGrid>
      )}
      {successText && (
        <StyledGrid paddingBottom={spacingDefaults.medium}>
          <Box color={color.DARKERBLUE} fontSize={fontSize.large}>
            {successText}
          </Box>
        </StyledGrid>
      )}
      {successIcon && (
        <StyledGrid paddingBottom={spacingDefaults.large}>
          <CircleOk height={iconSizes.xxlarge} width={iconSizes.xxlarge} />
        </StyledGrid>
      )}
      {contentLarger && (
        <StyledGrid
          item
          maxWidth={"400px"}
          width="80%"
          alignSelf="center"
          paddingBottom={extraSpace ? "3.5rem" : spacingDefaults.xlarge}
        >
          <Box color={color.DARKERGRAY} fontSize={fontSize.medium}>
            {contentLarger}
          </Box>
        </StyledGrid>
      )}
      {content && (
        <StyledGrid
          item
          maxWidth={"400px"}
          width="80%"
          alignSelf="center"
          paddingBottom={
            noButton ? "4.5rem" : extraSpace ? "3.5rem" : spacingDefaults.xlarge
          }
        >
          <Box color={color.DARKERGRAY}>{content}</Box>
        </StyledGrid>
      )}
      {partnerName && (
        <StyledGrid
          item
          maxWidth={"400px"}
          width="80%"
          alignSelf="center"
          paddingBottom={
            noButton ? "4.5rem" : extraSpace ? "3.5rem" : spacingDefaults.xlarge
          }
        >
          <Box color={color.DARKERGRAY} fontSize={fontSize.large}>
            Partner: {partnerName}
          </Box>
        </StyledGrid>
      )}
      {image === "WorkTime" && (
        <StyledGrid
          item
          maxWidth={"400px"}
          width="80%"
          alignSelf="center"
          paddingBottom={spacingDefaults.xlarge}
        >
          <Box color={color.DARKERGRAY}>
            Before we get started with your first assessment,{" "}
            <b>would you like to invite your partner to join the Project? </b>
            This way, you can experience The BrainHealth Project together and
            encourage each other along the way.
          </Box>
        </StyledGrid>
      )}
      {divideLine && isNewUser && <Ruler />}
      {contentTwo && isNewUser && (
        <StyledGrid
          item
          maxWidth={"400px"}
          width="80%"
          alignSelf="center"
          paddingBottom={spacingDefaults.xlarge}
        >
          <Box color={color.DARKERGRAY}>{contentTwo}</Box>
        </StyledGrid>
      )}
      {setEmail && (
        <StyledGrid marginBottom={spacingDefaults.xlarge} alignSelf="center">
          <StyledInput
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email Address"
            type="email"
          />
          {emailError && (
            <Box color={color.RED} title="validation-error">
              Please enter a valid email.
            </Box>
          )}
        </StyledGrid>
      )}
      {onSubmitText && !noButton && (
        <StyledGrid
          item
          marginBottom={profilePage ? "5rem" : "3rem"}
          marginTop={profilePage ? spacingDefaults.xlarge : "0px"}
        >
          <Button
            background={color.DARKBLUE}
            title="submit-email"
            onClick={() => {
              onSubmit && onSubmit();
            }}
          >
            {onSubmitText}
          </Button>
        </StyledGrid>
      )}
      {skip && isNewUser && (
        <StyledGrid item marginTop={"-1.25em"} marginBottom={"2em"}>
          <Button borderNone inverted onClick={onSkip}>
            Skip
          </Button>
        </StyledGrid>
      )}
      {cancelButton && (
        <StyledGrid item marginTop={"-1.25em"} marginBottom={"2em"}>
          <Button borderNone inverted onClick={onCancel}>
            Cancel
          </Button>
        </StyledGrid>
      )}
      {forwardArrow && (
        <StyledGrid
          item
          position={"absolute"}
          style={{ bottom: "1em", right: "1em" }}
        >
          <IconButton aria-label="next-button" onClick={forwardArrow}>
            <ChevronRight height={iconSizes.normal} width={iconSizes.normal} />
          </IconButton>
        </StyledGrid>
      )}
      {dotsFirst && (
        <>
          <StyledGrid
            alignSelf="center"
            marginBottom={
              spaceBottom ? spacingDefaults.large : spacingDefaults.normal
            }
          >
            <DotsFirst height={iconSizes.normal} width={iconSizes.normal} />
          </StyledGrid>
        </>
      )}
      {dotsSecond && (
        <StyledGrid
          alignSelf="center"
          marginBottom={
            spaceBottom ? spacingDefaults.large : spacingDefaults.normal
          }
        >
          <DotsSecond height={iconSizes.normal} width={iconSizes.normal} />
        </StyledGrid>
      )}
    </StyledGrid>
  </StyledGrid>
);

export default SpouseModal;
