import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

import { ReactComponent as ScreenShareOff } from "assets/screen-share-off.svg";
import { ReactComponent as ScreenShareOn } from "assets/screen-share-on.svg";
import useScreenShareParticipant from "../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { StyledIconButton } from "../styled";

export const SCREEN_SHARE_TEXT = "Share Screen";
export const STOP_SCREEN_SHARE_TEXT = "Stop Sharing Screen";
export const SHARE_IN_PROGRESS_TEXT =
  "Cannot share screen when another user is sharing";
export const SHARE_NOT_SUPPORTED_TEXT =
  "Screen sharing is not supported with this browser";

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare, isSharingScreen, room } = useVideoContext();
  const disableScreenShareButton =
    Boolean(screenShareParticipant) &&
    screenShareParticipant?.sid !== room!.localParticipant.sid;
  const isScreenShareSupported =
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled =
    props.disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = "";

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
    >
      <span>
        {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}

        <StyledIconButton
          onClick={toggleScreenShare}
          disabled={isDisabled}
          data-cy-share-screen
        >
          {isSharingScreen ? <ScreenShareOn /> : <ScreenShareOff />}
        </StyledIconButton>
      </span>
    </Tooltip>
  );
}
