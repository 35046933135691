import React from "react";
import styled from "styled-components";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";

import { breakpoints, color } from "style/constants";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { fontSize } from "style/constants";

export const CloseButtonWrapper = styled.div`
  @media (max-width: ${breakpoints.lg}px) {
    margin-right: 25px;
  }
  margin-right: 52px;
  svg {
    height: 15px;
    width: 15px;
    path {
      stroke: ${color.ALTBLACK};
      stroke-width: 1px;
    }
  }
`;

export const IconWrapper = styled.div`
  .MuiTabs-flexContainer {
    .MuiIconButton-root:hover {
      background-color: transparent;
    }
    margin-right: 52px;
    * {
      min-width: 30px;
      max-width: 30px;
    }
    * + * {
      margin-left: 30px;
      flex-wrap: nowrap;
    }
    * > span {
      background-color: ${color.ICON};
      border-radius: 100%;
      height: 30px;
      width: 30px;
    }
    @media (max-width: ${breakpoints.lg}px) {
      .MuiIconButton-root:hover {
        background-color: transparent;
      }
      margin-right: 52px;
      * {
        min-width: 30px;
        max-width: 30px;
      }
      * + * {
        margin-left: 15px;
        flex-wrap: nowrap;
      }
      * > span {
        background-color: ${color.ICON};
        border-radius: 100%;
        height: 30px;
        width: 30px;
      }
    }
  }
  svg {
    height: 15px;
    width: 15px;
    path {
      stroke: ${color.BLACK};
      stroke-width: 2px;
    }
  }
`;

export const Image = styled.img`
  height: 80px;
  margin-left: 38px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
    margin-left: 0;
  }
`;

export const StyledAppBar = styled(AppBar)`
  height: 80px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
  }
  .MuiButtonBase-root:focus {
    outline: none;
  }
`;

export const StyledTab = styled(({ ...otherProps }) => <Tab {...otherProps} />)`
  && {
    font-size: 1rem;
    text-transform: none;
  }
`;

export const StyledTabs = styled(({ ...otherProps }) => (
  <Tabs {...otherProps} />
))`
  .MuiTabs-flexContainer {
    height: 80px;
    @media (max-width: ${breakpoints.lg}px) {
      height: 65px;
      margin-right: 20px;
    }
  }
`;
