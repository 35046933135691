import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";

import {
  addBackgroundColorToRoot,
  appWidth,
  color,
  fontFamily,
  fontSize,
  removeBackgroundColorFromRoot,
  spacingDefaults
} from "style/constants";
import { BackButton, Button, StyledGrid } from "components/simple";
import TreeHeader from "components/simple/TreeHeader";

const HabitSetup: React.FC = () => {
  const history = useHistory();

  addBackgroundColorToRoot(color.WHITE);
  useEffect(() => {
    return () => {
      removeBackgroundColorFromRoot();
    };
  }, []);

  return (
    <>
      <BackButton location="dashboard" />
      <TreeHeader topHeader={"Habit"} />
      <StyledGrid container centerContent>
        <div
          style={{
            maxWidth: appWidth.max,
            padding: `0 ${spacingDefaults.normal}`
          }}
        >
          <Box fontFamily={fontFamily.secondary} fontSize={fontSize.header}>
            Unlocking a <br /> better you!
          </Box>
          <StyledGrid
            container
            direction="column"
            margin={`${spacingDefaults.large} 0`}
            style={{ gap: spacingDefaults.large }}
          >
            <StyledGrid item>
              <Box fontSize={fontSize.medium} color={color.GRAPHITE}>
                Welcome to habits! <br /> Habits are here to help you apply your
                training to every day life.
              </Box>
            </StyledGrid>
            <StyledGrid item>
              <Box fontSize={fontSize.medium} color={color.GRAPHITE}>
                Earn badges by practicing the same habit.
              </Box>
            </StyledGrid>
            <StyledGrid item>
              <Box fontSize={fontSize.medium} color={color.GRAPHITE}>
                On the next screen, you can select the first habit to focus on.
              </Box>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid
            container
            centerContent
            direction="column"
            style={{ gap: spacingDefaults.normal }}
          >
            <StyledGrid item>
              <Button
                width="215px"
                onClick={() => history.push("/track-your-habits")}
              >
                Continue
              </Button>
            </StyledGrid>
          </StyledGrid>
        </div>
      </StyledGrid>
    </>
  );
};
export default HabitSetup;
