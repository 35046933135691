import { ReactComponent as NextDayButtonEnabled } from "assets/chevron-right-roundbutton.svg";
import { ReactComponent as NextDayButtonDisabled } from "assets/chevron-right-roundbutton-disabled.svg";
import { IconButton } from "@material-ui/core";
import React from "react";

interface StreakPaginationProps {
  shouldDisable: boolean;
  onNextDayClick(): void;
}

const NextDayButton: React.FC<StreakPaginationProps> = ({
  shouldDisable,
  onNextDayClick
}: StreakPaginationProps) => {
  return (
    <>
      <IconButton
        aria-label="next day"
        onClick={onNextDayClick}
        style={{ outline: "none" }}
        disabled={shouldDisable}
      >
        {shouldDisable && <NextDayButtonDisabled />}
        {!shouldDisable && <NextDayButtonEnabled />}
      </IconButton>
    </>
  );
};

export default NextDayButton;
