import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import { StyledGrid } from "components/simple";
import {
  color,
  fontFamily,
  fontSize,
  spacingDefaults,
  breakpoints
} from "style/constants";
import ProfilePicture from "./Picture";

type Statistic = {
  label: string;
  value: number;
};

interface ProfileSummaryProps {
  name: string;
  statistics: Statistic[];
}

const ProfileSummary: React.FC<ProfileSummaryProps> = ({
  name,
  statistics
}: ProfileSummaryProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      container
      justifyContent={mobile ? "center" : undefined}
      padding={mobile ? "4.5rem 0 1rem 0" : undefined}
      position="relative"
    >
      <StyledGrid
        item
        padding={mobile ? undefined : "1.5rem 3rem"}
        position={mobile ? "absolute" : undefined}
        left={mobile ? "50%" : undefined}
        top={mobile ? "-62.5px" : undefined}
        transform={mobile ? "translateX(-50%)" : undefined}
        xs={mobile ? 12 : undefined}
      >
        <ProfilePicture />
      </StyledGrid>

      <StyledGrid
        alignContent="center"
        container
        item
        justifyContent="center"
        textAlign={mobile ? "center" : undefined}
        xs={mobile ? 12 : true}
      >
        <StyledGrid
          item
          marginBottom={
            mobile ? spacingDefaults.xsmall : spacingDefaults.normal
          }
          xs={12}
        >
          <Box
            component="h2"
            fontFamily={fontFamily.secondary}
            fontWeight="400"
            fontSize={mobile ? fontSize.large : "30px"}
            letterSpacing="-0.45px"
            margin="0"
          >
            {name}
          </Box>
        </StyledGrid>

        <StyledGrid
          container
          item
          justifyContent={mobile ? "center" : undefined}
          xs={12}
        >
          {statistics.map((statistic, index) => (
            <StyledGrid item key={index} width="100px">
              <Box
                color={color.DARKBLUE}
                component="p"
                fontSize="26px"
                margin={`0 0 ${mobile ? 0 : spacingDefaults.xsmall} 0`}
              >
                {statistic.value}
              </Box>

              <Box
                color={color.DARKERGRAY}
                component="p"
                fontSize="12px"
                margin="0"
              >
                {statistic.label}
              </Box>
            </StyledGrid>
          ))}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ProfileSummary;
