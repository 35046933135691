import { createAction } from "typesafe-actions";

import { Subdomain, User } from "./types";

// Loader

export const showLoader = createAction("loader/SHOW_LOADER")();

export const hideLoader = createAction("loader/HIDE_LOADER")();

// Notifier

export const showInfoNotification = createAction(
  "notifier/SHOW_INFO_NOTIFICATION",
  (title = "Information", description = "") => ({
    title,
    description
  })
)();

export const showSuccessNotification = createAction(
  "notifier/SHOW_SUCCESS_NOTIFICATION",
  (title = "Success!", description = "") => ({
    title,
    description
  })
)();

export const showErrorNotification = createAction(
  "notifier/SHOW_ERROR_NOTIFICATION",
  (title = "Something went wrong", description = "") => ({
    title,
    description
  })
)();

export const hideNotification = createAction("notifier/HIDE_NOTIFICATION")();

// User

export const setUser = createAction(
  "user/SET_USER",
  (user: Partial<User>) => user
)();

export const setSubdomain = createAction(
  "user/SET_SUBDOMAIN",
  (subdomain: Subdomain | null) => subdomain
)();

// Native Experience

export const setNativeExperience = createAction("native/NATIVE_EXPERIENCE")();
