import React, { Dispatch, SetStateAction } from "react";
import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { StyledGrid } from "components/simple";
import { color, spacingDefaults } from "style/constants";
import { UserHabit } from "models/userHabits";

export interface RecommendedHabit extends UserHabit {
  index: number;
}

interface HabitCarouselFooterProps {
  habits: RecommendedHabit[];
  currentHabit: RecommendedHabit;
  setCurrentHabit: Dispatch<SetStateAction<RecommendedHabit>>;
}

const HabitCarouselFooter: React.FC<HabitCarouselFooterProps> = ({
  habits,
  currentHabit,
  setCurrentHabit
}) => {
  return (
    <StyledGrid container justifyContent="space-between" alignItems="center">
      <StyledGrid
        backgroundColor={color.WHITE}
        borderRadius="50%"
        item
        boxShadow="0px 4px 13px rgba(0, 0, 0, 0.15)"
      >
        <IconButton
          aria-label="Previous"
          color="secondary"
          onClick={() => {
            const index =
              currentHabit.index === 0
                ? habits.length - 1
                : currentHabit.index - 1;
            setCurrentHabit({
              ...habits[index],
              index
            });
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </StyledGrid>
      <StyledGrid item>
        <StyledGrid
          container
          justifyContent="center"
          style={{ gap: spacingDefaults.normal }}
        >
          {habits.map((item: RecommendedHabit, idx: number) => (
            <StyledGrid key={idx} item>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor:
                    currentHabit?.habit?.name === item?.habit?.name
                      ? color.DARKBLUE
                      : color.INDEX_BG,
                  borderRadius: "50%"
                }}
              />
            </StyledGrid>
          ))}
        </StyledGrid>
      </StyledGrid>
      <StyledGrid
        backgroundColor={color.WHITE}
        borderRadius="50%"
        item
        boxShadow="0px 4px 13px rgba(0, 0, 0, 0.15)"
      >
        <IconButton
          aria-label="Next"
          color="secondary"
          onClick={() => {
            const index = (currentHabit.index + 1) % habits.length;
            setCurrentHabit({
              ...habits[index],
              index
            });
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default HabitCarouselFooter;
