import React, { useEffect, useState } from "react";
import Joyride, { CallBackProps, EVENTS, Step } from "react-joyride";
import styled from "styled-components";

import { ReactComponent as Lightbulb } from "assets/lightbulb.svg";
import useCoachMarks from "hooks/useCoachMarks";
import { color, fontFamily } from "style/constants";
import "../ProductTour/style.css";
import "./style.css";

export const StyledLightbulb = styled(Lightbulb)`
  border-radius: 50%;
  display: flex;
  width: 31px;
  height: 31px;
  &:hover {
    cursor: pointer;
  }
`;

const CoachMarks: React.FC<{
  coachMarksName: string;
}> = ({ coachMarksName }) => {
  const styles = {
    options: {
      backgroundColor: color.WHITE,
      fontFamily: fontFamily.primary,
      primaryColor: color.DARKBLUE,
      textColor: color.LIGHTGRAY,
      zIndex: 1
    }
  };

  const { productTour: coachMarks } = useCoachMarks(coachMarksName);
  const [steps, setSteps] = useState<Step[]>([]);
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (coachMarks) {
      const stepContent: Step[] = coachMarks.steps.map(step => {
        return {
          content: step.description,
          disableBeacon: true,
          hideFooter: true,
          target: ".lightbulb",
          title: step.title,
          spotlightPadding: 0
        };
      });
      setSteps(stepContent as Step[]);
    }
  }, [coachMarks]);

  useEffect(() => {
    if (run) {
      return () => {
        return setRun(false);
      };
    }
  }, [run]);

  useEffect(() => {
    if (run) {
      const html = document.querySelector("html");
      html?.classList.add("coach-marks-wrapper");
    }

    return () => {
      const html = document.querySelector("html");
      html?.classList.remove("coach-marks-wrapper");
    };
  }, [run]);

  if (coachMarks) {
    const callback = (data: CallBackProps) => {
      if (data.type == EVENTS.TOUR_END) {
        setRun(false);
      }
    };

    return (
      <>
        <StyledLightbulb
          className="lightbulb"
          onClick={() => {
            setRun(true);
          }}
        />
        <Joyride
          callback={callback}
          continuous={false}
          run={run}
          steps={steps}
          styles={styles}
          scrollToFirstStep={false}
        />
      </>
    );
  }
  return <></>;
};

export default CoachMarks;
