import { useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import track from "react-tracking";

import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import { Button, Confetti, StyledP, StyledGrid } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";
import { awardCoinType } from "graphql/types/Contentful";
import CoinModal from "components/simple/CoinModal";
import { useHistory, useLocation } from "react-router-dom";
import {
  CompleteImage,
  Subtitle
} from "components/pages/Assessments/OutroFinal/styled";

const url: string = routesConfig.surveys.complete.path;

const Outro: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  const { state } = useLocation<{
    coinsAwarded: awardCoinType[];
    hideConfetti: boolean;
    isCompletedAssessment: boolean;
  }>();
  if (!state) history.push("/dashboard");

  const hideConfetti = state.hideConfetti ?? false;
  const coinsAwarded = state?.coinsAwarded ?? [];
  const isCompletedAssessment = state?.isCompletedAssessment ?? false;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    coinsAwarded.length > 0 ? true : false
  );

  return (
    <>
      <StyledGrid
        alignItems="center"
        container
        centerContent
        direction="column"
        position="relative"
      >
        {!hideConfetti && <Confetti />}

        <StyledGrid
          item
          marginTop="175px"
          marginBottom={spacingDefaults.large}
          mobileMarginTop="100px"
          zIndex="1"
        >
          {isCompletedAssessment ? (
            <CompleteImage />
          ) : (
            <CheckIcon fill={color.GREEN} height={120} width={120} />
          )}
        </StyledGrid>

        <StyledGrid item marginBottom={spacingDefaults.large}>
          {isCompletedAssessment ? (
            <Subtitle>You are finished!</Subtitle>
          ) : (
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.header}
              textAlign="center"
            >
              <strong>Thank you!</strong>
            </StyledP>
          )}
        </StyledGrid>

        {!isCompletedAssessment && (
          <StyledGrid item marginBottom={spacingDefaults.xlarge}>
            <StyledP
              fontSize={fontSize.medium}
              lineHeight={fontSize.large}
              textAlign="center"
              width={mobile ? "290px" : "420px"}
            >
              We appreciate you taking the time to help improve the BrainHealth
              Project.
            </StyledP>
          </StyledGrid>
        )}

        <Link to={routesConfig.dashboard.path}>
          <Button aria-label="return-to-dashboard">Back to Dashboard</Button>
        </Link>
      </StyledGrid>
      <CoinModal
        openModal={isModalOpen}
        coinsAwarded={coinsAwarded}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default track({
  url
})(Outro);
