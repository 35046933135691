import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";

import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import {
  addOpacity,
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { Button, Confetti, StyledGrid } from "components/simple";
import { awardCoinType } from "graphql/types/Contentful";
import CoinModal from "components/simple/CoinModal";
import { UserHabit } from "models/userHabits";

const HabitSurveyComplete: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  const { state } = useLocation<{
    coinsAwarded: awardCoinType[];
    userHabit: UserHabit;
  }>();
  if (!state) history.push("/dashboard");

  const coinsAwarded = state?.coinsAwarded;
  const userHabit = state.userHabit;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    coinsAwarded.length > 0 ? true : false
  );

  const completedLevel = userHabit.streakCount + 1 === userHabit.maxStreakCount;

  return (
    <>
      <StyledGrid
        container
        centerContent
        padding={`0 ${spacingDefaults.normal}`}
      >
        <Confetti />
        <StyledGrid maxWidth="450px">
          <StyledGrid marginTop="30%" marginBottom={spacingDefaults.medium}>
            {completedLevel ? (
              <img
                src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                  userHabit.streakLevel +
                  (userHabit?.streakCount + 1 === userHabit?.maxStreakCount
                    ? 0
                    : -1)
                }.png`}
                height="200px"
              />
            ) : (
              <CheckIcon fill={color.GREEN} height={120} width={120} />
            )}
          </StyledGrid>
          <StyledGrid margin={`${spacingDefaults.xsmall} 0`}>
            <Box
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.header}
            >
              <strong>
                {completedLevel ? "You've Leveled Up!" : "Habit Completed!"}
              </strong>
            </Box>
          </StyledGrid>
          <StyledGrid margin={`${spacingDefaults.xsmall} 0`}>
            <Box
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.large}
            >
              {`${userHabit?.habit?.name} - Level ${userHabit?.streakLevel} ${
                completedLevel ? "Completed" : ""
              }`}
            </Box>
          </StyledGrid>
          {userHabit?.habit?.content && (
            <StyledGrid margin={`${spacingDefaults.large} 0`}>
              <Box fontSize={fontSize.medium}>{userHabit?.habit?.content}</Box>
            </StyledGrid>
          )}
          {userHabit?.habit?.science && (
            <StyledGrid
              container
              direction="column"
              margin={`${spacingDefaults.large} 0`}
              borderRadius="20px"
              padding={spacingDefaults.normal}
              style={{
                backgroundColor: addOpacity(color.LIGHTGRAY, 0.1),
                gap: "1rem"
              }}
            >
              <Box
                fontSize={fontSize.medium}
                color={color.GRAPHITE}
                fontWeight="bold"
              >
                The Science
              </Box>
              <Box fontSize={fontSize.medium} color={color.GRAPHITE}>
                {userHabit?.habit?.science}
              </Box>
            </StyledGrid>
          )}
          <StyledGrid margin={`${spacingDefaults.normal} 0`}>
            <Button onClick={() => history.push("/dashboard")}>
              Back to dashboard
            </Button>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
      <CoinModal
        openModal={isModalOpen}
        coinsAwarded={coinsAwarded}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
export default HabitSurveyComplete;
