import { color } from "style/constants";

export const getComponentDisplayValue = (component: string): string => {
  switch (component) {
    case "cognition": {
      return "Cognition";
    }

    case "daily-life": {
      return "Daily-Life";
    }

    case "well-being": {
      return "Well-Being";
    }

    case "interaction": {
      return "Interaction";
    }

    default: {
      return "";
    }
  }
};

export const getComponentColor = (component: string): color => {
  switch (component) {
    case "cognition": {
      return color.PURPLE;
    }

    case "daily-life": {
      return color.RED;
    }

    case "well-being": {
      return color.GREEN;
    }

    case "interaction": {
      return color.DARKYELLOW;
    }

    default: {
      return color.BLACK;
    }
  }
};
