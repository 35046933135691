import { UserAssessment } from "graphql/types/User";
import { Pillar } from "../types";

export const getAssessmentDescription = (name: string): string => {
  switch (name) {
    case "Social Support":
      return "Measures how much emotional, tangible, and affectionate social support you experience in your life.";
    case "Engagements":
      return "Measures the extent to which social abilities are integrated in your daily life to positively impact you and those around you.";
    case "Sleep":
      return "Measures your reported quality of sleep over the previous month.";
    case "Outlook":
      return "Measures the degree to which you believe you can positively impact your brain health and performance with effort and focused practice.";
    case "Fitness":
      return "Measures your level of cardiorespiratory fitness.";
    case "Activities":
      return "Measures the level of positive subjective experience one derives from meaningful activity.";
    case "Satisfaction":
      return "Measures your subjective sense of your quality of life in multiple domains.";
    case "Resilience":
      return "Measures mental and emotional resilience in the face of stress and difficulty. ";
    case "Mood":
      return "Measures symptoms of depression, anxiety, and stress you’ve experienced over the past week.";
    case "Happiness":
      return "Measures your sense of personal happiness.";
    case "Strategy":
      return "Measures your ability to sustain focus and block distractions.";
    case "Reasoning":
      return "Measures your ability to process complex information in order to synthesize high-level ideas and recall key details.";
    case "Innovation":
      return "Measures your ability to generate a wide range of novel and workable ideas and solutions from multiple perspectives.";
    case "Abstraction":
      return "Measures your ability to capture the underlying meaning from information.";
    case "Compassion":
      return "Measures concern for others who suffer and desire to reduce others’ suffering.";
    default:
      return "Coming soon.";
  }
};

export const mapScores = (
  pillarName: string,
  value: number,
  userAssessments: Partial<UserAssessment>[]
): Pillar => {
  let color = "#FFF";
  let description = "Description not found.";
  let order = 10;
  let weight = 1;
  let moreInfo: Record<any, any>[] = [];

  const assessments = userAssessments.map(a => {
    let totalScore = 0;
    if (a.scores) {
      a.scores.forEach(score => {
        if (score.scoreType === "percentile") totalScore += score.value;
      });
    }

    // only for reasoning and mood get the average of their 3 sub-scores
    if (a.name === "Reasoning") totalScore = totalScore / 3;
    if (a.name === "Mood") totalScore = totalScore / 3;

    return {
      name: a.name || "N/A",
      score: totalScore,
      description: getAssessmentDescription(a.name || "")
    };
  });

  switch (pillarName) {
    case "cognitive":
      pillarName = "Cognition";
      color = "#7B1FA2";
      weight = 3;
      order = 1;
      description =
        "The COGNITION component looks at how you think, learn, problem solve, remember and more. We look at your complex thinking capacities -  capacities that are required to take in information, process it, come back to it later, and combine it in different ways to create something completely new.";
      moreInfo = [
        {
          subContent: [
            {
              title: "Reasoning",
              content: [
                "Measures your capacity to integrate input from multiple sources to synthesize big ideas and bottom-line meanings. Also measures your ability to recall key details from complex information."
              ]
            },
            {
              title: "Abstraction",
              content: [
                "Measures your ‘big idea’ thinking ability, i.e., the ability to go beyond the stated ideas/facts/information and connect these in new ways to other learnings to create  more generalized meanings and applications."
              ]
            },
            {
              title: "Strategy",
              content: [
                "Measures your ability to block out irrelevant, distractions to maximally focus on key people/tasks/contexts. Selecting important information while filtering that which is unimportant to the task/conversation/moment at hand makes efficient use to calibrate your mental resources."
              ]
            },
            {
              title: "Innovation",
              content: [
                "Measures your ability to expand your thinking beyond deriving single solutions toward seeking a multitude of possible ideas, pathways, perspectives, opportunities, and options. It increases nimble, flexible thinking and is a major brain potentiator (accelerator) to increase neural efficiency."
              ]
            }
          ]
        }
      ];
      break;
    case "social":
      pillarName = "Interaction";
      color = "#BB8D00";
      order = 2;
      description =
        "The INTERACTION component looks at your engagements and quality of relationships. We want to know how you feel about your social support networks and the meaningfulness of your social connections.";
      moreInfo = [
        {
          subContent: [
            {
              title: "Engagements",
              content: [
                "Measures the extent to which your social abilities are integral to your daily life with a positive impact. It is your ability to establish meaningful relationships, participate in social conversations/discussions and navigate social challenges you encounter with agility and adeptness to the emotions of others. We can practice brain-healthy habits to: - become more attuned to where our social-cognitive effort is most needed, with awareness of interactions that can build or deplete our social health      - recognize and improve our personal social response styles      -gain confidence in speaking up to be part of the social interactions     - improve the quality and depth of our human interactions."
              ]
            },
            {
              title: "Social Support",
              content: [
                "Measures how much emotional, tangible and affectionate social support you experience in your life. These are the close and meaningful social relationships that provide positive support as you pursue your goals and as you seek trusted advice to bounce ideas and feel safe without judgment. Research has found that having more – and more meaningful – social supports correlates to lowered risk of mortality, cognitive decline, dementia, depression, cardiovascular disease, high blood pressure and upper respiratory infection. "
              ]
            },
            {
              title: "Compassion",
              content: [
                "Measures your ability to feel empathy and concern for a person who is suffering, and to act meaningfully on these feelings. Benefits of practicing compassion include increased happiness and overall well-being, stronger social connection and deeper relationship with others, as well as creating a buffer against stress."
              ]
            }
          ]
        }
      ];
      break;
    case "realLife":
      pillarName = "Daily Life";
      color = "#E91E63";
      order = 4;
      description =
        "The DAILY LIFE component monitors the complexity of your daily responsibilities, habits, and challenges. We are interested in the sense of meaning and purpose you derive from your daily activities and how your daily routine supports your optimal function.";
      moreInfo = [
        {
          subContent: [
            {
              title: "Fitness",
              content: [
                "Estimates your level of cardiorespiratory fitness. The relation between physical exercise and cognitive health is being more widely studied now. Physical exercise helps to increase brain blood flow, particularly when combined with cognitive training. Sustained cardiovascular fitness has also been shown to facilitate neuroplasticity, improve neuronal connectivity, enhance memory and decrease stress."
              ]
            },
            {
              title: "Activities",
              content: [
                "Measures your reason for doing something – a goal, a force that drives you forward. Remaining aware of one's purpose is a powerful tool to maintain an engaged, vibrant brain and avoid burnout."
              ]
            },
            {
              title: "Outlook",
              content: [
                "Measures your frame of mind that informs how you feel about and respond to challenges and situations. Your mindset sets the stage for how you approach a problem that needs to be solved. Recent advances in cognitive neuroscience have revealed that our brains are highly changeable and adaptive throughout our lives. With a GROWTH mindset, you believe in your ability to be empowered and can take advantage of your brain’s neuroplasticity to push your brain’s health and performance forward. With a FIXED mindset, you believe that your brain's capacity is unchanging and that you are not an active participant in your brain's performance; this allows your brain to stall and stagnate.          "
              ]
            },
            {
              title: "Sleep",
              content: [
                "An element of daily life that measures your reported quality of sleep, a natural and recurring state of rest. Putting healthy sleep habits into practice can increase the brain’s ability to recharge, reset, and refresh. Scientists have recently begun to uncover its importance for brain health, including:  - clearing out toxins and waste products that have accumulated in our brain over the course of the day    - memory consolidation, or the strengthening of the neural connections that form our memories so we are better able to process information and recall it later     - bolstering the brain’s plasticity and adaptability    - helping us process our emotions."
              ]
            }
          ]
        }
      ];
      break;
    case "wellBeing":
      pillarName = "Well-Being";
      color = "#009688";
      order = 3;
      description =
        "The WELL-BEING component taps into your emotional sense of yourself. We will ask you how you feel about the quality of your life, your level of happiness, your levels of stress and sadness, and get a gauge of your emotional resilience.";
      moreInfo = [
        {
          subContent: [
            {
              title: "Mood",
              content: [
                "An element of well-being that tracks your general state of mind – either positive or negative. Moods are different than (but inherently linked to) emotions, which are short-lived, potentially intense feelings with a known cause. The relationship between brain and mood is being actively researched. Experiencing a bad mood can act like a deadweight on our cognitive resources, particularly those modulated by frontal networks. Conversely, a good mood can energize our cognitive resources. Neurotransmitters (brain chemicals) play a key role, and other factors such as genetics, situational events, medications and sleep can play a part in improving or diminishing our mood. New evidence suggests you can alter your ‘neuropharmacy’ affecting mood by actions and thinking patterns."
              ]
            },
            {
              title: "Resilience",
              content: [
                `Measures your capacity to prepare for, adapt in the face of, and recover from stress, challenges or adversity. Resilience relates to our ability to down-regulate when the "fight or flight" amygdala gets activated and allows us to bring our "thinking brain", i.e., prefrontal cortex, back online. The more we practice healthy coping and stress management, the stronger the connections will be in our brains between the prefrontal cortex and the amygdala.`
              ]
            },
            {
              title: "Satisfaction",
              content: [
                "Measures your level of satisfaction across six domains: - material and physical well-being   - relationships with other people   - social, community and civic activities    - personal development and fulfillment   - recreation    - independence, ability to do for yourself."
              ]
            },
            {
              title: "Happiness",
              content: [
                "An element of well-being that measures your level of general contentment, regardless of your context."
              ]
            }
          ]
        }
      ];
      break;
  }

  return {
    color,
    description,
    order,
    pillarName,
    value,
    weight,
    assessments,
    moreInfo
  };
};
