import React from "react";
import { Grid, Hidden } from "@material-ui/core";

import { ReactComponent as DoNotDisturb } from "assets/order-confirmed.svg";
import { ReactComponent as Help } from "assets/help.svg";
import { StyledText, HelpContainer } from "../../../styled";

import { Tooltip } from "components/simple";

const Begin: React.FC = () => {
  return (
    <>
      <Grid item>
        <DoNotDisturb />
      </Grid>
      <Grid item>
        <StyledText>
          Please enable <b>Do Not Disturb</b> to limit interruptions during the
          test.
        </StyledText>
        <Hidden lgUp>
          <HelpContainer>
            <Tooltip
              anchorVertical={"bottom"}
              transformVertical={"top"}
              content={[
                `Android users: Swipe down from the top of your screen. Then tap Do Not Disturb.`,
                `iPhone users: Swipe up from the bottom of your screen (on iPhone X and later

              swipe down from the upper-right). Then tap Do Not Disturb.`
              ]}
            >
              <Help />
            </Tooltip>
          </HelpContainer>
        </Hidden>
        <Hidden mdDown>
          <Tooltip
            anchorVertical={"bottom"}
            transformVertical={"top"}
            maxWidth={"600px"}
            content={[
              `Android users: Swipe down from the top of your screen. Then tap Do Not Disturb.`,
              `iPhone users: Swipe up from the bottom of your screen (on iPhone X and later

        swipe down from the upper-right). Then tap Do Not Disturb.`
            ]}
          >
            <Help />
          </Tooltip>
        </Hidden>
      </Grid>
    </>
  );
};

export default Begin;
