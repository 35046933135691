import React from "react";
import styled from "styled-components";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

import { color, fontSize, fontFamily } from "style/constants";

interface Props {
  tag?: string;
  font?: "primary";
  fontSize?: fontSize;
  fontColor?: color;
  letterSpacing?: string;
  lineHeight?: string;
  weight?: number;
}

type HeadingProps = TypographyProps & Props;

const Heading = styled(
  ({
    font,
    fontSize,
    fontColor,
    letterSpacing,
    lineHeight,
    weight,
    tag,
    ...other
  }) => <Typography {...other} component={tag} />
)`
  && {
    color: ${props => (props.fontColor ? props.fontColor : color.BLACK)};
    font-family: ${props =>
      props.font === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-size: ${props => (props.fontSize ? props.fontSize : fontSize.large)};
    font-style: normal;
    font-weight: ${props => (props.weight ? props.weight : "normal")};
    letter-spacing: ${props =>
      props.letterSpacing ? props.letterSpacing : "normal"};
    ${props => props.lineHeight && `line-height: ${props.lineHeight};`}
  }
` as React.FC<HeadingProps>;

export default Heading;
