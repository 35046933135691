import styled from "styled-components";
import { color, fontFamily, fontSize } from "style/constants";

interface Props {
  color?: color;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  fontWeightStrong?: string;
  letterSpacing?: string;
  lineHeight?: string;
  margin?: string;
  margintop?: string;
  marginright?: string;
  marginbottom?: string;
  marginleft?: string;
  overflow?: string;
  padding?: string;
  textAlign?: string;
  textOverflow?: string;
  whiteSpace?: string;
  width?: string;
  weight?: string;
  margins?: string;
}

export const StyledP = styled("p")<Props>`
  color: ${props => props.color || color.DARKERGRAY};
  font-family: ${props => props.fontFamily || fontFamily.primary};
  font-size: ${props => props.fontSize || fontSize.normal};
  font-weight: ${props => props.fontWeight || 400};
  letter-spacing: ${props => props.letterSpacing || "normal"};
  line-height: ${props => props.lineHeight || "22px"};
  margin: ${props =>
    props.margin ||
    (props.margintop || 0) +
      " " +
      (props.marginleft || 0) +
      " " +
      (props.marginbottom || 0) +
      " " +
      (props.marginright || 0)};
  overflow: ${props => props.overflow || "visible"};
  padding: ${props => props.padding || "0px"};
  text-align: ${props => props.textAlign || "left"};
  text-overflow: ${props => props.textOverflow || "clip"};
  white-space: ${props => props.whiteSpace || "normal"};
  width: ${props => props.width || "auto"};
  ${props => (props.weight ? "font-weight:" + props.weight : "")}
  ${props => (props.margins ? "margin:" + props.margins : "")}
  strong {
    color: ${props => props.color || color.BLACK};
    font-weight: ${props => props.fontWeightStrong || 600};
  }
`;

export default StyledP;
