import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";

import rankCurve from "assets/rank-curve.svg";
import rankCurveWhite from "assets/rank-curve-white.svg";
import { BackButton, Loading, StyledGrid } from "components/simple";
import {
  appWidth,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { StyledCard } from "../DashboardV2/styled";
import { GetRanks, GetUserCurrentRank } from "graphql/userRanks/userRanks.gql";
import RankItem from "./RankItem";
import { CurrentRankData, Rank, RanksData } from "graphql/types/UserRanks";

const Ranks: React.FC = () => {
  const [ranksList, setRanksList] = useState<Rank[]>([]);
  const [currentRank, setCurrentRank] = useState<Rank>();

  const { loading: currentRankLoading } = useQuery<CurrentRankData>(
    GetUserCurrentRank,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: data => setCurrentRank(data?.currentUserRank?.rank)
    }
  );

  const { loading: ranksLoading } = useQuery<RanksData>(GetRanks, {
    onCompleted: data => setRanksList(data?.ranks)
  });

  const threshold =
    ranksList.findIndex(
      item =>
        item.rank === currentRank?.rank && item.level === currentRank?.level
    ) || 0;

  if (currentRankLoading || ranksLoading) return <Loading />;

  return (
    <StyledGrid container justifyContent="center">
      <BackButton location="/profile" />
      <StyledGrid
        alignSelf="center"
        container
        marginTop={spacingDefaults.large}
        maxWidth={appWidth.max}
        padding={spacingDefaults.medium}
      >
        <StyledCard
          borderTop={`${spacingDefaults.xsmall} solid ${color.DARKBLUE}`}
          centerContent
          width="100%"
        >
          <StyledGrid container direction="column">
            <div
              style={{
                backgroundColor: color.RANK_YELLOW,
                padding: `${spacingDefaults.large} 0`
              }}
            >
              <Box fontFamily={fontFamily.secondary} fontSize={fontSize.header}>
                Ranks
              </Box>
              <Box
                fontSize={fontSize.small}
                style={{
                  padding: `0 ${spacingDefaults.normal}`,
                  margin: "auto",
                  maxWidth: "500px"
                }}
              >
                Each time you get your BrainHealth Index you move up a rank!
              </Box>
              {currentRank && (
                <div style={{ margin: `${spacingDefaults.large} 0` }}>
                  <Box fontSize={fontSize.medium}>Current Rank:</Box>
                  <img
                    src={currentRank.rankImageKey}
                    height="100px"
                    style={{ margin: `${spacingDefaults.normal} 0` }}
                  />
                  <Box fontStyle="italic">{currentRank?.level}</Box>
                </div>
              )}
            </div>
            {ranksList.map((item: Rank, idx: number) => {
              if (idx % 2 == 0)
                return (
                  <div
                    key={idx}
                    style={{
                      backgroundColor:
                        idx === 0
                          ? color.RANK_YELLOW
                          : idx % 4 === 0
                          ? color.WHITE
                          : color.BLUEGRAY,
                      backgroundImage: `url(${
                        idx % 4 === 0 ? rankCurve : rankCurveWhite
                      })`,
                      backgroundSize: "cover",
                      padding: spacingDefaults.normal
                    }}
                  >
                    <Box fontSize={fontSize.medium}>{item.rank}</Box>
                    <StyledGrid
                      container
                      justifyContent="space-evenly"
                      key={idx}
                      minHeight="150px"
                      maxWidth="600px"
                      margin="auto"
                    >
                      {/* Level 1 Ranks */}
                      <RankItem
                        hasCurrentRank={!!currentRank}
                        index={idx}
                        item={item}
                        threshold={threshold}
                      />
                      {/* Level 2 Ranks */}
                      <RankItem
                        hasCurrentRank={!!currentRank}
                        index={idx + 1}
                        item={ranksList[idx + 1]}
                        threshold={threshold}
                      />
                    </StyledGrid>
                  </div>
                );
            })}
          </StyledGrid>
        </StyledCard>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Ranks;
