import React from "react";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";

import { Button, StyledGrid } from "components/simple";
import { color, spacingDefaults } from "style/constants";

interface Props {
  content: string | string[];
  children: JSX.Element;
  maxWidth?: string;
  anchorVertical?: "top" | "center" | "bottom";
  anchorHorizontal?: "center" | "left" | "right";
  transformVertical?: "top" | "center" | "bottom";
  transformHorizontal?: "center" | "left" | "right";
}

const Tooltip: React.FC<Props> = ({
  content,
  children,
  maxWidth,
  anchorVertical,
  anchorHorizontal,
  transformVertical,
  transformHorizontal
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button plain compressed onClick={handleOpen}>
        {children}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorVertical || "top",
          horizontal: anchorHorizontal || "center"
        }}
        transformOrigin={{
          vertical: transformVertical || "bottom",
          horizontal: transformHorizontal || "center"
        }}
      >
        <StyledGrid
          padding={spacingDefaults.xlarge}
          maxWidth={maxWidth || "250px"}
          color={color.DARKERGRAY}
        >
          <StyledGrid
            item
            position={"absolute"}
            style={{ top: "0px", right: "0px" }}
          >
            <Button plain onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </Button>
          </StyledGrid>
          {typeof content === "string"
            ? content
            : content.map((text, index) => <p key={index}>{text}</p>)}
        </StyledGrid>
      </Popover>
    </div>
  );
};

export default Tooltip;
