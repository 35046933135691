import { color, fontFamily } from "style/constants";

export default {
  inputMultiline: {
    fontFamily: fontFamily.primary,
    fontWeight: 300,
    "&::placeholder": {
      color: color.DARKERGRAY,
      opacity: 1
    }
  },
  multiline: {
    border: `1px solid ${color.PALEGRAY}`,
    borderRadius: "8px",
    color: color.DARKGRAY,
    fontSize: "18px",
    lineHeight: "22px",
    padding: "26px 23.5px",
    "&:hover": {
      borderColor: color.BLUE
    }
  },
  notchedOutline: {
    border: "none"
  }
};
