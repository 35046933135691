import React, { useState, Dispatch, SetStateAction } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { StyledGrid } from "components/simple";
import { breakpoints, color } from "style/constants";
import {
  RefreshRounded,
  Rotate90DegreesCcw,
  TextRotateUp
} from "@material-ui/icons";
import { transform } from "lodash";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

// New Training component with flash card
// This component is currently set for demo purpose only which can be extended to fully functional component.
interface flashCardProps {
  //these can be passed in whereever we use the flash card
  question?: string;
  answer?: string;
  flashCardInitial?: boolean;
  setFlashCardInitial?: Dispatch<SetStateAction<boolean>>;
}

const FlashCard: React.FC<flashCardProps> = ({
  question,
  answer,
  flashCardInitial,
  setFlashCardInitial
}: flashCardProps) => {
  const [flipped, setFlipped] = useState(false);
  const item = question ? question : "Item";
  const definiton = answer ? answer : "Definition";

  const mobile = useMediaQuery(`(max-width:${breakpoints.md}px)`);

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      borderRadius="8px"
      container
      width="50%"
      minWidth={mobile ? "300px" : "600px"}
      maxWidth="700px"
      margin="4rem auto"
      centerContent
      justifyContent="center"
      transition="200ms"
      transformStyle="preserve-3d"
      transform={
        flashCardInitial
          ? "perspective(1000px) rotateY(0deg)"
          : "perspective(1000px) rotateY(180deg)"
      }
    >
      {
        //( //front
        <Box
          sx={{
            bgcolor: color.WHITE,
            width: mobile ? 350 : "17.7rem",
            border: "1px solid #DFDFDF",
            marginTop: "10px",
            marginBottom: "20px",
            boxShadow: "0px 4px 10px 0px gray",
            height: "30.5rem",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            flexShrink: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
          onClick={() =>
            setFlashCardInitial && setFlashCardInitial(!flashCardInitial)
          } //clicking anywhere in the card will flip
        >
          <div
            style={{
              transform: `${!flashCardInitial ? "rotateY(180deg)" : ""}`,
              flex: "1 0 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              fontSize: "1rem",
              wordBreak: "break-word"
            }}
          >
            <h1
              style={{
                fontSize: "1.375rem",
                fontWeight: 400,
                width: "14.8125rem",
                textAlign: "center",
                lineHeight: "2.0625rem"
              }}
            >
              {flashCardInitial ? item : definiton}
            </h1>
            <StyledGrid
              marginBottom="3%"
              marginLeft="-120px"
              bottom="0"
              position="absolute"
            >
              <RefreshRounded />
            </StyledGrid>
            <h4
              style={{
                bottom: 0,
                position: "absolute",
                fontSize: "0.875rem",
                lineHeight: "1.32rem"
              }}
            >
              Tap card to flip
            </h4>
          </div>
        </Box>
      }
    </StyledGrid>
  );
};

export default FlashCard;
