import { Box, useMediaQuery } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SlideItem } from "./slideItem";
import { StyledGrid } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { SlideData } from "graphql/types/Contentful";
import { breakpoints } from "style/constants";
interface SlideProps {
  items: SlideData[];
  setIsLastSlideInLesson: Dispatch<SetStateAction<boolean>>;
  setHasVideoEnded: Dispatch<SetStateAction<boolean>>;
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  title?: string;
  tabText?: string;
}

export const Slides: React.FC<SlideProps> = ({
  items,
  setIsLastSlideInLesson,
  setHasVideoEnded,
  setHasVideo,
  title,
  tabText
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  useEffect(() => {
    setSlideIndex(0);
    setIsLastSlideInLesson(false);
    setHasVideoEnded(false);
    setHasVideo(false);
  }, []);

  const nextSlide = () => {
    if (slideIndex === items.length - 2) {
      setDisableNext(true);
      setIsLastSlideInLesson(true);
    }
    if (slideIndex < items.length - 1) {
      setSlideIndex(slideIndex + 1);
      disablePrev && setDisablePrev(false);
    }
  };

  const prevSlide = () => {
    if (slideIndex === 1) {
      setDisablePrev(true);
    }
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
      disableNext && setDisableNext(false);
      setIsLastSlideInLesson(false);
    }
    if (slideIndex == items.length) {
      setIsLastSlideInLesson(true);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        //height: "-50vh",
        width: mobile ? "100vw" : "40vw",
        margin: `{spacingDefaults.normal}`
      }}
    >
      <StyledGrid className="slides">
        <div
          className="slides-wrapper"
          style={{ transform: `translate(-${slideIndex * 100}%)` }}
        >
          {items &&
            items.map((item: any, index: number) => (
              <SlideItem
                key={index}
                item={item}
                width="100%"
                setHasVideoEnded={setHasVideoEnded}
                setHasVideo={setHasVideo}
                title={title}
                tabText={tabText}
              />
            ))}
        </div>
        <div className="slides-controls">
          <div className="arrow-indicators">
            <button
              onClick={prevSlide}
              style={{
                border: "none",
                background: "none",
                cursor: disablePrev ? "default" : "pointer"
              }}
              disabled={disablePrev}
            >
              <span className="material-symbols-outlined">
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  style={{
                    height: "2em",
                    color: disablePrev ? "#CDEBFA" : "#037DBA"
                  }}
                />
              </span>{" "}
            </button>
          </div>
          <p>
            {slideIndex + 1} of {items.length}
          </p>
          <div className="arrow-indicators">
            <button
              onClick={nextSlide}
              style={{
                border: "none",
                background: "none",
                cursor: disableNext ? "default" : "pointer"
              }}
              disabled={disableNext}
            >
              <span className="material-symbols-outlined">
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  style={{
                    height: "2em",
                    color: disableNext ? "#CDEBFA" : "#037DBA"
                  }}
                />
              </span>
            </button>
          </div>
        </div>
      </StyledGrid>
    </Box>
  );
};

export default Slides;
