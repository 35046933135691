import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";

import { StyledGrid } from "components/simple";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";
import { CycleSummaryQuery } from "graphql/user/user.gql";
import { spacingDefaults } from "style/constants";
import { CoachingSessionStatus, Cycle } from "../CycleSummary/types";
import UpcomingSessions from "../CycleSummary/UpcomingSessions";
import { GetGroupSessions } from "graphql/groupCoaching/groupCoaching.gql";
import PastSessions from "../CycleSummary/PastSessions";
import ScheduleSessions from "../CycleSummary/ScheduleSessions";
import {
  GetIsEligibleForCoachingSession,
  GetIsEligibleForImagingSession
} from "graphql/sessions/sessions.gql";

interface SessionProps {
  selectedCycleIndex: number;
  setSelectedCycleIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Sessions: React.FC<SessionProps> = ({
  selectedCycleIndex,
  setSelectedCycleIndex
}: SessionProps) => {
  const [cycles, setCycles] = useState<Cycle[]>([]);
  const [isEligibleForCoaching, setIsEligibleForCoaching] = useState<boolean>(
    false
  );
  const [isEligibleForImaging, setIsEligibleForImaging] = useState<boolean>(
    false
  );

  const { loading, data, error } = useQuery(CycleSummaryQuery, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  });

  const { data: groupSession } = useQuery(GetGroupSessions, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  });

  useQuery(GetIsEligibleForCoachingSession, {
    onCompleted: data =>
      setIsEligibleForCoaching(data.isEligibleForCoachingSession)
  });

  useQuery(GetIsEligibleForImagingSession, {
    onCompleted: data =>
      setIsEligibleForImaging(data.isEligibleForImagingSession)
  });

  useEffect(() => {
    if (data) {
      const allCycles = data.participantAssessmentCycles.sort(
        (a: Cycle, b: Cycle) => a.cycleNumber - b.cycleNumber
      );

      const finalizedCycles = allCycles
        .filter(
          (x: Cycle) => x.status === UserAssessmentCycleStatus.SCORE_FINALIZED
        )
        ?.sort((a: Cycle, b: Cycle) => a.cycleNumber - b.cycleNumber);

      if (finalizedCycles.length > 0) {
        setCycles(finalizedCycles);
        setSelectedCycleIndex(finalizedCycles.length - 1);
      } else {
        setCycles(allCycles);
        setSelectedCycleIndex(allCycles.length - 1);
      }
    }
  }, [data]);

  const selectedCycle = cycles[selectedCycleIndex];

  if (loading || error || !selectedCycle) {
    return null;
  }

  const currentDoneCoachingSessions = selectedCycle.coachingSessions
    .filter(
      x =>
        x.status !== CoachingSessionStatus.SCHEDULED &&
        x.status !== CoachingSessionStatus.CANCEL
    )
    .sort(
      (a, b) =>
        new Date(b.sessionTime).getTime() - new Date(a.sessionTime).getTime()
    );
  const anyScheduledCoachingSessions = cycles.flatMap(cycle =>
    cycle.coachingSessions.filter(
      session => session.status === CoachingSessionStatus.SCHEDULED
    )
  );

  const scheduledImagingSessions = selectedCycle.imagingSessions
    .filter(x => x.status === CoachingSessionStatus.SCHEDULED)
    .sort(
      (a, b) =>
        new Date(b.sessionTime).getTime() - new Date(a.sessionTime).getTime()
    );

  const currentDoneImagingSessions = selectedCycle.imagingSessions
    .filter(
      x =>
        x.status !== CoachingSessionStatus.SCHEDULED &&
        x.status !== CoachingSessionStatus.CANCEL
    )
    .sort(
      (a, b) =>
        new Date(b.sessionTime).getTime() - new Date(a.sessionTime).getTime()
    );

  const scheduledGroupCoachingSessions = groupSession?.groupCoachingSessions
    ?.filter(
      (x: { status: CoachingSessionStatus }) =>
        x.status === CoachingSessionStatus.SCHEDULED
    )
    .sort(
      (a: any, b: any) =>
        new Date(b.sessionTime).getTime() - new Date(a.sessionTime).getTime()
    );

  return (
    <>
      {(isEligibleForCoaching || isEligibleForImaging) && (
        <StyledGrid item xs={12} marginBottom={spacingDefaults.medium}>
          <ScheduleSessions
            allowCoachScheduling={isEligibleForCoaching}
            allowImageScheduling={isEligibleForImaging}
          />
        </StyledGrid>
      )}

      {(anyScheduledCoachingSessions.length > 0 ||
        scheduledImagingSessions.length > 0 ||
        scheduledGroupCoachingSessions.length > 0) && (
        <StyledGrid item xs={12} marginBottom={spacingDefaults.medium}>
          <UpcomingSessions
            groupSessionList={scheduledGroupCoachingSessions}
            imagingSessionList={scheduledImagingSessions}
            coachingSessionList={anyScheduledCoachingSessions}
          />
        </StyledGrid>
      )}
      {(currentDoneImagingSessions.length > 0 ||
        currentDoneCoachingSessions.length > 0) && (
        <StyledGrid item xs={12} marginBottom={spacingDefaults.medium}>
          <PastSessions
            imagingSessions={currentDoneImagingSessions}
            coachingSessions={currentDoneCoachingSessions}
          />
        </StyledGrid>
      )}
    </>
  );
};

export default Sessions;
