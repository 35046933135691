import React from "react";

import { StyledGrid } from "components/simple";
import { FactorInfo, NewCycle, PillarName } from "../types";
import Overview from "./Overview";
import { BHIChartType } from "components/simple/BHIChart";

interface BrainHealthIndexProps {
  currentCycleIndex: number;
  cycles: NewCycle[];
  selectedFactor?: PillarName;
  factorInfo: FactorInfo[];
  stepIndex: number;
  chartType: BHIChartType;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setChartType: React.Dispatch<React.SetStateAction<BHIChartType>>;
  notes: string;
  notesDate: string;
}

const BrainHealthIndex: React.FC<BrainHealthIndexProps> = ({
  currentCycleIndex,
  cycles,
  selectedFactor,
  factorInfo,
  stepIndex,
  chartType,
  setStepIndex,
  setChartType,
  notes,
  notesDate
}) => {
  return (
    <StyledGrid container item justifyContent="center">
      <Overview
        cycles={cycles}
        selectedCycleIndex={currentCycleIndex}
        selectedFactor={selectedFactor}
        factorInfo={factorInfo}
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        chartType={chartType}
        setChartType={setChartType}
        notes={notes}
        notesDate={notesDate}
      />
    </StyledGrid>
  );
};

export default BrainHealthIndex;
