import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { StyledGrid } from "components/simple";
import HabitCarouselFooter, { RecommendedHabit } from "./HabitCarouselFooter";
import HabitCard from "../../HabitCard";
import { UserHabit } from "models/userHabits";
import { toLowerDashCase } from "components/pages/Contentful/utils";

interface RecommendedHabitsProps {
  recommendedHabits: UserHabit[];
  showHabitSurvey: boolean;
}

const RecommendedHabits: React.FC<RecommendedHabitsProps> = ({
  recommendedHabits,
  showHabitSurvey
}) => {
  const history = useHistory();
  const [currentHabit, setCurrentHabit] = useState<RecommendedHabit>({
    ...recommendedHabits[0],
    index: 0
  });

  const habitsList = recommendedHabits
    .filter(x => x.habit)
    .map((item: UserHabit, idx: number) => {
      return {
        ...item,
        index: idx
      };
    }) as RecommendedHabit[];

  return (
    <StyledGrid container>
      {currentHabit && currentHabit.habit && (
        <>
          <HabitCard
            habit={currentHabit.habit}
            userHabit={currentHabit}
            onClick={() =>
              history.push(
                `/track-your-habits/${toLowerDashCase(
                  currentHabit.habit?.name
                )}`,
                { habit: currentHabit, showHabitSurvey }
              )
            }
          />
          <HabitCarouselFooter
            habits={habitsList}
            currentHabit={currentHabit}
            setCurrentHabit={setCurrentHabit}
          />
        </>
      )}
    </StyledGrid>
  );
};
export default RecommendedHabits;
