import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import track from "react-tracking";
import { differenceInYears } from "date-fns";

import { StyledGrid } from "components/simple";
import { GetBadges, Me } from "graphql/user/user.gql";
import { breakpoints, spacingDefaults, color, appWidth } from "style/constants";
import routesConfig from "utils/routesConfig";
import ProfileActions from "./Actions";
import ProfileDetails from "./Details";
import Feedback from "./Feedback";
import ProfileSummary from "./Summary";
import ReferralBanner from "./ReferralBanner";
import ProfileTiles from "./Tiles";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { UserData } from "models/user";
import { PillarScores } from "../Index/CycleSummary/types";
import { UserBadge } from "models/badge";

const url = routesConfig.profile.path;

const Profile: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [isRefactorEnabled, setIsRefactorEnabled] = useState<boolean>(false);
  const [isRanksEnabled, setIsRanksEnabled] = useState<boolean>(false);
  const [profileStatistics, setProfileStatistics] = useState([
    { label: "Index Score", value: 0 },
    { label: "Active Years", value: 0 },
    { label: "Badges Earned", value: 0 }
  ]);
  const [badges, setBadges] = useState<UserBadge[]>();

  const { data, loading, refetch } = useQuery<UserData>(Me);

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Refactor" } },
    onCompleted: data => setIsRefactorEnabled(data?.isFeatureEnabled.enabled)
  });

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Ranks" } },
    onCompleted: data => setIsRanksEnabled(data?.isFeatureEnabled.enabled)
  });

  useQuery(GetBadges, {
    onCompleted: data => setBadges(data?.me?.badges)
  });

  const currentBadge = badges?.sort(
    (x: UserBadge, y: UserBadge) =>
      new Date(y.achievedAt).getTime() - new Date(x.achievedAt).getTime()
  )[0];

  useEffect(() => {
    if (data && isRefactorEnabled != undefined) {
      let indexPillar = "index";
      let scores = data.me.currentCyclePillarScores;

      if (isRefactorEnabled) {
        indexPillar = "newIndex";
        scores = data.me.currentCycleBhiRefactorPillarScores;
      }

      if (scores && scores?.length > 0) {
        const indexScore = scores
          .find((p: PillarScores) => p.pillarName === indexPillar)
          ?.value.toFixed(1);
        const updatedStatistics = profileStatistics.map((item, idx) => {
          if (idx === 0) return { ...item, value: Number(indexScore || 0) };
          else return item;
        });
        setProfileStatistics(updatedStatistics);
      }
    }
  }, [data, isRefactorEnabled]);

  if (loading || !data?.me) return <></>;

  const isPartnerLinked = !!data?.me?.spouseId;
  const partnerId = data?.me?.spouseId;

  profileStatistics[1].value = differenceInYears(
    new Date(),
    new Date(data.me.createdAt)
  );

  if (data.me.badges && data.me.badges?.length > 0) {
    profileStatistics[2].value = data.me.badges.length;
  }

  const profileFields = {
    firstName: data.me.firstName,
    lastName: data.me.lastName,
    nickname: data.me.nickname,
    email: data.me.email,
    phone: data.me.phone,
    dob: data.me.dob?.replace("Z", "")
  };

  return (
    <StyledGrid
      alignSelf="center"
      container
      maxWidth={appWidth.max}
      padding={`0 ${spacingDefaults.normal}`}
    >
      <StyledGrid
        item
        xs={12}
        marginTop={mobile ? spacingDefaults.xxlarge : spacingDefaults.large}
      >
        <ReferralBanner
          referralLink={data.me.referralLink}
          totalReferrals={data.me.totalReferrals}
        />
      </StyledGrid>
      <StyledGrid
        item
        marginTop={mobile ? spacingDefaults.xxlarge : spacingDefaults.large}
        xs={12}
      >
        <ProfileSummary
          name={`${data.me.firstName} ${data.me.lastName}`}
          statistics={profileStatistics}
        />
      </StyledGrid>
      <StyledGrid item marginTop={spacingDefaults.normal} xs={12}>
        <ProfileTiles
          isRefactorEnabled={isRefactorEnabled}
          isRanksEnabled={isRanksEnabled}
          currentUserBadge={currentBadge}
        />
      </StyledGrid>
      <StyledGrid
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        item
        marginTop={spacingDefaults.normal}
        xs={12}
      >
        <ProfileDetails data={profileFields} refetchData={refetch} />
        <ProfileActions
          isPartnerLinked={isPartnerLinked}
          refetchMe={refetch}
          partnerId={partnerId}
        />
      </StyledGrid>

      <StyledGrid
        container
        item
        justifyContent="center"
        marginTop={spacingDefaults.normal}
        xs={12}
      >
        <Feedback />
      </StyledGrid>
    </StyledGrid>
  );
};

export default track({
  url
})(Profile);
