import React, { useState } from "react";
import { Hidden, useMediaQuery } from "@material-ui/core";

import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
import playButton from "assets/consent-video-play-button.png";
import { Button, StyledGrid, MarkdownContent } from "components/simple";
import { breakpoints, color, iconSizes } from "style/constants";
import {
  PlayButton,
  TimeText,
  Title,
  Video,
  AssessmentTitle,
  Body
} from "./styled";
import consentVideoThumbnail from "assets/consent-video-thumbnail.png";

interface Props {
  onAction(): void;
  actionLabel: string;
  title?: string;
  assessmentTitle?: string;
  body?: string;
  thumbnail?: string;
  url: string;
  timeLabel?: string;
}
const ConsentVideo: React.FC<Props> = ({
  title,
  assessmentTitle,
  body,
  url,
  thumbnail,
  timeLabel,
  actionLabel,
  onAction
}: Props) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <StyledGrid
      centerContent
      container
      direction="column"
      fillRemainingHeight
      wrap="nowrap"
      backgroundColor={color.WHITE}
    >
      <Hidden mdDown>
        <StyledGrid item marginBottom="2rem">
          <Title>{title}</Title>
        </StyledGrid>
      </Hidden>
      <StyledGrid
        alignItems="center"
        borderRadius={mobile ? "0" : "5px"}
        container
        direction="column"
        height={mobile ? "300px" : "470px"}
        item
        margin={mobile ? "60px auto 0 auto" : "0 auto"}
        maxWidth={mobile ? "" : "834px"}
        position="relative"
        style={{
          backgroundImage: `url(${thumbnail || consentVideoThumbnail})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "rgba(0, 0, 0, 0.8)"
        }}
      >
        {showVideo ? (
          <StyledGrid
            item
            position="relative"
            width="100%"
            paddingBottom="56.25%"
          >
            <Video
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              aria-label={title}
              src={`${url}?autoplay=1&cc_load_policy=1&color=white&rel=0`}
              title={title}
            />
          </StyledGrid>
        ) : (
          <>
            <Hidden lgUp>
              <StyledGrid item maxWidth={mobile ? "80%" : ""}>
                <Title>{title}</Title>
              </StyledGrid>
            </Hidden>
            <StyledGrid item position="absolute">
              <PlayButton
                alt="play button"
                aria-label="Play"
                onClick={() => setShowVideo(true)}
                src={playButton}
              />
            </StyledGrid>
            <StyledGrid
              backgroundColor={color.BLACK}
              borderRadius="4px"
              item
              position="absolute"
              style={{ bottom: "2%", right: "1%" }}
            >
              <TimeText>{timeLabel}</TimeText>
            </StyledGrid>
          </>
        )}
      </StyledGrid>
      <StyledGrid
        alignSelf="center"
        container
        maxWidth="574px"
        paddingLeft="2rem"
        paddingRight="2rem"
        textAlign="center"
      >
        <StyledGrid>
          <AssessmentTitle>{assessmentTitle}</AssessmentTitle>
        </StyledGrid>
        <StyledGrid item xs={12}>
          <Body>
            <MarkdownContent>{body}</MarkdownContent>
          </Body>
        </StyledGrid>
      </StyledGrid>
      <StyledGrid item marginTop={mobile ? "" : ".5rem"}>
        <Button
          icon={!mobile}
          fullWidth={mobile}
          stickyFooter={mobile}
          aria-label={actionLabel}
          plain={!mobile}
          onClick={() => onAction()}
        >
          {actionLabel}
          {!mobile && (
            <ChevronRight stroke={color.BLACK} height={iconSizes.small} />
          )}
        </Button>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ConsentVideo;
