import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import track from "react-tracking";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { getUser, getUserStatus, UserStatus } from "state";

import {
  addBackgroundColorToRoot,
  appWidth,
  color,
  removeBackgroundColorFromRoot,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";
import {
  Loading,
  StyledGrid,
  WaitlistBanner,
  WinterBreakBanner
} from "components/simple";
import SpouseModal from "components/simple/SpouseModals";
import {
  DashboardQuery,
  GetOrganizationConsentFormInfo,
  GetPartnerModalsData,
  GetStreakData,
  UpdateFirstTimeDashboard,
  UpdateFirstTimeNative
} from "graphql/user/user.gql";
import { Dashboard, TaskListType } from "graphql/types/Dashboard";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { GetUserTaskList } from "graphql/userTaskList/userTaskList.gql";
import { GetUpcomingCoachingSessions } from "graphql/sessions/sessions.gql";
import { GetGroupSessions } from "graphql/groupCoaching/groupCoaching.gql";
import { FindProductTourResponse } from "hooks/useProductTour";
import { TaskItemType } from "./TaskList/TaskItem";
import TaskList from "./TaskList";
import { CoachingSessionStatus } from "../Dashboard/CycleSummary/types";
import Header from "./Header";
import Sessions from "./Sessions";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";
import ProductTour from "../../simple/ProductTour";
import { FindProductTour } from "graphql/productTours/productTours.gql";
import { GetUserHabitCompleted } from "graphql/userHabits/userHabits.gql";
import { isFuture, isPast } from "date-fns";

const url: string = routesConfig.dashboard.path;

const DashboardV2: React.FC = () => {
  const history = useHistory();

  const { data } = useQuery(GetOrganizationConsentFormInfo, {
    fetchPolicy: "no-cache"
  });

  const user = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  const [isWelcomeModalOneOpen, setIsWelcomeModalOneOpen] = useState(false);
  const [isWelcomeModalTwoOpen, setIsWelcomeModalTwoOpen] = useState(false);
  const [isInviteSpouseModalOpen, setIsInviteSpouseModalOpen] = useState(false);
  const [welcomeTourFinished, setWelcomeTourFinished] = useState(false);

  const [isImagingEnabled, setIsImagingEnabled] = useState<boolean>(false);
  const [isPartnerLinkingEnabled, setIsPartnerLinkingEnabled] = useState<
    boolean
  >(false);

  const [partnerModalsData, setPartnerModalsData] = useState<any>();
  const [dashboardData, setDashboardData] = useState<Dashboard>();
  const [taskList, setTaskList] = useState<TaskItemType[]>([]);
  const [upcomingCoachingSessions, setUpcomingCoachingSessions] = useState<
    any[]
  >([]);
  const [
    upcomingGroupCoachingSessions,
    setUpcomingGroupCoachingSessions
  ] = useState<any[]>([]);

  const [completedHabit, setCompletedHabit] = useState<boolean>(false);
  const [updateFirstTimeNativeMutation] = useMutation(UpdateFirstTimeNative, {
    onCompleted() {
      loadTaskList();
    }
  });

  const shouldHideConsentForm = data?.me?.organization?.hideConsentForm;

  const shouldDisable = userStatus !== UserStatus.QUALIFIED_AND_CONSENTED;
  const isWaitlisted = userStatus === UserStatus.WAITLISTED;
  const optedOutTaskList = [
    {
      title: "You have opted out of the BrainHealth Project",
      subtitle: "Join the project to find out your BrainHealth Index!",
      type: shouldHideConsentForm
        ? TaskListType.optedOutHideConsentForm
        : TaskListType.optedOut,
      isCompleted: false
    }
  ];

  const [updateFirstTimeDashboardMutation] = useMutation(
    UpdateFirstTimeDashboard
  );
  const [allowTransferToBhp, setAllowTransferToBhp] = useState<boolean>(false);
  const [
    isUserInExpiredOrganization,
    setIsUserInExpiredOrganization
  ] = useState<boolean>(false);
  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Imaging" } },
    onCompleted: data => setIsImagingEnabled(data.isFeatureEnabled.enabled)
  });

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "PartnerLinking" } },
    onCompleted: data =>
      setIsPartnerLinkingEnabled(data.isFeatureEnabled.enabled)
  });

  useQuery(GetPartnerModalsData, {
    onCompleted: data => setPartnerModalsData(data?.me)
  });

  const { loading } = useQuery(DashboardQuery, {
    fetchPolicy: "no-cache",
    onCompleted: data => setDashboardData(data?.me)
  });

  const [loadTaskList, { loading: taskListLoading }] = useLazyQuery(
    GetUserTaskList,
    {
      fetchPolicy: "no-cache",
      onCompleted: data => {
        const isTransferAllowedTask =
          data?.userTaskList[0].type === "transferToBHP";
        const isTransferNotAllowedTask =
          data?.userTaskList[0].type === "NotransferToBHP";

        if (isTransferAllowedTask || isTransferNotAllowedTask) {
          setIsUserInExpiredOrganization(true);
          setTaskList(data?.userTaskList);
          if (isTransferAllowedTask) setAllowTransferToBhp(true);
        } else {
          if (shouldDisable) {
            setTaskList(optedOutTaskList);
          } else {
            setTaskList(data?.userTaskList);
          }
        }

        if (isWaitlisted) {
          setTaskList([]);
        }
      }
    }
  );

  useQuery(GetUserHabitCompleted, {
    fetchPolicy: "no-cache",
    onCompleted: data =>
      setCompletedHabit(data.allUserHabitsV2.hasCompletedHabit)
  });

  const { loading: upcomingCoachingSessionsLoading } = useQuery(
    GetUpcomingCoachingSessions,
    {
      onCompleted: data =>
        setUpcomingCoachingSessions(data?.upcomingCoachingSessionsAsParticipant)
    }
  );

  const { loading: groupSessionsLoading } = useQuery(GetGroupSessions, {
    onCompleted: data =>
      setUpcomingGroupCoachingSessions(data?.groupCoachingSessions)
  });

  const { data: userStreakData } = useQuery(GetStreakData);

  useQuery<FindProductTourResponse>(FindProductTour, {
    fetchPolicy: "cache-and-network",
    variables: { input: { name: "Welcome" } },
    onCompleted(data) {
      if (data) {
        setWelcomeTourFinished(!!data.findProductTour?.id);
      }
    }
  });

  const cycleStatus = dashboardData?.currentCycle?.userAssessmentCycle?.status;
  const upcomingGroupCoachingSession = upcomingGroupCoachingSessions?.find(
    (x: { status: CoachingSessionStatus }) =>
      x.status === CoachingSessionStatus.SCHEDULED
  );

  useEffect(() => {
    if (isPartnerLinkingEnabled && partnerModalsData && dashboardData?.id) {
      const firstTimeDashboard = partnerModalsData?.firstTimeDashboard;
      const isSpouseAlreadyConnected = partnerModalsData?.spouseId;

      if (
        firstTimeDashboard &&
        isPartnerLinkingEnabled &&
        !isSpouseAlreadyConnected
      ) {
        setIsWelcomeModalOneOpen(true);
        updateFirstTimeDashboardHelper(dashboardData?.id);
      } else if (firstTimeDashboard) {
        updateFirstTimeDashboardHelper(dashboardData?.id);
      }
    }
    updateNativeFirstSignInHelper(`${user?.id}`, "web");
  }, [
    isImagingEnabled,
    isPartnerLinkingEnabled,
    partnerModalsData,
    dashboardData
  ]);

  useEffect(() => {
    if (userStatus === UserStatus.QUALIFIED && !shouldHideConsentForm) {
      history.push("/consent/form");
    }
  }, [userStatus]);

  addBackgroundColorToRoot(color.WHITE);
  useEffect(() => {
    return () => {
      removeBackgroundColorFromRoot();
    };
  }, []);

  if (
    loading ||
    taskListLoading ||
    groupSessionsLoading ||
    upcomingCoachingSessionsLoading
  )
    return <Loading />;

  if (!dashboardData || !user || !taskList) return null;

  const runWelcomeTour =
    !isWelcomeModalOneOpen &&
    !isWelcomeModalTwoOpen &&
    !isInviteSpouseModalOpen;

  const runMyJourneyTour =
    userStreakData?.me.bestStreak >= 1 &&
    !isWelcomeModalOneOpen &&
    !isWelcomeModalTwoOpen &&
    !isInviteSpouseModalOpen;

  // show winter break banner starting on 12/22/23 and ending on 1/3/24.
  const isWinterBreak =
    isPast(new Date(2023, 11, 22, 16, 30)) && isFuture(new Date(2024, 0, 3, 8));

  return (
    <StyledGrid container justifyContent="center">
      <ProductTour
        productTourName="Welcome"
        run={runWelcomeTour}
        stepMetadata={[
          {
            placement: "center",
            target: "body"
          },
          {
            placement: "bottom",
            target: ".app-header"
          },
          {
            placement: "bottom",
            target: ".task-list > div:first-child"
          },
          {
            placement: "bottom",
            target: ".task-list > div:first-child button"
          }
        ]}
      />
      <ProductTour
        productTourName="My Journey Part 1"
        run={runMyJourneyTour && welcomeTourFinished}
        stepMetadata={[
          {
            disableBeacon: true,
            endTourOnClickThrough: true,
            hideFooter: true,
            hideTitleProgress: true,
            spotlightClicks: true,
            target: ".my-journey-button"
          }
        ]}
      />
      <Header />
      {/* Content */}
      <StyledGrid
        alignSelf="center"
        container
        margin={`${spacingDefaults.normal} 0`}
        maxWidth={appWidth.max}
        padding={spacingDefaults.medium}
      >
        {isWinterBreak && (
          <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
            <WinterBreakBanner />
          </StyledGrid>
        )}
        {isWaitlisted && <WaitlistBanner showButton />}
        {/* TaskList */}
        {taskList.length > 0 && (
          <StyledGrid container margin={`${spacingDefaults.large} 0 `}>
            <TaskList
              tasks={taskList}
              disabled={shouldDisable}
              hasCompletedHabit={completedHabit ? true : false}
            />
          </StyledGrid>
        )}
        {/* Coaching Sessions */}
        {(cycleStatus === UserAssessmentCycleStatus.SCORE_FINALIZED ||
          upcomingCoachingSessions ||
          upcomingGroupCoachingSession ||
          isImagingEnabled) &&
          !isUserInExpiredOrganization && (
            <StyledGrid
              container
              margin={`${spacingDefaults.large} 0 `}
              filter={shouldDisable ? "grayscale(1)" : "none"}
            >
              <Sessions />
            </StyledGrid>
          )}
      </StyledGrid>
      <SpouseModal
        isNewUser={true}
        isWelcomeModalOneOpen={isWelcomeModalOneOpen}
        setIsWelcomeModalOneOpen={setIsWelcomeModalOneOpen}
        setIsWelcomeModalTwoOpen={setIsWelcomeModalTwoOpen}
        userId={dashboardData?.id}
      />
      <SpouseModal
        isNewUser={true}
        isWelcomeModalTwoOpen={isWelcomeModalTwoOpen}
        setIsInviteSpouseModalOpen={setIsInviteSpouseModalOpen}
        setIsWelcomeModalTwoOpen={setIsWelcomeModalTwoOpen}
        userId={dashboardData?.id}
      />
      <SpouseModal
        isInviteSpouseModalOpen={isInviteSpouseModalOpen}
        isNewUser={true}
        setIsInviteSpouseModalOpen={setIsInviteSpouseModalOpen}
        userId={dashboardData?.id}
      />
    </StyledGrid>
  );
  async function updateNativeFirstSignInHelper(
    id: string,
    appVersion: string
  ): Promise<void> {
    try {
      await updateFirstTimeNativeMutation({
        variables: { id, appVersion }
      });
    } catch (error) {
      console.error("Problem updating App Version");
    }
  }
  async function updateFirstTimeDashboardHelper(id: string): Promise<void> {
    try {
      await updateFirstTimeDashboardMutation({
        variables: { id }
      });
    } catch (error) {
      console.error("Problem updating firstTimeDashboard");
    }
  }
};
export default track({
  url
})(DashboardV2);
