import { useQuery } from "@apollo/react-hooks";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";

import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import { Button, Loading, Notifier } from "components/simple";
import { GetCoachingSession } from "graphql/sessions/sessions.gql";
import { VideoCall } from "lib/twilio-video";
import { breakpoints, spacingDefaults } from "style/constants";
import { addMinutes } from "date-fns";

interface RouteProps {
  coachingSessionId: string;
}

type CoachingSession = {
  id: string;
  participant: {
    id: string;
    firstName: string;
    lastName: string;
    profileImageKey: string;
    showFirstVideoCallInfo: boolean;
  };
  sessionTime: Date;
  durationMinutes: number;
};

const CoachingCall: React.FC<RouteComponentProps<RouteProps>> = ({
  match
}: RouteComponentProps<RouteProps>) => {
  const { coachingSessionId } = match.params;

  const dispatch = useDispatch();

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [coachingSession, setCoachingSession] = useState<CoachingSession>();

  const [timeRemaining, setTimeRemaining] = useState(0);

  const { loading } = useQuery(GetCoachingSession, {
    variables: { id: coachingSessionId },

    fetchPolicy: "no-cache",

    onCompleted(data) {
      setCoachingSession(data.coachingSession);
    },

    onError() {
      dispatch({ type: "server_error", value: true });
    }
  });

  const history = useHistory();
  useEffect(() => {
    if (coachingSession) {
      const handle = setInterval(() => {
        const sessionEndTime = addMinutes(
          new Date(new Date(coachingSession.sessionTime)),
          coachingSession.durationMinutes
        );
        setTimeRemaining(
          Math.floor(new Date(sessionEndTime).getTime() / 1000) -
            Math.floor(new Date().getTime() / 1000)
        );
      }, 1000);

      return () => clearInterval(handle);
    }
  }, [coachingSession]);
  const showTimeRemaining = Math.floor(timeRemaining / 60) < 5;
  const zeroPad = Math.abs(timeRemaining % 60) < 10;
  if (loading) return <Loading />;
  if (!coachingSession) return <></>;
  const displayName = `${coachingSession.participant.firstName} ${coachingSession.participant.lastName}`;

  return (
    <Box
      padding={
        mobile
          ? `${spacingDefaults.normal} ${spacingDefaults.normal} 0 ${spacingDefaults.normal}`
          : `${spacingDefaults.large} ${spacingDefaults.large} 0 ${spacingDefaults.large}`
      }
    >
      {!mobile && (
        <Box marginBottom={spacingDefaults.large}>
          <Button onClick={() => history.push(`/dashboard`)} plain>
            <Grid container alignItems="center">
              <ChevronLeft />
              <Box marginLeft={spacingDefaults.normal} paddingTop="1px">
                Back to Dashboard
              </Box>
            </Grid>
          </Button>
        </Box>
      )}
      <Notifier
        hideNotification={() => {}}
        open={showTimeRemaining && coachingSession.durationMinutes != null}
        status="dismissed"
        title={
          "Your coaching session is scheduled to end in " +
          (Math.floor(timeRemaining / 60) +
            ":" +
            (zeroPad
              ? "0" + Math.abs(timeRemaining % 60)
              : Math.abs(timeRemaining % 60)))
        }
        forDrawer={false}
      />
      <Box height={mobile ? "auto" : "80vh"}>
        <VideoCall
          user={{
            displayName,
            profileImageUrl: coachingSession.participant.profileImageKey,
            askRecordingConsent: true,
            showFirstVideoCallInfo:
              coachingSession.participant.showFirstVideoCallInfo
          }}
        />
      </Box>
    </Box>
  );
};

export default CoachingCall;
