import { Hidden, useMediaQuery } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import track, { useTracking } from "react-tracking";
import * as Yup from "yup";

import { Button, Footer, StyledGrid } from "components/simple";
import { useApolloClient, useLazyQuery } from "@apollo/react-hooks";
import {
  CheckEmailUnique,
  Register,
  CheckOrgValid
} from "graphql/user/user.gql";
import { isMtec } from "graphql/featureFlags/featureFlags.gql";
import { RegisterFormValues } from "models/user";
import { getSubdomain, showErrorNotification } from "state";
import { breakpoints, color } from "style/constants";
import routesConfig from "utils/routesConfig";
import Form from "./Form";
import {
  AccountCallout,
  BackgroundImage,
  Hr,
  ImageOverlay,
  RegisterCallout,
  Title
} from "./styled";
import Logo from "components/simple/Logo";
import ReactPlayer from "react-player";

export const orgResult = {
  id: "",
  displayName: "".toUpperCase(),
  registrationCode: "".toUpperCase()
};

const url: string = routesConfig.register.path;

const Registration: React.FC = () => {
  const dispatch = useDispatch();
  const tracking = useTracking();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();
  const client = useApolloClient();
  const subdomain = useSelector(getSubdomain);
  const query = qs.parse(history.location.search, { ignoreQueryPrefix: true });

  const [isMtecOrganization, setIsMtecOrganization] = useState<
    boolean | undefined
  >(undefined);
  const [showVideo, setShowVideo] = useState<boolean>(
    subdomain?.name === "dod"
  );

  const organizationId =
    query["organization_id"] ?? subdomain?.defaultOrg ?? null;
  const referrer = query["referrer"];
  const referralType = query["referralType"];

  const [mtecQuery] = useLazyQuery(isMtec, {
    variables: { input: { featureName: "MTEC", organizationId } },
    onCompleted: data => {
      const mtec: boolean = data?.isMtec?.enabled ?? false;
      setIsMtecOrganization(mtec);
      setShowVideo(mtec);
    }
  });

  useEffect(() => {
    if (organizationId) {
      mtecQuery();
    } else {
      setIsMtecOrganization(false);
    }
  }, [location, organizationId]);

  function trackRegistration(): void {
    tracking.trackEvent({
      url,
      actions: ["Registration", "Complete Registration", organizationId]
    });
    return;
  }

  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    nickname: Yup.string(),
    email: Yup.string()
      .email("Invalid email")
      .test(
        "is-duplicate-email",
        "This email already exists",
        async (email): Promise<boolean> => {
          if (!email) {
            return true;
          }
          try {
            const response = await client.query({
              query: CheckEmailUnique,
              variables: { email }
            });
            return response.data.checkEmailUnique.valid;
          } catch {
            // if our backend service is down then just validate the email
            // will throw another error on submit
            return true;
          }
        }
      )
      .required(),
    phone: Yup.string().nullable(),
    smsNotification: Yup.boolean(),
    organizationId: Yup.string().test(
      "is-valid-organization",
      "This Name/ID does not match an existing organization",
      async (organizationId): Promise<boolean> => {
        if (!organizationId) {
          return true;
        }
        try {
          const response = await client.query({
            query: CheckOrgValid,
            variables: { input: organizationId }
          });
          if (response.data.checkOrgValid.id) {
            orgResult.id = response.data.checkOrgValid.id;
            orgResult.displayName = response?.data?.checkOrgValid?.displayName?.toUpperCase();
            orgResult.registrationCode = response?.data?.checkOrgValid?.registrationCode?.toUpperCase();
          }
          return response.data.checkOrgValid.id;
        } catch {
          // if our backend service is down then just validate the org
          // will throw another error on submit
          return true;
        }
      }
    ),
    ngUnitState: isMtecOrganization
      ? Yup.string().required()
      : Yup.string().nullable(),
    password: Yup.string()
      .min(8, "Must contain at least 8 characters")
      .required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Must match password")
      .required()
  });

  const submitForm = async (
    values: RegisterFormValues,
    helpers: FormikHelpers<RegisterFormValues>
  ): Promise<void> => {
    // confirm password is only needed for form validation
    // isMtec is only needed to conditionally render NG Unit State dropdown
    const { confirmPassword, isMtec, ...registerInput } = values;
    try {
      await client.mutate({
        mutation: Register,
        variables: {
          input: {
            ...registerInput,
            phone: registerInput.phone ? registerInput.phone : null,
            ngUnitState: registerInput.ngUnitState
              ? registerInput.ngUnitState
              : null,
            ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
            referrer,
            organizationId,
            referralType
          }
        }
      });
    } catch ({ graphQLErrors }) {
      helpers.setSubmitting(false);

      const errProperty = (graphQLErrors as any)[0]?.extensions?.exception
        ?.validationErrors[0]?.property;

      if (errProperty) {
        dispatch(
          showErrorNotification(
            "Uh oh, something went wrong",
            `Invalid value for ${errProperty}`
          )
        );

        if (errProperty === "phone") {
          helpers.setFieldError("phone", "Invalid phone number.");
        }
      } else {
        dispatch(showErrorNotification("", "Try refreshing the page"));
      }

      return;
    }
    trackRegistration();
    helpers.setSubmitting(false);
    history.push(routesConfig.verify.path);
    return;
  };

  return (
    <>
      {isMtecOrganization !== undefined && (
        <StyledGrid backgroundColor={color.WHITE} container minHeight="100vh">
          <Hidden mdDown>
            <BackgroundImage item lg={6}>
              {showVideo && (
                <StyledGrid
                  top="15vh"
                  position="absolute"
                  padding="3rem"
                  width="100%"
                  height="50%"
                >
                  <ReactPlayer
                    controls
                    width="100%"
                    height="100%"
                    light="https://images.ctfassets.net/n7r832fbv3mz/6wUtPwIHp5Myu78dIwj1cD/135156dbb38a2048ab2ae23140f6dbb8/MTEC_Intro_Video_Cover.png"
                    url="https://videos.ctfassets.net/n7r832fbv3mz/4z1hi5BGh6RnZGzS18TEAY/29082fa8455fd2f3342da32842835c21/Jz_Mike_MTEC2022_smaller.mp4"
                    config={{
                      file: {
                        attributes: {
                          autoplay: "autoplay"
                        }
                      }
                    }}
                  />
                </StyledGrid>
              )}
              <ImageOverlay />
              <StyledGrid
                container
                item
                justifyContent="center"
                marginTop="100px"
              >
                <Logo styleType="dark" />
              </StyledGrid>
            </BackgroundImage>
          </Hidden>
          <StyledGrid
            justifyContent="center"
            container
            direction="column"
            item
            lg={6}
            xs={12}
            margin="auto"
            maxWidth={mobile ? "70%" : "553px"}
            mobileMarginTop="86px"
            mobilePadding="0"
            padding="0 20px"
          >
            <StyledGrid item centerContent={mobile}>
              <Title>Create your account</Title>
              <RegisterCallout>
                Register now to ensure a spot or to get on the waitlist.
              </RegisterCallout>
            </StyledGrid>
            <Formik
              component={Form}
              initialValues={{
                firstName: "",
                lastName: "",
                nickname: "",
                organizationId: "",
                email: "",
                phone: "",
                smsNotification: false,
                ngUnitState: "",
                password: "",
                confirmPassword: "",
                isMtec: isMtecOrganization
              }}
              onSubmit={submitForm}
              validationSchema={RegistrationSchema}
              validateOnBlur
              validateOnMount
            />
            <Hr />
            <StyledGrid
              alignItems={mobile ? "stretch" : "center"}
              container
              item
              direction={mobile ? "column" : "row"}
            >
              <StyledGrid
                alignSelf={mobile ? "center" : "left"}
                item
                md={5}
                xs={12}
              >
                <AccountCallout>Already have an account?</AccountCallout>
              </StyledGrid>
              <StyledGrid item>
                <Link to={routesConfig.login.path}>
                  <Button fullWidth inverted>
                    Sign In
                  </Button>
                </Link>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item>
              <Footer />
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default track({
  url
})(Registration);
