import styled from "styled-components";

import { color } from "style/constants";

interface Props {
  color?: color;
}

const StyledSpan = styled.span<Props>`
  && {
    color: ${props => (props.color ? props.color : color.BLACK)};
  }
`;

export default StyledSpan;
