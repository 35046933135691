import React from "react";
import { createTheme, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

import {
  addOpacity,
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import { BHIHeader, BHILabel, BHIScore } from "./styled";
import StyledGrid from "../StyledGrid";
import {
  FactorInfo,
  PillarName
} from "components/pages/Index/CycleSummary/types";
import DropdownSelect from "../DropdownSelect";
import { overrideTheme } from "../DropdownSelect/styled";
import { getFactorColor } from "../BHIChart/radar";
import IndexCoachMark from "../IndexCoachMark";
import { BHIChartType } from "../BHIChart";

const StyledFactorHeader = styled(StyledGrid)<{
  factor?: string;
  selected?: string;
}>`
  cursor: pointer;
  transition: 0.3s;
  color: ${props => getColor(props.factor, props.selected)};
  box-shadow: ${props =>
    props.factor === props.selected
      ? "0px -3px 3px rgba(50, 50, 50, 0.75)"
      : "none"};
  &:hover {
    box-shadow: 0px -3px 3px rgba(50, 50, 50, 0.75);
  }
`;

const getColor = (factor?: string, selected?: string) => {
  if (!selected) return color.BLACK;
  else if (factor === selected) {
    if (factor === PillarName.EMERGING_FACTOR) return color.BLACK;
    else return color.DARKBLUE;
  } else return addOpacity(color.BLACK, 0.6);
};

const getPillarName = (factor: string) => {
  switch (factor) {
    case "Emotional Balance":
      return PillarName.EMOTIONAL_BALANCE;
    case "Connectedness":
      return PillarName.CONNECTEDNESS;
    case "Clarity":
      return PillarName.CLARITY;
    case "Emerging Factor":
      return PillarName.EMERGING_FACTOR;
    default:
      return "";
  }
};

interface Props {
  clarity: string;
  emotionalBalance: string;
  connectedness: string;
  index: string;
  indexDelta?: number;
  factors: FactorInfo[];
  selectedFactor?: PillarName;
  stepIndex: number;
  resetSelectedFactors: () => void;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setBhiChartType: React.Dispatch<React.SetStateAction<BHIChartType>>;
  toggleSelectedFactor: (pillarName: PillarName) => void;
}

const FactorsBanner: React.FC<Props> = ({
  clarity,
  emotionalBalance,
  connectedness,
  index,
  indexDelta,
  selectedFactor,
  factors,
  stepIndex,
  resetSelectedFactors,
  toggleSelectedFactor,
  setStepIndex,
  setBhiChartType
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const customTheme = createTheme({
    ...overrideTheme,
    overrides: {
      ...overrideTheme.overrides,
      MuiInputBase: {
        root: {
          "&:focus, &.Mui-focused": {
            outline: "none"
          }
        }
      },
      MuiNativeSelect: {
        root: {
          fontFamily: fontFamily.primary,
          fontSize: "18px",
          fontWeight: 700,
          lineHeight: "22px",
          color: selectedFactor ? getFactorColor(selectedFactor) : color.BLUE,
          padding: "1.5rem 2rem"
        },
        outlined: {
          border: "1px solid",
          borderColor: color.PALEGRAY,
          borderRadius: "8px",
          "&:focus": {
            borderRadius: "8px"
          }
        },
        select: {
          "&:hover": {
            backgroundColor: color.WHITE
          }
        }
      }
    }
  });

  const options = factors.map(factor => ({
    [factor.name]: getPillarName(factor.name)
  }));

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.WHITE}
      container
      direction="column"
      padding={!mobile ? "1rem" : ""}
    >
      <StyledGrid
        alignItems="center"
        item
        container
        maxWidth="686px"
        position="relative"
        xs={12}
        padding={
          mobile ? `${spacingDefaults.large}  ${spacingDefaults.xsmall}` : "0"
        }
      >
        {mobile ? (
          <>
            <StyledGrid item xs={12}>
              <BHIHeader>Your BrainHealth® Index</BHIHeader>
              <StyledGrid item position="absolute" top="14.5%" right="5%">
                <IndexCoachMark
                  stepIndex={stepIndex}
                  setStepIndex={setStepIndex}
                  resetSelectedFactors={resetSelectedFactors}
                  setBhiChartType={setBhiChartType}
                  toggleSelectedFactor={toggleSelectedFactor}
                />
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item xs={12}>
              <BHIHeader>
                <strong>{index}</strong>
              </BHIHeader>
            </StyledGrid>
          </>
        ) : (
          <StyledGrid item xs={12}>
            <BHIHeader>
              Your BrainHealth® Index <strong>{index}</strong>
            </BHIHeader>
            <StyledGrid item position="absolute" top="31%" right="0">
              <IndexCoachMark
                stepIndex={stepIndex}
                setStepIndex={setStepIndex}
                resetSelectedFactors={resetSelectedFactors}
                setBhiChartType={setBhiChartType}
                toggleSelectedFactor={toggleSelectedFactor}
              />
            </StyledGrid>
          </StyledGrid>
        )}
        {/* {indexDelta && (
          <StyledGrid
            item
            textAlign="end"
            xs={2}
            position={mobile ? "" : "absolute"}
            right={mobile ? "" : "0"}
          >
            <ScoreDelta>
              <Arrow className={indexDelta < 0 ? "negative" : ""} />
              <p>{Math.abs(indexDelta)}</p>
            </ScoreDelta>
          </StyledGrid>
        )} */}
        {mobile && (
          <StyledGrid item xs={12}>
            <DropdownSelect
              className="bhi-factor-dropdown"
              callback={e => toggleSelectedFactor(e)}
              customTheme={customTheme}
              options={options}
              defaultText="Overview"
              value={!selectedFactor ? "overview" : selectedFactor}
            />
          </StyledGrid>
        )}
      </StyledGrid>
      {!mobile && (
        <StyledGrid
          className="bhi-factors-banner"
          alignItems="flex-start"
          container
          item
          maxWidth="686px"
          textAlign="center"
          xs={12}
        >
          <StyledFactorHeader
            className="emotional-balance-factor"
            item
            container
            direction="column"
            xs={3}
            selected={selectedFactor}
            factor={"emotionalBalance"}
            onClick={() => toggleSelectedFactor(PillarName.EMOTIONAL_BALANCE)}
          >
            <StyledGrid
              backgroundColor={getFactorColor(PillarName.EMOTIONAL_BALANCE)}
              height="6px"
              item
            />
            <StyledGrid item>
              <BHILabel>Emotional Balance</BHILabel>
            </StyledGrid>
            <StyledGrid item>
              <BHIScore>{emotionalBalance}</BHIScore>
            </StyledGrid>
          </StyledFactorHeader>
          <StyledFactorHeader
            className="connectedness-factor"
            item
            container
            direction="column"
            xs={3}
            selected={selectedFactor}
            factor={"connectedness"}
            onClick={() => toggleSelectedFactor(PillarName.CONNECTEDNESS)}
          >
            <StyledGrid
              backgroundColor={getFactorColor(PillarName.CONNECTEDNESS)}
              height="6px"
              item
            />
            <StyledGrid item>
              <BHILabel>Connectedness</BHILabel>
            </StyledGrid>
            <StyledGrid item>
              <BHIScore>{connectedness}</BHIScore>
            </StyledGrid>
          </StyledFactorHeader>
          <StyledFactorHeader
            className="clarity-factor"
            item
            container
            direction="column"
            xs={3}
            selected={selectedFactor}
            factor={"clarity"}
            onClick={() => toggleSelectedFactor(PillarName.CLARITY)}
          >
            <StyledGrid
              backgroundColor={getFactorColor(PillarName.CLARITY)}
              height="6px"
              item
            />
            <StyledGrid item>
              <BHILabel>Clarity</BHILabel>
            </StyledGrid>
            <StyledGrid item>
              <BHIScore>{clarity}</BHIScore>
            </StyledGrid>
          </StyledFactorHeader>
          <StyledFactorHeader
            className="emerging-factor"
            item
            container
            direction="column"
            xs={3}
            selected={selectedFactor}
            factor={"emergingFactor"}
            onClick={() => toggleSelectedFactor(PillarName.EMERGING_FACTOR)}
          >
            <StyledGrid
              backgroundColor={getFactorColor(PillarName.EMERGING_FACTOR)}
              height="6px"
              item
            />
            <StyledGrid item padding="0 1rem">
              <BHILabel className="emerging">Emerging Factor</BHILabel>
            </StyledGrid>
          </StyledFactorHeader>
        </StyledGrid>
      )}
    </StyledGrid>
  );
};

export default FactorsBanner;
