import React from "react";
import { Box } from "@material-ui/core";
import { format } from "date-fns";
import { StyledGrid } from "components/simple";
import { color, fontSize } from "style/constants";
import { BrainGaugeResults } from ".";

const BrainGaugeMetric: React.FC<BrainGaugeResults> = ({
  completionDateTime,
  scores
}: BrainGaugeResults) => {
  return (
    <StyledGrid
      container
      justifyContent="space-between"
      alignItems="center"
      margin="30px 0px"
      padding="0 20px"
    >
      <StyledGrid item textAlign="left" md={1} xs={1}>
        <Box
          color={color.BLACK}
          fontSize={fontSize.normal}
          fontWeight="600"
          margin="0"
        >
          {format(new Date(completionDateTime), "MM/dd/yy")}
        </Box>
      </StyledGrid>
      {scores.map((score, idx: number) => (
        <StyledGrid item key={idx} md={1} xs={1} textAlign="center">
          <Box
            color={color.BLACK}
            fontSize={
              score?.name === "corticalmetric"
                ? fontSize.large
                : fontSize.medium
            }
            fontWeight="600"
          >
            {score?.score.toFixed()}
          </Box>
        </StyledGrid>
      ))}
    </StyledGrid>
  );
};

export default BrainGaugeMetric;
