import React from "react";
import {
  AssessmentCard,
  Button,
  QuestionPrompt,
  StyledGrid
} from "components/simple";
import { breakpoints, spacingDefaults } from "style/constants";
import { useMediaQuery } from "@material-ui/core";
import ReadyForCogB from "assets/CognitiveB/cog-b-ready.svg";

interface DigitSymbolInstructionData {
  callback: (isReady: boolean) => void;
}
export const Ready: React.FC<DigitSymbolInstructionData> = ({ callback }) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <>
      <AssessmentCard>
        <StyledGrid item marginTop="1rem" marginBottom="1rem">
          <img src={ReadyForCogB} height="300px" />
        </StyledGrid>

        <QuestionPrompt question="Are you ready to take your assessment? There will be one 3-minute trial."></QuestionPrompt>
        <StyledGrid
          justifyContent="center"
          container
          item
          direction="row"
          marginTop="1rem"
          style={{ gap: spacingDefaults.normal }}
        >
          <Button
            data-testid="next-btn"
            fullWidth={mobile}
            inverted
            onClick={() => callback(false)}
            stickyFooter={mobile}
            wide={!mobile}
            zIndex={100}
          >
            Back to Practice
          </Button>
          <Button
            data-testid="next-btn"
            wide={!mobile}
            stickyFooter={mobile}
            fullWidth={mobile}
            onClick={() => callback(true)}
            zIndex={100}
          >
            Take Assessment
          </Button>
        </StyledGrid>
      </AssessmentCard>
    </>
  );
};
