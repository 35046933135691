import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import Logo from "assets/brain-hq-logo.png";
import { StyledGrid } from "components/simple";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const PageOne: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      padding={spacingDefaults.xlarge}
    >
      <img alt="BrainHQ Logo" height={185} src={Logo} width={225} />

      <Box
        fontFamily={fontFamily.secondary}
        fontSize={mobile ? fontSize.large : fontSize.xlarge}
        letterSpacing="-0.45px"
        margin={`${spacingDefaults.large} 0`}
        textAlign="center"
      >
        What is BrainHQ?
      </Box>

      <Box
        fontSize={mobile ? fontSize.small : fontSize.normal}
        lineHeight="180%"
        maxWidth="620px"
        textAlign="center"
      >
        The BrainHQ brain-training program represents the culmination of 30
        years of research in neurological science and related medicine. BrainHQ
        has online exercises that work out specific thinking skills, such as
        attention, speed, memory, navigation, and intelligence. Using a special
        algorithm, each exercise adapts in difficulty as you work so that you
        always train at the optimum level for you—where you are most likely to
        improve your performance. Research findings suggest that consistently
        engaging in such trainings may positively impact your mood, memory, and
        attention – to name a few.
        <br />
        <br />
        <a
          href="https://www.brainhq.com/?v4=true&fr=y"
          rel="noopener noreferrer"
          target="_blank"
        >
          For more information about BrainHQ, click here.
        </a>
      </Box>
    </StyledGrid>
  );
};

export default PageOne;
