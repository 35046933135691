import React from "react";

import { ReactComponent as BannerMobile } from "assets/challenge-banner-mobile.svg";
import {
  StyledGrid,
  StyledP,
  WheelIcons,
  WidgetTitle
} from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import { StyledButton } from "../../styled";
import { CompletionStatus, DetailedCourseProgress } from "../../types";
import { useTlmsLink } from "../../utils";

interface CourseBannerProps {
  courseProgress: DetailedCourseProgress;
  disabled?: boolean;
}

const MobileCourseBanner: React.FC<CourseBannerProps> = ({
  courseProgress,
  disabled = false
}: CourseBannerProps) => {
  const { connectToTlms } = useTlmsLink();

  const courseNamewithCode = courseProgress?.name || "";
  const courseCode = courseProgress?.code;
  const courseDescription = courseProgress?.description;
  const courseName = courseNamewithCode.replace(`(${courseCode})`, "");
  const courseNotStarted =
    courseProgress.id === "160" &&
    courseProgress.completionStatus === CompletionStatus.NotAttempted;

  return (
    <StyledGrid container>
      <StyledGrid item xs={12}>
        <WidgetTitle>
          {courseNotStarted
            ? "Are You Ready to Begin Your Training?"
            : "Are You Ready to Jump Back to Your Training?"}
        </WidgetTitle>
      </StyledGrid>

      <StyledGrid
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        centerContent
        container
        height="225px"
        item
        marginTop={spacingDefaults.normal}
        overflow="hidden"
        padding="0 40px"
        position="relative"
        xs={12}
      >
        <StyledGrid item left="0" position="absolute" top="0">
          <BannerMobile height={275} />
        </StyledGrid>

        <StyledGrid item xs={12}>
          <StyledP
            color={color.BLACK}
            fontSize={courseDescription ? fontSize.medium : fontSize.large}
            letterSpacing="-0.9px"
            lineHeight="28px"
          >
            <strong>{courseName}</strong>
          </StyledP>
        </StyledGrid>

        {courseDescription && (
          <StyledGrid item marginTop={spacingDefaults.normal} xs={12}>
            <StyledP
              color={color.BLACK}
              fontSize="14px"
              fontWeight="300"
              lineHeight="24px"
            >
              {courseDescription}
            </StyledP>
          </StyledGrid>
        )}

        <StyledGrid
          item
          position="absolute"
          top={spacingDefaults.xsmall}
          right={spacingDefaults.small}
        >
          <WheelIcons pillars={courseProgress.pillars} />
        </StyledGrid>

        <StyledGrid
          container
          item
          justifyContent="center"
          marginTop={spacingDefaults.large}
          xs={12}
        >
          <StyledButton
            disabled={disabled}
            height="50px"
            width="225px"
            onClick={() => connectToTlms(courseProgress.id)}
          >
            {courseNotStarted ? "Begin Training" : "Keep Training"}
          </StyledButton>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default MobileCourseBanner;
