import styled from "styled-components";
import { color, fontFamily, fontSize } from "style/constants";

export const Header = styled.h1`
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.large};
  line-height: 29px;
  letter-spacing: -0.36px;
`;

export const Paragraph = styled.p`
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.small};
  white-space: pre-wrap;
`;
