import React, { useEffect } from "react";
import get from "lodash/get";
import { useTracking } from "react-tracking";
import { string, object } from "yup";

import {
  AssessmentCard,
  DigitSymbolQuestion,
  DropdownWithTextQuestion,
  DropdownQuestion,
  HourMinuteQuestion,
  ImageThemeQuestion,
  Information,
  LikertQuestion,
  MultipleChoiceQuestion,
  MultipleResponseQuestion,
  MultiResponseQuestion,
  NumericalScaleQuestion,
  PassageQuestion,
  TextQuestion,
  WordFlashQuestion
} from "components/simple";
import routesConfig from "utils/routesConfig";

interface Question {
  id: string;
  internalName: string;
  questionType: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  questionData: any;
}

interface QuestionProps {
  activeQuestion: number;
  assessmentName: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  answers: Record<any, any>[];
  question: Question;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  updateAnswers: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
  skipText?: string;
}

const url = routesConfig.assessments.assessment.path;

const Question: React.FC<QuestionProps> = ({
  activeQuestion,
  assessmentName,
  answers,
  question: { internalName, questionData, questionType },
  updateAnswers,
  skipCallback,
  skipText
}: QuestionProps) => {
  const tracking = useTracking();
  window.scrollTo(0, 0);

  useEffect(() => {
    tracking.trackEvent({
      url,
      actions: ["Assessments", "View Question", internalName]
    });
    return;
  }, [internalName, tracking]);

  switch (questionType) {
    case "dropdown_and_text": {
      let dropdownOptions;
      if (Array.isArray(questionData.dropdownAndTextChoices)) {
        dropdownOptions = questionData.dropdownAndTextChoices.map(
          (value: string) => ({
            [value]: value
          })
        );
      } else {
        dropdownOptions = Object.keys(questionData.dropdownAndTextChoices).map(
          value => {
            return { [value]: questionData.dropdownAndTextChoices[value] };
          }
        );
      }
      const answer = get(
        answers[activeQuestion],
        ["answerData", "response"],
        ""
      ) as Record<string, string>[];
      return (
        <DropdownWithTextQuestion
          answer={answer}
          callback={updateAnswers}
          options={dropdownOptions}
          question={questionData.prompt}
          subtext={questionData.subtext}
          textDefault={questionData.textDescription}
          skipCallback={skipCallback}
        />
      );
    }
    case "dropdown":
      const answer = get(
        answers[activeQuestion],
        ["answerData", "response"],
        ""
      ) as string;
      return (
        <DropdownQuestion
          answer={answer}
          choices={questionData.dropdownChoices}
          callback={updateAnswers}
          prompt={questionData.prompt}
        />
      );
    case "image_themes":
      return (
        <ImageThemeQuestion
          activeQuestion={activeQuestion}
          callback={updateAnswers}
          image={{ src: questionData.images[0], alt: "" }}
          subtext={questionData.subtext}
          time={questionData.time}
        />
      );
    case "information":
      const { image = "", text = "" } = questionData;
      const props = {
        body: text,
        buttonText: "Continue",
        callback: updateAnswers,
        imageSrc: image
      };
      return <Information props={props} />;
    case "likert_scale":
      return (
        <LikertQuestion
          answer={
            answers[activeQuestion] ? answers[activeQuestion].answerData : null
          }
          callback={updateAnswers}
          key={internalName}
          questions={questionData}
          skipCallback={skipCallback}
        />
      );
    case "flash_words":
      return (
        <WordFlashQuestion callback={updateAnswers} question={questionData} />
      );
    case "free_response":
      return (
        <TextQuestion
          answer={
            answers[activeQuestion]
              ? answers[activeQuestion].answerData.response
              : null
          }
          activeQuestion={activeQuestion}
          callback={updateAnswers}
          question={questionData.prompt}
          subtext={questionData.subtext}
          time={questionData.time}
          label="Type your response here..."
          type="long"
        />
      );
    case "multiple_choice":
      return (
        <MultipleChoiceQuestion
          answer={
            answers[activeQuestion]
              ? answers[activeQuestion].answerData.response
              : null
          }
          prompt={questionData.prompt}
          callback={updateAnswers}
          choices={questionData.choices}
          skipCallback={skipCallback}
          skipText={skipText}
        />
      );
    case "multiple_response":
      return (
        <MultipleResponseQuestion
          answer={
            answers[activeQuestion]
              ? answers[activeQuestion].answerData.response
              : null
          }
          prompt={questionData.prompt}
          callback={updateAnswers}
          choices={questionData.multiResponseChoices}
          exclusive={questionData.exclusive}
          skipCallback={skipCallback}
        />
      );
    case "proverb":
      const proverbList = get(questionData, "proverbList", []);
      const proverbPrompt =
        proverbList[Math.floor(Math.random() * proverbList.length)];
      return (
        <TextQuestion
          callback={answer => {
            const responseData = {
              prompt: proverbPrompt,
              ...answer
            };
            updateAnswers(responseData);
          }}
          question={proverbPrompt}
          subtext={questionData.subtext}
          label="Type your response here..."
          time={questionData.time}
          type="long"
        />
      );
    case "numerical_scale":
      return (
        <NumericalScaleQuestion
          answer={
            answers[activeQuestion]
              ? answers[activeQuestion].answerData.response
              : null
          }
          label={questionData.label}
          minValue={questionData.min}
          maxValue={questionData.max}
          prompt={questionData.prompt}
          callback={updateAnswers}
        />
      );
    case "passage":
      const { passages, prompt, time } = questionData;
      return (
        <PassageQuestion
          activeQuestion={activeQuestion}
          passages={passages}
          prompt={prompt}
          time={time}
          callback={updateAnswers}
        />
      );
    case "text_response": {
      const isPhone = questionData.label.toLocaleLowerCase().includes("phone");

      const isNumber = questionData.type
        ? questionData.type === "number"
        : undefined;
      const phoneRegExp = /^\+?[\d()-\s]+$/;
      const schema = object().shape({
        phone: string().required().matches(phoneRegExp)
      });
      const answer = get(
        answers[activeQuestion],
        ["answerData", "response"],
        ""
      ) as string;
      function getValidator(): any {
        if (isPhone) {
          return (phone: string) => schema.isValidSync({ phone });
        } else if (isNumber) {
          return (number: string) => parseInt(number, 10) >= 0;
        } else {
          return undefined;
        }
      }

      return (
        <TextQuestion
          additionalInfo={questionData.additionalInfo}
          answer={answer}
          callback={updateAnswers}
          question={questionData.prompt}
          label={questionData.label}
          subtext={questionData.subtext}
          skipCallback={skipCallback}
          type={isPhone ? "text" : questionData.type}
          validator={getValidator()}
          placeholder={isPhone ? "(808) 888-8888" : undefined}
          skipText={skipText}
        />
      );
    }
    case "word_callback":
      return (
        <MultiResponseQuestion
          callback={updateAnswers}
          time={questionData.time}
          question={questionData.prompt}
          activeQuestion={activeQuestion}
          subtext={questionData.subtext}
          skipCallback={skipCallback}
          hideWords={assessmentName === "Strategy"}
          oneWord={assessmentName === "Strategy"}
        />
      );

    case "digit_symbol":
      return (
        <DigitSymbolQuestion
          callback={updateAnswers}
          prompt={questionData.prompt}
          question={questionData.digitSymbols}
          isPractice={questionData.isPractice}
          inputDevice={answers[activeQuestion - 1]}
        />
      );

    case "digit_symbol":
      return (
        <DigitSymbolQuestion
          callback={updateAnswers}
          prompt={questionData.prompt}
          question={questionData.digitSymbols}
          isPractice={questionData.isPractice}
          inputDevice={answers[activeQuestion - 1]}
        />
      );

    case "hour_minute": {
      let dropdownOptions;
      if (Array.isArray(questionData.hourMinuteHourChoices)) {
        dropdownOptions = questionData.hourMinuteHourChoices.map(
          (value: string) => ({
            [value]: value + " hour(s)"
          })
        );
      } else {
        dropdownOptions = Object.keys(questionData.hourMinuteHourChoices).map(
          value => {
            return {
              [value]: questionData.hourMinuteHourChoices[value + " hour(s)"]
            };
          }
        );
      }

      let dropdownOptionsSecondary;
      if (Array.isArray(questionData.hourMinuteMinuteChoices)) {
        dropdownOptionsSecondary = questionData.hourMinuteMinuteChoices.map(
          (value: string) => ({
            [value]: value + " minute(s)"
          })
        );
      } else {
        dropdownOptionsSecondary = Object.keys(
          questionData.hourMinuteMinuteChoices
        ).map(value => {
          return {
            [value]: questionData.hourMinuteMinuteChoices[value + " minute(s)"]
          };
        });
      }
      const answer = get(
        answers[activeQuestion],
        ["answerData", "response"],
        ""
      ) as Record<string, string>[];
      return (
        <HourMinuteQuestion
          answer={answer}
          callback={updateAnswers}
          options={dropdownOptions}
          optionsSecondary={dropdownOptionsSecondary}
          question={questionData.prompt}
          subtext={questionData.subtext}
          skipCallback={skipCallback}
          label={questionData.label}
          secondaryLabel={questionData.secondaryLabel}
        />
      );
    }
    default:
      return (
        <AssessmentCard>
          <h1>Unsupported Question Type</h1>
          <div>
            <button
              onClick={() => {
                updateAnswers({});
              }}
            >
              Next
            </button>
          </div>
        </AssessmentCard>
      );
  }
};

export default Question;
