import { gql } from "apollo-boost";

export const habitFragment = gql`
  fragment habit on Habit {
    id
    contentfulHabitId
    name
    component
    mappedFactor
    content
    science
    dashboardPreview
    badgeActivity
    contentfulSectionId
  }
`;

export const userHabitFragment = gql`
  fragment userHabit on UserHabit {
    id
    userId
    habitId
    streakLevel
    streakCount
    lastCompletionDate
    totalHabitsCompleted
    habit {
      ...habit
    }
    maxStreakCount
    lostStreak
    isCompleted
    isPinned
    unlockedAt
  }
  ${habitFragment}
`;

export const GetAllUserHabits = gql`
  query allUserHabitsV2 {
    allUserHabitsV2 {
      userHabitsBasedOnTraining {
        ...userHabit
      }
      allUnlockedUserHabits {
        ...userHabit
      }
      pinnedHabits {
        ...userHabit
      }
      lockedHabits {
        ...habit
      }
      showHabitSurvey
      hasCompletedHabit
    }
  }
  ${habitFragment}
  ${userHabitFragment}
`;

export const GetHabitSurvey = gql`
  query habitSurvey {
    habitSurvey {
      id
      title
      questions {
        id
        question
        instructions
        possibleAnswers {
          id
          answer
          iconImage
        }
      }
    }
  }
`;

export const SaveHabitSurveyAnswers = gql`
  mutation saveHabitSurveyAnswers($input: SaveHabitSurveyAnswersInput!) {
    saveHabitSurveyAnswers(input: $input) {
      success
    }
  }
`;

export const TogglePinnedHabit = gql`
  mutation togglePinnedHabit($input: TogglePinnedHabitInput!) {
    togglePinnedHabit(input: $input) {
      success
    }
  }
`;

export const MarkUserHabitComplete = gql`
  mutation markUserHabitComplete($input: MarkUserHabitCompleteInput!) {
    markUserHabitComplete(input: $input) {
      success
      coinsAwarded {
        title
        coinsAwarded
        ornament
      }
    }
  }
`;

export const GetUserHabitCompleted = gql`
  query getUserHabitCompleted {
    allUserHabitsV2 {
      hasCompletedHabit
    }
  }
`;
