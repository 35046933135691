import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";

import { breakpoints, spacingDefaults } from "style/constants";
import {
  Button,
  RadioCard,
  StyledGrid,
  AssessmentCard
} from "components/simple";

interface Props {
  answer?: string;
  callback: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  choices: string[];
  prompt: string;
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
  skipText?: string;
}

const MultipleChoiceQuestion: React.FC<Props> = ({
  answer,
  callback,
  choices,
  prompt,
  skipCallback,
  skipText
}: Props) => {
  const [disabled, setDisabled] = useState(!answer);
  const [choice, setChoice] = useState<string>(answer || "");
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  useEffect(() => {
    setDisabled(!answer);
    setChoice(answer || "");
  }, [prompt]); // eslint-disable-line

  return (
    <AssessmentCard
      question={prompt}
      skipCallback={skipCallback}
      skipText={skipText}
    >
      <StyledGrid
        item
        width={mobile ? "300px" : "538px"}
        margin={"0"}
        marginTop={spacingDefaults.normal}
      >
        <RadioCard
          selections={choices}
          column
          onChange={(event, value) => {
            setChoice(value);
            setDisabled(false);
          }}
          value={choice}
        />
      </StyledGrid>
      <StyledGrid item margin={mobile ? "0" : "40px 0"}>
        <Button
          data-testid="next-btn"
          wide={!mobile}
          disabled={disabled}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={() => {
            setDisabled(true);
            callback({ response: choice });
          }}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default MultipleChoiceQuestion;
