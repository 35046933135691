import styled from "styled-components";
import { sizing } from "@material-ui/system";
import { fontFamily } from "style/constants";

export const StyledHeader = styled.p`
  && {
    ${sizing}
    font-family: ${fontFamily.secondary};
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: -0.4444444477558136px;
    text-align: center;
  }
`;

export const StyledText = styled.p`
  font-family: ${fontFamily.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
`;
