import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { Box, useMediaQuery } from "@material-ui/core";

import SuccessYellowBg from "assets/successYellowBg.svg";
import { Button, StyledGrid } from "components/simple";
import { TaskRewardImage } from "utils/TaskRewardImage";
import {
  breakpoints,
  color,
  fontSize,
  spacingDefaults,
  fontFamily
} from "style/constants";
import { awardCoinType } from "graphql/types/Contentful";
interface CoinModalProps {
  onClose?: () => void;
  openModal: boolean;
  coinsAwarded?: awardCoinType[];
}

const CoinModal: React.FC<CoinModalProps> = ({
  onClose,
  openModal,
  coinsAwarded
}: CoinModalProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const totalCoinAmount = coinsAwarded
    ?.map((x: awardCoinType) => x.coinsAwarded)
    .reduce((sum: number, current: number) => sum + current, 0);
  const title = coinsAwarded?.map((x: awardCoinType) => x.title);
  const ornament = coinsAwarded?.map((x: awardCoinType) => x.ornament);
  return (
    <Modal open={openModal}>
      <StyledGrid
        width={mobile ? "365px" : "570px"}
        absoluteCenter
        backgroundColor={color.WHITE}
      >
        <StyledGrid
          container
          direction="column"
          centerContent
          minHeight="250px"
          alignItems="center"
          backgroundImage={SuccessYellowBg}
          backgroundPosition="bottom"
        >
          <Box
            paddingTop={`${spacingDefaults.xlarge}`}
            fontWeight={400}
            fontSize={mobile ? fontSize.xsmall : fontSize.normal}
            fontFamily={fontFamily.secondary}
          >
            {title && title[0]}
          </Box>
          <Box
            fontSize={fontSize.medium}
            fontFamily={fontFamily.primary}
            marginBottom={spacingDefaults.normal}
          >
            You earned <b> {`${totalCoinAmount} coins`}</b>
          </Box>

          <Box
            color={color.WHITE}
            fontSize={mobile ? "2rem" : fontSize.xlarge}
            position="absolute"
            top={mobile ? "138px" : "135px"}
            left={mobile ? "215px" : "320px"}
          >
            {`+${totalCoinAmount}`}
          </Box>

          <Box paddingLeft={spacingDefaults.xxlarge}>
            {ornament && <TaskRewardImage ornament={ornament[0]} />}
          </Box>
        </StyledGrid>
        <StyledGrid
          container
          direction="column"
          centerContent
          alignItems="center"
          style={{
            fontSize: mobile
              ? `${spacingDefaults.normal} `
              : `${spacingDefaults.small} `
          }}
        >
          <Box
            padding={`${spacingDefaults.large} 0 `}
            fontSize={fontSize.xsmall}
            fontWeight="400"
          >
            Earn more coins by completing tasks!
          </Box>

          <Button
            width="200px"
            onClick={onClose}
            style={{
              marginBottom: mobile
                ? spacingDefaults.normal
                : spacingDefaults.xlarge,
              fontWeight: 600
            }}
          >
            Continue
          </Button>
        </StyledGrid>
      </StyledGrid>
    </Modal>
  );
};

export default CoinModal;
