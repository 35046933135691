import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { Button } from "components/simple";
import StyledGrid from "components/simple/StyledGrid";
import { Box } from "@material-ui/core";
import { spacingDefaults } from "style/constants";

import { JourneyDrawerStateProps } from "models/userStreak";
import FreeDayPassTree from "assets/journeytray-redeemed-freedaypass.svg";
import coinIcon from "assets/coin-icon.svg";
import { StyledEmptyStreakDrawerGrid } from "./styled";
import { CoinsRequiredForItem } from "graphql/coinsCatalog/coins.gql";
import { CoinsCatalogData } from "models/coins";
import UserFreeDayPassCoinsStatCard from "../UserFreeDayPassCoinsStatCard";

const EmptyJourneyDayState: React.FC<JourneyDrawerStateProps> = ({
  isToday,
  userStreak,
  hasFreeDayPass,
  canBuyScreenBreak,
  coinsTotal,
  closeDrawer,
  redeemFreeDayPass,
  purchaseScreenBreak
}) => {
  const history = useHistory();

  const [hasNoStreak, setHasNoStreak] = useState<boolean>(false);
  const [screenBreakCoins, setScreenBreakCoins] = useState<number>(0);
  const { loading: loadingScreenBreakCoinsRequired } = useQuery<
    CoinsCatalogData
  >(CoinsRequiredForItem, {
    variables: {
      input: { category: "Tree Revival", subCategory: "Screen Break" }
    },
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const coinsRequired = data?.coinsRequiredForItem?.amount ?? 0;

      setScreenBreakCoins(Math.abs(coinsRequired));
    }
  });

  useEffect(() => {
    const userHasNoStreak =
      !userStreak ||
      (!userStreak?.habitCompleted &&
        !userStreak?.trainingCompleted &&
        !userStreak?.resourceViewed &&
        !userStreak.freeDayPassApplied &&
        !userStreak.screenBreakApplied);

    setHasNoStreak(userHasNoStreak);
  }, [userStreak]);

  const canApplyFreeDayPass = hasNoStreak && !isToday && hasFreeDayPass;

  if (loadingScreenBreakCoinsRequired) return null;

  return (
    <>
      {(userStreak?.freeDayPassApplied || userStreak?.screenBreakApplied) && (
        <StyledEmptyStreakDrawerGrid
          container
          marginTop={spacingDefaults.large}
        >
          <img src={FreeDayPassTree} />
          <StyledGrid
            container
            alignItems="center"
            justifyContent="center"
            margin={spacingDefaults.medium}
            style={{ rowGap: "2rem" }}
          >
            <Box style={{ textAlign: "center" }} padding="0px">
              {userStreak.freeDayPassApplied
                ? "Free Day Pass Applied"
                : "Screen Break Redeemed"}
            </Box>
            <Box
              padding="0px"
              style={{ textAlign: "center", fontStyle: "italic" }}
            >
              {userStreak.freeDayPassApplied
                ? "Reminder: 1 Free Day Pass is available per week!"
                : "Complete tasks on your daily plan to earn coins that can be used for purchasing screen breaks!"}
            </Box>
          </StyledGrid>
          <Button
            inverted
            onClick={() => {
              history.push("/stats");
              closeDrawer();
            }}
          >
            See All Stats
          </Button>
        </StyledEmptyStreakDrawerGrid>
      )}

      {hasNoStreak && !canApplyFreeDayPass && !canBuyScreenBreak ? (
        <StyledEmptyStreakDrawerGrid container>
          <UserFreeDayPassCoinsStatCard
            hasFreeDayPass={hasFreeDayPass ?? false}
            coinsTotal={coinsTotal ?? 0}
          />
          Complete tasks on your daily plan to build your streak and earn more
          coins!
          <Button
            onClick={() => {
              history.push("/dashboard");
              closeDrawer();
            }}
          >
            Go to Daily Plan
          </Button>
        </StyledEmptyStreakDrawerGrid>
      ) : (
        <StyledEmptyStreakDrawerGrid container>
          {hasNoStreak && (
            <>
              <UserFreeDayPassCoinsStatCard
                hasFreeDayPass={hasFreeDayPass ?? false}
                coinsTotal={coinsTotal ?? 0}
              />
              <img
                src={FreeDayPassTree}
                style={{ filter: "grayscale(1)", opacity: "0.6" }}
              />
            </>
          )}
          {hasNoStreak && canApplyFreeDayPass && (
            <>
              <Box padding="0px" style={{ textAlign: "center" }}>
                No worries! It&apos;s important to take breaks. That&apos;s why
                we give you one free day pass a week that allows for a tree
                revival.
              </Box>
              <Button
                onClick={() => {
                  redeemFreeDayPass();
                }}
              >
                <StyledGrid container flexDirection="column">
                  <StyledGrid item>Use Free Day Pass</StyledGrid>
                </StyledGrid>
              </Button>
            </>
          )}
          {hasNoStreak && canBuyScreenBreak && (
            <Button
              inverted={canApplyFreeDayPass}
              onClick={() => {
                purchaseScreenBreak();
              }}
              style={{
                padding: "14px 25px"
              }}
            >
              <StyledGrid container flexDirection="row" alignItems="center">
                <img
                  src={coinIcon}
                  height="15px"
                  style={{ paddingRight: spacingDefaults.xsmall }}
                />
                <StyledGrid item>
                  Revive Tree ({screenBreakCoins} coins)
                </StyledGrid>
              </StyledGrid>
            </Button>
          )}
        </StyledEmptyStreakDrawerGrid>
      )}
    </>
  );
};

export default EmptyJourneyDayState;
