import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { breakpoints } from "style/constants";
import { AssessmentCard, Button, StyledGrid } from "components/simple";

interface StartProps {
  prompt: string;
  callback: () => void;
}

export const Start: React.FC<StartProps> = ({ prompt, callback }) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  return (
    <AssessmentCard
      question={prompt}
      subtext="You will not get feedback on your answers."
    >
      <StyledGrid item width="100%" textAlign="center">
        <Button aria-label="next-button" onClick={callback} wide={!mobile}>
          Start Assessment
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};
