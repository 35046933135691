import { gql } from "apollo-boost";

export const GetPushNotifications = gql`
  query GetPushNotifications {
    pushNotifications {
      acknowledged
      body
      createdAt
      dismissed
      id
      redirectUrl
      title
    }
  }
`;

export const MarkPushNotificationAsAcknowledged = gql`
  mutation MarkPushNotificationAsAcknowledged($input: PushNotificationsInput!) {
    markAsAcknowledged(input: $input) {
      success
    }
  }
`;

export const MarkPushNotificationAsDismissed = gql`
  mutation MarkPushNotificationAsDismissed($input: PushNotificationsInput!) {
    markAsDismissed(input: $input) {
      success
    }
  }
`;
