import styled from "styled-components";
import { breakpoints, color, fontFamily } from "style/constants";

export const Callout = styled.p`
  color: ${color.DARKERGRAY};
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const ResendText = styled(Callout)`
  text-decoration: underline;
  margin: 0;
`;

export const Spacer = styled.hr`
  border: none;
  border-bottom: none;
  margin: 0 30px;
`;

export const Title = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    font-size: 1.875rem;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 2.25rem;
  font-weight: normal;
  line-height: 2.5625rem;
  margin: 0;
`;
