import React from "react";
import { Box } from "@material-ui/core";
import { format } from "date-fns";
import { StyledGrid } from "components/simple";
import styled from "styled-components";
import { color, fontSize } from "style/constants";
import { BrainGaugeResults } from ".";

const StyledScore = styled("div")<{ dotColor: string }>`
  padding: 10px;
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background-color: ${({ dotColor }) => dotColor};
  ${({ dotColor }) => dotColor === "#E23434" && `color: white`}
`;

const getDotColor = (score: number) => {
  if (score <= 33) return "#E23434";
  if (score >= 34 && score <= 66) return "#FFCA28";
  if (score >= 67) return "#00B288";
};

const BrainGaugeMetric: React.FC<BrainGaugeResults> = ({
  completionDateTime,
  scores
}: BrainGaugeResults) => {
  return (
    <StyledGrid
      container
      justifyContent="space-between"
      alignItems="center"
      margin="30px 0px"
      padding="0 20px"
    >
      <StyledGrid item textAlign="left" md={1} xs={1}>
        <Box
          color={color.BLACK}
          fontSize={fontSize.normal}
          fontWeight="600"
          margin="0"
        >
          {format(new Date(completionDateTime), "MM/dd/yy")}
        </Box>
      </StyledGrid>
      {scores.map((score, idx: number) => (
        <StyledGrid item key={idx} md={1} xs={1} textAlign="center">
          <StyledScore dotColor={getDotColor(score?.score) || ""}>
            {score?.score.toFixed()}
          </StyledScore>
        </StyledGrid>
      ))}
    </StyledGrid>
  );
};

export default BrainGaugeMetric;
