import { useMutation } from "@apollo/react-hooks";
import { Box, IconButton, Modal, useMediaQuery } from "@material-ui/core";
import { CheckCircle, Close } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";

import { Button, StyledGrid } from "components/simple";
import { CompleteHabit, ShuffleHabit } from "graphql/user/user.gql";
import { showErrorNotification } from "state";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";
import { UserHabit } from "..";
import StreakStatusPills from "../StreakStatusPills";
import { getComponentColor, getComponentDisplayValue } from "../utils";
import ShuffleHabitConfirmation from "./ShuffleHabitConfirmation";
import { FadeIn, FadeOut } from "./styled";

interface HabitModalProps {
  show: boolean;
  onClose(): void;
  userHabit: UserHabit;
  isCompleted: boolean;
  refetch(): Promise<any>;
}

const url = routesConfig.dashboard.path;

const HabitModal: React.FC<HabitModalProps> = ({
  show,
  onClose,
  userHabit: {
    id,
    streakLevel,
    streakCount,
    maxStreakCount,
    habit: { name, component, content, science }
  },
  isCompleted,
  refetch
}: HabitModalProps) => {
  const tracking = useTracking();
  const dispatch = useDispatch();

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [showShuffleConfirmation, setShowShuffleConfirmation] = useState(false);
  const [animate, setAnimate] = useState(false);

  const [completeHabit] = useMutation(CompleteHabit, {
    fetchPolicy: "no-cache"
  });
  const [shuffleHabit] = useMutation(ShuffleHabit);

  const completedLevel = streakCount === maxStreakCount;

  return (
    <Modal open={show} onClose={_handleClose}>
      <StyledGrid
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        left="50%"
        position="absolute"
        top="50%"
        transform="translateX(-50%) translateY(-50%)"
        width={mobile ? "95%" : "750px"}
      >
        <StyledGrid
          item
          position="absolute"
          right={spacingDefaults.xsmall}
          top={spacingDefaults.xsmall}
        >
          <IconButton aria-label="close" key="close" onClick={_handleClose}>
            <Close style={{ fill: color.WHITE }} />
          </IconButton>
        </StyledGrid>

        <StyledGrid
          backgroundColor={color.BLACK}
          borderRadius="8px 8px 0 0"
          item
          padding="1rem"
          xs={12}
        >
          {completedLevel ? (
            <Box
              color={color.WHITE}
              fontFamily={fontFamily.secondary}
              fontSize={mobile ? "36px" : "64px"}
              textAlign="center"
            >
              Congratulations!
            </Box>
          ) : (
            <>
              <StyledGrid
                container
                direction="column"
                item
                marginBottom={spacingDefaults.normal}
                xs
              >
                <Box
                  color={color.WHITE}
                  fontFamily={fontFamily.secondary}
                  fontSize={mobile ? "20px" : "26px"}
                  letterSpacing="-0.5px"
                >
                  BrainHealth Habit Mastery
                </Box>

                <Box color={color.WHITE} fontWeight="700" marginBottom="2px">
                  {getComponentDisplayValue(component)}, Level {streakLevel}
                </Box>

                {!mobile && (
                  <Box color={color.WHITE} fontStyle="italic" fontWeight="400">
                    {name}
                  </Box>
                )}
              </StyledGrid>

              <StreakStatusPills
                componentColor={getComponentColor(component)}
                streakCount={streakCount}
                maxStreakCount={maxStreakCount}
                isCompleted={isCompleted}
              />
            </>
          )}
        </StyledGrid>

        <StyledGrid
          container
          item
          maxHeight="75vh"
          overflowY="scroll"
          padding={mobile ? "16px" : "32px"}
          xs={12}
        >
          <FadeOut animate={completedLevel}>
            <StyledGrid container item marginBottom="32px" xs={12}>
              <StyledGrid
                alignItems="center"
                container
                direction="column"
                item
                marginBottom={mobile ? spacingDefaults.normal : undefined}
                xs
              >
                <img
                  src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                    streakLevel - 1
                  }.png`}
                  alt={`The level ${streakLevel - 1} habit badge`}
                  width={mobile ? "200px" : "225px"}
                />

                <Box
                  color={color.DARKERGRAY}
                  fontStyle="italic"
                  fontWeight="400"
                >
                  Current Level: {streakLevel}
                </Box>
              </StyledGrid>

              <StyledGrid item xs>
                <Box
                  fontWeight="600"
                  fontSize="16px"
                  marginBottom={spacingDefaults.small}
                >
                  The Habit
                </Box>

                <Box
                  fontSize={mobile ? fontSize.small : fontSize.normal}
                  fontWeight="400"
                  lineHeight="175%"
                  marginBottom={spacingDefaults.normal}
                >
                  {content}
                </Box>

                <Box
                  color={color.DARKERGRAY}
                  fontSize={mobile ? fontSize.small : fontSize.normal}
                  fontStyle="italic"
                  fontWeight="400"
                  padding="0 35px"
                  textAlign="center"
                >
                  {`Complete ${maxStreakCount} consecutive days to reach Level ${
                    streakLevel + 1
                  }!`}
                </Box>
              </StyledGrid>
            </StyledGrid>
          </FadeOut>

          {completedLevel && (
            <FadeIn animate={animate}>
              <StyledGrid item xs={12}>
                <img
                  src={`https://bhp-images.s3.amazonaws.com/badges/habit_${streakLevel}_social_linkedin.png`}
                  alt={`The level ${streakLevel} habit badge`}
                  width="100%"
                />
              </StyledGrid>
            </FadeIn>
          )}

          <StyledGrid
            alignItems="center"
            item
            container
            direction="column"
            marginBottom={spacingDefaults.normal}
          >
            <StyledGrid item>
              <Button
                background={isCompleted ? color.GREEN : undefined}
                onClick={isCompleted ? undefined : _handleCompletion}
              >
                <CheckCircle />
                <Box marginLeft="8px">
                  {isCompleted ? "Today Completed!" : "Mark as Complete"}
                </Box>
              </Button>
            </StyledGrid>

            {!isCompleted && (
              <StyledGrid
                container
                item
                justifyContent="center"
                marginTop={spacingDefaults.xsmall}
              >
                {showShuffleConfirmation ? (
                  <ShuffleHabitConfirmation
                    onAccept={_handleShuffle}
                    setShowShuffleConfirmation={setShowShuffleConfirmation}
                    streakCount={streakCount}
                  />
                ) : (
                  <Button
                    onClick={() => setShowShuffleConfirmation(true)}
                    plain
                  >
                    <Box fontSize="14px" fontWeight="500">
                      <u>I want a different habit</u>
                    </Box>
                  </Button>
                )}
              </StyledGrid>
            )}
          </StyledGrid>

          <StyledGrid
            backgroundColor={color.ICON}
            borderRadius="8px"
            item
            padding={mobile ? "16px" : "24px"}
            xs={12}
          >
            <Box
              fontWeight="600"
              fontSize="16px"
              marginBottom={spacingDefaults.small}
            >
              The Science
            </Box>

            <Box
              fontSize={mobile ? fontSize.small : fontSize.normal}
              fontWeight="400"
              lineHeight="175%"
            >
              {science}
            </Box>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </Modal>
  );

  /* Internal */

  function _handleClose(): void {
    setAnimate(false);
    onClose();
  }

  async function _handleCompletion(): Promise<void> {
    try {
      const response = await completeHabit({
        variables: { input: { userHabitId: id } }
      });

      if (response) {
        await refetch();

        tracking.trackEvent({
          url,
          actions: ["Habits", `Complete Habit`, name]
        });

        setAnimate(true);
      } else {
        dispatch(
          showErrorNotification("", "Failed to mark habit as completed")
        );
      }
    } catch (error) {
      dispatch(showErrorNotification("", "Failed to mark habit as completed"));
    }
  }

  async function _handleShuffle(): Promise<void> {
    try {
      const response = await shuffleHabit();

      if (response) {
        tracking.trackEvent({
          url,
          actions: ["Habits", "Shuffle Habits", name]
        });

        await refetch();
      } else {
        dispatch(showErrorNotification("", "Failed to assign a new habit"));
      }
    } catch (error) {
      dispatch(showErrorNotification("", "Failed to assign a new habit"));
    }
  }
};

export default HabitModal;
