import { differenceInMinutes } from "date-fns";
import { UpcomingSession } from "./UpcomingSessions/UpcomingSessionCard";

export function canJoinVideoCall(coachingSession: UpcomingSession): boolean {
  if (!coachingSession) {
    return false;
  }

  const sessionTime = new Date(coachingSession.sessionTime);
  const currentTime = new Date();

  const under30 =
    sessionTime > currentTime &&
    differenceInMinutes(sessionTime, currentTime) < 30;

  const inProgress =
    currentTime > sessionTime &&
    differenceInMinutes(currentTime, sessionTime) < 120;

  return under30 || inProgress;
}
