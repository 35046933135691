import React from "react";
import { Box } from "@material-ui/core";
import UpArrow from "@material-ui/icons/KeyboardArrowUp";

import DashboardHeader from "assets/dashboard-header.svg";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";
import { MyJourneyProps } from "..";
import { isSameWeek, subWeeks } from "date-fns";
import { formatWeekRange } from "../utils";

const MyJourneyHeader: React.FC<MyJourneyProps> = ({
  userCreatedAt,
  periodStart,
  periodEnd,
  setPeriodStart,
  setPeriodEnd
}) => {
  const dateRangeFormatted = formatWeekRange(periodStart, periodEnd);
  const isThisWeek = isSameWeek(new Date(), periodStart, { weekStartsOn: 1 });
  const isUserStartWeek = userCreatedAt
    ? isSameWeek(periodStart, new Date(userCreatedAt), { weekStartsOn: 1 })
    : false;
  const previousWeekFormatted = formatWeekRange(
    subWeeks(periodStart, 1),
    subWeeks(periodEnd, 1)
  );

  return (
    <StyledGrid
      alignItems="center"
      backgroundImage={DashboardHeader}
      container
      direction="column"
      justifyContent="space-between"
      minHeight="200px"
      textAlign="center"
    >
      <StyledGrid item marginTop={spacingDefaults.xlarge}>
        <Box
          fontSize={fontSize.normal}
          fontFamily={fontFamily.primary}
          visibility={isUserStartWeek ? "hidden" : "visible"}
        >
          {isThisWeek ? `Last Week` : `Previous Week`}
          <br />
          {previousWeekFormatted}
        </Box>
      </StyledGrid>

      <StyledGrid item>
        {!isUserStartWeek && (
          <StyledGrid
            alignItems="center"
            backgroundColor={color.WHITE}
            borderRadius="50%"
            boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
            centerContent
            container
            height="30px"
            margin={`${spacingDefaults.xsmall} auto`}
            onClick={() => {
              setPeriodStart(subWeeks(periodStart, 1));
              setPeriodEnd(subWeeks(periodEnd, 1));
            }}
            style={{ cursor: "pointer" }}
            width="30px"
          >
            <UpArrow />
          </StyledGrid>
        )}
        <Box
          fontSize={fontSize.normal}
          fontFamily={fontFamily.primary}
          fontWeight="bold"
        >
          My Journey
        </Box>
        <Box fontSize={fontSize.normal} fontFamily={fontFamily.primary}>
          {isThisWeek
            ? `This Week | ${dateRangeFormatted}`
            : `${dateRangeFormatted}`}
        </Box>
      </StyledGrid>
    </StyledGrid>
  );
};
export default MyJourneyHeader;
