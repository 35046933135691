import React from "react";

import { Button, StyledGrid } from "components/simple";
import { Callout, ResendText, Title } from "./styled";
import { ReactComponent as EmailSent } from "assets/mail-check.svg";

interface ConfirmationProps {
  resend: React.Dispatch<any>;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  resend
}: ConfirmationProps) => {
  return (
    <StyledGrid
      container
      centerContent
      direction="column"
      item
      margin="auto"
      maxWidth="370px"
      mobileMaxWidth="80%"
    >
      <EmailSent width="100%" aria-label="letter with check mark" />
      <Title>Email Sent!</Title>
      <Callout>
        You’ll receive an email with a reset link shortly. If you don’t receive
        this email,{" "}
        <Button compressed plain onClick={resend} style={{ minHeight: "0" }}>
          <ResendText>click here</ResendText>
        </Button>
        .
      </Callout>
      <Callout>You may need to check your junk or spam folder.</Callout>
    </StyledGrid>
  );
};

export default Confirmation;
