import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as BhiImage } from "assets/bhi-tree.svg";
import { Button } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";

interface AssessmentsNotStartedProps {
  daysToComplete: number;
  disabled?: boolean;
}

const AssessmentsNotStarted: React.FC<AssessmentsNotStartedProps> = ({
  daysToComplete,
  disabled = false
}: AssessmentsNotStartedProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const daysToCompleteText = `${daysToComplete} day${
    daysToComplete > 1 ? "s" : ""
  }`;

  return (
    <>
      <Box marginBottom={spacingDefaults.medium}>
        <BhiImage height={125} width={175} />
      </Box>

      <Box
        component="h3"
        fontFamily={fontFamily.secondary}
        fontWeight="normal"
        fontSize="26px"
        letterSpacing="-0.36px"
        lineHeight="30px"
        margin={`0 0 ${spacingDefaults.normal} 0`}
        maxWidth={mobile ? "220px" : undefined}
        textAlign="center"
      >
        Get Your BrainHealth® Index
      </Box>

      <Box
        color={color.DARKERGRAY}
        component="p"
        fontSize="14px"
        lineHeight="21px"
        margin="0"
        maxWidth="330px"
        textAlign="center"
      >
        Establish a benchmark of your brain’s fitness today and track your
        progress over time.
      </Box>

      <Box marginY={spacingDefaults.medium}>
        <Link
          aria-disabled={disabled}
          onClick={disabled ? x => x.preventDefault() : undefined}
          to={routesConfig.assessments.intro.path}
        >
          <Button disabled={disabled} fullWidth>
            Start Assessment
          </Button>
        </Link>
      </Box>

      <Box
        color={color.DARKERGRAY}
        component="p"
        fontSize="14px"
        lineHeight="21px"
        margin="0"
        textAlign="center"
      >
        You have <strong>{daysToCompleteText}</strong> to finish all Assessments
      </Box>
    </>
  );
};

export default AssessmentsNotStarted;
