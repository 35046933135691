import styled from "styled-components";

import { ReactComponent as Img } from "assets/404-image.svg";
import { breakpoints, color, fontSize, fontFamily } from "style/constants";

export const Title = styled.h1`
  margin: 0;
  color: ${color.DARKBLUE};
  font-size: 88px;
  font-weight: 300;
  line-height: 100px;
`;

export const Subtitle = styled.h2`
  margin: 0;
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.header};
`;

export const Paragraph = styled.p`
  @media (max-width: ${breakpoints.lg}px) {
    max-width: 310px;
  }
  max-width: 550px;

  margin: 0;
  margin-bottom: 10px;
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.medium};
`;

export const BottomImage = styled(Img)`
  @media (max-width: ${breakpoints.lg}px) {
    bottom: 56px;
    width: auto;
    height: 230px;
  }
  bottom: 0;
  width: 100%;
  height: auto;

  left: 0;
  position: fixed;
  z-index: -1;
`;
