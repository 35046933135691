import { Grow, Hidden } from "@material-ui/core";
import React from "react";

import {
  Heading,
  Ruler,
  StyledGrid,
  StyledP,
  StyledSpan,
  PillarGraphIcon
} from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import { Pillar } from "../../types";
import { PillarValue } from "../styled";

interface BarProps {
  barColor: string;
  percentage: number;
  opacity: number;
  print?: boolean;
}

const Bar: React.FC<BarProps> = ({
  barColor,
  percentage,
  opacity,
  print
}: BarProps) => {
  return (
    <StyledGrid
      container
      width="25px"
      direction={"column-reverse"}
      zIndex={"10"}
    >
      <Grow
        in={true}
        style={{ transformOrigin: "bottom" }}
        timeout={print ? 0 : 500}
      >
        <StyledGrid
          aria-label="bar chart"
          item
          backgroundColor={barColor as color}
          borderRadius={"30px"}
          height={`${percentage}%`}
          width={"100%"}
          style={{ opacity }}
        ></StyledGrid>
      </Grow>
    </StyledGrid>
  );
};

const YaxisLines: React.FC = () => (
  <StyledGrid
    container
    position={"absolute"}
    zIndex={"1"}
    direction={"column"}
    justifyContent={"space-around"}
    style={{
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px"
    }}
  >
    <StyledGrid
      style={{ borderBottom: "solid 1px rgba(201, 201, 201, 0.31)" }}
    />
    <StyledGrid
      style={{ borderBottom: "solid 1px rgba(201, 201, 201, 0.31)" }}
    />
    <StyledGrid
      style={{ borderBottom: "solid 1px rgba(201, 201, 201, 0.31)" }}
    />
  </StyledGrid>
);

interface BarGraphProps {
  pillar: Pillar;
  isMobile: boolean;
  stepperValue: number;
  print?: boolean;
}

const BarGraph: React.FC<BarGraphProps> = ({
  pillar,
  isMobile,
  stepperValue,
  print
}: BarGraphProps) => {
  const { color: pillarColor, description, value, pillarName } = pillar;
  const assessments = pillar.assessments;

  return (
    <StyledGrid container direction="column">
      <StyledGrid item container direction="row">
        <Hidden mdDown>
          <StyledGrid item xs={3} textAlign="center">
            <PillarGraphIcon
              pillarColor={pillarColor}
              activePillar={pillarName}
            />
          </StyledGrid>
        </Hidden>
        <StyledGrid item container xs={12} lg={9}>
          <StyledGrid item container alignItems="center">
            <Hidden lgUp>
              <PillarGraphIcon
                width="50"
                height="50"
                pillarColor={pillarColor}
                activePillar={pillarName}
              />
            </Hidden>
            <Heading tag="h2" font="primary">
              Your
              <StyledSpan
                color={pillarColor as color}
              >{` ${pillarName} `}</StyledSpan>
              Results
            </Heading>
          </StyledGrid>
          <StyledGrid item>
            <StyledP color={color.BLACK}>{description}</StyledP>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>

      <Ruler margins={spacingDefaults.normal} />

      <StyledGrid container item>
        <StyledGrid
          container
          justifyContent="space-between"
          height="50%"
          alignItems="center"
          padding={spacingDefaults.normal}
        >
          <StyledGrid item textAlign="center">
            <p>Your Score Details</p>
          </StyledGrid>

          <StyledGrid item textAlign="center">
            <StyledGrid
              container
              justifyContent={"space-around"}
              alignItems={"center"}
              border={`1px solid ${color.MUTEDGRAY}`}
              borderRadius="8px"
              padding="2rem"
              margin="1rem"
              mobileMarginLeft="0px"
              boxShadow={print ? "" : "0px 2px 17px rgba(0, 0, 0, 0.05);"}
            >
              <span>Combined Score :</span>
              <PillarValue style={{ color: pillarColor }}>
                {value.toFixed(1)}
              </PillarValue>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
        <StyledGrid container height={"200px"}>
          <StyledGrid
            container
            justifyContent={"space-around"}
            height={"100%"}
            position={"relative"}
          >
            {assessments.map((a, index) => {
              const min = 10;
              const max = 100;
              // the score will be at least 10 but no more than 100
              const score = Math.min(Math.max(a.score, min), max);
              const opacity = isMobile && index !== stepperValue ? 0.35 : 1;
              return (
                <Bar
                  key={index}
                  print
                  barColor={pillarColor}
                  percentage={score}
                  opacity={opacity}
                />
              );
            })}
            {print ? null : <YaxisLines />}
          </StyledGrid>
        </StyledGrid>
        <StyledGrid
          container
          justifyContent={"space-around"}
          paddingTop={"1rem"}
        >
          {assessments.map((a, index) => (
            <StyledGrid key={index} item textAlign="center" xs>
              <p
                style={{
                  fontSize: isMobile ? fontSize.xsmall : fontSize.normal
                }}
              >
                {a.name}
              </p>
            </StyledGrid>
          ))}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default BarGraph;
