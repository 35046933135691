import { gql } from "apollo-boost";

export const Register = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      id
    }
  }
`;

export const Login = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
    }
  }
`;

export const LoginWithToken = gql`
  mutation LoginWithToken($token: String!) {
    loginWithToken(token: $token) {
      token
    }
  }
`;

export const ConfirmRegistration = gql`
  mutation ConfirmRegistration($token: String!) {
    confirmRegistration(token: $token) {
      token
    }
  }
`;

export const ForgotPassword = gql`
  mutation ForgotPassword($email: String!) {
    sendForgotPasswordEmail(email: $email) {
      success
    }
  }
`;

export const ResetPassword = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      token
    }
  }
`;

export const SendUpdateEmailAddressEmail = gql`
  mutation SendUpdateEmailAddressEmail($input: SendUpdateEmailAddressInput!) {
    sendUpdateEmailAddressEmail(input: $input) {
      success
    }
  }
`;

export const UpdateEmailAddress = gql`
  mutation UpdateEmailAddress($token: String!) {
    updateEmailAddress(token: $token) {
      token
      newEmail
    }
  }
`;

export const Me = gql`
  query Me {
    me {
      id
      ianaTimeZoneName
      createdAt
      firstName
      lastName
      nickname
      email
      role
      phone
      dob
      eligibleForStudy
      enrollmentConsent
      profileImageKey
      waitListed
      firstTimeDashboard
      referralLink
      totalReferrals
      completedTeaser
      currentCyclePillarScores {
        pillarName
        value
      }
      currentCycleBhiRefactorPillarScores {
        pillarName
        value
      }
      assessments {
        completed
      }
      organization {
        id
        name
        displayName
        registrationCode
      }
      badges {
        badge {
          badgeImageKey
          description
          displayName
          name
        }
        achievedAt
      }
      spouseId
    }
  }
`;

export const UserLastLogin = gql`
  query Me {
    me {
      id
      lastLogin
    }
  }
`;

export const GetPartnerModalsData = gql`
  query Me {
    me {
      firstTimeDashboard
      organization {
        id
        name
      }
      spouseId
    }
  }
`;

export const GetOrganizationName = gql`
  query Me {
    me {
      organization {
        name
      }
    }
  }
`;

export const GetOrganizationScreenerInfo = gql`
  query Me {
    me {
      organization {
        hideScreener
      }
    }
  }
`;

export const GetOrganizationConsentFormInfo = gql`
  query Me {
    me {
      organization {
        hideConsentForm
        hidePostConsentQuestions
      }
    }
  }
`;

export const GetOrganizationTeaserInfo = gql`
  query Me {
    me {
      organization {
        requiredTeaser
      }
    }
  }
`;

export const GetPartnerID = gql`
  query Me {
    me {
      spouseId
    }
  }
`;

export const GetSumoBaseUrl = gql`
  query User {
    me {
      id
      sumo {
        sumoBaseUrl
      }
    }
  }
`;

export const GetBadges = gql`
  query Badges {
    me {
      id
      badges {
        achievedAt
        badge {
          badgeImageKey
          description
          displayName
          name
          backgroundColor
          userHabit {
            streakCount
            maxStreakCount
          }
        }
      }
      referralLink
    }
  }
`;

export const GetUserTimezone = gql`
  query User {
    me {
      id
      ianaTimeZoneName
    }
  }
`;

export const GetSalesforceLeadId = gql`
  query User {
    me {
      id
      salesforceLeadId
    }
  }
`;

export const GetDietIDAccessCode = gql`
  query User {
    me {
      id
      dietidAccessCode
    }
  }
`;

export const GetUserInExpiredOrgStatus = gql`
  query Me {
    me {
      id
      isInExpiredOrg
    }
  }
`;

export const GetPartner = gql`
  query GetPartner($id: ID!) {
    user(id: $id) {
      firstName
      lastName
      nickname
    }
  }
`;

export const GetUserFreeDayPass = gql`
  query User {
    me {
      hasFreeDayPass
      totalCoins
    }
  }
`;

export const GetCreatedAtDate = gql`
  query Me {
    me {
      createdAt
    }
  }
`;

export const GetUserBrainGaugeAccessCode = gql`
  query Me {
    me {
      id
      brainGaugeAccessCode
    }
  }
`;

export const RedeemFreeDayPass = gql`
  mutation RedeemFreeDayPass($input: UserStreaksFreeDayPassInput!) {
    redeemFreeDayPass(input: $input) {
      id
      userId
      date
      createdAt
      habitCompleted
      resourceViewed
      trainingCompleted
      freeDayPassApplied
      screenBreakApplied
    }
  }
`;

export const RedeemScreenBreak = gql`
  mutation RedeemScreenBreak($input: UserStreaksFreeDayPassInput!) {
    redeemScreenBreak(input: $input) {
      id
      userId
      date
      createdAt
      habitCompleted
      resourceViewed
      trainingCompleted
      freeDayPassApplied
      screenBreakApplied
    }
  }
`;

export const RedeemAdditionalTraininng = gql`
  mutation RedeemAdditionalTraininng {
    redeemAdditionalTraininng {
      id
      userId
      date
      createdAt
      habitCompleted
      resourceViewed
      trainingCompleted
      freeDayPassApplied
      screenBreakApplied
    }
  }
`;

export const CheckEmailUnique = gql`
  query CheckEmailUnique($email: String!) {
    checkEmailUnique(email: $email) {
      valid
    }
  }
`;

export const CheckOrgValid = gql`
  query CheckOrgValid($input: String!) {
    checkOrgValid(input: $input) {
      id
      displayName
    }
  }
`;

export const ConfirmScreener = gql`
  mutation ConfirmScreener {
    confirmScreener {
      qualified
      waitlisted
    }
  }
`;

export const UpdateProfile = gql`
  mutation Profile($input: ProfileInput!) {
    profile(input: $input) {
      id
    }
  }
`;

export const Consent = gql`
  mutation Consent($input: ConsentInput!) {
    consent(input: $input) {
      success
    }
  }
`;

export const OptOut = gql`
  mutation OptOut {
    optOut {
      success
    }
  }
`;

export const DashboardQuery = gql`
  query Dashboard {
    me {
      id
      currentSurveyId
      currentSurveyName
      assessmentsDaysLeft
      assessmentsLeft
      assessmentsReset
      showNotificationPreferenceBanner
      createdAt
      brainGaugeAccessCode
      currentCycle {
        cycle {
          cycleNumber
        }
        userAssessmentCycle {
          status
          daysToComplete
          aceCompleted
          userAssessments {
            status
            assessment {
              timeToComplete
              assessmentGrouping {
                name
                order
                assessmentPart {
                  name
                  order
                }
              }
            }
          }
        }
        userSurveyCycle {
          status
          daysToComplete
          userSurveys {
            id
            completionDate
            survey {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const CycleSummaryQuery = gql`
  query CycleSummary {
    participantAssessmentCycles {
      id
      status
      cycleNumber
      cycleCompletionDate
      aceCompleted
      notes
      pillarScores {
        pillarName
        value
        userAssessments {
          name
          scores {
            scoreName
            scoreType
            value
          }
        }
      }
      coachingSessions {
        id
        coach {
          firstName
        }
        status
        acuityConfirmationNumber
        sessionTime
        updatedAt
        participantNotes
        reflectionSurvey {
          completionDate
          dismissalDate
          answers {
            answerData
          }
        }
      }
      imagingSessions {
        id
        status
        acuityConfirmationNumber
        sessionTime
        updatedAt
        participantNotes
      }
    }
  }
`;

export const DismissAssessmentsReset = gql`
  mutation DismissAssessmentsReset($id: String!) {
    dismissAssessmentsReset(id: $id) {
      success
    }
  }
`;

export const GetCurrentUserHabit = gql`
  query GetCurrentUserHabit {
    currentUserHabit {
      id
      totalHabitsCompleted
      maxStreakCount
      streakCount
      streakLevel
      lostStreak
      lastCompletionDate
      habit {
        name
        dashboardPreview
        component
        content
        science
      }
    }
  }
`;

export const GetUserBrainGaugeData = gql`
  query retrieveBrainGaugeDataForUser($input: BrainGaugeUserInput!) {
    retrieveBrainGaugeDataForUser(input: $input) {
      accessCode
      userLocation
      siteCoordinator {
        name
        phone
        email
        locations
      }
      results {
        completionDateTime
        scores {
          name
          score
        }
      }
    }
  }
`;

export const GetSubHeaderData = gql`
  query Me {
    me {
      id
      totalCoins
      currentStreak
    }
  }
`;

export const GetStreakData = gql`
  query Me {
    me {
      id
      createdAt
      currentStreak
      bestStreak
    }
  }
`;

export const CompleteHabit = gql`
  mutation MarkUserHabitComplete($input: MarkUserHabitCompleteInput!) {
    markUserHabitComplete(input: $input) {
      success
    }
  }
`;

export const ShuffleHabit = gql`
  mutation ShuffleUserHabit {
    shuffleUserHabit {
      id
    }
  }
`;

export const PostConsentQuestionQuery = gql`
  query Me {
    me {
      postConsentQuestions {
        order
        title
        description
        answers
        answerIndex
      }
    }
  }
`;

export const GetConsentForm = gql`
  query Me {
    me {
      organization {
        consentForm
        consentFormId
      }
    }
  }
`;

export const TerminateUserConsentCadence = gql`
  mutation TerminateUserConsentCadence {
    terminateUserConsentCadence {
      success
    }
  }
`;

export const TransferToBhpOnExpiry = gql`
  mutation TransferToBhpOnExpiry {
    transferToBhpOnExpiry {
      success
    }
  }
`;

export const DismissNotificationPreferencesBanner = gql`
  mutation DismissNotificationPreferencesBanner($id: String!) {
    dismissNotificationPreferencesBanner(id: $id) {
      success
    }
  }
`;

export const SendSpouseReferralEmail = gql`
  mutation sendSpouseReferralEmail($input: SpouseReferralInput!) {
    sendSpouseReferralEmail(input: $input) {
      success
    }
  }
`;

export const UpdateFirstTimeDashboard = gql`
  mutation UpdateFirstTimeDashboard($id: String!) {
    updateFirstTimeDashboard(id: $id) {
      success
    }
  }
`;

export const UpdateFirstTimeNative = gql`
  mutation UpdateFirstTimeNative($id: String!, $appVersion: String!) {
    updateFirstTimeNative(id: $id, appVersion: $appVersion) {
      success
    }
  }
`;
export const UnlinkPartner = gql`
  mutation UnlinkPartner {
    unlinkPartner {
      success
    }
  }
`;
