import { useMediaQuery } from "@material-ui/core";
import React from "react";

import calendar from "assets/calendar-check-mark.svg";
import { StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const RetakeAssessmentsBanner: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  return (
    <StyledGrid
      container
      backgroundColor={color.WHITE}
      borderRadius="8px"
      alignContent="center"
      alignItems="center"
      itemMargin={spacingDefaults.xsmall}
      padding={spacingDefaults.xsmall}
      maxHeight={mobile ? "none" : "100px"}
      justifyContent={mobile ? "center" : "flex-start"}
      textAlign={mobile ? "center" : "left"}
      marginBottom="2rem"
      mobileMarginBottom="0"
    >
      <img src={calendar} alt="" />
      <div>
        <StyledP
          fontFamily={fontFamily.secondary}
          fontSize={fontSize.large}
          letterSpacing="-0.5px"
          margin="0.5rem 0"
        >
          It’s time to retake your assessments
        </StyledP>
        <StyledP color={color.DARKERGRAY} margin={"0.5rem 0"}>
          Let’s find out how your BrainHealth Index has changed so you can
          continue to improve.
        </StyledP>
      </div>
    </StyledGrid>
  );
};

export default RetakeAssessmentsBanner;
