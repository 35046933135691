import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";

import {
  AssessmentCard,
  Button,
  DropdownSelect,
  StyledGrid,
  TextField
} from "components/simple";
import { breakpoints } from "style/constants";

interface DropdownWithTextQuestionProps {
  answer?: Record<string, string>[];
  callback: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, string>[]>>
  >;
  dropdownDefault?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options: Record<string, number | string>[];
  question: string;
  subtext?: string;
  textDefault: string;
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
}

function findKeyWithValue(
  options: Record<string, number | string>[],
  value: number
): string {
  return Object.keys(
    options.find(entry => {
      return entry[Object.keys(entry)[0]] === value;
    }) || {}
  )[0];
}

const DropdownWithTextQuestion: React.FC<DropdownWithTextQuestionProps> = ({
  answer,
  callback,
  dropdownDefault,
  options,
  question,
  subtext,
  textDefault,
  skipCallback
}: DropdownWithTextQuestionProps) => {
  const [disabled, setDisabled] = useState(true);
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [dropdownAnswer, textAnswer] = answer || [null, null];
  const dropdownValue = dropdownAnswer ? Object.values(dropdownAnswer)[0] : "";
  const textValue = textAnswer ? textAnswer["1"] : "";

  const [select, setSelect] = useState<string>(dropdownValue);
  const [text, setText] = useState<string>(textValue);

  useEffect(() => {
    if (select) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [select]);

  useEffect(() => {
    const dropdownValue = dropdownAnswer
      ? Object.values(dropdownAnswer)[0]
      : "";
    setSelect(dropdownValue);
  }, [prompt]); // eslint-disable-line

  return (
    <AssessmentCard
      question={question}
      skipCallback={skipCallback}
      subtext={subtext}
    >
      <StyledGrid
        item
        maxWidth="560px"
        marginBottom="1.5rem"
        marginTop="1.25rem"
        width="100%"
      >
        <DropdownSelect
          callback={setSelect}
          defaultText={dropdownDefault}
          options={options}
          value={select}
        />
      </StyledGrid>
      <StyledGrid item maxWidth="560px" width="100%">
        {select && (
          <TextField
            placeholder={textDefault}
            fullWidth
            multiline
            onChange={({ currentTarget }) => {
              setText(currentTarget.value);
            }}
            rows={4}
            variant={"outlined"}
            value={text}
          />
        )}
      </StyledGrid>
      <StyledGrid item marginTop="auto">
        <Button
          data-testid="next-btn"
          wide={!mobile}
          disabled={disabled}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={() => {
            setDisabled(true);
            callback({
              response: [
                { [findKeyWithValue(options, parseInt(select))]: select },
                { 1: text }
              ]
            });
          }}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default DropdownWithTextQuestion;
