import styled from "styled-components";

import { color, spacingDefaults } from "style/constants";

interface GraphWrapperProps {
  height: number;
}

export const GraphWrapper = styled("div")<GraphWrapperProps>`
  position: relative;
  overflow: hidden;
  width: 300px;
  height: ${props => 218 + Math.ceil(props.height * 1.5)}px;
`;

interface GraphSliderProps {
  totalWidth: number;
  rightPosition: number;
  score: number;
}

export const GraphSlider = styled("div")<GraphSliderProps>`
  position: absolute;
  display: flex;
  width: ${props => props.totalWidth}px;
  top: ${props => -82 + Math.ceil(props.score * 1.5)}px;
  right: ${props => props.rightPosition}px;
  transition: position, 0.5s ease;
`;

export const Axis = styled.div`
  position: absolute;
  border-top: solid 1px ${color.LIGHTERGRAY};
  bottom: 50px;
  left: 0px;
  right: 0px;
`;

interface CircleProps {
  top: number;
}

export const Circle = styled("div")<CircleProps>`
  z-index: 20;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #037dba;
  position: absolute;
  left: -7px;
  top: ${props => props.top - 3}%;
`;

interface WhiteGradientProps {
  top: number;
}

export const WhiteGradient = styled.div<WhiteGradientProps>`
  position: absolute;
  width: 100px;
  height: 250px;
  background-color: ${color.WHITE};
  left: 2px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) ${props => props.top}%,
    rgba(255, 255, 255, 0) 100%
  );
`;

const scoreTextLast = `
  color: ${color.DARKBLUE};
  font-size: ${spacingDefaults.large};
  left: -25px;
`;

const scoreTextSelected = `
  color: ${color.DARKBLUE};
  font-size: ${spacingDefaults.normal};
  left: -12px;
`;

interface BHILabelProps {
  position: number;
  last?: boolean;
  selected?: boolean;
}

export const BHILabel = styled.p<BHILabelProps>`
  position: absolute;
  margin: 0px;
  z-index: 30;
  left: -35px;
  padding-bottom: 50px;
  color: ${color.DARKGRAY};
  font-size: ${spacingDefaults.normal};
  bottom: ${props => props.position}%;
`;

interface ScoreTextProps {
  position: number;
  last?: boolean;
  selected?: boolean;
}

export const ScoreText = styled.p<ScoreTextProps>`
  position: absolute;
  margin: 0px;
  z-index: 30;
  left: -8px;
  padding-bottom: 10px;
  font-size: ${spacingDefaults.small};
  bottom: ${props => props.position}%;
  ${props => props.selected && scoreTextSelected}
  ${props => props.last && scoreTextLast}
`;

export const LabelWrapper = styled.div`
  position: absolute;
  height: 50px;
  left: -30%;
  text-align: center;
`;

export const RoundLabel = styled.p`
  font-weight: 500;
  font-style: normal;
  margin: 0px;
`;

export const TimeLabel = styled.p`
  font-weight: 300;
  font-style: italic;
  margin: 0px;
`;
