import styled from "styled-components";

import { color, fontFamily } from "style/constants";

export const Title = styled("h1")`
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
  margin: 2rem 0 1rem 0;
`;

export const Body = styled("div")`
  color: ${color.DARKERGRAY};
  font-family: ${fontFamily.primary};
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  margin: 0 0 2rem 0;
`;
