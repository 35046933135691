import {
  useLazyQuery,
  useMutation,
  useQuery as apolloQuery
} from "@apollo/react-hooks";
import { Box, useMediaQuery, Modal, IconButton } from "@material-ui/core";
import { gql } from "apollo-boost";
import React, { useEffect, useState } from "react";

import {
  BackButton,
  Button,
  Ruler,
  StyledGrid,
  StyledP
} from "components/simple";
import { useQueryCustom as useQuery } from "hooks";
import {
  appWidth,
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import ContactInformation from "./ContactInformation";
import EmailPreference from "./EmailPreference";
import NotificationTimePreference from "./NotificationTimePreference";
import {
  GetPartnerID,
  GetUserBrainGaugeAccessCode
} from "graphql/user/user.gql";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import TextPreference from "./TextPreference";
import routesConfig from "utils/routesConfig";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as CloseButton } from "assets/close.svg";
import GoogleFitPreference from "./GoogleFitPreference";
import { getUser } from "state";
import { useSelector } from "react-redux";

export const NotificationPreferencesQuery = gql`
  query NotificationPreferences {
    userNotificationPreferences {
      email
      phone
      twilioOptInRequired
      remindersEmail
      remindersSms
      trainingNotificationsEmail
      trainingNotificationsSms
      trainingNotificationsTime
      newsletterEmail
      habitNotificationsEmail
      habitNotificationsSms
      habitNotificationsTime
      partnerNotificationsEmail
      partnerNotificationsSms
      partnerNotificationsTime
      brainGaugeNotificationsEmail
      brainGaugeNotificationsSms
      brainGaugeNotificationsTime
      googleFit
      researchStudyNotificationEmail
    }
  }
`;

export const GetFitAuthUrlQuery = gql`
  query GetFitAuthUrl {
    getGoogleFitAuthUrl {
      url
    }
  }
`;

export const UpdateNotificationPreferencesMutation = gql`
  mutation UpdateNotificationPreferences(
    $input: UserNotificationPreferencesInput!
  ) {
    updateUserNotificationPreferences(input: $input) {
      success
    }
  }
`;

const NotificationPreferences: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const { data, refetch } = useQuery({
    input: NotificationPreferencesQuery
  });

  const [consentHandler, { data: getFitAuthData }] = useLazyQuery(
    GetFitAuthUrlQuery
  );

  const { data: partnerLinkingEnabledData } = apolloQuery(isFeatureEnabled, {
    variables: { input: { featureName: "PartnerLinking" } }
  });

  const { data: partnerIdData } = apolloQuery(GetPartnerID, {
    fetchPolicy: "no-cache"
  });

  const { data: brainGaugeData } = apolloQuery(GetUserBrainGaugeAccessCode, {
    fetchPolicy: "cache-and-network"
  });

  const [updateNotificationPreferencesMutation] = useMutation(
    UpdateNotificationPreferencesMutation
  );
  const [showPartnerNotifications, setShowPartnerNotifications] = useState<
    boolean
  >(false);

  const user = useSelector(getUser);
  //Activating Google Fit for 2 Test users only on Production.
  //1. aaron.tate81+76@gmail.com
  //2. sonal.jain@utdallas.edu
  //3. abhp9069@gmail.com
  const activateGoogleFit =
    user?.id == "a26cec47-2015-4924-b787-ab8a2befb447" ||
    user?.id == "fd4db427-efef-4205-b13a-d1dfeae18a3d" ||
    user?.id == "872b868e-60df-43bc-a836-814f2544af60" ||
    user?.id == "331f1254-f104-46ae-997d-1c53bdb7ea47";

  const [showModal, setShowModal] = useState<boolean>(false);
  const [googleFitUserPreference, setGoogleFitUserPreference] = useState<
    boolean
  >(false);

  const [showContinueButton, setShowContinueButton] = useState(false);
  const [
    showBrainGaugeNotifications,
    setShowBrainGaugeNotifications
  ] = useState<boolean>(false);

  const history = useHistory();

  const consentModalBodyCopy = `By consenting to participate in The BrainHealth Project, you
  are confirming that you are 18 years of age or older, that you
  understand the information provided in the consent form and
  have no further questions, and that you freely agree to
  participate in the study. If that is all true, click on the "I
  Consent to Participate" button to begin.`;

  useEffect(() => {
    if (getFitAuthData) {
      const path = `${getFitAuthData.getGoogleFitAuthUrl.url}`;
      window.location.assign(path);
    }
  }, [getFitAuthData]);

  useEffect(() => {
    if (data && partnerLinkingEnabledData && partnerIdData) {
      const isPartnerLinked = !!partnerIdData?.me?.spouseId;
      if (
        partnerLinkingEnabledData?.isFeatureEnabled?.enabled &&
        isPartnerLinked
      ) {
        setShowPartnerNotifications(true);
      }
    }
  }, [data, partnerLinkingEnabledData, partnerIdData]);

  useEffect(() => {
    if (brainGaugeData?.me?.brainGaugeAccessCode) {
      setShowBrainGaugeNotifications(true);
    }
  }, [brainGaugeData]);

  const location = useLocation<{ googleFitPreference: boolean }>();
  const googleFitPrefAfterRedirect = location?.state?.googleFitPreference;
  useEffect(() => {
    setGoogleFitUserPreference(googleFitPrefAfterRedirect);
  }, [googleFitPrefAfterRedirect]);

  useEffect(() => {
    if (
      document.referrer.includes("consent/questions") ||
      document.referrer.includes("screener/qualification")
    ) {
      setShowContinueButton(true);
    } else {
      setShowContinueButton(false);
    }
  }, [setShowContinueButton]);

  useEffect(() => {
    if (data?.userNotificationPreferences?.googleFit !== undefined) {
      setGoogleFitUserPreference(data?.userNotificationPreferences?.googleFit);
    }
  }, [data?.userNotificationPreferences]);

  if (!data?.userNotificationPreferences) {
    return null;
  }

  const notificationPreferences = data.userNotificationPreferences;

  const missingPhone = !!!notificationPreferences.phone;
  const twilioOptInRequiredMessage =
    "To opt in, text `START` to (938) 444-8117.";

  return (
    <StyledGrid container direction="column">
      <BackButton />

      <StyledGrid
        alignSelf="center"
        container
        item
        maxWidth={appWidth.max}
        padding={mobile ? spacingDefaults.normal : undefined}
      >
        <StyledGrid
          alignContent="center"
          container
          direction="column"
          item
          margin={mobile ? "1rem 0" : "3rem 0"}
          xs={12}
        >
          <Box
            component="h1"
            fontFamily={fontFamily.secondary}
            fontSize="30px"
            fontWeight="400"
            letterSpacing="-0.45px"
            lineHeight="41px"
            margin="0"
            textAlign="center"
          >
            Notification Preferences
          </Box>

          <Box
            color={color.DARKERGRAY}
            component="p"
            fontSize={fontSize.small}
            fontWeight="400"
            lineHeight="19px"
            margin={`${spacingDefaults.normal} 0 0 0`}
            maxWidth="525px"
            textAlign="center"
          >
            As part of the Project, the BrainHealth team will send periodic
            communications to participants. Please indicate how you prefer to
            receive them.
          </Box>
        </StyledGrid>

        <StyledGrid
          container
          item
          lg={8}
          paddingRight={mobile ? undefined : spacingDefaults.normal}
          xs={12}
        >
          <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
            <Box
              component="h2"
              fontSize="20px"
              fontWeight="400"
              lineHeight="24px"
              margin={`0 0 ${spacingDefaults.xsmall} 0`}
            >
              Core Reminders
            </Box>

            <Box
              component="p"
              fontSize={fontSize.small}
              fontWeight="300"
              lineHeight="150%"
              margin={`0 0 ${spacingDefaults.small} 0`}
            >
              Receive notifications about your assessments, scheduled
              appointments (coaching or fMRI), and feedback opportunities.
            </Box>

            <StyledGrid
              backgroundColor={color.WHITE}
              border={`1px solid ${color.MUTEDGRAY}`}
              borderRadius="8px"
            >
              <EmailPreference
                required={true}
                checked={notificationPreferences.remindersEmail}
                handleChange={(_, checked) =>
                  updateNotificationPreferences({
                    remindersEmail: checked
                  })
                }
              />

              <Ruler />

              <TextPreference
                checked={notificationPreferences.remindersSms}
                missingPhone={missingPhone}
                handleChange={(_, checked) =>
                  updateNotificationPreferences({
                    remindersSms: checked
                  })
                }
                disallowCheck={notificationPreferences.twilioOptInRequired}
                uncheckedMessage={twilioOptInRequiredMessage}
              />
            </StyledGrid>
          </StyledGrid>

          <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
            <Box
              component="h2"
              fontSize="20px"
              fontWeight="400"
              lineHeight="24px"
              margin={`0 0 ${spacingDefaults.xsmall} 0`}
            >
              Newsletter
            </Box>

            <Box
              component="p"
              fontSize={fontSize.small}
              fontWeight="300"
              lineHeight="150%"
              margin={`0 0 ${spacingDefaults.small} 0`}
            >
              Receive our email newsletter with events at the Center for
              BrainHealth, as well as latest research findings and currently
              recruiting studies.
            </Box>

            <StyledGrid
              backgroundColor={color.WHITE}
              border={`1px solid ${color.MUTEDGRAY}`}
              borderRadius="8px"
            >
              <EmailPreference
                checked={notificationPreferences.newsletterEmail}
                handleChange={(_, checked) =>
                  updateNotificationPreferences({ newsletterEmail: checked })
                }
              />
            </StyledGrid>
          </StyledGrid>

          <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
            <Box
              component="h2"
              fontSize="20px"
              fontWeight="400"
              lineHeight="24px"
              margin={`0 0 ${spacingDefaults.xsmall} 0`}
            >
              Research Study Notifications
            </Box>

            <Box
              component="p"
              fontSize={fontSize.small}
              fontWeight="300"
              lineHeight="150%"
              margin={`0 0 ${spacingDefaults.small} 0`}
            >
              Receive direct notifications about other research studies for
              which you may qualify.
            </Box>

            <StyledGrid
              backgroundColor={color.WHITE}
              border={`1px solid ${color.MUTEDGRAY}`}
              borderRadius="8px"
            >
              <EmailPreference
                checked={notificationPreferences.researchStudyNotificationEmail}
                handleChange={(_, checked) =>
                  updateNotificationPreferences({
                    researchStudyNotificationEmail: checked
                  })
                }
              />
            </StyledGrid>
          </StyledGrid>

          {activateGoogleFit && (
            <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
              <Box
                component="h2"
                fontSize="20px"
                fontWeight="400"
                lineHeight="24px"
                margin={`0 0 ${spacingDefaults.xsmall} 0`}
              >
                Health Trackers
              </Box>

              <Box
                component="p"
                fontSize={fontSize.small}
                fontWeight="300"
                lineHeight="150%"
                margin={`0 0 ${spacingDefaults.small} 0`}
              >
                Opt-in to sync up your BrainHealth journey with your physical
                activity like distance and steps.
              </Box>

              <StyledGrid
                backgroundColor={color.WHITE}
                border={`1px solid ${color.MUTEDGRAY}`}
                borderRadius="8px"
              >
                <GoogleFitPreference
                  checked={googleFitUserPreference}
                  handleChange={(_, checked) => {
                    if (checked) {
                      setShowModal(true);
                    } else {
                      updateNotificationPreferences({ googleFit: false });
                    }
                  }}
                />
              </StyledGrid>
            </StyledGrid>
          )}
          <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
            <Box
              component="h2"
              fontSize="20px"
              fontWeight="400"
              lineHeight="24px"
              margin={`0 0 ${spacingDefaults.xsmall} 0`}
            >
              Training Notifications
            </Box>

            <Box
              component="p"
              fontSize={fontSize.small}
              fontWeight="300"
              lineHeight="150%"
              margin={`0 0 ${spacingDefaults.small} 0`}
            >
              Receive weekly notifications about where you are in your training
              schedule, and get alerts about the challenges being posted on your
              dashboard.
            </Box>

            <StyledGrid
              backgroundColor={color.WHITE}
              border={`1px solid ${color.MUTEDGRAY}`}
              borderRadius="8px"
            >
              <EmailPreference
                checked={notificationPreferences.trainingNotificationsEmail}
                handleChange={(_, checked) =>
                  updateNotificationPreferences({
                    trainingNotificationsEmail: checked
                  })
                }
              />

              <Ruler />

              <TextPreference
                checked={notificationPreferences.trainingNotificationsSms}
                missingPhone={missingPhone}
                handleChange={(_, checked) =>
                  updateNotificationPreferences({
                    trainingNotificationsSms: checked
                  })
                }
                disallowCheck={notificationPreferences.twilioOptInRequired}
                uncheckedMessage={twilioOptInRequiredMessage}
              />

              <Ruler />

              <NotificationTimePreference
                prompt="When would you like to receive your BrainHealth Challenges?"
                value={
                  new Date(
                    `1970/01/01 ${notificationPreferences.trainingNotificationsTime}`
                  )
                }
                handleChange={value =>
                  updateNotificationPreferences({
                    trainingNotificationsTime: value
                  })
                }
              />
            </StyledGrid>
          </StyledGrid>

          <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
            <Box
              component="h2"
              fontSize="20px"
              fontWeight="400"
              lineHeight="24px"
              margin={`0 0 ${spacingDefaults.xsmall} 0`}
            >
              Habit Notifications
            </Box>

            <Box
              component="p"
              fontSize={fontSize.small}
              fontWeight="300"
              lineHeight="150%"
              margin={`0 0 ${spacingDefaults.small} 0`}
            >
              Receive a daily reminder about your current habit.
            </Box>

            <StyledGrid
              backgroundColor={color.WHITE}
              border={`1px solid ${color.MUTEDGRAY}`}
              borderRadius="8px"
            >
              <Ruler />
              <TextPreference
                checked={notificationPreferences.habitNotificationsSms}
                missingPhone={missingPhone}
                handleChange={(_, checked) =>
                  updateNotificationPreferences({
                    habitNotificationsSms: checked
                  })
                }
                disallowCheck={notificationPreferences.twilioOptInRequired}
                uncheckedMessage={twilioOptInRequiredMessage}
              />

              <Ruler />

              <NotificationTimePreference
                prompt="When would you like to receive your daily habit reminder?"
                value={
                  new Date(
                    `1970/01/01 ${notificationPreferences.habitNotificationsTime}`
                  )
                }
                handleChange={value =>
                  updateNotificationPreferences({
                    habitNotificationsTime: value
                  })
                }
              />
            </StyledGrid>
          </StyledGrid>
          {showPartnerNotifications && (
            <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
              <Box
                component="h2"
                fontSize="20px"
                fontWeight="400"
                lineHeight="24px"
                margin={`0 0 ${spacingDefaults.xsmall} 0`}
              >
                Partner Notifications
              </Box>

              <Box
                component="p"
                fontSize={fontSize.small}
                fontWeight="300"
                lineHeight="150%"
                margin={`0 0 ${spacingDefaults.small} 0`}
              >
                Receive notifications when your spouse makes an accomplishment.
              </Box>

              <StyledGrid
                backgroundColor={color.WHITE}
                border={`1px solid ${color.MUTEDGRAY}`}
                borderRadius="8px"
              >
                <EmailPreference
                  checked={notificationPreferences.partnerNotificationsEmail}
                  handleChange={(_, checked) =>
                    updateNotificationPreferences({
                      partnerNotificationsEmail: checked
                    })
                  }
                />

                <Ruler />

                <TextPreference
                  checked={notificationPreferences.partnerNotificationsSms}
                  missingPhone={missingPhone}
                  handleChange={(_, checked) =>
                    updateNotificationPreferences({
                      partnerNotificationsSms: checked
                    })
                  }
                  disallowCheck={notificationPreferences.twilioOptInRequired}
                  uncheckedMessage={twilioOptInRequiredMessage}
                />
                <Ruler />
                <NotificationTimePreference
                  prompt="When would you like to receive your notifications about your partner's progress?"
                  value={
                    new Date(
                      `1970/01/01 ${notificationPreferences.partnerNotificationsTime}`
                    )
                  }
                  handleChange={value =>
                    updateNotificationPreferences({
                      partnerNotificationsTime: value
                    })
                  }
                />
              </StyledGrid>
            </StyledGrid>
          )}
          {showBrainGaugeNotifications && (
            <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
              <Box
                component="h2"
                fontSize="20px"
                fontWeight="400"
                lineHeight="24px"
                margin={`0 0 ${spacingDefaults.xsmall} 0`}
              >
                Brain Gauge Notifications
              </Box>

              <Box
                component="p"
                fontSize={fontSize.small}
                fontWeight="300"
                lineHeight="150%"
                margin={`0 0 ${spacingDefaults.small} 0`}
              >
                Receive Brain Guage reminders each week.
              </Box>

              <StyledGrid
                backgroundColor={color.WHITE}
                border={`1px solid ${color.MUTEDGRAY}`}
                borderRadius="8px"
              >
                <EmailPreference
                  checked={notificationPreferences.brainGaugeNotificationsEmail}
                  handleChange={(_, checked) =>
                    updateNotificationPreferences({
                      brainGaugeNotificationsEmail: checked
                    })
                  }
                />

                <Ruler />

                <TextPreference
                  checked={notificationPreferences.brainGaugeNotificationsSms}
                  missingPhone={missingPhone}
                  handleChange={(_, checked) =>
                    updateNotificationPreferences({
                      brainGaugeNotificationsSms: checked
                    })
                  }
                  disallowCheck={notificationPreferences.twilioOptInRequired}
                  uncheckedMessage={twilioOptInRequiredMessage}
                />
                <Ruler />
                <NotificationTimePreference
                  prompt="When would you like to receive your Brain Gauge notifications?"
                  value={
                    new Date(
                      `1970/01/01 ${notificationPreferences.brainGaugeNotificationsTime}`
                    )
                  }
                  handleChange={value =>
                    updateNotificationPreferences({
                      brainGaugeNotificationsTime: value
                    })
                  }
                />
              </StyledGrid>
            </StyledGrid>
          )}
        </StyledGrid>

        <StyledGrid
          container
          item
          marginTop={mobile ? spacingDefaults.xlarge : undefined}
          paddingLeft={mobile ? undefined : spacingDefaults.normal}
          lg={4}
          xs={12}
        >
          <StyledGrid item xs={12}>
            <ContactInformation
              email={notificationPreferences.email}
              phone={notificationPreferences.phone}
            />
          </StyledGrid>
        </StyledGrid>
        {showContinueButton && (
          <StyledGrid item marginTop="20px" marginLeft="200px">
            <Button
              icon={!mobile}
              fullWidth={mobile}
              stickyFooter={mobile}
              onClick={() => history.push(routesConfig.dashboard.path)}
              width={mobile ? "100%" : "250px"}
            >
              Continue to Dashboard
            </Button>
          </StyledGrid>
        )}
      </StyledGrid>
      <>
        <svg>
          <text></text>
        </svg>
      </>
      <Modal open={showModal}>
        <>
          <StyledGrid
            absoluteCenter
            backgroundColor={color.WHITE}
            borderRadius="8px"
            container
            direction="column"
            maxWidth="350px"
            mobileMaxWidth="60%"
            padding={spacingDefaults.normal}
          >
            <StyledGrid item alignSelf="end" position="fixed">
              <IconButton
                aria-label="close-modal"
                onClick={() => setShowModal(false)}
              >
                <CloseButton strokeWidth="1px" height="15px" width="15px" />
              </IconButton>
            </StyledGrid>
            <StyledGrid
              container
              direction="column"
              item
              height="100%"
              width="100%"
            >
              <StyledGrid item width="100%" textAlign="center">
                <StyledP
                  textAlign="center"
                  color={color.BLACK}
                  fontSize={fontSize.large}
                  fontFamily={fontFamily.secondary}
                  fontWeight="bold"
                  margintop={spacingDefaults.xlarge}
                >
                  The BrainHealth Project would like to:
                </StyledP>
              </StyledGrid>
              <StyledGrid
                item
                width="100%"
                textAlign="center"
                padding={`${spacingDefaults.xlarge} ${spacingDefaults.medium}`}
              >
                <StyledP width="100%" color={color.BLACK} textAlign="center">
                  This will link health, physical fitness, and sleep data from
                  your Google fit device with your data in The BrainHealth
                  Project. See more in your{" "}
                  <a
                    color={color.DARKBLUE}
                    href="https://platform.thebrainhealthproject.org/consent/form?page=1"
                  >
                    consent form.
                  </a>
                </StyledP>
                <StyledP
                  width="100%"
                  color={color.GRAPHITE}
                  textAlign="center"
                  margins="20px 0px"
                >
                  {consentModalBodyCopy}
                </StyledP>
              </StyledGrid>
              <StyledGrid item width="100%" textAlign="center">
                <Button
                  aria-label="consent-button"
                  onClick={() => {
                    consentHandler();
                  }}
                  wide={!mobile}
                >
                  Consent
                </Button>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </>
      </Modal>
    </StyledGrid>
  );

  async function updateNotificationPreferences(values: any): Promise<void> {
    await updateNotificationPreferencesMutation({
      variables: {
        input: {
          ...values
        }
      }
    });
    refetch();
  }
};

export default NotificationPreferences;
