import React from "react";
import styled from "styled-components";
import { StyledGrid } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

interface Props {
  question: string | JSX.Element;
}

const italicizeText = (
  firstPart: string,
  boldPart: string,
  lastPart: string
) => (
  <QuestionText>
    {firstPart}
    <i>{boldPart}</i>
    {lastPart}
  </QuestionText>
);

const QuestionText = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.large};
    max-width: 75%;
  }

  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 1.75rem;
  margin: ${spacingDefaults.xxlarge} auto 0;
  max-width: 680px;
  white-space: pre-line;
  line-height: 130%;
`;

const QuestionPrompt: React.FC<Props> = ({ question }: Props) => {
  if (typeof question === "string" && question.includes("**")) {
    const questionSplit = question.split("**");
    question = italicizeText(
      questionSplit[0],
      questionSplit[1],
      questionSplit[2]
    );
  }

  return (
    <StyledGrid
      item
      marginBottom={spacingDefaults.xlarge}
      mobileMarginBottom={spacingDefaults.large}
      mobileMarginTop="0"
    >
      {typeof question === "string" ? (
        <QuestionText>{question}</QuestionText>
      ) : (
        question
      )}
    </StyledGrid>
  );
};

export default QuestionPrompt;
