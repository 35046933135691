import React from "react";
import Card from "@material-ui/core/Card";
import styled from "styled-components";

import { color, fontSize } from "style/constants";

const unselectStyle = `
  border: 0.5px solid ${color.CARDGRAY};
  color: ${color.DARKGRAY};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
`;

const selectStyle = `
  border: 1px solid ${color.DARKBLUE};
  color: ${color.DARKBLUE};
  box-shadow: none;
`;

export const StyledCard = styled(({ ...otherProps }) => (
  <Card {...otherProps} />
))`
  && {
    ${props => (props.selected ? selectStyle : unselectStyle)}
    border-radius: 5px;
    font-size: ${fontSize.medium};
    &:hover {
      cursor: pointer;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const StyledButton = styled.button`
  border: none;
  background: none;
  width: 100%;
  padding: 0;
  margin: 0;
`;
