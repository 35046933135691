import React from "react";
import { Grid } from "@material-ui/core";

import { ReactComponent as SocialNotifications } from "assets/social-notifications.svg";
import { StyledText } from "../../../styled";

const Begin: React.FC = () => {
  return (
    <>
      <Grid item>
        <SocialNotifications />
      </Grid>
      <Grid item>
        <StyledText>
          You’re <b>free from distractions</b> like pets and social media.
        </StyledText>
      </Grid>
    </>
  );
};

export default Begin;
