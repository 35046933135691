import React from "react";
import { Box } from "@material-ui/core";

import TreeFooter from "assets/big-group-trees.svg";
import { fontSize, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";
import TaskItem, { TaskItemType } from "./TaskItem";

interface TaskListProps {
  tasks: TaskItemType[];
  disabled: boolean;
  hasCompletedHabit: boolean;
}

const TaskList: React.FC<TaskListProps> = ({
  tasks,
  disabled,
  hasCompletedHabit
}: TaskListProps) => {
  return (
    <>
      <Box fontSize="20px" lineHeight="24px" margin={``}>
        Task List
      </Box>
      <StyledGrid className="task-list" centerContent container item>
        {tasks
          .sort((a, b) => Number(b.isCompleted) - Number(a.isCompleted))
          .map((task: TaskItemType, idx: number) => {
            const isOptedOut =
              task?.subtitle === "Withdraw User Flow Survey" ||
              "Join the project to find out your BrainHealth Index!";
            return (
              <React.Fragment key={idx}>
                {tasks[idx - 1]?.isCompleted && !task.isCompleted && (
                  <Box fontSize={fontSize.small} fontStyle="italic">
                    Keep up the good work!
                  </Box>
                )}
                <StyledGrid
                  item
                  xs={12}
                  padding={`${spacingDefaults.small} 0`}
                  filter={!isOptedOut && disabled ? "grayscale(1)" : "none"}
                >
                  {task.type === "habit" ? (
                    <TaskItem
                      task={task}
                      disabled={isOptedOut ? false : disabled}
                      hasCompletedHabit={hasCompletedHabit}
                    />
                  ) : (
                    <TaskItem
                      task={task}
                      disabled={isOptedOut ? false : disabled}
                      allowTransfer={task.type === "transferToBHP"}
                      notAllowTransfer={task.type === "NotransferToBHP"}
                    />
                  )}
                </StyledGrid>
              </React.Fragment>
            );
          })}

        {tasks.every(task => task.isCompleted) && (
          <Box fontSize={fontSize.medium}>
            Come back tomorrow for more BrainHealthy content!
          </Box>
        )}
      </StyledGrid>
      {/* Footer */}
      <StyledGrid
        container
        centerContent
        margin={`${spacingDefaults.medium} 0`}
      >
        <img src={TreeFooter} style={{ overflow: "hidden" }} />
      </StyledGrid>
    </>
  );
};
export default TaskList;
