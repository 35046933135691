import styled from "styled-components";
import { color } from "style/constants";

export const StyledForm = styled.form`
  border-radius: 25px;
  border: solid 1px grey;
  display: flex;
  justify-content: space-between;

  input {
    padding-left: 1rem;
  }

  .MuiIconButton-root {
    background: ${color.DARKBLUE};
  }

  .MuiIconButton-root.Mui-disabled {
    background-color: ${color.GRAY};
  }

  .MuiIconButton-colorPrimary:hover {
    background: ${color.BLUE};
  }
`;
