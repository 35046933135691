import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import { StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";
import AssessmentsComplete from "./Complete";
import AssessmentsInProgress from "./InProgress";
import AssessmentsNotStarted from "./NotStarted";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";

interface OverallProgressProps {
  cycleStatus: UserAssessmentCycleStatus;
  daysToComplete: number;
  daysRemaining: number;
  assessmentsRemaining: number;
  disabled?: boolean;
}
const OverallProgress: React.FC<OverallProgressProps> = ({
  cycleStatus,
  daysToComplete,
  daysRemaining,
  assessmentsRemaining,
  disabled = false
}: OverallProgressProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid container>
      <StyledGrid item marginBottom={spacingDefaults.normal} xs={12}>
        <Box
          color={color.BLACK}
          component="h2"
          fontSize="20px"
          fontWeight="normal"
          lineHeight="24px"
          margin="0"
        >
          Overall Progress
        </Box>
      </StyledGrid>

      <StyledGrid
        alignItems="center"
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        direction="column"
        minHeight={mobile ? undefined : "442px"}
        item
        justifyContent="center"
        padding={mobile ? spacingDefaults.large : undefined}
        xs={12}
      >
        {getContent()}
      </StyledGrid>
    </StyledGrid>
  );

  function getContent(): JSX.Element | null {
    switch (cycleStatus) {
      case UserAssessmentCycleStatus.NOT_STARTED: {
        return (
          <AssessmentsNotStarted
            daysToComplete={daysToComplete}
            disabled={disabled}
          />
        );
      }

      case UserAssessmentCycleStatus.IN_PROGRESS: {
        return (
          <AssessmentsInProgress
            daysToComplete={daysToComplete}
            daysRemaining={daysRemaining}
            assessmentsRemaining={assessmentsRemaining}
          />
        );
      }

      default: {
        return <AssessmentsComplete />;
      }
    }
  }
};

export default OverallProgress;
