import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import styled from "styled-components";

import { color, fontSize, spacingDefaults } from "style/constants";

interface StyledCoin {
  cursorPointer?: boolean;
}

type StyledCoinProps = StyledCoin & ChipProps;

export const StyledCoin = styled(({ cursorPointer, ...otherProps }) => (
  <Chip {...otherProps} />
))`
  && {
    text-align: center;
    border-radius: 20px;
    border: none;
    background-color: rgba(255, 202, 40, 0.5);
    padding: ${spacingDefaults.small} 0;
    width: 100px;
    height: 25px;
  }
  .MuiChip-label {
    font-size: ${fontSize.small};
  }
  &:hover {
    cursor: ${props => (props.cursorPointer ? "pointer" : "default")};
  }
` as React.FC<StyledCoinProps>;

export const StyledTimeEstimate = styled(Chip)`
  && {
    text-align: center;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid ${color.LIGHTGRAY};
    padding: ${spacingDefaults.small} 0;
    width: 80px;
  }
  .MuiChip-label {
    font-size: ${fontSize.small};
  }
`;
