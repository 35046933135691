import React from "react";
import { Link } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";

import BlueHill from "assets/blue-hill-header.svg";
import SmallerBlueHill from "assets/smaller-blue-hill-header.svg";
import { Button, StyledGrid } from "components/simple";
import { toLowerDashCase } from "../utils";
import {
  color,
  fontFamily,
  fontSize,
  spacingDefaults,
  breakpoints
} from "style/constants";
import { ContentfulCourse, ContentfulSection } from "graphql/types/Contentful";

interface CoursesHeaderProps {
  coursesCompleted: number;
  currentSection: ContentfulSection | undefined;
  currentCourse: ContentfulCourse | undefined;
  isTrainingComplete: boolean;
}

const CoursesHeader: React.FC<CoursesHeaderProps> = ({
  coursesCompleted,
  currentSection,
  currentCourse,
  isTrainingComplete
}: CoursesHeaderProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      container
      direction="column"
      minHeight={isTrainingComplete || !currentSection ? "500px" : "650px"}
      justifyContent="space-between"
      alignItems="center"
      backgroundImage={
        isTrainingComplete || !currentSection ? SmallerBlueHill : BlueHill
      }
      backgroundPosition="top"
      textAlign="center"
      padding={`0 ${spacingDefaults.normal}`}
    >
      <StyledGrid item marginTop={spacingDefaults.xlarge}>
        <Box fontSize="30px" fontFamily={fontFamily.secondary} fontWeight="500">
          Welcome to Your BrainHealth® Training
        </Box>
      </StyledGrid>
      <StyledGrid
        item
        container
        direction="column"
        marginTop={spacingDefaults.normal}
      >
        <StyledGrid item margin="0 auto">
          <Box
            style={{
              color: color.DARKBLUE,
              fontSize: fontSize.xlarge,
              fontWeight: 500,
              borderRadius: "50%",
              border: `2px solid ${color.DARKBLUE}`,
              padding:
                coursesCompleted > 99
                  ? "6px 0"
                  : coursesCompleted < 10
                  ? "0 16px"
                  : "0 5px"
            }}
          >
            {coursesCompleted}
          </Box>
        </StyledGrid>
        <StyledGrid item>
          <Box
            style={{
              color: color.DARKBLUE,
              fontSize: fontSize.medium,
              fontWeight: 500
            }}
          >
            {coursesCompleted === 1
              ? `Training Completed`
              : `Trainings Completed`}
          </Box>
        </StyledGrid>
      </StyledGrid>
      <StyledGrid item marginBottom={spacingDefaults.xlarge}>
        {!currentSection ? (
          <>
            {isTrainingComplete ? (
              <>
                <Box fontSize={fontSize.large} fontWeight="500">
                  {`Great Job`}
                </Box>
                <Box fontSize={fontSize.normal}>
                  {`You're all caught up on trainings right now.`}
                </Box>
              </>
            ) : (
              <Box fontSize={fontSize.normal}>
                No Training Currently Assigned
              </Box>
            )}
          </>
        ) : (
          <>
            <StyledGrid centerContent margin={`${spacingDefaults.xsmall} 0`}>
              <Box
                style={{
                  color: color.BLACK,
                  fontSize: fontSize.large,
                  fontWeight: 500
                }}
              >
                {`Continue: ${currentSection?.title}`}
              </Box>
            </StyledGrid>
            <StyledGrid
              container
              centerContent
              margin={`${spacingDefaults.normal} 0`}
            >
              <img height="200px" src={currentSection?.thumbnailImage} />
            </StyledGrid>
            <StyledGrid
              centerContent
              margin={`${spacingDefaults.normal} ${mobile ? `5%` : `15%`}`}
            >
              <Box
                style={{
                  color: color.BLACK,
                  fontSize: fontSize.small
                }}
              >
                {currentSection?.description}
              </Box>
            </StyledGrid>
            <StyledGrid centerContent>
              <Link
                to={{
                  pathname: `/course/${toLowerDashCase(currentCourse?.title)}`
                }}
              >
                <Button>Jump back in</Button>
              </Link>
            </StyledGrid>
          </>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default CoursesHeader;
