import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Box, Divider, useMediaQuery } from "@material-ui/core";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, Document } from "@contentful/rich-text-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import VideoThumbnail from "assets/consent-video-thumbnail.png";
import { StyledGrid } from "components/simple";
import { LayoutType, MediaType } from "graphql/types/Contentful";
import ParseHtml from "../ParseHtml";

const ButtonGroupStyle = {
  width: "180px",
  cursor: "pointer",
  alignItems: "flex-end",
  borderRadius: "20px",
  backgroundColor: color.WHITE,
  padding: `0.25rem ${spacingDefaults.xsmall}`,
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)"
};
interface Props {
  data?: Document;
  setHasVideoEnded?: Dispatch<SetStateAction<boolean>>;
  setHasVideo?: Dispatch<SetStateAction<boolean>>;
  layoutType?: LayoutType;
  textColor?: color;
  textAlign?: string;
  customFontSize?: fontSize;
}

const RichText: React.FC<Props> = ({
  data,
  setHasVideoEnded,
  setHasVideo,
  layoutType,
  textColor,
  textAlign,
  customFontSize
}: Props) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [showTranscript, setShowTranscript] = useState<boolean>(false);
  const videoEl = useRef<HTMLVideoElement>(null);
  let supposedCurrentTime = 0;

  const getJustify = (textAlign?: string) => {
    switch (textAlign) {
      case "LEFT":
        return "flex-start";
      case "CENTER":
        return "center";
      case "RIGHT":
        return "flex-end";
      default:
        return "center";
    }
  };

  const options = () => {
    return {
      renderNode: {
        ...{
          [BLOCKS.HEADING_2]: (node: any, children: any) => (
            <Box
              component="h2"
              fontWeight="medium"
              fontSize={fontSize.xlarge}
              mb={spacingDefaults.xsmall}
              textAlign={textAlign ?? undefined}
            >
              {children}
            </Box>
          ),
          [BLOCKS.HEADING_3]: (node: any, children: any) => (
            <Box
              component="h3"
              fontWeight="medium"
              fontSize={fontSize.large}
              mb={spacingDefaults.xsmall}
              textAlign={textAlign ?? undefined}
            >
              {children}
            </Box>
          ),
          [BLOCKS.HEADING_4]: (node: any, children: any) => (
            <Box
              component="h4"
              fontWeight="medium"
              fontSize={fontSize.medium}
              mb={spacingDefaults.xsmall}
              textAlign={textAlign ?? undefined}
            >
              {children}
            </Box>
          ),
          [BLOCKS.HEADING_5]: (node: any, children: any) => (
            <Box
              component="h5"
              fontWeight="medium"
              fontSize={fontSize.normal}
              mb={spacingDefaults.xsmall}
              textAlign={textAlign ?? undefined}
            >
              {children}
            </Box>
          ),
          [BLOCKS.HEADING_6]: (node: any, children: any) => (
            <Box
              component="h6"
              fontWeight="medium"
              fontSize={fontSize.xsmall}
              mb={spacingDefaults.xsmall}
              textAlign={textAlign ?? undefined}
            >
              {children}
            </Box>
          ),
          [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
            <Box
              fontSize={customFontSize ?? fontSize.normal}
              whiteSpace="pre-line"
              color={textColor || color.BLACK}
              textAlign={textAlign ?? undefined}
            >
              {children}
            </Box>
          ),
          [BLOCKS.HR]: () => <Divider />,
          [BLOCKS.UL_LIST]: (node: any, children: any) => (
            <StyledGrid container justifyContent={getJustify(textAlign)}>
              <ul
                style={{
                  textAlign: "left",
                  color: textColor || color.BLACK,
                  margin: `0 0 ${spacingDefaults.xxsmall} 0`,
                  paddingLeft: "1.2rem"
                }}
              >
                {children}
              </ul>
            </StyledGrid>
          ),
          [BLOCKS.OL_LIST]: (node: any, children: any) => (
            <StyledGrid container justifyContent={getJustify(textAlign)}>
              <ol
                style={{
                  textAlign: "left",
                  color: textColor || color.BLACK,
                  margin: `0 0 ${spacingDefaults.xxsmall} 0`,
                  paddingLeft: "1.2rem"
                }}
              >
                {children}
              </ol>
            </StyledGrid>
          ),
          [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
            <li>
              <Box fontSize={fontSize.normal}>{children}</Box>
            </li>
          ),
          [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            const { file } = node.data.target.fields;

            return (
              <StyledGrid
                container
                centerContent
                margin={`${spacingDefaults.normal} 0`}
              >
                {/* not supporting right now */}
                {/* {file?.contentType === MediaType.html && (
                  <ParseHtml html={file?.url} />
                )} */}
                {file?.contentType === MediaType.videoMp4 ? (
                  <>
                    {setHasVideo && setHasVideoEnded && (
                      <>
                        {setHasVideo(true)}
                        <video
                          ref={videoEl}
                          key={file?.url}
                          width="100%"
                          controls
                          style={{ maxWidth: "850px" }}
                          poster={VideoThumbnail}
                          onEnded={() => setHasVideoEnded(true)}
                          onTimeUpdate={() => {
                            if (!videoEl?.current?.seeking) {
                              supposedCurrentTime =
                                videoEl?.current?.currentTime || 0;
                            }
                          }}
                          onSeeking={(e: any) => {
                            if (videoEl?.current?.currentTime) {
                              const delta =
                                videoEl?.current?.currentTime -
                                supposedCurrentTime;
                              if (delta > 0.01) {
                                videoEl.current.currentTime = supposedCurrentTime;
                              }
                            }
                          }}
                        >
                          <source src={file?.url} type={"video/mp4"} />
                        </video>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {setHasVideo && setHasVideo(false)}
                    <img
                      src={file?.url}
                      width="100%"
                      style={{ maxWidth: "850px" }}
                    />
                  </>
                )}
              </StyledGrid>
            );
          },
          [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
            const { fields } = node?.data?.target;
            const transcript = node?.data?.target?.fields?.transcript;

            const videoFile = fields?.video?.fields?.file?.url;
            const videoPoster =
              node?.data?.target?.fields?.thumbnailImage?.fields?.file?.url;

            const imageFile = fields?.image?.fields?.file?.url;
            const imageTargetUrl = fields?.targetUrl;

            if (imageFile) {
              return (
                <a
                  href={imageTargetUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={imageFile}
                    width="100%"
                    style={{ maxWidth: "850px" }}
                  />
                </a>
              );
            }
            return (
              <StyledGrid centerContent margin={`${spacingDefaults.normal} 0`}>
                <video
                  ref={videoEl}
                  key={videoFile}
                  width={mobile ? "100%" : "850px"}
                  height={mobile ? "100%" : "480px"}
                  controls
                  style={{
                    maxWidth: "850px",
                    maxHeight: "480px"
                  }}
                  poster={videoPoster}
                >
                  <source src={videoFile} type={"video/mp4"} />
                </video>
                {transcript && (
                  <StyledGrid
                    container
                    centerContent
                    style={{
                      maxWidth: "850px"
                    }}
                    margin={`${spacingDefaults.small} 0`}
                  >
                    <StyledGrid
                      container
                      onClick={() => setShowTranscript(!showTranscript)}
                      style={ButtonGroupStyle}
                    >
                      {showTranscript ? (
                        <>
                          <Box fontSize={fontSize.normal}>Hide Transcript</Box>
                          <ExpandLessIcon />
                        </>
                      ) : (
                        <>
                          <Box fontSize={fontSize.normal}>Show Transcript</Box>
                          <ExpandMoreIcon />
                        </>
                      )}
                    </StyledGrid>
                    {showTranscript && (
                      <StyledGrid
                        container
                        margin={`${spacingDefaults.small} 0`}
                      >
                        <RichText data={transcript} textAlign="LEFT" />
                      </StyledGrid>
                    )}
                  </StyledGrid>
                )}
              </StyledGrid>
            );
          }
        }
      },
      renderMark: {
        [MARKS.CODE]: (text: any) => <ParseHtml html={text} />
      }
    };
  };
  if (!data) return <></>;
  return <>{documentToReactComponents(data, options())}</>;
};
export default RichText;
