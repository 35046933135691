import React from "react";
import Card, { CardProps } from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Check from "@material-ui/icons/CheckCircle";

import styled from "styled-components";

import {
  color,
  fontSize,
  iconSizes,
  spacing,
  spacingDefaults
} from "style/constants";
import Button from "./Button";
import { ReactComponent as Lock } from "assets/lock.svg";
import { ReactComponent as Clock } from "assets/clock.svg";

const CheckWrapper = styled.div`
  svg {
    fill: ${color.GREEN};
    height: ${iconSizes.normal};
    width: ${iconSizes.normal};
  }
`;

// details for assessment groups
interface AssessmentGroup {
  groupName: string;
  timeToComplete: number;
  useKeyboard?: boolean;
}

// component props
interface StyledCard {
  // stylistic props
  background?: string;
  backgroundImage?: string;
  margins?: spacingDefaults;
  removeBorderStyle?: boolean;

  // informational props
  title?: string;
  state?: "Locked" | "Begin" | "Completed";
  link?: string;
  disabled?: boolean;
  wide?: boolean;
  assessmentGroupList: AssessmentGroup[];
  surveys?: boolean;
}

type StyledCardProps = StyledCard & CardProps;

// style overrides
const backgroundImageStyle = `
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const borderStyle = `
  border: 1px solid ${color.MUTEDGRAY};
  border-radius: 8px;
`;

// style overrides for specific elements
const titleStyle = {
  fontSize: fontSize.medium,
  fontWeight: 700,
  letterSpacing: "-0.75px",
  maxWidth: "55%"
};

const labelStyle = {
  fontWeight: 600
};

const hintStyle = {
  fontStyle: "italic",
  opacity: 0.6
};

const dividerStyle = {
  color: color.MUTEDGRAY,
  margin: spacing(spacingDefaults.xsmall, spacingDefaults.auto)
};

const ActiveArea: React.FC<StyledCard> = ({
  state,
  link,
  disabled,
  wide
}: StyledCard) => (
  <React.Fragment>
    {state === "Begin" && (
      <Button compressed disabled={disabled} href={link} wide={wide}>
        Begin
      </Button>
    )}
    {state === "Locked" && (
      <Lock height={iconSizes.normal} width={iconSizes.normal} />
    )}
    {state === "Completed" && (
      <CheckWrapper>
        <Check />
      </CheckWrapper>
    )}
  </React.Fragment>
);

const AssessmentLabel: React.FC<AssessmentGroup> = (Props: AssessmentGroup) => (
  <React.Fragment>
    <Grid item>
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item style={labelStyle}>
          {Props.groupName}
        </Grid>
        {Props.useKeyboard && (
          <Grid item style={hintStyle}>
            Keyboard required
          </Grid>
        )}
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
        <Grid item>{Props.timeToComplete} mins</Grid>
        <Clock height={iconSizes.xsmall} width={iconSizes.xsmall} />
      </Grid>
    </Grid>
  </React.Fragment>
);

const AssessmentsSection: React.FC<StyledCard> = ({
  assessmentGroupList
}: StyledCard) => (
  <React.Fragment>
    {assessmentGroupList.map((A, i) => (
      <React.Fragment key={i}>
        {i > 0 && <Divider style={dividerStyle} />}
        <Grid container justifyContent="space-between">
          <AssessmentLabel {...A} />
        </Grid>
      </React.Fragment>
    ))}
  </React.Fragment>
);

// main component
const StyledCard = styled(
  ({
    title,
    state,
    link,
    disabled,
    wide,
    surveys,
    assessmentGroupList,
    removeBorderStyle = false,
    ...otherProps
  }) => (
    <>
      {!surveys ? (
        <Card {...otherProps}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingBottom: spacingDefaults.normal }}
          >
            <Grid item style={titleStyle}>
              {title}
            </Grid>
            <Grid item>
              <ActiveArea
                state={state}
                link={link}
                disabled={disabled}
                wide={wide}
                assessmentGroupList={[]} // I am unsure how to get this to not be required without breaking things
              />
            </Grid>
          </Grid>
          <AssessmentsSection assessmentGroupList={assessmentGroupList} />
        </Card>
      ) : (
        <Card {...otherProps}>
          <Grid container alignItems="center">
            <Grid item style={{ ...titleStyle, maxWidth: "100%" }}>
              {title}
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-end"
              style={{ marginTop: spacingDefaults.normal }}
            >
              <ActiveArea
                state={state}
                link={link}
                disabled={disabled}
                wide={wide}
                assessmentGroupList={[]} // I am unsure how to get this to not be required without breaking things
              />
            </Grid>
          </Grid>
          <AssessmentsSection assessmentGroupList={assessmentGroupList} />
        </Card>
      )}
    </>
  )
)`
  && {
    ${props => props.backgroundImage && backgroundImageStyle}
    ${props => !props.removeBorderStyle && borderStyle}
    background-color: ${props => props.background};
    background-image: url("${props => props.backgroundImage}");
    margin: ${props => spacing(props.margins)};
    color: ${color.BLACK};
    padding: ${spacing(spacingDefaults.medium)};
    font-size: ${fontSize.normal};
    font-weight: 500;
  }
` as React.FC<StyledCardProps>;

export default StyledCard;
