import { gql } from "apollo-boost";

export const TaskListFragment = gql`
  fragment taskList on UserTaskList {
    coinsAmount
    estimatedTimeMinutes
    isCompleted
    ornament
    subtitle
    title
    type
  }
`;
export const GetUserTaskList = gql`
  query userTaskList {
    userTaskList {
      ...taskList
    }
  }
  ${TaskListFragment}
`;
