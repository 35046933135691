import { LinearProgress } from "@material-ui/core";
import { color } from "style/constants";
import styled from "styled-components";

export const StyledLinearProgress = styled(LinearProgress)`
  && {
    background-color: ${color.PROGRESSGRAY};
    border-radius: 5px;
    height: 10px;
    width: 100px;
  }
`;
