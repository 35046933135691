import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  useMediaQuery
} from "@material-ui/core";
import { color, fontSize, spacingDefaults } from "style/constants";
import { Button, RadioCard, StyledGrid } from "components/simple";
import {
  QuizQuestions,
  QuestionType,
  Answers,
  SortingQuestion
} from "graphql/types/Contentful";
import RichText from "../RichText";
import CardSorting from "components/simple/CardSorting";
import FlashCard from "components/simple/Flashcard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight
} from "@fortawesome/free-solid-svg-icons";
import { StyledDiv } from "./styled";
import { breakpoints } from "style/constants";

interface AnswersArr extends Answers {
  value: boolean;
}

interface QuizProps {
  content: QuizQuestions;
  setPassedQuiz: Dispatch<SetStateAction<boolean>>;
  passedQuiz: boolean;
  setHasVideoEnded: Dispatch<SetStateAction<boolean>>;
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  handleContinue: () => void;
  handlePrev: () => void;
  totalQuestions: number;
  hasCompletedTraining: boolean;
}

const Quiz: React.FC<QuizProps> = ({
  content,
  setPassedQuiz,
  passedQuiz,
  setHasVideoEnded,
  setHasVideo,
  handleContinue,
  handlePrev,
  totalQuestions,
  hasCompletedTraining
}: QuizProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [answersState, setAnswersState] = useState<AnswersArr[]>(
    content.possibleAnswers.map((item: Answers) => ({
      id: item?.id,
      answer: item?.answer,
      value: false
    }))
  );
  const [quizIndex, setQuizIndex] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [flashCardInitial, setFlashCardInitial] = useState(true);
  const isSortingContent = content?.questionType === QuestionType.sorting;
  useEffect(() => {
    // reset check boxes
    setPassedQuiz(false);
    setIsCorrect(false);
    setIsSubmitted(false);
    setSelectedAnswer("");
    setAnswersState(
      content.possibleAnswers.map((item: Answers) => ({
        id: item?.id,
        answer: item?.answer,
        value: false
      }))
    );
  }, [content]);

  const ifCorrectAnswer = (option: AnswersArr): boolean => {
    return content.correctAnswers.some(
      answerObj => answerObj.answer === option.answer
    );
  };

  const checkSingleAnswer = (selectedAnswer: string): boolean => {
    // if question is single answer
    // there will only be one array element correctAnswers[0]
    return selectedAnswer === content.correctAnswers[0].answer;
  };

  const checkMultipleAnswer = (answers: AnswersArr[]): boolean => {
    const selectedAnswers = answers
      .filter((answer: AnswersArr) => answer.value)
      .map(item => item.id)
      .sort();
    const correctAnswers = content?.correctAnswers?.map(item => item.id).sort();

    const isCorrect =
      selectedAnswers.length === correctAnswers?.length &&
      selectedAnswers.every((item, idx) => item === correctAnswers[idx]);
    return isCorrect;
  };

  if (isCorrect && !isSortingContent) setPassedQuiz(true);
  const onSubmit = () => {
    setIsSubmitted(true);
    switch (content?.questionType) {
      case QuestionType.single:
        setIsCorrect(checkSingleAnswer(selectedAnswer));
        break;
      case QuestionType.multiple:
        setIsCorrect(checkMultipleAnswer(answersState));
        break;
      default:
        setIsCorrect(true);
    }
  };

  const nextSlide = () => {
    setFlashCardInitial(true);
    if (quizIndex === totalQuestions - 2) {
      setDisableNext(true);
    }
    if (quizIndex < totalQuestions - 1) {
      setQuizIndex(quizIndex + 1);
      handleContinue();
      disablePrev && setDisablePrev(false);
    }
  };

  const prevSlide = () => {
    setFlashCardInitial(true);
    if (quizIndex === 1) {
      setDisablePrev(true);
    }
    if (quizIndex > 0) {
      setQuizIndex(quizIndex - 1);
      handlePrev();
      disableNext && setDisableNext(false);
    }
  };

  const handleAnswerChange = () => {
    if (quizIndex < totalQuestions - 1) {
      setDisableNext(false);
    } else if (quizIndex === totalQuestions - 1) {
      setEnableSubmit(true);
    }
  };

  // track the changes of enable submit and set passed quiz to true
  useEffect(() => {
    if (enableSubmit) {
      setPassedQuiz(true);
    }
  }, [enableSubmit]);

  if (isCorrect) setPassedQuiz(true);
  return (
    <>
      <StyledGrid
        container
        centerContent
        padding={`0 ${spacingDefaults.normal}`}
        id="Web-Screen"
      >
        <StyledGrid
          maxWidth={mobile ? "500px" : "50%"}
          flexDirection="column"
          container
          margin={`${spacingDefaults.normal} 0`}
          alignItems="center"
          id="Content"
        >
          {content?.questionType === QuestionType.sorting ? (
            <>
              {content?.instructions && (
                <StyledGrid
                  margin={`${spacingDefaults.large} 0`}
                  id="sorting-instructions"
                >
                  <RichText
                    data={content?.instructions}
                    setHasVideoEnded={setHasVideoEnded}
                    setHasVideo={setHasVideo}
                  />
                </StyledGrid>
              )}
              {content && content.question && (
                <CardSorting
                  sortingQuestion={(content as unknown) as SortingQuestion}
                  continueToNext={handleAnswerChange}
                />
              )}
            </>
          ) : content?.questionType === QuestionType.flashcards ? (
            <>
              {content?.instructions && (
                <StyledGrid margin={`${spacingDefaults.large} 0`}>
                  <RichText
                    data={content?.instructions}
                    setHasVideoEnded={setHasVideoEnded}
                    setHasVideo={setHasVideo}
                  />
                </StyledGrid>
              )}
              <FlashCard
                question={content.question}
                answer={content.correctAnswers[0].answer}
                flashCardInitial={flashCardInitial}
                setFlashCardInitial={setFlashCardInitial}
              />

              <>
                {!passedQuiz && (
                  <StyledDiv className="controls">
                    <div
                      className="slides-controls"
                      style={{ padding: "5px 0%" }}
                    >
                      <div className="arrow-indicators">
                        <button
                          onClick={prevSlide}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: disablePrev ? "default" : "pointer"
                          }}
                          disabled={disablePrev}
                        >
                          <span className="material-symbols-outlined">
                            <FontAwesomeIcon
                              icon={faChevronCircleLeft}
                              style={{
                                height: "2em",
                                color: disablePrev ? "#CDEBFA" : "#037DBA"
                              }}
                            />
                          </span>{" "}
                        </button>
                      </div>
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "1.7rem",
                          width: "65px"
                        }}
                      >
                        {quizIndex + 1} of {totalQuestions}
                      </p>
                      <div className="arrow-indicators">
                        <button
                          onClick={nextSlide}
                          style={{
                            border: "none",
                            background: "none",
                            cursor: disableNext ? "default" : "pointer"
                          }}
                          disabled={disableNext}
                        >
                          <span className="material-symbols-outlined">
                            <FontAwesomeIcon
                              icon={faChevronCircleRight}
                              style={{
                                height: "2em",
                                color: disableNext ? "#CDEBFA" : "#037DBA"
                              }}
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </StyledDiv>
                )}
              </>
            </>
          ) : (
            <StyledGrid>
              <StyledGrid margin={`${spacingDefaults.large} 0`}>
                <Box fontSize={fontSize.large}>{content.question}</Box>
              </StyledGrid>
              <StyledGrid margin={`${spacingDefaults.large} 0`}>
                <RichText
                  data={content?.instructions}
                  setHasVideoEnded={setHasVideoEnded}
                  setHasVideo={setHasVideo}
                />
              </StyledGrid>
              {content.questionType === QuestionType.single && (
                <>
                  <RadioCard
                    column
                    selections={content.possibleAnswers?.map(
                      item => item.answer
                    )}
                    onChange={(_, value) => {
                      setIsSubmitted(false);
                      setSelectedAnswer(value);
                    }}
                    incorrect={isSubmitted && !isCorrect}
                    correct={isSubmitted && isCorrect}
                    disabled={isCorrect}
                    value={selectedAnswer}
                  />
                </>
              )}
              {content.questionType === QuestionType.multiple && (
                <>
                  <StyledGrid container centerContent>
                    <StyledGrid item>
                      {content.possibleAnswers.map(
                        (item: Answers, idx: number) => {
                          const currentAnswer =
                            answersState.find(state => state.id === item.id) ||
                            answersState[0];
                          return (
                            <StyledGrid key={idx} textAlign="left">
                              <FormControlLabel
                                control={
                                  <StyledGrid
                                    marginLeft={spacingDefaults.small}
                                  >
                                    <Checkbox
                                      checked={currentAnswer?.value}
                                      disabled={passedQuiz}
                                      onChange={(_, value: boolean) => {
                                        setIsSubmitted(false);
                                        currentAnswer.value = value;
                                        setAnswersState(
                                          answersState.map((x: AnswersArr) =>
                                            x.id === currentAnswer.id
                                              ? currentAnswer
                                              : x
                                          )
                                        );
                                      }}
                                    />
                                  </StyledGrid>
                                }
                                label={item.answer}
                              />
                            </StyledGrid>
                          );
                        }
                      )}
                    </StyledGrid>
                  </StyledGrid>
                  {isSubmitted && (
                    <>
                      {isCorrect && (
                        <Box color={color.GREEN} fontSize={fontSize.medium}>
                          Correct!
                        </Box>
                      )}
                      {!isCorrect && (
                        <Box color={color.DARKRED} fontSize={fontSize.medium}>
                          Uh oh! This is not the correct answer. Try again.
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}
            </StyledGrid>
          )}
        </StyledGrid>
      </StyledGrid>
      {!passedQuiz && !hasCompletedTraining && (
        <StyledGrid container centerContent marginTop="auto">
          <Button
            onClick={onSubmit}
            disabled={
              content?.questionType === QuestionType.single
                ? !selectedAnswer
                : content?.questionType === QuestionType.sorting
                ? false
                : content?.questionType === QuestionType.flashcards
                ? quizIndex + 1 !== totalQuestions
                : !!!answersState.find(item => item.value)
            }
          >
            {content?.questionType === QuestionType.flashcards
              ? `${quizIndex + 1}/${totalQuestions} Complete`
              : "Submit"}
          </Button>
        </StyledGrid>
      )}
    </>
  );
};

export default Quiz;
