import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import {
  AssessmentCard,
  Button,
  DesktopSlider,
  StyledGrid
} from "components/simple";
import { breakpoints, color } from "style/constants";

const StyledP = styled.p`
  color: ${color.DARKBLUE};
  text-transform: capitalize;
`;

interface Props {
  answer?: number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  callback: React.Dispatch<any>;
  label: string;
  minValue?: number;
  maxValue?: number;
  prompt: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  skipCallback?: React.Dispatch<any>;
}

const NumericalScaleQuestion: React.FC<Props> = ({
  answer,
  callback,
  label,
  minValue,
  maxValue,
  prompt,
  skipCallback
}: Props) => {
  const [disabled, setDisabled] = useState(true);
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [value, setValue] = useState(answer || 0);

  useEffect(() => {
    setValue(answer || 0);
  }, [prompt]); // eslint-disable-line

  useEffect(() => {
    setDisabled(!value && value != minValue);
  }, [value]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleChange = (event: any, newValue: number | number[]): void => {
    setValue(newValue as number);
  };

  function valuetext(value: number): string {
    if (value <= 1)
      return `${value} ${label.endsWith("s") && label.slice(0, -1)}`;
    return `${value} ${label}`;
  }

  return (
    <AssessmentCard
      question={prompt}
      subtext={"Slide left to right to select option"}
      skipCallback={skipCallback}
    >
      <StyledGrid item width="100%" padding="2rem" marginBottom="10rem">
        <DesktopSlider
          aria-labelledby={`${label}-slider`}
          defaultValue={value}
          getAriaValueText={valuetext}
          min={minValue}
          max={maxValue}
          onChange={handleChange as any}
          value={value}
        />
        <StyledP>{label}</StyledP>
      </StyledGrid>
      <StyledGrid item marginBottom="1.6875rem">
        <Button
          data-testid="next-btn"
          wide={!mobile}
          disabled={disabled}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={() => {
            callback({ response: value });
            setDisabled(true);
          }}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default NumericalScaleQuestion;
