import { color } from "style/constants";

export default {
  root: {
    "&$disabled": {
      color: color.DARKGRAY
    },
    "&$error": {
      color: color.DARKGRAY
    },
    "&$focused": {
      color: color.LIGHTGRAY
    },
    color: color.DARKGRAY,
    marginLeft: "12px"
  }
};
