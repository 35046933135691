import React from "react";

import { ReactComponent as User } from "assets/defaultAvatar.svg";
import { color } from "style/constants";
import StyledGrid from "../StyledGrid";

interface AvatarProps {
  firstName?: string;
  lastName?: string;
  profileImageKey?: string;
  backgroundColor?: color;
  width?: string;
  height?: string;
}
const Avatar: React.FC<AvatarProps> = ({
  firstName,
  lastName,
  profileImageKey,
  backgroundColor = color.ICON,
  width = "36px",
  height = "36px"
}: AvatarProps) => (
  <StyledGrid
    backgroundColor={backgroundColor}
    borderRadius="50%"
    centerContent
    container
    height={height}
    overflow="hidden"
    width={width}
  >
    {!profileImageKey ? (
      <User height="75%" width="75%" />
    ) : (
      <img
        alt={`${firstName} ${lastName}`}
        height={width}
        src={profileImageKey}
        width={height}
        data-testid="profile-image"
      />
    )}
  </StyledGrid>
);

export default Avatar;
