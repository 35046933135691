import { Document } from "@contentful/rich-text-types";
import { PillarScore, UserAssessmentCycleStatus } from "graphql/types/Cycle";

export interface GetSessionsData {
  participantAssessmentCycles: AssessmentCycle[];
}

export interface GetCyclesData {
  participantAssessmentCycles: NewCycle[];
}

export interface NewCycle {
  id: string;
  bhiRefactorPillarScores: NewPillarScore[];
  cycleCompletionDate: string;
  cycleNumber: number;
  pillarScores: PillarScores[];
  status: UserAssessmentCycleStatus;
  statusDates: UserAssessmentCycleStatusDates;
  aceCompleted: boolean;
}

export interface UserAssessmentCycleStatusDates {
  assessments_not_started?: string;
  assessments_in_progress?: string;
  ready_to_score?: string;
  scoring_in_progress?: string;
  ready_for_review?: string;
  score_finalized?: string;
}

export interface PillarScores {
  pillarName: string;
  value: number;
  userAssessments: UserAssessmentsScore[];
}

export interface NewPillarScore {
  pillarName: PillarName;
  value: number;
  bhiRefactorUserAssessments: UserAssessmentsScore[];
}

export interface UserAssessmentsScore {
  name: string;
  scores: Scores[];
}

export interface Scores {
  scoreName: string;
  scoreType: ScoreType;
  value: number;
}

export interface GetFactorsData {
  factors: FactorInfo[];
}

export interface FactorInfo {
  name: string;
  description: string;
  subFactors: SubFactor[];
}

export interface SubFactor {
  name: string;
  description: string;
  longDescription?: Document;
}

export interface SelectedSubFactor extends SubFactor {
  index: number;
}

export enum PillarName {
  NEW_INDEX = "newIndex",
  CONNECTEDNESS = "connectedness",
  EMOTIONAL_BALANCE = "emotionalBalance",
  CLARITY = "clarity",
  EMERGING_FACTOR = "emergingFactor"
}

export enum ScoreType {
  RAW = "raw",
  PERCENTILE = "percentile"
}

export interface AssessmentCycle {
  id: string; // UserAssessmentCycleId
  coachingSessions: CoachingSession[];
  imagingSessions: ImagingSession[];
}
export interface CoachingSession {
  id: string;
  coach: {
    firstName: string;
  };
  status: CoachingSessionStatus;
  acuityConfirmationNumber: number;
  sessionTime: string;
  updatedAt: string;
  participantNotes: string | null;
  userAssessmentCycleId: string;
  reflectionSurvey: {
    completionDate: string | null;
    dismissalDate: string | null;
    answers: {
      answerData: {
        response: string;
      };
    }[];
  } | null;
}

export interface ImagingSession {
  id: string;
  status: CoachingSessionStatus;
  acuityConfirmationNumber: number;
  sessionTime: string;
  updatedAt: string;
  participantNotes: string | null;
}

export enum CoachingSessionStatus {
  SCHEDULED = "scheduled",
  COMPLETED = "completed",
  CANCEL = "cancel",
  MISSED = "missed"
}

export enum SessionType {
  COACHING = "coaching",
  IMAGING = "imaging",
  GROUP = "group"
}
export interface Cycle {
  status: UserAssessmentCycleStatus;
  cycleNumber: number;
  cycleCompletionDate: string;
  aceCompleted: boolean;
  notes: string;
  pillarScores: PillarScore[];
  coachingSessions: CoachingSession[];
  imagingSessions: ImagingSession[];
}

export interface Pillar {
  color: string;
  description: string;
  pillarName: string;
  value: number;
  order: number;
  weight: number;
  index?: number;
  assessments: PillarAssessment[];
  moreInfo: Record<any, any>[];
}

export interface PillarAssessment {
  name: string;
  score: number;
  description: string;
}
