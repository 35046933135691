import { FieldProps } from "formik";
import intlTelInput, { Plugin } from "intl-tel-input";
import React, { useEffect, useRef, useState } from "react";

import "intl-tel-input/build/css/intlTelInput.min.css";
import "./index.css";

interface FormPhoneInputProps {
  className?: string;
  label?: string;
}

type Props = FormPhoneInputProps & FieldProps;

const FormPhoneInput: React.FC<Props> = ({
  className,
  label = "Phone Number",
  field,
  form,
  ...props
}: Props) => {
  const input = useRef<HTMLInputElement>(null);

  const [plugin, setPlugin] = useState<Plugin>();

  useEffect(_initializeInput, []);

  const hasError = form.touched.phone && !!form.errors.phone;

  return (
    <div className={className}>
      <label className="iti__label">{label}</label>
      <input
        className={hasError ? "error" : ""}
        {...field}
        onBlur={_handleBlur}
        ref={input}
        {...props}
      />
      {hasError && (
        <p className="iti__error">
          {field.value ? "Invalid phone number." : "Phone Number is required"}
        </p>
      )}
    </div>
  );

  /* Internal */

  function _handleBlur(): void {
    form.setFieldValue(field.name, plugin?.getNumber(), true);
  }

  /* Effects */

  function _initializeInput(): void {
    if (!input?.current) {
      return;
    }

    const plugin = intlTelInput(input.current, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.min.js"
    });

    setPlugin(plugin);
  }
};

export default FormPhoneInput;
