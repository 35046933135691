import React from "react";
import { Box, Chip } from "@material-ui/core";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { LockOutlined, Warning } from "@material-ui/icons";

import styled from "styled-components";
import { addOpacity, color, fontSize, spacingDefaults } from "style/constants";
import { ReactComponent as Clock } from "assets/clock.svg";
import { Button, ConfirmationModal, StyledGrid } from "components/simple";
import { toLowerDashCase } from "../utils";
import {
  ContentfulTask,
  ContentfulUnit,
  CourseStatus,
  TaskType
} from "graphql/types/Contentful";
import ItemStatus from "../Course/ItemStatus";
import { TrainingLockedStatus } from ".";
import coinIcon from "assets/coin-icon.svg";

export interface UnitItemProps {
  unitNumber: number;
  unitDetails: ContentfulUnit;
  isTeaserTrainingLocked: TrainingLockedStatus;
  isTrainingLocked: TrainingLockedStatus;
  canBuyAdditionalTraining: boolean;
  redeemAdditionalTraining(): void;
}

const StyledChip = styled(Chip)`
  && {
    text-align: center;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid ${color.LIGHTGRAY};
    padding: ${spacingDefaults.small} 0;
    width: 90px;
  }
  .MuiChip-label {
    font-size: ${fontSize.small};
  }
`;

const UnitItem: React.FC<UnitItemProps> = ({
  unitNumber,
  unitDetails,
  isTeaserTrainingLocked,
  isTrainingLocked,
  canBuyAdditionalTraining,
  redeemAdditionalTraining
}: UnitItemProps) => {
  const [showUnlockTrainingModal, setShowUnlockTrainingModal] = React.useState<
    boolean
  >(false);

  const isCompleted = unitDetails?.status === CourseStatus.completed;
  const isInProgress = unitDetails?.status === CourseStatus.inProgress;
  const isNotStarted =
    unitDetails?.status === CourseStatus.notStarted && unitDetails?.active;
  const unitAction = isCompleted ? "View" : isInProgress ? "Continue" : "Start";

  const tasks = unitDetails?.tasks;
  const lessonsAndQuizes =
    unitDetails?.tasks
      ?.sort((a, b) => a.order - b.order)
      ?.filter(
        (task: ContentfulTask) =>
          task.taskType === TaskType.lesson || task.taskType === TaskType.quiz
      ) || [];

  const currentTask = tasks
    ?.sort((a, b) => a.order - b.order)
    ?.find((task: ContentfulTask) => task.status !== CourseStatus.completed);
  const unitSlug = toLowerDashCase(unitDetails?.title);
  const match = useRouteMatch();

  const lockedTrainingStatuses = ["locked", "additionalTrainingAvailable"];

  const history = useHistory();

  return (
    <StyledGrid container wrap="nowrap" margin={`${spacingDefaults.small} 0`}>
      <StyledGrid
        item
        container
        centerContent
        alignItems="center"
        marginRight={spacingDefaults.normal}
        backgroundColor={
          isCompleted
            ? (addOpacity(color.GREEN, 0.3) as color)
            : isInProgress
            ? (addOpacity(color.BLUE, 0.3) as color)
            : color.GRAY
        }
        border={`1px solid ${
          isCompleted
            ? color.GREEN
            : isInProgress
            ? color.BLUE
            : color.LIGHTGRAY
        }`}
        borderRadius="50%"
        minWidth="65px"
        maxWidth="65px"
        minHeight="65px"
        maxHeight="65px"
      >
        <Box
          fontSize={fontSize.xlarge}
          fontWeight="500"
          color={
            isCompleted
              ? color.GREEN
              : isInProgress
              ? color.BLUE
              : color.LIGHTGRAY
          }
        >
          {unitNumber}
        </Box>
      </StyledGrid>
      <StyledGrid item>
        <StyledGrid container>
          <StyledGrid item marginRight={spacingDefaults.normal}>
            <Box
              color={isInProgress ? color.BLACK : color.GRAPHITE}
              fontWeight={"500"}
            >
              {unitDetails?.title}
            </Box>
          </StyledGrid>
          <StyledGrid item>
            <ItemStatus
              status={unitDetails?.status}
              active={unitDetails?.active}
            />
          </StyledGrid>
        </StyledGrid>
        <StyledGrid container textAlign="left">
          <ul
            style={{
              color: isInProgress ? color.BLACK : color.GRAPHITE,
              margin: `${spacingDefaults.xsmall} 3px`,
              paddingLeft: spacingDefaults.normal,
              listStylePosition: "outside"
            }}
          >
            {tasks?.map((task: ContentfulTask, idx: number) => (
              <li key={idx}>{task?.title}</li>
            ))}
          </ul>
        </StyledGrid>
        {!isCompleted && (
          <StyledGrid container>
            <StyledChip
              icon={<Clock />}
              label={`${unitDetails?.timeEstimate} mins`}
              size="small"
              color="primary"
            />
          </StyledGrid>
        )}
        {isCompleted && lessonsAndQuizes.length > 0 && (
          <StyledGrid container>
            <Link
              to={{
                pathname: `${match.url}/${unitSlug}`,
                search: `?task-id=${lessonsAndQuizes[0]?.id}`,
                state: {
                  allTasks: lessonsAndQuizes,
                  pageNumber: 1,
                  percentageCompletion: 100,
                  isUnitCompleted: true
                }
              }}
              style={{
                color: color.BLUE,
                textDecoration: "underline"
              }}
            >
              {unitAction}
            </Link>
          </StyledGrid>
        )}
        {(isInProgress || isNotStarted) && (
          <StyledGrid container marginTop={spacingDefaults.xsmall}>
            {lockedTrainingStatuses.includes(isTeaserTrainingLocked) ? (
              <>
                <StyledGrid container alignItems="center">
                  <Button
                    background={color.GRAY}
                    compressed
                    width="100px"
                    style={{
                      marginRight: spacingDefaults.xsmall,
                      cursor: "default"
                    }}
                  >
                    <LockOutlined
                      fontSize="small"
                      style={{ color: color.GRAPHITE }}
                    />
                  </Button>
                  {isTeaserTrainingLocked === "additionalTrainingAvailable" &&
                    canBuyAdditionalTraining && (
                      <StyledGrid
                        direction="column"
                        margin={`${spacingDefaults.xsmall} 0`}
                      >
                        <Button
                          compressed
                          onClick={() => {
                            setShowUnlockTrainingModal(true);
                          }}
                        >
                          <img
                            src={coinIcon}
                            height="100%"
                            style={{ paddingRight: spacingDefaults.xsmall }}
                          />
                          Unlock Unit
                        </Button>
                      </StyledGrid>
                    )}
                </StyledGrid>
                <StyledGrid
                  margin={`${spacingDefaults.xsmall} 0`}
                  textAlign="left"
                >
                  {isTeaserTrainingLocked === "additionalTrainingAvailable" &&
                  canBuyAdditionalTraining ? (
                    <Box color={color.BLUE} fontStyle="italic">
                      Training is unlocked in small chunks so you have time to
                      practice in real life.
                    </Box>
                  ) : (
                    <Box color={color.BLUE} fontStyle="italic">
                      We’re protecting you from information overload! Additional
                      training will be unlocked tomorrow. <br />
                      In the meantime, go practice what you’ve learned.
                    </Box>
                  )}
                </StyledGrid>
              </>
            ) : lockedTrainingStatuses.includes(isTrainingLocked) ? (
              <>
                <StyledGrid container alignItems="center">
                  <Button
                    background={color.GRAY}
                    compressed
                    width="100px"
                    style={{
                      marginRight: spacingDefaults.xsmall,
                      cursor: "default"
                    }}
                  >
                    <LockOutlined
                      fontSize="small"
                      style={{ color: color.GRAPHITE }}
                    />
                  </Button>
                  {isTrainingLocked === "additionalTrainingAvailable" &&
                    canBuyAdditionalTraining && (
                      <StyledGrid
                        direction="column"
                        margin={`${spacingDefaults.xsmall} 0`}
                      >
                        <Button
                          compressed
                          onClick={() => {
                            setShowUnlockTrainingModal(true);
                          }}
                        >
                          <img
                            src={coinIcon}
                            height="100%"
                            style={{ paddingRight: spacingDefaults.xsmall }}
                          />
                          Unlock Unit
                        </Button>
                      </StyledGrid>
                    )}
                </StyledGrid>
                <StyledGrid
                  margin={`${spacingDefaults.xsmall} 0`}
                  textAlign="left"
                >
                  {isTrainingLocked === "additionalTrainingAvailable" &&
                  canBuyAdditionalTraining ? (
                    <Box color={color.BLUE} fontStyle="italic">
                      Training is unlocked in small chunks so you have time to
                      practice in real life.
                    </Box>
                  ) : (
                    <Box color={color.BLUE} fontStyle="italic">
                      We’re protecting you from information overload! Additional
                      training will be unlocked tomorrow. <br />
                      In the meantime, go practice what you’ve learned.
                    </Box>
                  )}
                </StyledGrid>
              </>
            ) : (
              <Link
                to={{
                  pathname: `${match.url}/${unitSlug}`,
                  search: `?task-id=${currentTask?.id}`,
                  state: {
                    allTasks: tasks,
                    pageNumber: 1,
                    percentageCompletion: unitDetails?.percentageCompletion
                  }
                }}
              >
                <Button compressed width="100px">
                  {unitAction}
                </Button>
              </Link>
            )}
          </StyledGrid>
        )}
        {showUnlockTrainingModal && (
          <ConfirmationModal
            icon={
              <img
                src={coinIcon}
                height="100%"
                style={{ paddingBottom: spacingDefaults.xsmall }}
              />
            }
            title={"Are you sure you want to spend 100 coins?"}
            content={"Use coins to unlock one additional training per day."}
            confirmText={"Yes, unlock training"}
            onConfirm={() => {
              setShowUnlockTrainingModal(false);
              redeemAdditionalTraining();

              history.push({
                pathname: `${match.url}/${unitSlug}`,
                search: `?task-id=${currentTask?.id}`,
                state: {
                  allTasks: tasks,
                  pageNumber: 1,
                  percentageCompletion: unitDetails?.percentageCompletion
                }
              });
            }}
            cancelText={"No, I'll save my coins"}
            onCancel={() => setShowUnlockTrainingModal(false)}
          />
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default UnitItem;
