import { useMutation } from "@apollo/react-hooks";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import track from "react-tracking";
import * as Yup from "yup";

import { ReactComponent as ForgotPasswordIcon } from "assets/forgot-password.svg";
import { BackButton, StyledGrid } from "components/simple";
import { ForgotPassword } from "graphql/user/user.gql";
import { ForgotPasswordFormValues } from "models/user";
import { showErrorNotification } from "state";
import { breakpoints, color } from "style/constants";
import routesConfig from "utils/routesConfig";
import Confirmation from "./Confirmation";
import { RequestForm } from "./Forms";
import { Callout, Spacer, Title } from "./styled";

const initialFormValues = {
  email: ""
};

const url: string = routesConfig.forgotPassword.path;

const ForgotPasswordForm: React.FC = () => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [emailSent, setEmailSent] = useState(false);
  const [forgotPassword] = useMutation(ForgotPassword);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email")
  });

  const submitForm = async (
    values: ForgotPasswordFormValues,
    helpers: FormikHelpers<ForgotPasswordFormValues>
  ): Promise<null> => {
    const { email } = values;
    try {
      const res = await forgotPassword({
        variables: { email: email }
      });
      if (
        res.data &&
        res.data.sendForgotPasswordEmail &&
        res.data.sendForgotPasswordEmail.success
      ) {
        setEmailSent(true);
      }
    } catch (err) {
      dispatch(showErrorNotification("", "Check email"));
      return null;
    } finally {
      helpers.setSubmitting(false);
    }
    return null;
  };

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      container
      direction="column"
      fillRemainingHeight
    >
      {emailSent ? (
        <Confirmation resend={() => setEmailSent(false)} />
      ) : (
        <>
          <BackButton />
          <StyledGrid
            container
            centerContent={!mobile}
            direction={mobile ? "column" : "row"}
            maxWidth={mobile ? "75%" : "1086px"}
            spacing={mobile ? 2 : 10}
            margin="auto"
            fillRemainingHeight
          >
            <StyledGrid
              alignSelf="center"
              item
              lg={6}
              maxWidth={mobile ? "80%" : "470px"}
              xs={12}
            >
              <ForgotPasswordIcon width="100%" height="100%" />
            </StyledGrid>
            <Hidden mdDown>
              <StyledGrid>
                <Spacer />
              </StyledGrid>
            </Hidden>
            <StyledGrid
              container
              direction="column"
              item
              lg={6}
              xs={12}
              justifyContent="center"
              textAlign={mobile ? "center" : "left"}
              spacing={3}
            >
              <StyledGrid item>
                <Title>Forgot Password</Title>
                <Callout>
                  Enter your email address associated with your account below.
                </Callout>
              </StyledGrid>
              <StyledGrid item>
                <Formik
                  component={RequestForm}
                  initialValues={initialFormValues}
                  onSubmit={submitForm}
                  validationSchema={ForgotPasswordSchema}
                  validateOnBlur
                />
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </>
      )}
    </StyledGrid>
  );
};

export default track({
  url
})(ForgotPasswordForm);
