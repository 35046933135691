import React, { Fragment } from "react";
import { kebabCase } from "lodash";

import { NavTitle, NavNums, Anchor, StyledList, Ruler } from "../styled";
import { StyledGrid } from "components/simple";

interface SideNavProps {
  pages: any;
  currentPage: number;
  children: React.ReactElement;
  showQuestions: boolean;
}

const SideNav: React.FC<SideNavProps> = ({
  pages,
  currentPage,
  showQuestions,
  children
}: SideNavProps) => {
  const totalPages = pages.length;
  const questionsPageNumber = totalPages + 1;
  return (
    <StyledGrid position="sticky" top="80px" maxHeight="90vh" overflow="scroll">
      <NavTitle>Table of Contents</NavTitle>
      {pages.map((page: any, index: number) => {
        const pageNum = index + 1;

        return (
          <Fragment key={index}>
            {index !== 0 && (
              <StyledGrid item margin="1rem 50% 1rem 0">
                <Ruler />
              </StyledGrid>
            )}
            <NavNums>
              {pageNum.toString().length === 1 ? "0" : ""}
              {pageNum}
            </NavNums>
            <StyledList>
              {page.fields.content.content.map((item: any) => {
                if (item.nodeType === "heading-2") {
                  const pageNum = index + 1;
                  const id = kebabCase(item.content[0].value);
                  return (
                    <div key={id}>
                      <Anchor to={`/consent/form?page=${pageNum}#${id}`}>
                        {item.content[0].value}
                      </Anchor>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </StyledList>
            {pageNum === currentPage && children}
          </Fragment>
        );
      })}
      {showQuestions ? (
        <>
          <StyledGrid item margin="1rem 50% 1rem 0">
            <Ruler />
          </StyledGrid>
          <NavNums>
            {questionsPageNumber.toString().length === 1 ? "0" : ""}
            {questionsPageNumber}
          </NavNums>
          <StyledList>
            <div>
              <Anchor to={"/consent/questions"}>Consent Questions</Anchor>
            </div>
          </StyledList>
        </>
      ) : null}
    </StyledGrid>
  );
};

export default SideNav;
