import React from "react";

import { MultiResponseQuestion } from "components/simple";
import { breakpoints } from "style/constants";
import defaultImage from "assets/innovation-image.png";
import styled from "styled-components";

interface Image {
  alt: string;
  src: string;
}

interface ImageThemeQuestionProps {
  callback: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  activeQuestion: number;
  image?: Image;
  subtext?: string;
  time?: number;
}

const StyledImg = styled.img`
  @media (max-width: ${breakpoints.lg}px) {
    max-width: 90%;
    max-height: 450px;
  }
  max-width: 650px;
  max-height: 650px;
  width: auto;
  height: auto;
`;

const ImageThemeQuestion: React.FC<ImageThemeQuestionProps> = ({
  activeQuestion,
  callback,
  image,
  subtext,
  time
}: ImageThemeQuestionProps) => {
  if (!image) {
    image = {
      alt:
        "A giraffe looks out to a cityscape in the distance on a clear sunny day.",
      src: defaultImage
    };
  }
  const { src } = image;

  const handleResponse = (response: any): void => {
    callback({ ...response, image: src });
  };

  return (
    <MultiResponseQuestion
      activeQuestion={activeQuestion}
      callback={handleResponse}
      time={time}
      question={<StyledImg {...image} />}
      subtext={subtext}
      hideWords={false}
      oneWord={true}
    />
  );
};

export default ImageThemeQuestion;
