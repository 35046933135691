import { useQuery } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";

import { hideLoader, showLoader } from "state";

interface Props {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  input: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options?: any;
}
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const useQueryCustom = ({ input, options }: Props): any => {
  const { data, error, loading, refetch } = useQuery(input, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    ...options
  });
  const dispatch = useDispatch();
  if (data || error) {
    dispatch(hideLoader());
  }
  if (loading) {
    dispatch(showLoader());
  }
  if (data) {
    return { data, refetch };
  }

  return {};
};

export default useQueryCustom;
