import styled from "styled-components";

import {
  addOpacity,
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

export const BHIHeader = styled.h2`
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.large};
  font-weight: 400;
  text-align: center;

  & > strong {
    color: ${color.BLUE};
    font-family: ${fontFamily.primary};
    font-size: 30px;
    font-weight: 600;
    margin-left: 1rem;
    vertical-align: middle;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.large};
    margin: 0.25rem;
    text-align: center;

    & > strong {
      font-size: ${fontSize.header};
    }
  }
`;

export const BHILabel = styled.p`
  margin: 0;
  margin-top: ${spacingDefaults.xsmall};

  &.emerging {
    font-size: ${fontSize.medium};
    font-style: italic;

    @media screen and (max-width: ${breakpoints.lg}px) {
      font-size: ${fontSize.small};
    }
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.small};
  }
`;

export const BHIScore = styled.p`
  font-size: ${fontSize.large};
  margin: 0;
  margin-top: ${spacingDefaults.xsmall};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${fontSize.small};
  }
`;

export const ScoreDelta = styled.div`
  align-items: center;
  border-radius: 7px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: ${color.DARKBLUE};
  display: inline-flex;
  padding: 1rem;

  p {
    display: inline;
    line-height: 13px;
    margin: 0;
  }

  svg {
    fill: ${color.DARKBLUE};
    margin-right: ${spacingDefaults.xsmall};

    &.negative {
      transform: rotate(0.5turn);
    }
  }
`;
