import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery } from "@apollo/react-hooks";
import {
  useMediaQuery,
  Drawer as MUIDrawer,
  makeStyles,
  IconButton,
  Box
} from "@material-ui/core";

import BlueHillCurve from "assets/UserStreaks/bluecurve.svg";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import Button from "../Button";
import StyledGrid from "../StyledGrid";
import { UserData } from "models/user";
import { GetStreakData } from "graphql/user/user.gql";
import UserStreakCalendar from "./UserStreakCalendar";

interface UserStreakDrawerProps {
  inputDate: Date;
  drawerOpen: boolean;
  setStreakDrawer: (isOpen: boolean) => void;
}

const UserStreakDrawer: React.FC<UserStreakDrawerProps> = ({
  inputDate,
  drawerOpen,
  setStreakDrawer
}) => {
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const useStyles = makeStyles({
    paper: {
      background: color.WHITE,
      padding: "0px",
      height: `calc(100% - ${mobile ? "145px" : "65px"})`,
      top: mobile ? "100px" : "115px",
      width: mobile ? "100%" : "400px"
    }
  });
  const classes = useStyles();

  const [userCreatedAt, setUserCreatedAt] = useState<Date>(inputDate);

  const { data, loading } = useQuery<UserData>(GetStreakData, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      setUserCreatedAt(data.me.createdAt);
    }
  });

  if (loading) return null;

  return (
    <>
      <MUIDrawer
        anchor="right"
        classes={{ paper: classes.paper }}
        onClose={() => setStreakDrawer(false)}
        open={drawerOpen}
        variant="temporary"
      >
        {drawerOpen && (
          <StyledGrid
            container
            alignItems="center"
            direction="column"
            padding={spacingDefaults.medium}
          >
            <img
              src={BlueHillCurve}
              width="100%"
              style={{ position: "absolute", top: "-25px", zIndex: -1 }}
            />

            <IconButton
              size="medium"
              style={{
                position: "absolute",
                top: 0,
                left: 0
              }}
              onClick={() => setStreakDrawer(false)}
            >
              <CloseIcon />
            </IconButton>

            <StyledGrid item>
              <StyledGrid
                container
                alignItems="center"
                flexDirection="column"
                margin={`${spacingDefaults.medium} 0`}
              >
                <Box fontSize={fontSize.small}>
                  Habits, Training, &amp; Resources
                </Box>
                <Box
                  fontFamily={fontFamily.secondary}
                  fontSize={fontSize.large}
                  fontWeight="bold"
                >
                  Streak Progress
                </Box>
              </StyledGrid>
            </StyledGrid>

            <StyledGrid
              item
              marginTop={spacingDefaults.large}
              textAlign="center"
            >
              <Box fontSize={fontSize.normal} fontWeight="bold">
                Current Streak: {data?.me?.currentStreak || 0}
              </Box>
              <Box fontSize={fontSize.normal} fontWeight="bold">
                Best Streak: {data?.me?.bestStreak || 0}
              </Box>
            </StyledGrid>
            <StyledGrid
              item
              width="100%"
              maxWidth="400px"
              marginTop={spacingDefaults.large}
            >
              <UserStreakCalendar
                userCreatedAt={userCreatedAt}
                currentDate={new Date()}
              />
            </StyledGrid>
            <StyledGrid
              item
              marginTop={spacingDefaults.large}
              textAlign="center"
            >
              <Box
                fontSize={fontSize.normal}
                color={color.GRAPHITE}
                fontStyle="italic"
              >
                Spend coins to revive your streak in My Journey
              </Box>
            </StyledGrid>
            <StyledGrid item marginTop={spacingDefaults.large}>
              <Box>
                <Button
                  onClick={() => {
                    setStreakDrawer(false);
                    history.push("/myjourney");
                  }}
                >
                  Go to My Journey
                </Button>
              </Box>
            </StyledGrid>
          </StyledGrid>
        )}
      </MUIDrawer>
    </>
  );
};

export default UserStreakDrawer;
