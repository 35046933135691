import { CircularProgress as MuiCircularProgress } from "@material-ui/core";
import { StyledGrid } from "components/simple";
import React from "react";
import styled from "styled-components";

import { color } from "style/constants";

interface CircularProgressProps {
  value: number;
  total: number;
  color?: string;
  size: string;
  thickness: number;
}

export const StyledMuiCircularProgress = styled(MuiCircularProgress)<{
  overrideColor?: string;
}>`
  && {
    color: ${props => props.overrideColor ?? color.MUTEDGRAY};
  }
`;

const CircularProgress: React.FC<React.PropsWithChildren<
  CircularProgressProps
>> = ({
  value,
  total,
  color,
  size,
  thickness,
  children
}: React.PropsWithChildren<CircularProgressProps>) => {
  return (
    <StyledGrid
      alignItems="center"
      centerContent
      container
      direction="column"
      height={size}
      item
      width={size}
    >
      <StyledGrid item position="absolute" top="0">
        <StyledMuiCircularProgress
          thickness={thickness}
          size={size}
          value={100}
          variant="determinate"
        />
      </StyledGrid>

      <StyledGrid item position="absolute" top="0">
        <StyledMuiCircularProgress
          overrideColor={color}
          thickness={thickness}
          size={size}
          value={100 * (value / total)}
          variant="determinate"
        />
      </StyledGrid>

      {children}
    </StyledGrid>
  );
};

export default CircularProgress;
