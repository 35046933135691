import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import { ConfirmScreener } from "graphql/user/user.gql";
import routesConfig from "utils/routesConfig";
import Disqualify from "./Disqualify";
import Qualify from "./Qualify";

const Qualification: React.FC = () => {
  const [confirmScreener] = useMutation(ConfirmScreener);

  const [isQualified, setIsQualified] = useState<boolean | null>(null);
  const [isWaitlisted, setIsWaitlisted] = useState<boolean | null>(null);

  const qualifiedRoute = async (): Promise<any> => {
    const response = await confirmScreener();

    if (response?.data?.confirmScreener) {
      const qualified = response.data.confirmScreener.qualified;
      setIsQualified(qualified);

      const waitlisted = response.data.confirmScreener.waitlisted;
      setIsWaitlisted(waitlisted);
    }
  };

  useEffect(() => {
    if (isQualified === null) qualifiedRoute();
  }, []); //eslint-disable-line

  if (isQualified === null || isWaitlisted === null) {
    return null;
  }

  return isQualified ? (
    isWaitlisted ? (
      <Redirect to={routesConfig.waitlist.path} />
    ) : (
      <Qualify />
    )
  ) : (
    <Disqualify />
  );
};

export default Qualification;
