import React from "react";
import Link from "@material-ui/core/Link";

import { ReactComponent as Stopwatch } from "assets/time-management.svg";
import { BackButton, Button, StyledGrid } from "components/simple";
import { color } from "style/constants";
import { StyledHeader } from "../styled";

interface BeginProps {
  setShowCarousel: React.Dispatch<React.SetStateAction<boolean>>;
}

const Begin: React.FC<BeginProps> = ({ setShowCarousel }: BeginProps) => {
  setShowCarousel(false);
  return (
    <>
      <BackButton location="/dashboard" />
      <StyledGrid
        backgroundColor={color.WHITE}
        centerContent
        container
        direction="column"
        fillRemainingHeight
        itemMargin="3rem"
      >
        <StyledGrid item>
          <Stopwatch />
        </StyledGrid>
        <StyledGrid container item>
          <StyledHeader maxWidth="435px">
            Set aside enough time to complete this session
          </StyledHeader>
        </StyledGrid>
        <StyledGrid item>
          <Button onClick={() => setShowCarousel(true)} wide>
            Begin
          </Button>
        </StyledGrid>
        <StyledGrid item>
          <Link href="/dashboard" underline="always">
            I&apos;ll do it later
          </Link>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export default Begin;
