import styled from "styled-components";

const WidgetTitle = styled("h2")`
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
  margin: 0;
`;

export default WidgetTitle;
