import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { breakpoints } from "style/constants";
import { ProductTour } from "components/simple";
import { BHIChartType } from "components/simple/BHIChart";
import { PillarName } from "../CycleSummary/types";
import { Step } from "components/simple/ProductTour";

interface BhiProductTourProps {
  bhiTourName: string;
  runIndexTourExistingUser: boolean;
  runIndexTourNewUser: boolean;
  stepIndex: number;
  resetSelectedFactors: () => void;
  toggleSelectedFactor: (pillarName: PillarName) => void;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setBhiChartType: React.Dispatch<React.SetStateAction<BHIChartType>>;
}

const BhiProductTour: React.FC<BhiProductTourProps> = ({
  bhiTourName,
  runIndexTourExistingUser,
  runIndexTourNewUser,
  stepIndex,
  resetSelectedFactors,
  toggleSelectedFactor,
  setStepIndex,
  setBhiChartType
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const existingUserTourSteps: Partial<Step>[] = [
    {
      // existing user intro BHI 2.0
      placement: "center",
      target: "body"
    },
    {
      // factors
      callback: () => {
        if (mobile) {
          toggleSelectedFactor(PillarName.CLARITY);
          setStepIndex(2);
        }
      },
      placement: "bottom",
      target: mobile ? ".bhi-factor-dropdown" : ".bhi-factors-banner"
    },
    {
      // toggle
      event: "index-toggle-factor-select",
      placement: "bottom",
      hideFooter: true,
      target: ".clarity-factor",
      spotlightClicks: true
    },
    {
      // measures
      callback: () => {
        resetSelectedFactors();
        setStepIndex(4);
      },
      placement: "top",
      target: ".subfactor-bar-graph"
    },
    {
      // tree
      placement: "top",
      target: ".bhi-tree"
    },
    {
      // switch views
      event: "index-switch-bhi-chart",
      placement: "top",
      hideFooter: true,
      target: ".bhi-chart-switch-button",
      spotlightClicks: true
    },
    {
      // data views
      callback: () => {
        setBhiChartType(BHIChartType.Tree);
        setStepIndex(7);
      },
      placement: "top",
      target: ".bhi-radar"
    },
    {
      // rounds
      placement: "bottom",
      target: ".cycle-select-banner",
      spotlightClicks: true
    }
  ];

  const existingUserTourStepsMobile: Partial<Step>[] = [
    {
      // existing user intro BHI 2.0
      placement: "center",
      target: "body"
    },
    {
      // factors
      callback: () => {
        if (mobile) {
          toggleSelectedFactor(PillarName.CLARITY);
          setStepIndex(2);
        }
      },
      placement: "bottom",
      target: mobile ? ".bhi-factor-dropdown" : ".bhi-factors-banner"
    },
    {
      // measures
      callback: () => {
        resetSelectedFactors();
        setStepIndex(3);
      },
      placement: "top",
      target: ".subfactor-bar-graph"
    },
    {
      // tree
      placement: "top",
      target: ".bhi-tree"
    },
    {
      // switch views
      event: "index-switch-bhi-chart",
      placement: "top",
      hideFooter: true,
      target: ".bhi-chart-switch-button",
      spotlightClicks: true
    },
    {
      // data views
      callback: () => {
        setBhiChartType(BHIChartType.Tree);
        setStepIndex(6);
      },
      placement: "top",
      target: ".bhi-radar"
    },
    {
      // rounds
      placement: "bottom",
      target: ".cycle-select-banner",
      spotlightClicks: true
    }
  ];

  const newUserTour: Partial<Step>[] = [
    {
      // factors
      callback: () => {
        if (mobile) {
          toggleSelectedFactor(PillarName.CLARITY);
          setStepIndex(1);
        }
      },
      placement: "bottom",
      target: mobile ? ".bhi-factor-dropdown" : ".bhi-factors-banner"
    },
    {
      // toggle
      event: "index-toggle-factor-select",
      placement: "bottom",
      hideFooter: true,
      target: ".clarity-factor",
      spotlightClicks: true
    },
    {
      // measures
      callback: () => {
        resetSelectedFactors();
        setStepIndex(3);
      },
      placement: "top",
      target: ".subfactor-bar-graph"
    },
    {
      // tree
      placement: "top",
      target: ".bhi-tree"
    },
    {
      // switch views
      event: "index-switch-bhi-chart",
      placement: "top",
      hideFooter: true,
      target: ".bhi-chart-switch-button",
      spotlightClicks: true
    },
    {
      // data views
      callback: () => {
        setBhiChartType(BHIChartType.Tree);
        setStepIndex(6);
      },
      placement: "top",
      target: ".bhi-radar"
    },
    {
      // rounds
      placement: "bottom",
      target: ".cycle-select-banner",
      spotlightClicks: true
    }
  ];

  const newUserTourMobile: Partial<Step>[] = [
    {
      // factors
      callback: () => {
        if (mobile) {
          toggleSelectedFactor(PillarName.CLARITY);
          setStepIndex(1);
        }
      },
      placement: "bottom",
      target: mobile ? ".bhi-factor-dropdown" : ".bhi-factors-banner"
    },

    {
      // measures
      callback: () => {
        resetSelectedFactors();
        setStepIndex(2);
      },
      placement: "top",
      target: ".subfactor-bar-graph"
    },
    {
      // tree
      placement: "top",
      target: ".bhi-tree"
    },
    {
      // switch views
      event: "index-switch-bhi-chart",
      placement: "top",
      hideFooter: true,
      target: ".bhi-chart-switch-button",
      spotlightClicks: true
    },
    {
      // data views
      callback: () => {
        setBhiChartType(BHIChartType.Tree);
        setStepIndex(5);
      },
      placement: "top",
      target: ".bhi-radar"
    },
    {
      // rounds
      placement: "bottom",
      target: ".cycle-select-banner",
      spotlightClicks: true
    }
  ];

  return (
    <>
      {runIndexTourExistingUser && (
        <ProductTour
          productTourName={bhiTourName}
          run={runIndexTourExistingUser}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          scrollOffset={mobile ? 420 : 250}
          stepMetadata={
            mobile ? existingUserTourStepsMobile : existingUserTourSteps
          }
        />
      )}
      {runIndexTourNewUser && (
        <ProductTour
          productTourName={bhiTourName}
          run={runIndexTourNewUser}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          scrollOffset={mobile ? 420 : 250}
          stepMetadata={mobile ? newUserTourMobile : newUserTour}
        />
      )}
    </>
  );
};

export default BhiProductTour;
