import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as ContactInformationImage } from "assets/contact-information.svg";
import { ReactComponent as EmailIcon } from "assets/email.svg";
import { ReactComponent as TextIcon } from "assets/text.svg";
import { Ruler, StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";

interface ContactInformationProps {
  email: string;
  phone?: string;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  email,
  phone
}: ContactInformationProps) => {
  return (
    <StyledGrid
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      container
      direction="column"
      item
      padding={spacingDefaults.large}
    >
      <ContactInformationImage />

      <Box
        component="h2"
        fontSize="20px"
        fontWeight="400"
        lineHeight="24px"
        margin={`${spacingDefaults.normal} 0 ${spacingDefaults.large} 0`}
        maxWidth="250px"
      >
        Is your contact information correct?
      </Box>

      <Ruler />

      <StyledGrid
        alignItems="center"
        container
        marginTop={spacingDefaults.large}
      >
        <EmailIcon />

        <Box
          component="span"
          fontSize={fontSize.small}
          fontWeight="400"
          lineHeight="15px"
          marginLeft={spacingDefaults.small}
        >
          {email}
        </Box>
      </StyledGrid>

      <StyledGrid
        alignItems="center"
        container
        margin={`${spacingDefaults.normal} 0 ${spacingDefaults.large} 0`}
      >
        <TextIcon />

        <Box
          component="span"
          fontSize={fontSize.small}
          fontWeight="400"
          lineHeight="15px"
          marginLeft={spacingDefaults.small}
        >
          {phone ? phone : <em>No phone number provided</em>}
        </Box>
      </StyledGrid>

      <StyledGrid container justifyContent="center">
        <Link to={routesConfig.profile.path}>
          <Box
            color={color.DARKGRAY}
            component="span"
            fontSize="12px"
            fontWeight="400"
            lineHeight="14.4px"
            style={{ textDecoration: "underline" }}
          >
            Update Information
          </Box>
        </Link>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ContactInformation;
