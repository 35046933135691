import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

import { AssessmentCard, Button, StyledGrid, Timer } from "components/simple";
import { breakpoints, fontFamily, fontSize } from "style/constants";

const StyledP = styled.p`
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.medium};
  line-height: 33px;
  margin: 0 10%;
  padding: 10px;
  text-align: left;
  text-indent: 50px;
`;

interface TextQuestionProps {
  activeQuestion: number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  callback: React.Dispatch<any>;
  passages: string[];
  prompt: string;
  time: number;
}

const PassageQuestion: React.FC<TextQuestionProps> = ({
  activeQuestion,
  callback,
  passages,
  prompt,
  time
}: TextQuestionProps) => {
  const [timesUp, setTimesUp] = useState(false);

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const skipToNextQuestion = (): void => {
    callback({});
  };

  if (timesUp) {
    skipToNextQuestion();
  }

  return (
    <AssessmentCard
      subtext={prompt}
      timer={
        time && (
          <Timer
            seconds={time}
            onDone={setTimesUp}
            activeQuestion={activeQuestion}
          />
        )
      }
    >
      <StyledGrid
        item
        width="100%"
        marginBottom="3rem"
        marginTop="1rem"
        hidden={timesUp}
      >
        {passages.map((text, index) => (
          <StyledP key={index}>{text}</StyledP>
        ))}
      </StyledGrid>
      <p>
        Click the button below when you are done reading. You will not be able
        to come back.
      </p>
      <StyledGrid item marginBottom="1.6875rem">
        <Button
          data-testid="next-btn"
          wide={!mobile}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={skipToNextQuestion}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default PassageQuestion;
