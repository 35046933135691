import React, { useEffect, useState } from "react";
import { memo } from "react";
import { useDrop } from "react-dnd";
import { detectMob } from "App";

export interface CategoryProps {
  id: string;
  name: string;
  accepts: string;
  onDrop: (item: any) => void;
  answer: string;
  cardBgColor: string;
  cardBGBorderColor: string;
  moveToNext: (flag: boolean) => void;
  isCorrect: (flag: boolean) => void;
  updateCategoryBackground: (isCorrect: boolean) => void;
  onClick: () => void;
  isAnswered: boolean;
}

export const Category: React.FC<CategoryProps> = memo(function Category({
  id,
  name,
  accepts,
  onDrop,
  answer,
  cardBgColor,
  cardBGBorderColor,
  moveToNext,
  isCorrect,
  updateCategoryBackground,
  onClick,
  isAnswered
}) {
  // re render the component when new props are passed
  useEffect(() => {
    setItem("");
    setIsDropped(false);
    isCorrect(false);
    setBgColor(cardBgColor);
    setBorderColor(cardBGBorderColor);
  }, [name, id]);
  const isMobile = detectMob();
  const [itemDropped, setItem] = useState<string>("");
  const [isDropped, setIsDropped] = useState<boolean>(false);
  const [bgColor, setBgColor] = useState<string>("#FFF");
  const [borderColor, setBorderColor] = useState<string>("1px dashed #4F4C4D");

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: accepts,
    drop: (item: any) => {
      performOnDrop(item);
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const performOnDrop = (item: any) => {
    setItem(item.name);
    onDrop(item);
    setIsDropped(true);
    if (name === answer) {
      updateCategoryBackground(true);
      moveToNext(true);
      isCorrect(true);
    } else {
      updateCategoryBackground(false);
      isCorrect(false);
    }
  };

  const isActive = isOver && canDrop;
  const staticStyle = {
    display: "flex",
    width: "9.9375rem",
    height: "10.5rem",
    padding: "2rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
    borderRadius: "0.25rem",
    border: cardBGBorderColor,
    background: cardBgColor
  };

  const whileDrag = {
    ...staticStyle,
    borderRadius: "0.25rem",
    border: "1px solid var(--primary-300, #CDEBFA)",
    background: "var(--primary-100, #F2FAFE)"
  };

  return (
    <div
      ref={drop}
      style={canDrop ? { ...whileDrag } : { ...staticStyle }}
      onClick={() => onClick()}
    >
      {/* check if item dropped and update the display of the category with the item dropped */}
      {/* make itemdropped as a small box inside */}
      <>
        {(isDropped || isAnswered) && !canDrop ? (
          <div
            className="category_box"
            style={{
              display: "flex",
              width: "8.9375rem",
              height: "8.24756rem",
              padding: "0.50175rem 0.75263rem 0.75263rem 0.75263rem",
              flexDirection: "column",
              alignItems: "center",
              gap: "0.75263rem",
              flexShrink: 0,
              background: "white",
              justifyContent: "center"
            }}
          >
            <p
              style={{
                width: "4.875rem",
                flexShrink: 0,
                textAlign: "center",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "0.875rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "1.3125rem"
              }}
            >
              {name}
            </p>
          </div>
        ) : (
          <p
            style={{
              width: "4.875rem",
              flexShrink: 0,
              textAlign: "center",
              color: "#4F4C4D",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "0.875rem",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "1.3125rem"
            }}
          >
            {name}
          </p>
        )}
      </>
    </div>
  );
});
