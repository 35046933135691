import { ActionType } from "typesafe-actions";

import * as actions from "./actions";

export interface State {
  forNativeExperience: boolean;
  loader: {
    show: boolean;
  };
  notifier: {
    show: boolean;
    type: NotificationType;
    title: string;
    description: string;
  };
  user: Partial<User> | null;
  subdomain: Subdomain | null;
}

export enum NotificationType {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error"
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  eligibleForStudy: boolean | null;
  organization: {
    name: string;
  };
  waitlisted: boolean;
  enrollmentConsent: boolean | null;
  forcedConsent: boolean;
  profileImageKey: string | null;
  active: boolean;
  completedTeaser: boolean;
}

export enum UserStatus {
  UNKNOWN = "unknown",
  DISQUALIFIED = "disqualified",
  WAITLISTED = "waitlisted",
  QUALIFIED_AND_UNCONSENTED = "qualified_and_unconsented",
  QUALIFIED = "qualified",
  QUALIFIED_AND_CONSENTED = "qualified_and_consented",
  INACTIVE = "inactive"
}

export interface Subdomain {
  name: string;
  defaultOrg: string;
}

export const Subdomains = {
  DOD: { name: "dod", defaultOrg: "1ef851f5-aba9-47bb-95ee-a98a63462edc" }
};

export type Action = ActionType<typeof actions>;
