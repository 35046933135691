import { useQuery } from "@apollo/react-hooks";

import { GetProductTour as GetCoachMarks } from "graphql/productTours/productTours.gql";
import {
  GetProductTourResponse as GetCoachMarksResponse,
  UserProductToursData as CoachMarksData
} from "./useProductTour";

const useCoachMarks = (name: String): CoachMarksData => {
  const variables = { input: { name } };

  const { data } = useQuery<GetCoachMarksResponse>(GetCoachMarks, {
    variables
  });

  return { productTour: data?.getProductTour };
};

export default useCoachMarks;
