import styled from "styled-components";

const verticalSpacing = "5.625rem";
const verticalSpacingMobile = "4.5625rem";
const rightPathWidth = "53px";
const singleColumnBreakpoint = "1397px";
const horizontalSpacing = "5.8125rem";

export const CourseContainer = styled.ol`
  align-self: center;
  column-gap: ${horizontalSpacing};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: ${verticalSpacing} ${horizontalSpacing};
  max-width: 1500px;
  padding: 0;
  row-gap: ${verticalSpacing};

  @media (max-width: ${singleColumnBreakpoint}) {
    flex-direction: column;
    row-gap: ${verticalSpacingMobile};
  }
`;

export const Course = styled.li`
  position: relative;

  & > svg {
    left: calc(100% + (${horizontalSpacing} - ${rightPathWidth}) / 2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:first-child:last-child > svg,
  &:nth-last-child(-n + 3):first-child ~ &:last-child > svg {
    display: none;
  }

  // last course in row
  &:nth-child(3n) > svg {
    bottom: -1rem;
    left: unset;
    position: absolute;
    right: 50%;
    top: unset;
    transform: translateY(100%);

    &:first-of-type {
      display: none;
    }
  }

  // last course
  &:nth-last-child(4) > svg {
    display: none;
  }

  @media (max-width: ${singleColumnBreakpoint}) {
    & > svg,
    &:nth-child(3n) > svg {
      bottom: calc(
        ((${verticalSpacingMobile} - ${rightPathWidth}) / -2) - 34px
      );
      left: 50%;
      position: absolute;
      top: unset;
      transform: translateX(-50%) rotateZ(90deg);

      &:first-of-type {
        display: initial;
      }

      &:nth-of-type(2) {
        display: none;
      }
    }

    &:last-child > svg {
      display: none;
    }
  }
`;
