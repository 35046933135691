import styled from "styled-components";

import { color, fontSize, spacingDefaults } from "style/constants";

export const NotificationBody = styled.p`
  color: ${color.DARKERGRAY};
  font-weight: 300;
`;

export const NotificationCard = styled.div`
  p {
    margin: 0;
  }
`;

export const NotificationFooter = styled.p`
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.xsmall};
  font-style: italic;
  margin-top: ${spacingDefaults.normal} !important;
`;

export const NotificationTitle = styled.p`
  color: ${color.BLACK};
`;
