import React from "react";

import { Button } from "components/simple";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useAppState } from "lib/twilio-video/state";
import { color } from "style/constants";

export default function EndCallButton(props: { className?: string }) {
  const { room } = useVideoContext();
  const { setShouldRedirectOnDisconnect } = useAppState();

  return (
    <Button
      squared
      warning
      background={color.RED}
      textColor={color.WHITE}
      onClick={() => {
        setShouldRedirectOnDisconnect(true);
        room!.disconnect();
      }}
      data-cy-disconnect
    >
      Leave Call
    </Button>
  );
}
