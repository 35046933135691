import { gql } from "apollo-boost";

export const StartUserAssessments = gql`
  mutation StartUserAssessments {
    startUserAssessments {
      assessmentsStarted
    }
  }
`;

export const CurrentAssessment = gql`
  query {
    getCurrentAssessment {
      id
      lastAssessment
      userAnswers {
        answerData
        questionId
        assessmentQuestion {
          order
        }
      }
      assessment {
        displayName
        assessmentGrouping {
          name
          order
          userAssessments {
            completed
            name
          }
          assessmentPart {
            numberOfGroups
            order
          }
        }
        hideBack
        questions {
          id
          internalName
          questionType
          questionData {
            ... on DigitSymbolQuestion {
              time
              subtext
              skip
              prompt
              digitSymbols
              isPractice
            }
            ... on DropdownAndTextQuestion {
              time
              subtext
              skip
              prompt
              dropdownAndTextChoices: choices
              textDescription
            }
            ... on DropdownQuestion {
              time
              subtext
              skip
              prompt
              dropdownChoices: choices
            }
            ... on FlashWordsQuestion {
              time
              subtext
              skip
              wordLists
              wordInterval
            }
            ... on FreeResponseQuestion {
              time
              subtext
              skip
              prompt
            }
            ... on HourMinuteQuestion {
              time
              subtext
              skip
              prompt
              hourMinuteHourChoices: hourChoices
              hourMinuteMinuteChoices: minuteChoices
              label
              secondaryLabel
            }
            ... on ImageThemesQuestion {
              time
              skip
              images
              subtext
            }
            ... on InformationQuestion {
              time
              subtext
              skip
              image
              text
            }
            ... on LikertQuestion {
              time
              subtext
              skip
              prompt
              likertResponses
              reverse
            }
            ... on MultipleResponseQuestion {
              time
              subtext
              skip
              prompt
              multiResponseChoices
            }
            ... on MultipleChoiceQuestion {
              time
              subtext
              skip
              prompt
              choices
            }
            ... on NumericalScaleQuestion {
              time
              subtext
              skip
              label
              max
              prompt
            }
            ... on PassageQuestion {
              time
              subtext
              skip
              prompt
              passages
            }
            ... on ProverbQuestion {
              time
              subtext
              skip
              proverbList
            }
            ... on TextResponseQuestion {
              additionalInfo
              time
              subtext
              skip
              label
              prompt
              type
            }
            ... on WordCallbackQuestion {
              time
              skip
              prompt
              subtext
            }
          }
        }
      }
    }
  }
`;

export const GroupQuery = gql`
  query getCurrentGroup {
    getCurrentGroup {
      status
      assessment {
        displayName
        order
        timeToComplete
        assessmentGrouping {
          name
        }
      }
    }
  }
`;

export const PreambleQuery = gql`
  query {
    getCurrentAssessment {
      assessment {
        displayName
        preambleImage
        videoUrl
        timeLabel
        preambleImageAltText
        preambleText
      }
    }
  }
`;

export const SubmitAssessment = gql`
  mutation SaveAnswers($input: UserAnswersInput!) {
    saveAnswers(input: $input) {
      records {
        userAssessmentId
      }
    }
  }
`;

export const GetAllCyclesScores = gql`
  query {
    participantAssessmentCycles {
      cycleNumber
      status
      cycleCompletionDate
      pillarScores {
        value
        pillarName
        userAssessments {
          name
          scores {
            scoreName
            scoreType
            value
          }
        }
      }
    }
  }
`;

export const getUserAssessmentNotes = gql`
  query fetchUserAssessmentNotes($input: UserAssessmentCycleNotesInput!) {
    fetchUserAssessmentNotes(input: $input) {
      notes
      dateAdded
    }
  }
`;
