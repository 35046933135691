import { gql } from "apollo-boost";

export const GetUserCurrentRank = gql`
  query currentUserRank {
    currentUserRank {
      rank {
        level
        rank
        rankImageKey
      }
    }
  }
`;

export const GetRanks = gql`
  query ranks {
    ranks {
      id
      rank
      level
      rankImageKey
      order
    }
  }
`;
