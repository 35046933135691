/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import { Link } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { ReactComponent as Lock } from "assets/lock.svg";
import defaultImage from "assets/daily-life.svg";
import { color, spacingDefaults } from "style/constants";
import {
  AssessmentCard,
  StyledGrid,
  Button,
  BackButton
} from "components/simple";
import {
  AssessmentTime,
  AssessmentTitle,
  CheckWrapper,
  GroupTitle,
  LockWrapper
} from "./styled";
import { UserAssessment } from "graphql/types/User";

interface Props {
  userAssessments: Partial<UserAssessment>[];
  groupTitle: string;
}

const Group: React.FC<Props> = ({ userAssessments, groupTitle }: Props) => {
  if (!userAssessments) return null;
  userAssessments.sort((a: any, b: any) => {
    return a.assessment.order - b.assessment.order;
  });

  const image = {
    src: "",
    alt:
      "A woman walks to her left outdoors. A tree about twice her height is beside her on the right"
  };
  const { src, alt } = image;

  return (
    <>
      <BackButton location="/dashboard" />
      <StyledGrid container item justifyContent="center">
        <AssessmentCard>
          <StyledGrid
            container
            item
            justifyContent="center"
            marginBottom="auto"
            marginTop="auto"
          >
            <Hidden lgUp>
              <StyledGrid item textAlign="center">
                <GroupTitle>{groupTitle}</GroupTitle>
              </StyledGrid>
            </Hidden>
            <StyledGrid item marginBottom="auto" marginTop="auto" lg={6}>
              <img alt={alt} src={src || defaultImage} />
            </StyledGrid>
            <StyledGrid
              container
              direction="column"
              item
              itemMargin="5px"
              marginBottom="auto"
              marginTop="auto"
              maxWidth="342px"
              mobileMarginTop={spacingDefaults.normal}
              lg={6}
            >
              <Hidden mdDown>
                <StyledGrid item textAlign="left">
                  <GroupTitle>{groupTitle}</GroupTitle>
                </StyledGrid>
              </Hidden>
              {userAssessments.map((assessment: any) => (
                <StyledGrid
                  border={`1px solid ${color.CARDGRAY}`}
                  borderRadius="8px"
                  container
                  item
                  justifyContent="space-between"
                  key={assessment.assessment.displayName}
                  padding={spacingDefaults.normal}
                >
                  <StyledGrid item textAlign="left">
                    <AssessmentTitle>
                      {assessment.assessment.displayName}
                    </AssessmentTitle>
                    <AssessmentTime>
                      {assessment.assessment.timeToComplete}
                    </AssessmentTime>
                  </StyledGrid>
                  <StyledGrid alignSelf="center" item>
                    {assessment.status === "next" && (
                      <Link to="/assessments/start">
                        <Button compressed width="90px">
                          Begin
                        </Button>
                      </Link>
                    )}
                    {assessment.status === "completed" && (
                      <CheckWrapper>
                        <CheckCircleIcon data-testid="check-icon" />
                      </CheckWrapper>
                    )}
                    {assessment.status === "locked" && (
                      <LockWrapper>
                        <Lock data-testid="lock-icon" />
                      </LockWrapper>
                    )}
                  </StyledGrid>
                </StyledGrid>
              ))}
            </StyledGrid>
          </StyledGrid>
        </AssessmentCard>
      </StyledGrid>
    </>
  );
};

export default Group;
