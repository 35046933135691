import { createTheme } from "@material-ui/core/styles";

import overrides from "./overrides";

import { breakpoints, color, fontFamily, fontSize } from "style/constants";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  typography: {
    fontFamily: fontFamily.primary,
    h1: {
      fontFamily: fontFamily.secondary,
      fontSize: fontSize.xlarge
    },
    h2: {
      fontFamily: fontFamily.secondary,
      fontSize: fontSize.header
    },
    h3: {
      fontFamily: fontFamily.secondary,
      fontSize: fontSize.large
    },
    h4: {
      fontFamily: fontFamily.secondary,
      fontSize: fontSize.medium
    },
    h5: {
      fontFamily: fontFamily.secondary,
      fontSize: fontSize.normal
    },
    body1: {
      fontFamily: fontFamily.primary,
      fontSize: fontSize.normal
    },
    body2: {
      fontFamily: fontFamily.primary,
      fontSize: fontSize.normal
    }
  },
  palette: {
    primary: {
      main: color.WHITE
    },
    secondary: {
      main: color.BLUE
    },
    error: {
      main: color.RED
    },
    background: {
      default: color.WHITE
    }
  },
  overrides
});

export default theme;
