import { useMediaQuery } from "@material-ui/core";
import React from "react";

import { breakpoints } from "style/constants";
import { DetailedCourseProgress } from "../types";
import DesktopCourseBanner from "./Desktop";
import MobileCourseBanner from "./Mobile";

interface CourseBannerProps {
  courseProgress: DetailedCourseProgress;
  disabled?: boolean;
}

const CourseBanner: React.FC<CourseBannerProps> = ({
  courseProgress,
  disabled = false
}: CourseBannerProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  if (mobile) {
    return (
      <MobileCourseBanner courseProgress={courseProgress} disabled={disabled} />
    );
  } else {
    return (
      <DesktopCourseBanner
        courseProgress={courseProgress}
        disabled={disabled}
      />
    );
  }
};

export default CourseBanner;
