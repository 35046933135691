import { Hidden, IconButton, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { ReactComponent as CoachingCallImage } from "assets/coaching-call.svg";
import { Button, StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  iconSizes,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";

interface CoachingSessionReflectionBannerProps {
  dismiss(): Promise<void>;
}

const CoachingSessionReflectionBanner: React.FC<CoachingSessionReflectionBannerProps> = ({
  dismiss
}: CoachingSessionReflectionBannerProps) => {
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.GREEN}
      borderRadius="8px"
      container
      height="96px"
      overflow={mobile ? "hidden" : undefined}
      position="relative"
      onClick={
        mobile
          ? () => history.push(routesConfig.surveys.survey.path)
          : undefined
      }
    >
      <Hidden mdDown>
        <StyledGrid item height="96px" position="relative" width="200px">
          <CoachingCallImage
            height={160}
            style={{
              position: "absolute",
              top: "-30px"
            }}
            width={192}
          />
        </StyledGrid>

        <StyledGrid container item xs>
          <StyledGrid item marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.WHITE}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.large}
              fontWeight="600"
            >
              How was your coaching call?
            </StyledP>
          </StyledGrid>

          <StyledP
            color={color.WHITE}
            fontSize={fontSize.normal}
            fontWeight="400"
          >
            Take 2 minutes to reflect on your coaching call and provide
            feedback.
          </StyledP>
        </StyledGrid>

        <StyledGrid item marginRight="50px">
          <Link to={routesConfig.surveys.survey.path}>
            <Button aria-label="go-to-survey" inverted longText width="200px">
              Submit Reflection
            </Button>
          </Link>
        </StyledGrid>
      </Hidden>

      <Hidden lgUp>
        <StyledGrid item height="96px" position="relative" width="135px">
          <CoachingCallImage
            height={150}
            style={{
              left: "-40px",
              position: "absolute",
              top: "5px"
            }}
            width={180}
          />
        </StyledGrid>

        <StyledGrid container item xs>
          <StyledGrid item marginBottom="3px">
            <StyledP
              color={color.WHITE}
              fontSize={fontSize.normal}
              fontWeight="400"
              lineHeight={fontSize.large}
              width="200px"
            >
              Take 2 minutes to reflect on your coaching call.
            </StyledP>
          </StyledGrid>

          <StyledP
            color={color.WHITE}
            fontSize={fontSize.normal}
            fontWeight="600"
            lineHeight={fontSize.large}
          >
            Submit Reflection &gt;
          </StyledP>
        </StyledGrid>
      </Hidden>

      <StyledGrid item position="absolute" right="5px" top="5px">
        <IconButton
          aria-label="close-notification"
          onClick={event => {
            event.stopPropagation();
            dismiss();
          }}
        >
          <CloseIcon
            stroke={color.WHITE}
            height={iconSizes.smaller}
            width={iconSizes.smaller}
          />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default CoachingSessionReflectionBanner;
