import React from "react";
import { Box } from "@material-ui/core";
import DownArrow from "@material-ui/icons/KeyboardArrowDown";

import TreeHeaderImg from "assets/dashboard-header.svg";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";
import { StyledGrid } from "components/simple";

interface TreeHeaderProps {
  header?: string;
  subTitle?: string;
  topHeader?: string;
  addArrowButton?: boolean;
}

const TreeHeader: React.FC<TreeHeaderProps> = ({
  topHeader,
  header,
  subTitle,
  addArrowButton
}) => {
  return (
    <StyledGrid
      alignItems="center"
      backgroundImage={TreeHeaderImg}
      container
      direction="column"
      justifyContent="space-between"
      minHeight="200px"
      textAlign="center"
    >
      {topHeader && (
        <StyledGrid item marginTop={spacingDefaults.xlarge}>
          <Box fontSize={fontSize.normal} fontFamily={fontFamily.primary}>
            {topHeader}
          </Box>
        </StyledGrid>
      )}
      {(addArrowButton || header || subTitle) && (
        <StyledGrid item>
          {addArrowButton && (
            <StyledGrid
              alignItems="center"
              backgroundColor={color.WHITE}
              borderRadius="50%"
              boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
              centerContent
              container
              height="30px"
              margin={`${spacingDefaults.xsmall} auto`}
              onClick={() => {}}
              style={{ cursor: "pointer" }}
              width="30px"
            >
              <DownArrow />
            </StyledGrid>
          )}
          {header && (
            <Box
              fontSize={fontSize.normal}
              fontFamily={fontFamily.primary}
              fontWeight="bold"
            >
              {header}
            </Box>
          )}
          {subTitle && (
            <Box fontSize={fontSize.normal} fontFamily={fontFamily.primary}>
              {subTitle}
            </Box>
          )}
        </StyledGrid>
      )}
    </StyledGrid>
  );
};
export default TreeHeader;
