import React from "react";
import { StyledP } from "components/simple";
import { spacingDefaults } from "style/constants";
import TimeSlots from "./TimeSlots";
import { AvailableTime } from "../..";

interface Props {
  availableTimes: AvailableTime[];
  selectedTime: Date | undefined;
  setSelectedTime: React.Dispatch<React.SetStateAction<Date | undefined>>;
}

const Availability: React.FC<Props> = ({
  availableTimes,
  selectedTime,
  setSelectedTime
}) => {
  return (
    <>
      {availableTimes && availableTimes?.length > 0 ? (
        <>
          {availableTimes.map((availableTime, idx: number) => (
            <TimeSlots
              key={idx}
              timeOfDay={availableTime.timeOfDay}
              times={availableTime.times}
              selectedTime={selectedTime}
              onClick={time => setSelectedTime(time)}
            />
          ))}
        </>
      ) : (
        // If no times are available for current day, then this text is rendered instead
        <StyledP textAlign="center" marginbottom={spacingDefaults.large}>
          No Availability. <br />
          Please select another day.
        </StyledP>
      )}
    </>
  );
};

export default Availability;
