import { Modal, useMediaQuery } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import React, { useState } from "react";

import { ReactComponent as CloseButton } from "assets/close.svg";
import { ReactComponent as UpArrow } from "assets/up_arrow.svg";
import { Button, StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";
import { Cycle } from "../../../types";
import { Body, Index, Title } from "../../styled";
import ScoreHistoryGraph from "../ScoreHistoryGraph";

function isBHI(p: any): boolean {
  return p.pillarName === "index";
}

interface ScoreHistoryProps {
  allCycles: Cycle[];
  currentScore: number;
  selectedCycle: number;
}

const ScoreHistory: React.FC<ScoreHistoryProps> = ({
  allCycles,
  currentScore,
  selectedCycle
}: ScoreHistoryProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [openModal, setOpenModal] = useState(false);

  const cycleNumber = allCycles[selectedCycle].cycleNumber;
  const hasMoreThanOneCycle = allCycles.length > 1;

  function closeModal(): void {
    setOpenModal(false);
  }

  function getScoreIncrease(): string | null {
    if (!hasMoreThanOneCycle || selectedCycle === 0) return null;

    const latestCycle = allCycles.find(
      (c: any) => c.cycleNumber === cycleNumber
    );
    const previousCycle = allCycles.find(
      (c: any) => c.cycleNumber === cycleNumber - 1
    );
    const latestBHI = latestCycle && latestCycle.pillarScores.find(isBHI);
    const previousBHI = previousCycle && previousCycle.pillarScores.find(isBHI);

    const latestBHIValue = latestBHI ? latestBHI.value : 0;
    const previousBHIValue = previousBHI ? previousBHI.value : 0;

    if (latestBHIValue > previousBHIValue) {
      return (latestBHIValue - previousBHIValue).toFixed(2);
    } else {
      return null;
    }
  }

  const scoreIncreaseValue = getScoreIncrease();

  function getCycleText(): string {
    if (!hasMoreThanOneCycle) {
      return "This is your snapshot, a composite of the individual BrainHealth assessment scores shown below.";
    }

    if (scoreIncreaseValue) {
      return `Great job! Your index has increased by ${scoreIncreaseValue} since your last assessment.`;
    } else {
      return "Great job! Practice your Cognition challenges to increase your Index for next round.";
    }
  }

  return (
    <>
      <StyledGrid
        alignItems="center"
        container
        direction="column"
        item
        justifyContent="center"
      >
        <StyledGrid item textAlign="center">
          <Title>Your BrainHealth Index</Title>
          <Body>{getCycleText()}</Body>
          {!mobile && !hasMoreThanOneCycle && (
            <StyledGrid>
              <Index>{currentScore.toFixed(1)}</Index>
            </StyledGrid>
          )}
        </StyledGrid>
        {mobile && (
          <StyledGrid item>
            <StyledGrid container justifyContent="center" alignItems="center">
              <StyledGrid>
                <Index>{currentScore.toFixed(1)}</Index>
              </StyledGrid>
              {hasMoreThanOneCycle && (
                <StyledGrid margin={spacingDefaults.normal}>
                  <Button
                    squared
                    textColor={color.DARKBLUE}
                    background={color.LIGHTERBLUE}
                    onClick={() => setOpenModal(true)}
                  >
                    <StyledGrid container direction="column">
                      {scoreIncreaseValue && (
                        <div>
                          <UpArrow />
                          <span> {scoreIncreaseValue}</span>
                        </div>
                      )}
                      <div>View History</div>
                    </StyledGrid>
                  </Button>
                </StyledGrid>
              )}
            </StyledGrid>
          </StyledGrid>
        )}
        {!mobile && hasMoreThanOneCycle && (
          <StyledGrid item>
            <ScoreHistoryGraph
              cycles={allCycles}
              selectedCycle={selectedCycle}
            />
          </StyledGrid>
        )}
      </StyledGrid>
      {openModal && (
        <Modal
          aria-label="Score History Modal"
          open={true}
          onClose={closeModal}
        >
          <>
            <StyledGrid
              container
              absoluteCenter
              backgroundColor={color.WHITE}
              borderRadius="8px"
              direction="column"
              maxWidth="50%"
              mobileMaxWidth="98%"
              padding={spacingDefaults.normal}
            >
              <StyledGrid item alignSelf="flex-end" position="fixed">
                <IconButton aria-label="close-modal" onClick={closeModal}>
                  <CloseButton strokeWidth="1px" height="15px" width="15px" />
                </IconButton>
              </StyledGrid>
              <StyledGrid container item height="100%" width="100%">
                <StyledGrid item width="100%" textAlign="center">
                  <Title>Your BrainHealth Index</Title>
                </StyledGrid>
                <ScoreHistoryGraph
                  cycles={allCycles}
                  selectedCycle={selectedCycle}
                />
              </StyledGrid>
            </StyledGrid>
          </>
        </Modal>
      )}
    </>
  );
};

export default ScoreHistory;
