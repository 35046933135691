import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  Filler,
  PointElement,
  LineElement,
  RadialLinearScale,
  ChartOptions,
  ChartData,
  Plugin
} from "chart.js";
import { Radar } from "react-chartjs-2";

import { BHIChartProps } from ".";
import { LabelContainer, RadarLabel } from "./styled";
import { addOpacity, color } from "style/constants";
import { PillarName } from "components/pages/Index/CycleSummary/types";

ChartJS.register(Filler, PointElement, LineElement, RadialLinearScale);

export const options: ChartOptions<"radar"> = {
  animation: {
    duration: 500
  },
  backgroundColor: "transparent",
  elements: {
    line: {
      borderWidth: 0
    },
    point: {
      radius: 7.5
    }
  },
  plugins: { tooltip: { enabled: false } },
  scales: {
    r: {
      grid: {
        display: false
      },
      max: 100,
      min: 0,
      pointLabels: {
        font: {
          size: 0.1
        }
      },
      ticks: {
        display: false
      }
    }
  }
};

const multiColorPointsPlugin: Plugin<"radar"> = {
  id: "custom_canvas_background_color",
  beforeDraw: chart => {
    const points = chart.getDatasetMeta(0).data;
    const [emotionalBalancePoint, connectednessPoint, clarityPoint] = points;
    clarityPoint.options.backgroundColor = color.PURPLE;
    emotionalBalancePoint.options.backgroundColor = color.FUSCHIA;
    connectednessPoint.options.backgroundColor = color.GREEN;
  }
};

const conicGradientBackgroundPlugin: Plugin<"radar"> = {
  id: "custom_canvas_background_color",
  beforeDraw: chart => {
    const points = chart.getDatasetMeta(0).data;
    const ctx = chart.canvas.getContext("2d");
    if (ctx) {
      const [emotionalBalancePoint, connectednessPoint, clarityPoint] = points;
      const region = new Path2D();
      region.moveTo(clarityPoint.x, clarityPoint.y);
      region.lineTo(emotionalBalancePoint.x, emotionalBalancePoint.y);
      region.lineTo(connectednessPoint.x, connectednessPoint.y);
      region.lineTo(clarityPoint.x, clarityPoint.y);
      region.closePath();

      /* Firefox and Safari implement the start position
         for createConicGradient differently. Utlilizing
         browser duck-typing in order to determine if browser
         is Firefox or Safari and updating the offset accordingly
         https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers/9851769#9851769

         Also need to turn off ts checking for browser duck-typing strategies
         with eslint-disable-next-line @typescript-eslint/ban-ts-comment and @ts-ignore
      */

      let shiftOffset = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isFirefox = typeof InstallTrigger !== "undefined";
      const isSafari =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !window["safari"] ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (typeof safari !== "undefined" && safari.pushNotification)
        );
      const ua = navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

      if (isFirefox || isSafari || iOSSafari) {
        shiftOffset = true;
      }
      const angleStart = shiftOffset ? 120 : 30;

      const gradient = (ctx as any).createConicGradient(
        angleStart * (Math.PI / 180),
        (chart.boxes[0] as any).xCenter,
        (chart.boxes[0] as any).yCenter
      );
      gradient.addColorStop(0, "rgba(0, 150, 136, 0.3)");
      gradient.addColorStop(1 / 3, "rgba(123, 31, 162, 0.3)");
      gradient.addColorStop(2 / 3, "rgba(233, 30, 99, 0.3)");
      gradient.addColorStop(1, "rgba(0, 150, 136, 0.3)");

      ctx.fillStyle = gradient;
      ctx.fill(region, "nonzero");
      ctx.restore();
    }
  }
};

export const getFactorColor = (factor?: PillarName): color => {
  switch (factor) {
    case PillarName.EMOTIONAL_BALANCE:
      return color.FUSCHIA;
    case PillarName.CONNECTEDNESS:
      return color.GREEN;
    case PillarName.CLARITY:
      return color.PURPLE;
    case PillarName.NEW_INDEX:
      return color.YELLOW;
    case PillarName.EMERGING_FACTOR:
      return color.YELLOW;
    default:
      return color.GRAY_PROGESS_BAR;
  }
};

const CustomRadar: React.FC<BHIChartProps> = ({
  scores,
  labels,
  origin,
  selectedFactor
}) => {
  const dataPoints = scores.map(item => item * 100);

  const data: ChartData<"radar"> = {
    labels: labels,
    datasets: [
      {
        data: dataPoints,
        ...(selectedFactor && {
          backgroundColor: addOpacity(getFactorColor(selectedFactor), 0.4),
          pointBackgroundColor: getFactorColor(selectedFactor)
        })
      }
    ]
  };
  return (
    <>
      {labels.map((label, index) => {
        const angle = (360 / labels.length) * index;
        const flipText = angle >= 90 && angle <= 270;
        return (
          <LabelContainer
            angle={angle}
            originX={origin?.x ?? 0}
            originY={origin?.y ?? 0}
            key={index}
          >
            <RadarLabel flipText={flipText}>{label}</RadarLabel>
          </LabelContainer>
        );
      })}
      <Radar
        className="bhi-radar"
        data={data}
        options={options}
        plugins={[conicGradientBackgroundPlugin, multiColorPointsPlugin]}
      />
    </>
  );
};

export default CustomRadar;
