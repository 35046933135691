import React from "react";
import Grid, { GridProps } from "@material-ui/core/Grid";
import styled from "styled-components";

import { absoluteCenter, centerContent, setItemMargin } from "./styled";
import { breakpoints, color } from "style/constants";

interface CustomProps {
  absoluteCenter?: boolean;
  alignSelf?: string;
  backgroundColor?: color;
  backgroundImage?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  border?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  borderTop?: string;
  borderTopRadius?: string;
  borderRadius?: string;
  bottom?: string;
  boxShadow?: string;
  centerContent?: boolean;
  display?: string;
  ellipsis?: boolean;
  flexDirection?: string;
  fillRemainingHeight?: boolean;
  filter?: string;
  height?: string;
  itemMargin?: string;
  left?: string;
  lineClamp?: string;
  lineHeight?: string;
  margin?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  maxWidth?: string;
  maxHeight?: string;
  minHeight?: string;
  minWidth?: string;
  mobileMarginBottom?: string;
  mobileMarginLeft?: string;
  mobileMarginTop?: string;
  mobileMaxWidth?: string;
  mobilePadding?: string;
  mobileTop?: string;
  opacity?: string;
  order?: number;
  outline?: string;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  padding?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  pointerEvents?: string;
  position?: string;
  right?: string;
  textAlign?: string;
  top?: string;
  transform?: string;
  transformStyle?: string;
  transition?: string;
  width?: string;
  zIndex?: string;
}

type StyledGridProps = CustomProps & GridProps;

const ellipsisStyle = `
&& {
  overflow: hidden;
  textOverflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
`;

const StyledGrid: React.FC<StyledGridProps> = styled(
  ({
    absoluteCenter,
    alignSelf,
    backgroundImage,
    backgroundPosition,
    backgroundSize,
    backgroundColor,
    border,
    borderBottom,
    borderLeft,
    borderRight,
    borderTop,
    borderTopRadius,
    borderRadius,
    bottom,
    boxShadow,
    centerContent,
    display,
    ellipsis,
    fillRemainingHeight,
    filter,
    flexDirection,
    height,
    itemMargin,
    left,
    lineClamp = "unset",
    lineHeight,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    maxWidth,
    maxHeight,
    minHeight,
    minWidth,
    mobileMarginBottom,
    mobileMarginLeft,
    mobileMarginTop,
    mobileMaxWidth,
    mobilePadding,
    mobileTop,
    opacity,
    order,
    overflow,
    overflowX,
    overflowY,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    pointerEvents,
    position,
    right,
    textAlign,
    top,
    transform,
    transformStyle,
    transition,
    width,
    zIndex,

    ...otherProps
  }: StyledGridProps) => <Grid {...otherProps} />
)`
  && {
    ${props => props.absoluteCenter && absoluteCenter}
    ${props => props.alignSelf && "align-self: " + props.alignSelf}
    ${props =>
      props.backgroundColor && "background-color: " + props.backgroundColor}
    ${props =>
      props.backgroundImage &&
      ` background-image: url(${props.backgroundImage});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      `}
      ${props =>
      props.backgroundPosition &&
      "background-position: " + props.backgroundPosition}
      ${props =>
      props.backgroundSize && "background-size: " + props.backgroundSize}
    ${props => props.border && "border: " + props.border}
    ${props => props.borderBottom && "border-bottom: " + props.borderBottom}
    ${props => props.borderLeft && "border-left: " + props.borderLeft}
    ${props => props.borderRight && "border-right: " + props.borderRight}
    ${props =>
      props.borderTopRadius &&
      "border-top-left-radius: " + props.borderTopRadius}
    ${props =>
      props.borderTopRadius &&
      "border-top-right-radius: " + props.borderTopRadius}
    ${props => props.borderTop && "border-top: " + props.borderTop}
    ${props => props.borderRadius && "border-radius: " + props.borderRadius}
    ${props => props.bottom && "bottom: " + props.bottom}
    ${props => props.boxShadow && "box-shadow: " + props.boxShadow + ";"}
    ${props => props.centerContent && centerContent}
    ${props => props.display && "display: " + props.display}
    ${props =>
      props.ellipsis &&
      `&& {  overflow: hidden;  textOverflow: ellipsis;  display: -webkit-box;  -webkit-line-clamp: ${props.lineClamp};  -webkit-box-orient: vertical;}`}
    ${props => props.flexDirection && "flex-direction: " + props.flexDirection}
    ${props => props.fillRemainingHeight && "flex: 1"}
    ${props => props.filter && "filter: " + props.filter + ";"}
    ${props => props.height && "height: " + props.height}
    ${props =>
      props.itemMargin && setItemMargin(props.direction, props.itemMargin)}
      ${props => props.left && "left: " + props.left}
    ${props => props.lineHeight && "line-height: " + props.lineHeight}
    ${props => props.margin && "margin: " + props.margin}
    ${props => props.marginBottom && "margin-bottom: " + props.marginBottom}
    ${props => props.marginLeft && "margin-left: " + props.marginLeft}
    ${props => props.marginRight && "margin-right: " + props.marginRight}
    ${props => props.marginTop && "margin-top: " + props.marginTop}
    ${props => props.maxWidth && "max-width: " + props.maxWidth}
    ${props => props.maxHeight && "max-height: " + props.maxHeight}
    ${props => props.minHeight && "min-height: " + props.minHeight + ";"}
    ${props => props.minWidth && "min-width: " + props.minWidth}
    ${props =>
      props.mobileMarginBottom &&
      `@media (max-width: ${breakpoints.lg}px) { margin-bottom: ${props.mobileMarginBottom}}`}
    ${props =>
      props.mobileMarginLeft &&
      `@media (max-width: ${breakpoints.lg}px) { margin-left: ${props.mobileMarginLeft}}`}
    ${props =>
      props.mobileMarginTop &&
      `@media (max-width: ${breakpoints.lg}px) { margin-top: ${props.mobileMarginTop}}`}
    ${props =>
      props.mobileMaxWidth &&
      `@media (max-width: ${breakpoints.lg}px) { max-width: ${props.mobileMaxWidth}}`}
    ${props =>
      props.mobilePadding &&
      `@media (max-width: ${breakpoints.lg}px) { padding: ${props.mobilePadding}}`}
    ${props =>
      props.mobileTop &&
      `@media (max-width: ${breakpoints.lg}px) { top: ${props.mobileTop}}`}
    ${props => props.opacity && "opacity: " + props.opacity}
    ${props => props.order && "order: " + props.order}
    ${props => props.outline && "outline: " + props.outline}
    ${props => props.overflow && "overflow: " + props.overflow}
    ${props => props.overflowX && "overflow-x: " + props.overflowX}
    ${props => props.overflowY && "overflow-y: " + props.overflowY}
    ${props => props.padding && "padding: " + props.padding}
    ${props => props.paddingBottom && "padding-bottom: " + props.paddingBottom}
    ${props => props.paddingLeft && "padding-left: " + props.paddingLeft}
    ${props => props.paddingRight && "padding-right: " + props.paddingRight}
    ${props => props.paddingTop && "padding-top: " + props.paddingTop}
    ${props => props.pointerEvents && "pointer-events: " + props.pointerEvents}
    ${props => props.position && "position: " + props.position}
    ${props => props.right && "right: " + props.right}
    ${props => props.textAlign && "text-align: " + props.textAlign}
    ${props => props.top && "top: " + props.top}
    ${props => props.transform && "transform: " + props.transform + ";"}
    ${props =>
      props.transformStyle && "transform-style: " + props.transformStyle + ";"}
    ${props => props.transition && "transition: " + props.transition + ";"}
    ${props => props.width && "width: " + props.width}
    ${props => props.zIndex && "z-index: " + props.zIndex}
  }
` as React.FC<StyledGridProps>;

export default StyledGrid;
