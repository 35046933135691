import { Box } from "@material-ui/core";
import React from "react";
import { Redirect } from "react-router";
import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

import { color, spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import MenuBar from "./components/MenuBar/MenuBar";
import PreJoinScreens from "./components/PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from "./components/ReconnectingNotification/ReconnectingNotification";
import RecordingNotifications from "./components/RecordingNotifications/RecordingNotifications";
import Room from "./components/Room/Room";
import useRoomState from "./hooks/useRoomState/useRoomState";
import { ChatProvider } from "./providers/ChatProvider";
import { VideoProvider } from "./providers/VideoProvider";
import { useAppState } from "./state";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import { isMobile } from "./utils";
import ChatWindow from "./components/ChatWindow/ChatWindow";

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      height: "calc(80vh - 130px)",
      [theme.breakpoints.down("sm")]: {
        height: "auto"
      }
    }
  };
});

export const App: React.FC = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />

      <ChatProvider>
        <VideoApp />
      </ChatProvider>
    </VideoProvider>
  );
};

const VideoApp: React.FC = () => {
  const classes = useStyles();
  const roomState = useRoomState();
  const { shouldRedirectOnDisconnect } = useAppState();

  return (
    <Box borderRadius="8px" bgcolor={color.BLACK} height="100%">
      {roomState === "disconnected" ? (
        shouldRedirectOnDisconnect ? (
          <Redirect to={routesConfig.dashboard.path} />
        ) : (
          <PreJoinScreens />
        )
      ) : (
        <Box padding={spacingDefaults.normal}>
          <ReconnectingNotification />
          <RecordingNotifications />

          <Box className={clsx(classes.container)}>
            <Room />
          </Box>

          <MenuBar />
          {roomState === "connected" && isMobile && <ChatWindow />}
        </Box>
      )}
    </Box>
  );
};
