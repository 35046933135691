import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { StyledGrid } from "components/simple";
import { breakpoints } from "style/constants";
import { useQuery } from "@apollo/react-hooks";
import { PostConsentQuestionQuery } from "graphql/user/user.gql";
import ConsentQuestion, { consentQuestions } from "./Questions";

const Questions: React.FC = () => {
  const [activeQuestion, setActiveQuestion] = useState(1);
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const { data, error } = useQuery(PostConsentQuestionQuery, {
    fetchPolicy: "no-cache"
  });

  if (!data || error) return null;

  const questions: consentQuestions[] = data.me.postConsentQuestions;
  const numOfQuestions = questions.length;
  questions.sort((a, b) => (a.order > b.order ? 1 : -1));

  return (
    <StyledGrid
      container
      direction={mobile ? "column" : "row"}
      justifyContent={mobile ? "center" : "flex-start"}
      textAlign="center"
    >
      {activeQuestion <= numOfQuestions ? (
        <ConsentQuestion
          content={questions[activeQuestion - 1]}
          setActiveQuestion={setActiveQuestion}
          isLastQuestion={activeQuestion === numOfQuestions}
        />
      ) : null}
    </StyledGrid>
  );
};

export default Questions;
