import styled from "styled-components";
import { StyledP } from "components/simple";

export const TextOverflowP = styled(StyledP)`
  && {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
