import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { sizing } from "@material-ui/system";
import { breakpoints, fontFamily } from "style/constants";

export const StyledContainer = styled(Grid)`
  && {
    @media (max-width: ${breakpoints.lg}px) {
      width: 100%;
      margin: auto;
    }
    padding: 10% 0 0 0;
  }
`;

export const StyledItem = styled(Grid)`
  && {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 400px;
    width: 300px;
  }
`;

export const StyledHeader = styled.p`
  && {
    ${sizing}
    font-family: ${fontFamily.secondary};
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    letter-spacing: -0.444444px;
    margin: 0;
  }
`;

export const StyledText = styled.p`
  font-family: ${fontFamily.secondary};
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.444444px;
`;

export const HelpContainer = styled.div`
  position: absolute;
  top: 110px;
  right: 30px;
`;
