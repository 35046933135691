import { GetAceLink } from "graphql/training/training.gql";
import { Hidden, Link, useMediaQuery } from "@material-ui/core";
import { ReactComponent as BannerLeft } from "assets/brain-hq-banner-left.svg";
import { ReactComponent as BannerRight } from "assets/brain-hq-banner-right.svg";
import { useQuery } from "@apollo/react-hooks";
import { UserStatus } from "state";
import { useUserStatus } from "hooks";
import AceLogo from "assets/ace-logo.png";
import React, { useEffect, useState } from "react";
import { StyledGrid, StyledP, WidgetTitle } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { Cycle, NewCycle } from "components/pages/Index/CycleSummary/types";
import { differenceInDays } from "date-fns";

interface AceLinkProps {
  selectedCycleIndex: number;
  assessmentCycles: Cycle[] | NewCycle[];
}
const ACELink: React.FC<AceLinkProps> = ({
  selectedCycleIndex,
  assessmentCycles
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const userStatus = useUserStatus();
  const disabled = userStatus !== UserStatus.QUALIFIED_AND_CONSENTED;

  const [isAceEnabled, setIsAceEnabled] = useState<boolean>();
  const [hasAceAccess, setHasAceAccess] = useState<boolean>(false);
  const [aceLink, setAceLink] = useState<string>();

  useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "ACE" } },
    onCompleted: data =>
      setIsAceEnabled(data?.isFeatureEnabled?.enabled ?? false)
  });
  const { loading, error } = useQuery(GetAceLink, {
    onCompleted: data => setAceLink(data?.training?.aceLink)
  });

  useEffect(() => {
    if (
      selectedCycleIndex > -1 &&
      assessmentCycles &&
      assessmentCycles?.length > 0 &&
      isAceEnabled
    ) {
      const currentCycle = assessmentCycles[selectedCycleIndex];
      const hasAceCompleted = currentCycle.aceCompleted;
      const hasCycleCompletedWithinTwoWeeks =
        differenceInDays(
          new Date(),
          new Date(assessmentCycles[selectedCycleIndex].cycleCompletionDate)
        ) <= 14;

      // We only show the ACE link when the following criteria are met:
      //
      // 1. User is enabled for ACE based on feature flags (DOD orgs do not have ACE)
      // 2. User has not completed ACE assessment.
      // 3. User has completed assessments within 14 days.
      setHasAceAccess(
        isAceEnabled && !hasAceCompleted && hasCycleCompletedWithinTwoWeeks
      );
    }
  }, [selectedCycleIndex, assessmentCycles, isAceEnabled]);

  if (loading || error) return null;

  if (!isAceEnabled || !aceLink || !hasAceAccess) return null;

  const redirectToACE = async (): Promise<void> => {
    window.location.assign(aceLink);
  };

  return (
    <StyledGrid
      item
      container
      xs={12}
      marginTop={spacingDefaults.normal}
      marginBottom={spacingDefaults.normal}
    >
      <StyledGrid item>
        <WidgetTitle>ACE Assessments</WidgetTitle>
      </StyledGrid>

      <StyledGrid
        alignItems="center"
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        marginTop={spacingDefaults.normal}
        mobilePadding={spacingDefaults.medium}
        overflow="hidden"
        padding={`${spacingDefaults.small} ${spacingDefaults.large}`}
        position="relative"
      >
        <StyledGrid item position="absolute" left="-30px">
          <BannerLeft height={mobile ? 135 : 200} />
        </StyledGrid>

        <Hidden mdDown>
          <StyledGrid item position="absolute" right="0">
            <BannerRight height={500} width={420} />
          </StyledGrid>
        </Hidden>

        <StyledGrid
          height={mobile ? "80px" : "140px"}
          item
          overflow="hidden"
          width={mobile ? "125px" : "200px"}
          zIndex="1"
        >
          <img alt="ACE Logo" src={AceLogo} height={mobile ? 80 : 140} />
        </StyledGrid>

        <StyledGrid item zIndex={"200"}>
          {mobile ? (
            <StyledGrid item marginBottom={spacingDefaults.normal}>
              <StyledP
                color={color.BLACK}
                fontFamily={fontFamily.secondary}
                fontSize={fontSize.medium}
                width="175px"
              >
                Complete your assessments with <strong>ACE Explorer</strong>
              </StyledP>
            </StyledGrid>
          ) : (
            <>
              <StyledGrid item marginBottom={spacingDefaults.normal}>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.secondary}
                  fontSize={fontSize.large}
                >
                  Complete your assessments with <strong>ACE Explorer</strong>
                </StyledP>
              </StyledGrid>

              <StyledGrid item marginBottom={spacingDefaults.normal}>
                <StyledP color={color.BLACK} width="550px">
                  ACE is an interactive 3d experience that is used to further
                  measure BrainHealth metrics.
                </StyledP>
              </StyledGrid>
            </>
          )}

          <Link
            aria-disabled={disabled}
            component="button"
            disabled={disabled}
            onClick={disabled ? undefined : redirectToACE}
          >
            <StyledP color={color.BLACK}>
              <strong>Visit ACE &gt;</strong>
            </StyledP>
          </Link>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ACELink;
