import React from "react";
import { Box } from "@material-ui/core";

import { StyledGrid } from "components/simple";
import { color, spacingDefaults } from "style/constants";
import { Rank } from "graphql/types/UserRanks";

interface RankItemProps {
  hasCurrentRank: boolean;
  index: number;
  item: Rank; // TODO: type this
  threshold: number;
}
const RankItem: React.FC<RankItemProps> = ({
  hasCurrentRank,
  index,
  item,
  threshold
}) => {
  return (
    <div
      style={{
        filter: hasCurrentRank && index > threshold ? "grayscale(1)" : "none"
      }}
    >
      <StyledGrid height="20px">
        {hasCurrentRank && index === threshold && (
          <Box color={color.DARKBLUE}>Current Rank</Box>
        )}
      </StyledGrid>
      <img
        src={item.rankImageKey}
        height="100px"
        style={{ margin: `${spacingDefaults.normal} 0` }}
      />
      <Box fontStyle="italic" color={color.DARKERGRAY}>
        {item.level}
      </Box>
    </div>
  );
};

export default RankItem;
