import React from "react";
import { Box, IconButton, useMediaQuery } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { format } from "date-fns";

import { StyledGrid } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

interface HeaderProps {
  selectedCycleIndex: number;
  selectedCycleDate: string;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  setSelectedCycleIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Header: React.FC<HeaderProps> = ({
  selectedCycleIndex,
  selectedCycleDate,
  isPreviousDisabled,
  isNextDisabled,
  setSelectedCycleIndex
}: HeaderProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      className="cycle-select-banner"
      alignItems="center"
      backgroundColor={color.DARKBLUE}
      container
      justifyContent="center"
      position="fixed"
      width="100%"
      zIndex="1"
    >
      <StyledGrid backgroundColor={color.WHITE} borderRadius="50%" item>
        <IconButton
          aria-label="Previous"
          color="secondary"
          disabled={isPreviousDisabled}
          onClick={() => setSelectedCycleIndex(selectedCycleIndex - 1)}
        >
          <ChevronLeftIcon />
        </IconButton>
      </StyledGrid>
      <StyledGrid
        item
        margin={`${mobile ? "0.25rem" : spacingDefaults.normal} ${
          spacingDefaults.large
        }`}
        textAlign="center"
      >
        <Box
          color={color.WHITE}
          component="p"
          fontFamily={fontFamily.secondary}
          fontSize={fontSize.large}
          margin="0"
        >{`Round ${selectedCycleIndex + 1}`}</Box>

        <Box color={color.WHITE} component="p" margin="0">{`${format(
          new Date(selectedCycleDate),
          "MMM yyyy"
        )}`}</Box>
      </StyledGrid>
      <StyledGrid backgroundColor={color.WHITE} borderRadius="50%" item>
        <IconButton
          aria-label="Next"
          color="secondary"
          disabled={isNextDisabled}
          onClick={() => setSelectedCycleIndex(selectedCycleIndex + 1)}
        >
          <ChevronRightIcon />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Header;
