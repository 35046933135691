import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GetDietIDAccessCode } from "graphql/user/user.gql";
import { useHistory } from "react-router";

import PageOne from "./Pages/PageOne";
import PageTwo from "./Pages/PageTwo";
import PageThree from "./Pages/PageThree";
import { ContentCarousel, Loading, StyledGrid } from "components/simple";
import { color, spacingDefaults } from "style/constants";

const DietID: React.FC = () => {
  const history = useHistory();
  const { data, loading } = useQuery(GetDietIDAccessCode, {
    fetchPolicy: "no-cache"
  });

  if (loading) return <Loading />;

  const dietidAccessCode = data.me.dietidAccessCode;

  if (!dietidAccessCode) history.push("/dashboard");

  const pages = [
    {
      component: <PageOne />,
      buttonText: "Continue"
    },
    {
      component: <PageTwo />,
      buttonText: "Continue",
      showPrevious: true
    },
    {
      component: <PageThree dietidAccessCode={dietidAccessCode} />,
      buttonText: "Go to Diet ID",
      showPrevious: true,
      callback: () => {
        window.open("https://brainhealthproject.dietid.com/", "_blank");
      }
    }
  ];

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.BLUEGRAY}
      container
      direction="column"
      fillRemainingHeight
      justifyContent="center"
      margin={`${spacingDefaults.xxlarge} 0`}
      padding={spacingDefaults.normal}
    >
      <ContentCarousel content={pages} />
    </StyledGrid>
  );
};

export default DietID;
