import styled from "styled-components";

import {
  breakpoints,
  backgroundImageStyle,
  color,
  fontFamily,
  fontSize
} from "style/constants";
import consentVideoThumbnail from "assets/onboarding-video-thumbnail.png";

export const PlayButton = styled.img`
  cursor: pointer;
`;

export const Timestamp = styled.div`
  @media (min-width: ${breakpoints.lg}px) {
    position: absolute;
    bottom: 2%;
    right: 1%;
  }
  width: 75px;
  height: 29px;
  background: ${color.BLACK};
  border-radius: 4px;
`;

export const TimeText = styled.p`
  font-family: ${fontFamily.primary};
  font-size: ${fontSize.small};
  color: ${color.WHITE};
  text-align: center;
  margin: 0;
  line-height: 29px;
  letter-spacing: -0.44px;
`;

export const Title = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    color: ${color.WHITE};
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 400px;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.header};
  font-weight: 600;
  line-height: 2.5625rem;
  margin-bottom: 3.5rem;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: ${breakpoints.lg}px) {
    background-blend-mode: darken;
    background-color: rgba(0, 0, 0, 0.5);
    border: 0;
    border-radius: 0px;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  align-items: center;
  border: 0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 468px;
  justify-content: center;
  margin: auto;
  max-width: 834px;
  position: relative;
  width: 100%;
  ${backgroundImageStyle(consentVideoThumbnail)}
`;
