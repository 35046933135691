/* eslint-disable @typescript-eslint/no-use-before-define */

import { useMutation } from "@apollo/react-hooks";
import { Hidden } from "@material-ui/core";
import { get, cloneDeep } from "lodash";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { Prompt, Redirect, useHistory } from "react-router-dom";
import track, { useTracking } from "react-tracking";
import { useQuery } from "@apollo/react-hooks";

import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import { Button, ProgressBar, Question, StyledGrid } from "components/simple";
import { UserSurvey } from "graphql/types/UserSurvey";
import {
  GetUserSurvey,
  SubmitUserFeedback
} from "graphql/userSurveys/userSurveys.gql";
import { spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import ThankYou from "./ThankYou";

const url: string = routesConfig.surveys.survey.path;

const Feedback: React.FC<any> = (props: any) => {
  const { location } = props;

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const id = queryParams["id"];
  const history = useHistory();
  const tracking = useTracking();

  const { data, loading, error } = useQuery(GetUserSurvey, {
    variables: { id },
    skip: !id,
    fetchPolicy: "no-cache"
  });

  const [submitUserFeedback] = useMutation(SubmitUserFeedback);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<any, any>[]>([]);
  const [success, setSuccess] = useState(false);

  const userSurvey: UserSurvey = get(data, "getUserSurvey");
  const userAnswers = get(userSurvey, "answers");
  const surveyName = get(userSurvey, "survey.name", "");
  const surveyQuestions = get(userSurvey, "survey.questions", []);
  surveyQuestions.sort((a: any, b: any) => a.order - b.order);

  useEffect(_handleBeforeUnloadEvent, []);
  useEffect(_trackStartedSurveyEvent, [tracking, surveyName]);
  useEffect(_handleComplete, [userSurvey, currentQuestionIndex]);

  if (loading) {
    return null;
  }

  if (!id || error || !userSurvey) {
    return <Redirect push to="/login" />;
  }

  if (userAnswers?.length > 0) {
    return <ThankYou />;
  }

  return success ? (
    <ThankYou />
  ) : (
    <>
      <Prompt
        message="quit-survey"
        when={currentQuestionIndex !== surveyQuestions.length}
      />

      <ProgressBar
        activeStep={currentQuestionIndex}
        steps={surveyQuestions.length}
        variant="solid"
      />

      {currentQuestionIndex > 0 && (
        <StyledGrid
          container
          item
          marginLeft={spacingDefaults.xlarge}
          mobileMarginLeft="0"
          mobileTop="90px"
          position="fixed"
          top="120px"
        >
          <Button plain onClick={_handleBackClick}>
            <ChevronLeft />

            <StyledGrid item marginLeft={spacingDefaults.normal}>
              <Hidden mdDown>Back</Hidden>
            </StyledGrid>
          </Button>
        </StyledGrid>
      )}

      {surveyQuestions[currentQuestionIndex] && (
        <Question
          assessmentName={""}
          question={surveyQuestions[currentQuestionIndex]}
          activeQuestion={currentQuestionIndex}
          answers={answers}
          updateAnswers={_updateAnswers}
        />
      )}
    </>
  );

  /* Internal */

  function _handleBackClick(): void {
    if (currentQuestionIndex === 0) {
      history.push(routesConfig.login.path);
      return;
    }

    let lastActiveQuestionIndex = currentQuestionIndex - 1;

    while (
      get(answers[lastActiveQuestionIndex], "answerData.response") === "N/A"
    ) {
      lastActiveQuestionIndex--;
    }

    setCurrentQuestionIndex(lastActiveQuestionIndex);
  }

  function _updateAnswers(answerData: Record<any, any>): void {
    tracking.trackEvent({
      url,
      actions: [
        "Feedback",
        "Answer Feedback Question",
        surveyQuestions[currentQuestionIndex].internalName
      ]
    });
    const answer = {
      questionId: surveyQuestions[currentQuestionIndex].id,
      answerData
    };

    const updatedAnswers = cloneDeep(answers);
    updatedAnswers[currentQuestionIndex] = answer;

    setAnswers(updatedAnswers);

    // NOTE - Move to the next question.

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  }

  /* Effects */

  function _handleBeforeUnloadEvent(): () => void {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "Changes will be lost!";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }

  function _trackStartedSurveyEvent(): void {
    if (tracking && surveyName) {
      tracking.trackEvent({
        url,
        actions: ["Feedback", "Start Feedback", surveyName]
      });
    }
  }

  function _handleComplete(): void {
    if (!userSurvey || currentQuestionIndex !== surveyQuestions.length) {
      return;
    }

    tracking.trackEvent({
      url,
      actions: ["Feedback", "Complete Feedback", surveyName]
    });

    (async function () {
      await submitUserFeedback({
        variables: {
          input: {
            userSurveyId: userSurvey.id,
            answers
          }
        }
      });
      setSuccess(true);
      // history.push(routesConfig.thankyou.path);
    })();
  }
};

export default track({
  url
})(Feedback);
