import React from "react";
import { Link } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";

import { Button, StyledGrid } from "components/simple";
import { breakpoints, fontSize, spacingDefaults } from "style/constants";
import { StyledCard } from "components/pages/DashboardV2/styled";

interface ScheduleCardProps {
  disabled?: boolean;
  appointmentType: string;
}

const ScheduleCard: React.FC<ScheduleCardProps> = ({
  appointmentType
}: ScheduleCardProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const coachingSession = appointmentType === "coaching";

  const headerText = coachingSession ? "Coaching Call" : "Image Scheduling";
  const url = coachingSession ? "scheduling/coaching" : "scheduling/imaging";
  const appointmentTextContent = coachingSession
    ? "Schedule a time to meet with your coach!"
    : "Make an appointment for your brain imaging at the Center for BrainHealth®!";

  return (
    <StyledCard
      container
      alignItems="center"
      minHeight="100px"
      justifyContent="space-between"
      padding={spacingDefaults.normal}
    >
      <StyledGrid item>
        <Box fontSize={fontSize.medium}>{headerText}</Box>
        <Box
          fontSize={fontSize.small}
          margin={`${spacingDefaults.xsmall} 0`}
          style={{
            width: mobile ? "180px" : "100%"
          }}
        >
          {appointmentTextContent}
        </Box>
      </StyledGrid>
      <StyledGrid item>
        <Link to={url}>
          <Button inverted width={mobile ? "130px" : "100%"}>
            Schedule
          </Button>
        </Link>
      </StyledGrid>
    </StyledCard>
  );
};

export default ScheduleCard;
