import React from "react";
import { memo } from "react";
import { useDrag } from "react-dnd";
import { detectMob } from "App";

export interface ItemProps {
  name: string;
  type: string;
}

export const Item: React.FC<ItemProps> = memo(function Item({ name, type }) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: { name, type },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });
  return (
    <div>
      <div
        ref={drag}
        id="card"
        style={{
          display: "flex",
          opacity: isDragging ? "0" : "1",
          width: "17.8125rem",
          height: "16.4375rem",
          padding: "1rem 1.5rem 1.5rem 1.5rem",
          flexDirection: "column",
          alignItems: "center",
          gap: "1.5rem",
          flexShrink: "inherit",
          borderRadius: "0.25rem",
          background: "var(--neutrals-0, #FFF)",
          boxShadow: "0px 4px 28px 0px rgba(0, 0, 0, 0.10)"
        }}
      >
        <div
          id="frame1"
          style={{
            display: "flex",
            height: "14.5rem",
            flexDirection: "column",
            alignItems: "center",
            flexShrink: "inherit",
            alignSelf: "stretch"
          }}
        >
          <div
            id="frame1-2"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
              flex: "1 0 0"
            }}
          >
            <h1
              id="item-text"
              style={{
                color: "#000",
                width: "14.8125rem",
                textAlign: "center",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "1.375rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "2.0625rem"
              }}
            >
              {name}
            </h1>
          </div>
          {!isDragging && (
            <p
              id="instruction"
              style={{
                color: "#231F20",
                textAlign: "center",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontSize: "0.875rem",
                fontStyle: "italic",
                fontWeight: 300,
                lineHeight: "1.3125rem"
              }}
            >
              {detectMob()
                ? "Tap on the correct category"
                : "Hold and drag to move"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
});
