import styled from "styled-components";

import { ReactComponent as Img } from "assets/finish-line.svg";
import { breakpoints, color, fontSize, fontFamily } from "style/constants";

export const Subtitle = styled.h1`
  margin: 0;
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-weight: 500;
`;

export const Paragraph = styled.p`
  @media (max-width: ${breakpoints.lg}px) {
    max-width: 310px;
    margin: 0;
  }
  max-width: 607px;

  margin: 0 0 10px 0;
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.medium};
`;

export const CompleteImage = styled(Img)`
  @media (max-width: ${breakpoints.lg}px) {
    width: 274px;
    height: 156px;
  }
  width: 376px;
  height: 214px;
`;
