import React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import VideoCamIcon from "@material-ui/icons/VideocamOutlined";
import { ReactComponent as CheckIcon } from "../../../assets/check-filled.svg";

import { StyledGrid, StyledP } from "components/simple";
import NewIcon from "../../../assets/new.svg";
import { color } from "style/constants";
import { subDays } from "date-fns";

interface MediaIndicatorProps {
  readingTime?: number;
  hasVideo?: boolean;
  createdAt: string;
  completed: boolean;
}

const MediaIndicator: React.FC<MediaIndicatorProps> = ({
  readingTime,
  hasVideo = false,
  createdAt,
  completed
}: MediaIndicatorProps) => {
  return (
    <>
      {new Date(createdAt) >= subDays(new Date(), 7) && (
        <StyledGrid
          backgroundColor={color.ICON}
          borderRadius="12px"
          container
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          marginBottom="5px"
        >
          <img src={NewIcon} height="20px" />
        </StyledGrid>
      )}
      {readingTime && (
        <StyledGrid
          backgroundColor={color.ICON}
          borderRadius="12px"
          container
          padding="1px 6px"
          alignContent="center"
          justifyContent="center"
          alignItems="center"
        >
          {completed ? (
            <CheckIcon fill={color.GREEN} height={14} width={14} />
          ) : hasVideo ? (
            <VideoCamIcon style={{ color: color.BLACK, fontSize: 14 }} />
          ) : (
            <AccessTimeIcon style={{ color: color.BLACK, fontSize: 14 }} />
          )}
          {readingTime && (
            <StyledP
              color={color.BLACK}
              fontSize="10px"
              fontWeight="300"
              lineHeight="18px"
              marginright="2px"
            >
              {readingTime}m
            </StyledP>
          )}
        </StyledGrid>
      )}
    </>
  );
};

export default MediaIndicator;
