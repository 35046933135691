// Enums
export enum appWidth {
  max = "1048px"
}

export enum breakpoints {
  xs = 0,
  sm = 375,
  md = 600,
  lg = 900,
  xl = 1440
}

export enum color {
  ALTBLACK = "#373737",
  BLACK = "#231F20",
  BLACK_RGBA = "rgba(1, 1, 1, 0.38)",
  BLUE = "#039BE5",
  BLUEGRAY = "#F5F6FA",
  BLUEGRAY_RGBA = "245, 246, 250, 0.8",
  CARDGRAY = "#DFDFDF",
  CARDBORDER = "#C8C8C8",
  DARKBLUE = "#037DBA",
  DARKERBLUE = "#00689B",
  DARKERGRAY = "#707070",
  DARKGRAY = "#333333",
  DARKGREEN = "#008566",
  DARKRED = "#E00000",
  DARKYELLOW = "#C39400",
  FUSCHIA = "#E91E63",
  GRAPHITE = "#505050",
  GRAY = "#E7E8EC",
  GRAY_PROGESS_BAR = "#D3D2D2",
  GRAY_RGBA = "0, 0, 0, 0.3",
  GREEN = "#00B288",
  GREEN_HABITS = "#019688",
  GREEN_HABITS_DARK = "#00776C",
  GREEN_HABITS_LIGHT = "#B3E0DB",
  ICON = "#F4F4F4",
  INDEX_BG = "#F4F4F4",
  INDEX_FOOTNOTE = "#646464",
  LIGHTBLUE = "#00BFF3",
  LIGHTERBLUE = "#F2FAFE",
  LIGHTERGRAY = "#B9B9B9",
  LIGHTGRAY = "#5F5F5F",
  LIGHTRED = "#FF5252",
  NETURALS700 = "#4F4C4D",
  NETURALS900 = "#231F20",
  MUTEDGRAY = "#DFDFDF",
  PALEBLUE = "#EFF9FF",
  PALEGRAY = "#A4A4A4",
  PINK = "#FF7070",
  PROGRESSGRAY = "#d3d2d2",
  PURPLE = "#7B1FA2",
  RANK_YELLOW = "#FEEBB1",
  RED = "#E91E63",
  SLIDERGRAY = "#B9B9B9",
  SOFTBLUE = "#CDEBFA",
  WHITE = "#FFF",
  YELLOW = "#FFCA28"
}
export enum appearances {
  PRIMARY = color.BLUE,
  SUCCESS = color.GREEN,
  WARNING = color.YELLOW,
  ERROR = color.RED
}

export enum fontFamily {
  primary = "Museo Sans, museo-sans, sans-serif",
  secondary = "Mercury Display, mercury-display, sans-serif"
}

export enum fontSize {
  xsmall = ".75rem",
  small = ".875rem",
  normal = "1rem",
  medium = "1.15rem",
  large = "1.5rem",
  header = "2.25rem",
  xlarge = "2.5rem",
  xxlarge = "3rem"
}

export enum iconSizes {
  xsmall = "0.65rem",
  smaller = "1rem",
  small = "1.5rem",
  normal = "2rem",
  large = "2.5rem",
  xlarge = "3rem",
  xxlarge = "5rem",
  xxxlarge = "8rem"
}

export enum spacingDefaults {
  auto = "auto",
  xxsmall = "0.25rem",
  xsmall = "0.5rem",
  small = "0.75rem",
  normal = "1rem",
  medium = "1.25rem",
  large = "1.75rem",
  xlarge = "2.25rem",
  xxlarge = "4rem"
}

export const addOpacity = (color: color, opacity: number): string => {
  const opacityValue = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + opacityValue.toString(16).toUpperCase();
};

export const backgroundImageStyle = (url: string): string => {
  const backgroundImage = url && `background-image: url(${url})`;
  return `
    ${backgroundImage};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `;
};

export const spacing = (
  top: spacingDefaults,
  right: spacingDefaults = top,
  btm: spacingDefaults = top,
  left: spacingDefaults = right
): string => {
  return `
    ${top || 0}
    ${right || 0}
    ${btm || 0}
    ${left || 0}
  `;
};

export const addBackgroundColorToRoot = (color: color) => {
  const root = document.querySelector("#root") as HTMLElement;
  if (root != null) {
    root.style.backgroundColor = color;
  }
};

export const removeBackgroundColorFromRoot = () => {
  const root = document.querySelector("#root") as HTMLElement;
  if (root != null) {
    root.style.backgroundColor = "";
  }
};
