import React from "react";

import { StyledGrid, StyledP, PillarGraphIcon } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";

interface Props {
  data: Record<any, any>[];
  pillarColor: color;
  pillarName: string;
  print?: boolean;
  mobile?: boolean;
}

const MoreInfo: React.FC<Props> = ({
  data,
  pillarColor,
  pillarName,
  print,
  mobile
}: Props) => {
  return (
    <>
      {data.map((info: any) => (
        <StyledGrid container item xs={12} key={info.title}>
          {info.title && (
            <StyledP
              color={pillarColor}
              fontSize={fontSize.medium}
              weight="600"
              margins={print ? spacingDefaults.small : spacingDefaults.normal}
            >
              {info.title}
            </StyledP>
          )}
          {info.content &&
            info.content.map((text: string, index: number) => (
              <StyledP
                key={index}
                color={color.DARKGRAY}
                margins={print ? spacingDefaults.small : spacingDefaults.medium}
                fontSize={print ? "14px" : fontSize.normal}
                lineHeight={print ? "16px" : "25px"}
              >
                {text}
              </StyledP>
            ))}

          {info.subContent &&
            info.subContent.map((sub: any) => (
              <StyledGrid
                item
                key={sub.title}
                padding={print ? spacingDefaults.small : spacingDefaults.medium}
                xs={mobile ? 12 : 6}
              >
                <StyledGrid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  margin={print ? "0px" : spacingDefaults.normal}
                  itemMargin={"5px"}
                >
                  <PillarGraphIcon
                    width="30"
                    height="30"
                    pillarColor={pillarColor}
                    activePillar={pillarName}
                  />
                  <StyledP
                    color={color.BLACK}
                    fontSize={fontSize.medium}
                    weight="600"
                  >
                    {sub.title}
                  </StyledP>
                </StyledGrid>
                {sub.content.map((text: string, index: number) => (
                  <StyledP
                    key={index}
                    color={color.DARKGRAY}
                    margins={
                      print ? spacingDefaults.small : spacingDefaults.medium
                    }
                    fontSize={print ? "14px" : fontSize.normal}
                    lineHeight={print ? "16px" : "25px"}
                  >
                    {text}
                  </StyledP>
                ))}
              </StyledGrid>
            ))}
        </StyledGrid>
      ))}
    </>
  );
};

export default MoreInfo;
