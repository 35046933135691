import { Box, Grid, useMediaQuery } from "@material-ui/core";
import React from "react";

import { StyledGrid } from "components/simple";
import { breakpoints, color } from "style/constants";
import { StyledProgressBar } from "./styled";

interface StreakStatusPillsProps {
  componentColor: color;
  streakCount: number;
  maxStreakCount: number;
  isCompleted: boolean;
}

const StreakStatusPills: React.FC<StreakStatusPillsProps> = ({
  componentColor,
  streakCount,
  maxStreakCount,
  isCompleted
}: StreakStatusPillsProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const compact = maxStreakCount > 7;
  const adjustedStreakCount = streakCount + (isCompleted ? -1 : 0);

  return (
    <Grid container item>
      {mobile ? (
        <StyledGrid item xs={12}>
          <StyledProgressBar
            backgroundColor={componentColor}
            value={(streakCount / maxStreakCount) * 100}
            variant="determinate"
          />
        </StyledGrid>
      ) : (
        [...new Array(maxStreakCount)].map((_, i) => (
          <StyledGrid
            key={i}
            alignItems="center"
            backgroundColor={i < streakCount ? componentColor : undefined}
            border={`2px solid ${
              i === adjustedStreakCount ? color.WHITE : componentColor
            }`}
            borderRadius={mobile ? "10px" : "18px"}
            container
            height={mobile || compact ? "20px" : "36px"}
            item
            justifyContent="center"
            marginRight="10px"
            xs
          >
            {!compact && i === adjustedStreakCount && (
              <Box
                color={color.WHITE}
                component="span"
                fontSize="14px"
                fontWeight="700"
                textAlign="center"
              >
                Day {adjustedStreakCount + 1} of {maxStreakCount}
              </Box>
            )}
          </StyledGrid>
        ))
      )}
    </Grid>
  );
};

export default StreakStatusPills;
