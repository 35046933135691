import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { useDispatch } from "react-redux";

import { BackButton, Button, Loading, StyledGrid } from "components/simple";
import { GetBrainHqLink } from "graphql/training/training.gql";
import Logo from "assets/brain-hq-logo.png";
import { Box, useMediaQuery } from "@material-ui/core";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const BrainHqAssessment: React.FC = () => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const { data, loading, error } = useQuery(GetBrainHqLink, {
    fetchPolicy: "cache-and-network",
    onError: () => dispatch({ type: "error" })
  });
  if (loading) return <Loading />;
  if (error) return null;

  const ssoLink = data.training.bhqLink;

  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      fillRemainingHeight
      justifyContent="center"
    >
      <BackButton />
      <StyledGrid
        alignItems="center"
        container
        direction="column"
        padding={spacingDefaults.xlarge}
      >
        <img alt="BrainHQ Logo" height={185} src={Logo} width={225} />

        <Box
          fontFamily={fontFamily.secondary}
          fontSize={mobile ? fontSize.large : fontSize.xlarge}
          letterSpacing="-0.45px"
          margin={`${spacingDefaults.large} 0`}
          textAlign="center"
        >
          What is the BrainHQ assessment?
        </Box>

        <Box
          fontSize={mobile ? fontSize.small : fontSize.normal}
          lineHeight="180%"
          maxWidth="620px"
          textAlign="center"
        >
          The BrainHQ assessment measures speed of processing. This assessment
          is accessed through a partnership with Posit Science and The
          BrainHealth Project.
          <br />
          <strong>Completion time: 5 min</strong>
          <br />
          <br />
          <a
            href="https://www.brainhq.com/?v4=true&fr=y"
            rel="noopener noreferrer"
            target="_blank"
          >
            For more information about BrainHQ, click here.
          </a>
        </Box>
        <StyledGrid container centerContent marginTop={spacingDefaults.xlarge}>
          <Button
            onClick={() => {
              window.location.assign(ssoLink);
            }}
          >
            Continue
          </Button>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default BrainHqAssessment;
