import React from "react";
import { Tooltip, useMediaQuery } from "@material-ui/core";

import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import { ReactComponent as ClockIcon } from "assets/clock.svg";
import { StyledGrid, StyledP } from "components/simple";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";

interface UnitStatusProps {
  name: string;
  number: number;
  completed: boolean;
  timeEstimate?: number;
}

const UnitStatus: React.FC<UnitStatusProps> = ({
  name,
  number,
  completed,
  timeEstimate
}: UnitStatusProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      container
      height="60px"
      justifyContent="space-between"
    >
      <StyledGrid item overflow="hidden" xs>
        <Tooltip title={name}>
          <StyledP
            fontSize={mobile ? fontSize.normal : fontSize.medium}
            letterSpacing="-0.75px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            Unit {number}: <strong>{name}</strong>
          </StyledP>
        </Tooltip>
      </StyledGrid>

      <StyledGrid item marginLeft={spacingDefaults.normal}>
        {completed ? (
          <CheckIcon
            fill={color.BLUE}
            filter="saturate(0)"
            height={32}
            width={32}
          />
        ) : (
          <StyledGrid container justifyContent="flex-end">
            <StyledP
              fontSize={mobile ? fontSize.normal : fontSize.medium}
              letterSpacing="-0.75px"
            >
              {timeEstimate || "-"} min
            </StyledP>
            <StyledGrid marginLeft="8px">
              <ClockIcon />
            </StyledGrid>
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default UnitStatus;
