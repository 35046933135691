import { Box } from "@material-ui/core";
import React from "react";
import StyledGrid from "../StyledGrid";
import coinIcon from "assets/coin-icon.svg";
import reviveLeafIcon from "assets/revive-leaf.svg";

interface FreeDayPassCoinsStatsProps {
  hasFreeDayPass: boolean;
  coinsTotal: number;
}

const UserFreeDayPassCoinsStatCard: React.FC<FreeDayPassCoinsStatsProps> = ({
  hasFreeDayPass,
  coinsTotal
}: FreeDayPassCoinsStatsProps) => {
  return (
    <>
      <StyledGrid alignItems="center" container flexDirection="column">
        <Box>
          <img src={reviveLeafIcon} height="15px" /> Free Day Pass Available:{" "}
          {hasFreeDayPass ? <>&#10004;</> : "X"}
        </Box>
        <Box>
          <img src={coinIcon} height="15px" /> Total Coins Available:{" "}
          {coinsTotal} coins
        </Box>
      </StyledGrid>
    </>
  );
};

export default UserFreeDayPassCoinsStatCard;
