import { Box } from "@material-ui/core";
import React from "react";

import { StyledGrid } from "components/simple";
import { color, fontSize } from "style/constants";

interface PillarScoreProps {
  color: string;
  pillarName: string;
  value: number;
}

const PillarScore: React.FC<PillarScoreProps> = ({
  color: pillarColor,
  pillarName,
  value
}: PillarScoreProps) => (
  <StyledGrid container direction="column" textAlign="center">
    <StyledGrid item>
      <Box color={pillarColor} component="p" fontSize="26px" margin="0">
        {value.toFixed(1)}
      </Box>
    </StyledGrid>

    <StyledGrid item>
      <Box
        color={color.DARKERGRAY}
        component="p"
        fontSize={fontSize.small}
        lineHeight={fontSize.small}
        margin="0"
        whiteSpace="nowrap"
      >
        {pillarName}
      </Box>
    </StyledGrid>
  </StyledGrid>
);

export default PillarScore;
