import { Box, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { format } from "date-fns";
import React from "react";

import { StyledGrid } from "components/simple";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";

interface HeaderProps {
  backgroundColor: color;
  selectedCycleIndex: number;
  selectedCycleDate: string;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  setSelectedCycleIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Header: React.FC<HeaderProps> = ({
  backgroundColor,
  selectedCycleIndex,
  selectedCycleDate,
  isPreviousDisabled,
  isNextDisabled,
  setSelectedCycleIndex
}: HeaderProps) => (
  <StyledGrid
    alignItems="center"
    backgroundColor={backgroundColor}
    container
    justifyContent="center"
  >
    <StyledGrid backgroundColor={color.WHITE} borderRadius="50%" item>
      <IconButton
        aria-label="Previous"
        color="secondary"
        onClick={() => setSelectedCycleIndex(selectedCycleIndex - 1)}
        disabled={isPreviousDisabled}
      >
        <ChevronLeftIcon />
      </IconButton>
    </StyledGrid>

    <StyledGrid
      item
      margin={`${spacingDefaults.normal} ${spacingDefaults.large}`}
      textAlign="center"
    >
      <Box
        color={color.WHITE}
        component="p"
        fontFamily={fontFamily.secondary}
        fontSize={fontSize.large}
        margin="0"
      >{`Round ${selectedCycleIndex + 1}`}</Box>

      <Box color={color.WHITE} component="p" margin="0">{`${format(
        new Date(selectedCycleDate),
        "MMM yyyy"
      )}`}</Box>
    </StyledGrid>

    <StyledGrid backgroundColor={color.WHITE} borderRadius="50%" item>
      <IconButton
        aria-label="Next"
        color="secondary"
        onClick={() => setSelectedCycleIndex(selectedCycleIndex + 1)}
        disabled={isNextDisabled}
      >
        <ChevronRightIcon />
      </IconButton>
    </StyledGrid>
  </StyledGrid>
);

export default Header;
