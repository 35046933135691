import { gql } from "apollo-boost";

export const GetTraining = gql`
  query GetTraining {
    training {
      waitListed
      tlmsUserId
      aceUserId
      trainingProgress {
        completedCourses
        totalCourses
        dueDate
        currentCourseProgress {
          id
          name
          categoryName
          description
          code
          pillars
          locked
          completionStatus
          completionPercentage
          timeEstimate
          unitProgress {
            name
            completionStatus
          }
        }
        overallCourseProgress {
          name
          categoryName
          pillars
          locked
          completionStatus
          completionPercentage
        }
      }
      bhqUserId
      dietidAccessCode
    }
  }
`;

export const GetTeaserTraining = gql`
  query GetTeaserTraining {
    teaserTrainingProgress {
      completedTeaserCourses
      totalTeaserCourses
      dueDate
      currentTeaserCourseProgress {
        id
        name
        categoryName
        description
        code
        pillars
        locked
        completionStatus
        completionPercentage
        timeEstimate
        unitProgress {
          name
          completionStatus
        }
      }
      overallTeaserCourseProgress {
        name
        categoryName
        pillars
        locked
        completionStatus
        completionPercentage
      }
    }
  }
`;

export const GetBrainHqDietIdTraining = gql`
  query GetBrainHqDietIdTraining {
    training {
      bhqUserId
      isBrainHqActive
      dietidAccessCode
    }
  }
`;

export const GetAceLink = gql`
  query GetAceLink {
    training {
      aceLink
    }
  }
`;

export const GetChallengeLink = gql`
  query GetChallengeLink {
    training {
      challengeProgress {
        link
      }
    }
  }
`;

export const GetTalentLmsLink = gql`
  query GetTalentLmsLink($tlmsCourseId: String) {
    training {
      tlmsLink(tlmsCourseId: $tlmsCourseId)
    }
  }
`;

export const GetBrainHqLink = gql`
  query GetBrainHqLink {
    training {
      bhqLink
    }
  }
`;

// CONTENTFUL

export const GetContentfulCourses = gql`
  query trainingProgress {
    trainingProgress {
      completedCourseCount
      currentCourse {
        id
        title
        sections {
          id
          title
          thumbnailImage
          description
          active
          status
        }
      }
      courses {
        id
        title
        thumbnailImage
        description
        factors
        order
        active
        status
        percentageCompletion
      }
    }
  }
`;

export const GetContentfulSections = gql`
  query trainingProgress {
    trainingProgress {
      courses {
        id
        title
        thumbnailImage
        factors
        status
        sections {
          id
          title
          thumbnailImage
          description
          active
          order
          status
        }
      }
    }
  }
`;

export const GetContentfulUnits = gql`
  query trainingProgress {
    trainingProgress {
      isTrainingLocked
      courses {
        id
        title
        sections {
          id
          title
          thumbnailImage
          description
          units {
            id
            title
            timeEstimate
            status
            active
            order
            percentageCompletion
            tasks {
              id
              title
              taskType
              status
              active
              order
            }
          }
        }
      }
    }
  }
`;

export const GetLessonContent = gql`
  query getLessonContent($input: UserTrainingTaskInput!) {
    getLessonContent(input: $input) {
      id
      title
      description
      contentModule {
        id
        title
        layoutType
        fullBleed
        contentSlots {
          title
          richText
          textAlignment
          tabText
          media {
            title
            description
            fileUrl
            mediaType
          }
        }
      }
    }
  }
`;

export const GetQuizContent = gql`
  query getQuizContent($input: UserTrainingTaskInput!) {
    getQuizContent(input: $input) {
      id
      title
      instructions
      passingScore
      questions {
        id
        question
        instructions
        questionType
        possibleAnswers {
          id
          answer
        }
        correctAnswers {
          id
          answer
        }
      }
    }
  }
`;

export const GetSurveyContent = gql`
  query getSurveyContent($input: UserTrainingTaskInput!) {
    getSurveyContent(input: $input) {
      id
      title
      instructions
      questions {
        id
        question
        instructions
        type
        possibleAnswers {
          id
          answer
        }
      }
    }
  }
`;

export const GetUnitProgress = gql`
  query trainingProgress {
    trainingProgress {
      currentCourse {
        id
        currentSection {
          id
          currentUnit {
            id
            title
            percentageCompletion
          }
        }
      }
    }
  }
`;

export const SaveSurveyAnswers = gql`
  mutation saveSurveyAnswers($input: SaveSurveyAnswersInput!) {
    saveSurveyAnswers(input: $input) {
      success
    }
  }
`;

export const MarkAsStarted = gql`
  mutation markAsStarted($input: UserTrainingTaskInput!) {
    markAsStarted(input: $input) {
      success
    }
  }
`;

export const MarkAsCompleted = gql`
  mutation markAsCompleted($input: UserTrainingTaskInput!) {
    markAsCompleted(input: $input) {
      success
      coinsAwarded {
        title
        coinsAwarded
        ornament
      }
    }
  }
`;

export const GetTeaserContentfulCourses = gql`
  query teaserTrainingProgress {
    teaserTrainingProgress {
      completedTeaserCourseCount
      currentTeaserCourse {
        id
        title
        sections {
          id
          title
          thumbnailImage
          description
          active
          status
        }
      }
      teaserCourses {
        id
        title
        thumbnailImage
        description
        factors
        order
        active
        status
        percentageCompletion
      }
    }
  }
`;

export const GetTeaserContentfulSections = gql`
  query teaserTrainingProgress {
    teaserTrainingProgress {
      teaserCourses {
        id
        title
        thumbnailImage
        factors
        status
        sections {
          id
          title
          thumbnailImage
          description
          active
          order
          status
        }
      }
    }
  }
`;

export const GetTeaserContentfulUnits = gql`
  query teaserTrainingProgress {
    teaserTrainingProgress {
      isTeaserTrainingLocked
      teaserCourses {
        id
        title
        sections {
          id
          title
          thumbnailImage
          description
          units {
            id
            title
            timeEstimate
            status
            active
            order
            percentageCompletion
            tasks {
              id
              title
              taskType
              status
              active
              order
            }
          }
        }
      }
    }
  }
`;

export const GetTeaserLessonContent = gql`
  query getTeaserLessonContent($input: UserTeaserTrainingTaskInput!) {
    getTeaserLessonContent(input: $input) {
      id
      title
      description
      contentModule {
        id
        title
        layoutType
        fullBleed
        contentSlots {
          title
          richText
          textAlignment
          tabText
          media {
            title
            description
            fileUrl
            mediaType
          }
        }
      }
    }
  }
`;

export const GetTeaserQuizContent = gql`
  query getTeaserQuizContent($input: UserTeaserTrainingTaskInput!) {
    getTeaserQuizContent(input: $input) {
      id
      title
      instructions
      passingScore
      questions {
        id
        question
        instructions
        questionType
        possibleAnswers {
          id
          answer
        }
        correctAnswers {
          id
          answer
        }
      }
    }
  }
`;

export const GetTeaserSurveyContent = gql`
  query getTeaserSurveyContent($input: UserTeaserTrainingTaskInput!) {
    getTeaserSurveyContent(input: $input) {
      id
      title
      instructions
      questions {
        id
        question
        instructions
        type
        possibleAnswers {
          id
          answer
        }
      }
    }
  }
`;

export const MarkTeaserAsStarted = gql`
  mutation markTeaserAsStarted($input: UserTeaserTrainingTaskInput!) {
    markTeaserAsStarted(input: $input) {
      success
    }
  }
`;

export const MarkTeaserAsCompleted = gql`
  mutation markTeaserAsCompleted($input: UserTeaserTrainingTaskInput!) {
    markTeaserAsCompleted(input: $input) {
      success
    }
  }
`;
