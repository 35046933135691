import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { breakpoints } from "style/constants";
import { Button, StyledGrid, AssessmentCard } from "components/simple";
import { StyledCard, StyledButton } from "./styled";

interface Props {
  answer?: string[];
  callback: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
  choices: string[];
  exclusive?: Exclusive;
  prompt: string;
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
}
interface ExclusiveResponses {
  byAnswer: string[];
  excludedResponses: string[];
}

interface Exclusive {
  rules: ExclusiveResponses[];
}

interface CardButtonProps {
  value: string;
  response: string[];
  excludedOptions?: string[];
  callback: React.Dispatch<React.SetStateAction<any>>;
}

const CardButton: React.FC<CardButtonProps> = ({
  callback,
  value,
  excludedOptions,
  response
}: CardButtonProps) => {
  const [selected, setSelected] = useState(response.includes(value));

  useEffect(() => {
    setSelected(response.includes(value));
  }, [response, value]);

  const handleClick = (): void => {
    if (selected) {
      const filteredResponse = response.filter(
        responseValue => responseValue !== value
      );
      callback(filteredResponse);
      setSelected(false);
    } else {
      if (excludedOptions) {
        const newResponse = response.filter(
          res => !excludedOptions.includes(res)
        );
        callback([...newResponse, value]);
      } else {
        callback([...response, value]);
        setSelected(true);
      }
    }
  };
  return (
    <StyledButton
      onClick={handleClick}
      role="checkbox"
      aria-checked={selected}
      aria-label={value}
    >
      <StyledCard selected={selected}>
        <CardContent>{value}</CardContent>
      </StyledCard>
    </StyledButton>
  );
};

const MultipleResponseQuestion: React.FC<Props> = ({
  answer,
  callback,
  choices,
  exclusive,
  prompt,
  skipCallback
}: Props) => {
  const [disabled, setDisabled] = useState(true);
  const [response, setResponse] = useState<string[]>([]);
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  useEffect(() => {
    setDisabled(answer ? false : true);
    setResponse(answer || []);
  }, [answer, prompt]);

  const handleSetResponse = (value: string[]): void => {
    setResponse(value);
    if (value.length !== 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <AssessmentCard
      question={prompt}
      subtext="Select all that apply"
      skipCallback={skipCallback}
    >
      <StyledGrid
        item
        container
        itemMargin="0.3rem 1rem"
        width={mobile ? "300px" : "538px"}
      >
        {choices.map((choice: string, index: number) => {
          const exclusiveRule = exclusive?.rules.filter(rule =>
            rule.byAnswer.includes(choice)
          );
          const exclusiveRuleReverse = exclusive?.rules.filter(rule =>
            rule.excludedResponses.includes(choice)
          );
          if (exclusiveRule && exclusiveRule.length > 0) {
            const excludedOptions: string[] = [];
            exclusiveRule.forEach(rule => {
              excludedOptions.push(...rule.excludedResponses);
            });
            const uniqueExcludedOptions = [...new Set(excludedOptions)];
            return (
              <CardButton
                key={index}
                callback={handleSetResponse}
                value={choice}
                excludedOptions={uniqueExcludedOptions}
                response={response}
              />
            );
          } else if (exclusiveRuleReverse && exclusiveRuleReverse.length > 0) {
            const excludedOptions: string[] = [];
            exclusiveRuleReverse.forEach(rule => {
              excludedOptions.push(...rule.byAnswer);
            });
            const uniqueExcludedOptions = [...new Set(excludedOptions)];
            return (
              <CardButton
                key={index}
                callback={handleSetResponse}
                value={choice}
                excludedOptions={uniqueExcludedOptions}
                response={response}
              />
            );
          } else {
            return (
              <CardButton
                key={index}
                callback={handleSetResponse}
                value={choice}
                response={response}
              />
            );
          }
        })}
      </StyledGrid>
      <StyledGrid item margin={mobile ? "0" : "40px 0"}>
        <Button
          data-testid="next-btn"
          wide={!mobile}
          disabled={disabled}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={() => {
            callback({ response: response });
          }}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default MultipleResponseQuestion;
