import React from "react";

import { StyledGrid, WidgetTitle } from "components/simple";
import { spacingDefaults } from "style/constants";
import { CoachingSession, ImagingSession } from "../types";
import CoachingSessions from "../CoachingSessions";
import ImagingSessions from "../ImagingSessions";

interface PastSessionsProps {
  imagingSessions?: ImagingSession[];
  coachingSessions?: CoachingSession[];
}

const PastSessions: React.FC<PastSessionsProps> = ({
  imagingSessions = [],
  coachingSessions = []
}: PastSessionsProps) => {
  return (
    <StyledGrid container>
      <StyledGrid item xs={12} margin={`${spacingDefaults.normal} 0`}>
        <WidgetTitle>Past Sessions</WidgetTitle>
      </StyledGrid>
      <StyledGrid item xs={12} container>
        <ImagingSessions imagingSessions={imagingSessions} />
        <CoachingSessions coachingSessions={coachingSessions} />
      </StyledGrid>
    </StyledGrid>
  );
};

export default PastSessions;
