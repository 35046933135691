import { gql } from "apollo-boost";

export const GetGroupCoachingCalendarEvents = gql`
  query GetGroupCoachingCalendarEvents($input: GroupCoachingSessionIdInput!) {
    groupCoachingSession(input: $input) {
      sessionTime
      googleCalendarLink
      icalFileDetails
      icsFileDetails
    }
  }
`;

export const AddParticipantToGroupCoachingSession = gql`
  mutation AddParticipantToGroupCoaching($input: GroupCoachingRsvpInput!) {
    addParticipantToGroupCoachingSession(input: $input) {
      success
    }
  }
`;

export const GetGroupSessions = gql`
  query GetGroupSessions {
    groupCoachingSessions {
      id
      title
      sessionTime
      status
      groupCoachingJoinLink
    }
  }
`;

export const MarkParticipantAsAttended = gql`
  mutation MarkParticipantAsAttended($input: GroupCoachingSessionIdInput!) {
    markParticipantAsAttended(input: $input) {
      success
    }
  }
`;
