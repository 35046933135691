import React from "react";
import { chain } from "lodash";
import { Box } from "@material-ui/core";

import { PartCard as AssessmentPartCard, StyledGrid } from "components/simple";
import { color, spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import { StyledCard } from "components/pages/DashboardV2/styled";

interface AssessmentPart {
  partName: string;
  order: number;
  status: "Locked" | "Begin" | "Completed" | undefined;
  groupedAssessments: AssessmentGroup[];
}

interface AssessmentGroup {
  groupName: string;
  order: number;
  timeToComplete: number;
}

interface UserAssessment {
  status: string;
  assessment: {
    timeToComplete: number;
    assessmentGrouping: {
      name: string;
      order: number;
      assessmentPart: {
        name: string;
        order: number;
      };
    };
  };
}

interface UserSurveyCycle {
  status: string;
  daysToComplete: number;
  userSurveys: UserSurvey[];
}

interface UserSurvey {
  id: string;
  completionDate: any;
  survey: Survey;
}

interface Survey {
  id: string;
  name: string;
}

interface AssessmentProgressProps {
  userAssessments: UserAssessment[];
  userSurveys: UserSurvey[];
  disabled?: boolean;
}

const AssessmentProgress: React.FC<AssessmentProgressProps> = ({
  userAssessments,
  userSurveys,
  disabled = false
}: AssessmentProgressProps) => {
  return (
    <>
      <StyledGrid container>
        <StyledGrid item marginBottom={spacingDefaults.xsmall} xs={12}>
          <Box
            color={color.BLACK}
            component="h2"
            fontSize="20px"
            fontWeight="normal"
            lineHeight="24px"
            margin="0"
          >
            Assessment Progress
          </Box>
        </StyledGrid>

        {getAssessmentParts()}
        {/* get mtec specific parts for surveys */}
      </StyledGrid>
      {userSurveys?.length > 0 && (
        <StyledGrid container marginTop={spacingDefaults.large}>
          <StyledGrid item marginBottom={spacingDefaults.xsmall} xs={12}>
            <Box
              color={color.BLACK}
              component="h2"
              fontSize="20px"
              fontWeight="normal"
              lineHeight="24px"
              margin="0"
            >
              Surveys
            </Box>
          </StyledGrid>
          {getRequiredSurveys()}
        </StyledGrid>
      )}
    </>
  );

  function getRequiredSurveys(): JSX.Element[] {
    return userSurveys?.map((us: UserSurvey, idx: number) => (
      <StyledCard item key={idx} marginTop={spacingDefaults.xsmall} xs={12}>
        <AssessmentPartCard
          key={idx}
          title={us?.survey?.name}
          state={us?.completionDate ? "Completed" : "Begin"}
          link={`${routesConfig.surveys.survey.path}/${us?.survey?.id}/${us?.id}`}
          assessmentGroupList={[]}
          disabled={false}
          removeBorderStyle
          surveys={true}
        />
      </StyledCard>
    ));
  }

  function getAssessmentParts(): JSX.Element[] {
    const assessmentGroups = chain(userAssessments)
      .groupBy("assessment.assessmentGrouping.name")
      .map(
        (userAssessments, key) =>
          ({
            groupName: key,
            order: userAssessments[0].assessment.assessmentGrouping.order,
            timeToComplete:
              Math.ceil(
                userAssessments.reduce(
                  (totalTimeToComplete, userAssessment) =>
                    totalTimeToComplete +
                    userAssessment.assessment.timeToComplete,
                  0
                ) / 5
              ) * 5
          } as AssessmentGroup)
      )
      .value();

    const assessmentParts = chain(userAssessments)
      .groupBy("assessment.assessmentGrouping.assessmentPart.name")
      .map(
        (userAssessments, key) =>
          ({
            partName: key,
            order:
              userAssessments[0].assessment.assessmentGrouping.assessmentPart
                .order,
            status: (() => {
              let status = "Locked";

              if (userAssessments.some(x => x.status === "next")) {
                status = "Begin";
              }

              if (userAssessments.every(x => x.status === "completed")) {
                status = "Completed";
              }

              return status;
            })(),
            groupedAssessments: (() => {
              const result: AssessmentGroup[] = [];

              userAssessments.forEach(userAssessment => {
                const assessmentGroup = assessmentGroups.find(
                  assessmentGroup =>
                    assessmentGroup.groupName ===
                    userAssessment.assessment.assessmentGrouping.name
                );

                if (assessmentGroup && !result.includes(assessmentGroup)) {
                  result.push(assessmentGroup);
                }
              });

              result.sort((a, b) => a.order - b.order);

              return result;
            })()
          } as AssessmentPart)
      )
      .value();

    return assessmentParts
      .sort((a, b) => a.order - b.order)
      .map((assessmentPart, index) => (
        <StyledCard item key={index} marginTop={spacingDefaults.xsmall} xs={12}>
          <AssessmentPartCard
            title={assessmentPart.partName}
            state={assessmentPart.status}
            link={routesConfig.assessments.intro.path}
            assessmentGroupList={assessmentPart.groupedAssessments}
            disabled={disabled}
            removeBorderStyle
          />
        </StyledCard>
      ));
  }
};

export default AssessmentProgress;
