import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import StyledGrid from "components/simple/StyledGrid";
import { SchedulingBanner } from "components/simple/Banners";
import { useParams } from "react-router-dom";
import Details from "./Details";
import Calendar from "./Calendar";
import { BackButton } from "components/simple";
import { breakpoints, spacingDefaults } from "style/constants";
import {
  GetAvailableTimeSlots,
  GetFirstAvailability
} from "graphql/sessions/sessions.gql";
import { useMediaQuery } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showErrorNotification } from "state";

export interface MonthlyAvailablity {
  date: string;
}

interface TimeSlots {
  time: Date;
  formattedTime: string;
}

export interface AvailableTime {
  timeOfDay: string;
  times: TimeSlots[];
}

interface Params {
  appointmentType: string;
}

const Scheduling: React.FC = () => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [currentDate, setCurrentDate] = useState<string>("");
  const [minDate, setMinDate] = useState<string>("");
  const [availableTimes, setAvailableTimes] = useState<AvailableTime[]>([]);
  const [initialAppointments, setInitialAppointments] = useState<
    MonthlyAvailablity[]
  >([]);

  const { appointmentType } = useParams<Params>();

  interface MonthlyAvailabilityResponse {
    getFirstAvailability: MonthlyAvailablity[];
  }

  interface AvailableTimesResponse {
    getAvailabilityForDate: AvailableTime[];
  }

  useQuery<MonthlyAvailabilityResponse>(GetFirstAvailability, {
    variables: {
      input: {
        appointmentType,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },

    fetchPolicy: "cache-and-network",
    onCompleted(firstAvailableData) {
      const firstDateAvailable =
        firstAvailableData.getFirstAvailability[0]?.date;
      setCurrentDate(firstDateAvailable);
      setMinDate(firstDateAvailable);
      setInitialAppointments(firstAvailableData?.getFirstAvailability);
    },
    onError() {
      dispatch(
        showErrorNotification("", "Error retrieving appointment availability")
      );
    }
  });

  useQuery<AvailableTimesResponse>(GetAvailableTimeSlots, {
    variables: {
      input: {
        date: currentDate,
        appointmentType,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    skip: currentDate === "",
    fetchPolicy: "cache-and-network",
    onCompleted(availableTimesData) {
      setAvailableTimes(availableTimesData.getAvailabilityForDate);
    },
    onError() {
      dispatch(
        showErrorNotification("", "Error retrieving appointment availability")
      );
    }
  });

  return (
    <>
      {currentDate && availableTimes && (
        // Main Container
        <StyledGrid container>
          <BackButton location="/dashboard" />
          <SchedulingBanner appointmentType={appointmentType} />

          {/* Content Container */}
          <StyledGrid
            container
            item
            justifyContent="center"
            style={{ columnGap: "212px" }}
            alignItems="center"
            marginTop={spacingDefaults.xlarge}
            spacing={mobile ? 3 : 0}
          >
            {/* Date Picker - Left Column  */}
            <StyledGrid item md={12} maxWidth="377px">
              <Calendar
                currentDate={currentDate}
                appointmentType={appointmentType}
                minDate={minDate}
                initialAppointments={initialAppointments}
                onDateChange={(e: Date) => setCurrentDate(e.toDateString())}
              />
            </StyledGrid>

            {/* Text Content - Right Column */}
            <StyledGrid item md={12} maxWidth="346px" minWidth="346px">
              <Details
                currentDate={currentDate}
                appointmentType={appointmentType}
                availableTimes={availableTimes}
              />
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default Scheduling;
