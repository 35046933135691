import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as FinishLineImage } from "assets/finish-line.svg";
import { StyledGrid, WidgetTitle, StyledA, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import { StyledButton } from "../styled";
import { useTlmsLink } from "../utils";

interface TrainingCompleteProps {
  handleViewAllCourses(): void;
  disabled?: boolean;
}

const TrainingComplete: React.FC<TrainingCompleteProps> = ({
  handleViewAllCourses,
  disabled = false
}: TrainingCompleteProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const { connectToTlms } = useTlmsLink();

  return (
    <StyledGrid container>
      <StyledGrid container item justifyContent="space-between">
        <WidgetTitle>Training Status</WidgetTitle>
        {!disabled && (
          <StyledA
            aria-disabled={disabled}
            onClick={disabled ? undefined : handleViewAllCourses}
          >
            View All Courses
          </StyledA>
        )}
      </StyledGrid>

      <StyledGrid
        alignItems="center"
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        centerContent
        container
        direction="column"
        item
        marginTop={spacingDefaults.normal}
        padding="40px 0"
        mobilePadding="40px"
      >
        <StyledGrid marginBottom={spacingDefaults.normal}>
          <FinishLineImage height={160} width={240} />
        </StyledGrid>

        <StyledGrid marginBottom={spacingDefaults.normal}>
          <StyledP
            color={color.BLACK}
            fontFamily={fontFamily.secondary}
            fontSize="26px"
            lineHeight="33px"
            textAlign="center"
          >
            You finished your BrainHealth Training!
          </StyledP>
        </StyledGrid>

        <StyledGrid marginBottom={spacingDefaults.normal}>
          <StyledP
            lineHeight="22px"
            textAlign="center"
            width={mobile ? undefined : "375px"}
          >
            You will be able to start your next round of training after your
            next round of assessments.
            <br />
            In the meantime, explore our BrainHealth Resources!
          </StyledP>
        </StyledGrid>

        <StyledGrid marginBottom={spacingDefaults.xsmall}>
          <RouterLink
            aria-disabled={disabled}
            onClick={disabled ? x => x.preventDefault() : undefined}
            to="/resources"
          >
            <StyledButton height="45px" width="225px">
              Explore Resources
            </StyledButton>
          </RouterLink>
        </StyledGrid>
        {!disabled && (
          <StyledButton
            height="45px"
            inverted
            width="225px"
            disabled={disabled}
            onClick={() => connectToTlms()}
          >
            Go To Courses
          </StyledButton>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default TrainingComplete;
