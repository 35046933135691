import { useEffect } from "react";

function useDelay(seconds: number, callback: () => void): void {
  useEffect(() => {
    const timer = setTimeout(() => callback(), seconds);

    return () => {
      clearTimeout(timer);
    };
  }, []); //eslint-disable-line
}

export default useDelay;
