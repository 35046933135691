import React from "react";
import { FieldProps } from "formik";

import { DatePicker } from "components/simple";

const FormDatePicker: React.FC<FieldProps> = ({
  field,
  form,
  ...other
}: FieldProps) => {
  const hasError = form.touched.dob && !!form.errors.dob;
  function getHelperText(): string {
    if (!hasError) return "";
    if (field.value === null || String(field.value) === "") {
      return "Date of Birth is Required.";
    } else {
      return "Invalid Date";
    }
  }

  return (
    <DatePicker
      label={"Date of Birth"}
      format={"MM/dd/yyyy"}
      name={field.name}
      value={field.value}
      onChange={date => {
        if (date) {
          const stringDate = new Date(date).toLocaleDateString();
          form.setFieldValue(field.name, stringDate, true);
        } else {
          form.setFieldValue(field.name, null, true);
        }
      }}
      error={hasError}
      helperText={getHelperText()}
      {...other}
    />
  );
};

export default FormDatePicker;
