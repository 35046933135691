import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { IconButton, useMediaQuery } from "@material-ui/core";
import {
  breakpoints,
  color,
  fontSize,
  fontFamily,
  spacingDefaults
} from "style/constants";
import { Button, StyledGrid, StyledP } from "components/simple";
import { ReactComponent as CloseButton } from "assets/close.svg";
import { AnswerOptions, DigitSymbolQuestionData, Platform } from "..";

interface AnswerStatusProps {
  platform: Platform;
  question: DigitSymbolQuestionData;
  userAnswer: AnswerOptions;
  showModal: boolean;
  handleCloseModal: () => void;
}

export const AnswerStatusModal: React.FC<AnswerStatusProps> = ({
  platform,
  question,
  userAnswer,
  showModal,
  handleCloseModal
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  useEffect(() => {
    if (platform == Platform.Keyboard) {
      function handleKeyDown(e: { target: any; keyCode: any }) {
        handleCloseModal();
      }
      document.addEventListener("keydown", handleKeyDown);

      return function cleanup() {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [userAnswer]);

  function getTitle(): string {
    switch (userAnswer) {
      case AnswerOptions.CORRECT:
        return "That's Right!";
      case AnswerOptions.INCORRECT:
        return "Oops...";
      case AnswerOptions.INVALID:
        return "Invalid Response";
      case AnswerOptions.NO_ANSWER:
      default:
        return "Time's Up";
    }
  }

  function getBody(): string {
    switch (userAnswer) {
      case AnswerOptions.CORRECT:
        return question.isCorrect
          ? "The digit-symbol pair correctly correctly matches the key."
          : "The digit-symbol pair does not match.";
      case AnswerOptions.INCORRECT:
        return question.isCorrect
          ? "The digit-symbol pair IS a correct match."
          : "The digit-symbol pair does not match.";
      case AnswerOptions.INVALID:
        return 'Please press the "f" or "j" key.';
      case AnswerOptions.NO_ANSWER:
      default:
        return "Please answer before time is up.";
    }
  }

  return (
    <Modal open={showModal}>
      <>
        <StyledGrid
          absoluteCenter
          backgroundColor={color.WHITE}
          borderRadius="8px"
          container
          direction="column"
          maxWidth="350px"
          mobileMaxWidth="60%"
          padding={spacingDefaults.normal}
        >
          <StyledGrid item alignSelf="end" position="fixed">
            <IconButton aria-label="close-modal" onClick={handleCloseModal}>
              <CloseButton strokeWidth="1px" height="15px" width="15px" />
            </IconButton>
          </StyledGrid>
          <StyledGrid
            container
            direction="column"
            item
            height="100%"
            width="100%"
          >
            <StyledGrid item width="100%" textAlign="center">
              <StyledP
                textAlign="center"
                color={color.BLACK}
                fontSize={fontSize.large}
                fontFamily={fontFamily.secondary}
                fontWeight="bold"
                margintop={spacingDefaults.xlarge}
              >
                {getTitle()}
              </StyledP>
            </StyledGrid>
            <StyledGrid
              item
              width="100%"
              textAlign="center"
              padding={`${spacingDefaults.xlarge} ${spacingDefaults.medium}`}
            >
              <StyledP width="100%" color={color.BLACK} textAlign="center">
                {getBody()}
              </StyledP>
            </StyledGrid>
            <StyledGrid item width="100%" textAlign="center">
              <Button
                aria-label="next-button"
                onClick={handleCloseModal}
                wide={!mobile}
              >
                {[AnswerOptions.INVALID, AnswerOptions.INCORRECT].includes(
                  userAnswer
                )
                  ? "Try Again"
                  : platform == Platform.Keyboard
                  ? "Press any key to continue"
                  : "Tap to continue"}
              </Button>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </>
    </Modal>
  );
};
