import React from "react";

import { ReactComponent as BannerLeft } from "assets/challenge-banner-left.svg";
import { ReactComponent as BannerRight } from "assets/challenge-banner-right.svg";
import {
  StyledGrid,
  StyledP,
  WheelIcons,
  WidgetTitle
} from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import { StyledButton } from "../../styled";
import { DetailedCourseProgress } from "../../types";
import { useTlmsLink } from "../../utils";
import { CompletionStatus } from "../../types";

interface CourseBannerProps {
  courseProgress: DetailedCourseProgress;
  disabled?: boolean;
}

const DesktopCourseBanner: React.FC<CourseBannerProps> = ({
  courseProgress,
  disabled = false
}: CourseBannerProps) => {
  const { connectToTlms } = useTlmsLink();

  const courseNamewithCode = courseProgress?.name || "";
  const courseCode = courseProgress?.code;
  const courseDescription = courseProgress?.description;
  const courseName = courseNamewithCode.replace(`(${courseCode})`, "");
  const courseNotStarted =
    courseProgress.id === "160" &&
    courseProgress.completionStatus === CompletionStatus.NotAttempted;

  return (
    <StyledGrid container>
      <StyledGrid item xs={12}>
        <WidgetTitle>
          {courseNotStarted
            ? "Are You Ready to Begin Your Training?"
            : "Are You Ready to Jump Back to Your Training?"}
        </WidgetTitle>
      </StyledGrid>

      <StyledGrid
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        height="150px"
        item
        justifyContent="center"
        marginTop={spacingDefaults.normal}
        overflow="hidden"
        position="relative"
        xs={12}
      >
        <StyledGrid height="150px" item>
          <BannerLeft />
        </StyledGrid>

        <StyledGrid
          container
          direction="column"
          item
          justifyContent="center"
          padding={spacingDefaults.normal}
          xs
        >
          <StyledP
            color={color.BLACK}
            fontSize={courseDescription ? fontSize.medium : fontSize.large}
            letterSpacing="-0.9px"
          >
            <strong>{courseName}</strong>
          </StyledP>

          {courseDescription && (
            <StyledGrid marginTop={spacingDefaults.xsmall}>
              <StyledP fontSize={fontSize.small}>{courseDescription}</StyledP>
            </StyledGrid>
          )}
        </StyledGrid>

        <StyledGrid height="150px" item>
          <BannerRight />
        </StyledGrid>

        <StyledGrid
          item
          position="absolute"
          top={spacingDefaults.xsmall}
          right={spacingDefaults.small}
        >
          <WheelIcons pillars={courseProgress.pillars} />
        </StyledGrid>

        <StyledGrid
          alignItems="center"
          container
          height="100%"
          item
          justifyContent="flex-end"
          position="absolute"
          right={spacingDefaults.xlarge}
        >
          <StyledButton
            disabled={disabled}
            height="50px"
            width="225px"
            onClick={() => connectToTlms(courseProgress.id)}
          >
            {courseNotStarted ? "Begin Training" : "Keep Training"}
          </StyledButton>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default DesktopCourseBanner;
