import React from "react";
import { Grid, Hidden } from "@material-ui/core";

import { ReactComponent as DietIDChart } from "assets/diet-id-chart.svg";
import { StyledText, StyledHeader } from "../styled";

interface DietIdProp {
  dietidAccessCode: string;
}

const Begin: React.FC<DietIdProp> = ({ dietidAccessCode }: DietIdProp) => {
  return (
    <>
      <Hidden mdUp>
        <Grid style={{ height: "3.5rem" }} />
      </Hidden>
      <Grid item>
        <DietIDChart />
      </Grid>
      <Grid item>
        <StyledHeader>How is my data used and protected?</StyledHeader>
        <Hidden smDown>
          <Grid
            item
            style={{
              width: "620px",
              padding: "0 1rem",
              marginBottom: "2rem"
            }}
          >
            <StyledText>
              You are part of a pilot group helping us determine if DietID
              should be incorporated into the BrainHealth Index. If you opt-in
              to participate, the data you enter into DietID (height, weight,
              gender, age, activity level etc.) will be shared with us at the
              end of the 6-month pilot study. DietID has technical, physical and
              administrative safeguards in place to protect data.
              <br />
              <br />
              Remember to use passcode <b>{dietidAccessCode}</b> to access your
              Diet ID account.
              <br />
              <a href={"https://www.dietid.com/privacy-policy"}>
                <u>You can access their privacy policy.</u>
              </a>
              <br />
              <a href={"https://www.dietid.com/terms-of-service"}>
                <u>And General Terms of Service.</u>
              </a>
            </StyledText>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item style={{ padding: "0 1rem" }}>
            <StyledText>
              You are part of a pilot group helping us determine if DietID
              should be incorporated into the BrainHealth Index. If you opt-in
              to participate, the data you enter into DietID (height, weight,
              gender, age, activity level etc.) will be shared with us at the
              end of the 6-month pilot study. DietID has technical, physical and
              administrative safeguards in place to protect data.
              <br />
              <br />
              Remember to use passcode <b>{dietidAccessCode}</b> to access your
              Diet ID account.
              <br />
              <a href={"https://www.dietid.com/privacy-policy"}>
                <u>You can access their privacy policy.</u>
              </a>
              <br />
              <a href={"https://www.dietid.com/terms-of-service"}>
                <u>And General Terms of Service.</u>
              </a>
            </StyledText>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default Begin;
