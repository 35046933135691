import React from "react";
import { Grid } from "@material-ui/core";

import { ReactComponent as MobileUser } from "assets/mobile-user.svg";
import { StyledText } from "../../../styled";

const Begin: React.FC = () => {
  return (
    <>
      <Grid item>
        <MobileUser />
      </Grid>
      <Grid item>
        <StyledText>
          You’re in a <b>quiet place</b> like your home office or a quiet coffee
          shop.
        </StyledText>
      </Grid>
    </>
  );
};

export default Begin;
