import { Hidden, IconButton, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import NotificationPreferencesImage from "assets/notification-preferences.png";
import { Button, StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  iconSizes,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";

interface NotificationPreferencesBannerProps {
  dismiss(): Promise<void>;
}

const NotificationPreferencesBanner: React.FC<NotificationPreferencesBannerProps> = ({
  dismiss
}: NotificationPreferencesBannerProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.GREEN}
      borderRadius="8px"
      container
      overflow={mobile ? "hidden" : undefined}
      padding="20px"
      position="relative"
      onClick={
        mobile
          ? () => history.push(routesConfig.notificationPreferences.path)
          : undefined
      }
    >
      <Hidden mdDown>
        <StyledGrid bottom="-6px" item left="60px" position="absolute">
          <img
            alt="Notification Preference"
            src={NotificationPreferencesImage}
            height="125"
          />
        </StyledGrid>

        <StyledGrid container direction="column" item marginLeft="225px" xs>
          <StyledGrid item marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.WHITE}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.large}
            >
              <strong>Earn your Habit streak via text messages</strong>
            </StyledP>
          </StyledGrid>

          <StyledP color={color.WHITE}>
            Activate text notifications in your notification preferences.
          </StyledP>
        </StyledGrid>

        <StyledGrid item marginRight="40px">
          <Link to={routesConfig.notificationPreferences.path}>
            <Button
              aria-label="go-to-notification-preferences"
              inverted
              longText
              width="200px"
              onClick={dismiss}
            >
              Set Preferences
            </Button>
          </Link>
        </StyledGrid>
      </Hidden>

      <Hidden lgUp>
        <StyledGrid bottom="-20px" item left="5px" position="absolute">
          <img
            alt="Notification Preference"
            src={NotificationPreferencesImage}
            height="95"
          />
        </StyledGrid>

        <StyledGrid container direction="column" item marginLeft="110px">
          <StyledP color={color.WHITE} fontSize={fontSize.small}>
            Activate text notifications.
          </StyledP>

          <StyledP color={color.WHITE}>
            <strong>Set Preferences &gt;</strong>
          </StyledP>
        </StyledGrid>
      </Hidden>

      <StyledGrid item position="absolute" right="5px" top="5px">
        <IconButton
          aria-label="close-notification"
          onClick={event => {
            event.stopPropagation();
            dismiss();
          }}
        >
          <CloseIcon
            stroke={color.WHITE}
            height={iconSizes.smaller}
            width={iconSizes.smaller}
          />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default NotificationPreferencesBanner;
