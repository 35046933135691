import { IconButton } from "@material-ui/core";
import React from "react";

import { color, iconSizes } from "style/constants";
import { StyledGrid, Button } from "components/simple";
import { ReactComponent as Close } from "assets/close.svg";
import { Header, Paragraph } from "components/simple/ConfirmationModal/styled";

interface PinningHabitModalProps {
  icon?: JSX.Element;
  title?: string;
  content?: string;
  confirmText: string;
  onConfirm: React.Dispatch<any>;
  cancelText?: string;
  onCancel: React.Dispatch<any>;
}

const PinningHabitModal: React.FC<PinningHabitModalProps> = ({
  icon,
  title,
  content,
  confirmText,
  onConfirm,
  cancelText,
  onCancel
}: PinningHabitModalProps) => (
  <StyledGrid
    absoluteCenter
    backgroundColor={color.BLACK_RGBA}
    container
    centerContent
    height={"100%"}
    width={"100%"}
    style={{ zIndex: 1100 }}
    position="fixed"
  >
    <StyledGrid
      absoluteCenter
      backgroundColor={color.WHITE}
      borderRadius={"8px"}
      boxShadow={"0px 4px 14px rgba(223, 223, 223, 0.85);"}
      centerContent
      container
      direction={"column"}
      item
      padding={"1em"}
      width={"342px"}
    >
      <StyledGrid
        item
        position={"absolute"}
        style={{ top: "1em", right: "1em" }}
      >
        <IconButton aria-label="close" key="close" onClick={onCancel}>
          <Close height={iconSizes.smaller} width={iconSizes.smaller} />
        </IconButton>
      </StyledGrid>
      {icon && (
        <StyledGrid item marginTop="2rem">
          {icon}
        </StyledGrid>
      )}
      <StyledGrid item maxWidth={"250px"} marginBottom={"-1em"}>
        <Header>{title}</Header>
      </StyledGrid>
      <StyledGrid item maxWidth={"250px"}>
        <Paragraph>{content}</Paragraph>
      </StyledGrid>
      <StyledGrid item marginBottom={"2em"}>
        <Button onClick={onConfirm} width="100%">
          {confirmText}
        </Button>
      </StyledGrid>
      {cancelText && (
        <StyledGrid item marginTop={"-1.25em"} marginBottom={"2em"}>
          <Button inverted onClick={onCancel} width="100%">
            {cancelText}
          </Button>
        </StyledGrid>
      )}
    </StyledGrid>
  </StyledGrid>
);

export default PinningHabitModal;
