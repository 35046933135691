import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ReactComponent as Tree } from "assets/bhi-tree.svg";
import { color, fontFamily, fontSize, spacingDefaults } from "style/constants";
import { BarChart, Ruler, StyledGrid, StyledP } from "components/simple";
import { Logo } from "components/simple/Logo";
import { getFactorColor } from "components/simple/BHIChart/radar";
import { ChartContainer } from "components/simple/BHIChart/styled";
import { BHIChartType, scaleFactorScores } from "components/simple/BHIChart";
import {
  FactorInfo,
  GetCyclesData,
  GetFactorsData,
  NewCycle,
  NewPillarScore,
  PillarName
} from "../../types";
import { GetCycles, GetFactors } from "graphql/index/index.gql";
import { getSubFactorScores } from "../Overview";
import { Box } from "@material-ui/core";
import {
  convertToTitleCase,
  getFactorTree
} from "components/simple/FactorCard/FactorCardHeader";
import RichText from "components/pages/Contentful/Unit/RichText";
import { sortBy } from "lodash";

const Header: React.FC = () => {
  return (
    <StyledGrid item container height="50px">
      <StyledGrid item container>
        <StyledGrid item container xs={4}>
          <Logo styleType="pdfSmall" />
        </StyledGrid>
        <StyledGrid item container alignItems="center" xs={8}>
          <StyledP
            fontSize="12px"
            fontFamily={fontFamily.secondary}
            color={color.BLACK}
          >
            Your BrainHealth Index Report
          </StyledP>
        </StyledGrid>
      </StyledGrid>
      <StyledGrid item width="100%">
        <Ruler />
      </StyledGrid>
    </StyledGrid>
  );
};

interface PageProps {
  children: React.ReactNode;
  hideHeader?: boolean;
}
const Page: React.FC<PageProps> = ({ children, hideHeader }: PageProps) => {
  return (
    <StyledGrid
      container
      alignContent="flex-start"
      width="210mm"
      height="297mm"
      border={`solid 1px ${color.PALEGRAY}`}
      backgroundColor={color.WHITE}
      padding="5mm"
      overflow="hidden"
    >
      {hideHeader ? null : <Header />}
      {children}
    </StyledGrid>
  );
};

const PDFRefactor: React.FC = () => {
  const { search } = useLocation<{ selectedCycleIndex: number }>();
  const params = new URLSearchParams(search);
  const cycleNumber = Number(params.get("cycle")) || 0;

  const [factorInfo, setFactorInfo] = useState<FactorInfo[]>([]);
  const [buildingPDF, setBuildingPDF] = useState(false);
  const [allCycles, setAllCycles] = useState<NewCycle[]>();
  const [indexScore, setIndexScore] = useState<number>(0);
  const [connectedness, setConnectednessScore] = useState<number>(0);
  const [clarity, setClarityScore] = useState<number>(0);
  const [emotionalBalance, setEmotionalBalanceScore] = useState<number>(0);
  const [pillarScores, setPillarScores] = useState<NewPillarScore[]>();

  const { data, loading, error } = useQuery<GetCyclesData>(GetCycles);

  const { loading: factorInfoLoading } = useQuery<GetFactorsData>(GetFactors, {
    onCompleted: data => setFactorInfo(data.factors)
  });

  const downloadPDF = async (): Promise<void> => {
    const pages = document.getElementById("toPrint");
    if (!pages) return;
    setBuildingPDF(true);

    const canvas = await html2canvas(pages, { scale: 1 });

    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const doc = new jsPDF("p", "mm", undefined, true);

    let position = 0;
    let heightLeft = imgHeight;

    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    setBuildingPDF(false);
    doc.save("BrainHealth_Index_&_Assessment_Report.pdf");
  };

  useLayoutEffect(() => {
    if (!loading) downloadPDF();
  }, [loading]);

  useEffect(() => {
    if (data?.participantAssessmentCycles) {
      const cycleData = [...data.participantAssessmentCycles];
      const allCycleData = cycleData.sort(
        (a: NewCycle, b: NewCycle) => a.cycleNumber - b.cycleNumber
      );
      setAllCycles(cycleData);

      const factorScores = allCycleData[cycleNumber]?.bhiRefactorPillarScores;
      const index =
        factorScores?.find(x => x.pillarName === PillarName.NEW_INDEX)?.value ||
        0;
      setIndexScore(index);

      const scores = factorScores
        ?.filter(i => i.pillarName !== PillarName.NEW_INDEX)
        ?.map(x => x.value);

      const scaledFactors = scaleFactorScores(scores);
      const [connectedness, emotionalBalance, clarity] = scaledFactors;

      setConnectednessScore(connectedness);
      setEmotionalBalanceScore(emotionalBalance);
      setClarityScore(clarity);
      setPillarScores(factorScores);
    }
  }, [data]);

  if (loading || error) return null;

  return (
    <>
      {(buildingPDF || factorInfoLoading) && (
        <StyledGrid
          container
          textAlign="center"
          margin="15px"
          itemMargin="5px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={22} color="secondary" />
          Creating PDF...
        </StyledGrid>
      )}

      {pillarScores && allCycles && (
        <StyledGrid id="toPrint" margin="auto" width="210mm">
          <Page hideHeader>
            <StyledGrid item container margin="30px">
              <StyledGrid item margin="0px 100px 100px 100px">
                <StyledGrid
                  container
                  item
                  justifyContent="center"
                  marginBottom="64px"
                  marginTop="24px"
                >
                  <Logo styleType="pdfLarge" />
                </StyledGrid>
                <StyledP
                  fontSize="60px"
                  lineHeight="60px"
                  fontFamily={fontFamily.secondary}
                  color={color.BLACK}
                  textAlign="center"
                >
                  Your BrainHealth Index Report
                </StyledP>
              </StyledGrid>
              <StyledGrid item width="100%">
                <StyledP color={color.BLACK}>
                  Your BrainHealth Index &amp; Factor Scores
                </StyledP>
                <Ruler margins="10px 0px" />
              </StyledGrid>
              <StyledGrid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginTop="40px"
              >
                <StyledGrid item width="45%" textAlign="center">
                  <StyledP
                    textAlign="center"
                    color={color.DARKBLUE}
                    fontSize="50px"
                  >
                    {indexScore}
                  </StyledP>
                </StyledGrid>
                <StyledGrid item height="100%">
                  <Ruler orientation="vertical" />
                </StyledGrid>
                <StyledGrid item width="45%" margin="auto">
                  <ChartContainer
                    animationDuration={0}
                    clarity={clarity}
                    className="scaleIn"
                    connectedness={connectedness}
                    emotionalBalance={emotionalBalance}
                    type={BHIChartType.Tree}
                  >
                    <Tree />
                  </ChartContainer>
                  <StyledGrid
                    container
                    direction="column"
                    item
                    itemMargin="15px"
                    justifyContent="center"
                    margin={`${spacingDefaults.normal} 0`}
                  >
                    {pillarScores
                      .filter(x => x.pillarName !== PillarName.NEW_INDEX)
                      .map(data => {
                        return (
                          <StyledGrid item key={data.pillarName}>
                            <StyledP
                              textAlign="center"
                              color={getFactorColor(data.pillarName)}
                            >
                              {data.value}
                            </StyledP>
                            <StyledP textAlign="center">
                              {convertToTitleCase(data.pillarName)}
                            </StyledP>
                          </StyledGrid>
                        );
                      })}
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
          </Page>

          {pillarScores
            .filter(x => x.pillarName !== PillarName.NEW_INDEX)
            .map((data, idx) => {
              const subFactorLabels =
                factorInfo
                  .find(
                    factor =>
                      factor.name === convertToTitleCase(data.pillarName)
                  )
                  ?.subFactors.map(c => c.name)
                  .sort() || [];
              const subFactorScores = getSubFactorScores(
                allCycles[cycleNumber],
                subFactorLabels,
                data.pillarName
              );
              const factorDescription = factorInfo.find(
                factor => factor.name === convertToTitleCase(data.pillarName)
              )?.description;
              return (
                <React.Fragment key={idx}>
                  <Page>
                    <StyledGrid width="100%" container direction="column">
                      <StyledGrid
                        alignItems="center"
                        container
                        margin={`${spacingDefaults.xsmall} 0`}
                      >
                        <StyledGrid item xs={2}>
                          <img
                            src={getFactorTree(data.pillarName)}
                            height="100px"
                          />
                        </StyledGrid>
                        <StyledGrid item xs={10}>
                          <Box
                            color={getFactorColor(data.pillarName)}
                            fontSize={fontSize.large}
                          >
                            {convertToTitleCase(data.pillarName)}
                          </Box>
                          <Box
                            fontSize={fontSize.medium}
                            color={color.GRAPHITE}
                          >
                            {factorDescription}
                          </Box>
                        </StyledGrid>
                      </StyledGrid>
                      <StyledGrid
                        item
                        marginBottom={spacingDefaults.normal}
                        width="100%"
                      >
                        <Ruler />
                      </StyledGrid>
                      <BarChart
                        labels={subFactorLabels}
                        scores={subFactorScores}
                        selectedFactor={data.pillarName}
                        selectedSubFactor={{
                          index: 0,
                          name: "test",
                          description: ""
                        }}
                      />
                      <StyledGrid
                        container
                        direction="column"
                        margin={`${spacingDefaults.xlarge} 0`}
                      >
                        {sortBy(
                          factorInfo.find(
                            factor =>
                              factor.name ===
                              convertToTitleCase(data.pillarName)
                          )?.subFactors,
                          ["name"]
                        ).map((subFactor, idx) => (
                          <StyledGrid
                            container
                            direction="column"
                            key={idx}
                            margin={`${spacingDefaults.xsmall} 0`}
                          >
                            <Box fontSize={fontSize.normal}>
                              {subFactor.name}
                            </Box>
                            <Box
                              fontSize={fontSize.small}
                              color={color.GRAPHITE}
                            >
                              {subFactor.description}
                            </Box>
                          </StyledGrid>
                        ))}
                      </StyledGrid>
                    </StyledGrid>
                  </Page>
                  <Page>
                    {sortBy(
                      factorInfo.find(
                        factor =>
                          factor.name === convertToTitleCase(data.pillarName)
                      )?.subFactors,
                      ["name"]
                    ).map((subFactor, idx) => (
                      <StyledGrid
                        container
                        direction="column"
                        key={idx}
                        margin={`${spacingDefaults.xsmall} 0`}
                      >
                        <StyledGrid container>
                          <StyledGrid
                            item
                            marginTop={spacingDefaults.xsmall}
                            xs={1}
                          >
                            {idx === 0 && (
                              <img
                                src={getFactorTree(data.pillarName)}
                                height="50px"
                              />
                            )}
                          </StyledGrid>
                          <StyledGrid item xs={11}>
                            <Box fontSize={fontSize.normal}>
                              {subFactor.name}
                            </Box>
                            <Box
                              fontSize={fontSize.small}
                              color={color.GRAPHITE}
                            >
                              {subFactor.description}
                            </Box>
                            {subFactor?.longDescription && (
                              <RichText
                                customFontSize={fontSize.small}
                                data={subFactor.longDescription}
                                textColor={color.GRAPHITE}
                              />
                            )}
                          </StyledGrid>
                        </StyledGrid>
                      </StyledGrid>
                    ))}
                  </Page>
                </React.Fragment>
              );
            })}
        </StyledGrid>
      )}
    </>
  );
};

export default PDFRefactor;
