import { color, fontSize } from "style/constants";

export default {
  root: {
    height: 8,
    color: color.DARKBLUE
  },
  thumb: {
    height: "26px",
    width: "26px",
    backgroundColor: color.BLUE,
    marginTop: "-13px",
    marginLeft: "-12px",
    "&:focus,&:hover,&$active": {
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.4)"
    }
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: color.SLIDERGRAY
  },
  valueLabel: {
    marginLeft: "14px",
    marginTop: "12px",
    fontSize: fontSize.large,
    "& *": {
      background: "transparent",
      color: color.DARKBLUE,
      fontWeight: 500
    }
  },
  mark: {
    backgroundColor: color.LIGHTERGRAY,
    height: 10,
    width: 2,
    marginTop: -4
  },
  markActive: {
    backgroundColor: "currentColor"
  }
};
