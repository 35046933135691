import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import coinIcon from "assets/coin-icon.svg";
import {
  BackButton,
  Button,
  Loading,
  Ruler,
  StyledGrid
} from "components/simple";
import {
  appWidth,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { StyledCard } from "../DashboardV2/styled";
import StatSection from "./StatSection";
import { UserStreakDetailsForPeriod } from "graphql/userStreak/userStreak.gql";
import {
  UserStreakDetailForPeriodData,
  UserStreakDetailItem,
  UserStreakDetails
} from "models/userStreak";

const Stats: React.FC = () => {
  const [userStreakDetails, setUserStreakDetails] = useState<
    UserStreakDetails
  >();
  const history = useHistory();
  const { loading } = useQuery<UserStreakDetailForPeriodData>(
    UserStreakDetailsForPeriod,
    {
      variables: {
        input: {
          periodStart: null,
          periodEnd: null
        }
      },
      onCompleted: data => {
        setUserStreakDetails(data?.userStreakDetailsForPeriod);
      }
    }
  );

  if (loading) return <Loading />;

  return (
    <>
      {userStreakDetails && (
        <StyledGrid container justifyContent="center">
          <BackButton location="profile" />
          <StyledGrid
            alignSelf="center"
            container
            maxWidth={appWidth.max}
            marginTop={spacingDefaults.large}
            padding={spacingDefaults.medium}
          >
            <StyledCard
              minHeight="500px"
              width="100%"
              borderTop={`${spacingDefaults.xsmall} solid ${color.DARKBLUE}`}
              centerContent
              padding={spacingDefaults.large}
            >
              <StyledGrid
                container
                margin="auto"
                maxWidth="400px"
                direction="column"
                style={{ gap: spacingDefaults.normal }}
              >
                <Box
                  fontFamily={fontFamily.secondary}
                  fontSize={fontSize.header}
                >
                  Stats
                </Box>
                <StyledGrid container alignItems="center">
                  <StyledGrid item height="30px" width="15%">
                    <img src={coinIcon} height="100%" />
                  </StyledGrid>
                  <StyledGrid item width="85%">
                    <StyledGrid
                      container
                      justifyContent="space-between"
                      border={`1px solid ${color.YELLOW}`}
                      borderRadius="100px"
                      padding={`${spacingDefaults.xsmall} ${spacingDefaults.normal}`}
                      alignItems="center"
                      height="45px"
                    >
                      <StyledGrid item>
                        <Box>Total Coins Earned</Box>
                      </StyledGrid>
                      <StyledGrid item>
                        <Box>{userStreakDetails?.totalCoins || 0}</Box>
                      </StyledGrid>
                    </StyledGrid>
                  </StyledGrid>
                </StyledGrid>
                <StyledGrid item>
                  <Box
                    color={color.DARKERGRAY}
                    textAlign="center"
                    fontSize={fontSize.normal}
                  >
                    Spend coins to revive your streak in My Journey
                  </Box>
                </StyledGrid>
                {userStreakDetails?.userStreakDetails?.map(
                  (item: UserStreakDetailItem, idx: number) => (
                    <React.Fragment key={idx}>
                      <Ruler />
                      <StatSection userStreakDetails={item} />
                      {idx ===
                        userStreakDetails?.userStreakDetails?.length - 1 && (
                        <Ruler />
                      )}
                    </React.Fragment>
                  )
                )}
                <StyledGrid>
                  <Box>
                    <Button
                      onClick={() => {
                        history.push("/myjourney");
                      }}
                    >
                      Go to My Journey
                    </Button>
                  </Box>
                </StyledGrid>
              </StyledGrid>
            </StyledCard>
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default Stats;
