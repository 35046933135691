import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";

import {
  AssessmentCard,
  Button,
  MultiInput,
  StyledGrid,
  Timer
} from "components/simple";
import { breakpoints } from "style/constants";

interface MultiResponseQuestionProps {
  callback: React.Dispatch<React.SetStateAction<Record<"values", string[]>>>;
  hideWords?: boolean;
  deleteableChips?: boolean;
  question: string | JSX.Element;
  activeQuestion: number;
  subtext?: string;
  time?: number;
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
  oneWord?: boolean;
}

const MultiResponseQuestion: React.FC<MultiResponseQuestionProps> = ({
  question,
  activeQuestion,
  time,
  subtext,
  callback,
  skipCallback,
  hideWords,
  oneWord
}: MultiResponseQuestionProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [entries, setEntries] = useState<string[]>([]);
  const [timesUp, setTimesUp] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (timesUp || entries.length > 0) {
      setIsDisabled(false);
    }
  }, [timesUp, entries]);

  // reset state for next question of the same type
  useEffect(() => {
    setEntries([]);
    setIsDisabled(true);
  }, [question]);

  const handleClick = (): void => {
    const wordsWithoutSpaces = entries.map(word => word.trim());
    callback({ values: wordsWithoutSpaces });
  };

  return (
    <AssessmentCard
      question={timesUp ? "Time's Up" : question}
      subtext={timesUp ? "Your answers will be saved as they are." : subtext}
      skipCallback={skipCallback}
      timer={
        time && (
          <Timer
            seconds={time}
            onDone={setTimesUp}
            activeQuestion={activeQuestion}
          />
        )
      }
    >
      <MultiInput
        entries={entries}
        callback={setEntries}
        deleteableChips={false}
        hideInput={timesUp}
        hideWords={hideWords}
        timesUp={timesUp}
        oneWord={oneWord}
      />
      <StyledGrid item marginTop="auto">
        <Button
          data-testid="next-btn"
          wide={!mobile}
          disabled={isDisabled}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={handleClick}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default MultiResponseQuestion;
