import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { GetUserBrainGaugeData } from "graphql/user/user.gql";
import BrainGaugeResults from "./BrainGaugeResults";
import BrainGaugeCard from "./BrainGaugeCard";
import { useSelector } from "react-redux";
import { getUser } from "state";

export interface BrainGaugeData {
  completionDateTime: string;
  scores: BrainGaugeScore[];
  accessCode: string;
  userLocation: string;
  siteCoordinator: {
    name: string;
    phone: string;
    email: string;
    locations: string;
  };
  results: BrainGaugeResults[];
}

export interface BrainGaugeScore {
  name: string;
  score: number;
}

export interface BrainGaugeResults {
  completionDateTime: string;
  scores: BrainGaugeScore[];
}

const BrainGauge: React.FC = () => {
  const user = useSelector(getUser);
  const { data } = useQuery(GetUserBrainGaugeData, {
    variables: { input: { bhpUserId: user?.id } }
  });
  const brainGaugeData = data?.retrieveBrainGaugeDataForUser;
  const hasResults = brainGaugeData?.results?.length > 0;

  if (!brainGaugeData) return <></>;
  return hasResults ? (
    <BrainGaugeResults {...brainGaugeData} />
  ) : (
    <BrainGaugeCard {...brainGaugeData} />
  );
};

export default BrainGauge;
