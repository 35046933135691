import { gql } from "apollo-boost";

export const UserStreakForPeriod = gql`
  query UserStreakForPeriod($input: UserStreaksForPeriodInput!) {
    userStreakForPeriod(input: $input) {
      id
      habitCompleted
      trainingCompleted
      resourceViewed
      freeDayPassApplied
      screenBreakApplied
      date
      userRegistrationDate
      createdAt
    }
  }
`;

export const UserStreakDetailsForPeriod = gql`
  query UserStreakDetailsForPeriod($input: UserStreaksForPeriodInput!) {
    userStreakDetailsForPeriod(input: $input) {
      totalCoins
      userStreakDetails {
        title
        count
        ornament
        type
        coinsEarned
      }
    }
  }
`;
