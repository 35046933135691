import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { Document } from "@contentful/rich-text-types";
import { StyledGrid, StyledP, StyledA, Button } from "components/simple";
import {
  color,
  spacingDefaults,
  fontFamily,
  breakpoints,
  fontSize,
  appWidth
} from "style/constants";
import { useQuery } from "@apollo/react-hooks";
import { GetFaqs } from "graphql/faqs/faqs.gql";

import Nav from "./Nav";
import Content from "./Content";

interface Question {
  title: string;
  answer: Document;
}

export interface FaqData {
  title: string;
  questions: Question[];
}

const Faq: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const { data, loading } = useQuery(GetFaqs, { fetchPolicy: "cache-first" });
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    if (data?.getFaqs && data.getFaqs.length > 0) {
      setActiveSection(mobile ? "" : data?.getFaqs[0].title);
    }
  }, [mobile, data]);

  if (loading || !data) {
    return null;
  }

  const faqs: FaqData[] = data?.getFaqs;
  return (
    <StyledGrid
      alignSelf="center"
      container
      direction="column"
      maxWidth={appWidth.max}
      padding={spacingDefaults.normal}
    >
      {activeSection !== "" && mobile && (
        <StyledGrid
          container
          item
          position="fixed"
          marginLeft={spacingDefaults.xlarge}
          mobileMarginLeft="0"
          top={"120px"}
          mobileTop={"90px"}
        >
          <Button onClick={() => setActiveSection("")} plain aria-label="Back">
            <ChevronLeft />
          </Button>
        </StyledGrid>
      )}

      {((activeSection === "" && mobile) || !mobile) && (
        <StyledGrid
          marginTop={spacingDefaults.normal}
          marginBottom={spacingDefaults.normal}
        >
          <StyledP
            color={color.BLACK}
            fontFamily={fontFamily.secondary}
            fontSize="30px"
            letterSpacing="-0.45px"
            lineHeight="38px"
            textAlign="center"
          >
            Frequently Asked Questions
          </StyledP>

          <StyledP textAlign="center" margintop={spacingDefaults.normal}>
            Can’t find your answer here? Email us at{" "}
            <StyledA
              fontSize={fontSize.normal}
              color={color.DARKBLUE}
              href="mailto:support@thebrainhealthproject.org"
            >
              support@thebrainhealthproject.org
            </StyledA>
          </StyledP>
        </StyledGrid>
      )}

      <StyledGrid
        container
        item
        backgroundColor={mobile ? undefined : color.WHITE}
        border={mobile ? undefined : `1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        marginTop={spacingDefaults.normal}
        xs={12}
      >
        {((activeSection === "" && mobile) || !mobile) && (
          <Nav
            data={faqs}
            mobile={mobile}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        )}
        <StyledGrid item container xs>
          <Content data={faqs} mobile={mobile} activeSection={activeSection} />
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Faq;
