import { LinearProgress } from "@material-ui/core";
import styled from "styled-components";

import { color } from "style/constants";

export const StyledProgressBar = styled(LinearProgress)<{
  backgroundColor: color;
}>`
  && {
    background-color: ${props => props.backgroundColor}99;
    border-radius: 8px;
    height: 16px;

    .MuiLinearProgress-barColorPrimary {
      background-color: ${props => props.backgroundColor};
    }
  }
`;
