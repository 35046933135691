import styled from "styled-components";

interface Animate {
  animate: boolean;
}

export const FadeIn = styled.div<Animate>`
   {
    text-align: center;
    ${props =>
      props.animate &&
      `
    opacity: 0;
    transform: scale(0);
    animation-name: fade-in;
    animation-delay: 1.75s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;`}

    @keyframes fade-in {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

export const FadeOut = styled.div<Animate>`
   {
    width: 100%;
    ${props =>
      props.animate
        ? `
      opacity: 0;
      height: 0;
      transition: all 0.75s;
      transition-delay: 1s;`
        : `
      opacity: 1;
      `}
  }
`;
