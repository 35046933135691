import client from "graphql/client";
import { Me } from "graphql/user/user.gql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    _paq: any; // eslint-disable-line
  }
}

export const trackPageView = async (
  matomo: any, // eslint-disable-line
  trackingData: AnalyticsObj
): Promise<void> => {
  if (!matomo) return;
  const token = localStorage.getItem("token");
  let response;

  if (token) response = await client.query({ query: Me });
  if (response && response.data.me) {
    matomo.push(["setUserId", response.data.me.id]);
  }

  matomo.push([
    "setDocumentTitle",
    `${process.env.REACT_APP_PROD_FRONTEND_DOMAIN}${trackingData.url}`
  ]);
  matomo.push(["trackPageView"]);
};

export const trackEvent = async (
  matomo: any, // eslint-disable-line
  trackingData: AnalyticsObj
): Promise<void> => {
  if (!matomo) return;
  const token = localStorage.getItem("token");
  let response;

  if (token) response = await client.query({ query: Me });
  if (response && response.data.me) {
    matomo.push(["setUserId", response.data.me.id]);
  }

  matomo.push([
    "setDocumentTitle",
    `${process.env.REACT_APP_PROD_FRONTEND_DOMAIN}${trackingData.url}`
  ]);
  matomo.push(["trackEvent", ...trackingData.actions]);
};

export interface AnalyticsObj {
  url: string;
  actions: string[];
}
