import React from "react";

import SmallBlueTree from "assets/journeytray-smallbluetree.svg";
import BlueTree from "assets/journeytray-bluetree.svg";
import GreenTree from "assets/journeytray-greentree.svg";
import GreenTree_FruitNutFlower from "assets/journeytray-greentree-fruitnutflower.svg";
import GreenTree_FruitNut from "assets/journeytray-greentree-fruitnut.svg";
import GreenTree_FruitFlower from "assets/journeytray-greentree-fruitflower.svg";
import GreenTree_NutFlower from "assets/journeytray-greentree-nutflower.svg";
import GreenTree_Fruit from "assets/journeytray-greentree-fruit.svg";
import GreenTree_Nut from "assets/journeytray-greentree-nut.svg";
import GreenTree_Flower from "assets/journeytray-greentree-flower.svg";
import BlueTree_FruitNutFlower from "assets/journeytray-bluetree-fruitnutflower.svg";
import BlueTree_FruitNut from "assets/journeytray-bluetree-fruitnut.svg";
import BlueTree_FruitFlower from "assets/journeytray-bluetree-fruitflower.svg";
import BlueTree_NutFlower from "assets/journeytray-bluetree-nutflower.svg";
import BlueTree_Fruit from "assets/journeytray-bluetree-fruit.svg";
import BlueTree_Nut from "assets/journeytray-bluetree-nut.svg";
import BlueTree_Flower from "assets/journeytray-bluetree-flower.svg";
import GrayTree from "assets/journeytray-graytree.svg";
import { UserStreak } from "models/userStreak";
import styled from "styled-components";

const StreakTree = styled.img`
  align-self: center;
  width: 150px;
`;

interface JourneyTreeProps {
  isToday: boolean;
  userStreak?: UserStreak;
}

export const JourneyDrawerTree = ({
  isToday,
  userStreak
}: JourneyTreeProps) => {
  if (!userStreak) {
    // default to current-day blank tree
    return <StreakTree src={SmallBlueTree} />;
  }

  switch (isToday) {
    case true: {
      if (userStreak?.freeDayPassApplied || userStreak?.screenBreakApplied) {
        return <StreakTree src={BlueTree} />;
      }
      if (
        userStreak?.habitCompleted &&
        userStreak?.trainingCompleted &&
        userStreak?.resourceViewed
      ) {
        return <StreakTree src={BlueTree_FruitNutFlower} />;
      }
      if (userStreak?.habitCompleted && userStreak?.trainingCompleted) {
        return <StreakTree src={BlueTree_FruitFlower} />;
      }
      if (userStreak?.habitCompleted && userStreak?.resourceViewed) {
        return <StreakTree src={BlueTree_FruitNut} />;
      }
      if (userStreak?.trainingCompleted && userStreak?.resourceViewed) {
        return <StreakTree src={BlueTree_NutFlower} />;
      }
      if (userStreak?.habitCompleted) {
        return <StreakTree src={BlueTree_Fruit} />;
      }
      if (userStreak?.trainingCompleted) {
        return <StreakTree src={BlueTree_Flower} />;
      }
      if (userStreak?.resourceViewed) {
        return <StreakTree src={BlueTree_Nut} />;
      }

      return <StreakTree src={SmallBlueTree} />;
    }
    case false:
      if (userStreak?.freeDayPassApplied || userStreak?.screenBreakApplied) {
        return <StreakTree src={GreenTree} />;
      }
      if (
        userStreak?.habitCompleted &&
        userStreak?.trainingCompleted &&
        userStreak?.resourceViewed
      ) {
        return <StreakTree src={GreenTree_FruitNutFlower} />;
      }
      if (userStreak?.habitCompleted && userStreak?.trainingCompleted) {
        return <StreakTree src={GreenTree_FruitFlower} />;
      }
      if (userStreak?.habitCompleted && userStreak?.resourceViewed) {
        return <StreakTree src={GreenTree_FruitNut} />;
      }
      if (userStreak?.trainingCompleted && userStreak?.resourceViewed) {
        return <StreakTree src={GreenTree_NutFlower} />;
      }
      if (userStreak?.habitCompleted) {
        return <StreakTree src={GreenTree_Fruit} />;
      }
      if (userStreak?.trainingCompleted) {
        return <StreakTree src={GreenTree_Flower} />;
      }
      if (userStreak?.resourceViewed) {
        return <StreakTree src={GreenTree_Nut} />;
      }
      return <StreakTree src={GrayTree} />;
  }
};
