import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import track from "react-tracking";

import { color, breakpoints } from "style/constants";
import { Subtitle, Paragraph, CompleteImage } from "./styled";
import { Button } from "components/simple";
import StyledGrid from "components/simple/StyledGrid";
import routesConfig from "utils/routesConfig";

const url: string = routesConfig.assessments.complete.path;

const PartOutroFinal: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      centerContent
      container
      direction="column"
      fillRemainingHeight
      itemMargin={mobile ? "1rem" : "2rem"}
      marginTop="4rem"
      backgroundColor={color.WHITE}
    >
      <StyledGrid item>
        <CompleteImage />
      </StyledGrid>
      <StyledGrid item>
        <Subtitle>You are finished!</Subtitle>
      </StyledGrid>
      <StyledGrid container item itemMargin="0.75rem">
        <StyledGrid item width="284px">
          <Link to="/dashboard">
            <Button fullWidth>Return to Dashboard</Button>
          </Link>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default track({
  url
})(PartOutroFinal);
