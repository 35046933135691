import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import backgroundImage from "assets/registration.jpg";
import {
  backgroundImageStyle,
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

export const AccountCallout = styled.p`
  @media (max-width: ${breakpoints.lg}px) {
    text-align: center;
  }
  color: ${color.DARKERGRAY};
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const BackgroundImage = styled(Grid)`
  && {
    ${backgroundImageStyle(backgroundImage)}
    position: relative;
  }
`;

export const ConfirmationTitle = styled.h1`
  text-align: center;
`;

export const Hr = styled.hr`
  @media (max-width: ${breakpoints.lg}px) {
    margin: 0;
  }
  border: 1px solid ${color.MUTEDGRAY};
  border-bottom: none;
  margin: ${spacingDefaults.medium} 0;
`;

export const ImageOverlay = styled.div`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  height: 25%;
  position: relative;
  top: 75%;
`;

export const RegisterCallout = styled.p`
  color: ${color.DARKERGRAY};
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const Title = styled.h1`
  @media (max-width: ${breakpoints.lg}px) {
    font-size: 1.875rem;
  }
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: 2.25rem;
  font-weight: normal;
  line-height: 2.5625rem;
  margin: 0;
`;

export const TermsCondition = styled.p`
  font-family: Museo Sans;
  font-size: 12px;
  line-height: 18px;
  width: 249px;
  color: #707070;
`;

export const StyledLink = styled.a`
  font-family: Museo Sans;
  font-size: 12px;
  line-height: 18px;
  color: black;
`;

export const StyledLabel = styled.label`
  font-size: ${fontSize.xsmall};
  color: ${color.DARKERGRAY};
`;
