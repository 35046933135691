import React from "react";
import styled from "styled-components";

import { Hidden, useMediaQuery } from "@material-ui/core";
import { ReactComponent as FeaturedLeft } from "assets/featured-left.svg";
import { ReactComponent as FeaturedRight } from "assets/featured-right.svg";

import MediaIndicator from "../MediaIndicator";

import { StyledGrid, WheelIcons, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { Link } from "react-router-dom";

const FeaturedArticleLink = styled(Link)`
  && {
    background-color: rgba(30, 30, 30, 0.8);
    cursor: pointer;
    display: block;
    transition: background-color 0.5s ease;
    &:hover {
      background-color: rgba(30, 30, 30, 0.95);
    }
    &:focus {
      background-color: rgba(30, 30, 30, 0.95);
    }
  }
`;

interface FeaturedArticleProps {
  id: string;
  createdAt: string;
  callback?: any;
  coverImage?: {
    description: string;
    url: string;
  };
  hasVideo?: boolean;
  pillars?: any;
  readingTime?: number;
  slug?: string;
  source?: string;
  summary?: string;
  target: string;
  title: string;
  url: string;
  completed: boolean;
}

const FeaturedArticle: React.FC<FeaturedArticleProps> = ({
  id,
  createdAt,
  callback,
  coverImage,
  hasVideo = false,
  pillars,
  readingTime,
  slug,
  source,
  summary,
  target,
  title,
  url,
  completed
}: FeaturedArticleProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      borderRadius="8px"
      boxShadow={`0px 4px 24px rgba(0, 0, 0, 0.5)`}
      backgroundImage={coverImage ? coverImage.url + "?w=400&h=400" : ""}
      item
      marginTop={spacingDefaults.normal}
      overflow="hidden"
      xs={12}
    >
      <FeaturedArticleLink
        to={{
          pathname: target === "internal" ? `/resources/${slug}/${id}` : url
        }}
        onClick={callback}
        target={target === "internal" ? "_self" : "_blank"}
        data-testid="article-link"
      >
        <StyledGrid
          container
          height={mobile ? "210px" : "175px"}
          item
          justifyContent="space-between"
        >
          <Hidden mdDown>
            <StyledGrid height="175px" item>
              <FeaturedLeft height="100%" />
            </StyledGrid>

            <StyledGrid
              container
              direction="column"
              item
              justifyContent="center"
              padding={spacingDefaults.normal}
              spacing={2}
              xs
            >
              <StyledGrid item>
                <StyledP
                  color={color.WHITE}
                  fontFamily={fontFamily.secondary}
                  fontSize={fontSize.large}
                  fontWeight="900"
                >
                  {title}
                </StyledP>
              </StyledGrid>
              {summary && (
                <StyledGrid item>
                  <StyledP color={color.WHITE} fontSize={fontSize.small}>
                    {summary}
                  </StyledP>
                </StyledGrid>
              )}
              {source && (
                <StyledGrid item>
                  <StyledP
                    color={color.WHITE}
                    fontSize={fontSize.xsmall}
                    fontWeight="500"
                  >
                    {source}
                  </StyledP>
                </StyledGrid>
              )}
            </StyledGrid>

            <StyledGrid
              alignItems="flex-end"
              container
              direction="column"
              height="100%"
              item
              justifyContent="space-between"
              padding={spacingDefaults.normal}
              width="150px"
            >
              <StyledGrid item>
                <MediaIndicator
                  readingTime={readingTime}
                  hasVideo={hasVideo}
                  createdAt={createdAt}
                  completed={completed}
                />
              </StyledGrid>
              <StyledGrid item>
                {pillars && <WheelIcons pillars={pillars} />}
              </StyledGrid>
            </StyledGrid>
          </Hidden>

          <Hidden lgUp>
            <StyledGrid
              container
              direction="column"
              item
              justifyContent="center"
              spacing={2}
              xs
              padding={spacingDefaults.medium}
            >
              <StyledGrid item>
                <StyledP
                  color={color.WHITE}
                  fontFamily={fontFamily.secondary}
                  fontSize={fontSize.large}
                  fontWeight="900"
                  textAlign="center"
                >
                  {title}
                </StyledP>
              </StyledGrid>
              <StyledGrid item>
                <StyledP
                  color={color.WHITE}
                  fontSize={fontSize.xsmall}
                  fontWeight="500"
                  textAlign="center"
                >
                  {source}
                </StyledP>
              </StyledGrid>
            </StyledGrid>

            <StyledGrid
              alignItems="flex-end"
              container
              direction="column"
              height="100%"
              item
              justifyContent="space-between"
              position="relative"
              xs={4}
            >
              <StyledGrid
                display="inline-block"
                position="absolute"
                right="10px"
                top="10px"
              >
                <MediaIndicator
                  readingTime={readingTime}
                  hasVideo={hasVideo}
                  createdAt={createdAt}
                  completed={completed}
                />
              </StyledGrid>

              <StyledGrid item>
                <FeaturedRight height="100%" />
              </StyledGrid>

              <StyledGrid
                display="inline-block"
                position="absolute"
                right="10px"
                bottom="10px"
              >
                <WheelIcons pillars={pillars} />
              </StyledGrid>
            </StyledGrid>
          </Hidden>
        </StyledGrid>
      </FeaturedArticleLink>
    </StyledGrid>
  );
};
export default FeaturedArticle;
