import React from "react";
import Card, { CardProps } from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup, { RadioGroupProps } from "@material-ui/core/RadioGroup";
import { Divider, Grid } from "@material-ui/core";
import styled from "styled-components";

import {
  color,
  fontSize,
  spacing,
  spacingDefaults,
  fontFamily
} from "style/constants";
import StyledGrid from "./StyledGrid";

interface StyledCard {
  label: string;
  selected?: boolean;
  large?: boolean;
  incorrect?: boolean;
  correct?: boolean;
  column?: boolean;
  icon?: string;
}

type StyledCardProps = StyledCard & CardProps;

interface StyledGroup {
  selections: string[];
  large?: boolean;
  margins?: spacingDefaults;
  fullWidth?: boolean;
  column?: boolean;
  incorrect?: boolean;
  correct?: boolean;
  disabled?: boolean;
  icons?: string[];
}

type StyledGroupProps = StyledGroup &
  RadioGroupProps & { touchscreen?: Boolean };

const unselectStyle = `
  border: 0.5px solid ${color.CARDGRAY};
  color: ${color.DARKGRAY};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
`;

const selectStyle = `
  border: 1px solid ${color.DARKBLUE};
  color: ${color.DARKBLUE};
  box-shadow: none;
`;

const incorrectStyle = `
  border: 1px solid ${color.LIGHTRED};
  color: ${color.DARKRED};
  box-shadow: none;
`;

const correctStyle = `
  border: 1px solid ${color.GREEN};
  color: ${color.GREEN};
  box-shadow: none;
`;

const IncorrectDiv = styled.div`
  font-family: ${fontFamily.primary};
  color: ${color.DARKRED};
  font-size: ${fontSize.medium};
`;

const CorrectDiv = styled.div`
  font-family: ${fontFamily.primary};
  color: ${color.GREEN};
  font-size: ${fontSize.medium};
`;

export const StyledCardGrid = styled(({ large, ...otherProps }) => (
  <Grid {...otherProps} />
))`
  && {
    min-height: ${props => (props.large ? "83px" : "48px")};
  }
`;

const RadioButton = styled(
  ({
    label,
    large,
    incorrect,
    correct,
    column,
    icon,
    ...otherProps
  }: StyledCardProps) => (
    <Grid container direction="column" justifyContent="flex-start">
      <Card {...otherProps}>
        <CardContent>
          <StyledCardGrid
            container
            direction={icon ? "row" : "column"}
            alignItems="center"
            justifyContent="center"
            large={large}
          >
            {icon ? (
              <>
                <StyledGrid item xs={4}>
                  <img src={icon} />
                </StyledGrid>
                <StyledGrid item xs={4}>
                  <Grid item>{label}</Grid>
                </StyledGrid>
                <StyledGrid item xs={4} />
              </>
            ) : (
              <Grid item>{label}</Grid>
            )}
          </StyledCardGrid>
        </CardContent>
      </Card>
      {incorrect && (
        <IncorrectDiv>
          Uh oh! This is not the correct answer. Try again.
        </IncorrectDiv>
      )}
      {correct && <CorrectDiv>Correct!</CorrectDiv>}
    </Grid>
  )
)`
  && {
    ${props => (props.selected ? selectStyle : unselectStyle)}
    ${props => props.incorrect && incorrectStyle}
    ${props => props.correct && correctStyle}
    width: ${props =>
      props.column ? `100%` : props.large ? `246px` : `128px`};
    min-height: ${props => (props.large ? `115px` : `80px`)};
    font-size: ${fontSize.medium};
    border-radius: 5px;
  }
` as React.FC<StyledCardProps>;

const SelectSection: React.FC<StyledGroup> = ({
  selections,
  large,
  incorrect,
  correct,
  disabled,
  column,
  icons
}: StyledGroup) => {
  return (
    <React.Fragment>
      {selections.map((A, i) => (
        <React.Fragment key={i}>
          {i > 0 && (
            <Divider
              style={{
                backgroundColor: "inherit",
                margin: `${column ? "5px 0" : `auto ${large ? "9px" : "7px"}`}`
              }}
            />
          )}
          <Radio
            key={i}
            name={"group0"}
            value={A}
            disabled={disabled}
            icon={
              <RadioButton
                label={A}
                large={large}
                column={column}
                icon={icons ? icons[i] : undefined}
              />
            }
            checkedIcon={
              <RadioButton
                label={A}
                large={large}
                selected
                incorrect={incorrect}
                correct={correct}
                column={column}
                icon={icons ? icons[i] : undefined}
              />
            }
            inputProps={{ "aria-label": A }}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const StyledGroup = styled(
  ({
    selections,
    large,
    incorrect,
    correct,
    disabled,
    column,
    icons,
    ...otherProps
  }) => (
    <RadioGroup {...otherProps} row={column ? false : true}>
      <SelectSection
        selections={selections}
        large={large}
        incorrect={incorrect}
        correct={correct}
        column={column}
        disabled={disabled}
        icons={icons}
      />
    </RadioGroup>
  )
)`
  && {
    margin: ${props => spacing(props.margins)};
    ${props =>
      props.touchscreen &&
      `width: 100%;
      justify-content: space-between;`}
  }
` as React.FC<StyledGroupProps>;

const RadioCard: React.FC<StyledGroupProps> = (Props: StyledGroupProps) => {
  const [value, setValue] = React.useState<string>();
  return (
    <StyledGroup
      onChange={(event: React.ChangeEvent<unknown>) =>
        setValue((event.target as HTMLInputElement).value)
      }
      value={value}
      {...Props}
    />
  );
};

export default RadioCard;
