import React from "react";
import get from "lodash/get";
import track from "react-tracking";

import { Information, BackButton, Video } from "components/simple";
import { PreambleQuery } from "graphql/userAssessments/userAssessments.gql";
import { UserAssessment } from "graphql/types/User";
import { useQueryCustom as useQuery } from "hooks";
import routesConfig from "utils/routesConfig";
import { useHistory } from "react-router-dom";

const url: string = routesConfig.assessments.start.path;

const PreamblePage: React.FC = () => {
  const history = useHistory();
  const { data, loading } = useQuery({ input: PreambleQuery });
  const preambleData: Partial<UserAssessment> = get(
    data,
    "getCurrentAssessment.assessment"
  );
  if (loading || !data) {
    return <></>;
  }
  const videoUrl = preambleData?.videoUrl;
  const timeLabel = preambleData?.timeLabel;
  const title = preambleData?.displayName;
  const body = preambleData?.preambleText;

  const props = {
    body: preambleData.preambleText,
    buttonText: "Begin",
    imageAlt: preambleData.preambleImageAltText,
    imageSrc: preambleData.preambleImage,
    title: preambleData.displayName
  };

  return (
    <>
      <BackButton location="/assessments/group" />
      {videoUrl ? (
        <Video
          url={videoUrl}
          timeLabel={timeLabel}
          actionLabel="Got it!"
          onAction={() => history.push("/assessments/assessment")}
          assessmentTitle={title}
          body={body}
        />
      ) : (
        <Information props={props} />
      )}
    </>
  );
};

export default track({
  url
})(PreamblePage);
