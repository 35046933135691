import React from "react";
import { Box } from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";

import { color, fontSize, spacingDefaults } from "style/constants";
import { Button, StyledGrid } from "components/simple";
import { ContentfulSection, CourseStatus } from "graphql/types/Contentful";
import { toLowerDashCase } from "../utils";
import ItemStatus from "./ItemStatus";
import { SectionSubtitle } from "components/pages/Consent/styled";

export interface SectionItemProps {
  courseTitle: string;
  sectionNumber: number;
  sectionDetails: ContentfulSection;
}

// Commented coins until gamification SOW - FB
// const StyledChip = styled(Chip)`
//   && {
//     text-align: center;
//     border-radius: 20px;
//     border: none;
//     background-color: rgba(255, 202, 40, 0.5);
//     padding: ${spacingDefaults.small} 0;
//     width: 90px;
//   }
//   .MuiChip-label {
//     font-size: ${fontSize.small};
//   }
// `;

const SectionItem: React.FC<SectionItemProps> = ({
  courseTitle,
  sectionNumber,
  sectionDetails
}: SectionItemProps) => {
  const isCompleted = sectionDetails?.status === CourseStatus.completed;
  const isInProgress = sectionDetails?.status === CourseStatus.inProgress;
  const isNotStarted =
    sectionDetails?.status === CourseStatus.notStarted && sectionDetails.active;

  const sectionAction = isCompleted
    ? "View"
    : isInProgress
    ? "Continue"
    : "Start";
  const match = useRouteMatch();
  const courseSectionTitle = `${courseTitle} ${`${
    sectionNumber < 10 ? `0${sectionNumber}` : `${sectionNumber}`
  }`}`;

  return (
    <StyledGrid
      container
      wrap="nowrap"
      alignItems="center"
      margin={`${spacingDefaults.small} 0`}
    >
      <StyledGrid
        item
        container
        centerContent
        alignItems="center"
        marginRight={spacingDefaults.normal}
        backgroundColor={
          isCompleted
            ? color.GREEN
            : isInProgress || isNotStarted
            ? color.BLUE
            : color.GRAY
        }
        outline={isCompleted ? `3px solid ${color.WHITE}` : undefined}
        border={isCompleted ? `3px solid ${color.WHITE}` : undefined}
        borderRadius="50%"
        minWidth="85px"
        maxWidth="85px"
        minHeight="85px"
        maxHeight="85px"
      >
        <StyledGrid item width="60px" height="60px">
          <img src={sectionDetails.thumbnailImage} width="60px" height="60px" />
        </StyledGrid>
      </StyledGrid>
      <StyledGrid item>
        <StyledGrid container>
          <StyledGrid item marginRight={spacingDefaults.normal}>
            <Box
              color={isInProgress ? color.BLACK : color.GRAPHITE}
              fontWeight="300"
            >
              {courseSectionTitle}
            </Box>
          </StyledGrid>
          <StyledGrid item>
            <ItemStatus
              status={sectionDetails?.status}
              active={sectionDetails?.active}
            />
          </StyledGrid>
        </StyledGrid>
        <StyledGrid container>
          <Box
            color={isInProgress ? color.BLACK : color.GRAPHITE}
            fontSize={fontSize.medium}
          >
            {sectionDetails?.title}
          </Box>
        </StyledGrid>
        {/* Commented coins until gamification SOW - FB */}
        {/* {!isCompleted && (
          <StyledGrid container>
            <StyledChip label="100 coins" size="small" color="primary" />
          </StyledGrid>
        )} */}
        {sectionDetails.active && (
          <StyledGrid container>
            <Link
              to={{
                pathname: `${match.url}/${toLowerDashCase(
                  sectionDetails.title
                )}`
              }}
              style={
                isCompleted
                  ? {
                      color: color.BLUE,
                      textDecoration: "underline"
                    }
                  : { color: color.BLACK, textDecoration: "none" }
              }
            >
              {isCompleted ? (
                sectionAction
              ) : (
                <Button compressed width="130px">
                  {sectionAction}
                </Button>
              )}
            </Link>
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default SectionItem;
