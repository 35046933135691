import React from "react";
import { MobileStepper, Select, Tab } from "@material-ui/core";
import styled from "styled-components";

import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

export const StyledSelect = styled(({ ...props }) => <Select {...props} />)`
  && {
    .MuiSelect-outlined {
      border: solid 1px ${color.PALEGRAY};
      color: ${color.BLACK};
    }
  }
`;

export const StyledTab = styled(({ ...otherProps }) => <Tab {...otherProps} />)`
  && {
    font-size: ${fontSize.small};
    padding: 1.25rem;
    text-transform: none;
  }
`;

export const Index = styled.h1`
  color: ${color.DARKBLUE};
  font-weight: 300;
  font-size: 68px;
  letter-spacing: -3px;
  line-height: 13px;
`;

export const Title = styled.h2`
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.large};
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: ${fontSize.large};
`;

export const SubTitle = styled.h3`
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.large};
  font-weight: normal;
  letter-spacing: -0.5px;
  line-height: ${fontSize.large};
`;

export const Body = styled.p`
  color: ${color.DARKERGRAY};
`;

export const SmallText = styled.p`
  color: ${color.PALEGRAY};
  font-size: ${fontSize.xsmall};
  margin: ${spacingDefaults.xsmall};
`;

export const PillarName = styled.p`
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.small};
  line-height: ${fontSize.small};
  margin: ${fontSize.small} 0;
  white-space: nowrap;
`;

export const PillarValue = styled.h2`
  font-size: 26px;
  font-weight: 500;
  margin: 0;
`;

export const StyledStepper = styled(({ ...otherProps }) => (
  <MobileStepper {...otherProps} />
))`
  && {
    background: transparent;

    .MuiMobileStepper-dot {
      background: transparent;
      border: solid 1px #8a8a8a;
    }

    .MuiMobileStepper-dotActive {
      background: #8a8a8a;
    }
  }
`;

export const StepperButton = styled.button`
  background: none;
  border: none;
`;

export const ImagePDFSmall = styled.img`
  height: 40px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
    margin-left: 0;
  }
`;

export const ImagePDFLarge = styled.img`
  height: 250px;
  marginleft: -50px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 65px;
    margin-left: 0;
  }
`;

export const ImageCoachingCall = styled.img`
  height: 36px;
  width: 36px;
`;
