import React, { useEffect, useState } from "react";
import { format, isSameDay } from "date-fns";
import { Box } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";

import { StyledGrid, Button } from "components/simple";
import { fontSize, spacingDefaults } from "style/constants";
import { UpcomingSession } from "..";
import { MarkParticipantAsAttended } from "graphql/groupCoaching/groupCoaching.gql";
import { canJoinVideoCall } from "components/pages/Dashboard/CycleSummary/utils";
import { StyledCard } from "components/pages/DashboardV2/styled";
import { useHistory } from "react-router";
import { StyledCoin } from "components/pages/DashboardV2/TaskList/styled";

interface UpcomingSessionProps {
  session: UpcomingSession;
}

const UpcomingGroupSession: React.FC<UpcomingSessionProps> = ({
  session
}: UpcomingSessionProps) => {
  const appHistory = useHistory();
  const [showJoinCall, setShowJoinCall] = useState(canJoinVideoCall(session));
  const date = format(new Date(session.sessionTime), "MMMM d, yyyy");
  const time = format(new Date(session.sessionTime), "p");
  const timezoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const [markParticipantAsAttended] = useMutation(MarkParticipantAsAttended, {
    variables: { input: { groupCoachingSessionsId: session.id } }
  });

  useEffect(() => {
    if (isSameDay(new Date(), new Date(session.sessionTime))) {
      const handle = setInterval(() => {
        setShowJoinCall(canJoinVideoCall(session));
      }, 1000 * 30); // Check every 30 seconds.

      return () => clearInterval(handle);
    }
  }, [session]);

  const dateAndTime = `${time} ${timezoneAbbreviation} on ${date}`;
  const message =
    "A button to join will appear here 30 minutes before the start.";

  return (
    <StyledGrid item xs={12}>
      <StyledCard
        alignItems="center"
        container
        justifyContent="space-between"
        minHeight="100px"
        padding={spacingDefaults.normal}
      >
        <StyledGrid item>
          <Box fontSize={fontSize.medium}>
            {session?.title || "Group Coaching Call Scheduled"}
          </Box>
          <Box fontSize={fontSize.small} margin={`${spacingDefaults.xsmall} 0`}>
            {dateAndTime} <br />
            {message}
          </Box>
          <StyledCoin label="100 Coins" size="small" color="primary" />
        </StyledGrid>
        <StyledGrid item>
          {showJoinCall && (
            <Button
              aria-label="go-to-group-coaching-call"
              onClick={() => {
                window.open(session.groupCoachingJoinLink, "_blank");
                markParticipantAsAttended();
              }}
            >
              Join Group Call
            </Button>
          )}
          {!showJoinCall && (
            <Button
              aria-label="go-to-group-coaching-call"
              onClick={() => {
                appHistory.push(`/groupCoachingConfirmation/${session.id}`);
              }}
            >
              Add to Calendar
            </Button>
          )}
        </StyledGrid>
      </StyledCard>
    </StyledGrid>
  );
};

export default UpcomingGroupSession;
