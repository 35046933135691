import { User, UserAssessment } from "./User";

export enum UserAssessmentCycleStatus {
  IN_PROGRESS = "assessments_in_progress",
  NOT_STARTED = "assessments_not_started",
  READY_FOR_REVIEW = "ready_for_review",
  READY_TO_SCORE = "ready_to_score",
  SCORE_FINALIZED = "score_finalized",
  SCORING_IN_PROGRESS = "scoring_in_progress",
  ASSESSMENTS_NULL = "assessments_null"
}

export interface PillarScore {
  id?: string;
  pillarName: string;
  value: number;
  userAssessmentCycleId?: string;
  userAssessments: Partial<UserAssessment>[] | null;
}

export interface UserCycle {
  id: string;
  userId: string;
  user: User;
  cycleNuber: number;
  userAssessments: UserAssessment[];
  status: UserAssessmentCycleStatus;
  lastUpdate: Date;
  pillarScores: PillarScore[];
  daysToComplete: number;
  cycleDueDate: Date;
}
