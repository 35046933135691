import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import { CloseButtonWrapper } from "./styled";
import { ReactComponent as CloseButton } from "assets/close.svg";

const Close: React.FC = () => {
  const pathsToShow = ["/assessments", "/surveys"];

  const history = useHistory();
  const location = history.location.pathname;

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(
      pathsToShow.some(
        path => path === location || location.includes(path + "/")
      )
    );
  }, [location, pathsToShow]);

  return (
    <>
      {show && (
        <Grid item>
          <CloseButtonWrapper>
            <Link to="/dashboard">
              <IconButton
                aria-controls="menu-appbar"
                aria-label="returns to dashboard"
              >
                <CloseButton />
              </IconButton>
            </Link>
          </CloseButtonWrapper>
        </Grid>
      )}
    </>
  );
};

export default Close;
