import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";

import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { ReactComponent as TreeGroupFooter } from "assets/tree-group-footer.svg";
import { ReactComponent as ThreeDotsGreen } from "assets/three-dots-green.svg";
import { ReactComponent as ThreeDotsBlue } from "assets/three-dots-blue.svg";
import BlueBackground from "assets/blue-course-background.svg";
import GreenBackground from "assets/green-course-background.svg";
import { BackButton, Loading, StyledGrid } from "components/simple";
import { toLowerDashCase } from "../utils";
import { Factors } from "../Courses/CourseCard";
import SectionItem from "./SectionItem";
import {
  ContentfulCourse,
  ContentfulSection,
  CourseStatus
} from "graphql/types/Contentful";
import {
  GetTeaserContentfulSections,
  GetContentfulSections
} from "graphql/training/training.gql";

const Course: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const { courseTitle } = useParams<{
    courseTitle: string;
  }>();

  const [courses, setCourses] = useState<ContentfulCourse[]>();
  const [teaserCourses, setTeaserCourses] = useState<ContentfulCourse[]>();
  const [currentCourse, setCurrentCourse] = useState<ContentfulCourse>();
  const [currentTeaserCourse, setCurrentTeaserCourse] = useState<
    ContentfulCourse
  >();
  const [sections, setSections] = useState<ContentfulSection[] | undefined>();
  const [teaserSections, setTeaserSections] = useState<
    ContentfulSection[] | undefined
  >();
  const [contenfulError, setContenfulError] = useState<boolean>(false);

  const { loading: teaserSectionsLoading } = useQuery(
    GetTeaserContentfulSections,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: data =>
        setTeaserCourses(data?.teaserTrainingProgress?.teaserCourses),
      onError: () => setContenfulError(true)
    }
  );

  const { loading: sectionsLoading } = useQuery(GetContentfulSections, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => setCourses(data?.trainingProgress?.courses),
    onError: () => setContenfulError(true)
  });

  useEffect(() => {
    setCurrentTeaserCourse(
      teaserCourses?.find(
        (teaserCourse: ContentfulCourse) =>
          toLowerDashCase(teaserCourse?.title) === courseTitle
      )
    );
    setTeaserSections(currentTeaserCourse?.sections);
  }, [teaserCourses, currentTeaserCourse]);

  useEffect(() => {
    setCurrentCourse(
      courses?.find(
        (course: ContentfulCourse) =>
          toLowerDashCase(course?.title) === courseTitle
      )
    );
    setSections(currentCourse?.sections);
  }, [courses, currentCourse]);

  if (sectionsLoading || teaserSectionsLoading) return <Loading />;
  return (
    <>
      <StyledGrid container justifyContent="center" zIndex="1">
        <BackButton location="/training" />
      </StyledGrid>
      <StyledGrid
        container
        position="relative"
        centerContent
        backgroundColor={color.SOFTBLUE}
      >
        <StyledGrid container width="100vw">
          {mobile ? (
            <>
              <StyledGrid backgroundColor={color.BLUE} />
              <StyledGrid>
                <img
                  src={
                    currentTeaserCourse
                      ? currentTeaserCourse?.thumbnailImage
                      : currentCourse?.thumbnailImage
                  }
                  height="250px"
                />
              </StyledGrid>
            </>
          ) : (
            <StyledGrid>
              <img
                src={
                  currentTeaserCourse
                    ? currentTeaserCourse?.status === CourseStatus.completed
                      ? GreenBackground
                      : BlueBackground
                    : currentCourse?.status === CourseStatus.completed
                    ? GreenBackground
                    : BlueBackground
                }
                style={{ width: "100vw" }}
              />
            </StyledGrid>
          )}
        </StyledGrid>
        {!mobile && (
          <StyledGrid position="absolute" width="22vw" maxWidth="480px">
            <img
              src={
                currentTeaserCourse
                  ? currentTeaserCourse?.thumbnailImage
                  : currentCourse?.thumbnailImage
              }
              width="100%"
              style={{ maxHeight: "300px" }}
            />
          </StyledGrid>
        )}
        <StyledGrid margin={`${spacingDefaults.large} 0`} bottom="0">
          <StyledGrid container centerContent alignItems="center">
            <StyledGrid item>
              <Box
                fontSize={fontSize.header}
                fontFamily={fontFamily.secondary}
                fontWeight="500"
              >
                {currentTeaserCourse
                  ? currentTeaserCourse?.title
                  : currentCourse?.title}
              </Box>
            </StyledGrid>
            {currentTeaserCourse
              ? currentTeaserCourse?.factors && (
                  <StyledGrid item>
                    <Factors factors={currentTeaserCourse?.factors} />
                  </StyledGrid>
                )
              : currentCourse?.factors && (
                  <StyledGrid item>
                    <Factors factors={currentCourse?.factors} />
                  </StyledGrid>
                )}
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
      <StyledGrid centerContent margin={`${spacingDefaults.xlarge} 0`}>
        <Box
          fontSize={fontSize.large}
          fontFamily={fontFamily.secondary}
          fontWeight="500"
        >
          Overview
        </Box>
      </StyledGrid>
      <StyledGrid padding={`0 ${spacingDefaults.normal}`} margin="0 auto">
        {teaserSections
          ? teaserSections
              ?.sort((a, b) => a.order - b.order)
              .map((section: ContentfulSection, idx: number) => (
                <StyledGrid key={idx}>
                  <StyledGrid margin="0 auto">
                    <SectionItem
                      sectionNumber={idx + 1}
                      courseTitle={currentTeaserCourse?.title || ""}
                      sectionDetails={section}
                    />
                  </StyledGrid>
                  {idx < (teaserSections?.length || 0) - 1 && (
                    <StyledGrid marginLeft="40px">
                      {section?.status === CourseStatus.completed ? (
                        <ThreeDotsGreen />
                      ) : (
                        <ThreeDotsBlue />
                      )}
                    </StyledGrid>
                  )}
                </StyledGrid>
              ))
          : sections
              ?.sort((a, b) => a.order - b.order)
              .map((section: ContentfulSection, idx: number) => (
                <StyledGrid key={idx}>
                  <StyledGrid margin="0 auto">
                    <SectionItem
                      sectionNumber={idx + 1}
                      courseTitle={currentCourse?.title || ""}
                      sectionDetails={section}
                    />
                  </StyledGrid>
                  {idx < (sections?.length || 0) - 1 && (
                    <StyledGrid marginLeft="40px">
                      {section?.status === CourseStatus.completed ? (
                        <ThreeDotsGreen />
                      ) : (
                        <ThreeDotsBlue />
                      )}
                    </StyledGrid>
                  )}
                </StyledGrid>
              ))}
      </StyledGrid>
      <StyledGrid
        centerContent
        marginTop="auto"
        marginBottom={spacingDefaults.normal}
      >
        <Link
          to="/training"
          style={{ color: color.DARKERGRAY, textDecoration: "underline" }}
        >
          Back to courses
        </Link>
      </StyledGrid>
      <StyledGrid centerContent>
        <TreeGroupFooter />
      </StyledGrid>
    </>
  );
};

export default Course;
