import { gql } from "apollo-boost";

export const ShareBadge = gql`
  mutation ShareBadge($input: ShareBadgeInput!) {
    shareBadge(input: $input) {
      success
    }
  }
`;

export const ShareReferral = gql`
  mutation ShareReferral($input: ShareReferralInput!) {
    shareReferral(input: $input) {
      success
    }
  }
`;
