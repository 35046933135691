import { Document } from "@contentful/rich-text-types";

export interface ContenfulTraining {
  userId: string;
  completedCourseCount: number;
  currentCourse: ContentfulCourse;
  courses: ContentfulCourse[];
}

export interface ContenfulTeaserTraining {
  userId: string;
  completedTeaserCourseCount: number;
  currentTeaserCourse: ContentfulCourse;
  teaserCourses: ContentfulCourse[];
}

export interface ContentfulCourse {
  id: string;
  userId: string;
  contentfulCourseId: string;
  title?: string;
  thumbnailImage?: string;
  description?: string;
  slug?: string;
  type?: string;
  factors?: FactorTypes[];
  startDateTime?: Date;
  completionDateTime?: Date;
  status?: CourseStatus;
  percentageCompletion?: number;
  active?: boolean;
  order: number;
  sections?: ContentfulSection[];
}

export interface ContentfulSection {
  id: string;
  userTrainingCourseId: string;
  contentfulSectionId: string;
  title: string;
  thumbnailImage: string;
  description: string;
  startDateTime?: Date;
  completionDateTime?: Date;
  status?: CourseStatus;
  completedUnits?: number;
  totalUnits?: number;
  active: boolean;
  order: number;
  units?: ContentfulUnit[];
}

export interface ContentfulUnit {
  id: string;
  userTrainingSectionId: string;
  contentfulUnitId?: string;
  title: string;
  timeEstimate: string;
  startDateTime?: Date;
  completionDateTime?: Date;
  status?: CourseStatus;
  active?: boolean;
  order?: number;
  percentageCompletion?: number;
  tasks?: ContentfulTask[];
}

export interface ContentfulTask {
  id: string;
  userTrainingUnitId: string;
  title: string;
  taskType: TaskType;
  contentfulTaskId: string;
  startDateTime?: Date;
  completionDateTime?: Date;
  status?: CourseStatus;
  active: boolean;
  order: number;
}

export interface ContentfulContent {
  id: string;
  title: string;
  description?: string;
  contentModule: ContentModule[];
}

export interface ContentModule {
  id: string;
  title: string;
  layoutType: LayoutType;
  fullBleed: boolean;
  contentSlots: ContentSlot[];
}

export interface ContentSlot {
  title: string;
  richText?: Document;
  media?: Media[];
  textAlignment?: string;
  tabText?: string;
}

export interface Media {
  title: string;
  description: string;
  fileUrl: string;
  mediaType: MediaType;
}

export interface QuizContent {
  id: string;
  title: string;
  instructions?: Document;
  passingScore?: number;
  questions: QuizQuestions[];
}

export interface QuizQuestions {
  id: string;
  question: string;
  instructions?: Document;
  questionType: QuestionType;
  possibleAnswers: Answers[];
  correctAnswers: Answers[];
}

export interface SurveyContent {
  id: string;
  title: string;
  instructions?: Document;
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  id: string;
  question: string;
  instructions?: Document;
  type: SurveyType;
  possibleAnswers: Answers[];
}

export interface Answers {
  id: string;
  answer: string;
  iconImage?: string;
}

export interface SurveyAnswers {
  questionId: string;
  answer: string;
}

export interface SortingContent {
  id: string;
  title: string;
  questions: SortingQuestion[];
}

export interface SortingQuestion {
  id: string;
  question: string;
  instructions?: Object;
  questionType: string;
  possibleAnswers: Answers[];
  correctAnswers: Answers[];
}

export enum CourseStatus {
  notStarted = "Not Started",
  completed = "Completed",
  inProgress = "In Progress"
}

export interface SlideData {
  data?: Document;
  media?: Media[];
  title?: string;
  tabText?: string;
}

export interface TabsData {
  data?: Document;
  media?: Media[];
  title?: string;
  textAlignment?: string;
  tabText?: string;
}

export enum TaskType {
  lesson = "lesson",
  quiz = "quiz",
  survey = "survey",
  sorting = "sorting"
}

export enum QuestionType {
  single = "single",
  multiple = "multiple",
  flashcards = "flashcards",
  sorting = "sorting"
}

export enum SurveyType {
  multipleChoice = "multipleChoice",
  openEnded = "openEnded"
}

export enum LayoutType {
  oneColumn = "One-Column",
  twoColumn = "Two-Column",
  threeColumn = "Three-Column",
  fourColumn = "Four-Column",
  slides = "Content Carousel",
  tabs = "Tabs"
}

export enum MediaType {
  videoMp4 = "video/mp4",
  photoSvg = "photo/svg",
  photoJpg = "photo/jpg",
  photoPng = "photo/png",
  html = "text/html"
}

export enum FactorTypes {
  cognitive = "cognitive",
  social = "social",
  wellBeing = "wellBeing",
  realLife = "realLife",
  index = "index",
  newIndex = "newIndex",
  connectedness = "connectedness", // newFactor1 / resilience
  emotionalBalance = "emotionalBalance", // newFactor2 / fortitude
  clarity = "clarity", // newFactor3
  emerging = "emerging",
  factor = "factor"
}

export enum TaskCategories {
  unit = "Training Completed",
  section = "Training Section Completed"
}

export type awardCoinType = {
  title: string;
  coinsAwarded: number;
  ornament: string;
};
