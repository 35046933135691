import React from "react";

import AudioInputList from "./AudioInputList/AudioInputList";
import AudioOutputList from "./AudioOutputList/AudioOutputList";
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Theme,
  DialogTitle
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VideoInputList from "./VideoInputList/VideoInputList";
import { Button } from "components/simple";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100vw - 32px)"
    },
    "& .inputSelect": {
      width: "calc(100% - 35px)"
    }
  },
  button: {
    float: "right"
  },
  paper: {
    width: "600px",
    maxWidth: "600px",
    [theme.breakpoints.down("xs")]: {
      width: undefined,
      margin: "16px"
    }
  },
  headline: {
    marginBottom: "1.3em",
    fontSize: "1.1rem"
  },
  listSection: {
    margin: "2em 0 0.8em",
    "&:first-child": {
      margin: "1em 0 2em 0"
    }
  }
}));

export default function DeviceSelectionDialog({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>Audio and Video Settings</DialogTitle>
      <Divider />
      <DialogContent className={classes.container}>
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Video
          </Typography>
          <VideoInputList />
        </div>
        <Divider />
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Audio
          </Typography>
          <AudioInputList />
        </div>
        <div className={classes.listSection}>
          <AudioOutputList />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button squared className={classes.button} onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
