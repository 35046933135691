import { useApolloClient } from "@apollo/react-hooks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { GetChallengeLink } from "graphql/training/training.gql";
import { hideLoader, showLoader } from "state";
import routesConfig from "utils/routesConfig";

const Challenges: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const client = useApolloClient();

  useEffect(() => {
    (async function () {
      dispatch(showLoader());

      let response;

      try {
        response = await client.query({
          query: GetChallengeLink,
          fetchPolicy: "no-cache"
        });
      } catch {
        dispatch(hideLoader());
        return;
      }

      const link = response.data?.training.challengeProgress?.link;

      if (link) {
        dispatch(hideLoader());

        window.location.replace(link);
        return;
      } else {
        history.push(routesConfig.training.path);
      }
    })();
  }, [history, dispatch, client]);

  return null;
};

export default Challenges;
