import React from "react";
import { Box } from "@material-ui/core";
import { fontSize } from "style/constants";
import { StyledTreeCard } from "./styled";
import { endOfDay, format, isAfter, isBefore, startOfDay } from "date-fns";
import { JourneyTreeStateProps } from "models/userStreak";
import { MyJourneyTree } from "./MyJourneyTree";
import { StyledGrid } from "components/simple";

export const MyJourneyDay: React.FC<JourneyTreeStateProps> = ({
  isToday,
  userStreak,
  openDrawer
}) => {
  let userStreakDate = new Date();
  if (userStreak?.date) {
    const [year, month, day] = userStreak.date.split("-"); // yyyy-MM-dd (e.g. 2022-07-11)
    userStreakDate = new Date(+year, +month - 1, +day); // month is 0-indexed (e.g. Jan = 0)
  }

  const isOutsideUserStreakDateRange = userStreak
    ? isAfter(userStreakDate, new Date()) ||
      isBefore(
        startOfDay(userStreakDate),
        startOfDay(new Date(userStreak?.userRegistrationDate))
      )
    : true;

  return (
    <>
      {userStreak && (
        <StyledTreeCard
          container
          flexDirection="column"
          wrap="nowrap"
          onClick={() => {
            isOutsideUserStreakDateRange
              ? undefined
              : openDrawer(userStreakDate);
          }}
          style={{
            cursor: isOutsideUserStreakDateRange ? "default" : "pointer"
          }}
        >
          <MyJourneyTree userStreak={userStreak} />
          <StyledGrid item>
            <Box
              fontSize={fontSize.small}
              fontWeight={isToday ? "bold" : "normal"}
              color={isToday ? "#039BE5" : "color.DARKGRAY"}
              style={{ textAlign: "center" }}
            >
              {format(userStreakDate, "EEEE")}
            </Box>
            <Box
              fontSize={fontSize.small}
              color={isToday ? "#039BE5" : "color.DARKGRAY"}
              style={{ textAlign: "center" }}
            >
              {format(endOfDay(userStreakDate), "MMM do")}
            </Box>
          </StyledGrid>
        </StyledTreeCard>
      )}
    </>
  );
};
