import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import { ReactComponent as SendFeedbackImage } from "assets/sendFeedback.svg";
import { Button, StyledGrid } from "components/simple";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const Feedback: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      container
      direction={mobile ? "column" : "row"}
      width="auto"
    >
      <StyledGrid item>
        <SendFeedbackImage
          height={mobile ? 155 : 180}
          width={mobile ? 165 : 190}
        />
      </StyledGrid>

      <StyledGrid
        alignItems={mobile ? "center" : undefined}
        container
        direction="column"
        item
        xs
      >
        <StyledGrid item>
          <Box
            component="p"
            fontFamily={fontFamily.secondary}
            fontSize={fontSize.large}
            letterSpacing="-0.45px"
            margin={`0 0 ${spacingDefaults.small} 0`}
          >
            Send us your feedback
          </Box>
        </StyledGrid>

        <StyledGrid item>
          <Button
            href="mailto:support@thebrainhealthproject.org?BrainHealth Project Feedback"
            width="175px"
          >
            Email Us!
          </Button>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Feedback;
