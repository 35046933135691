import { Box, useMediaQuery } from "@material-ui/core";
import { addHours, format, isAfter } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as CoachingCallImage } from "assets/coaching-call.svg";
import { Avatar, Button, StyledGrid } from "components/simple";
import Logo from "components/simple/Logo";
import { getUser } from "state";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import { useQuery } from "@apollo/react-hooks";
import { GetUserReflectionSurvey } from "graphql/userSurveys/userSurveys.gql";
import { get } from "lodash";
import { UserSurvey } from "graphql/types/UserSurvey";
import {
  CoachingSession,
  CoachingSessionStatus
} from "components/pages/Index/CycleSummary/types";

export interface CoachingSessionSummaryProps {
  index: number;
  coachingSession: CoachingSession;
  acuityConfirmationNumber: number;
}

const CoachingSessionSummary: React.FC<CoachingSessionSummaryProps> = ({
  index,
  coachingSession
}: CoachingSessionSummaryProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const user = useSelector(getUser);

  const coachName = `${coachingSession.coach.firstName}`;

  const isScheduled =
    coachingSession.status === CoachingSessionStatus.SCHEDULED;

  const sessionDate = new Date(coachingSession.sessionTime);

  const showSummary =
    coachingSession.status === CoachingSessionStatus.COMPLETED;

  const summaryDate = format(
    new Date(coachingSession.updatedAt),
    "MMMM d, yyyy"
  );
  const summary = coachingSession.participantNotes;

  const showSubmitReflection =
    coachingSession.reflectionSurvey &&
    coachingSession.reflectionSurvey.completionDate === null &&
    coachingSession.reflectionSurvey.dismissalDate === null;
  const showCompletedReflection =
    coachingSession.reflectionSurvey &&
    coachingSession.reflectionSurvey.completionDate !== null;
  const showDismissedReflection =
    coachingSession.reflectionSurvey &&
    coachingSession.reflectionSurvey.dismissalDate !== null;

  const reflectionDate = coachingSession.reflectionSurvey?.completionDate
    ? format(
        new Date(coachingSession.reflectionSurvey.completionDate),
        "MMMM d, yyyy"
      )
    : null;
  const reflection =
    coachingSession.reflectionSurvey?.answers[0]?.answerData.response;

  const { data, loading } = useQuery(GetUserReflectionSurvey, {
    fetchPolicy: "no-cache"
  });
  if (loading) return null;
  const userSurvey: UserSurvey = get(data, "getUserReflectionSurvey");

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      container
      overflow="hidden"
    >
      {coachingSession.status === CoachingSessionStatus.MISSED ? (
        <StyledGrid container>
          <StyledGrid
            alignItems="center"
            backgroundColor={color.LIGHTERGRAY}
            container
            item
            padding={
              mobile ? `${spacingDefaults.xsmall} 0` : spacingDefaults.medium
            }
            xs={12}
          >
            <StyledGrid item height="90px" paddingTop="5px">
              <CoachingCallImage height={90} />
            </StyledGrid>
            <StyledGrid>
              <Box
                color={color.BLACK}
                component="p"
                fontSize={fontSize.medium}
                fontWeight="600"
                lineHeight={fontSize.large}
                margin="0"
              >
                Coaching Call #{index + 1}
              </Box>
              <Box
                color={color.BLACK}
                component="p"
                fontWeight="400"
                lineHeight={fontSize.large}
                margin="0"
              >
                Skipped
              </Box>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      ) : (
        <StyledGrid width="100%">
          <StyledGrid
            alignItems="center"
            backgroundColor={color.LIGHTERBLUE}
            container
            item
            padding={
              mobile ? `${spacingDefaults.xsmall} 0` : spacingDefaults.medium
            }
            xs={12}
          >
            <StyledGrid item height="90px" paddingTop="5px">
              <CoachingCallImage height={90} />
            </StyledGrid>
            <StyledGrid item>
              <Box
                color={color.BLACK}
                component="p"
                fontSize={fontSize.medium}
                fontWeight="600"
                lineHeight={fontSize.large}
                margin="0"
              >
                Coaching Call #{index + 1}
              </Box>

              <Box
                color={color.BLACK}
                component="p"
                fontWeight="400"
                lineHeight={fontSize.large}
                margin="0"
              >
                {coachName}
              </Box>

              <Box
                color={color.LIGHTGRAY}
                component="p"
                fontSize={fontSize.small}
                margin="0"
              >
                {`${format(sessionDate, "MMMM d, yyyy")}`}
              </Box>
            </StyledGrid>
          </StyledGrid>

          <StyledGrid
            container
            item
            padding={mobile ? spacingDefaults.medium : spacingDefaults.xlarge}
            xs={12}
          >
            <>
              <StyledGrid container item xs={12}>
                <StyledGrid item width="45px">
                  <Logo styleType="coachingCall" height="5px" width="20px" />
                </StyledGrid>

                <StyledGrid item paddingTop="7px" xs>
                  <Box
                    color={color.BLACK}
                    component="p"
                    fontSize={fontSize.normal}
                    fontWeight="600"
                    lineHeight={fontSize.large}
                    margin="0"
                  >
                    Coaching Call Summary
                  </Box>

                  {showSummary && (
                    <>
                      <Box
                        color={color.LIGHTGRAY}
                        component="p"
                        fontSize={fontSize.small}
                        margin={`0 0 ${spacingDefaults.normal} 0`}
                      >
                        {summaryDate}
                      </Box>

                      <Box
                        color={color.BLACK}
                        component="p"
                        fontSize={fontSize.small}
                        lineHeight={fontSize.large}
                        margin="0"
                      >
                        {summary}
                      </Box>
                    </>
                  )}
                </StyledGrid>

                {!showSummary && (
                  <StyledGrid container item justifyContent="center" xs={12}>
                    <Box
                      component="p"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="25px"
                      maxWidth="250px"
                      textAlign="center"
                    >
                      <em>
                        You will receive an email when your coaching summary is
                        available.
                      </em>
                    </Box>
                  </StyledGrid>
                )}

                <StyledGrid
                  container
                  item
                  marginTop={spacingDefaults.large}
                  xs={12}
                >
                  <StyledGrid item width="45px">
                    <Avatar
                      profileImageKey={user?.profileImageKey || undefined}
                      firstName={user?.firstName}
                      lastName={user?.lastName}
                    />
                  </StyledGrid>

                  <StyledGrid item paddingTop="7px" xs>
                    <Box
                      color={color.BLACK}
                      component="p"
                      fontSize={fontSize.normal}
                      fontWeight="600"
                      lineHeight={fontSize.large}
                      margin="0"
                    >
                      Your Coaching Call Reflection
                    </Box>

                    {showCompletedReflection && (
                      <>
                        <Box
                          color={color.LIGHTGRAY}
                          component="p"
                          fontSize={fontSize.small}
                          margin={`0 0 ${spacingDefaults.normal} 0`}
                        >
                          {reflectionDate}
                        </Box>

                        <Box
                          color={color.BLACK}
                          component="p"
                          fontSize={fontSize.small}
                          lineHeight={fontSize.large}
                          margin={`0 0 ${spacingDefaults.normal} 0`}
                        >
                          Write down the two biggest take-aways from your
                          coaching call.
                        </Box>

                        <Box
                          component="p"
                          fontSize={fontSize.small}
                          fontWeight="300"
                          lineHeight={fontSize.large}
                          margin="0"
                          whiteSpace="pre-line"
                        >
                          {reflection}
                        </Box>
                      </>
                    )}
                  </StyledGrid>

                  {!showCompletedReflection && (
                    <StyledGrid container item justifyContent="center" xs={12}>
                      {isScheduled && (
                        <Box
                          component="p"
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="25px"
                          maxWidth="250px"
                          textAlign="center"
                        >
                          <em>
                            You will receive a short reflection survey when your
                            coaching summary is available.
                          </em>
                        </Box>
                      )}

                      {showSubmitReflection && (
                        <StyledGrid
                          alignItems="center"
                          backgroundColor={color.LIGHTERBLUE}
                          borderRadius="8px"
                          container
                          direction="column"
                          item
                          justifyContent="center"
                          marginTop={spacingDefaults.large}
                          padding={spacingDefaults.normal}
                        >
                          <Box
                            component="p"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="25px"
                            margin={`0 0 ${spacingDefaults.xsmall} 0`}
                          >
                            Submit your coaching call reflection
                          </Box>

                          <Link
                            to={`${routesConfig.surveys.survey.path}/${userSurvey?.survey?.id}/${userSurvey?.id}`}
                          >
                            <Button
                              aria-label="go-to-survey"
                              longText
                              width="200px"
                            >
                              Submit Reflection
                            </Button>
                          </Link>
                        </StyledGrid>
                      )}

                      {showDismissedReflection && (
                        <Box
                          component="p"
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="25px"
                          maxWidth="250px"
                          textAlign="center"
                        >
                          <em>
                            You did not submit a reflection for this coaching
                            call.
                          </em>
                        </Box>
                      )}
                    </StyledGrid>
                  )}
                </StyledGrid>
              </StyledGrid>
            </>
          </StyledGrid>
        </StyledGrid>
      )}
    </StyledGrid>
  );
};

export default CoachingSessionSummary;
