import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import { StyledGrid } from "components/simple";
import { color } from "style/constants";
import {
  Axis,
  BHILabel,
  Circle,
  GraphSlider,
  GraphWrapper,
  LabelWrapper,
  RoundLabel,
  ScoreText,
  TimeLabel,
  WhiteGradient
} from "./styled";

interface LineProps {
  dotted?: boolean;
  topLeft?: number;
  topRight?: number;
  stroke?: string;
}

const Line: React.FC<LineProps> = ({
  dotted,
  topLeft,
  topRight,
  stroke
}: LineProps) => {
  const x = topLeft ? "0" : "100";
  return (
    <line
      x1={x}
      y1="100"
      x2={x}
      y2={topLeft || topRight}
      stroke={stroke || color.DARKBLUE}
      strokeWidth="2px"
      strokeDasharray={dotted ? "2 2" : "0"}
    />
  );
};

interface ScoreLineProps {
  topLeft: number;
  topRight: number;
  stroke?: string;
}

const ScoreLine: React.FC<ScoreLineProps> = ({
  topLeft,
  topRight,
  stroke
}: ScoreLineProps) => (
  <line
    x1="0"
    y1={topLeft}
    x2="100"
    y2={topRight}
    stroke={stroke || color.DARKBLUE}
  />
);

interface ScoreBoxProps {
  width: number;
  currentScore: number;
  nextScore: number;
  label: string;
  date: string;
  selected?: boolean;
  transparent?: boolean;
}

const ScoreBox: React.FC<ScoreBoxProps> = ({
  width,
  currentScore,
  nextScore,
  label,
  date,
  selected,
  transparent
}: ScoreBoxProps) => {
  const topLeft = 100 - currentScore;
  const topRight = 100 - nextScore;

  function getOpacity(): string {
    if (selected) return "1";
    if (transparent) return "0.3";
    return "1";
  }
  return (
    <StyledGrid height="250px" position="relative">
      <ScoreText selected={selected} position={currentScore}>
        {currentScore}
      </ScoreText>
      <Circle top={topLeft} />
      <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        width={`${width}px`}
        height="100%"
        opacity={getOpacity()}
      >
        <path
          d={`M0,${topLeft} L100,${topRight} L100,100 L0,100z`}
          fill="#E6F5FC"
        ></path>
        <ScoreLine topLeft={topLeft} topRight={topRight} />
        <Line
          topLeft={topLeft}
          stroke={selected ? color.DARKBLUE : color.GRAY}
        />
      </svg>
      <LabelWrapper>
        <RoundLabel>{label}</RoundLabel>
        <TimeLabel>{date}</TimeLabel>
      </LabelWrapper>
    </StyledGrid>
  );
};

interface FutureScoreBoxProps {
  width: number;
  currentScore: number;
  label: string;
  date: string;
  selected?: boolean;
}

const FutureScoreBox: React.FC<FutureScoreBoxProps> = ({
  currentScore,
  width,
  label,
  date,
  selected
}: FutureScoreBoxProps) => {
  const topLeft = 100 - currentScore;
  return (
    <StyledGrid width={`${width}px`} height="250px" position="relative">
      <BHILabel position={currentScore}>New Index</BHILabel>
      <ScoreText last={selected} position={currentScore}>
        {currentScore}
      </ScoreText>
      <Circle top={topLeft} />
      <WhiteGradient top={topLeft} />
      <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        height="100%"
        width="100%"
      >
        <path
          d={`M1,${topLeft} L100,${topLeft} L100,100 L1,100z`}
          fill="#E6F5FC"
        ></path>

        <Line
          topLeft={topLeft}
          stroke={selected ? color.DARKBLUE : color.GRAY}
        />
        <Line dotted topRight={topLeft} stroke={color.LIGHTERGRAY} />
      </svg>
      <LabelWrapper>
        <RoundLabel>{label}</RoundLabel>
        <TimeLabel>{date}</TimeLabel>
      </LabelWrapper>
    </StyledGrid>
  );
};

interface GraphPillar {
  value: number;
  pillarName: string;
}

interface Cycles {
  cycleNumber: number;
  cycleCompletionDate: string;
  pillarScores: GraphPillar[];
}

interface Props {
  cycles: Cycles[];
  selectedCycle: number;
}

const ScoreHistoryGraph: React.FC<Props> = ({
  cycles,
  selectedCycle
}: Props) => {
  const boxWidth = 100;
  const startedPosition = boxWidth / 2;
  const [position, setPosition] = useState(startedPosition);

  const totalWidth = cycles.length * boxWidth;
  const scoreCount = cycles.length - 1;

  const indexScoreArray = cycles.map((c: Cycles) => {
    const BHI = c.pillarScores.find(
      (p: GraphPillar) => p.pillarName === "index"
    );
    return BHI ? BHI.value : -1;
  });
  const maxIndexScore = Math.max(...indexScoreArray);

  useEffect(() => {
    if (selectedCycle === scoreCount) setPosition(startedPosition);
    const num = cycles.length - (selectedCycle + 1);
    const total = startedPosition - num * boxWidth;
    setPosition(total);
  }, [cycles.length, scoreCount, selectedCycle, startedPosition]);

  if (cycles.length <= 1) return null;

  const graphData = cycles.map((c: Cycles) => {
    const BHI = c.pillarScores.find(
      (p: GraphPillar) => p.pillarName === "index"
    );

    return {
      date: format(new Date(c.cycleCompletionDate), "MMM yyyy"),
      label: `Round ${c.cycleNumber}`,
      score: BHI ? BHI.value.toFixed(1) : ""
    };
  });

  return (
    <StyledGrid container height="100%">
      <StyledGrid container justifyContent="center">
        <StyledGrid item>
          <GraphWrapper height={maxIndexScore}>
            <GraphSlider
              totalWidth={totalWidth}
              rightPosition={position}
              score={maxIndexScore}
            >
              {graphData.map((cycle: any, index: number) => {
                const isLastScore = scoreCount === index;
                const isSelected = index === selectedCycle;
                const isTransparent = selectedCycle
                  ? index !== selectedCycle - 1
                  : false;

                if (isLastScore) {
                  return (
                    <FutureScoreBox
                      key={index}
                      width={boxWidth}
                      currentScore={cycle.score}
                      label={cycle.label}
                      date={cycle.date}
                      selected={isSelected}
                    />
                  );
                }
                const nextScore = parseInt(graphData[index + 1].score);
                return (
                  <ScoreBox
                    key={index}
                    width={boxWidth}
                    currentScore={cycle.score}
                    nextScore={nextScore}
                    label={cycle.label}
                    date={cycle.date}
                    selected={isSelected}
                    transparent={isTransparent}
                  />
                );
              })}
            </GraphSlider>
            <Axis />
          </GraphWrapper>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default ScoreHistoryGraph;
