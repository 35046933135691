import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { ReactComponent as TreeGroupFooter } from "assets/tree-group-footer.svg";
import BlueHeaderCurve from "assets/blue-header-curve.svg";
import { BackButton, Loading, StyledGrid } from "components/simple";
import { toLowerDashCase } from "../utils";
import UnitItem from "./UnitItem";
import {
  ContentfulCourse,
  ContentfulSection,
  ContentfulUnit,
  CourseStatus
} from "graphql/types/Contentful";
import {
  GetContentfulUnits,
  GetTeaserContentfulUnits
} from "graphql/training/training.gql";
import { useDispatch } from "react-redux";
import { showErrorNotification, showSuccessNotification } from "state";
import {
  GetSubHeaderData,
  RedeemAdditionalTraininng,
  RedeemScreenBreak
} from "graphql/user/user.gql";
import { UserData } from "models/user";
import { CanBuyItemData } from "models/userCoins";
import { CanBuyItem } from "graphql/userCoins/userCoins.gql";

export type TrainingLockedStatus =
  | "unlocked"
  | "additionalTrainingAvailable"
  | "locked";

interface HeaderProps {
  completedUnits: number;
  totalUnits: number;
  mobile: boolean;
}

const UnitsCompletedHeader = ({
  completedUnits,
  totalUnits,
  mobile
}: HeaderProps) => {
  return (
    <StyledGrid
      item
      container
      position={!mobile ? "absolute" : undefined}
      alignItems="center"
      bottom={spacingDefaults.large}
      width="140px"
      height="140px"
      border={`5px solid ${color.WHITE}`}
      borderRadius="50%"
      backgroundColor={color.SOFTBLUE}
    >
      <StyledGrid>
        <Box fontSize={fontSize.header} fontWeight="500" color={color.DARKBLUE}>
          {`${completedUnits}/${totalUnits}`}
        </Box>
        <Box fontSize={fontSize.small}>
          units <br />
          completed
        </Box>
      </StyledGrid>
    </StyledGrid>
  );
};

const Section: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const dispatch = useDispatch();
  const history = useHistory();
  const { courseTitle, sectionTitle } = useParams<{
    courseTitle: string;
    sectionTitle: string;
  }>();

  const [currentTeaserCourse, setCurrentTeaserCourse] = useState<
    ContentfulCourse
  >();
  const [currentCourse, setCurrentCourse] = useState<ContentfulCourse>();

  const [currentSection, setCurrentSection] = useState<ContentfulSection>();
  const [isTrainingLocked, setIsTrainingLocked] = useState<
    TrainingLockedStatus
  >("unlocked");
  const [isTeaserTrainingLocked, setIsTeaserTrainingLocked] = useState<
    TrainingLockedStatus
  >("unlocked");
  const [canBuyAdditionalTraining, setUserCanBuyAdditionalTraining] = useState<
    boolean
  >(false);

  const { loading: teaserUnitsLoading } = useQuery(GetTeaserContentfulUnits, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setCurrentTeaserCourse(
        data?.teaserTrainingProgress?.teaserCourses?.find(
          (teaserCourse: ContentfulCourse) =>
            toLowerDashCase(teaserCourse?.title) === courseTitle
        )
      );
      setIsTeaserTrainingLocked(
        data?.teaserTrainingProgress?.isTeaserTrainingLocked
      );
    },
    onError: (error: any) => {
      history.push({
        pathname: `/course/${courseTitle}`
      });
      dispatch(
        showErrorNotification("Error", error?.graphQLErrors[0]?.message)
      );
    }
  });

  const { loading: unitsLoading } = useQuery(GetContentfulUnits, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setCurrentCourse(
        data?.trainingProgress?.courses?.find(
          (course: ContentfulCourse) =>
            toLowerDashCase(course?.title) === courseTitle
        )
      );
      setIsTrainingLocked(data?.trainingProgress?.isTrainingLocked);
    },
    onError: (error: any) => {
      history.push({
        pathname: `/course/${courseTitle}`
      });
      dispatch(
        showErrorNotification("Error", error?.graphQLErrors[0]?.message)
      );
    }
  });

  const [userCanBuyAdditionalTraining] = useLazyQuery<CanBuyItemData>(
    CanBuyItem,
    {
      variables: {
        input: {
          category: "Unlock Additional Training",
          subCategory: "Additional Training"
        }
      },
      fetchPolicy: "no-cache",
      onCompleted: data => {
        setUserCanBuyAdditionalTraining(data.canBuyItem);
      }
    }
  );

  const [redeemAdditionalTraining] = useMutation(RedeemAdditionalTraininng, {
    refetchQueries: [{ query: GetSubHeaderData }],
    onCompleted: () => {
      dispatch(
        showSuccessNotification(
          "Success",
          "Successfully purchased Additional Training!"
        )
      );
    },
    onError: error => {
      dispatch(
        showErrorNotification(
          "Error",
          (error as any)?.graphQLErrors[0]?.message
        )
      );
    }
  });

  useEffect(() => {
    setCurrentSection(
      currentTeaserCourse
        ? currentTeaserCourse?.sections?.find(
            (section: ContentfulSection) =>
              toLowerDashCase(section?.title) === sectionTitle
          )
        : currentCourse?.sections?.find(
            (section: ContentfulSection) =>
              toLowerDashCase(section?.title) === sectionTitle
          )
    );

    userCanBuyAdditionalTraining();
  }, [currentTeaserCourse ? currentTeaserCourse : currentCourse]);

  const units = currentSection?.units;
  const completedUnits: number = units?.reduce(
    (acc: any, unit: ContentfulUnit) =>
      acc + (unit?.status === CourseStatus.completed ? 1 : 0),
    0
  );

  if (unitsLoading || teaserUnitsLoading) return <Loading />;
  return (
    <>
      <StyledGrid container justifyContent="center">
        <BackButton location={`/course/${courseTitle}`} />
      </StyledGrid>
      <StyledGrid container centerContent backgroundColor={color.SOFTBLUE}>
        <StyledGrid item margin={`${spacingDefaults.large} 0`}>
          <StyledGrid container centerContent>
            <StyledGrid item>
              <Box
                fontSize={fontSize.small}
                fontFamily={fontFamily.secondary}
                fontWeight="500"
              >
                {currentTeaserCourse
                  ? currentTeaserCourse?.title
                  : currentCourse?.title}
              </Box>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid container centerContent>
            <StyledGrid item>
              <Box
                fontSize={fontSize.header}
                fontFamily={fontFamily.secondary}
                fontWeight="500"
              >
                {currentSection?.title}
              </Box>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid container centerContent>
            <StyledGrid item>
              <img src={currentSection?.thumbnailImage} height="200px" />
            </StyledGrid>
          </StyledGrid>
          <StyledGrid
            centerContent
            padding={spacingDefaults.normal}
            margin={`${spacingDefaults.normal} ${mobile ? undefined : "30%"}`}
          >
            <Box fontSize={fontSize.medium} fontWeight="500">
              {currentSection?.description}
            </Box>
          </StyledGrid>
          {mobile && (
            <StyledGrid container centerContent>
              <UnitsCompletedHeader
                completedUnits={completedUnits}
                totalUnits={units?.length || 0}
                mobile={mobile}
              />
            </StyledGrid>
          )}
        </StyledGrid>
      </StyledGrid>
      <StyledGrid container position="relative" centerContent>
        <StyledGrid
          position="absolute"
          width="100vw"
          height="150px"
          bottom="67px"
          zIndex="-1"
          backgroundColor={color.SOFTBLUE}
        ></StyledGrid>
        <img src={BlueHeaderCurve} style={{ width: "100%" }} />
        {!mobile && (
          <UnitsCompletedHeader
            completedUnits={completedUnits}
            totalUnits={units?.length || 0}
            mobile={mobile}
          />
        )}
      </StyledGrid>
      <StyledGrid centerContent margin={`${spacingDefaults.xlarge} 0`}>
        <Box
          fontSize={fontSize.large}
          fontFamily={fontFamily.secondary}
          fontWeight="500"
        >
          {`${
            currentTeaserCourse
              ? currentTeaserCourse?.title
              : currentCourse?.title
          } - ${currentSection?.title}`}
        </Box>
      </StyledGrid>
      <StyledGrid
        container
        centerContent={mobile ? true : false}
        maxWidth="1200px"
        margin="0 auto"
        alignItems="flex-start"
        padding={spacingDefaults.normal}
      >
        {mobile ? (
          <StyledGrid>
            {units?.map((unit: ContentfulUnit, idx: number) => {
              return (
                <StyledGrid
                  key={idx}
                  item
                  xs={12}
                  lg={6}
                  padding={`${spacingDefaults.xsmall} 0`}
                >
                  <UnitItem
                    unitDetails={unit}
                    unitNumber={idx + 1}
                    isTeaserTrainingLocked={isTeaserTrainingLocked}
                    isTrainingLocked={isTrainingLocked}
                    canBuyAdditionalTraining={canBuyAdditionalTraining}
                    redeemAdditionalTraining={redeemAdditionalTraining}
                  />
                </StyledGrid>
              );
            })}
          </StyledGrid>
        ) : (
          <>
            {units?.map((unit: ContentfulUnit, idx: number) => {
              return (
                <StyledGrid
                  key={idx}
                  container
                  item
                  lg={6}
                  padding={`${spacingDefaults.xsmall} 0`}
                >
                  <StyledGrid item marginLeft="10%" marginRight="10%">
                    <UnitItem
                      unitDetails={unit}
                      unitNumber={idx + 1}
                      isTeaserTrainingLocked={isTeaserTrainingLocked}
                      isTrainingLocked={isTrainingLocked}
                      canBuyAdditionalTraining={canBuyAdditionalTraining}
                      redeemAdditionalTraining={redeemAdditionalTraining}
                    />
                  </StyledGrid>
                </StyledGrid>
              );
            })}
          </>
        )}
      </StyledGrid>
      <StyledGrid
        centerContent
        marginTop="auto"
        marginBottom={spacingDefaults.normal}
      >
        <Link
          to={`/course/${courseTitle}`}
          style={{ color: color.DARKERGRAY, textDecoration: "underline" }}
        >
          Back to course
        </Link>
      </StyledGrid>
      <StyledGrid centerContent>
        <TreeGroupFooter />
      </StyledGrid>
    </>
  );
};

export default Section;
