import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";

import { Ruler, StyledGrid } from "components/simple";
import { CycleSummaryQuery } from "graphql/user/user.gql";
import { color, spacingDefaults } from "style/constants";
import ACELink from "../ACELink";
import BrainHealthIndex from "./BrainHealthIndex";
import ClinicianFeedback from "./ClinicianFeedback";
import Header from "./Header";
import { Cycle } from "./types";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";

interface CycleSummaryProps {
  disabled?: boolean;
  selectedCycleIndex: number;
  setSelectedCycleIndex: React.Dispatch<React.SetStateAction<number>>;

  hasAceEnabled?: boolean;
  hasImagingEnabled?: boolean;
}

const CycleSummary: React.FC<CycleSummaryProps> = ({
  disabled = false,
  selectedCycleIndex,
  setSelectedCycleIndex,

  hasAceEnabled = false
}: CycleSummaryProps) => {
  const [cycles, setCycles] = useState<Cycle[]>([]);
  const [selectedCycle, setSelectedCycle] = useState<Cycle>();

  const { loading, data, error } = useQuery(CycleSummaryQuery, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  });

  useEffect(() => {
    if (data) {
      const participantCycles = data.participantAssessmentCycles
        .filter(
          (x: Cycle) => x.status === UserAssessmentCycleStatus.SCORE_FINALIZED
        )
        .sort((a: Cycle, b: Cycle) => a.cycleNumber - b.cycleNumber);

      setCycles(participantCycles);
      setSelectedCycleIndex(participantCycles.length - 1);
      setSelectedCycle(participantCycles[participantCycles.length - 1]);
    }
  }, [data]);

  if (loading || error || cycles.length === 0) {
    return null;
  }

  return (
    <>
      {selectedCycle && (
        <>
          <StyledGrid
            backgroundColor={color.WHITE}
            border={`1px solid ${color.MUTEDGRAY}`}
            borderRadius="8px"
            container
            item
            marginBottom={spacingDefaults.medium}
            overflow="hidden"
            xs={12}
          >
            <StyledGrid item xs={12}>
              <Header
                backgroundColor={color.DARKBLUE}
                selectedCycleIndex={selectedCycleIndex}
                selectedCycleDate={selectedCycle.cycleCompletionDate}
                setSelectedCycleIndex={setSelectedCycleIndex}
                isPreviousDisabled={selectedCycleIndex === 0}
                isNextDisabled={selectedCycleIndex === cycles.length - 1}
              />
            </StyledGrid>
            {cycles.length >= 0 && (
              <StyledGrid item xs={12}>
                <BrainHealthIndex
                  cycles={cycles}
                  selectedCycleIndex={selectedCycleIndex}
                />
              </StyledGrid>
            )}

            <Ruler margins={`0 ${spacingDefaults.xxlarge}`} />

            <StyledGrid item xs={12}>
              <Ruler margins={`0 ${spacingDefaults.xxlarge}`} />

              <ClinicianFeedback notes={selectedCycle.notes} />
            </StyledGrid>
          </StyledGrid>

          <ACELink
            selectedCycleIndex={selectedCycleIndex}
            assessmentCycles={cycles}
          />
        </>
      )}
    </>
  );
};

export default CycleSummary;
