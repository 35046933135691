import React, { useState, useEffect } from "react";

import { Button, StyledGrid, AssessmentCard } from "components/simple";
import { Instructions, Word, WordCase } from "./styled";
import { spacingDefaults } from "style/constants";

interface InstructionData {
  highCase: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  callback: React.Dispatch<any>;
}

interface WordData {
  word: string;
  case: string;
}

interface WordListData {
  highscoreCase: string;
  words: WordData[];
}

interface WordFlashQuestionData {
  wordLists: WordListData[];
  wordInterval: number;
}

interface WordFlashQuestionProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  callback: React.Dispatch<React.SetStateAction<Record<any, any>>>;
  question: WordFlashQuestionData;
}

const InstructionDisplay: React.FC<InstructionData> = ({
  highCase,
  callback
}: InstructionData) => (
  <>
    <StyledGrid item>
      <WordCase bold={highCase === "uppercase"}>
        Words in UPPERCASE are worth{" "}
        {highCase === "uppercase" ? "10 points" : "1 point"}.
      </WordCase>
    </StyledGrid>
    <StyledGrid item>
      <WordCase bold={highCase === "lowercase"}>
        Words in lowercase are worth{" "}
        {highCase === "uppercase" ? "1 point" : "10 points"}.
      </WordCase>
    </StyledGrid>
    <StyledGrid item marginTop={spacingDefaults.large}>
      <Instructions>
        Remember, your goal is to earn as many points as you can by remembering
        words after you see the entire list of words.
      </Instructions>
    </StyledGrid>
    <StyledGrid item marginTop={spacingDefaults.large}>
      <Button onClick={() => callback(true)} wide>
        Begin
      </Button>
    </StyledGrid>
  </>
);

const WordDisplay: React.FC<WordData> = (data: WordData) => (
  <Word>
    {data.case === "uppercase"
      ? data.word.toUpperCase()
      : data.word.toLowerCase()}
  </Word>
);

const WordFlashQuestion: React.FC<WordFlashQuestionProps> = ({
  callback,
  question: { wordLists, wordInterval }
}: WordFlashQuestionProps) => {
  // randomly determine which word list to use
  const [list] = useState(Math.floor(Math.random() * wordLists.length));
  const [word, setWord] = useState(0);
  const [flash, setFlash] = useState(false);

  const speed = window.location.search === "?test_fast" ? 10 : wordInterval;

  const wordList = wordLists[list];

  const nextWord = (timer: number): void => {
    if (word >= wordList.words.length - 1) {
      clearInterval(timer);
      callback({ wordList: wordList });
    } else {
      setWord(word => word + 1);
    }
  };

  useEffect(() => {
    if (!flash) return;
    const timer: number = setInterval(() => {
      nextWord(timer);
    }, speed);
    return () => clearInterval(timer);
  });

  return (
    <AssessmentCard>
      <StyledGrid
        alignItems={"center"}
        container
        direction={"column"}
        fillRemainingHeight
        item
        justifyContent={"center"}
        spacing={1}
      >
        {flash ? (
          <WordDisplay {...wordList.words[word]} />
        ) : (
          <InstructionDisplay
            callback={() => setFlash(true)}
            highCase={wordList.highscoreCase}
          />
        )}
      </StyledGrid>
    </AssessmentCard>
  );
};

export default WordFlashQuestion;
