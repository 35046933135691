import React from "react";
import styled from "styled-components";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import SVGIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import { color, fontFamily, iconSizes } from "style/constants";
import globalTheme from "style/theme";

export const overrideTheme = createTheme({
  ...globalTheme,
  overrides: {
    MuiInputBase: {
      root: {
        "&:focus, &.Mui-focused": {
          outline: "#DFDFDF solid 3px"
        }
      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottomWidth: "0px !important"
        },
        "&:after": {
          borderBottomWidth: "0px !important"
        }
      }
    },
    MuiNativeSelect: {
      root: {
        fontFamily: fontFamily.primary,
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "22px",
        color: color.DARKERGRAY,
        padding: "1.5rem 2rem"
      },
      outlined: {
        border: "1px solid",
        borderColor: color.PALEGRAY,
        borderRadius: "8px",
        "&:focus": {
          borderRadius: "8px",
          backgroundColor: "inherit"
        }
      },
      select: {
        "&:hover": {
          borderColor: color.BLUE
        }
      }
    }
  }
});

export const selectedTheme = createTheme({
  ...overrideTheme,
  overrides: {
    ...overrideTheme.overrides,
    MuiNativeSelect: {
      root: {
        fontFamily: fontFamily.primary,
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22px",
        color: color.DARKBLUE,
        padding: "1.5rem 2rem"
      },
      outlined: {
        border: "1px solid",
        borderColor: color.BLUE,
        backgroundColor: color.PALEBLUE,
        borderRadius: "8px",
        "&:focus": {
          borderRadius: "8px",
          backgroundColor: color.PALEBLUE
        }
      }
    }
  }
});

const useStyles = makeStyles({
  icon: {
    top: "calc(50% - 5px)",
    right: "1.75rem",
    position: "absolute",
    pointerEvents: "none",
    fill: "none",
    stroke: color.ALTBLACK
  }
});

export const StyledDown: React.FC = () => (
  <SVGIcon
    classes={{ root: useStyles().icon }}
    width={iconSizes.normal}
    height={iconSizes.small}
  >
    <ChevronDown />
  </SVGIcon>
);

export const StyledMenuItem = styled.option`
  && {
    color: ${color.DARKERGRAY};
    &:hover {
      background-color: ${color.BLUE};
    }
  }
`;

export const StyledSelect = styled(NativeSelect)`
  && {
    width: 100%;
  }
`;
