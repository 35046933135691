export function checkAuth(): boolean {
  const authToken = getToken();
  const loggedIn = (authToken && authToken !== "") || false;
  return loggedIn;
}

export function getToken() {
  const assumedIdentityToken = localStorage.getItem("assumedIdentityToken");
  if (assumedIdentityToken) {
    return assumedIdentityToken;
  } else {
    return localStorage.getItem("token");
  }
}

export function deleteToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("assumedIdentityToken");
}
