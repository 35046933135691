export interface TrainingProgress {
  completedCourses: number;
  totalCourses: number;
  dueDate: number | null;
  currentCourseProgress: DetailedCourseProgress;
  overallCourseProgress: CourseProgress[];
}

export interface TeaserTrainingProgress {
  completedTeaserCourses: number;
  totalTeaserCourses: number;
  dueDate: number | null;
  currentTeaserCourseProgress: DetailedCourseProgress;
  overallTeaserCourseProgress: CourseProgress[];
}

export interface CourseProgress {
  id: string;
  name: string;
  categoryName: string;
  pillars: Pillars[];
  locked?: boolean;
  completionStatus?: CompletionStatus;
  completionPercentage: number;
}

export type DetailedCourseProgress = CourseProgress & {
  timeEstimate: number | null;
  unitProgress: UnitProgress[];
  description: string;
  code: string;
};

export interface UnitProgress {
  name: string;
  completionStatus: CompletionStatus;
}

export enum CompletionStatus {
  NotAttempted = "not_attempted",
  Incomplete = "incomplete",
  Completed = "completed"
}

export enum Pillars {
  Cognitive = "cognitive",
  RealLife = "real-life",
  Social = "social",
  WellBeing = "well-being"
}
