import React from "react";
import { Box, CircularProgress, Icon, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import styled from "styled-components";

import LockIcon from "assets/lock-filled.svg";
import UnPin from "assets/unpin.svg";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import { Button, StyledGrid } from "components/simple";
import { StyledCard } from "components/pages/DashboardV2/styled";
import { Habit, UserHabit } from "models/userHabits";

export const CircularProgressBackground = styled(CircularProgress)`
  && {
    color: ${color.MUTEDGRAY};
  }
`;

interface HabitCardProps {
  habit: Habit;
  listSize: number;
  userHabit: UserHabit;
  disabled?: boolean;
  onClick?: () => void;
  mobile: boolean;
  setSelectedHabit: (habit: UserHabit) => void;
}

const LongCard: React.FC<HabitCardProps> = props => {
  const {
    habit,
    mobile,
    userHabit,
    disabled = false,
    onClick = undefined,
    setSelectedHabit
  } = props;

  return (
    <StyledCard
      container
      margin={`${spacingDefaults.small} 0 0 0`}
      minHeight={mobile ? "131px" : "134px"}
      textAlign="left"
      display="flex"
      alignContent="flex-start"
      direction="column"
      width={"100%"}
      position="relative"
    >
      <StyledGrid
        width="100%"
        container
        justifyContent="space-between"
        padding="9px 7px"
        position="absolute"
        top="0"
        left="0"
      >
        <StyledGrid
          container
          border="1px solid #2F3243"
          width="25px"
          height="14px"
          borderRadius="9px"
          backgroundColor={color.WHITE}
          color="#2F3243"
          justifyContent="center"
          alignContent="center"
        >
          <img src={UnPin} alt="unpin" />
        </StyledGrid>
        <Button
          miniIcon
          aria-label="close"
          onClick={() => {
            setSelectedHabit(userHabit);
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      </StyledGrid>
      <StyledGrid
        container
        onClick={onClick}
        style={{
          cursor: "pointer"
        }}
      >
        <StyledGrid direction="row" container>
          <StyledGrid
            container
            backgroundColor={color.LIGHTERBLUE}
            justifyContent="center"
            alignContent="center"
            width={mobile ? "33%" : "25%"}
            height="134px"
          >
            <StyledGrid item position="relative" paddingTop="10px">
              {!disabled && userHabit && userHabit?.maxStreakCount && (
                <>
                  <div style={{ position: "absolute" }}>
                    <CircularProgressBackground
                      color="secondary"
                      size={mobile ? "80px" : "91px"}
                      thickness={3}
                      value={100}
                      variant="determinate"
                    />
                  </div>
                  <CircularProgress
                    color="secondary"
                    size={mobile ? "80px" : "91px"}
                    thickness={3}
                    value={
                      (userHabit.streakCount / userHabit.maxStreakCount) * 100
                    }
                    variant="determinate"
                  />
                  {userHabit?.streakLevel && (
                    <div
                      style={{
                        position: "absolute",
                        top: mobile ? "19px" : "21px",
                        left: mobile ? "9px" : "11px"
                      }}
                    >
                      <img
                        src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                          userHabit.streakLevel +
                          (userHabit?.streakCount === userHabit?.maxStreakCount
                            ? 0
                            : -1)
                        }.png`}
                        height={mobile ? "62px" : "69px"}
                      />
                    </div>
                  )}
                </>
              )}
            </StyledGrid>
          </StyledGrid>
          <StyledGrid
            item
            padding="0 16px 7px 16px"
            width={mobile ? "67%" : "75%"}
          >
            <StyledGrid
              container
              direction="column"
              height="100%"
              justifyContent="center"
            >
              <StyledGrid item>
                <Box
                  fontSize={fontSize.normal}
                  fontWeight={400}
                  lineHeight={"19px"}
                  color={color.BLACK}
                  margin={mobile ? "8px 0" : "17px 0"}
                >
                  {habit.name}
                </Box>
              </StyledGrid>
              {!disabled && (
                <StyledGrid item ellipsis lineClamp={mobile ? "3" : "2"}>
                  <Box
                    component="div"
                    fontSize={fontSize.small}
                    color={color.GRAPHITE}
                    fontWeight={mobile ? 300 : 400}
                    lineHeight={mobile ? "18px" : "17px"}
                  >
                    {habit.content}
                  </Box>
                </StyledGrid>
              )}
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledCard>
  );
};
export default LongCard;
