import styled from "styled-components";

import {
  breakpoints,
  color,
  fontSize,
  spacingDefaults,
  fontFamily
} from "style/constants";

export const ProfileField = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacingDefaults.medium};
  min-height: 45px;
  padding: 0 ${spacingDefaults.normal};

  @media (max-width: ${breakpoints.lg}px) {
    align-items: flex-start;
  }

  label {
    color: ${color.LIGHTGRAY};
    font-size: 10px;
    line-height: 240%;
  }

  span {
    color: ${color.DARKGRAY};
    font-size: ${fontSize.normal};
    font-weight: 300;
    line-height: 131%;
  }
`;
export const Header = styled.h1`
  color: ${color.BLACK};
  font-family: ${fontFamily.secondary};
  font-size: ${fontSize.large};
  line-height: 29px;
  letter-spacing: -0.36px;
`;

export const Paragraph = styled.p`
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.small};
  white-space: pre-wrap;
`;
