import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";

import { BHIChart, ScoreHistory, StyledGrid, StyledP } from "components/simple";
import {
  NewCycle,
  PillarName
} from "components/pages/Index/CycleSummary/types";
import { InfoCardWrapper } from "../ScoreHistory/styled";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { BHIChartType } from "../BHIChart";
import { StyledCard } from "components/pages/DashboardV2/styled";
interface FactorCardProps {
  cycles: NewCycle[];
  labels: string[];
  scores: number[];
  selectedCycleIndex: number;
  selectedFactor?: PillarName;
  stepIndex: number;
  chartType: BHIChartType;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setChartType: React.Dispatch<React.SetStateAction<BHIChartType>>;
  notes: string;
  notesDate: string;
}
import Logo from "components/simple/Logo";
const OverviewCard: React.FC<FactorCardProps> = ({
  cycles,
  labels,
  scores,
  selectedCycleIndex,
  selectedFactor,
  stepIndex,
  chartType,
  setStepIndex,
  setChartType,
  notes,
  notesDate
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const hasMultipleCycles = cycles.length > 0;
  const formattedDate = new Date(notesDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });
  return (
    <StyledGrid container>
      <StyledGrid item width="100%" textAlign="center">
        <InfoCardWrapper>
          <StyledGrid container justifyContent="space-between">
            <StyledGrid item xs={12} lg={hasMultipleCycles ? 6 : 12}>
              <Box
                fontFamily={fontFamily.secondary}
                fontSize={fontSize.large}
                margin={`0 0 ${spacingDefaults.large} 0`}
              >
                Your BrainHealth® Index{" "}
                {`${chartType === BHIChartType.Tree ? "Tree" : "Factors"}`}
              </Box>
              <BHIChart
                labels={labels}
                scores={scores}
                selectedFactor={selectedFactor}
                chartType={chartType}
                stepIndex={stepIndex}
                setChartType={setChartType}
                setStepIndex={setStepIndex}
              />
            </StyledGrid>

            <StyledGrid item xs={12} lg={6}>
              <Box
                fontFamily={fontFamily.secondary}
                fontSize={fontSize.large}
                margin={`0 0 ${spacingDefaults.large} 0`}
              >
                Score History
              </Box>
              <ScoreHistory
                cycles={cycles}
                selectedCycleIndex={selectedCycleIndex}
              />
            </StyledGrid>
            <StyledCard width="100%" flexDirection="flex-start">
              <StyledGrid
                padding={
                  mobile ? spacingDefaults.medium : spacingDefaults.xlarge
                }
                xs={12}
              >
                <>
                  <StyledGrid container item xs={12}>
                    <StyledGrid item width="45px">
                      <Logo
                        styleType="coachingCall"
                        height="5px"
                        width="20px"
                      />
                    </StyledGrid>

                    <StyledGrid item paddingTop="7px" xs>
                      <Box
                        color={color.BLACK}
                        component="p"
                        fontSize={fontSize.normal}
                        fontWeight="600"
                        lineHeight={fontSize.large}
                        margin="0"
                        textAlign="left"
                      >
                        Assessment Notes
                      </Box>

                      <Box
                        color={color.LIGHTGRAY}
                        component="p"
                        fontSize={fontSize.small}
                        margin={`0 0 ${spacingDefaults.normal} 0`}
                        textAlign="left"
                      >
                        {formattedDate}
                      </Box>

                      <Box
                        color={color.BLACK}
                        component="p"
                        fontSize={fontSize.small}
                        lineHeight={fontSize.large}
                        margin="0"
                        textAlign="left"
                      >
                        {notes}
                      </Box>
                    </StyledGrid>
                  </StyledGrid>
                </>
              </StyledGrid>
            </StyledCard>
          </StyledGrid>
        </InfoCardWrapper>
      </StyledGrid>
    </StyledGrid>
  );
};

export default OverviewCard;
