import { useMutation } from "@apollo/react-hooks";
import { get } from "lodash";
import qs from "qs";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import track from "react-tracking";

import { UpdateEmailAddress } from "graphql/user/user.gql";
import {
  showLoader,
  hideLoader,
  showErrorNotification,
  showSuccessNotification
} from "state";
import routesConfig from "utils/routesConfig";

const url: string = routesConfig.updateEmail.path;

const UpdateEmail: React.FC<any> = (props: any) => {
  const { location } = props;

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const token = queryParams["token"];
  const to = queryParams["to"];

  const dispatch = useDispatch();
  const [updateEmailAddress] = useMutation(UpdateEmailAddress);
  const history = useHistory();

  useEffect(() => {
    (async function () {
      dispatch(showLoader());

      if (!token) history.push("/dashboard");

      let response;

      try {
        response = await updateEmailAddress({ variables: { token } });
      } catch {
        dispatch(hideLoader());
        return;
      }

      const sessionToken = get(response, "data.updateEmailAddress.token");

      if (sessionToken) {
        const newEmail = get(response, "data.updateEmailAddress.newEmail");
        if (newEmail) {
          dispatch(
            showSuccessNotification(
              "Success",
              `You have successfully changed your email address to ${newEmail}`
            )
          );
        } else {
          dispatch(
            showErrorNotification(
              "Failed",
              `Failed to change your email address. Please check its availability.`
            )
          );
        }
        dispatch(hideLoader());

        localStorage.setItem("token", sessionToken);

        history.push(`/${to || "dashboard"}`);
        return;
      }
    })();
  }, [token, updateEmailAddress, dispatch, history, to]);

  return null;
};

export default track({
  url
})(UpdateEmail);
