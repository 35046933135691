import styled from "styled-components";
import { fontFamily, fontSize, color } from "style/constants";

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  font-family: ${fontFamily.primary};
  font-size: ${fontSize.medium};
  color: ${color.DARKERGRAY};
  text-align: center;

  p {
    margin: 0;
    padding-top: 0.5rem;
  }

  div span {
    width: 100%;
    position: absolute;
    height: 26px;
  }

  div:first-child span {
    width: 50%;
    position: absolute;
    height: 26px;
    align-self: flex-end;
  }

  div:last-child span {
    width: 50%;
    position: absolute;
    height: 26px;
    align-self: flex-start;
  }
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 122px;
  height: 90px;
  position: relative;
`;

export const Line = styled.div`
  height: 1px;
  background-color: ${color.LIGHTERGRAY};
  margin: 12px 0;
`;

export const Radio = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
  margin: auto;

  ::before {
    content: "";
    border-radius: 100%;
    border: 2px solid ${color.DARKBLUE};
    background: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    margin: 0;
    z-index: 0;
  }
`;

export const RadioLabel = styled.label`
  cursor: pointer;
  position: relative;
`;

export const RadioInput = styled.input`
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  ~ p {
    position: absolute;
    width: 110px;
    height: 48px;
    left: 50%;
    transform: translateX(-50%);
  }

  :checked ~ p {
    color: ${color.DARKBLUE};
  }

  :focus {
    outline: none;
  }

  :checked ~ .radio-fill {
    width: 100%;
    height: 100%;
    transition: width 0.2s ease-out, height 0.2s ease-out;
    box-shadow: 0px 3px 12px rgba(0, 59, 88, 0.2114);
  }
`;

export const RadioFill = styled.div`
  background: ${color.DARKBLUE};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;
`;
