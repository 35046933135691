import { useApolloClient } from "@apollo/react-hooks";
import React from "react";
import { useDispatch } from "react-redux";

import { BackButton, ContentCarousel, StyledGrid } from "components/simple";
import { GetBrainHqLink } from "graphql/training/training.gql";
import { showErrorNotification } from "state";
import PageOne from "./PageOne";
import PageThree from "./PageThree";
import PageTwo from "./PageTwo";

const BrainHq: React.FC = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const content = [
    {
      component: <PageOne />,
      buttonText: "Continue"
    },
    {
      component: <PageTwo />,
      buttonText: "Continue"
    },
    {
      component: <PageThree />,
      buttonText: "Continue",
      link: window.location.pathname,
      callback: async (): Promise<void> => {
        const response = await client.query({ query: GetBrainHqLink });

        if (response.data?.training?.bhqLink) {
          window.location.assign(response.data.training.bhqLink);
        } else {
          dispatch(
            showErrorNotification("", "Unable to connect you to brainHQ.")
          );
        }
      }
    }
  ];

  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      fillRemainingHeight
      justifyContent="center"
    >
      <BackButton />
      <ContentCarousel content={content} />
    </StyledGrid>
  );
};

export default BrainHq;
