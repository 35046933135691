import { breakpoints } from "style/constants";
import styled from "styled-components";
import { fontFamily, fontSize } from "style/constants";

export const StyledGrid = styled.div`
  && {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    .slides-wrapper {
      white-space: nowrap;
      transition: transform 0.3s;
    }
    .slide-item {
      display: inline-grid;
      align-items: center;
      justify-content: center;
      background-color: #fff;
    }
    .slide-item-heading {
      font-family: ${fontFamily.primary};
      font-size: 22px;
      font-weight: 500;
      line-height: 33px;
      padding: 20px 0px 0px 20px;
      text-align: left;
    }
    .slide-item-content {
      font-family: ${fontFamily.primary};
      font-size: ${fontSize.xlarge};
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 20px 0px 0px 20px;
      white-space: normal;
    }
    .slide-item-image {
      width: 85%;
      height: 25vh;
      padding: 0 10px 0 10px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
      justify-content: center;
      margin: auto;
    }
    .slides-controls {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 3%;
    }
    .arrow-indicators {
      background: none;
      border: none;
      cursor: pointer;
      margin-top: 20px;
    }
  }
`;

export const StyledImage = styled.img`
  height: 350px;
  width: auto;
  object-fit: contain;
  padding: 5px;
  @media (max-width: ${breakpoints.lg}px) {
    height: 300px;
  }
  @media (max-width: ${breakpoints.md}px) {
    height: 210px;
  }
`;
