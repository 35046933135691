import React from "react";

import { ReactComponent as Disqualified } from "assets/disqualified.svg";
import { Button, StyledGrid, Heading, StyledP } from "components/simple";
import { color, spacingDefaults, fontSize } from "style/constants";

const Disqualify: React.FC = () => (
  <StyledGrid
    justifyContent="center"
    alignContent="center"
    alignItems="center"
    backgroundColor={color.WHITE}
    container
    direction="column"
    fillRemainingHeight
    minHeight="700px"
    wrap="nowrap"
    textAlign="center"
    padding={spacingDefaults.normal}
    itemMargin={spacingDefaults.normal}
  >
    <StyledGrid item>
      <Disqualified />
    </StyledGrid>
    <StyledGrid item>
      <Heading tag="h1" fontSize={fontSize.header}>
        Another Option
      </Heading>
    </StyledGrid>
    <StyledGrid
      item
      maxWidth="600px"
      mobileMarginBottom={spacingDefaults.normal}
    >
      <StyledP textAlign="center">
        Based on your responses, you do not qualify to participate in the study,
        but you can still learn about brain-healthy practices. Just click the
        link!
      </StyledP>
    </StyledGrid>
    <StyledGrid item>
      <Button wide href={"/resources"}>
        Explore Resources
      </Button>
    </StyledGrid>
  </StyledGrid>
);

export default Disqualify;
