import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import styled from "styled-components";

import UnPin from "assets/unpin.svg";
import { color, fontSize, spacingDefaults } from "style/constants";
import { Button, StyledGrid } from "components/simple";
import { StyledCard } from "components/pages/DashboardV2/styled";
import { Habit, UserHabit } from "models/userHabits";

export const CircularProgressBackground = styled(CircularProgress)`
  && {
    color: ${color.MUTEDGRAY};
  }
`;

interface HabitCardProps {
  habit: Habit;
  listSize: number;
  userHabit: UserHabit;
  onClick?: () => void;
  mobile: boolean;
  setSelectedHabit: (habit: UserHabit) => void;
}

const MiniCard: React.FC<HabitCardProps> = props => {
  const {
    habit,
    listSize,
    mobile,
    userHabit,
    onClick = undefined,
    setSelectedHabit
  } = props;

  const titleSize =
    listSize == 2
      ? fontSize.normal
      : listSize == 3
      ? fontSize.small
      : fontSize.xsmall;

  const width = mobile
    ? "48%"
    : listSize == 2
    ? "48%"
    : listSize == 3
    ? "31%"
    : "23%";

  return (
    <StyledCard
      container
      margin={`${mobile ? spacingDefaults.xsmall : spacingDefaults.medium} 0`}
      minHeight="188px"
      textAlign="left"
      display="flex"
      alignContent="flex-start"
      width={width}
    >
      <StyledGrid
        width="100%"
        container
        justifyContent="space-between"
        padding="9px 7px"
        backgroundColor={color.LIGHTERBLUE}
      >
        <StyledGrid
          container
          border="1px solid #2F3243"
          width="25px"
          height="14px"
          borderRadius="9px"
          backgroundColor={color.WHITE}
          color="#2F3243"
          justifyContent="center"
          alignContent="center"
        >
          <img src={UnPin} alt="unpin" />
        </StyledGrid>
        <Button
          miniIcon
          aria-label="close"
          onClick={() => {
            setSelectedHabit(userHabit);
          }}
        >
          <CloseIcon fontSize="small" />
        </Button>
      </StyledGrid>
      <StyledGrid
        onClick={onClick}
        style={{
          cursor: "pointer"
        }}
      >
        <StyledGrid
          container
          backgroundColor={color.LIGHTERBLUE}
          justifyContent="center"
          alignContent="center"
        >
          <StyledGrid item position="relative">
            {userHabit && userHabit?.maxStreakCount && (
              <>
                <div style={{ position: "absolute" }}>
                  <CircularProgressBackground
                    color="secondary"
                    size={"75px"}
                    thickness={3}
                    value={100}
                    variant="determinate"
                  />
                </div>
                <CircularProgress
                  color="secondary"
                  size={"75px"}
                  thickness={3}
                  value={
                    (userHabit.streakCount / userHabit.maxStreakCount) * 100
                  }
                  variant="determinate"
                />
                {userHabit?.streakLevel && (
                  <div
                    style={{ position: "absolute", top: "10px", left: "10px" }}
                  >
                    <img
                      src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                        userHabit.streakLevel +
                        (userHabit?.streakCount === userHabit?.maxStreakCount
                          ? 0
                          : -1)
                      }.png`}
                      height="55px"
                    />
                  </div>
                )}
              </>
            )}
          </StyledGrid>
        </StyledGrid>
        <StyledGrid item padding="0 9px 7px 9px">
          <StyledGrid
            container
            direction="column"
            height="100%"
            justifyContent="space-evenly"
          >
            <StyledGrid item>
              <Box
                fontSize={titleSize}
                fontWeight={400}
                lineHeight={"16px"}
                color={color.NETURALS900}
                margin={"8px 0"}
              >
                {habit.name}
              </Box>
            </StyledGrid>
            <StyledGrid item ellipsis lineClamp={"2"}>
              <Box
                component="div"
                fontSize={"7.8px"}
                color={color.NETURALS700}
                fontWeight={300}
                lineHeight={"12px"}
              >
                {habit.content}
              </Box>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledCard>
  );
};
export default MiniCard;
