import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React, { useEffect } from "react";

import { StyledGrid } from "components/simple";
import { color as colors, spacingDefaults } from "style/constants";
import { Pillar } from "../../types";
import {
  Body,
  PillarValue,
  StepperButton,
  StyledStepper,
  SubTitle
} from "../styled";

interface StepperProps {
  isMobile: boolean;
  pillar: Pillar;
  callback?: React.Dispatch<React.SetStateAction<number>>;
  print?: boolean;
}

const Stepper: React.FC<StepperProps> = ({
  isMobile,
  pillar,
  callback,
  print
}: StepperProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const assessments = pillar.assessments;
  const color = pillar.color;
  const maxSteps = assessments.length;

  useEffect(() => {
    if (callback) callback(activeStep);
  }, [activeStep, callback]);

  const handleNext = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  if (!isMobile) {
    return (
      <StyledGrid container wrap={"nowrap"}>
        {assessments.map((a, i) => (
          <StyledGrid
            key={i}
            border={`1px solid ${colors.MUTEDGRAY}`}
            borderRadius="8px"
            padding={spacingDefaults.normal}
            margin={spacingDefaults.normal}
            textAlign={"center"}
            boxShadow={print ? "" : "0px 11px 17px 5px rgba(0,0,0,0.05);"}
            style={{ flex: 1 }}
          >
            <PillarValue style={{ color }}>{a.score.toFixed(1)}</PillarValue>
            <SubTitle>{a.name}</SubTitle>
            <Body>{a.description}</Body>
          </StyledGrid>
        ))}
      </StyledGrid>
    );
  }

  return (
    <StyledGrid container>
      <StyledGrid
        item
        textAlign={"center"}
        width="100%"
        border={`1px solid ${colors.MUTEDGRAY}`}
        borderRadius="8px"
        padding={spacingDefaults.normal}
        margin={spacingDefaults.normal}
        boxShadow="0px 11px 17px 5px rgba(0,0,0,0.05)"
      >
        <>
          <PillarValue style={{ color }}>
            {assessments[activeStep].score.toFixed(1)}
          </PillarValue>
          <SubTitle>{assessments[activeStep].name}</SubTitle>
          <Body>{assessments[activeStep].description}</Body>
        </>
      </StyledGrid>
      <StyledGrid item width="100%">
        <StyledStepper
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            <StepperButton
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              aria-label="next"
            >
              <KeyboardArrowRight />
            </StepperButton>
          }
          backButton={
            <StepperButton
              onClick={handleBack}
              disabled={activeStep === 0}
              aria-label="back"
            >
              <KeyboardArrowLeft />
            </StepperButton>
          }
        />
      </StyledGrid>
    </StyledGrid>
  );
};

export default Stepper;
