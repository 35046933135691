import { useMutation, useQuery } from "@apollo/react-hooks";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import track, { useTracking } from "react-tracking";

import { ReactComponent as JoinStudy } from "assets/join-study.svg";
import {
  AssessmentCard,
  BackButton,
  Button,
  StyledGrid
} from "components/simple";
import {
  Consent,
  GetOrganizationConsentFormInfo,
  GetUserInExpiredOrgStatus,
  OptOut,
  TerminateUserConsentCadence,
  TransferToBhpOnExpiry
} from "graphql/user/user.gql";
import {
  fetchUser,
  getUser,
  hideLoader,
  showErrorNotification,
  showLoader
} from "state";
import { spacingDefaults } from "style/constants";
import { JoinHeader, Paragraph } from "./styled";
import routesConfig from "utils/routesConfig";

const url = routesConfig.consent.path;

const Join: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tracking = useTracking();
  const [consent] = useMutation(Consent);
  const [optOut] = useMutation(OptOut);
  const [terminateUserConsentCadence] = useMutation(
    TerminateUserConsentCadence
  );

  const { data } = useQuery(GetOrganizationConsentFormInfo, {
    fetchPolicy: "no-cache"
  });

  const shouldHideConsentForm = data?.me?.organization?.hideConsentForm;
  const shouldHidePostConsentQuestions =
    data?.me?.organization?.hidePostConsentQuestions;

  const consentModalBodyCopy = `By consenting to participate in The BrainHealth Project, you
  are confirming that you are 18 years of age or older, that you
  understand the information provided in the consent form and
  have no further questions, and that you freely agree to
  participate in the study. If that is all true, click on the "I
  Consent to Participate" button to begin.`;

  const consentModalBodyCopyForHideConsent = `By using the BrainHealth App, you embark on a journey to explore strategies that enhance your understanding of brain health through our training, habits, and resources.`;
  const [transferToBhpOnExpiry] = useMutation(TransferToBhpOnExpiry);
  const [marketingOptIn, setMarketingOptIn] = useState(false);
  const user = useSelector(getUser);
  const [isUserInExpiredOrg, setIsUserInExpiredOrg] = useState(false);
  useQuery(GetUserInExpiredOrgStatus, {
    onCompleted: data => {
      setIsUserInExpiredOrg(data?.me?.isInExpiredOrg);
    }
  });

  async function updateConsent(consented: boolean): Promise<void> {
    dispatch(showLoader());
    tracking.trackEvent({
      url,
      actions: ["Consent", consented ? "Consent to Join" : "I do not consent"]
    });
    if (consented) {
      await consent({
        variables: { input: { marketingOptIn: marketingOptIn } }
      });
    } else if (user?.forcedConsent) {
      await terminateUserConsentCadence();
      dispatch(hideLoader());
      history.push("/consent");
      return;
    } else {
      await optOut();
      history.push("/consent/join");
    }
    dispatch(fetchUser());
    consented
      ? history.push("/notification_preferences")
      : history.push("/dashboard");
  }

  async function moveToBhp() {
    try {
      dispatch(showLoader());
      await transferToBhpOnExpiry();
    } catch (error) {
      dispatch(showErrorNotification("Uh oh, something went wrong"));
      dispatch(hideLoader());
    }
  }

  const [disabled, setDisabled] = useState(false);
  return (
    <>
      {!shouldHideConsentForm && !shouldHidePostConsentQuestions && (
        <BackButton location="/consent/form" />
      )}
      <StyledGrid container item justifyContent="center">
        <AssessmentCard>
          <StyledGrid item marginTop={spacingDefaults.large}>
            <JoinStudy />
          </StyledGrid>
          <StyledGrid item margin={spacingDefaults.normal}>
            <JoinHeader>
              {shouldHideConsentForm
                ? `I Agree and Continue`
                : `I Consent to Participate`}
            </JoinHeader>
          </StyledGrid>
          <StyledGrid
            item
            maxWidth="550px"
            mobileMaxWidth="80%"
            marginBottom={spacingDefaults.normal}
          >
            <Paragraph>
              {shouldHideConsentForm
                ? consentModalBodyCopyForHideConsent
                : consentModalBodyCopy}
            </Paragraph>
          </StyledGrid>
          <StyledGrid item margin={spacingDefaults.normal}>
            <Button
              onClick={() => {
                if (isUserInExpiredOrg) moveToBhp();
                updateConsent(true);
                setDisabled(true);
              }}
              disabled={disabled}
            >
              {shouldHideConsentForm
                ? `I Agree and Continue`
                : `I Consent to Participate`}
            </Button>
          </StyledGrid>
          {!shouldHideConsentForm && (
            <StyledGrid item margin={spacingDefaults.normal}>
              <Button
                plain
                onClick={() => {
                  updateConsent(false);
                  setDisabled(true);
                }}
                disabled={disabled}
                style={{ textDecoration: "underline" }}
              >
                I do not consent
              </Button>
            </StyledGrid>
          )}
        </AssessmentCard>
      </StyledGrid>
    </>
  );
};

export default track({
  url
})(Join);
