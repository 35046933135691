import { Hidden } from "@material-ui/core";
import React from "react";

import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import { ReactComponent as LockIcon } from "assets/lock-filled.svg";
import { StyledGrid, WheelIcons, StyledP } from "components/simple";
import { color, spacingDefaults } from "style/constants";
import { StyledLinearProgress } from "./styled";

interface CourseStatusProps {
  name: string;
  category: string;
  pillars: string[];
  locked: boolean;
  completionPercentage: number;
}

const CourseStatus: React.FC<CourseStatusProps> = ({
  name,
  category,
  pillars,
  locked,
  completionPercentage
}: CourseStatusProps) => {
  return (
    <StyledGrid
      backgroundColor={locked ? color.WHITE : color.PALEBLUE}
      border={`1px solid ${locked ? color.LIGHTGRAY : color.DARKBLUE}`}
      borderRadius="8px"
      item
      overflow="hidden"
      width="100%"
    >
      <StyledGrid
        alignItems="center"
        backgroundColor={locked ? color.LIGHTGRAY : color.DARKBLUE}
        container
        height="32px"
        item
        justifyContent="space-between"
        padding="0 20px"
        width="100%"
      >
        <StyledP color={color.WHITE}>{category}</StyledP>

        <StyledGrid item marginTop="1px">
          <WheelIcons pillars={pillars} />
        </StyledGrid>
      </StyledGrid>

      <StyledGrid
        alignItems="center"
        container
        height="60px"
        item
        padding="0 20px"
      >
        <StyledGrid item xs>
          <StyledP color={color.BLACK}>
            <strong>{name}</strong>
          </StyledP>
        </StyledGrid>

        {locked && <LockIcon height={30} width={30} />}

        {completionPercentage === 100 && (
          <CheckIcon
            fill={color.BLUE}
            filter="saturate(0)"
            height={30}
            width={30}
          />
        )}

        {!locked && completionPercentage < 100 && (
          <>
            <Hidden lgUp>
              <StyledP color={color.BLACK}>
                <em>In Progress</em>
              </StyledP>
            </Hidden>

            <Hidden mdDown>
              <StyledGrid marginRight={spacingDefaults.xsmall}>
                <StyledP color={color.BLACK}>{completionPercentage}%</StyledP>
              </StyledGrid>

              <StyledLinearProgress
                color="secondary"
                value={completionPercentage}
                variant="determinate"
              />
            </Hidden>
          </>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default CourseStatus;
