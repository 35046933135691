import React, { useEffect, Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import {
  Challenges,
  GoogleFit,
  NotificationPreferences
} from "components/pages";
import Article from "components/pages/Resources/Article";
import GroupPage from "components/pages/Assessments/Group";
import Intro from "components/pages/Assessments/Intro";
import PartOutro from "components/pages/Assessments/Outro";
import PartOutroFinal from "components/pages/Assessments/OutroFinal";
import PreamblePage from "components/pages/Assessments/Preamble";
import QuestionsPage from "components/pages/Assessments/Questions";
import Badges from "components/pages/Badges";
import BrainHq from "components/pages/BrainHq";
import BrainHqAssessment from "components/pages/BrainHqAssessment";
import CoachingCall from "components/pages/Coaching/CoachingCall";
import Consent from "components/pages/Consent";
import Dashboard from "components/pages/Dashboard";
import PDF from "components/pages/Dashboard/CycleSummary/BrainHealthIndex/Pdf";
import OAuthCallback from "components/pages/OAuth/Callback";
import OnboardingVideo from "components/pages/OnboardingVideo";
import PageNotFound from "components/pages/PageNotFound";
import Profile from "components/pages/Profile";
import Resources from "components/pages/Resources";
import FullOrganization from "components/pages/Screener/Qualification/FullOrganization";
import Qualification from "components/pages/Screener/Qualification";
import Screener from "components/pages/Screener/Screener";
import SurveyCompletePage from "components/pages/Surveys/Outro";
import SurveyQuestionsPage from "components/pages/Surveys/Questions";
import Training from "components/pages/Training";
import Faq from "components/pages/Faq";
import { Loading, StyledGrid } from "components/simple";
import DietID from "components/pages/DietID";

import {
  fetchUser,
  getUser,
  getUserStatus,
  hideLoader,
  UserStatus
} from "state";
import { checkAuth } from "utils/auth";
import routesConfig from "utils/routesConfig";
import BrainGauge from "components/pages/BrainGauge";
import LoggedInAs from "components/simple/LoggedInAs";
import Courses from "components/pages/Contentful/Courses";
import Course from "components/pages/Contentful/Course";
import Section from "components/pages/Contentful/Section";
import CoachingConfirmation from "components/pages/CoachingConfirmation";
import ImagingConfirmation from "components/pages/ImagingConfirmation";
import Unit from "components/pages/Contentful/Unit";
import Scheduling from "components/pages/Scheduling";
import GroupCoachingConfirmation from "components/pages/GroupCoachingConfirmation";
import DashboardV2 from "components/pages/DashboardV2";
import AssessmentOverview from "components/pages/AssessmentOverview";
import {
  getOrgConfig,
  isFeatureEnabled
} from "graphql/featureFlags/featureFlags.gql";
import Index from "components/pages/Index/index";
import HabitSetup from "components/pages/HabitSetup";
import TrackYourHabits from "components/pages/HabitSetup/TrackYourHabits";
import MyJourney from "components/pages/DashboardV2/MyJourney";
import Stats from "components/pages/Stats";
import Ranks from "components/pages/Ranks";
import SelectedHabit from "components/pages/HabitSetup/SelectedHabit";
import HabitSurvey from "components/pages/HabitSurvey";
import HabitSurveyComplete from "components/pages/HabitSurvey/HabitSurveyComplete";
import ResourceSurvey from "components/pages/ResourceSurvey";
import ResourceSurveyComplete from "components/pages/ResourceSurvey/ResourceSurveyComplete";
import PDFRefactor from "components/pages/Index/CycleSummary/BrainHealthIndex/PDFRefactor";

interface Props {
  setIs404: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProtectedRoutes: React.FC<Props> = ({ setIs404 }: Props) => {
  const isSignedIn = checkAuth();

  const history = useHistory();
  const location = history.location.pathname;

  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  const [isRefactorEnabled, setIsRefactorEnabled] = useState<boolean>(false);
  const [isRanksEnabled, setIsRanksEnabled] = useState<boolean>(false);
  const [isContentfulEnabled, setIsContentfulEnabled] = useState<boolean>(
    false
  );
  const [isResourceEnabled, setIsResourceEnabled] = useState<boolean>(false);
  const [isTrainingEnabled, setIsTrainingEnabled] = useState<boolean>(false);

  const { data } = useQuery(getOrgConfig, {
    onCompleted: d => {
      setIsResourceEnabled(d.getOrgConfig.isResourceEnabled);
      setIsTrainingEnabled(d.getOrgConfig.isTrainingEnabled);
    }
  });

  const { loading } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Refactor" } },
    onCompleted: data => setIsRefactorEnabled(data.isFeatureEnabled.enabled)
  });

  const { loading: ranksFFLoading } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Ranks" } },
    onCompleted: data => setIsRanksEnabled(data.isFeatureEnabled.enabled)
  });

  const { loading: contentfulFFLoading } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Contentful" } },
    onCompleted: data => setIsContentfulEnabled(data.isFeatureEnabled.enabled)
  });

  // NOTE - Login route guard.
  useEffect(() => {
    if (isSignedIn) {
      dispatch(fetchUser());
    } else {
      dispatch(hideLoader());
      history.push(routesConfig.login.path, {
        to: `${location}${history.location.search}`
      });
    }
  }, [isSignedIn, dispatch, history, location]);

  useEffect(() => {
    if (data && data.getOrgConfig) {
      setIsResourceEnabled(data.getOrgConfig.isResourcesEnabled);
      setIsTrainingEnabled(data.getOrgConfig.isTrainingEnabled);
    }
  }, [data]);

  // NOTE - Consent, Screener, and Disqualified route guards.
  useEffect(() => {
    if (!user || !userStatus) {
      return;
    }

    switch (userStatus) {
      case UserStatus.UNKNOWN: {
        if (
          ![routesConfig.screener.path, routesConfig.surveys.basePath].some(x =>
            location.includes(x)
          )
        ) {
          history.push(routesConfig.screener.path);
        }

        break;
      }

      case UserStatus.DISQUALIFIED: {
        if (location !== routesConfig.resources.path) {
          history.push(routesConfig.resources.path);
        }

        break;
      }

      case UserStatus.QUALIFIED_AND_UNCONSENTED:
        if (
          user?.forcedConsent &&
          !location.includes(routesConfig.consent.path)
        ) {
          history.push(routesConfig.consent.path);
        }

        break;
    }
  }, [user, userStatus, location, dispatch, history]);

  if (loading || contentfulFFLoading || ranksFFLoading) {
    return <></>;
  }

  return (
    <>
      <LoggedInAs
        name={`${user?.firstName} ${user?.lastName} (${user?.email})`}
      />
      <StyledGrid
        container
        direction="column"
        wrap="nowrap"
        fillRemainingHeight
      >
        {/* Lazy load routes */}
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path={routesConfig.badges.path} component={Badges} />
            <Route
              path={routesConfig.dashboard.path}
              component={isRefactorEnabled ? DashboardV2 : Dashboard}
            />
            {isRefactorEnabled && (
              <Route path={routesConfig.myjourney.path} component={MyJourney} />
            )}
            {isRefactorEnabled && (
              <Route path={routesConfig.index.path} component={Index} />
            )}
            <Route
              path={routesConfig.resources.article.path}
              component={Article}
            />
            <Route path={routesConfig.resources.path} component={Resources} />
            <Route
              path={routesConfig.training.path}
              component={isContentfulEnabled ? Courses : Training}
              exact
            />
            <Route
              path={routesConfig.training.challenges.path}
              component={Challenges}
            />
            <Route
              path={routesConfig.training.brainHq.path}
              component={BrainHq}
            />
            <Route
              path={routesConfig.training.brainHqAssessment.path}
              component={BrainHqAssessment}
            />
            <Route path={routesConfig.faq.path} component={Faq} />
            <Route path={routesConfig.googleFit.path} component={GoogleFit} />
            <Route path={routesConfig.profile.path} component={Profile} />
            <Route
              path={routesConfig.notificationPreferences.path}
              component={NotificationPreferences}
            />
            <Route
              path={routesConfig.screener.qualification.path}
              component={Qualification}
            />
            <Route path={routesConfig.screener.path} component={Screener} />
            <Route
              path={routesConfig.waitlist.path}
              component={FullOrganization}
            />
            <Route path={routesConfig.consent.path} component={Consent} />
            <Route
              path={routesConfig.onboardingVideo.path}
              component={OnboardingVideo}
            />
            <Route
              path={routesConfig.assessments.start.path}
              component={PreamblePage}
            />
            <Route
              path={routesConfig.assessments.intro.path}
              component={Intro}
            />
            <Route
              path={routesConfig.assessments.group.path}
              component={GroupPage}
            />
            <Route
              path={routesConfig.assessments.assessment.path}
              component={QuestionsPage}
            />
            <Route
              path={routesConfig.assessments.partComplete.path}
              component={PartOutro}
            />
            <Route
              path={routesConfig.assessments.complete.path}
              component={PartOutroFinal}
            />
            <Route
              path={routesConfig.assessmentOverview.path}
              component={AssessmentOverview}
            />
            <Route
              path={`${routesConfig.surveys.survey.path}/:surveyId/:userSurveyId`}
              component={SurveyQuestionsPage}
            />
            <Route
              path={routesConfig.surveys.survey.path}
              component={SurveyQuestionsPage}
            />
            <Route
              path={routesConfig.surveys.complete.path}
              component={SurveyCompletePage}
            />
            <Route
              path={routesConfig.coachingSessions.path}
              component={CoachingCall}
            />
            <Route
              path={routesConfig.pdf.path}
              component={isRefactorEnabled ? PDFRefactor : PDF}
            />
            <Route
              path={routesConfig.oauth.callback.path}
              component={OAuthCallback}
            />
            <Route path={routesConfig.dietid.path} component={DietID} />
            <Route
              path={`${routesConfig.scheduling.path}/:appointmentType`}
              component={Scheduling}
            />
            <Route
              path={`${routesConfig.groupCoachingConfirmation.path}/:sessionId`}
              component={GroupCoachingConfirmation}
            />
            <Route
              path={`${routesConfig.coachingConfirmation.path}/:coachingSessionId`}
              component={CoachingConfirmation}
            />
            <Route
              path={`${routesConfig.imagingConfirmation.path}/:imagingSessionId`}
              component={ImagingConfirmation}
            />
            <Route path={routesConfig.brainGauge.path} component={BrainGauge} />
            <Route
              path={`${routesConfig.courses.unit.path}/:unitTitle`}
              component={Unit}
            />
            <Route
              path={`${routesConfig.courses.unit.path}/:unitTitle`}
              component={Unit}
            />
            <Route
              path={`${routesConfig.courses.section.path}/:sectionTitle`}
              component={Section}
            />
            <Route
              path={`${routesConfig.courses.course.path}/:courseTitle`}
              component={Course}
            />
            {isRefactorEnabled && (
              <Route
                path={routesConfig.habitSetup.selectedHabit.path}
                component={SelectedHabit}
              />
            )}
            {isRefactorEnabled && (
              <Route
                path={routesConfig.habitSetup.trackYourHabits.path}
                component={TrackYourHabits}
              />
            )}
            {isRefactorEnabled && (
              <Route
                path={routesConfig.habitSetup.path}
                component={HabitSetup}
              />
            )}
            {isRefactorEnabled && (
              <Route path={routesConfig.stats.path} component={Stats} />
            )}
            {isRanksEnabled && (
              <Route path={routesConfig.ranks.path} component={Ranks} />
            )}
            {isRefactorEnabled && (
              <Route
                path={routesConfig.habitSurvey.complete.path}
                component={HabitSurveyComplete}
              />
            )}
            {isRefactorEnabled && (
              <Route
                path={routesConfig.habitSurvey.path}
                component={HabitSurvey}
              />
            )}

            <Route
              path={routesConfig.resourceSurvey.complete.path}
              component={ResourceSurveyComplete}
            />

            <Route
              path={routesConfig.resourceSurvey.path}
              component={ResourceSurvey}
            />

            <Redirect exact from="/" to={routesConfig.dashboard.path} />
            <Route
              component={() => (
                <PageNotFound
                  setIs404={setIs404}
                  isRefactorEnabled={isRefactorEnabled}
                  isResourceEnabled={isResourceEnabled}
                  isTrainingEnabled={isTrainingEnabled}
                />
              )}
            />
          </Switch>
        </Suspense>
      </StyledGrid>
    </>
  );
};

export default ProtectedRoutes;
