import React, { Dispatch, SetStateAction, useRef } from "react";
import { Box } from "@material-ui/core";

import { fontSize, spacingDefaults } from "style/constants";
import VideoThumbnail from "assets/consent-video-thumbnail.png";
import { StyledGrid } from "components/simple";
import {
  ContentModule,
  ContentSlot,
  LayoutType,
  Media,
  MediaType,
  TabsData
} from "graphql/types/Contentful";
import { SlideData } from "graphql/types/Contentful";
import RichText from "../RichText";
import { Slides } from "components/simple/Slides";
import TabComponent from "components/simple/Tabs";

interface LessonProps {
  content: ContentModule;
  setHasVideoEnded: Dispatch<SetStateAction<boolean>>;
  setHasVideo: Dispatch<SetStateAction<boolean>>;
  setIsLastSlideInLesson: Dispatch<SetStateAction<boolean>>;
}

const Lesson: React.FC<LessonProps> = ({
  content,
  setHasVideoEnded,
  setHasVideo,
  setIsLastSlideInLesson
}: LessonProps) => {
  const videoEl = useRef<HTMLVideoElement>(null);
  let supposedCurrentTime = 0;
  const isMultiColumn = content.layoutType !== LayoutType.oneColumn;
  const isSlidesContent = content.layoutType === LayoutType.slides;
  const isTabsContent = content.layoutType === LayoutType.tabs;
  const getColumnSpan = (layoutType: LayoutType) => {
    switch (layoutType) {
      case LayoutType.oneColumn:
      case LayoutType.slides:
      case LayoutType.tabs:
        return 12;
      case LayoutType.twoColumn:
        return 6;
      case LayoutType.threeColumn:
        return 4;
      case LayoutType.fourColumn:
        return 3;
      default:
        return 12;
    }
  };

  const tabsArray: TabsData[] = [];
  const slidesArray: SlideData[] = [];

  if (isTabsContent) {
    content.contentSlots.forEach(element => {
      const data: TabsData = {
        data: element.richText,
        media: element.media,
        title: element.title,
        textAlignment: element.textAlignment,
        tabText: element.tabText
      };
      tabsArray.push(data);
    });
  }

  if (isSlidesContent) {
    content.contentSlots.forEach(element => {
      const data: SlideData = {
        data: element.richText,
        media: element.media,
        title: element.title,
        tabText: element.tabText
      };
      slidesArray.push(data);
    });
  }

  const renderSlides = (content: any) => {
    return (
      <StyledGrid
        padding={content.fullBleed ? "0" : `0 ${spacingDefaults.small}`}
      >
        <StyledGrid
          container
          margin={`${spacingDefaults.normal} 0`}
          lineHeight={spacingDefaults.large}
          textAlign={isMultiColumn ? "left" : "center"}
        >
          <Slides
            title={content.title}
            tabText={content.tabText}
            key={content.id}
            items={slidesArray}
            setIsLastSlideInLesson={setIsLastSlideInLesson}
            setHasVideoEnded={setHasVideoEnded}
            setHasVideo={setHasVideo}
          ></Slides>
        </StyledGrid>
      </StyledGrid>
    );
  };

  const renderTabs = (data: any) => {
    return (
      <TabComponent
        data={data}
        setHasVideoEnded={setHasVideoEnded}
        setHasVideo={setHasVideo}
      ></TabComponent>
    );
  };

  return isSlidesContent ? (
    renderSlides(content)
  ) : isTabsContent ? (
    renderTabs(tabsArray)
  ) : (
    <StyledGrid
      padding={content.fullBleed ? "0" : `0 ${spacingDefaults.normal}`}
    >
      <StyledGrid margin={`${spacingDefaults.normal} 0`}>
        <Box fontSize={fontSize.large}>{content.title}</Box>
      </StyledGrid>
      <StyledGrid
        container
        maxWidth={content.fullBleed && !isMultiColumn ? undefined : "1100px"}
        margin={`${spacingDefaults.normal} 0`}
        lineHeight={spacingDefaults.large}
        textAlign={isMultiColumn ? "left" : "center"}
      >
        {content.contentSlots.map((slot: ContentSlot, idx: number) => (
          <StyledGrid
            item
            container
            key={idx}
            xs={12}
            lg={getColumnSpan(content.layoutType)}
            alignContent={isMultiColumn ? "space-between" : undefined}
            padding={isMultiColumn ? `0 ${spacingDefaults.medium}` : undefined}
            margin={`${spacingDefaults.normal} 0`}
          >
            <StyledGrid textAlign={slot.textAlignment ?? undefined}>
              <RichText
                data={slot.richText}
                setHasVideoEnded={setHasVideoEnded}
                setHasVideo={setHasVideo}
                layoutType={content.layoutType}
                textAlign={slot.textAlignment}
              />
            </StyledGrid>
            {slot?.media?.map((media: Media, idx: number) => (
              <StyledGrid
                key={idx}
                margin={`${spacingDefaults.medium} 0`}
                container={isMultiColumn ? true : false}
                centerContent={isMultiColumn ? true : false}
              >
                {media.mediaType === MediaType.videoMp4 ? (
                  <>
                    {setHasVideo(true)}
                    <video
                      key={media?.fileUrl}
                      ref={videoEl}
                      width="100%"
                      controls
                      style={{ maxWidth: "850px" }}
                      poster={VideoThumbnail}
                      onEnded={() => setHasVideoEnded(true)}
                      onTimeUpdate={() => {
                        if (!videoEl?.current?.seeking) {
                          supposedCurrentTime =
                            videoEl?.current?.currentTime || 0;
                        }
                      }}
                      onSeeking={(e: any) => {
                        if (videoEl?.current?.currentTime) {
                          const delta =
                            videoEl?.current?.currentTime - supposedCurrentTime;
                          if (delta > 0.01) {
                            videoEl.current.currentTime = supposedCurrentTime;
                          }
                        }
                      }}
                    >
                      <source src={media?.fileUrl} type="video/mp4" />
                    </video>
                  </>
                ) : (
                  <img
                    src={media?.fileUrl}
                    style={{
                      maxWidth: content.fullBleed ? undefined : "850px",
                      width:
                        content.fullBleed || isMultiColumn ? "100%" : "100%"
                    }}
                  />
                )}
                <StyledGrid margin={`${spacingDefaults.normal} 0`}>
                  <Box fontSize={fontSize.normal}>{media?.description}</Box>
                </StyledGrid>
              </StyledGrid>
            ))}
          </StyledGrid>
        ))}
      </StyledGrid>
    </StyledGrid>
  );
};

export default Lesson;
