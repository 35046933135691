import React from "react";

import { ReactComponent as CognitiveIcon } from "assets/wheel_cognitive.svg";
import { ReactComponent as SocialIcon } from "assets/wheel_social.svg";
import { ReactComponent as WellBeingIcon } from "assets/wheel_well-being.svg";
import { ReactComponent as RealLifeIcon } from "assets/wheel_real-life.svg";

interface WheelIconProps {
  pillar: string;
}

const WheelIcon: React.FC<WheelIconProps> = ({ pillar }: WheelIconProps) => {
  switch (pillar.toLowerCase()) {
    case "cognition":
    case "cognitive": {
      return <CognitiveIcon />;
    }
    case "interaction":
    case "social": {
      return <SocialIcon />;
    }
    case "well-being": {
      return <WellBeingIcon />;
    }
    case "daily life":
    case "real-life": {
      return <RealLifeIcon />;
    }

    default: {
      return null;
    }
  }
};

export default WheelIcon;
