import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTracking } from "react-tracking";
import { ReactComponent as FormIcon } from "assets/form-icon.svg";
import { ReactComponent as CoupleIcon } from "assets/couple-outline.svg";
import { ReactComponent as LogoutIcon } from "assets/logout.svg";
import { ReactComponent as OptOutIcon } from "assets/not-allowed.svg";
import { ReactComponent as PreferencesIcon } from "assets/preferences.svg";
import { StyledGrid, ConfirmationModal } from "components/simple";
import { GetOrganizationConsentFormInfo, OptOut } from "graphql/user/user.gql";
import { fetchUser, getUserStatus, UserStatus } from "state";
import { breakpoints, color } from "style/constants";
import routesConfig from "utils/routesConfig";
import ActionButton from "./ActionButton";
import SpouseModals from "components/simple/SpouseModals";
import { Me, GetPartner } from "graphql/user/user.gql";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { deleteToken } from "utils/auth";
import { UserData } from "models/user";

const url: string = routesConfig.profile.path;

interface ProfileActionsProps {
  isPartnerLinked: boolean;
  refetchMe: Function;
  partnerId?: string;
}

const ProfileActions: React.FC<ProfileActionsProps> = ({
  isPartnerLinked,
  refetchMe,
  partnerId
}: ProfileActionsProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const history = useHistory();
  const tracking = useTracking();

  const dispatch = useDispatch();
  const userStatus = useSelector(getUserStatus);
  const { data, loading, refetch } = useQuery<UserData>(Me, {
    fetchPolicy: "no-cache"
  });

  // Partner Linking Feature Flag
  const { data: partnerLinkingEnabledData } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "PartnerLinking" } }
  });
  const isPartnerLinkingEnabled =
    partnerLinkingEnabledData?.isFeatureEnabled?.enabled;

  const { data: partnerData } = useQuery(GetPartner, {
    variables: { id: partnerId },
    fetchPolicy: "no-cache",
    skip: !isPartnerLinkingEnabled || !partnerId
  });

  const { data: consentFormInfo } = useQuery(GetOrganizationConsentFormInfo, {
    fetchPolicy: "no-cache"
  });

  const [
    showOptOutConfirmationModal,
    setShowOptOutConfirmationModal
  ] = useState(false);

  const [isInviteSpouseModalOpen, setIsInviteSpouseModalOpen] = useState<
    boolean
  >(false);
  const [isUnlinkPartnerModalOpen, setIsUnlinkPartnerModalOpen] = useState<
    boolean
  >(false);

  const [optOut] = useMutation(OptOut);

  const partnerName = `${partnerData?.user.firstName} ${partnerData?.user.lastName}`;
  const displayPartnerName = partnerName || "is linked";

  const shouldHideConsentForm =
    consentFormInfo?.me?.organization?.hideConsentForm;

  return (
    <StyledGrid container>
      <SpouseModals
        isInviteSpouseModalOpen={isInviteSpouseModalOpen}
        setIsInviteSpouseModalOpen={setIsInviteSpouseModalOpen}
        userId={data?.me.id}
        isNewUser={false}
        profilePage={true}
      />
      <SpouseModals
        isUnlinkPartnerModalOpen={isUnlinkPartnerModalOpen}
        setIsUnlinkPartnerModalOpen={setIsUnlinkPartnerModalOpen}
        userId={data?.me.id}
        isNewUser={false}
        profilePage={true}
        partnerName={partnerName}
        refetchMe={refetchMe}
      />
      {userStatus !== UserStatus.WAITLISTED &&
        isPartnerLinkingEnabled &&
        !isPartnerLinked && (
          <StyledGrid
            borderRight={mobile ? undefined : `1px solid ${color.MUTEDGRAY}`}
            borderTop={`1px solid ${color.MUTEDGRAY}`}
            container
            height="80px"
            item
            lg
            xs={12}
          >
            <ActionButton
              icon={<CoupleIcon height={32} width={32} />}
              text="Link Partner"
              handleClick={() => setIsInviteSpouseModalOpen(true)}
            />
          </StyledGrid>
        )}

      {userStatus !== UserStatus.WAITLISTED && !shouldHideConsentForm && (
        <StyledGrid
          borderRight={mobile ? undefined : `1px solid ${color.MUTEDGRAY}`}
          borderTop={`1px solid ${color.MUTEDGRAY}`}
          container
          height="80px"
          item
          lg
          xs={12}
        >
          <ActionButton
            icon={<FormIcon height={32} width={32} />}
            text="Consent Form"
            handleClick={() => history.push("/consent/form?page=1")}
          />
        </StyledGrid>
      )}

      {userStatus !== UserStatus.WAITLISTED &&
        isPartnerLinkingEnabled &&
        isPartnerLinked && (
          <StyledGrid
            borderRight={mobile ? undefined : `1px solid ${color.MUTEDGRAY}`}
            borderTop={`1px solid ${color.MUTEDGRAY}`}
            container
            height="80px"
            item
            lg
            xs={12}
          >
            <ActionButton
              icon={<CoupleIcon height={32} width={32} />}
              text={`Partner: ${displayPartnerName}`}
              handleClick={() => setIsUnlinkPartnerModalOpen(true)}
            />
          </StyledGrid>
        )}

      {userStatus === UserStatus.QUALIFIED_AND_CONSENTED && (
        <StyledGrid
          borderRight={mobile ? undefined : `1px solid ${color.MUTEDGRAY}`}
          borderTop={`1px solid ${color.MUTEDGRAY}`}
          container
          height="80px"
          item
          lg
          xs={12}
        >
          <ActionButton
            icon={<OptOutIcon height={32} width={32} />}
            text="Opt Out of Project"
            handleClick={() => setShowOptOutConfirmationModal(true)}
          />
        </StyledGrid>
      )}

      <StyledGrid
        borderTop={`1px solid ${color.MUTEDGRAY}`}
        container
        height="80px"
        item
        lg
        xs={12}
      >
        <ActionButton
          icon={<PreferencesIcon height={32} width={32} />}
          text="Notification Preferences"
          handleClick={() =>
            history.push(routesConfig.notificationPreferences.path)
          }
        />
      </StyledGrid>

      <StyledGrid
        borderLeft={mobile ? undefined : `1px solid ${color.MUTEDGRAY}`}
        borderTop={`1px solid ${color.MUTEDGRAY}`}
        container
        height="80px"
        item
        lg
        xs={12}
      >
        <ActionButton
          icon={<LogoutIcon height={32} width={32} />}
          text="Log Out"
          handleClick={() => {
            tracking.trackEvent({
              url,
              actions: ["Session", "Logout"]
            });
            deleteToken();
            history.push(routesConfig.login.path);
          }}
        />
      </StyledGrid>

      {showOptOutConfirmationModal && (
        <ConfirmationModal
          title="Are you sure?"
          content="Opting out of the Project will restrict your access to the BrainHealth dashboard. You can opt back in at any time."
          confirmText="Yes, I want to opt out."
          cancelText="I'm staying in."
          onConfirm={async () => {
            setShowOptOutConfirmationModal(false);

            await optOut();

            dispatch(fetchUser());
            history.push(routesConfig.surveys.survey.path);
          }}
          onCancel={() => setShowOptOutConfirmationModal(false)}
        />
      )}
    </StyledGrid>
  );
};

export default ProfileActions;
