import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import { ReactComponent as TextIcon } from "assets/text.svg";
import { StyledGrid, Switch } from "components/simple";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";

interface TextPreferenceProps {
  checked: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  missingPhone: boolean;
  disallowCheck?: boolean;
  uncheckedMessage?: string;
}

const TextPreference: React.FC<TextPreferenceProps> = ({
  checked,
  handleChange,
  missingPhone,
  disallowCheck = false,
  uncheckedMessage
}: TextPreferenceProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      container
      height="60px"
      padding={`0 ${spacingDefaults.large}`}
    >
      <StyledGrid alignItems="center" container item xs>
        <TextIcon />

        <Box
          color={color.DARKERGRAY}
          component="p"
          fontSize={fontSize.small}
          lineHeight="150%"
          margin={`0 0 0 ${spacingDefaults.xsmall}`}
        >
          Text
        </Box>
      </StyledGrid>

      {getContent()}
    </StyledGrid>
  );

  function getContent(): JSX.Element {
    if (missingPhone) {
      return (
        <Box
          color={color.DARKBLUE}
          fontSize="12px"
          lineHeight="142%"
          margin="0"
          maxWidth={mobile ? "165px" : "200px"}
          textAlign="right"
        >
          <em>No phone number provided</em>
        </Box>
      );
    }

    if (disallowCheck) {
      return (
        <>
          <Box
            color={color.DARKBLUE}
            fontSize="12px"
            lineHeight="142%"
            margin="0"
            maxWidth={mobile ? "165px" : "315px"}
            textAlign="right"
          >
            <em>{uncheckedMessage}</em>
          </Box>

          <Box
            component="p"
            lineHeight="175%"
            margin={`0 0 0 ${spacingDefaults.small}`}
            textAlign="center"
            width="45px"
          >
            Off
          </Box>
        </>
      );
    }

    return <Switch checked={checked} onChange={handleChange} />;
  }
};

export default TextPreference;
