import React, { useState, useEffect } from "react";
import { Chip } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

import { ReactComponent as CloseButton } from "assets/close.svg";
import { StyledGrid } from "components/simple";
import { StyledForm } from "./styled";

interface MultiInputProps {
  entries: string[];
  callback: React.Dispatch<React.SetStateAction<string[]>>;
  deleteableChips?: boolean;
  hideInput?: boolean;
  hideWords?: boolean;
  oneWord?: boolean;
  timesUp?: boolean;
}

const MultiInput: React.FC<MultiInputProps> = ({
  entries,
  callback,
  deleteableChips,
  hideInput,
  hideWords,
  oneWord,
  timesUp
}: MultiInputProps) => {
  const [entry, setEntry] = useState<string>("");

  // reset state when rendering same component continuously
  useEffect(() => {
    if (entries.length === 0) setEntry("");
  }, [entries]);

  const createChip = (entry: string): void => {
    const result = entries.findIndex(
      word => entry.toLowerCase() === word.toLowerCase()
    );

    if (entry !== "" && result === -1) {
      const newEntries = [...entries, entry];
      callback(newEntries);
    }
    setEntry("");
  };

  useEffect(() => {
    if (timesUp && entry !== "") createChip(entry);
  }, [timesUp]); // eslint-disable-line

  const deleteChip = (entry: string): void => {
    const newEntries = entries.filter(value => value !== entry);
    callback(newEntries);
  };

  let handleReturnKeyPress;
  if (oneWord) {
    handleReturnKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === " " || event.key === ",") {
        event.preventDefault();
        createChip(entry);
      }
    };
  }

  return (
    <>
      <StyledGrid
        item
        maxWidth="435px"
        marginBottom="1.5rem"
        marginTop="2rem"
        width="100%"
      >
        {!hideInput && (
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
              createChip(entry);
            }}
          >
            {oneWord ? (
              <InputBase
                value={entry}
                placeholder="Type here"
                inputProps={{ "aria-label": "Type here" }}
                fullWidth
                onKeyPress={handleReturnKeyPress}
                onChange={event => {
                  setEntry(event.target.value);
                }}
              />
            ) : (
              <InputBase
                value={entry}
                placeholder="Type here"
                inputProps={{ "aria-label": "Type here" }}
                fullWidth
                onChange={event => {
                  setEntry(event.target.value);
                }}
              />
            )}
            <IconButton
              type="submit"
              color="primary"
              aria-label="add words"
              disabled={entry === ""}
            >
              <AddIcon />
            </IconButton>
          </StyledForm>
        )}
      </StyledGrid>
      <StyledGrid container justifyContent="center">
        {entries &&
          !hideWords &&
          entries.map((entry, index) => (
            <StyledGrid
              item
              key={index}
              width="max-content"
              marginRight="0.625rem"
              marginBottom="1rem"
              maxWidth="435px"
            >
              {deleteableChips && (
                <Chip
                  deleteIcon={<CloseButton />}
                  label={entry}
                  onDelete={() => deleteChip(entry)}
                  variant="outlined"
                />
              )}
              {!deleteableChips && <Chip label={entry} />}
            </StyledGrid>
          ))}
      </StyledGrid>
    </>
  );
};

export default MultiInput;
