import React, { useEffect, useState } from "react";
import { Instructions } from "./Instructions";
import { Practice } from "./Practice";
import { Ready } from "./Ready";
import { AnswerOptions, DigitSymbolQuestionData, Platform } from "..";
import { AnswerStatusModal } from "./AnswerStatusModal";
import { PracticeStart } from "./PracticeStart";

export interface DigitSymbolIntroData {
  callback: () => void;
  platform: Platform;
  question: DigitSymbolQuestionData[];
}

export interface DigitSymbolInstructionData {
  callback: React.Dispatch<any>;
  platform: Platform;
}

const Intro: React.FC<DigitSymbolIntroData> = ({
  callback: startAssessmentCallback,
  platform,
  question: digitSymbols
}) => {
  const [instructionsVisible, setInstructionsVisible] = useState<boolean>(true);

  const [userAnswer, setUserAnswer] = useState<AnswerOptions>(
    AnswerOptions.NO_ANSWER
  );
  const [currentPracticeIndex, setCurrentPracticeIndex] = useState<number>(0);
  const [practiceStartVisible, setPracticeStartVisible] = useState<boolean>(
    false
  );
  const [practiceVisible, setPracticeVisible] = useState<boolean>(false);
  const [readyVisible, setReadyVisible] = useState<boolean>(false);
  const [showAnswerStatusModal, setShowAnswerStatusModal] = useState<boolean>(
    false
  );
  const [showBlank, setShowBlank] = useState(false);

  useEffect(() => {
    if (
      !showAnswerStatusModal &&
      ![AnswerOptions.INVALID, AnswerOptions.INCORRECT].includes(userAnswer)
    ) {
      setShowBlank(true);
      setTimeout(() => {
        setShowBlank(false);
      }, 500);
    }
  }, [showAnswerStatusModal]);

  function handleUserPracticeAnswer(
    e: { target?: any; keyCode: any },
    question: DigitSymbolQuestionData
  ) {
    let answer = AnswerOptions.NO_ANSWER;

    // z: 90 (YES); /: 191 (NO) --> Updated to f: 70 (YES); j:74
    if (![74, 70].includes(e.keyCode)) {
      answer = AnswerOptions.INVALID;
    } else {
      if (e.keyCode === 70) {
        answer = question.isCorrect
          ? AnswerOptions.CORRECT
          : AnswerOptions.INCORRECT;
      } else if (e.keyCode === 74) {
        answer = !question.isCorrect
          ? AnswerOptions.CORRECT
          : AnswerOptions.INCORRECT;
      }
    }

    setUserAnswer(answer);
    setShowAnswerStatusModal(true);
  }

  function handleCloseAnswerStatusModal() {
    setShowAnswerStatusModal(false);

    if (
      ![AnswerOptions.INVALID, AnswerOptions.INCORRECT].includes(userAnswer)
    ) {
      setUserAnswer(AnswerOptions.NO_ANSWER);
      if (currentPracticeIndex < digitSymbols.length - 1) {
        setCurrentPracticeIndex(currentPracticeIndex + 1);
      } else {
        setPracticeVisible(false);
        setReadyVisible(true);
      }
    }
  }

  function handleNextStep(isReady: boolean) {
    setReadyVisible(false);

    if (isReady) {
      startAssessmentCallback();
    } else {
      setPracticeVisible(true);
      setCurrentPracticeIndex(0);
    }
  }

  if (showBlank) return <></>;

  return (
    <>
      {instructionsVisible && (
        <Instructions
          callback={() => {
            setInstructionsVisible(false);
            setPracticeStartVisible(true);
          }}
          platform={platform}
        />
      )}
      {practiceStartVisible && (
        <PracticeStart
          callback={() => {
            setPracticeStartVisible(false);
            setPracticeVisible(true);
          }}
          platform={platform}
        />
      )}
      {practiceVisible && currentPracticeIndex < digitSymbols.length && (
        <Practice
          callback={handleUserPracticeAnswer}
          platform={platform}
          question={digitSymbols[currentPracticeIndex]}
          showAnswerStatusModal={showAnswerStatusModal}
        />
      )}
      {readyVisible && <Ready callback={handleNextStep} />}

      {showAnswerStatusModal && digitSymbols[currentPracticeIndex] && (
        <>
          <AnswerStatusModal
            platform={platform}
            question={digitSymbols[currentPracticeIndex]}
            userAnswer={userAnswer}
            showModal={true}
            handleCloseModal={handleCloseAnswerStatusModal}
          />
        </>
      )}
    </>
  );
};

export default Intro;
