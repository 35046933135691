import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { createTheme } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ThemeProvider } from "@material-ui/styles";
import styled from "styled-components";

import { color } from "style/constants";
import globalTheme from "style/theme";

interface StyledTextField {
  error?: boolean;
  label?: string;
  required?: boolean;
  type?: string;
  showPassword?: boolean;
  setShowPassword?: React.Dispatch<React.SetStateAction<boolean>>;
  success?: boolean;
}

type StyledTextFieldProps = StyledTextField & TextFieldProps;

interface PasswordAdornmentProps {
  showPassword?: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ValidationAdormnentProps {
  valid?: boolean;
}

interface EndAdornmentProps {
  error?: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  showPassword: boolean;
  success?: boolean;
  type?: string;
}

const successTheme = createTheme({
  ...globalTheme,
  overrides: {
    MuiFormHelperText: {
      root: {
        color: color.DARKGREEN,
        paddingLeft: "13px"
      }
    },
    MuiInput: {
      root: {
        paddingBottom: "10px",
        paddingLeft: "13px"
      },
      underline: {
        "&:after": {
          borderBottomColor: color.GREEN,
          borderBottomWidth: "1px !important",
          transform: "scaleX(1)"
        },
        "&:before": {
          borderBottomWidth: "1px !important"
        }
      }
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: color.LIGHTGRAY
        },
        color: color.DARKGRAY,
        marginLeft: "12px"
      }
    }
  }
});

const ValidationAdornment: React.FC<ValidationAdormnentProps> = (
  props: ValidationAdormnentProps
) => {
  return (
    <InputAdornment position="end" style={{ marginLeft: "-5px" }}>
      {props.valid ? (
        <Check style={{ color: color.GREEN }} />
      ) : (
        <Close style={{ color: color.LIGHTRED }} />
      )}
    </InputAdornment>
  );
};

const PasswordAdornment: React.FC<PasswordAdornmentProps> = (
  props: PasswordAdornmentProps
) => {
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault();
  };

  const handleClickShowPassword = (): void => {
    props.setShowPassword(!props.showPassword);
  };

  return (
    <InputAdornment
      position="end"
      style={{ marginLeft: "-4px", marginRight: "-12px" }}
    >
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        {props.showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

const EndAdornment: React.FC<EndAdornmentProps> = (
  props: EndAdornmentProps
) => {
  return (
    <span style={{ display: "flex" }}>
      {props.error && <ValidationAdornment />}
      {props.success && <ValidationAdornment valid />}
      {props.type === "password" && (
        <PasswordAdornment
          setShowPassword={props.setShowPassword}
          showPassword={props.showPassword}
        />
      )}
    </span>
  );
};

const StyledTextField = styled(
  ({ setShowPassword, showPassword, success, type, ...otherProps }) => (
    <TextField
      {...otherProps}
      InputProps={{
        endAdornment: (
          <EndAdornment
            error={otherProps.error}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
            success={success}
            type={type}
          />
        ),
        type: type === "password" ? (showPassword ? "text" : "password") : type
      }}
    />
  )
)`
  && {
    display: default;
  }
` as React.FC<StyledTextFieldProps>;

const TextFieldWrapper: React.FC<StyledTextFieldProps> = (
  props: StyledTextFieldProps
) => {
  const [showPassword, setShowPassword] = useState(false);

  const theme = props.success ? successTheme : globalTheme;

  return (
    <ThemeProvider theme={theme}>
      <StyledTextField
        setShowPassword={setShowPassword}
        showPassword={showPassword}
        {...props}
      />
    </ThemeProvider>
  );
};

export default TextFieldWrapper;
