import { gql } from "apollo-boost";

export const GetFaqs = gql`
  query GetFaqs {
    getFaqs {
      title
      questions {
        title
        answer
      }
    }
  }
`;
