import { useEffect, useState, MutableRefObject } from "react";

function useOnScreen(
  ref: MutableRefObject<HTMLDivElement | null>,
  rootMargin = "0px"
): boolean {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const current = ref.current;
    if (current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        { rootMargin }
      );

      observer.observe(current);

      return () => {
        observer.unobserve(current);
      };
    }
  }, []); // eslint-disable-line

  return isIntersecting;
}

export default useOnScreen;
