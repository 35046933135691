import { useQuery } from "@apollo/react-hooks";

import { GetPushNotifications } from "graphql/pushNotifications/pushNotifications.gql";

interface PushNotifications {
  pushNotifications: PushNotification[];
}

export interface PushNotification {
  acknowledged: boolean;
  body: string;
  createdAt: string;
  dismissed: boolean;
  id: string;
  redirectUrl: string;
  title: string;
}

export const POLL_INTERVAL_SECONDS = 5;

const useNotifications = () => {
  const { data, startPolling, stopPolling } = useQuery<PushNotifications>(
    GetPushNotifications,
    {
      pollInterval: POLL_INTERVAL_SECONDS * 1000,
      variables: {
        notifications: true
      }
    }
  );

  return {
    pushNotifications: data?.pushNotifications || [],
    startPolling,
    stopPolling
  };
};

export default useNotifications;
