import React from "react";
import { AssessmentCard, Button, StyledGrid } from "components/simple";
import { breakpoints } from "style/constants";
import { useMediaQuery } from "@material-ui/core";
import { DigitSymbolInstructionData } from ".";

export const PracticeStart: React.FC<DigitSymbolInstructionData> = ({
  callback
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <AssessmentCard
      question={`Let's do 5 practice trials.\nDecide whether the digit-symbol pair is correct.\nGo as quickly and accurately as you can.`}
    >
      <StyledGrid item marginTop="1.6875rem">
        <Button
          data-testid="next-btn"
          wide={!mobile}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={callback}
          zIndex={100}
        >
          Let&apos;s Practice!
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};
