import React from "react";

import { StyledGrid } from "components/simple";
import { ReactComponent as Balloons } from "assets/confirmRegistration.svg";
import { RegisterCallout, Title } from "./styled";
import track from "react-tracking";
import routesConfig from "utils/routesConfig";

const url: string = routesConfig.verify.path;

const Confirmation: React.FC = () => {
  return (
    <>
      <StyledGrid
        centerContent
        container
        direction="column"
        item
        margin="auto"
        maxWidth="370px"
        mobileMaxWidth="80%"
      >
        <Balloons
          height="275px"
          width="100%"
          aria-label="3 balloons with scattered envelopes"
        />
        <Title>Check your inbox</Title>
        <RegisterCallout>
          You’ll receive an email with a verification link. Please navigate to
          your inbox and click on the magic link.
        </RegisterCallout>
        <RegisterCallout>
          You may need to check your junk or spam folder.
        </RegisterCallout>
      </StyledGrid>
    </>
  );
};

export default track({
  url
})(Confirmation);
