import React from "react";
import { Box } from "@material-ui/core";

import { StyledGrid } from "components/simple";
import { spacingDefaults } from "style/constants";
import OrnamentIcon from "components/simple/Ornament";
import { UserStreakDetailItem } from "models/userStreak";

interface StatSectionProps {
  userStreakDetails: UserStreakDetailItem;
}

const StatSection: React.FC<StatSectionProps> = ({ userStreakDetails }) => {
  return (
    <StyledGrid
      container
      direction="column"
      padding={`0 ${spacingDefaults.small}`}
    >
      <StyledGrid item>
        <StyledGrid container justifyContent="space-between">
          <Box fontWeight="500">{userStreakDetails.title}</Box>
          <StyledGrid item>
            <StyledGrid container style={{ gap: "0.25rem" }}>
              <Box>{userStreakDetails.count}</Box>
              <OrnamentIcon ornament={userStreakDetails.ornament} />
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
      <StyledGrid item>
        <StyledGrid container justifyContent="space-between">
          <Box fontStyle="italic" fontWeight="300">
            Coins Earned
          </Box>
          <Box fontStyle="italic" fontWeight="300">
            {userStreakDetails.coinsEarned}
          </Box>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default StatSection;
