import React from "react";
import { StyledGrid } from "components/simple";
import { Pillar } from "../../../types";
import { ReactComponent as Background } from "assets/bhi_background.svg";

const Cognition: React.FC = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.0181614 108C0.534926 79.55 12.0611 52.3754 32.2183 32.2183C52.8473 11.5893 80.8262 0 110 0C139.174 0 167.153 11.5893 187.782 32.2183C207.939 52.3754 219.465 79.55 219.982 108H137.176C137.177 107.908 137.178 107.816 137.178 107.723C137.178 92.6246 124.938 80.3846 109.839 80.3846C94.74 80.3846 82.5 92.6246 82.5 107.723C82.5 107.816 82.5005 107.908 82.5014 108H0.0181614ZM0 110L82.5934 110C82.5934 110 82.5934 110 82.5934 110L0 110Z"
    fill="#7B1FA2"
  />
);

const Interaction: React.FC = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M96.5051 131.596C88.7733 127.268 83.3595 119.293 82.5935 110H1.21463e-09C-9.15515e-05 129.484 5.17551 148.629 15.0108 165.471C24.8192 182.267 38.8999 196.153 55.8071 205.724L96.5051 131.596Z"
    fill="#bb8d00"
  />
);

const DailyLife: React.FC = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M166.468 204.4C182.666 194.711 196.102 181.015 205.479 164.624C214.994 147.992 220 129.162 220 110H137.084C136.348 118.934 131.315 126.65 124.057 131.079L166.468 204.4Z"
    fill="#E91E63"
  />
);

const WellBeing: React.FC = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M122.313 132.057C118.573 133.978 114.332 135.062 109.839 135.062C105.71 135.062 101.795 134.147 98.2857 132.508L57.5564 206.694C73.9117 215.564 92.2618 220.147 110.891 219.996C129.804 219.843 148.351 214.816 164.743 205.411L122.313 132.057ZM55.6342 205.626L62.2448 193.998L55.8071 205.724L55.6342 205.626Z"
    fill="#009688"
  />
);

interface WrapperProps {
  children: React.ReactNode;
  score: number;
  position?: string;
}
const Wrapper: React.FC<WrapperProps> = (props: WrapperProps) => {
  const score = props.score;
  const position = props.position ?? "center";

  const min = 30;
  const max = 110;
  const pixels = (score * (max - min)) / 100 + min;

  return (
    <StyledGrid position="absolute" top="0" left="0">
      <svg
        clipPath={`circle(${pixels}px at ${position})`}
        width="220"
        height="220"
        viewBox="0 0 220 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {props.children}
      </svg>
    </StyledGrid>
  );
};

interface Graphic {
  pillars: Pillar[];
}

const PillarDonutGraph: React.FC<Graphic> = ({ pillars }: Graphic) => {
  let isSafari = false;
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") !== -1) {
    if (ua.indexOf("chrome") > -1) {
      isSafari = false;
    } else {
      isSafari = true;
    }
  }

  return (
    <StyledGrid
      item
      marginBottom="20px"
      position="relative"
      width="220px"
      height="220px"
    >
      {pillars.map(pillar => {
        const score = pillar.value;
        const name = pillar.pillarName;

        if (isSafari) {
          //Since we have fixed size, the position of center on Safari is in the smaller warpper. we hard coded it to the 220x220 square's center
          return (
            <>
              <Wrapper score={score} key={name} position={"50% 100%"}>
                {name === "Cognition" && <Cognition />}
              </Wrapper>
              <Wrapper score={score} key={name} position={"113% 0%"}>
                {name === "Interaction" && <Interaction />}
              </Wrapper>
              <Wrapper score={score} key={name} position={"50% -24%"}>
                {name === "Well-Being" && <WellBeing />}
              </Wrapper>
              <Wrapper score={score} key={name} position={"-13% 0%"}>
                {name === "Daily Life" && <DailyLife />}
              </Wrapper>
            </>
          );
        } else {
          return (
            <Wrapper score={score} key={name}>
              {name === "Cognition" && <Cognition />}
              {name === "Interaction" && <Interaction />}
              {name === "Well-Being" && <WellBeing />}
              {name === "Daily Life" && <DailyLife />}
            </Wrapper>
          );
        }
      })}
      <StyledGrid position="absolute" top="0" left="0">
        <Background />
      </StyledGrid>
    </StyledGrid>
  );
};

export default PillarDonutGraph;
