// Dependencies
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import track from "react-tracking";
import client from "graphql/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { ApolloProvider } from "@apollo/react-hooks";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useMediaQuery } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

// Styling and Assets
import "style/App.css";
import { breakpoints } from "style/constants";
import theme from "style/theme";

// Routes
import Routes from "utils/Routes";
import customUserConfirmation from "utils/customUserConfirmation";

// Utils
import { AnalyticsObj, trackPageView, trackEvent } from "analytics/index";
import { getNativeExperience } from "state";
export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
}
const App: React.FC = () => {
  const isMobile = detectMob();

  const isNativeExperience = useSelector(getNativeExperience);

  const isTouchDevice = () => {
    if ("ontouchstart" in window) {
      return true;
    }
    return false;
  };
  return (
    <React.Fragment>
      <ApolloProvider client={client}>
        {isMobile ? (
          <DndProvider
            backend={TouchBackend}
            options={{ enableMouseEvents: true, enableTouchEvents: true }}
          >
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter getUserConfirmation={customUserConfirmation}>
                <Routes />
              </BrowserRouter>
            </ThemeProvider>
          </DndProvider>
        ) : (
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter getUserConfirmation={customUserConfirmation}>
                <Routes />
              </BrowserRouter>
            </ThemeProvider>
          </DndProvider>
        )}
      </ApolloProvider>
    </React.Fragment>
  );
};

export default track(
  {},
  {
    dispatch: (trackingData: AnalyticsObj) => {
      if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "production") return;
      const matomo: any = window._paq; // eslint-disable-line
      if (!matomo || !trackingData) return;
      if (trackingData.actions) trackEvent(matomo, trackingData);
    },
    process: (trackingData: AnalyticsObj) => {
      if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "production") return;
      const matomo: any = window._paq; // eslint-disable-line
      if (!matomo || !trackingData) return;
      if (trackingData.url) trackPageView(matomo, trackingData);
      return false;
    }
  }
)(App);
