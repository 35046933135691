import React from "react";
import { useMediaQuery } from "@material-ui/core";
import StyledGrid from "components/simple/StyledGrid";
import { Box } from "@material-ui/core";
import { breakpoints, spacingDefaults, fontSize, color } from "style/constants";
import { StyledTreesDaysRow, StyledTreesWeeklyGrid } from "./styled";
import { MyJourneyDay } from "./MyJourneyDay";
import { UserStreak } from "models/userStreak";

interface MyJourneyWeekProps {
  weeklyStreak: UserStreak[];
  openDrawer(date: Date): void;
}
export const MyJourneyWeek: React.FC<MyJourneyWeekProps> = ({
  weeklyStreak,
  openDrawer
}) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <>
      {mobile && (
        <StyledTreesWeeklyGrid className="journey-week" container>
          {/* Monday, Tuesday */}
          <StyledTreesDaysRow container>
            {weeklyStreak.slice(0, 2).map((dayStreak: UserStreak, index) => (
              <StyledGrid item key={index}>
                <MyJourneyDay userStreak={dayStreak} openDrawer={openDrawer} />
              </StyledGrid>
            ))}
          </StyledTreesDaysRow>

          {/* Wednesday, Thursday, Friday */}
          <StyledTreesDaysRow container>
            {weeklyStreak.slice(2, 5).map((dayStreak: UserStreak, index) => (
              <StyledGrid item key={index}>
                <MyJourneyDay userStreak={dayStreak} openDrawer={openDrawer} />
              </StyledGrid>
            ))}
          </StyledTreesDaysRow>

          {/* Saturday, Sunday */}
          <StyledTreesDaysRow container>
            {weeklyStreak.slice(5, 7).map((dayStreak: UserStreak, index) => (
              <StyledGrid item key={index}>
                <MyJourneyDay userStreak={dayStreak} openDrawer={openDrawer} />
              </StyledGrid>
            ))}
          </StyledTreesDaysRow>
          <StyledGrid margin={`${spacingDefaults.small} 0`} zIndex="100">
            <Box
              fontSize={fontSize.small}
              color={color.GRAPHITE}
              fontStyle="italic"
            >
              Click a tree to see your daily activity. <br />
              Spend coins to revive a missed day.
            </Box>
          </StyledGrid>
        </StyledTreesWeeklyGrid>
      )}
      {!mobile && (
        <StyledTreesWeeklyGrid className="journey-week" container>
          {/* Monday, Tuesday, Wednesday */}
          <StyledTreesDaysRow container>
            {weeklyStreak.slice(0, 3).map((dayStreak: UserStreak, index) => (
              <StyledGrid item key={index}>
                <MyJourneyDay userStreak={dayStreak} openDrawer={openDrawer} />
              </StyledGrid>
            ))}
          </StyledTreesDaysRow>

          {/* Thursday, Friday, Satuday, Sunday */}
          <StyledTreesDaysRow container>
            {weeklyStreak.slice(3, 7).map((dayStreak: UserStreak, index) => (
              <StyledGrid item key={index}>
                <MyJourneyDay userStreak={dayStreak} openDrawer={openDrawer} />
              </StyledGrid>
            ))}
          </StyledTreesDaysRow>
          <StyledGrid margin={`${spacingDefaults.small} 0`} zIndex="100">
            <Box
              fontSize={fontSize.small}
              color={color.GRAPHITE}
              fontStyle="italic"
            >
              Click a tree to see your daily activity. Spend coins to revive a
              missed day.
            </Box>
          </StyledGrid>
        </StyledTreesWeeklyGrid>
      )}
    </>
  );
};
