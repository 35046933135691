import React from "react";
import { createStyles, withStyles, Theme, Switch } from "@material-ui/core";
import { color } from "style/constants";

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 20,
      padding: 0
    },
    switchBase: {
      padding: 2,

      "&$checked": {
        color: color.WHITE,
        transform: "translateX(21px)",

        "& + $track": {
          backgroundColor: color.BLUE,
          opacity: 1
        }
      }
    },
    thumb: {
      width: 16,
      height: 16
    },
    track: {
      borderRadius: 20 / 2,
      backgroundColor: "#D8D8D8",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

export default CustomSwitch;
