import React, { useCallback, useRef } from "react";

import { ReactComponent as VideoOff } from "assets/video-off.svg";
import { ReactComponent as VideoOn } from "assets/video-on.svg";
import useDevices from "../../../hooks/useDevices/useDevices";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import { StyledIconButton } from "../styled";

export default function ToggleVideoButton(props: { disabled?: boolean }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <StyledIconButton
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
    >
      {hasVideoInputDevices && isVideoEnabled ? <VideoOn /> : <VideoOff />}
    </StyledIconButton>
  );
}
