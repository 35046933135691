import { useMediaQuery } from "@material-ui/core";
import { SortingQuestion } from "graphql/types/Contentful";
import React, { useEffect, useState } from "react";
import { Item } from "./item";
import { Category } from "./category";
import { breakpoints } from "style/constants";
import { detectMob } from "App";
import { number } from "yargs";

interface SortingProps {
  sortingQuestion: SortingQuestion;
  continueToNext: (flag: boolean) => void;
}

interface CategoryState {
  accepts: string;
  lastDroppedItem: any;
  backgroundColor: string;
  borderCoder: string;
}

interface ItemState {
  name: string;
  type: string;
}

const CardSorting: React.FC<SortingProps> = ({
  sortingQuestion,
  continueToNext
}: SortingProps) => {
  const mobile = detectMob();

  const {
    id,
    question,
    questionType,
    possibleAnswers,
    correctAnswers
  } = sortingQuestion;
  const [categories, setCategories] = useState<CategoryState[]>(
    possibleAnswers.map(answer => ({
      accepts: answer?.answer || "",
      lastDroppedItem: null,
      backgroundColor: "#FFF",
      borderCoder: "1px dashed #4F4C4D"
    }))
  );

  useEffect(() => {
    setCategories(
      possibleAnswers.map(answer => ({
        accepts: answer?.answer || "",
        lastDroppedItem: null,
        backgroundColor: "#FFF",
        borderCoder: "1px dashed #4F4C4D"
      }))
    );
  }, [possibleAnswers]);

  const handleDrop = (index: number, item: any) => {
    const { name } = item;
    const newCategories = [...categories];
    newCategories[index].lastDroppedItem = name;
    setCategories(newCategories);
  };
  const [isAnswered, setIsAnswered] = useState(false);
  const handleClick = (index: number) => {
    const newCategories = [...categories];
    const item = newCategories[index].accepts;
    if (item === correctAnswers[0].answer) {
      updateCategoryBackground(index, true);
    } else {
      updateCategoryBackground(index, false);
    }
    setIsAnswered(true);
  };
  const [isCardHideable, updateIsCardHideable] = useState(false);
  const handleCard = (isCorrect: boolean) => {
    updateIsCardHideable(isCorrect);
  };
  const updateCategoryBackground = (
    droppedIndex: number,
    isCorrect: boolean
  ) => {
    const newCategories = [...categories];
    newCategories.forEach((category, index) => {
      category.backgroundColor = "#FFF";
      category.borderCoder = "1px dashed #4F4C4D";
      if (isCorrect && droppedIndex === index) {
        category.backgroundColor = "rgba(31, 162, 162, 0.50)";
        category.borderCoder = "1px solid rgba(31, 162, 162, 0.50)";
        updateIsCardHideable(true);
      } else if (!isCorrect && droppedIndex === index) {
        category.backgroundColor = "#FFA8A8";
        category.borderCoder = "1px solid #FFA8A8";
        updateIsCardHideable(false);
      }
    });
    setCategories(newCategories);
  };

  return (
    <div
      style={
        !mobile ? { margin: "20px auto", justifyContent: "space-between" } : {}
      }
    >
      <div
        id="item"
        style={{
          cursor: "grab",
          display: "flex",
          width: "23.4375rem",
          height: "26.4375rem",
          padding: "1.5rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          flexShrink: 0
        }}
      >
        {!isCardHideable && <Item name={question} type={"Question"} />}
      </div>
      <div
        id="categories"
        style={{
          display: "flex",
          width: "23.4375rem",
          height: "13.25rem",
          padding: "1.5rem",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5rem",
          flexShrink: 0
        }}
      >
        {categories &&
          categories.map((category, index) => (
            <Category
              id={id}
              key={index}
              name={category.accepts}
              accepts={"Question"}
              onDrop={(item: any) => handleDrop(index, item)}
              answer={correctAnswers[0].answer}
              cardBgColor={category.backgroundColor}
              cardBGBorderColor={category.borderCoder}
              moveToNext={continueToNext}
              isCorrect={handleCard}
              updateCategoryBackground={(isCorrect: boolean) =>
                updateCategoryBackground(index, isCorrect)
              }
              onClick={() => handleClick(index)}
              isAnswered={isAnswered}
            />
          ))}
      </div>
    </div>
  );
};

export default CardSorting;
