import React, { useEffect, useState } from "react";
import { Box, Divider, useMediaQuery } from "@material-ui/core";
import {
  AssessmentCard,
  RadioCard,
  StyledGrid,
  StyledP,
  Timer
} from "components/simple";
import { color, fontSize, spacingDefaults, fontFamily } from "style/constants";
import { AnswerOptions, Platform } from ".";

interface DigitSymbolQuestionData {
  reference: { digits: string[]; symbols: string[] };
  digitSymbol: { digit: string; symbol: string };
  isCorrect: boolean;
}

interface DigitSymbolProps {
  saveAnswerCallback: (answer: AnswerOptions, responseTime: number) => void;
  prompt: string;
  platform: Platform;
  question: DigitSymbolQuestionData;
  currentIndex: number;
}

export const DigitSymbol: React.FC<DigitSymbolProps> = ({
  saveAnswerCallback,
  platform,
  question,
  currentIndex
}: DigitSymbolProps) => {
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [userAnswer, setUserAnswer] = useState<AnswerOptions>(
    AnswerOptions.NO_ANSWER
  );
  const [digitSymbolWidth, setDigitSymbolWidth] = useState(0);
  const [paused, setPaused] = useState(false);
  const [timesUp, setTimesUp] = useState(false);
  const [showBlank, setShowBlank] = useState(false);
  const absoluteReferenceDisplay = !useMediaQuery("(min-height:730px)");

  const reference = question.reference;
  const digitSymbol = question.digitSymbol;

  function handleKeyDown(e: { target: any; keyCode: any }) {
    // once a user enters an invalid answer, the question is disabled
    // and no new answers are accepted until time is up
    if (userAnswer === AnswerOptions.INVALID) return;

    let answer = AnswerOptions.NO_ANSWER;

    if (![74, 70].includes(e.keyCode)) {
      answer = AnswerOptions.INVALID;
    } else {
      // z: 90 (YES); /: 191 (NO) --> Updated to f: 70 (YES); j:74
      setPaused(true);
      if (e.keyCode === 70) {
        answer = question.isCorrect
          ? AnswerOptions.CORRECT
          : AnswerOptions.INCORRECT;
      } else if (e.keyCode === 74) {
        answer = !question.isCorrect
          ? AnswerOptions.CORRECT
          : AnswerOptions.INCORRECT;
      }
    }
    setUserAnswer(answer);

    // show blank screen between each digit-symbol question
    if ([AnswerOptions.CORRECT, AnswerOptions.INCORRECT].includes(answer)) {
      setShowBlank(true);
    }
  }

  function handleButtonTap(yes: boolean) {
    setPaused(true);
    let answer = AnswerOptions.NO_ANSWER;
    if (yes) {
      answer = question.isCorrect
        ? AnswerOptions.CORRECT
        : AnswerOptions.INCORRECT;
    } else {
      answer = !question.isCorrect
        ? AnswerOptions.CORRECT
        : AnswerOptions.INCORRECT;
    }
    setUserAnswer(answer);
    setShowBlank(true);
  }

  useEffect(() => {
    if (platform == Platform.Touchscreen) {
      return;
    } else {
      document.addEventListener("keydown", handleKeyDown);
    }

    return function cleanup() {
      if (platform == Platform.Keyboard) {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [question]);

  useEffect(() => {
    setStartTime(new Date());
  }, [currentIndex]);

  useEffect(() => {
    if (timesUp) {
      setShowBlank(true);
    }
  }, [timesUp]);

  useEffect(() => {
    if (showBlank) {
      setTimeout(() => {
        setUserAnswer(AnswerOptions.NO_ANSWER);

        const elapsedTime = (
          (new Date().getTime() - startTime.getTime()) /
          1000
        ).toFixed(3);

        saveAnswerCallback(userAnswer, +elapsedTime);

        // reset state for next question of same type
        setTimesUp(false);
        setPaused(false);
        setShowBlank(false);
      }, 500);
    }
  }, [showBlank]);

  useEffect(() => {
    setDigitSymbolWidth(
      document.querySelector(".digit-symbol")?.scrollWidth || 0
    );
  }, []);

  if (showBlank) return <></>;

  return (
    <AssessmentCard
      timer={
        <Timer
          seconds={3.5}
          paused={paused}
          onDone={setTimesUp}
          activeQuestion={currentIndex}
          hidden={true}
        />
      }
    >
      <StyledGrid item marginTop="1rem" marginBottom="1rem" width="100%">
        <StyledGrid
          container
          justifyContent="center"
          marginBottom="0px"
          wrap={"nowrap"}
        >
          {reference.symbols.map((symbol, index) => (
            <img
              className="digit-symbol"
              key={index}
              src={encodeURI(`https://bhp-images.s3.amazonaws.com/${symbol}`)}
              style={{ maxWidth: "80px" }}
              width="100%"
            />
          ))}
        </StyledGrid>
        <StyledGrid
          container
          justifyContent="center"
          marginTop="0px"
          wrap={"nowrap"}
        >
          {reference.digits.map((digit, index) => (
            <img
              key={index}
              src={encodeURI(`https://bhp-images.s3.amazonaws.com/${digit}`)}
              style={{ maxWidth: "80px" }}
              width="100%"
            />
          ))}
        </StyledGrid>
        <StyledGrid
          marginTop={spacingDefaults.large}
          marginBottom={spacingDefaults.large}
          width="100%"
        >
          <Divider style={{ color: color.BLACK, height: "2px" }} />
        </StyledGrid>
        <StyledGrid
          alignContent="space-between"
          alignItems="center"
          container
          justifyContent="center"
          mobileMarginTop={spacingDefaults.xxsmall}
        >
          <StyledGrid container item justifyContent="space-between">
            <StyledGrid alignContent="center" container direction="column">
              <img
                src={encodeURI(
                  `https://bhp-images.s3.amazonaws.com/${digitSymbol.symbol}`
                )}
                width={`${digitSymbolWidth}px`}
              />

              <img
                src={encodeURI(
                  `https://bhp-images.s3.amazonaws.com/${digitSymbol.digit}`
                )}
                width={`${digitSymbolWidth}px`}
              />
            </StyledGrid>
          </StyledGrid>
          {platform == Platform.Keyboard && (
            <StyledGrid
              bottom={spacingDefaults.xlarge}
              container
              justifyContent="center"
              marginTop={spacingDefaults.xxlarge}
              position={absoluteReferenceDisplay ? "absolute" : "initial"}
            >
              <StyledGrid item>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="22px"
                  textAlign="center"
                >
                  Yes
                </StyledP>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="16px"
                  textAlign="center"
                >
                  (Press &quot;f&quot;)
                </StyledP>
              </StyledGrid>
              <StyledGrid item marginLeft={"10rem"}>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="22px"
                  textAlign="center"
                >
                  No
                </StyledP>
                <StyledP
                  color={color.BLACK}
                  fontFamily={fontFamily.primary}
                  fontSize="16px"
                  textAlign="center"
                >
                  (Press &quot;j&quot;)
                </StyledP>
              </StyledGrid>
            </StyledGrid>
          )}
          {platform == Platform.Touchscreen && (
            <StyledGrid
              container
              justifyContent="center"
              width="100%"
              alignContent="center"
              margin="3rem 0"
            >
              <RadioCard
                touchscreen={true}
                selections={["Yes", "No"]}
                onChange={(_, value) => {
                  handleButtonTap(value == "Yes");
                }}
              />
            </StyledGrid>
          )}
          {platform == Platform.Keyboard &&
            userAnswer === AnswerOptions.INVALID && (
              <Box
                color={color.DARKRED}
                fontSize={fontSize.medium}
                marginTop={spacingDefaults.large}
              >
                Invalid key.
                <br />
                Press &#34;f&#34; for YES and &#34;j&#34; for NO.
              </Box>
            )}
        </StyledGrid>
      </StyledGrid>
    </AssessmentCard>
  );
};
