import React from "react";
import { Link } from "react-router-dom";
import { useStep } from "react-hooks-helper";
import { Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import styled from "styled-components";
import routesConfig from "utils/routesConfig";
import { ReactComponent as Unselected } from "assets/unselect.svg";
import { ReactComponent as Selected } from "assets/select.svg";
import { Button, StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";
import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import { ReactComponent as ChevronLeft2 } from "assets/chevron-left-2.svg";
import { ReactComponent as ChevronRight2 } from "assets/chevron-right-2.svg";

interface CCProps {
  content: {
    component: JSX.Element;
    buttonText?: string;
    link?: string;
    buttonTwoText?: string;
    linkTwo?: string;
    openModal?: () => void;
    openModalTwo?: () => void;
    callback?: Function;
    showPrevious?: boolean;
    maybeLater?: boolean;
    navigationArrows?: boolean;
  }[];
}

const StepsGrid = styled(Grid)`
  && {
    margin-top: 30px;
    & > svg {
      margin-right: 10px;
    }
  }
`;

const StyledBackButton = styled(Button)`
  && {
    margin-left: 55px;
    svg {
      margin-right: 1rem;
    }
    @media (max-width: ${breakpoints.md}px) {
      margin-left: 1rem;
    }
  }
`;

const BackContainer = styled.div`
  position: absolute;
  left: 10px;
  top: 108px;
`;

const ContentCarousel: React.FC<CCProps> = ({ content }: CCProps) => {
  const stepIcons = content.map((_, i) => i);
  const {
    index,
    navigation: { next, previous }
  } = useStep({ steps: content.length });
  return (
    <>
      {content.map(
        (c, i) =>
          i === index && (
            <React.Fragment key={i}>
              {c?.navigationArrows ? (
                <StyledGrid
                  container
                  justifyContent="center"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <StyledGrid item wrap="nowrap">
                    {index !== 0 && (
                      <IconButton
                        aria-label="Previous"
                        color="secondary"
                        onClick={previous}
                      >
                        <ChevronLeft2 />
                      </IconButton>
                    )}
                  </StyledGrid>
                  <StyledGrid item>{c.component}</StyledGrid>
                  <StyledGrid item wrap="nowrap">
                    {index !== content.length - 1 && (
                      <IconButton
                        aria-label="Next"
                        color="secondary"
                        onClick={next}
                      >
                        <ChevronRight2 />
                      </IconButton>
                    )}
                  </StyledGrid>
                </StyledGrid>
              ) : (
                c.component
              )}

              <Grid item>
                {i > 0 && c.showPrevious && (
                  <BackContainer>
                    <StyledBackButton onClick={previous} plain>
                      <ChevronLeft />
                      <Hidden mdDown>Back</Hidden>
                    </StyledBackButton>
                  </BackContainer>
                )}

                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  {i !== content.length - 1 && (
                    <Button onClick={next}>{c.buttonText}</Button>
                  )}
                  {i === content.length - 1 && c.callback && (
                    <Link to={c.link || "."}>
                      <Button
                        onClick={() => {
                          if (c.callback) c.callback();
                        }}
                      >
                        {c.buttonText}
                      </Button>
                    </Link>
                  )}
                  {i === content.length - 1 &&
                    !c.callback &&
                    (c.openModal ? (
                      <StyledGrid marginBottom={spacingDefaults.medium}>
                        <Button width="14rem" onClick={c.openModal}>
                          {c.buttonText}
                        </Button>
                      </StyledGrid>
                    ) : (
                      <Link
                        to={{
                          pathname: c.link || "."
                        }}
                      >
                        <Button>{c.buttonText}</Button>
                      </Link>
                    ))}
                  {i === content.length - 1 &&
                    c.buttonTwoText &&
                    (c.openModalTwo ? (
                      <StyledGrid marginBottom={spacingDefaults.large}>
                        <Button
                          onClick={c.openModalTwo}
                          width="14rem"
                          inverted
                          background={color.BLUEGRAY}
                        >
                          {c.buttonTwoText}
                        </Button>
                      </StyledGrid>
                    ) : (
                      <Link
                        to={{
                          pathname: c.linkTwo || "."
                        }}
                      >
                        <Button inverted>{c.buttonTwoText}</Button>
                      </Link>
                    ))}
                  {c.maybeLater && (
                    <StyledGrid item>
                      <Link
                        to={{
                          pathname: routesConfig.dashboard.path
                        }}
                      >
                        <Typography
                          style={{
                            color: color.DARKBLUE,
                            borderBottom: `1px solid ${color.DARKBLUE}`
                          }}
                        >
                          Maybe Later
                        </Typography>
                      </Link>
                    </StyledGrid>
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          )
      )}
      <StepsGrid item>
        {stepIcons.map((stepIndex, i) =>
          stepIndex === index ? <Selected key={i} /> : <Unselected key={i} />
        )}
      </StepsGrid>
    </>
  );
};

export default ContentCarousel;
