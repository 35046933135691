import styled from "styled-components";
import { breakpoints, color } from "style/constants";

export const StyledDiv = styled.div<{
  // eslint-disable-line
  bottom: string | undefined;
  mobileBottom: string | undefined;
}>`
  background: #fff;
  color: ${color.DARKERGRAY};
  border: solid 1px ${color.GRAY};
  border-radius: 23px;
  padding: 0.5rem 2rem;
  position: fixed;
  width: auto;
  z-index: 1000;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  bottom: ${props => (props.bottom ? props.bottom : "5px")};

  @media (max-width: ${breakpoints.lg}px) {
    padding: 0.5rem;
    border-radius: 0px;
    border-right: none;
    border-left: none;
    box-shadow:0px -5px 12px rgba(0, 0, 0, 0.1);
    left: 0px;
    right: 0px;
    bottom:
    ${props => (props.mobileBottom ? props.mobileBottom : "55px")};
  }

  svg {
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes blink {
    0% {opacity: 1;}
    50% {opacity 0.3;}
    100% {opacity: 1;}
  }
`;
