import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ReactComponent as GoogleFitIcon } from "assets/google-fit.svg";
import { StyledGrid, Switch } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";

interface GoogleFitPreferenceProps {
  checked: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const GoogleFitPreference: React.FC<GoogleFitPreferenceProps> = ({
  checked,
  handleChange
}: GoogleFitPreferenceProps) => {
  const [checkedStatus, setCheckedStatus] = useState<boolean>(false);

  useEffect(() => {
    setCheckedStatus(checked);
  }, [checked]);

  return (
    <StyledGrid
      alignItems="center"
      container
      height="60px"
      padding={`0 ${spacingDefaults.large}`}
    >
      <StyledGrid alignItems="center" container item xs>
        <GoogleFitIcon />

        <Box
          color={color.DARKERGRAY}
          component="p"
          fontSize={fontSize.small}
          lineHeight="150%"
          margin={`0 0 0 ${spacingDefaults.xsmall}`}
        >
          Google Fit
        </Box>
      </StyledGrid>

      <Switch checked={checkedStatus} onChange={handleChange} />
    </StyledGrid>
  );
};

export default GoogleFitPreference;
