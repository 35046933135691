import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router";
import { useTracking } from "react-tracking";

import { ReactComponent as LogoutIcon } from "assets/logout.svg";
import { StyledGrid } from "components/simple";
import Logo from "components/simple/Logo";
import { UserStatus } from "state";

import { breakpoints, spacingDefaults } from "style/constants";
import { deleteToken } from "utils/auth";
import Close from "./Close";
import IconTray from "./IconTray";
import Navigation from "./Navigation";
import { StyledAppBar as AppBar } from "./styled";
import SubHeader from "./SubHeader";
import { GetSubHeaderData } from "graphql/user/user.gql";
import { PushNotification } from "hooks/useNotifications";

interface HeaderProps {
  isRefactorEnabled: boolean;
  showAll?: boolean;
  userStatus: UserStatus;
  pushNotifications: PushNotification[];
  tab: number | boolean;
  streakDrawerOpen: boolean;
  handleAppBarClick: () => void;
  toggleNotificationsDrawer: () => void;
  setTab: React.Dispatch<React.SetStateAction<number | boolean>>;
  setStreakDrawer: (isOpen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({
  isRefactorEnabled,
  showAll,
  userStatus,
  pushNotifications,
  tab,
  streakDrawerOpen,
  handleAppBarClick,
  toggleNotificationsDrawer,
  setTab,
  setStreakDrawer
}: HeaderProps) => {
  const tracking = useTracking();
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [, setNavActive] = useState(true);

  const handleHomeClick = (): void => {
    history.push("/");
  };

  const { data } = useQuery(GetSubHeaderData);
  return (
    <>
      <StyledGrid
        className="app-header"
        container
        direction="column"
        position="sticky"
        zIndex="1301"
        top="0"
      >
        <StyledGrid item height={mobile ? "65px" : "80px"}>
          <AppBar
            id="header"
            color="primary"
            onClick={() => handleAppBarClick()}
            role="banner"
          >
            <StyledGrid
              alignItems="center"
              container
              height="100%"
              justifyContent="space-between"
              marginLeft={mobile ? spacingDefaults.small : "0px"}
              onClick={() => handleAppBarClick()}
            >
              <StyledGrid
                height="100%"
                item
                onClick={handleHomeClick}
                style={{ cursor: "pointer" }}
              >
                <Logo />
              </StyledGrid>
              {userStatus !== UserStatus.DISQUALIFIED ? (
                <>
                  <Navigation
                    setActive={setNavActive}
                    show={showAll || false}
                  />
                  <IconTray
                    toggleNotificationsDrawer={toggleNotificationsDrawer}
                    setTab={setTab}
                    show={showAll || false}
                    tab={tab}
                    numOfNotDismissedNotifications={
                      pushNotifications.filter(
                        notification => !notification.dismissed
                      ).length
                    }
                  />
                </>
              ) : (
                <IconButton
                  href="#"
                  onClick={() => {
                    tracking.trackEvent({
                      actions: ["Session", "Logout"]
                    });
                    deleteToken();
                    window.location.assign("/login");
                  }}
                >
                  <LogoutIcon />
                </IconButton>
              )}
              <Close />
            </StyledGrid>
          </AppBar>
        </StyledGrid>
        {isRefactorEnabled && (
          <SubHeader
            dayStreak={data?.me?.currentStreak || 0}
            userCoinAmount={data?.me?.totalCoins || 0}
            streakDrawerOpen={streakDrawerOpen}
            setStreakDrawer={setStreakDrawer}
          />
        )}
      </StyledGrid>
    </>
  );
};

export default Header;
