import React from "react";

import { StyledGrid } from "components/simple";
import { color } from "style/constants";

interface PillarDotProps {
  color: color;
}

const PillarDot: React.FC<PillarDotProps> = ({ color }: PillarDotProps) => (
  <StyledGrid
    borderRadius="50%"
    backgroundColor={color}
    display="inline-block"
    height="10px"
    item
    width="10px"
  />
);

export default PillarDot;
