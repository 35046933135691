import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import Schedule from "assets/brain-hq-schedule.png";
import { StyledGrid } from "components/simple";
import {
  breakpoints,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";

const PageTwo: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      alignItems="center"
      container
      direction="column"
      padding={spacingDefaults.xlarge}
    >
      <img
        alt="Recommended BrainHQ Schedule. The days of the week with Monday, Wednesday, Friday and Saturday checked."
        height={135}
        src={Schedule}
        width={310}
      />

      <Box
        fontFamily={fontFamily.secondary}
        fontSize={mobile ? fontSize.large : fontSize.xlarge}
        letterSpacing="-0.45px"
        margin={`${spacingDefaults.large} 0`}
        textAlign="center"
      >
        How long will BrainHQ take?
      </Box>

      <Box
        fontSize={mobile ? fontSize.small : fontSize.normal}
        lineHeight="180%"
        maxWidth="620px"
        textAlign="center"
      >
        The recommended training regimen is <strong>4 days a week.</strong> Each
        session is approximately <strong>30 minutes.</strong>
        <br />
        <br />
        <a
          href="https://www.brainhq.com/why-brainhq/about-the-brainhq-exercises"
          rel="noopener noreferrer"
          target="_blank"
        >
          Find descriptions of training exercises here.
        </a>
      </Box>
    </StyledGrid>
  );
};

export default PageTwo;
