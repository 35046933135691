import React from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as BookingApptImage } from "assets/booking.svg";
import { ReactComponent as ImagingApptImage } from "assets/imaging.svg";
import { Button, StyledGrid } from "components/simple";
import { color, spacingDefaults } from "style/constants";

interface ScheduleCardProps {
  disabled?: boolean;
  appointmentType: string;
}

const ScheduleCard: React.FC<ScheduleCardProps> = ({
  appointmentType
}: ScheduleCardProps) => {
  // maximum height for image on scheduling card
  const maxHeight = "200px";

  const coachingSession = appointmentType === "coaching";
  const url = coachingSession ? "scheduling/coaching" : "scheduling/imaging";
  const appointmentTextContent = coachingSession
    ? `We're here to help. Meet with your coach quarterly to review progress and plan next steps.`
    : "Make an appointment for your brain imaging at the Center for BrainHealth!";

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      container
      direction="column"
      padding={spacingDefaults.large}
    >
      {coachingSession ? (
        <BookingApptImage height="100%" style={{ maxHeight }} width="100%" />
      ) : (
        <ImagingApptImage height="100%" style={{ maxHeight }} width="100%" />
      )}

      <Box
        component="p"
        style={{ marginBottom: "0", marginTop: spacingDefaults.normal }}
        maxWidth="380px"
        textAlign="center"
      >
        {appointmentTextContent}
      </Box>

      <Link to={url}>
        <Button style={{ margin: `${spacingDefaults.normal} 0` }}>
          Schedule {coachingSession ? "a Coaching Call" : "an Imaging Session"}
        </Button>
      </Link>
    </StyledGrid>
  );
};

export default ScheduleCard;
