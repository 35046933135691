import { UserAssessmentCycleStatus } from "./Cycle";

export interface Dashboard {
  id: string;
  assessmentsDaysLeft?: number;
  assessmentsLeft?: number;
  assessmentsReset: boolean;
  brainGaugeAccessCode?: string;
  currentCycle?: UserCycle;
  currentSurveyId?: string;
  currentSurveyName?: string;
  showNotificationPreferenceBanner: boolean;
}

export interface UserCycle {
  cycle?: Cycle;
  userAssessmentCycle?: UserAssessmentCycle;
  userSurveyCycle?: any;
}

export interface Cycle {
  cycleNumber: number;
}

export interface UserAssessmentCycle {
  status: UserAssessmentCycleStatus;
  daysToComplete: number;
  aceCompleted: boolean;
  userAssessments?: UserAssessment[];
}

export interface UserAssessment {
  status?: AssessmentStatus;
  assessment: Assessment;
}

export interface Assessment {
  timeToComplete: number;
  assessmentGrouping?: any;
}

export enum AssessmentStatus {
  next = "next",
  completed = "completed",
  locked = "locked"
}

export enum UserSurveyCycleStatus {
  "none_required" = "none_required",
  "surveys_not_started" = "surveys_not_started",
  "surveys_in_progress" = "surveys_in_progress",
  "completed" = "completed"
}

export enum TaskListType {
  training = "training",
  resource = "resource",
  habit = "habit",
  survey = "survey",
  assessments = "assessments",
  optedOut = "optedOut",
  optedOutHideConsentForm = "optedOutHideConsentForm"
}
