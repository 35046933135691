import React from "react";
import FlowerIcon from "assets/flower-icon.svg";
import FruitIcon from "assets/fruit-icon.svg";
import NutIcon from "assets/nut-icon.svg";

export interface OrnamentProps {
  ornament: string;
  isCompleted?: boolean;
}
const OrnamentIcon = ({ ornament, isCompleted }: OrnamentProps) => {
  const style = {
    filter: isCompleted ? "grayscale(1)" : "none"
  };
  switch (ornament) {
    case "flower":
      return <img src={FlowerIcon} style={style} />;
    case "fruit":
      return <img src={FruitIcon} style={style} />;
    case "nut":
      return <img src={NutIcon} style={style} />;
    default:
      return null;
  }
};

export default OrnamentIcon;
