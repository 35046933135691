import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@material-ui/core";

import { breakpoints } from "style/constants";
import {
  Button,
  RadioCard,
  StyledGrid,
  AssessmentCard
} from "components/simple";

interface LikertQuestionData {
  prompt: string;
  likertResponses: Record<string, number>;
  reverse?: boolean;
}

interface LikertQuestionProps {
  answer?: Record<string, number>;
  callback: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  questions: LikertQuestionData;
  questionSubtitle?: string;
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
}

const LikertQuestion: React.FC<LikertQuestionProps> = ({
  answer,
  callback,
  questions: { likertResponses, prompt, reverse },
  skipCallback
}: LikertQuestionProps) => {
  const initialChoice = answer && Object.keys(answer)[0];

  const [disabled, setDisabled] = useState(!answer);
  const [choice, setChoice] = useState<string>(initialChoice || "");
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const selections = Object.keys(likertResponses).sort((first, second) => {
    return reverse
      ? likertResponses[second] - likertResponses[first]
      : likertResponses[first] - likertResponses[second];
  });

  useEffect(() => {
    const initialChoice = answer && Object.keys(answer)[0];

    setDisabled(!answer);
    setChoice(initialChoice || "");
  }, [prompt]); // eslint-disable-line

  return (
    <AssessmentCard question={prompt} skipCallback={skipCallback}>
      <StyledGrid item width={mobile ? "300px" : "538px"}>
        <RadioCard
          selections={selections}
          column
          onChange={(event, value) => {
            setChoice(value);
            setDisabled(false);
          }}
          value={choice}
        />
      </StyledGrid>
      <StyledGrid item margin={mobile ? "0" : "40px 0"}>
        <Button
          data-testid="next-btn"
          wide={!mobile}
          disabled={disabled}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={() => {
            setDisabled(true);
            callback({ [choice]: likertResponses[choice] });
          }}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default LikertQuestion;
