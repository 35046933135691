import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, ThemeProvider } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { endOfMonth, isSameDay, startOfDay, startOfMonth } from "date-fns";
import { showErrorNotification } from "state";
import styled from "styled-components";

import BlueTree from "assets/blue-streak-tree.svg";
import GreenTree from "assets/green-streak-tree.svg";
import GreyTree from "assets/grey-streak-tree.svg";
import ReviveLeaf from "assets/revive-leaf.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/calendar-chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/calendar-chevron-right.svg";
import globalTheme from "style/theme";
import { color, spacingDefaults } from "style/constants";
import { UserStreak, UserStreakForPeriodData } from "models/userStreak";
import { UserStreakForPeriod } from "graphql/userStreak/userStreak.gql";
import StyledGrid from "components/simple/StyledGrid";

interface Props {
  currentDate: Date;
  userCreatedAt: Date;
}

interface StyledDayProps {
  disabled?: boolean;
}

const StyledDay = styled("div")<StyledDayProps>`
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${props => (props.disabled ? color.PALEGRAY : color.BLACK)};
`;

interface StyledTreeProps {
  day: Date;
  tree: string;
}

const StyledTree: React.FC<StyledTreeProps> = ({ day, tree }) => {
  return (
    <StyledGrid container position="relative">
      <img src={tree} height="40px" width="40px" />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        {day.getDate()}
      </div>
    </StyledGrid>
  );
};

const SchedulingCalendarTheme = createTheme({
  ...globalTheme,
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: "100%",
        width: "100%"
      }
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        justifyContent: "space-between",
        border: `2px solid ${color.GRAY}`,
        borderRadius: "10px"
      }
    },
    MuiTypography: {
      root: {
        color: color.BLUE,
        textAlign: "center"
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        justifyContent: "center"
      },
      iconButton: {
        "& svg": {
          "& path": {
            stroke: color.DARKBLUE,
            strokeOpacity: 1
          }
        },
        "&:disabled": {
          opacity: 0.3,
          "& svg": {
            "& path": {
              display: "none"
            }
          }
        }
      },
      daysHeader: {
        justifyContent: "space-around"
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: "300px"
      },
      week: {
        justifyContent: "space-around",
        margin: `${spacingDefaults.normal} 0`
      }
    }
  }
} as any);

const UserStreakCalendar: React.FC<Props> = ({
  currentDate,
  userCreatedAt
}) => {
  const dispatch = useDispatch();

  const [beginningDate, setBeginningDate] = useState<Date>(
    startOfMonth(currentDate)
  );
  const [endDate, setEndDate] = useState<Date>(currentDate);
  const [userStreak, setUserStreak] = useState<UserStreak[]>([]);

  useQuery<UserStreakForPeriodData>(UserStreakForPeriod, {
    variables: {
      input: {
        periodStart: beginningDate,
        periodEnd: endDate
      }
    },
    skip: !beginningDate || !endDate,
    onCompleted: data => {
      const streak = data?.userStreakForPeriod;
      setUserStreak(streak);
    },
    onError: () =>
      dispatch(
        showErrorNotification("Error", "Unable to pull streak progress data.")
      )
  });

  const userCompletedStreaks = userStreak.filter(
    data => data.habitCompleted || data.resourceViewed || data.trainingCompleted
  );

  const screenBreakAppliedStreaks = userStreak.filter(
    data => data.freeDayPassApplied || data.screenBreakApplied
  );

  const getStreakTree = (streakDay: Date) => {
    if (isSameDay(startOfDay(currentDate), streakDay)) return BlueTree;

    const userCompletedStreak = userCompletedStreaks.find(streak => {
      if (streak.date) {
        const [year, month, day] = streak.date.split("-"); // yyyy-MM-dd (e.g. 2022-07-11)
        const userStreakDate = new Date(+year, +month - 1, +day); // month is 0-indexed (e.g. Jan = 0)

        return isSameDay(userStreakDate, streakDay);
      }
    });
    const hasAppliedScreenBreak = screenBreakAppliedStreaks.find(streak => {
      if (streak.date) {
        const [year, month, day] = streak.date.split("-"); // yyyy-MM-dd (e.g. 2022-07-11)
        const userStreakDate = new Date(+year, +month - 1, +day); // month is 0-indexed (e.g. Jan = 0)

        return isSameDay(userStreakDate, streakDay);
      }
    });
    if (userCompletedStreak) {
      return GreenTree;
    } else if (hasAppliedScreenBreak) {
      return ReviveLeaf;
    } else return GreyTree;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={SchedulingCalendarTheme}>
        <DatePicker
          disableToolbar
          variant="static"
          leftArrowIcon={<ChevronLeftIcon height={20} />}
          rightArrowIcon={<ChevronRightIcon height={20} />}
          renderDay={(day, _, dayInCurrentMonth) => {
            if (dayInCurrentMonth) {
              if (
                (day && day > currentDate) ||
                (day && day < startOfDay(new Date(userCreatedAt)))
              ) {
                return <StyledDay>{day?.getDate()}</StyledDay>;
              } else {
                return (
                  <>
                    {day && <StyledTree day={day} tree={getStreakTree(day)} />}
                  </>
                );
              }
            } else {
              return <StyledDay disabled>{day?.getDate()}</StyledDay>;
            }
          }}
          value={currentDate}
          minDate={userCreatedAt}
          maxDate={new Date()}
          onChange={() => {}}
          onMonthChange={date => {
            if (date) {
              setBeginningDate(date);
              setEndDate(endOfMonth(date));
            }
          }}
          type={"date"}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default UserStreakCalendar;
