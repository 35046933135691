import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { ReactComponent as Bell } from "assets/bell.svg";
import { ReactComponent as Help } from "assets/question-mark.svg";
import { getUser } from "state";
import Avatar from "../Avatar";
import { IconWrapper, StyledTab as Tab, StyledTabs as Tabs } from "./styled";
import { color, fontSize } from "style/constants";

interface IconTrayProps {
  toggleNotificationsDrawer: () => void;
  setTab: React.Dispatch<React.SetStateAction<number | boolean>>;
  show: boolean;
  tab: number | boolean;
  numOfNotDismissedNotifications: number;
}

export const getTab = (location: string): number | boolean => {
  switch (location) {
    case "/faq":
      return 0;
    case "/profile":
      return 2;
    default:
      return false;
  }
};

const IconTray: React.FC<IconTrayProps> = ({
  toggleNotificationsDrawer,
  setTab,
  show,
  tab,
  numOfNotDismissedNotifications
}: IconTrayProps) => {
  const pathsToShow = [
    "/dashboard",
    "/profile",
    "/resources",
    "/index",
    "/training",
    "/faq",
    "/courses",
    "/myjourney"
  ];
  const location = window.location.pathname;
  const shouldShow =
    pathsToShow.some(
      path => path === location || location.includes(path + "/")
    ) || show;

  useEffect(() => {
    const tab = getTab(location);
    setTab(tab);
  }, [location, setTab]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setTab(newValue);
  };

  const handleBellClick = (event: React.ChangeEvent) => {
    toggleNotificationsDrawer();
    event.stopPropagation();
  };

  const user = useSelector(getUser);
  const useStyles = makeStyles(() =>
    createStyles({
      notificationIndicator: {
        opacity: 1,
        overflow: "visible",
        "&[aria-selected=false] span": {
          backgroundColor: "rgba(244, 244, 244, 0.7)",
          "& path": {
            stroke: "rgba(0,0,0,0.609)"
          }
        },
        "&::after": {
          backgroundColor: color.RED,
          borderRadius: "15px",
          color: color.WHITE,
          content: `"${numOfNotDismissedNotifications}"`,
          fontSize: fontSize.xsmall,
          height: "20px",
          minWidth: "20px",
          position: "absolute",
          right: "-8px",
          textAlign: "center",
          top: "14px"
        }
      }
    })
  );
  const classes = useStyles();
  return (
    <>
      {shouldShow && (
        <Grid item>
          <IconWrapper>
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={handleChange}
              aria-label="icon tabs"
            >
              <Tab component={Link} to="/faq" icon={<Help />} />
              <Tab
                className={
                  numOfNotDismissedNotifications > 0
                    ? classes.notificationIndicator
                    : ""
                }
                icon={<Bell />}
                onClick={handleBellClick}
              />
              <Tab
                component={Link}
                to="/profile"
                icon={
                  <Avatar
                    profileImageKey={user?.profileImageKey || undefined}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                  />
                }
              />
            </Tabs>
          </IconWrapper>
        </Grid>
      )}
    </>
  );
};

export default IconTray;
