import styled from "styled-components";

import { color } from "style/constants";

export const Wrapper = styled.div`
  .MuiBottomNavigation-root {
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 1301;
    .Mui-selected {
      svg {
        path {
          stroke: ${color.BLUE};
        }
      }
    }
  }
`;
