import React from "react";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";

import DatePickerTheme from "./DatePickerTheme";

const DatePicker: React.FC<KeyboardDatePickerProps> = ({
  name,
  value,
  format,
  label,
  ...other
}: KeyboardDatePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={DatePickerTheme}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          fullWidth
          label={label}
          name={name}
          value={value}
          format={format}
          placeholder={format}
          {...other}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
