import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import track from "react-tracking";
import styled from "styled-components";

import image from "assets/confirmRegistration.svg";
import { Button, StyledGrid } from "components/simple";
import {
  ConfirmRegistration,
  GetOrganizationScreenerInfo,
  Me
} from "graphql/user/user.gql";
import { hideLoader, showLoader } from "state";
import routesConfig from "utils/routesConfig";
import { checkAuth } from "utils/auth";
import { Title, RegisterCallout } from "../Registration/styled";

const StyledImage = styled.img`
  && {
    display: flex;
    height: 275px;
    margin: auto;
    width: 100%;
  }
`;

const url: string = routesConfig.confirm.path;

const Confirmation: React.FC<any> = (props: any) => {
  const { hidden, location } = props;
  const [confirmRegistration] = useMutation(ConfirmRegistration);
  const [goodToken, setGoodToken] = useState(false);
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const isLoggedIn = checkAuth();
  const history = useHistory();
  const [skipScreener, setSkipScreener] = useState(false);
  const [meQuery] = useLazyQuery(GetOrganizationScreenerInfo, {
    onCompleted: data => {
      const shouldHideScreener = data?.me?.organization?.hideScreener;
      setSkipScreener(shouldHideScreener);
    }
  });

  useEffect(() => {
    async function fetchData(): Promise<void> {
      dispatch(showLoader());
      let res;

      const sessionToken = location.search.split("=")[1];
      try {
        res = await confirmRegistration({ variables: { token: sessionToken } });
      } catch {
        setText(
          "Something went wrong. Please try signing in again to receive another confirmation email."
        );
        dispatch(hideLoader());
        setGoodToken(false);
      }

      const token = get(res, "data.confirmRegistration.token");
      if (token) {
        localStorage.setItem("token", token);
        dispatch(hideLoader());
        setGoodToken(true);
        meQuery();
      }
    }
    if (isLoggedIn) {
      history.push(routesConfig.dashboard.path);
    } else {
      fetchData();
    }
  }, [confirmRegistration, location, dispatch]);

  return (
    <>
      {goodToken ? (
        <StyledGrid
          hidden={hidden}
          alignItems="center"
          container
          fillRemainingHeight
        >
          <StyledGrid
            item
            maxWidth="500px"
            centerContent
            alignItems="center"
            container
            direction="column"
            margin="0 auto"
            mobileMaxWidth="90%"
            mobilePadding="2rem 0"
            spacing={1}
          >
            <StyledGrid item marginBottom="10px">
              <StyledImage
                src={image}
                alt="3 balloons with scattered envelopes"
              />
            </StyledGrid>
            <StyledGrid item>
              <Title>Thank you for registering with BrainHealth.</Title>
            </StyledGrid>
            {!skipScreener ? (
              <StyledGrid item>
                <StyledGrid>
                  <RegisterCallout>
                    <b>Next step:</b> Complete a 6-question screener to find out
                    if you qualify to participate in the research study.
                  </RegisterCallout>
                  <Link to="/">
                    <Button>Take Screener</Button>
                  </Link>
                </StyledGrid>
              </StyledGrid>
            ) : (
              <StyledGrid>
                <StyledGrid item width="250px">
                  <Link to="/screener/qualification">
                    <Button fullWidth>Continue</Button>
                  </Link>
                </StyledGrid>
              </StyledGrid>
            )}
          </StyledGrid>
        </StyledGrid>
      ) : (
        <div>{text}</div>
      )}
    </>
  );
};

export default track({
  url
})(Confirmation);
