import { CircularProgress } from "@material-ui/core";
import React from "react";

import { StyledGrid, WidgetTitle, StyledP } from "components/simple";
import { color, spacingDefaults } from "style/constants";
import { CircularProgressUnderlay } from "../styled";
import { TrainingProgress } from "../types";
import { TeaserTrainingProgress } from "../types";

interface TrainingStatusProps {
  trainingProgress: Partial<TrainingProgress> | null;
  teaserTrainingProgress: Partial<TeaserTrainingProgress> | null;
  isActive?: boolean;
}

const TrainingStatus: React.FC<TrainingStatusProps> = ({
  trainingProgress,
  teaserTrainingProgress,
  isActive = false
}: TrainingStatusProps) => {
  const completionPercentage = trainingProgress
    ? (trainingProgress.completedCourses || 0) /
      (trainingProgress.totalCourses || 1)
    : 0;

  const dueDate =
    trainingProgress && trainingProgress.dueDate
      ? new Date(trainingProgress.dueDate)
      : undefined;

  const teaserCompletionPercentage = teaserTrainingProgress
    ? (teaserTrainingProgress.completedTeaserCourses || 0) /
      (teaserTrainingProgress.totalTeaserCourses || 1)
    : 0;

  const teaserDueDate =
    teaserTrainingProgress && teaserTrainingProgress.dueDate
      ? new Date(teaserTrainingProgress.dueDate)
      : undefined;

  return (
    <StyledGrid container>
      <StyledGrid item>
        <WidgetTitle>Training Status</WidgetTitle>
      </StyledGrid>

      <StyledGrid
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        item
        justifyContent="center"
        marginTop={spacingDefaults.normal}
        padding="40px"
        filter={isActive ? undefined : "saturate(0)"}
      >
        <StyledGrid
          alignItems="center"
          centerContent
          container
          direction="column"
          height="246px"
          item
        >
          <StyledGrid item position="absolute">
            <CircularProgressUnderlay
              thickness={2}
              size="246px"
              value={100}
              variant="determinate"
            />
          </StyledGrid>

          <StyledGrid item position="absolute">
            <CircularProgress
              color="secondary"
              thickness={2}
              size="246px"
              value={
                teaserTrainingProgress
                  ? 100 * teaserCompletionPercentage
                  : 100 * completionPercentage
              }
              variant="determinate"
            />
          </StyledGrid>

          <StyledGrid item marginBottom={spacingDefaults.medium}>
            <StyledP color={color.BLUE} fontSize="60px" textAlign="center">
              {teaserTrainingProgress
                ? `${teaserTrainingProgress.completedTeaserCourses}/${teaserTrainingProgress.totalTeaserCourses}`
                : trainingProgress
                ? `${trainingProgress.completedCourses}/${trainingProgress.totalCourses}`
                : "0"}
            </StyledP>
          </StyledGrid>

          <StyledP fontSize="20px" textAlign="center" width="100px">
            courses completed
          </StyledP>
        </StyledGrid>

        {(dueDate || teaserDueDate) && (
          <StyledGrid item marginTop={spacingDefaults.normal}>
            <StyledP data-testid="due-date" textAlign="center">
              Complete training at your own pace. We suggest 15 minutes weekly.
            </StyledP>
          </StyledGrid>
        )}
      </StyledGrid>
    </StyledGrid>
  );
};

export default TrainingStatus;
