import React, { useEffect, useState } from "react";

import { ReactComponent as ChatOn } from "assets/chat-on.svg";
import { ReactComponent as ChatOff } from "assets/chat-off.svg";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import { StyledIconButton } from "../styled";

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  circle: {
    width: "10px",
    height: "10px",
    backgroundColor: "#5BB75B",
    borderRadius: "50%",
    position: "absolute",
    top: "11px",
    left: "32px",
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`
  },
  hasUnreadMessages: {
    opacity: 1
  },
  ring: {
    border: "3px solid #5BB75B",
    borderRadius: "30px",
    height: "14px",
    width: "14px",
    position: "absolute",
    top: "13px",
    left: "35px",
    opacity: 0
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1
  },
  "@keyframes expand": {
    "0%": {
      transform: "scale(0.1, 0.1)",
      opacity: 0
    },
    "50%": {
      opacity: 1
    },
    "100%": {
      transform: "scale(1.4, 1.4)",
      opacity: 0
    }
  }
});

export default function ToggleChatButton(): JSX.Element {
  const classes = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const {
    isChatWindowOpen,
    setIsChatWindowOpen,
    conversation,
    hasUnreadMessages
  } = useChatContext();

  const toggleChatWindow = (): void => {
    setIsChatWindowOpen(!isChatWindowOpen);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = (): void => setShouldAnimate(true);
      conversation.on("messageAdded", handleNewMessage);
      return () => {
        conversation.off("messageAdded", handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);

  return (
    <StyledIconButton onClick={toggleChatWindow} disabled={!conversation}>
      {isChatWindowOpen ? <ChatOff /> : <ChatOn />}
      <div
        className={clsx(classes.ring, {
          [classes.animateRing]: shouldAnimate
        })}
      />
      <div
        className={clsx(classes.circle, {
          [classes.hasUnreadMessages]: hasUnreadMessages
        })}
      />
    </StyledIconButton>
  );
}
