import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as ResourcesIcon } from "assets/bookmark.svg";
import { ReactComponent as HealthIcon } from "assets/Health.svg";
import { ReactComponent as TrainingIcon } from "assets/training.svg";
import { ReactComponent as IndexIcon } from "assets/index.svg";
import { useUserStatus } from "hooks";
import { UserStatus } from "state";
import { Wrapper } from "./styled";
import { mobileNavPaths } from "./util";

interface MobileNavProps {
  show?: boolean;
  isRefactorEnabled: boolean;
  isResourceEnabled: boolean;
  isTrainingEnabled: boolean;
  mobileTab: number;
  setMobileTab: React.Dispatch<React.SetStateAction<number>>;
  handleMobileNavChange: (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => void;
}

const getTab = (location: string): number => {
  switch (location) {
    case "/dashboard":
      return 1;
    case "/index":
      return 2;
    case "/training":
      return 3;
    case "/resources":
      return 4;
    default:
      return 0;
  }
};

const SimpleBottomNavigation: React.FC<MobileNavProps> = ({
  show,
  isRefactorEnabled,
  isResourceEnabled,
  isTrainingEnabled,
  mobileTab,
  setMobileTab,
  handleMobileNavChange
}: MobileNavProps) => {
  const userStatus = useUserStatus();

  const location = useLocation().pathname;
  const mobileNav = mobileNavPaths.some(
    path => path === location || location.includes(path + "/")
  );

  useEffect(() => {
    setMobileTab(getTab(location));
  }, [location]);

  return (
    <>
      {(mobileNav || show) && (
        <Wrapper>
          <BottomNavigation
            className="mobile-nav-footer"
            value={mobileTab}
            onChange={handleMobileNavChange}
            showLabels
          >
            {/* not puting this in styled because it's a placeholder for the tabs to have an unset state */}
            <BottomNavigationAction style={{ display: "none" }} />
            {userStatus !== UserStatus.DISQUALIFIED && (
              <BottomNavigationAction
                label="Dashboard"
                icon={<HealthIcon />}
                component={Link}
                to="/dashboard"
              />
            )}
            {isRefactorEnabled && (
              <BottomNavigationAction
                label="Index"
                icon={<IndexIcon />}
                component={Link}
                to="/index"
              />
            )}
            {userStatus !== UserStatus.DISQUALIFIED && isTrainingEnabled && (
              <BottomNavigationAction
                label="Training"
                icon={<TrainingIcon />}
                component={Link}
                to="/training"
              />
            )}
            {isResourceEnabled && (
              <BottomNavigationAction
                label="Resources"
                icon={<ResourcesIcon />}
                component={Link}
                to="/resources"
              />
            )}
          </BottomNavigation>
        </Wrapper>
      )}
    </>
  );
};

export default SimpleBottomNavigation;
