import { Grid, useMediaQuery } from "@material-ui/core";
import format from "date-fns/format";
import React, { useState } from "react";

import {
  Button,
  StyledGrid,
  StyledP,
  StyledA,
  ConfirmationModal
} from "components/simple";
import { ProfileFormValues } from "models/user";
import { breakpoints, fontSize, spacingDefaults } from "style/constants";
import ProfileForm from "./Form";
import { ProfileField } from "./styled";
import { OptOut } from "graphql/user/user.gql";
import { useMutation } from "@apollo/react-hooks";
import { fetchUser, getUserStatus, UserStatus } from "state";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import routesConfig from "utils/routesConfig";

interface ProfileDetailsProps {
  data: ProfileFormValues;
  refetchData: () => Promise<any>;
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({
  data,
  refetchData
}: ProfileDetailsProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [inEditMode, setInEditMode] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();
  const userStatus = useSelector(getUserStatus);

  const [
    showOptOutConfirmationModal,
    setShowOptOutConfirmationModal
  ] = useState(false);

  const [optOut] = useMutation(OptOut);

  return (
    <StyledGrid
      container
      padding={mobile ? "2rem" : "3rem"}
      paddingBottom={spacingDefaults.normal}
    >
      {inEditMode ? (
        <ProfileForm
          data={data}
          refetchData={refetchData}
          setInEditMode={setInEditMode}
        />
      ) : (
        <>
          <Grid item xs={12} lg={4}>
            <ProfileField>
              <label>First Name</label>
              <span>{data.firstName}</span>
            </ProfileField>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ProfileField>
              <label>Last Name</label>
              <span>{data.lastName}</span>
            </ProfileField>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ProfileField>
              <label>Nickname (Optional)</label>
              <span>{data.nickname ?? "-"}</span>
            </ProfileField>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ProfileField>
              <label>Email</label>
              <span>{data.email}</span>
            </ProfileField>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ProfileField>
              <label>Phone Number</label>
              <span>{data.phone ?? "-"}</span>
            </ProfileField>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ProfileField>
              <label>Date of Birth</label>
              <span>
                {data.dob ? format(new Date(data.dob), "MM/dd/yyyy") : "-"}
              </span>
            </ProfileField>
          </Grid>

          <Grid container item justifyContent="center" xs={12}>
            <Button
              onClick={() => {
                setInEditMode(true);
              }}
            >
              Edit Profile
            </Button>
          </Grid>
        </>
      )}
      {userStatus === UserStatus.QUALIFIED_AND_CONSENTED && (
        <StyledGrid item marginTop={spacingDefaults.normal} xs={12}>
          <StyledP
            fontSize={fontSize.xsmall}
            textAlign={mobile ? "center" : undefined}
          >
            <em>
              If you no longer want to participate in The BrainHealth
              Project&reg; click the{" "}
              <StyledA
                fontSize={fontSize.xsmall}
                onClick={() => setShowOptOutConfirmationModal(true)}
              >
                Opt Out of Project
              </StyledA>{" "}
              button below.
            </em>
          </StyledP>
        </StyledGrid>
      )}
      {showOptOutConfirmationModal && (
        <ConfirmationModal
          title="Are you sure?"
          content="Opting out of the Project will restrict your access to the BrainHealth dashboard. You can opt back in at any time."
          confirmText="Yes, I want to opt out."
          cancelText="I'm staying in."
          onConfirm={async () => {
            setShowOptOutConfirmationModal(false);

            await optOut();

            dispatch(fetchUser());
            history.push(routesConfig.surveys.survey.path);
          }}
          onCancel={() => setShowOptOutConfirmationModal(false)}
        />
      )}
    </StyledGrid>
  );
};

export default ProfileDetails;
