import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Prompt } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { get, omit } from "lodash";
import { useTracking } from "react-tracking";

import { Button, ProgressBar, Question, StyledGrid } from "components/simple";
import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import { spacingDefaults } from "style/constants";
import { UserAssessment } from "graphql/types/User";
import { SubmitAssessment } from "graphql/userAssessments/userAssessments.gql";
import routesConfig from "utils/routesConfig";
import { DashboardQuery, GetSubHeaderData } from "graphql/user/user.gql";
import { Dashboard } from "graphql/types/Dashboard";

function getLastActiveQuestion(
  answers: Record<any, any>[],
  activeQuestion: number
): number {
  const num = activeQuestion - 1;
  const answer = answers[num];
  const response = get(answer, "answerData.response", null);
  if (response === "N/A") {
    return getLastActiveQuestion(answers, num);
  }
  return num;
}

function shouldSkip(skipMap: Record<string, string>, response: any): boolean {
  let result = false;

  if (Array.isArray(response)) {
    result = response.every((x: string) => !!skipMap[x]);
  } else {
    result = !!skipMap[response];
  }

  return result;
}

function getSkipTo(skipMap: Record<string, string>, response: any): string {
  let result = "";

  if (Array.isArray(response)) {
    // NOTE - We are assuming each response maps to the same skip. We don't
    // support multiple_response with different skips.

    result = skipMap[response[0]];
  } else {
    result = skipMap[response];
  }

  return result;
}

interface Props {
  userAssessment: Partial<UserAssessment>;
  lastAssessmentInGroup?: boolean;
}

interface UserSurvey {
  id: string;
  completionDate: any;
  survey: any;
}

const url = routesConfig.assessments.assessment.path;

const Questions: React.FC<Props> = ({ userAssessment }: Props) => {
  const tracking = useTracking();
  const userAssessmentId = get(userAssessment, "id", "");
  const hideBack = get(userAssessment, "assessment.hideBack", "");
  const questions = get(userAssessment, "assessment.questions", []);
  const assessmentName = get(userAssessment, "assessment.displayName", "");
  const lastAssessment = get(userAssessment, "lastAssessment", false);
  const displayName = get(userAssessment, "assessment.displayName", "");
  const [activeQuestion, setActiveQuestion] = useState(0);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [answers, setAnswers] = useState<Record<any, any>[]>([]);
  const [responseTimes, setResponseTimes] = useState<number[]>([]);
  const [startTime, setStartTime] = useState(new Date());
  const [goBack, setGoBack] = useState(false);
  const [assessmentComplete, setAssessmentComplete] = useState(false);
  const [skipTo, setSkipTo] = useState<string>("");
  const [
    hasIncompleteRequiredSurveys,
    setHasIncompleteRequiredSurveys
  ] = useState(false);
  const [dashboardData, setDashboardData] = useState<Dashboard>();

  useQuery(DashboardQuery, {
    fetchPolicy: "no-cache",
    onCompleted: data => setDashboardData(data?.me)
  });

  const [submitAssessment] = useMutation(SubmitAssessment, {
    refetchQueries: [{ query: GetSubHeaderData }],
    onCompleted() {
      tracking.trackEvent({
        url,
        actions: ["Assessments", "Complete Assessment", displayName]
      });
      setAssessmentComplete(true);
    }
  });

  useEffect(() => {
    setStartTime(new Date());
  }, [activeQuestion]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const updateAnswers = (answer: Record<any, any>): void => {
    const elapsedTime = Math.floor(
      (new Date().getTime() - startTime.getTime()) / 1000
    );
    responseTimes[activeQuestion] =
      (responseTimes[activeQuestion] ?? 0) + elapsedTime;
    setResponseTimes(responseTimes);

    tracking.trackEvent({
      url,
      actions: [
        "Assessments",
        "Answer Question",
        questions[activeQuestion].internalName
      ]
    });
    answers[activeQuestion] = {
      questionId: questions[activeQuestion].id,
      answerData: answer,
      responseTime: responseTimes[activeQuestion] ?? 0
    };
    setAnswers(answers);
    if (activeQuestion === questions.length - 1) {
      const input = {
        userAssessmentId,
        answers
      };
      submitAssessment({
        variables: { input }
      });
      return;
    }
    setActiveQuestion(activeQuestion + 1);

    // NOTE - Handle skipping based on response.

    const { questionType, questionData } = questions[activeQuestion];

    const isLikert = questionType === "likert_scale";
    const response = isLikert ? Object.keys(answer)[0] : answer["response"];

    if (
      questionData.skip?.byAnswer &&
      shouldSkip(questionData.skip.byAnswer, response)
    ) {
      tracking.trackEvent({
        url,
        actions: [
          "Assessments",
          "Skip Question",
          questions[activeQuestion].internalName
        ]
      });

      setSkipTo(getSkipTo(questionData.skip.byAnswer, response));
    }
  };

  useEffect(() => {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "Changes will be lost!";
    };
  }, []);

  useEffect(() => {
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (
      questions[activeQuestion] &&
      questions[activeQuestion].internalName === skipTo
    ) {
      setSkipTo("");
      return;
    }
    if (skipTo) {
      updateAnswers({ response: "N/A" });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activeQuestion, skipTo]);

  useEffect(() => {
    if (displayName !== "") {
      tracking.trackEvent({
        url,
        actions: ["Assessments", "Start Assessment", displayName]
      });
    }
    return;
  }, [displayName, tracking]);

  useEffect(() => {
    if (dashboardData?.currentCycle?.userSurveyCycle?.userSurveys) {
      const userSurveysCompleted: boolean[] = dashboardData?.currentCycle?.userSurveyCycle?.userSurveys.map(
        (us: UserSurvey) =>
          us?.completionDate !== null && us?.completionDate !== undefined
      );
      setHasIncompleteRequiredSurveys(
        userSurveysCompleted.filter(usc => usc === false).length > 0
      );
    } else {
      setHasIncompleteRequiredSurveys(false);
    }
  }, [dashboardData]);

  // NOTE - For the About You assessment, we autopopulate the answers from the
  // previous cycle.

  if (
    displayName === "About You" &&
    userAssessment.userAnswers &&
    userAssessment.userAnswers.length
  ) {
    // NOTE - Technically, new questions could have been added, or the existing
    // ones moved around, so we user the question order to make the array. Also
    // if we see 'N/A' we are assuming that it was skipped the last time around
    // and we can ignore it.

    const oldAnswers = userAssessment.userAnswers.reduce(
      (result: any[], userAnswer: any) => {
        const answer =
          userAnswer.answerData.response === "N/A"
            ? undefined
            : omit(userAnswer, ["__typename", "assesmentQuestion"]);

        result[userAnswer.assessmentQuestion.order - 1] = answer;

        return result;
      },
      []
    );

    if (answers.length === 0 && oldAnswers.length > 0) setAnswers(oldAnswers);
  }

  if (goBack) {
    return <Redirect push to="/assessments/group" />;
  }
  if (assessmentComplete) {
    if (lastAssessment) {
      if (!hasIncompleteRequiredSurveys)
        return <Redirect push to="/assessments/complete" />;
    }
    const numberOfGroups = get(
      userAssessment,
      "assessment.assessmentGrouping.assessmentPart.numberOfGroups",
      -1
    );
    const groupOrder = get(
      userAssessment,
      "assessment.assessmentGrouping.order",
      0
    );
    const group: UserAssessment[] = get(
      userAssessment,
      "assessment.assessmentGrouping.userAssessments",
      []
    );
    const groupName: string = get(
      userAssessment,
      "assessment.assessmentGrouping.name",
      ""
    );
    const isLastAssessmentInGroup =
      group.filter(assessment => !assessment.completed).length === 1;
    const isFinalAssessmentInPart =
      isLastAssessmentInGroup && groupOrder === numberOfGroups;
    if (isFinalAssessmentInPart) {
      const partNumber = get(
        userAssessment,
        "assessment.assessmentGrouping.assessmentPart.order"
      );
      const props = {
        groupName,
        partNumber,
        lastAssessment,
        hasIncompleteRequiredSurveys
      };
      return (
        <Redirect
          to={{
            pathname: "/assessments/part/complete",
            state: { props }
          }}
        />
      );
    }
    return <Redirect push to="/assessments/group" />;
  }

  if (skipTo && questions[activeQuestion]?.internalName !== skipTo) {
    return null;
  }

  return (
    <>
      <Prompt message={"quit-assessment"} />
      <ProgressBar
        activeStep={activeQuestion}
        steps={questions.length}
        variant="solid"
      />
      <StyledGrid
        container
        item
        position="fixed"
        marginLeft={spacingDefaults.xlarge}
        mobileMarginLeft="0"
        top={"120px"}
        mobileTop={"90px"}
      >
        {!hideBack && (
          <Button
            plain
            onClick={() => {
              if (activeQuestion === 0) {
                setGoBack(true);
              }
              const num = getLastActiveQuestion(answers, activeQuestion);
              setActiveQuestion(num);
            }}
          >
            <ChevronLeft />
            <StyledGrid item marginLeft="1rem">
              <Hidden mdDown>Back</Hidden>
            </StyledGrid>
          </Button>
        )}
      </StyledGrid>
      {questions[activeQuestion] && (
        <Question
          activeQuestion={activeQuestion}
          assessmentName={assessmentName}
          answers={answers}
          question={questions[activeQuestion]}
          updateAnswers={updateAnswers}
          skipCallback={
            !!questions[activeQuestion]?.questionData?.skip?.asNotApplicable
              ? () =>
                  setSkipTo(
                    questions[activeQuestion].questionData.skip.asNotApplicable
                  )
              : undefined
          }
          skipText={questions[activeQuestion]?.questionData?.skip?.skipText}
        />
      )}
    </>
  );
};

export default Questions;
