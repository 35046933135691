import { Hidden, IconButton, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/close.svg";
import { ReactComponent as EarImage } from "assets/ear.svg";
import { StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  iconSizes,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";

interface SurveyBannerProps {
  dismiss(): Promise<void>;
  firstName?: string;
}

const SurveyBanner: React.FC<SurveyBannerProps> = ({
  dismiss,
  firstName
}: SurveyBannerProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  return (
    <StyledGrid
      alignItems="center"
      backgroundColor={color.WHITE}
      borderRadius="8px"
      container
      overflow={mobile ? "hidden" : undefined}
      padding="20px"
      position="relative"
      onClick={
        mobile
          ? () => history.push(routesConfig.surveys.survey.path)
          : undefined
      }
    >
      <Hidden mdDown>
        <StyledGrid item height="50px" position="relative">
          <EarImage
            height={475}
            style={{
              position: "absolute",
              top: "-215px"
            }}
            width={275}
          />
        </StyledGrid>

        <StyledGrid container direction="column" item marginLeft="320px" xs>
          <StyledGrid item marginBottom={spacingDefaults.xsmall}>
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.large}
              aria-label="listening"
            >
              <strong>{firstName}</strong>, we&#39;re listening!
            </StyledP>
          </StyledGrid>

          <StyledP color={color.BLACK} aria-label="improve">
            Help us improve so we can help you improve your brain fitness.
          </StyledP>

          <Link to={routesConfig.surveys.survey.path} aria-label="go-to-survey">
            <StyledP color={color.BLACK} margintop={spacingDefaults.small}>
              <strong>Take Survey (2 min) &gt;</strong>
            </StyledP>
          </Link>
        </StyledGrid>
      </Hidden>
      <Hidden lgUp>
        <StyledGrid item height="0px" position="relative" width="60px">
          <EarImage
            height={120}
            style={{
              left: "-20px",
              position: "absolute",
              top: "-40px"
            }}
            width={100}
          />
        </StyledGrid>

        <StyledGrid container direction="column" item marginLeft="80px">
          <StyledP
            color={color.BLACK}
            fontSize={fontSize.medium}
            aria-label="listening-mobile"
          >
            <strong>{firstName}</strong>, we&#39;re listening!
          </StyledP>

          <StyledP color={color.BLACK} aria-label="go-to-survey-mobile">
            <strong>Take Survey (2 min) &gt;</strong>
          </StyledP>
        </StyledGrid>
      </Hidden>

      <StyledGrid item position="absolute" right="5px" top="5px">
        <IconButton
          aria-label="close-notification"
          onClick={event => {
            event.stopPropagation();
            dismiss();
          }}
        >
          <CloseIcon
            stroke={color.WHITE}
            height={iconSizes.smaller}
            width={iconSizes.smaller}
          />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default SurveyBanner;
