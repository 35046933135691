import React from "react";
import styled from "styled-components";

import { color, spacingDefaults, breakpoints } from "style/constants";

interface Container {
  relativePosition?: boolean;
}

interface ProgressBar {
  activeStep: number;
  steps: number;
  variant: "steps" | "solid";
}

type ProgressBarProps = ProgressBar & Container;

interface Step {
  value: number;
}

const Container = styled.div<Container>`
  left: 0;
  position: fixed;
  top: 65px;
  width: 100%;
  z-index: 1100;
  @media (min-width: ${breakpoints.lg}px) {
    top: 80px;
    ${props =>
      props.relativePosition &&
      `
        position: relative;
        z-index: auto;
        top: auto;
        max-width: 520px;
    `}
  }
`;

const Step = styled.div<Step>`
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  background: linear-gradient(
    90deg,
    ${color.BLUE} ${props => props.value}%,
    ${color.CARDGRAY} 0%
  );
` as React.FC<Step>;

const StepsGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  > * {
    flex: 1;
    &:not(:first-child) {
      margin-left: ${spacingDefaults.normal};
      @media (max-width: ${breakpoints.lg}px) {
        margin-left: ${spacingDefaults.xsmall};
      }
    }
  }
`;

const ProgressBar: React.FC<ProgressBarProps> = ({
  activeStep,
  steps = 1,
  variant,
  relativePosition
}: ProgressBarProps) => {
  return (
    <Container
      className="progress-bar"
      role="progressbar"
      aria-valuenow={activeStep}
      aria-valuemin={0}
      aria-valuemax={steps}
      relativePosition={relativePosition}
    >
      {variant === "steps" && (
        <StepsGroup>
          {[...new Array(steps)].map((_, index) => (
            <Step key={index} value={index < activeStep ? 100 : 0} />
          ))}
        </StepsGroup>
      )}
      {steps !== 0 && variant === "solid" && (
        <Step value={(activeStep / steps) * 100} />
      )}
    </Container>
  );
};

export default ProgressBar;
