import React from "react";
import { Box } from "@material-ui/core";

import {
  addOpacity,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import {
  PillarName,
  SelectedSubFactor
} from "components/pages/Index/CycleSummary/types";
import StyledGrid from "../StyledGrid";
import { getFactorColor } from "../BHIChart/radar";

interface BarChartProps {
  labels: string[];
  scores: number[];
  selectedFactor: PillarName;
  selectedSubFactor: SelectedSubFactor;
}

interface StyledBarProps {
  disabled: boolean;
  score: number;
  selectedFactor: PillarName;
}

const StyledBar = ({ disabled, score, selectedFactor }: StyledBarProps) => {
  return (
    <StyledGrid item width="100%">
      <StyledGrid backgroundColor={color.BLUEGRAY} borderRadius="16px">
        <StyledGrid
          borderRadius="16px"
          height="16px"
          backgroundColor={
            addOpacity(
              getFactorColor(selectedFactor),
              disabled ? 0.2 : 1
            ) as color
          }
          width={`${score || 0}%`}
        />
      </StyledGrid>
    </StyledGrid>
  );
};

const BarChart: React.FC<BarChartProps> = ({
  labels,
  scores,
  selectedFactor,
  selectedSubFactor
}) => {
  return (
    <StyledGrid container>
      {labels.map((item, idx) => {
        const labelsToInvertScore = [
          "Anxiety",
          "Depression",
          "Stress",
          "Sleep"
        ];
        const isOverallSubFactor = selectedSubFactor.index === 0;
        const isDisabled = item !== selectedSubFactor.name;
        return (
          <StyledGrid
            container
            direction="column"
            key={idx}
            margin={`${spacingDefaults.xsmall} 0`}
            textAlign="left"
          >
            <StyledGrid item>
              <Box
                color={
                  isOverallSubFactor
                    ? color.BLACK
                    : isDisabled
                    ? color.PALEGRAY
                    : color.BLACK
                }
                fontFamily={fontFamily.primary}
              >
                {item}
              </Box>
            </StyledGrid>
            <StyledBar
              disabled={isOverallSubFactor ? false : isDisabled}
              score={
                labelsToInvertScore.includes(item)
                  ? 100 - scores[idx]
                  : scores[idx]
              }
              selectedFactor={selectedFactor}
            />
          </StyledGrid>
        );
      })}
    </StyledGrid>
  );
};

export default BarChart;
