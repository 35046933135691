import { gql } from "apollo-boost";

export const DismissUserSurvey = gql`
  mutation DismissUserSurvey($input: DimissUserSurveyInput!) {
    dismissUserSurvey(input: $input) {
      success
    }
  }
`;

export const GetUserSurvey = gql`
  query GetUserSurvey($id: String!) {
    getUserSurvey(id: $id) {
      id
      survey {
        id
        name
        hideConfetti
        questions {
          id
          order
          internalName
          questionType
          questionData {
            ... on DropdownAndTextQuestion {
              time
              subtext
              skip
              prompt
              dropdownAndTextChoices: choices
              textDescription
            }
            ... on DropdownQuestion {
              time
              subtext
              skip
              prompt
              dropdownChoices: choices
            }
            ... on FlashWordsQuestion {
              time
              subtext
              skip
              wordLists
              wordInterval
            }
            ... on FreeResponseQuestion {
              time
              subtext
              skip
              prompt
            }
            ... on HourMinuteQuestion {
              time
              subtext
              skip
              prompt
              hourMinuteHourChoices: hourChoices
              hourMinuteMinuteChoices: minuteChoices
              label
              secondaryLabel
            }
            ... on ImageThemesQuestion {
              time
              skip
              images
              subtext
            }
            ... on InformationQuestion {
              time
              subtext
              skip
              image
              text
            }
            ... on LikertQuestion {
              time
              subtext
              skip
              prompt
              likertResponses
              reverse
            }
            ... on MultipleResponseQuestion {
              time
              subtext
              skip
              prompt
              exclusive
              multiResponseChoices
            }
            ... on MultipleChoiceQuestion {
              time
              subtext
              skip
              prompt
              choices
            }
            ... on NumericalScaleQuestion {
              time
              subtext
              skip
              label
              min
              max
              prompt
            }
            ... on PassageQuestion {
              time
              subtext
              skip
              prompt
              passages
            }
            ... on ProverbQuestion {
              time
              subtext
              skip
              proverbList
            }
            ... on TextResponseQuestion {
              additionalInfo
              time
              subtext
              skip
              label
              prompt
              type
            }
            ... on WordCallbackQuestion {
              time
              skip
              prompt
              subtext
            }
          }
        }
      }
      answers {
        id
      }
    }
  }
`;

export const GetCurrentUserSurvey = gql`
  query GetCurrentUserSurvey {
    getCurrentUserSurvey {
      id
      survey {
        id
        name
        hideConfetti
        questions {
          id
          order
          internalName
          questionType
          questionData {
            ... on DropdownAndTextQuestion {
              time
              subtext
              skip
              prompt
              dropdownAndTextChoices: choices
              textDescription
            }
            ... on DropdownQuestion {
              time
              subtext
              skip
              prompt
              dropdownChoices: choices
            }
            ... on FlashWordsQuestion {
              time
              subtext
              skip
              wordLists
              wordInterval
            }
            ... on FreeResponseQuestion {
              time
              subtext
              skip
              prompt
            }
            ... on HourMinuteQuestion {
              time
              subtext
              skip
              prompt
              hourMinuteHourChoices: hourChoices
              hourMinuteMinuteChoices: minuteChoices
              label
              secondaryLabel
            }
            ... on ImageThemesQuestion {
              time
              skip
              images
              subtext
            }
            ... on InformationQuestion {
              time
              subtext
              skip
              image
              text
            }
            ... on LikertQuestion {
              time
              subtext
              skip
              prompt
              likertResponses
              reverse
            }
            ... on MultipleResponseQuestion {
              time
              subtext
              skip
              exclusive
              prompt
              multiResponseChoices
            }
            ... on MultipleChoiceQuestion {
              time
              subtext
              skip
              prompt
              choices
            }
            ... on NumericalScaleQuestion {
              time
              subtext
              skip
              label
              min
              max
              prompt
            }
            ... on PassageQuestion {
              time
              subtext
              skip
              prompt
              passages
            }
            ... on ProverbQuestion {
              time
              subtext
              skip
              proverbList
            }
            ... on TextResponseQuestion {
              additionalInfo
              time
              subtext
              skip
              label
              prompt
              type
            }
            ... on WordCallbackQuestion {
              time
              skip
              prompt
              subtext
            }
          }
        }
      }
    }
  }
`;

export const GetUserReflectionSurvey = gql`
  query GetUserReflectionSurvey {
    getUserReflectionSurvey {
      id
      survey {
        id
      }
    }
  }
`;

export const SubmitUserSurvey = gql`
  mutation SubmitUserSurvey($input: UserSurveyAnswersInput!) {
    submitUserSurvey(input: $input) {
      success
      coinsAwarded {
        title
        coinsAwarded
      }
      isCompletedAssessment
    }
  }
`;

export const SubmitUserFeedback = gql`
  mutation SubmitUserFeedback($input: UserSurveyAnswersInput!) {
    submitUserFeedback(input: $input) {
      success
    }
  }
`;
