import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { showErrorNotification } from "state";
import { Box, Divider } from "@material-ui/core";
import { fontSize, spacingDefaults } from "style/constants";
import StyledGrid from "components/simple/StyledGrid";
import {
  JourneyDrawerStateProps,
  UserStreakDetailForPeriodData,
  UserStreakDetailItem
} from "models/userStreak";
import { UserStreakDetailsForPeriod } from "graphql/userStreak/userStreak.gql";
import OrnamentIcon from "components/simple/Ornament";
import { Button } from "components/simple";
import { StyledCompletedStreakTask } from "./styled";
import { useHistory } from "react-router";
import { endOfDay, startOfDay } from "date-fns";

const StreakItemJourneyDayState: React.FC<JourneyDrawerStateProps> = ({
  currentDate = new Date(),
  userStreak,
  closeDrawer
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userStreakDetails, setUserStreakDetails] = useState<
    UserStreakDetailItem[]
  >();
  const [totalCoins, setTotalCoins] = useState<Number>(0);
  const [hasStreakData, setHasStreakData] = useState<boolean>(false);
  const { loading } = useQuery<UserStreakDetailForPeriodData>(
    UserStreakDetailsForPeriod,
    {
      variables: {
        input: {
          periodStart: startOfDay(currentDate),
          periodEnd: endOfDay(currentDate)
        }
      },
      fetchPolicy: "cache-and-network",
      onCompleted: data => {
        if (data && userStreak) {
          const streakDetailData = data.userStreakDetailsForPeriod;
          setTotalCoins(streakDetailData.totalCoins);
          setUserStreakDetails(streakDetailData.userStreakDetails);

          const hasStreakData =
            (!userStreak.freeDayPassApplied ?? false) &&
            ((userStreak.habitCompleted ||
              userStreak.trainingCompleted ||
              userStreak.resourceViewed) ??
              false);
          setHasStreakData(hasStreakData);
        }
      },
      onError: () =>
        dispatch(
          showErrorNotification("", "Unable to pull journey data for today.")
        )
    }
  );

  if (loading || !hasStreakData) return null;

  return (
    <StyledGrid container justifyContent="center">
      {userStreakDetails?.map((detail: UserStreakDetailItem, index: number) => (
        <StyledGrid key={index} container>
          <StyledCompletedStreakTask container>
            <Box fontSize={fontSize.medium}>{detail.title}</Box>
            <StyledGrid item>
              <StyledGrid container style={{ gap: spacingDefaults.xsmall }}>
                <Box>{detail.count}</Box>
                <OrnamentIcon ornament={detail.ornament} isCompleted={false} />
              </StyledGrid>
            </StyledGrid>

            <StyledGrid container justifyContent="space-between">
              <Box fontStyle="italic">Coins Earned</Box>
              <StyledGrid item>{detail.coinsEarned}</StyledGrid>
            </StyledGrid>
          </StyledCompletedStreakTask>
          <StyledGrid
            padding={`${spacingDefaults.medium} ${spacingDefaults.xlarge}`}
            width="100%"
          >
            <Divider />
          </StyledGrid>
        </StyledGrid>
      ))}

      <StyledGrid
        container
        justifyContent="center"
        style={{ gap: spacingDefaults.large }}
        width="80%"
      >
        <Button
          style={{
            // button background opacity is 40% while text opacity is 100%
            backgroundColor: "rgba(255, 202, 40, 0.4)",
            color: "#000000",
            padding: `${spacingDefaults.normal} ${spacingDefaults.large}`
          }}
          width="100%"
        >
          <StyledGrid
            container
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontStyle="#000000">Total Coins</Box>
            <Box>{totalCoins} Coins</Box>
          </StyledGrid>
        </Button>

        <Button
          inverted
          onClick={() => {
            history.push("/stats");
            closeDrawer();
          }}
        >
          See All Stats
        </Button>
      </StyledGrid>
    </StyledGrid>
  );
};

export default StreakItemJourneyDayState;
