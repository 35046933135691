import { Box, CircularProgress, Grid, Hidden } from "@material-ui/core";
import React from "react";

import { Button, StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { useAppState } from "../../../state";
import SettingsButton from "../../Buttons/SettingsButton/SettingsButton";
import ToggleAudioButton from "../../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import { Steps } from "../PreJoinScreens";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({
  name,
  roomName
}: DeviceSelectionScreenProps) {
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting
  } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <StyledGrid
        height="100%"
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Box component="p" fontSize={fontSize.medium} color={color.WHITE}>
            Joining Video Call
          </Box>
        </div>
      </StyledGrid>
    );
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item lg={6} xs={12}>
          <Box position="relative">
            <LocalVideoPreview identity={name} />

            <Hidden smDown>
              <StyledGrid container position="absolute" bottom="0" zIndex="1">
                <StyledGrid item xs />

                <StyledGrid item>
                  <ToggleAudioButton disabled={disableButtons} />
                  <ToggleVideoButton disabled={disableButtons} />
                </StyledGrid>

                <StyledGrid container item justifyContent="flex-end" xs>
                  <SettingsButton />
                </StyledGrid>
              </StyledGrid>
            </Hidden>
          </Box>

          <Hidden mdUp>
            <StyledGrid
              container
              justifyContent="center"
              marginTop={spacingDefaults.normal}
            >
              <ToggleAudioButton disabled={disableButtons} />
              <ToggleVideoButton disabled={disableButtons} />
              <SettingsButton />
            </StyledGrid>
          </Hidden>
        </Grid>

        <Grid
          container
          item
          lg={6}
          xs={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box component="p" fontSize={fontSize.large} color={color.WHITE}>
            Everything Look Good?
          </Box>

          <Button
            squared
            data-cy-join-now
            onClick={handleJoin}
            disabled={disableButtons}
          >
            Join Video Call
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
