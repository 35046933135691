interface RouteComponent {
  path: string;
}

interface RoutesConfig {
  login: RouteComponent;
  register: RouteComponent;
  verify: RouteComponent;
  confirm: RouteComponent;
  forgotPassword: RouteComponent;
  resetPassword: RouteComponent;
  updateEmail: RouteComponent;
  badges: RouteComponent;
  dashboard: RouteComponent;
  myjourney: RouteComponent;
  index: RouteComponent;
  resources: {
    path: string;
    article: RouteComponent;
  };
  training: {
    path: string;
    challenges: RouteComponent;
    brainHq: RouteComponent;
    brainHqAssessment: RouteComponent;
  };
  newTrainingComponents: {
    path: string;
  };
  faq: RouteComponent;
  scheduling: RouteComponent;
  coachingConfirmation: RouteComponent;
  imagingConfirmation: RouteComponent;
  groupCoachingConfirmation: RouteComponent;
  profile: RouteComponent;
  notificationPreferences: RouteComponent;
  googleFit: RouteComponent;
  screener: {
    path: string;
    qualification: RouteComponent;
  };
  waitlist: {
    path: string;
  };
  consent: {
    path: string;
    qualify: RouteComponent;
    disqualify: RouteComponent;
  };
  onboardingVideo: RouteComponent;
  coachingSessions: {
    path: string;
  };
  assessments: {
    basePath: string;
    start: RouteComponent;
    intro: RouteComponent;
    group: RouteComponent;
    assessment: RouteComponent;
    partComplete: RouteComponent;
    complete: RouteComponent;
  };
  assessmentOverview: RouteComponent;
  surveys: {
    basePath: string;
    survey: RouteComponent;
    complete: RouteComponent;
  };
  pdf: RouteComponent;
  oauth: {
    basePath: string;
    callback: RouteComponent;
  };
  dietid: RouteComponent;
  brainGauge: RouteComponent;
  feedback: RouteComponent;
  courses: {
    course: RouteComponent;
    section: RouteComponent;
    unit: RouteComponent;
  };
  habitSetup: {
    path: string;
    trackYourHabits: RouteComponent;
    selectedHabit: RouteComponent;
  };
  habitSurvey: { path: string; complete: RouteComponent };
  resourceSurvey: { path: string; complete: RouteComponent };
  stats: RouteComponent;
  ranks: RouteComponent;
}

const routesConfig: RoutesConfig = {
  login: {
    path: "/login"
  },
  register: {
    path: "/register"
  },
  verify: {
    path: "/verify"
  },
  confirm: {
    path: "/confirm"
  },
  forgotPassword: {
    path: "/forgot_password"
  },
  resetPassword: {
    path: "/reset"
  },
  updateEmail: {
    path: "/update_email"
  },
  badges: {
    path: "/badges"
  },
  dashboard: {
    path: "/dashboard"
  },
  myjourney: {
    path: "/myjourney"
  },
  index: {
    path: "/index"
  },
  resources: {
    path: "/resources",
    article: {
      path: "/resources/:article/:id"
    }
  },
  training: {
    path: "/training",
    challenges: {
      path: "/training/challenges"
    },
    brainHq: {
      path: "/training/brain_hq"
    },
    brainHqAssessment: {
      path: "/training/brain_hq_assessment"
    }
  },
  newTrainingComponents: {
    path: "/new-training-components"
  },
  faq: {
    path: "/faq"
  },
  profile: {
    path: "/profile"
  },
  notificationPreferences: {
    path: "/notification_preferences"
  },
  googleFit: {
    path: "/google-fit"
  },
  screener: {
    path: "/screener",
    qualification: {
      path: "/screener/qualification"
    }
  },
  waitlist: {
    path: "/waitlist"
  },
  consent: {
    path: "/consent",
    qualify: {
      path: "/consent/qualify"
    },
    disqualify: {
      path: "/consent/disqualify"
    }
  },
  onboardingVideo: {
    path: "onboarding-video"
  },
  coachingSessions: {
    path: "/coaching-sessions/:coachingSessionId"
  },
  assessments: {
    basePath: "assessments",
    start: {
      path: "/assessments/start"
    },
    intro: {
      path: "/assessments/intro"
    },
    group: {
      path: "/assessments/group"
    },
    assessment: {
      path: "/assessments/assessment"
    },
    partComplete: {
      path: "/assessments/part/complete"
    },
    complete: {
      path: "/assessments/complete"
    }
  },
  assessmentOverview: {
    path: "/assessment-overview"
  },
  scheduling: {
    path: "/scheduling"
  },
  coachingConfirmation: {
    path: "/coachingConfirmation"
  },
  imagingConfirmation: {
    path: "/imagingConfirmation"
  },
  groupCoachingConfirmation: {
    path: "/groupCoachingConfirmation"
  },
  surveys: {
    basePath: "surveys",
    survey: {
      path: "/surveys/survey"
    },
    complete: {
      path: "/surveys/complete"
    }
  },
  pdf: {
    path: "/pdf"
  },
  oauth: {
    basePath: "oauth",
    callback: {
      path: "/oauth/callback"
    }
  },
  dietid: {
    path: "/dietid"
  },
  feedback: {
    path: "/feedback"
  },
  brainGauge: {
    path: "/braingauge"
  },
  courses: {
    course: {
      path: "/course"
    },
    section: {
      path: "/course/:courseTitle"
    },
    unit: {
      path: "/course/:courseTitle/:sectionTitle"
    }
  },
  habitSetup: {
    path: "/habit-setup",
    trackYourHabits: {
      path: "/track-your-habits"
    },
    selectedHabit: {
      path: "/track-your-habits/:habitTitle"
    }
  },
  habitSurvey: {
    path: "/habit-survey",
    complete: {
      path: "/habit-survey/complete"
    }
  },
  resourceSurvey: {
    path: "/resource-survey",
    complete: {
      path: "/resource-survey/complete"
    }
  },
  stats: {
    path: "/stats"
  },
  ranks: {
    path: "/ranks"
  }
};

export default routesConfig;
