import { Checkbox, Tooltip, useMediaQuery } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { Field, FormikProps } from "formik";
import React from "react";

import {
  Button,
  DropdownSelect,
  FormPhoneInput,
  StyledGrid,
  TextField
} from "components/simple";
import { RegisterFormValues } from "models/user";
import { breakpoints, spacingDefaults } from "style/constants";
import { StyledLabel } from "./styled";
import { StyledLink, TermsCondition } from "./styled";
import { orgResult } from "./index";
import { useHistory } from "react-router";

type FormProps = FormikProps<RegisterFormValues>;

const states = [
  { ["Alabama"]: "Alabama" },
  { ["Alaska"]: "Alaska" },
  { ["Arizona"]: "Arizona" },
  { ["Arkansas"]: "Arkansas" },
  { ["California"]: "California" },
  { ["Colorado"]: "Colorado" },
  { ["Connecticut"]: "Connecticut" },
  { ["Delaware"]: "Delaware" },
  { ["Florida"]: "Florida" },
  { ["Georgia"]: "Georgia" },
  { ["Hawaii"]: "Hawaii" },
  { ["Idaho"]: "Idaho" },
  { ["Illinois"]: "Illinois" },
  { ["Indiana"]: "Indiana" },
  { ["Iowa"]: "Iowa" },
  { ["Kansas"]: "Kansas" },
  { ["Kentucky"]: "Kentucky" },
  { ["Louisiana"]: "Louisiana" },
  { ["Maine"]: "Maine" },
  { ["Maryland"]: "Maryland" },
  { ["Massachusetts"]: "Massachusetts" },
  { ["Michigan"]: "Michigan" },
  { ["Minnesota"]: "Minnesota" },
  { ["Mississippi"]: "Mississippi" },
  { ["Missouri"]: "Missouri" },
  { ["Montana"]: "Montana" },
  { ["Nebraska"]: "Nebraska" },
  { ["Nevada"]: "Nevada" },
  { ["New Hampshire"]: "New Hampshire" },
  { ["New Jersey"]: "New Jersey" },
  { ["New Mexico"]: "New Mexico" },
  { ["New York"]: "New York" },
  { ["North Carolina"]: "North Carolina" },
  { ["North Dakota"]: "North Dakota" },
  { ["Ohio"]: "Ohio" },
  { ["Oklahoma"]: "Oklahoma" },
  { ["Oregon"]: "Oregon" },
  { ["Pennsylvania"]: "Pennsylvania" },
  { ["Rhode Island"]: "Rhode Island" },
  { ["South Carolina"]: "South Carolina" },
  { ["South Dakota"]: "South Dakota" },
  { ["Tennessee"]: "Tennessee" },
  { ["Texas"]: "Texas" },
  { ["Utah"]: "Utah" },
  { ["Vermont"]: "Vermont" },
  { ["Virginia"]: "Virginia" },
  { ["Washington"]: "Washington" },
  { ["West Virginia"]: "West Virginia" },
  { ["Wisconsin"]: "Wisconsin" },
  { ["Wyoming"]: "Wyoming" }
];

function determineError(
  value: string | undefined,
  error: string | undefined
): boolean {
  return !!error && value?.length !== 0;
}

function determineSuccess(
  value: string | undefined,
  error: string | undefined
): boolean {
  return value?.length !== 0 && !error;
}

function determineHelperText(
  field: string | undefined,
  error: string | undefined
): string {
  if (error && field?.length !== 0) {
    return error;
  }
  const message = field?.length !== 0 && "Perfect!";
  return message || "";
}

const Form: React.FC<FormProps> = ({
  values: {
    firstName,
    lastName,
    nickname,
    organizationId,
    email,
    phone,
    smsNotification,
    ngUnitState,
    password,
    confirmPassword,
    isMtec
  },
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldValue,
  errors,
  isValid,
  isSubmitting
}: FormProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();
  const location = history.location.search;
  const hasOrgLink = location.includes("organization_id");

  return (
    <form onSubmit={handleSubmit}>
      <StyledGrid
        container
        direction="column"
        marginTop="30px"
        maxWidth="422px"
        mobileMaxWidth="100%"
      >
        <StyledGrid container direction="column" item spacing={2}>
          <StyledGrid item>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={firstName}
            />
          </StyledGrid>
          <StyledGrid item>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={lastName}
            />
          </StyledGrid>
          <StyledGrid item>
            <TextField
              fullWidth
              label="Nickname (Optional)"
              name="nickname"
              onBlur={handleBlur}
              onChange={handleChange}
              value={nickname}
            />
          </StyledGrid>
          <StyledGrid item>
            <TextField
              error={determineError(email, errors.email)}
              fullWidth
              helperText={determineHelperText(email, errors.email)}
              label="Email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              success={determineSuccess(email, errors.email)}
              value={email}
            />
          </StyledGrid>
          <StyledGrid item>
            <Field
              className="registration"
              component={FormPhoneInput}
              label="Phone Number (Optional)"
              name="phone"
              onChange={(e: React.ChangeEvent<any>) => {
                if (!e.target.value) {
                  setFieldValue("smsNotification", false);
                }

                handleChange(e);
              }}
              value={phone}
            />
            {phone && (
              <StyledGrid
                alignItems="center"
                container
                item
                marginTop={spacingDefaults.xsmall}
              >
                <StyledGrid item>
                  <StyledLabel>
                    <Checkbox
                      checked={smsNotification}
                      name="smsNotification"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    Enable SMS Notifications
                  </StyledLabel>
                </StyledGrid>
                <StyledGrid
                  item
                  marginLeft={spacingDefaults.xsmall}
                  marginTop="5px"
                >
                  <Tooltip title="Enable to get real-time updates on your Brain Health Index progress.">
                    <HelpOutline color="secondary" aria-label="Information" />
                  </Tooltip>
                </StyledGrid>
              </StyledGrid>
            )}
          </StyledGrid>
          {isMtec && (
            <StyledGrid item>
              <DropdownSelect
                fullWidth
                callback={() => {}}
                defaultText="NG Unit State *"
                name="ngUnitState"
                onBlur={handleBlur}
                onChange={handleChange}
                options={states}
                required={true}
                value={ngUnitState}
              />
            </StyledGrid>
          )}
          {!hasOrgLink && (
            <StyledGrid item>
              <TextField
                error={determineError(organizationId, errors.organizationId)}
                fullWidth
                helperText={determineHelperText(
                  organizationId,
                  errors.organizationId
                )}
                label="Organization (Optional)"
                name="organizationId"
                onBlur={handleBlur}
                onChange={handleChange}
                success={determineSuccess(
                  organizationId,
                  errors.organizationId
                )}
                value={
                  determineSuccess(
                    organizationId?.toUpperCase(),
                    errors.organizationId?.toUpperCase()
                  )
                    ? orgResult?.registrationCode?.toUpperCase() ||
                      orgResult?.displayName?.toUpperCase() ||
                      organizationId?.toUpperCase() //new code instead of displayname
                    : organizationId
                }
              />
            </StyledGrid>
          )}
          <StyledGrid item>
            <TextField
              error={determineError(password, errors.password)}
              fullWidth
              helperText={determineHelperText(password, errors.password)}
              label="Password"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              success={determineSuccess(password, errors.password)}
              type="password"
              value={password}
            />
          </StyledGrid>
          <StyledGrid item>
            <TextField
              error={determineError(confirmPassword, errors.confirmPassword)}
              fullWidth
              helperText={determineHelperText(
                confirmPassword,
                errors.confirmPassword
              )}
              label="Confirm Password"
              name="confirmPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              success={determineSuccess(
                confirmPassword,
                errors.confirmPassword
              )}
              type="password"
              value={confirmPassword}
            />
          </StyledGrid>
          <StyledGrid
            alignItems={mobile ? "stretch" : "center"}
            container
            direction={mobile ? "column" : "row"}
            item
            spacing={mobile ? 1 : 2}
          >
            <StyledGrid item lg={5} xs={12}>
              <Button
                name="submit-button"
                disabled={!isValid || isSubmitting}
                fullWidth
                longText
                type="submit"
              >
                Create account
              </Button>
            </StyledGrid>
            {/* <StyledGrid item lg={6} xs={12}>
              <TermsCondition>
                By creating an account I accept Center for
                BrainHealth&apos;s&nbsp;
                <StyledLink href="https://centerforbrainhealth.org/training/terms-of-service">
                  Terms &amp; conditions
                </StyledLink>
              </TermsCondition>
            </StyledGrid> */}
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </form>
  );
};

export default Form;
