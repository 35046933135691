import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import styled from "styled-components";

import { color } from "style/constants";

interface StyledButton {
  background?: string;
  backgroundImage?: string;
  borderNone?: boolean;
  borderRadius?: string;
  compressed?: boolean;
  disabled?: boolean;
  icon?: boolean;
  inverted?: boolean;
  jumbo?: boolean;
  longText?: boolean;
  plain?: boolean;
  squared?: boolean;
  stickyFooter?: boolean;
  shadow?: boolean;
  mini?: boolean;
  miniIcon?: boolean;
  textColor?: string;
  wide?: boolean;
  width?: string;
  zIndex?: number;
  warning?: boolean;
}

// Extending our custom type with material props
type StyledButtonProps = StyledButton & ButtonProps;

const backgroundImageStyle = `
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const compressedStyle = `
  height: 32px;
  line-height: 0;
`;

const disabledStyle = `
  background-color: ${color.GRAY};
  color: ${color.BLACK};
`;

const iconStyle = `
&& {
  border-radius: 50px;
  height: 50px;
  min-width: unset;
  padding: 0;
  span > * {
    margin-left: 1rem;
  }
}
`;

const invertedStyle = `
  background-color: ${color.WHITE};
  border: 1px solid ${color.BLUE};
  color: ${color.DARKBLUE};
  &:hover {
    background-color: ${color.WHITE};
    color: ${color.DARKBLUE};
  }
`;

const jumboStyle = `
  border-radius: 0px;
  padding: 20px 98px;
  height: 60px;
  width: 375px;
`;

const plainStyle = `
  background-color: unset !important;
  color: ${color.BLACK};
  height: 100%;
  padding: 0;
  "&:hover": {
    backgroundColor: unset !important;
    color: ${color.BLACK};
  }
`;

const stickyFooterStyle = `
  border-radius: 0px;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const warningStyle = `
  background-color: ${color.RED};
  color: ${color.WHITE};
`;

const miniStyle = `
background-color: ${color.WHITE};
color:#323232
height: 14px;
width: 25px;
min-height:14px
min-width:25px
border: 0.8px solid #2F3243
border-radius: 9.2px;
padding: 1px 3px 1px 3px;
:hover {
  background-color: unset;
  color: unset;
}
`;

const miniIconStyle = `
  background-color: rgba(0, 0, 0, 0);
  font-size:11px;
  padding:3px
  color: #323232;
  border: 0;
  height: 14px;
  width: 14px;
  min-height:14px;
  min-width:14px;
  :hover {
    background-color: unset;
    color: unset;
  }
`;

// Destructuring props passed to styled Material UI component to avoid console warnings for props passed directly to underlying DOM element
const StyledButton = styled(
  ({
    background,
    backgroundImage,
    borderNone,
    borderRadius,
    color,
    compressed,
    icon,
    inverted,
    jumbo,
    // removes left and right padding to accomodate long text
    longText,
    plain,
    squared,
    stickyFooter,
    shadow,
    textColor,
    warning,
    mini,
    miniIcon,
    wide,
    width,
    zIndex,
    ...otherProps
  }) => <Button {...otherProps} />
)`
  && {
    ${props => props.backgroundImage && backgroundImageStyle}
    ${props => props.compressed && compressedStyle}
    ${props => props.disabled && disabledStyle}
    ${props => props.icon && iconStyle}
    ${props => props.inverted && invertedStyle}
    ${props => props.jumbo && jumboStyle}
    ${props => props.plain && plainStyle}
    ${props => props.stickyFooter && stickyFooterStyle}
    ${props => props.warning && warningStyle}
    ${props => props.mini && miniStyle}
    ${props => props.miniIcon && miniIconStyle}
    ${props =>
      props.backgroundImage &&
      'background-image: url("' + props.backgroundImage + '");'}
    ${props => props.shadow && "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"}
    background-color: ${props => props.background};
    border-radius: ${props => props.squared && "5px"};
    border-radius: ${props => props.borderRadius};
    ${props => props.borderNone && "border: none"}
    color: ${props => props.textColor};
    padding: ${props => props.wide && "14px 88px"};
    padding: ${props => props.longText && "14px 0"};
    width: ${props => props.width};
    z-index: ${props => props.zIndex || 100};
    text-transform: none;
    &:hover {
      background-color: ${props => props.background};
      color: ${props => props.textColor};
    }
  }
` as React.FC<StyledButtonProps>;

export default StyledButton;
