import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { format, isSameDay } from "date-fns";

import { StyledGrid, Button } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import GroupSessionImage from "assets/groupcoachingjoin.svg";
import { UpcomingSession } from "..";
import { canJoinVideoCall } from "../../../utils";
import { MarkParticipantAsAttended } from "graphql/groupCoaching/groupCoaching.gql";

interface UpcomingSessionProps {
  session: UpcomingSession;
  index: number;
}

const UpcomingGroupSession: React.FC<UpcomingSessionProps> = ({
  session,
  index
}: UpcomingSessionProps) => {
  const [showJoinCall, setShowJoinCall] = useState(canJoinVideoCall(session));
  const date = format(new Date(session.sessionTime), "MMMM d, yyyy");
  const time = format(new Date(session.sessionTime), "p");
  const timezoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const [markParticipantAsAttended] = useMutation(MarkParticipantAsAttended, {
    variables: { input: { groupCoachingSessionsId: session.id } }
  });

  useEffect(() => {
    if (isSameDay(new Date(), new Date(session.sessionTime))) {
      const handle = setInterval(() => {
        setShowJoinCall(canJoinVideoCall(session));
      }, 1000 * 30); // Check every 30 seconds.

      return () => clearInterval(handle);
    }
  }, [session]);

  const groupZoom =
    process.env.REACT_APP_GROUP_SESSION_LINK ||
    "https://centerforbrainhealth.zoom.us/j/91393692612";

  const message = `You have a group coaching session scheduled at ${time} ${timezoneAbbreviation} on ${date}.  A button to join will appear here 30 minutes before the start.`;

  return (
    <StyledGrid
      item
      key={index}
      lg={6}
      paddingBottom={spacingDefaults.normal}
      paddingRight={spacingDefaults.normal}
      xs={12}
    >
      <StyledGrid
        alignItems="center"
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        container
        height="100%"
        justifyContent="center"
        key={index}
        marginTop={spacingDefaults.xsmall}
        padding={spacingDefaults.large}
      >
        <StyledGrid item md={4}>
          <img
            alt="GroupSessionImage"
            src={GroupSessionImage}
            style={{ maxHeight: "200px" }}
            width="100%"
          />
        </StyledGrid>
        <StyledGrid
          item
          md={8}
          paddingLeft={spacingDefaults.normal}
          paddingBottom={showJoinCall ? "3px" : "0"}
        >
          <Box
            color={color.GRAPHITE}
            fontSize={fontSize.normal}
            component="p"
            margin="0"
          >
            {message}
          </Box>
          {showJoinCall && (
            <Box margin={`${spacingDefaults.normal} 0 0 0`}>
              <Button
                aria-label="go-to-group-coaching-call"
                longText
                fullWidth
                onClick={() => {
                  window.open(groupZoom, "_blank");
                  markParticipantAsAttended();
                }}
              >
                Join Group Call
              </Button>
            </Box>
          )}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default UpcomingGroupSession;
