import React, { useState } from "react";
import { Hidden } from "@material-ui/core";
import track from "react-tracking";

import { ReactComponent as ChevronRight } from "assets/chevron-right.svg";
import playButton from "assets/consent-video-play-button.png";
import { Button, StyledGrid } from "components/simple";
import { color, iconSizes } from "style/constants";
import routesConfig from "utils/routesConfig";

import {
  PlayButton,
  Timestamp,
  TimeText,
  Title,
  Wrapper,
  VideoWrapper
} from "./styled";

const url: string = routesConfig.onboardingVideo.path;

const OnboardingVideo: React.FC = () => {
  const [showVideo, setShowVideo] = useState(false);

  function toggleVideo(): void {
    setShowVideo(true);
  }

  return (
    <StyledGrid
      centerContent
      container
      direction="column"
      fillRemainingHeight
      marginTop="-5%"
    >
      <StyledGrid container item>
        <Hidden mdDown>
          <Title>Welcome to the BrainHealth Dashboard</Title>
        </Hidden>
      </StyledGrid>
      {showVideo ? (
        <StyledGrid container item>
          <Wrapper>
            <StyledGrid container item>
              <Hidden lgUp>
                <Title>Welcome to the BrainHealth Dashboard</Title>
              </Hidden>
            </StyledGrid>
            <StyledGrid container item>
              <VideoWrapper>
                <iframe
                  title="consent-video"
                  src="https://videos.ctfassets.net/n7r832fbv3mz/5UlAz7gKYwdKBmXc7BQdSz/226224257a8a1faa3a2d7fd9ad6fec10/Consent.mp4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  aria-label="consent video"
                ></iframe>
              </VideoWrapper>
            </StyledGrid>
          </Wrapper>
        </StyledGrid>
      ) : (
        <StyledGrid container direction="column">
          <Wrapper>
            <StyledGrid container item>
              <Hidden lgUp>
                <Title>Welcome to the BrainHealth Dashboard</Title>
              </Hidden>
            </StyledGrid>
            <PlayButton
              src={playButton}
              alt="play button"
              aria-label="Play"
              onClick={() => toggleVideo()}
            />
            <Timestamp>
              <TimeText>0:45 sec</TimeText>
            </Timestamp>
          </Wrapper>
        </StyledGrid>
      )}
      <StyledGrid container item>
        <Hidden lgUp>
          <Button
            stickyFooter
            background={color.BLACK}
            fullWidth
            href="/dashboard"
            aria-label="Skip Video"
          >
            Skip Video
          </Button>
        </Hidden>
      </StyledGrid>
      <Hidden mdDown>
        <StyledGrid container marginTop=".5rem" item>
          <Button icon plain aria-label="Skip Video" href="/dashboard">
            Skip Video
            <ChevronRight stroke={color.BLACK} height={iconSizes.small} />
          </Button>
        </StyledGrid>
      </Hidden>
    </StyledGrid>
  );
};

export default track({
  url
})(OnboardingVideo);
