import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Box, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { hideLoader, showErrorNotification, showLoader } from "state";

import GroupCoaching from "assets/groupcoachinggrid.svg";
import { BackButton, Button, StyledGrid } from "components/simple";
import {
  AddParticipantToGroupCoachingSession,
  GetGroupCoachingCalendarEvents
} from "graphql/groupCoaching/groupCoaching.gql";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { format } from "date-fns";

interface GroupCoachingConfirmationParams {
  sessionId: string;
}

const GroupCoachingConfirmation: React.FC = () => {
  const appHistory = useHistory();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const { sessionId } = useParams<GroupCoachingConfirmationParams>();
  const [sessionTime, setSessionTime] = useState<Date>(new Date());

  const [participantConfirmed, setParticipantConfirmed] = useState(false);

  const [outlookEvent, setOutlookEvent] = useState<string>("");
  const [iCalEvent, setiCalEvent] = useState<string>("");
  const [googleEvent, setGoogleEvent] = useState<string>("");

  const [addParticipantMutation] = useMutation(
    AddParticipantToGroupCoachingSession
  );

  const [getCoachingCalendarEvents] = useLazyQuery(
    GetGroupCoachingCalendarEvents,
    {
      variables: {
        input: {
          groupCoachingSessionsId: sessionId
        }
      },
      onCompleted(data) {
        const {
          groupCoachingSession: {
            sessionTime,
            googleCalendarLink,
            icsFileDetails,
            icalFileDetails
          }
        } = data;

        setSessionTime(new Date(sessionTime));
        setGoogleEvent(googleCalendarLink);
        setOutlookEvent(icsFileDetails);
        setiCalEvent(icalFileDetails);
      },

      onError(error) {
        dispatch(
          showErrorNotification(
            "Error",
            (error as any)?.graphQLErrors[0]?.message
          )
        );
      }
    }
  );

  useEffect(() => {
    dispatch(showLoader());

    let isSubscribed = true;
    const addParticipant = async () => {
      try {
        const response = await addParticipantMutation({
          variables: {
            input: { groupCoachingSessionsId: sessionId }
          }
        });
        if (!response?.data?.addParticipantToGroupCoachingSession?.success) {
          throw new Error("GroupCoachingRsvpError");
        }
        setParticipantConfirmed(true);
      } catch (error) {
        appHistory.replace("/dashboard");
        dispatch(hideLoader());
        dispatch(
          showErrorNotification(
            "Unable to confirm for group coaching session.",
            (error as any)?.graphQLErrors[0]?.message
          )
        );
        isSubscribed && setParticipantConfirmed(false);
      }

      if (isSubscribed) {
        dispatch(hideLoader());
      }
    };

    addParticipant();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (participantConfirmed) {
      getCoachingCalendarEvents();
    }
  }, [participantConfirmed]);

  const timezoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  return (
    <>
      {participantConfirmed && (
        <StyledGrid
          backgroundColor={color.WHITE}
          container
          direction="column"
          fillRemainingHeight
        >
          <BackButton location="/dashboard" />
          <StyledGrid
            alignItems="center"
            alignSelf="center"
            container
            direction="column"
            item
            marginTop={mobile ? "96px" : "161px"}
            maxWidth="768px"
            padding={mobile ? spacingDefaults.normal : undefined}
            xs={12}
          >
            <img src={GroupCoaching} height={mobile ? "200px" : "300px"} />
            <Box
              component="h1"
              fontFamily={fontFamily.secondary}
              fontSize="30px"
              fontWeight="400"
              letterSpacing="-0.45px"
              lineHeight="41px"
              margin={`${spacingDefaults.large} 0 0 0`}
              textAlign="center"
            >
              Your video group coaching session is confirmed!
            </Box>
            <Box
              color={color.DARKERGRAY}
              component="p"
              fontSize={fontSize.medium}
              lineHeight="28px"
              margin={`${spacingDefaults.normal} 0 0 0`}
              textAlign="center"
            >
              Your group coaching session is scheduled for
              <strong>
                {" "}
                {format(sessionTime, "MMMM d")} at{" "}
                {format(sessionTime, "h:mm aa")} {timezoneAbbreviation}
              </strong>
              . A button to join the session will appear on your dashboard 30
              minutes before the session is scheduled to start.
            </Box>
            <StyledGrid
              container
              direction="row"
              item
              justifyContent="space-evenly"
              margin={"1rem 0"}
              marginTop="2rem"
              textAlign="center"
            >
              <StyledGrid
                item
                lg={4}
                margin={`${spacingDefaults.xsmall} 0`}
                xs={12}
              >
                <Button
                  onClick={() => downloadIcsFile(outlookEvent)}
                  width="225px"
                >
                  Add to Outlook
                </Button>
              </StyledGrid>
              <StyledGrid
                item
                lg={4}
                margin={`${spacingDefaults.xsmall} 0`}
                xs={12}
              >
                <Button
                  background={color.PINK}
                  onClick={() => downloadIcsFile(iCalEvent)}
                  width="225px"
                >
                  Add to iCal
                </Button>
              </StyledGrid>
              <StyledGrid
                item
                lg={4}
                margin={`${spacingDefaults.xsmall} 0`}
                xs={12}
              >
                <Button
                  background={color.GREEN}
                  longText
                  onClick={() => window.open(googleEvent, "_blank")}
                  width="225px"
                >
                  Add to Google Calendar
                </Button>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );

  function downloadIcsFile(input: string): void {
    window.open("data:text/calendar;charset=utf8," + encodeURIComponent(input));
  }
};

export default GroupCoachingConfirmation;
