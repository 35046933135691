import { format, isAfter, isSameMonth, isSameWeek, isToday } from "date-fns";

export const formatWeekRange = (periodStart: Date, periodEnd: Date): string => {
  const endDay = format(periodEnd, "d");
  isSameMonth(periodStart, periodEnd);
  const dateRangeFormatted = `${format(periodStart, "MMM d")} - ${
    isSameMonth(periodStart, periodEnd) ? endDay : format(periodEnd, "MMM d")
  }`;

  return dateRangeFormatted;
};
