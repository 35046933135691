import { IconButton, useMediaQuery } from "@material-ui/core";
import React from "react";

import assessment from "assets/assessment.svg";
import { ReactComponent as Close } from "assets/close.svg";
import { StyledGrid, StyledP } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  iconSizes,
  spacingDefaults
} from "style/constants";

interface AssessmentsResetBannerProps {
  dismiss(): Promise<void>;
}

const AssessmentsResetBanner: React.FC<AssessmentsResetBannerProps> = ({
  dismiss
}: AssessmentsResetBannerProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid
      container
      backgroundColor={color.WHITE}
      borderRadius="8px"
      alignContent="center"
      direction="column"
      position="relative"
      alignItems="center"
      itemMargin={spacingDefaults.xsmall}
      padding={spacingDefaults.xsmall}
      maxHeight={mobile ? "none" : "135px"}
      justifyContent={mobile ? "center" : "flex-start"}
      textAlign={mobile ? "center" : "left"}
      marginBottom="2rem"
      mobileMarginBottom="0"
    >
      <StyledGrid item margin={mobile ? undefined : "-25px 25px 0 0"}>
        <img src={assessment} alt="" />
      </StyledGrid>
      <div>
        <StyledP
          fontFamily={fontFamily.secondary}
          fontSize={fontSize.large}
          letterSpacing="-0.5px"
          margin="0.5rem 0"
        >
          Your assessments have been reset.
        </StyledP>
        <StyledP
          color={color.DARKERGRAY}
          margin={"0.5rem 0"}
          width={mobile ? undefined : "700px"}
        >
          The two-week window to take your assessments ended before you were
          able to complete them, so they have been reset. You can start again at
          any time!
        </StyledP>
      </div>
      <StyledGrid
        item
        position={"absolute"}
        style={{ top: "0em", right: "0em" }}
      >
        <IconButton aria-label="close-notification" onClick={dismiss}>
          <Close height={iconSizes.smaller} width={iconSizes.smaller} />
        </IconButton>
      </StyledGrid>
    </StyledGrid>
  );
};

export default AssessmentsResetBanner;
