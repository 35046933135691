import { useMutation } from "@apollo/react-hooks";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import track from "react-tracking";
import * as Yup from "yup";

import { ReactComponent as Icon } from "assets/forgot-password.svg";
import { StyledGrid } from "components/simple";
import { ResetPassword } from "graphql/user/user.gql";
import { ResetPasswordFormValues } from "models/user";
import { showErrorNotification } from "state";
import { breakpoints, color } from "style/constants";
import routesConfig from "utils/routesConfig";
import { ResetForm } from "./Forms";
import { Callout, Spacer, Title } from "./styled";

const initialFormValues = {
  newPassword: "",
  confirmPassword: ""
};

const url: string = routesConfig.resetPassword.path;

const PasswordReset: React.FC<RouteComponentProps> = ({
  location
}: RouteComponentProps) => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [resetPassword] = useMutation(ResetPassword);

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword")],
      "Passwords must match"
    )
  });

  const sessionToken = location.search.split("=")[1];
  if (!sessionToken) {
    return <Redirect to="/login" />;
  }

  const submitForm = async (
    values: ResetPasswordFormValues,
    helpers: FormikHelpers<ResetPasswordFormValues>
  ): Promise<null> => {
    const { newPassword } = values;
    try {
      const res = await resetPassword({
        variables: {
          token: sessionToken,
          newPassword: newPassword
        }
      });
      if (res.data && res.data.resetPassword && res.data.resetPassword.token) {
        localStorage.setItem("token", res.data.resetPassword.token);
        window.location.assign("/dashboard");
        window.location.search = "";
      }
      if (
        res.data &&
        res.data.resetPassword &&
        res.data.resetPassword.token === null
      ) {
        dispatch(
          showErrorNotification(
            "Please verify your email address",
            'Check your email inbox and click the link that reads "BrainHealth® dashboard"'
          )
        );
      }
    } catch (error) {
      if (error?.graphQLErrors[0]?.extensions?.code === "BAD_PASSWORD") {
        helpers.setFieldError(
          "newPassword",
          "You have used this password before, please try another one"
        );
      } else {
        dispatch(showErrorNotification("", "Magic link may have expired"));
      }
      return null;
    } finally {
      helpers.setSubmitting(false);
    }
    return null;
  };

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      container
      direction="column"
      fillRemainingHeight
    >
      <StyledGrid
        container
        centerContent={!mobile}
        direction={mobile ? "column" : "row"}
        maxWidth={mobile ? "75%" : "1086px"}
        spacing={mobile ? 2 : 10}
        margin="auto"
        fillRemainingHeight
      >
        <StyledGrid
          alignSelf="center"
          item
          lg={6}
          maxWidth={mobile ? "80%" : "470px"}
          xs={12}
        >
          <Icon width="100%" height="100%" />
        </StyledGrid>
        <Hidden mdDown>
          <StyledGrid>
            <Spacer />
          </StyledGrid>
        </Hidden>
        <StyledGrid
          container
          direction="column"
          item
          lg={6}
          xs={12}
          justifyContent="center"
          textAlign={mobile ? "center" : "left"}
          spacing={3}
        >
          <StyledGrid item>
            <Title>Forgot Password</Title>
            <Callout>Enter and confirm your new password.</Callout>
          </StyledGrid>
          <StyledGrid item>
            <Formik
              component={ResetForm}
              initialValues={initialFormValues}
              onSubmit={submitForm}
              validationSchema={ResetPasswordSchema}
              validateOnBlur
            />
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default track({
  url
})(PasswordReset);
