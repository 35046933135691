import React from "react";
import styled from "styled-components";
import { breakpoints, fontFamily } from "style/constants";

export const Word = styled.h1`
  font-size: 80px;
  font-weight: 500;
  font-family: ${fontFamily.primary};
  @media (max-width: ${breakpoints.lg}px) {
    font-size: 48px;
  }
`;

export const Instructions = styled.p`
  font-size: 16px;
  margin: 0px;
  @media (max-width: ${breakpoints.lg}px) {
    font-size: 14px;
  }
`;

interface WordCaseProps {
  bold?: boolean;
  children?: string[] | JSX.Element;
}

export const WordCase: React.FC<WordCaseProps> = styled(
  ({ ...props }: WordCaseProps) => <Instructions {...props} />
)`
  && {
    ${props => props.bold && `font-weight: 700`};
    font-size: ${props => (props.bold ? "22px" : "20px")};
    @media (max-width: ${breakpoints.lg}px) {
      font-size: ${props => (props.bold ? "18px" : "16px")};
    }
  }
`;
