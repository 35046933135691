/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Hidden } from "@material-ui/core";
import { get, cloneDeep } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Prompt, Redirect, useHistory, useParams } from "react-router-dom";
import track, { useTracking } from "react-tracking";
import { ReactComponent as ChevronLeft } from "assets/chevron-left.svg";
import { Button, ProgressBar, Question, StyledGrid } from "components/simple";
import { UserSurvey } from "graphql/types/UserSurvey";
import {
  GetCurrentUserSurvey,
  GetUserSurvey,
  SubmitUserSurvey
} from "graphql/userSurveys/userSurveys.gql";
import { spacingDefaults } from "style/constants";
import routesConfig from "utils/routesConfig";
import { DashboardQuery, GetSubHeaderData } from "graphql/user/user.gql";
import { Dashboard, UserAssessment } from "graphql/types/Dashboard";

const url: string = routesConfig.surveys.survey.path;

const Questions: React.FC = () => {
  const history = useHistory();
  const tracking = useTracking();
  const { surveyId, userSurveyId } = useParams<{
    surveyId: "";
    userSurveyId: "";
  }>();
  const surveyQuery = surveyId ? GetUserSurvey : GetCurrentUserSurvey;
  const variables = surveyId ? { id: surveyId } : undefined;

  const { data } = useQuery(surveyQuery, {
    variables,
    fetchPolicy: "no-cache"
  });

  const [submitUserSurvey] = useMutation(SubmitUserSurvey, {
    refetchQueries: [{ query: GetSubHeaderData }]
  });

  const userSurvey: UserSurvey = get(
    data,
    surveyId && userSurveyId ? "getUserSurvey" : "getCurrentUserSurvey"
  );
  const surveyName = get(userSurvey, "survey.name", "");
  const isScreenerSurvey = surveyName === "Screener Survey (v3)";
  const surveyQuestions = get(userSurvey, "survey.questions", []);
  surveyQuestions.sort((a: any, b: any) => a.order - b.order);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<any, any>[]>([]);
  const [skipTo, setSkipTo] = useState<string>("");

  const allowsExplicitSkip = useMemo(_allowsExplicitSkip, [
    surveyQuestions,
    currentQuestionIndex
  ]);
  const skipText = useMemo(_getSkipText, [
    surveyQuestions,
    currentQuestionIndex
  ]);

  useEffect(_handleBeforeUnloadEvent, []);
  useEffect(_trackStartedSurveyEvent, [tracking, surveyName]);
  useEffect(_handleComplete, [userSurvey, currentQuestionIndex]);
  useEffect(_handleSkip, [currentQuestionIndex, skipTo]);

  if (data?.getCurrentUserSurvey === null) {
    return <Redirect to={routesConfig.dashboard.path} />;
  }

  if (
    skipTo &&
    surveyQuestions[currentQuestionIndex]?.internalName !== skipTo
  ) {
    return null;
  }

  return (
    <>
      <Prompt
        message="quit-survey"
        when={currentQuestionIndex !== surveyQuestions.length}
      />

      <ProgressBar
        activeStep={currentQuestionIndex}
        steps={surveyQuestions.length}
        variant="solid"
      />

      <StyledGrid
        container
        item
        marginLeft={spacingDefaults.xlarge}
        mobileMarginLeft="0"
        mobileTop="90px"
        position="fixed"
        top="120px"
      >
        <Button plain onClick={_handleBackClick}>
          <ChevronLeft />

          <StyledGrid item marginLeft={spacingDefaults.normal}>
            <Hidden mdDown>Back</Hidden>
          </StyledGrid>
        </Button>
      </StyledGrid>

      {surveyQuestions[currentQuestionIndex] && (
        <Question
          assessmentName={""}
          question={surveyQuestions[currentQuestionIndex]}
          activeQuestion={currentQuestionIndex}
          answers={answers}
          updateAnswers={_updateAnswers}
          skipCallback={
            allowsExplicitSkip
              ? () =>
                  setSkipTo(
                    surveyQuestions[currentQuestionIndex].questionData.skip
                      .asNotApplicable
                  )
              : undefined
          }
          skipText={skipText}
        />
      )}
    </>
  );

  /* Internal */

  function _handleBackClick(): void {
    if (currentQuestionIndex === 0) {
      history.push(routesConfig.dashboard.path);
      return;
    }

    let lastActiveQuestionIndex = currentQuestionIndex - 1;

    while (
      get(answers[lastActiveQuestionIndex], "answerData.response") === "N/A"
    ) {
      lastActiveQuestionIndex--;
    }

    setCurrentQuestionIndex(lastActiveQuestionIndex);
  }

  function _updateAnswers(answerData: Record<any, any>): void {
    tracking.trackEvent({
      url,
      actions: [
        "Surveys",
        answerData.response === "N/A"
          ? "Skip Survey Question"
          : "Answer Survey Question",
        surveyQuestions[currentQuestionIndex].internalName
      ]
    });
    const answer = {
      questionId: surveyQuestions[currentQuestionIndex].id,
      answerData
    };

    const updatedAnswers = cloneDeep(answers);
    updatedAnswers[currentQuestionIndex] = answer;

    setAnswers(updatedAnswers);

    // NOTE - Handle skipping based on response.

    const questionData = surveyQuestions[currentQuestionIndex].questionData;

    if (
      questionData.skip?.byAnswer &&
      _shouldSkip(questionData.skip.byAnswer, answerData.response)
    ) {
      setSkipTo(_getSkipTo(questionData.skip.byAnswer, answerData.response));
    }

    // NOTE - Move to the next question.

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  }

  function _shouldSkip(
    skipMap: Record<string, string>,
    response: any
  ): boolean {
    let result = false;

    if (Array.isArray(response)) {
      result = response.every((x: string) => !!skipMap[x]);
    } else {
      result = !!skipMap[response];
    }

    return result;
  }

  function _getSkipTo(skipMap: Record<string, string>, response: any): string {
    let result = "";

    if (Array.isArray(response)) {
      // NOTE - We are assuming each response maps to the same skip. We don't
      // support multiple_response with different skips.

      result = skipMap[response[0]];
    } else {
      result = skipMap[response];
    }

    return result;
  }

  /* Memos */

  function _allowsExplicitSkip(): boolean {
    return !!surveyQuestions[currentQuestionIndex]?.questionData?.skip
      ?.asNotApplicable;
  }

  function _getSkipText(): string | undefined {
    return surveyQuestions[currentQuestionIndex]?.questionData?.skip?.skipText;
  }

  /* Effects */

  function _handleBeforeUnloadEvent(): () => void {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "Changes will be lost!";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }

  function _trackStartedSurveyEvent(): void {
    if (tracking && surveyName) {
      tracking.trackEvent({
        url,
        actions: ["Surveys", "Start Survey", surveyName]
      });
    }
  }

  function _handleSkip(): void {
    if (!skipTo || !surveyQuestions[currentQuestionIndex]) {
      return;
    }

    if (surveyQuestions[currentQuestionIndex].internalName === skipTo) {
      setSkipTo("");
    } else {
      _updateAnswers({ response: "N/A" });
    }
  }

  function _handleComplete(): void {
    if (!userSurvey || currentQuestionIndex !== surveyQuestions.length) {
      return;
    }

    tracking.trackEvent({
      url,
      actions: ["Surveys", "Complete Survey", surveyName]
    });

    (async function () {
      const { data } = await submitUserSurvey({
        variables: {
          input: {
            userSurveyId: surveyId ? userSurveyId : userSurvey.id,
            answers
          }
        }
      });

      if (isScreenerSurvey) {
        history.push(routesConfig.screener.qualification.path);
      } else {
        history.push(routesConfig.surveys.complete.path, {
          hideConfetti: get(userSurvey, "survey.hideConfetti", false),
          coinsAwarded: data?.submitUserSurvey?.coinsAwarded,
          isCompletedAssessment: data?.submitUserSurvey?.isCompletedAssessment
        });
      }
    })();
  }
};

export default track({
  url
})(Questions);
