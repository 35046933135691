import React from "react";

import { StyledGrid, StyledP } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";

import { Facebook, LinkedIn, Twitter } from "@material-ui/icons";
import { Divider, IconButton } from "@material-ui/core";
import { useApolloClient } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import {
  handleLinkedInClick,
  handleFacebookClick,
  handleTwitterClick
} from "./util";
import { showErrorNotification } from "state";

interface BasicBadge {
  badgeImageKey: string;
  displayName: string;
  width?: string;
  referralLink: string;
}

const BasicBadge: React.FC<BasicBadge> = ({
  badgeImageKey,
  displayName,
  width,
  referralLink
}: BasicBadge) => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  return (
    <StyledGrid
      alignItems="center"
      container
      centerContent
      direction="column"
      height="100%"
    >
      <StyledGrid item>
        <img
          src={badgeImageKey}
          alt={`${displayName} badge`}
          style={{ maxHeight: "90px" }}
        />
      </StyledGrid>

      <StyledGrid item>
        <StyledP
          color={color.BLACK}
          fontWeight="500"
          fontSize={fontSize.xsmall}
          margin="0"
          width={width}
          textAlign="center"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {displayName}
        </StyledP>
        <StyledGrid container justifyContent="center">
          <IconButton
            size="small"
            onClick={() => {
              try {
                handleLinkedInClick(client, badgeImageKey, referralLink);
              } catch (error) {
                dispatch(showErrorNotification("", error));
              }
            }}
          >
            <LinkedIn />
          </IconButton>

          <IconButton
            size="small"
            onClick={() => handleFacebookClick(referralLink)}
          >
            <Facebook />
          </IconButton>

          <IconButton
            size="small"
            onClick={() => handleTwitterClick(referralLink)}
          >
            <Twitter />
          </IconButton>
          <StyledGrid
            padding={`${spacingDefaults.medium} ${spacingDefaults.xlarge}`}
            width="100%"
          >
            <Divider />
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default BasicBadge;
