import React from "react";

import { color } from "style/constants";
import { ReactComponent as SkateboardGuy } from "assets/Group.svg";
import { StyledGrid, Heading, StyledP, Loading } from "components/simple";
import { fontSize } from "style/constants";
import { Button } from "components/simple";
import { Redirect, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GetOrganizationScreenerInfo } from "graphql/user/user.gql";

const Intro: React.FC = () => {
  const history = useHistory();
  const { loading, data } = useQuery(GetOrganizationScreenerInfo, {
    fetchPolicy: "no-cache"
  });

  const shouldHideScreener = data?.me?.organization?.hideScreener;
  if (shouldHideScreener) history.push("/screener/qualification");

  if (loading) return <Loading />;

  return (
    <StyledGrid backgroundColor={color.WHITE} container fillRemainingHeight>
      <StyledGrid
        alignItems="center"
        centerContent
        container
        direction="column"
        margin="0 auto"
        maxWidth="620px"
        mobileMaxWidth="90%"
        mobilePadding="2rem 0"
        spacing={2}
      >
        <StyledGrid item>
          <SkateboardGuy />
        </StyledGrid>
        <StyledGrid item>
          <Heading tag="h1" fontSize={fontSize.header}>
            Research Screener
          </Heading>
        </StyledGrid>
        <StyledGrid item>
          <StyledP textAlign="center">
            The BrainHealth Project is a research study, so we need to ask you a
            few questions to determine if you are eligible to participate. All
            your answers are secure and confidential and will only be used to
            determine eligibility.
          </StyledP>
        </StyledGrid>
        <StyledGrid item>
          <Button
            onClick={() => {
              const url = `/surveys/survey`;
              history.push(url);
            }}
          >
            Continue
          </Button>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Intro;
