import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";

import {
  AssessmentCard,
  Button,
  DatePicker,
  DropdownSelect,
  StyledGrid,
  TextField,
  Timer
} from "components/simple";
import { breakpoints } from "style/constants";

interface TextQuestionProps {
  additionalInfo?: string | string[];
  activeQuestion?: number;
  answer?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  callback: React.Dispatch<any>;
  label: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options?: Record<any, any>[];
  placeholder?: string;
  question: string;
  subtext?: string;
  time?: number;
  type: "number" | "text" | "select" | "long" | "date";
  skipCallback?: React.Dispatch<React.SetStateAction<string>>;
  validator?: (input: string) => boolean;
  skipText?: string;
}

interface ResponseFieldProps {
  answer: string | Date | null;
  error: boolean;
  label: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  options?: Record<any, any>[];
  placeholder?: string;
  setAnswer: React.Dispatch<string>;
  setError: React.Dispatch<boolean>;
  type: string;
  timesUp?: boolean;
  validator?(input: string): boolean;
}

const ResponseField: React.FC<ResponseFieldProps> = ({
  answer,
  error,
  placeholder,
  label,
  options,
  setAnswer,
  setError,
  timesUp,
  type,
  validator
}: ResponseFieldProps) => {
  switch (type) {
    case "select":
      return (
        <DropdownSelect
          callback={setAnswer}
          defaultText={label}
          options={options || []}
        />
      );
    case "long":
      return (
        <TextField
          id={`${label}-input`}
          error={error}
          fullWidth
          multiline
          onChange={e => {
            if (validator) {
              setError(!validator(e.currentTarget.value));
            }
            setAnswer(e.currentTarget.value);
          }}
          placeholder={label}
          rows={4}
          variant={"outlined"}
          value={answer}
          disabled={timesUp}
        />
      );
    case "date":
      return (
        <DatePicker
          id={`${label}-input`}
          label={label}
          format={"MM/dd/yyyy"}
          name={"date"}
          InputLabelProps={{ shrink: !!answer }}
          value={answer || null}
          onChange={(date, value) => {
            setAnswer(value as string);
          }}
          onError={err => {
            if (err) {
              setError(true);
            } else {
              setError(false);
            }
          }}
        />
      );
    case "number":
      return (
        <TextField
          id={`${label}-input`}
          error={error}
          fullWidth
          name={"number"}
          label={label}
          onChange={e => {
            if (validator) {
              setError(!validator(e.currentTarget.value));
            }
            setAnswer(e.currentTarget.value);
          }}
          type={type}
          helperText={
            answer && typeof answer === "string"
              ? parseInt(answer, 10) < 0
                ? "The value must be greater than 0."
                : ""
              : ""
          }
          value={answer}
          disabled={timesUp}
          placeholder={placeholder}
        />
      );
    default:
      return (
        <TextField
          id={`${label}-input`}
          error={error}
          fullWidth
          label={label}
          onChange={e => {
            if (validator) {
              setError(!validator(e.currentTarget.value));
            }
            setAnswer(e.currentTarget.value);
          }}
          type={type}
          value={answer}
          disabled={timesUp}
          placeholder={placeholder}
        />
      );
  }
};

const TextQuestion: React.FC<TextQuestionProps> = ({
  activeQuestion,
  additionalInfo,
  answer: previousAnswer,
  callback,
  label,
  options,
  placeholder,
  question,
  subtext,
  time,
  type,
  validator,
  skipCallback,
  skipText
}: TextQuestionProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [answer, setAnswer] = useState<string | Date | null>(
    previousAnswer || ""
  );
  const [error, setError] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [timesUp, setTimesUp] = useState(false);

  useEffect(() => {
    if (answer && !error) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [answer, error]);

  useEffect(() => {
    setTimesUp(false);
    setError(false);
    setAnswer(previousAnswer || "");
  }, [previousAnswer, question]);

  const handleClick = (): void => {
    callback({ response: answer });
  };

  return (
    <AssessmentCard
      additionalInfo={!timesUp ? additionalInfo : ""}
      question={timesUp ? "Time's Up" : question}
      subtext={timesUp ? "Your answers will be saved as they are." : subtext}
      timer={
        time && (
          <Timer
            seconds={time}
            onDone={setTimesUp}
            activeQuestion={activeQuestion}
          />
        )
      }
      skipCallback={skipCallback}
      skipText={skipText}
    >
      <StyledGrid
        item
        maxWidth={type === "select" ? "560px" : "435px"}
        width="100%"
        marginBottom="15.3125rem"
        marginTop="1rem"
      >
        <ResponseField
          answer={answer}
          error={error}
          label={label}
          options={options}
          setAnswer={setAnswer}
          setError={setError}
          type={type}
          validator={validator}
          timesUp={timesUp}
          placeholder={placeholder}
        />
      </StyledGrid>
      <StyledGrid item marginBottom="1.6875rem">
        <Button
          data-testid="next-btn"
          wide={!mobile}
          disabled={!timesUp && disabled}
          stickyFooter={mobile}
          fullWidth={mobile}
          onClick={handleClick}
          zIndex={100}
        >
          Next
        </Button>
      </StyledGrid>
    </AssessmentCard>
  );
};

export default TextQuestion;
