import { Grid, useMediaQuery } from "@material-ui/core";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { omit } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Button, FormPhoneInput, TextField } from "components/simple";
import client from "graphql/client";
import { CheckEmailUnique, UpdateProfile } from "graphql/user/user.gql";
import { ProfileFormValues } from "models/user";
import { fetchUser, showErrorNotification } from "state";
import { breakpoints } from "style/constants";
import { ProfileField } from "../styled";
import FormDatePicker from "./FormDatePicker";
import UpdateEmailModal from "./UpdateEmailModal";

interface ProfileFormProps {
  data: ProfileFormValues;
  refetchData: () => Promise<any>;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  data,
  refetchData,
  setInEditMode
}: ProfileFormProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [openModal, setOpenModal] = useState(false);
  const oldEmailAddress = data.email;
  const [newEmail, setNewEmail] = useState(oldEmailAddress);

  const schema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    nickname: Yup.string().ensure(),
    email: Yup.string()
      .email("Invalid email")
      .test(
        "is-duplicate-email",
        "This email already exists",
        async (email): Promise<boolean> => {
          if (!email) {
            return true;
          }
          try {
            const response = await client.query({
              query: CheckEmailUnique,
              variables: { email }
            });
            const valid = response.data.checkEmailUnique.valid;
            if (!valid && email === oldEmailAddress) {
              return true;
            }
            return valid;
          } catch {
            // if our backend service is down then just validate the email
            // will throw another error on submit
            return true;
          }
        }
      )
      .required(),
    phone: Yup.string().nullable().required("Invalid phone number"),
    dob: Yup.date()
      .nullable()
      .min(new Date(1900, 1, 1), "Invalid date.")
      .max(new Date(), "Invalid date.")
      .required("Invalid date. Try MM/DD/YYYY")
  });

  const dispatch = useDispatch();

  function dispatchUser(): void {
    dispatch(fetchUser());
  }

  return (
    <>
      <Formik
        initialValues={data}
        onSubmit={submitForm}
        validationSchema={schema}
        validateOnBlur
        validateOnMount
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleReset,
          handleSubmit,
          touched,
          values
        }: FormikProps<ProfileFormValues>) => (
          <Form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} lg={4}>
                <ProfileField>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    onBlur={handleBlur}
                    error={determineError(
                      values.firstName,
                      errors.firstName,
                      !!touched.firstName
                    )}
                    helperText={determineHelperText(
                      values.firstName,
                      errors.firstName
                    )}
                  />
                </ProfileField>
              </Grid>

              <Grid item xs={12} lg={4}>
                <ProfileField>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    onBlur={handleBlur}
                    error={determineError(
                      values.lastName,
                      errors.lastName,
                      !!touched.lastName
                    )}
                    helperText={determineHelperText(
                      values.lastName,
                      errors.lastName
                    )}
                  />
                </ProfileField>
              </Grid>

              <Grid item xs={12} lg={4}>
                <ProfileField>
                  <TextField
                    fullWidth
                    label="Nickname"
                    name="nickname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nickname || ""}
                  />
                </ProfileField>
              </Grid>

              <Grid item xs={12} lg={4}>
                <ProfileField>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                    error={determineError(
                      values.email,
                      errors.email,
                      !!touched.email
                    )}
                    helperText={determineHelperText(values.email, errors.email)}
                  />
                </ProfileField>
              </Grid>

              <Grid item xs={12} lg={4}>
                <ProfileField>
                  <Field
                    component={FormPhoneInput}
                    name="phone"
                    onChange={handleChange}
                    value={values.phone || ""}
                  />
                </ProfileField>
              </Grid>

              <Grid item xs={12} lg={4}>
                <ProfileField>
                  <Field
                    name="dob"
                    value={values.dob}
                    component={FormDatePicker}
                    onBlur={handleBlur}
                  />
                </ProfileField>
              </Grid>

              <Grid container item justifyContent="center" spacing={2} xs={12}>
                <Grid item>
                  <Button
                    inverted
                    onClick={() => {
                      handleReset();
                      setInEditMode(false);
                    }}
                    width={mobile ? "250px" : undefined}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button type="submit" width={mobile ? "250px" : undefined}>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <UpdateEmailModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        oldEmail={oldEmailAddress}
        newEmail={newEmail}
        turnOffEditMode={() => setInEditMode(false)}
        refetchUser={() => dispatchUser()}
      />
    </>
  );

  async function submitForm(
    values: ProfileFormValues,
    helpers: FormikHelpers<ProfileFormValues>
  ): Promise<any> {
    const emailChanged = oldEmailAddress !== values.email;
    if (emailChanged) {
      setNewEmail(values.email);
      setOpenModal(true);
    }
    const input = omit(values, "email");
    try {
      const response = await client.mutate({
        mutation: UpdateProfile,
        variables: {
          input
        }
      });

      if (response && !emailChanged) {
        setInEditMode(false);
      }
    } catch ({ graphQLErrors }) {
      const errProperty =
        graphQLErrors[0].extensions.exception.validationErrors[0].property;

      dispatch(
        showErrorNotification(
          "Uh oh, something went wrong",
          `Invalid value for ${errProperty}`
        )
      );

      if (errProperty === "phone") {
        helpers.setFieldError("phone", "Invalid phone number.");
      }

      return;
    } finally {
      helpers.setSubmitting(false);
    }

    helpers.setSubmitting(false);

    refetchData();
    if (!emailChanged) {
      dispatch(fetchUser());
    }
  }

  function determineError(
    value: string,
    error: string | undefined,
    touched: boolean
  ): boolean {
    if (!touched) {
      return false;
    }

    return !!error || !(value && value.length !== 0);
  }

  function determineHelperText(
    field: string,
    error: string | undefined
  ): string {
    if (error && field && field.length !== 0) {
      return error;
    }

    return "";
  }
};

export default ProfileForm;
