import React, { useState } from "react";
import { Link } from "react-router-dom";
import { camelCase, sortBy } from "lodash";

import { ReactComponent as DownloadIcon } from "assets/download_icon.svg";
import { FactorCard, Loading, StyledGrid, StyledP } from "components/simple";
import { appWidth, color, spacingDefaults } from "style/constants";
import { Footnote } from "../styled";
import {
  FactorInfo,
  NewCycle,
  PillarName,
  Scores,
  ScoreType,
  SelectedSubFactor,
  UserAssessmentsScore
} from "../../types";
import FactorCardHeader from "components/simple/FactorCard/FactorCardHeader";
import OverviewCard from "components/simple/OverviewCard";
import routesConfig from "utils/routesConfig";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import { useQuery } from "@apollo/react-hooks";
import { BHIChartType } from "components/simple/BHIChart";
import { getNewPillarScore } from "../..";

interface OverviewProps {
  cycles: NewCycle[];
  selectedCycleIndex: number;
  selectedFactor?: PillarName;
  factorInfo: FactorInfo[];
  stepIndex: number;
  chartType: BHIChartType;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setChartType: React.Dispatch<React.SetStateAction<BHIChartType>>;
  notes: string;
  notesDate: string;
}

export const getSubFactorScores = (
  cycle: NewCycle,
  subFactorList: string[],
  selectedFactor?: PillarName
) => {
  const subFactorData = sortBy(
    cycle.bhiRefactorPillarScores.find(
      pillar => pillar.pillarName === selectedFactor
    )?.bhiRefactorUserAssessments,
    [x => x.name]
  );

  const filteredSubFactorData: UserAssessmentsScore[] = [];
  subFactorData.map(sf => {
    switch (sf.name) {
      case "Mood":
        // stress / anxiety / depression is nested in Mood scores array
        sf.scores.map((s: Scores) => {
          if (
            s.scoreName &&
            s.scoreType === ScoreType.PERCENTILE &&
            subFactorList.find(
              x => x.toLowerCase() === s.scoreName.toLowerCase()
            )
          ) {
            const capitalize = (s: string) =>
              (s && s[0].toUpperCase() + s.slice(1)) || "";

            filteredSubFactorData.push({
              name: capitalize(s.scoreName),
              scores: [s]
            });
          }
        });
        break;
      case "Strategy":
      // No special handling needed as 'Aggregate' score result is
      // also the only singular 'percentile' score.  Fall through to default case.
      default:
        if (sf.name === subFactorList.find(x => x === sf.name)) {
          filteredSubFactorData.push(sf);
        }
        break;
    }
  });
  filteredSubFactorData.sort((a, b) => a.name.localeCompare(b.name));

  const scoresArr = filteredSubFactorData?.map(item => item.scores);

  // return average of PERCENTILE scores for
  // each sub factor
  const percentileScoreTotals =
    scoresArr?.flatMap(item => {
      const percentiles = item?.filter(
        x => x.scoreType === ScoreType.PERCENTILE
      );
      return (
        percentiles.reduce((acc, curr) => acc + curr.value, 0) /
        percentiles.length
      );
    }) || [];

  return percentileScoreTotals;
};

const Overview: React.FC<OverviewProps> = ({
  cycles,
  selectedCycleIndex,
  selectedFactor,
  factorInfo,
  stepIndex,
  chartType,
  setStepIndex,
  setChartType,
  notes,
  notesDate
}) => {
  const [selectedSubFactor, setSelectedSubFactor] = useState<SelectedSubFactor>(
    {
      name: "Overall",
      description: "Toggle through to see additional details",
      index: 0
    }
  );
  const [isPdfReportEnabled, setIsPdfReportEnabled] = useState<
    boolean | undefined
  >();

  const { loading } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "PdfScoreReport" } },
    onCompleted: data => setIsPdfReportEnabled(data?.isFeatureEnabled?.enabled)
  });

  const currentCycle = cycles[selectedCycleIndex];

  // Factor Scores
  const connectedness = getNewPillarScore(
    currentCycle,
    PillarName.CONNECTEDNESS
  );
  const clarity = getNewPillarScore(currentCycle, PillarName.CLARITY);
  const emotionalBalance = getNewPillarScore(
    currentCycle,
    PillarName.EMOTIONAL_BALANCE
  );

  const selectedFactorInfo = factorInfo.find(
    item => camelCase(item.name) === selectedFactor
  );

  const subFactorList = selectedFactorInfo?.subFactors?.map(x => x.name) || [];

  const getScores = () => {
    if (selectedFactor)
      return getSubFactorScores(currentCycle, subFactorList, selectedFactor);
    else return [emotionalBalance, connectedness, clarity];
  };

  const getRadarLabels = () => {
    if (selectedFactor) {
      return subFactorList;
    } else return ["Emotional Balance", "Connectedness", "Clarity"];
  };

  const getFactorScore = (factor?: PillarName): number => {
    switch (factor) {
      case PillarName.EMOTIONAL_BALANCE:
        return emotionalBalance;
      case PillarName.CONNECTEDNESS:
        return connectedness;
      case PillarName.CLARITY:
        return clarity;
      default:
        return 0;
    }
  };

  const subFactors = {
    name: selectedFactorInfo?.name || "",
    description: selectedFactorInfo?.description || "",
    subFactors: [
      {
        name: "Overall",
        description: "Toggle through to see additional details"
      },
      ...(sortBy(selectedFactorInfo?.subFactors, ["name"]) || [])
    ]
  };

  if (loading) return <Loading />;

  return (
    <StyledGrid
      alignItems="center"
      container
      maxWidth={appWidth.max}
      justifyContent="space-evenly"
      style={{ rowGap: "2rem" }}
    >
      <StyledGrid item xs={12}>
        {selectedFactor ? (
          <StyledGrid padding={`0 ${spacingDefaults.xsmall}`}>
            <FactorCardHeader
              score={getFactorScore(selectedFactor)}
              selectedFactor={selectedFactor}
            />
            <FactorCard
              factorInfo={subFactors}
              selectedFactor={selectedFactor}
              selectedSubFactor={selectedSubFactor}
              setSelectedSubFactor={setSelectedSubFactor}
              subFactorScores={getScores()}
            />
          </StyledGrid>
        ) : (
          <StyledGrid padding={spacingDefaults.xsmall}>
            {isPdfReportEnabled && (
              <StyledGrid container justifyContent="flex-end">
                <Link
                  to={{
                    pathname: routesConfig.pdf.path,
                    search: `?cycle=${selectedCycleIndex}`
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledGrid alignItems="center" container itemMargin="5px">
                    <DownloadIcon />
                    <StyledP color={color.BLACK}>
                      Download Latest Report
                    </StyledP>
                  </StyledGrid>
                </Link>
              </StyledGrid>
            )}
            <OverviewCard
              cycles={cycles}
              labels={getRadarLabels()}
              scores={getScores()}
              selectedCycleIndex={selectedCycleIndex}
              selectedFactor={selectedFactor}
              stepIndex={stepIndex}
              chartType={chartType}
              setStepIndex={setStepIndex}
              setChartType={setChartType}
              notes={notes}
              notesDate={notesDate}
            />
          </StyledGrid>
        )}
      </StyledGrid>
      <StyledGrid item xs={12}>
        <Footnote>
          This is your snapshot, a composite of the individual BrainHealth
          assessment scores shown above.
        </Footnote>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Overview;
