import React from "react";
import track from "react-tracking";

import Begin from "./Begin";
import Carousel from "./Begin/Carousel";
import routesConfig from "utils/routesConfig";

const url: string = routesConfig.assessments.intro.path;

const Intro: React.FC = () => {
  const [showCarousel, setShowCarousel] = React.useState(false);
  return (
    <>
      {!showCarousel && <Begin setShowCarousel={setShowCarousel} />}
      {showCarousel && <Carousel />}
    </>
  );
};

export default track({
  url
})(Intro);
