import { Box } from "@material-ui/core";
import { isMobile } from "lib/twilio-video/utils";
import React from "react";

import { spacingDefaults } from "style/constants";

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  return (
    <Box
      borderRadius="8px"
      height="100%"
      padding={spacingDefaults.large}
      flex={1}
      display="flex"
      minHeight={isMobile ? "672px" : undefined}
    >
      {props.children}
    </Box>
  );
};

export default IntroContainer;
