import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from "@material-ui/core";
import { endOfWeek, startOfWeek } from "date-fns";

import { JourneyDrawer, ProductTour } from "components/simple";
import { UserStreakForPeriod } from "graphql/userStreak/userStreak.gql";
import { UserStreak, UserStreakForPeriodData } from "models/userStreak";
import { showErrorNotification } from "state";
import { breakpoints, color } from "style/constants";
import MyJourneyFooter from "./MyJourneyFooter";
import MyJourneyHeader from "./MyJourneyHeader";
import { MyJourneyWeek } from "./MyJourneyWeek";
import { FindProductTourResponse } from "hooks/useProductTour";
import { FindProductTour } from "graphql/productTours/productTours.gql";

export interface MyJourneyProps {
  userCreatedAt?: Date;
  periodStart: Date;
  periodEnd: Date;
  setPeriodStart: React.Dispatch<React.SetStateAction<Date>>;
  setPeriodEnd: React.Dispatch<React.SetStateAction<Date>>;
}

const MyJourney: React.FC = () => {
  const dispatch = useDispatch();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [stepIndex, setStepIndex] = useState(0);
  const [runTour, setRunTour] = useState(false);

  const [periodStart, setPeriodStart] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [periodEnd, setPeriodEnd] = useState<Date>(
    endOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [weeklyStreak, setWeeklyStreak] = useState<UserStreak[]>();
  const [streakDataLoaded, setStreakDataLoaded] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [drawerOpen, setDrawerOpen] = useState(false);

  function openDrawer(date: Date) {
    setSelectedDate(date);
    setDrawerOpen(true);
    setTimeout(() => {
      setStepIndex(stepIndex + 1);
    }, 400);
  }

  useQuery<UserStreakForPeriodData>(UserStreakForPeriod, {
    variables: {
      input: {
        periodStart,
        periodEnd
      }
    },
    skip: !periodStart || !periodEnd,
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const streak = data?.userStreakForPeriod?.sort(
        (a: UserStreak, b: UserStreak) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      setWeeklyStreak(streak);
      setStreakDataLoaded(true);
    },
    onError: error =>
      dispatch(
        showErrorNotification(
          "Unable to pull journey data for period specified.",
          (error as any)?.graphQLErrors[0]?.message
        )
      )
  });

  const { data: findProductTourResponse } = useQuery<FindProductTourResponse>(
    FindProductTour,
    {
      fetchPolicy: "cache-and-network",
      variables: { input: { name: "My Journey Part 1" } }
    }
  );

  useEffect(() => {
    if (findProductTourResponse?.findProductTour?.id) {
      setRunTour(!!findProductTourResponse?.findProductTour?.id);
    }
  }, [findProductTourResponse]);

  function updateWeeklyStreak(updatedUserStreak: UserStreak) {
    const unchangedUserStreaks = weeklyStreak?.filter(
      ws => ws.date !== updatedUserStreak.date
    );
    if (unchangedUserStreaks) {
      const updatedWeeklyStreak = [
        ...unchangedUserStreaks,
        updatedUserStreak
      ].sort(
        (a: UserStreak, b: UserStreak) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
      setWeeklyStreak(updatedWeeklyStreak);
    }
  }

  return (
    <>
      <ProductTour
        productTourName="My Journey Part 2"
        run={runTour}
        stepIndex={stepIndex}
        setStepIndex={setStepIndex}
        stepMetadata={[
          {
            disableBeacon: true,
            event: "my-journey-step-1",
            hideFooter: true,
            placement: "bottom",
            spotlightClicks: true,
            target: ".journey-week > div:first-child > div:first-child"
          },
          {
            callback: () => {
              setDrawerOpen(false);
            },
            placement: mobile ? "top" : "left",
            target: ".journey-drawer-day"
          },
          {
            placement: "top",
            target: ".back-to-work",
            spotlightClicks: true
          }
        ]}
      />
      <div
        style={{
          minHeight: mobile ? "calc(100vh - 187px)" : "calc(100vh - 146px)",
          backgroundColor: color.WHITE
        }}
      >
        {streakDataLoaded && weeklyStreak && (
          <>
            <MyJourneyHeader
              userCreatedAt={weeklyStreak[0].userRegistrationDate}
              periodStart={periodStart}
              periodEnd={periodEnd}
              setPeriodStart={setPeriodStart}
              setPeriodEnd={setPeriodEnd}
            />
            {selectedDate && (
              <JourneyDrawer
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                inputDate={selectedDate}
                updateWeeklyStreak={updateWeeklyStreak}
              />
            )}
            <MyJourneyWeek
              weeklyStreak={weeklyStreak}
              openDrawer={openDrawer}
            />
            <MyJourneyFooter
              periodStart={periodStart}
              periodEnd={periodEnd}
              setPeriodStart={setPeriodStart}
              setPeriodEnd={setPeriodEnd}
            />
          </>
        )}
      </div>
    </>
  );
};
export default MyJourney;
