import { useMutation } from "@apollo/react-hooks";
import { parse } from "qs";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteProps, useHistory } from "react-router-dom";

import { ShareBadge, ShareReferral } from "graphql/social";
import { showErrorNotification, showSuccessNotification } from "state";
import routesConfig from "utils/routesConfig";

type OAuthContext = {
  action: string;
  data: any;
};

const Callback: React.FC<RouteProps> = ({ location }: RouteProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [shareBadge] = useMutation(ShareBadge);
  const [shareReferral] = useMutation(ShareReferral);

  useEffect(() => {
    (async () => {
      const searchParams = parse(location?.search ?? "", {
        ignoreQueryPrefix: true
      });

      const context = sessionStorage.getItem(searchParams.state);

      if (context) {
        sessionStorage.removeItem(searchParams.state);

        const { action, data }: OAuthContext = JSON.parse(context);

        switch (action) {
          case "shareBadge": {
            try {
              await shareBadge({
                variables: {
                  input: {
                    platform: data.platform,
                    authorizationCode: searchParams.code,
                    badgeImageKey: data.badgeImageKey,
                    referralLink: data.referralLink
                  }
                }
              });

              history.push(routesConfig.badges.path);
              dispatch(
                showSuccessNotification(
                  "Success!",
                  "We've created a post on your feed"
                )
              );
            } catch {
              history.push(routesConfig.dashboard.path);
              dispatch(showErrorNotification());
            }
            break;
          }
          case "shareReferral": {
            try {
              await shareReferral({
                variables: {
                  input: {
                    platform: data.platform,
                    authorizationCode: searchParams.code,
                    referralLink: data.referralLink
                  }
                }
              });

              history.push(routesConfig.profile.path);
              dispatch(
                showSuccessNotification(
                  "Success!",
                  "We've created a post on your feed"
                )
              );
            } catch {
              history.push(routesConfig.dashboard.path);
              dispatch(showErrorNotification());
            }
            break;
          }
        }
      } else {
        history.push(routesConfig.dashboard.path);
        dispatch(showErrorNotification());
      }
    })();
  }, [dispatch, history, location, shareBadge, shareReferral]);

  return null;
};

export default Callback;
