import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ChartOptions,
  ChartData,
  ChartDataset,
  CategoryScale,
  LinearScale,
  Plugin,
  PointElement,
  LineElement,
  Tooltip,
  ScriptableContext
} from "chart.js";
import { Line } from "react-chartjs-2";

import {
  NewCycle,
  PillarName
} from "components/pages/Index/CycleSummary/types";
import { addOpacity, color, fontFamily } from "style/constants";
import { LineWrapper } from "./styled";

interface LineProps {
  activeIndex: number;
  cycles: NewCycle[];
  pillars: Set<PillarName>;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

const DATA_POINTS_SHOWN = 5;

const getOptions = (max: number) => {
  /*
    max needs to be big enough such that the max
    score still has room to display tooltip above
  */
  const heightNeededForToolTip = 80;
  const adjustedMax = max + heightNeededForToolTip;

  const options: ChartOptions<"line"> = {
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        bodyFont: {
          family: fontFamily.primary,
          size: 12,
          style: "italic",
          lineHeight: "17px"
        },
        callbacks: {
          label: function (tooltip) {
            return parseFloat(tooltip.formattedValue).toFixed(1);
          },
          title: function () {
            return "";
          }
        },
        cornerRadius: 15,
        displayColors: false,
        padding: {
          bottom: 8,
          left: 12,
          right: 12,
          top: 8
        },
        xAlign: "center",
        yAlign: "bottom"
      }
    },
    elements: {
      line: {
        borderWidth: 1
      },
      point: {
        hoverRadius: 8
      }
    },
    scales: {
      x: {
        grid: {
          borderColor: color.BLACK,
          borderWidth: 2,
          display: false
        },
        ticks: {
          font: {
            family: fontFamily.primary,
            lineHeight: "17px",
            size: 12,
            style: "italic"
          }
        }
      },
      y: {
        min: 0,
        max: adjustedMax,
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }
    }
  };
  return options;
};

const getPillarScores = (pillarName: PillarName, cycles: NewCycle[]) => {
  return cycles.map(
    cycle =>
      cycle.bhiRefactorPillarScores.find(
        pillar => pillar.pillarName == pillarName
      )?.value || 0
  );
};

const getPlugins = (activeIndex: number, total: number) => {
  const verticalLinePlugin: Plugin<"line"> = {
    id: "vertical_line",
    beforeDraw: chart => {
      const points = chart.getDatasetMeta(0).data;
      const ctx = chart.canvas.getContext("2d");
      const minY = chart.scales["y"].bottom;
      if (ctx) {
        const activePoint = points[activeIndex];
        ctx.beginPath();
        ctx.setLineDash([2, 2]);
        ctx.moveTo(
          total == 1 ? chart.scales["x"].right / 2 + 4.5 : activePoint.x,
          minY
        );
        ctx.lineTo(
          total == 1 ? chart.scales["x"].right / 2 + 4.5 : activePoint.x,
          activePoint.y
        );
        ctx.stroke();
        ctx.lineWidth = 1.5;
        ctx.setLineDash([]);
        ctx.restore();
      }
    }
  };
  return [verticalLinePlugin];
};

const getPrependedPlaceholders = (activeIndex: number, total: number) => {
  /* showing up to 5 data points on line chart
     if there are less than 5 add buffer points to total 5
  */
  if (total == DATA_POINTS_SHOWN) {
    return [];
  }
  let bufferPoints = 0;
  if (total == 1) {
    bufferPoints = 2;
  } else {
    bufferPoints = Math.max(2 - activeIndex, 0);
  }
  return Array(bufferPoints).fill("");
};

const getPrependedDataPoints = (
  activeIndex: number,
  total: number,
  yValue: number
) => {
  if (total == 5) {
    return [];
  }
  let count = 0;
  if (total == 1) {
    count = 2;
  } else {
    count = Math.max(2 - activeIndex, 0);
  }
  return Array(count).fill(yValue);
};

const getAppendedPlaceholders = (activeIndex: number, total: number) => {
  if (total == 5) {
    return [];
  }
  let count = 0;
  if (total == 1) {
    count = 2;
  }
  if (total == 2) {
    count = activeIndex + 1;
  }
  if (total == 3) {
    count = activeIndex;
  }
  if (total == 4) {
    count = Math.max(activeIndex - 1, 0);
  }

  return Array(count).fill("");
};

const CustomLine: React.FC<LineProps> = ({
  activeIndex,
  cycles,
  pillars
}: LineProps) => {
  const indexScores = getPillarScores(PillarName.NEW_INDEX, cycles);
  const connectednessScores = getPillarScores(PillarName.CONNECTEDNESS, cycles);
  const clarityScores = getPillarScores(PillarName.CLARITY, cycles);
  const emotionalBalanceScores = getPillarScores(
    PillarName.EMOTIONAL_BALANCE,
    cycles
  );

  const dates = cycles.map(cycle =>
    new Date(cycle.cycleCompletionDate).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short"
    })
  );

  const maxScore = Math.max(
    ...indexScores,
    ...connectednessScores,
    ...clarityScores,
    ...emotionalBalanceScores
  );

  const determinePointBackgroundColor = (
    ctx: ScriptableContext<"line">,
    factorColor: color
  ) => {
    if (ctx.dataset.data.length >= indexScores.length) {
      if (indexScores.length == 1) {
        return factorColor;
      }
      const difference = ctx.dataset.data.length - indexScores.length;
      return ctx.dataIndex == activeIndex + difference
        ? color.WHITE
        : factorColor;
    }
    return ctx.dataIndex == activeIndex ? color.WHITE : factorColor;
  };

  const determinePointRadius = (ctx: ScriptableContext<"line">) => {
    if (ctx.dataset.data.length > indexScores.length) {
      const difference = ctx.dataset.data.length - indexScores.length;
      if (ctx.dataIndex < difference) {
        return 0;
      }
    }
    return indexScores.length == 1 ? pointRadius * 1.75 : pointRadius;
  };

  const pointRadius = 4;
  const opacity = 0.3;

  const indexDataSet: ChartDataset<"line", number[]> = {
    backgroundColor: addOpacity(color.DARKBLUE, opacity),
    borderColor: indexScores.length == 1 ? color.WHITE : color.DARKBLUE,
    data: indexScores,
    hoverBackgroundColor: "white",
    hoverBorderColor: color.DARKBLUE,
    label: "index",
    pointBackgroundColor: ctx =>
      determinePointBackgroundColor(ctx, color.DARKBLUE),
    pointRadius: ctx => determinePointRadius(ctx)
  };

  const connectednessDataSet: ChartDataset<"line", number[]> = {
    backgroundColor: addOpacity(color.GREEN, opacity),
    borderColor: connectednessScores.length == 1 ? color.WHITE : color.GREEN,
    data: connectednessScores,
    label: "connectedness",
    pointStyle: "triangle",
    pointBackgroundColor: ctx =>
      determinePointBackgroundColor(ctx, color.GREEN),
    pointRadius: ctx => determinePointRadius(ctx)
  };

  const clarityDataSet: ChartDataset<"line", number[]> = {
    backgroundColor: addOpacity(color.PURPLE, opacity),
    borderColor: clarityScores.length == 1 ? color.WHITE : color.PURPLE,
    data: clarityScores,
    label: "clarity",
    pointStyle: "rectRot",
    pointBackgroundColor: ctx =>
      determinePointBackgroundColor(ctx, color.PURPLE),
    pointRadius: ctx => determinePointRadius(ctx)
  };

  const emotionalBalanceDataSet: ChartDataset<"line", number[]> = {
    backgroundColor: addOpacity(color.FUSCHIA, opacity),
    borderColor:
      emotionalBalanceScores.length == 1 ? color.WHITE : color.FUSCHIA,
    data: emotionalBalanceScores,
    label: "emotionalBalance",
    pointStyle: "rect",
    pointBackgroundColor: ctx =>
      determinePointBackgroundColor(ctx, color.FUSCHIA),
    pointRadius: ctx => determinePointRadius(ctx)
  };

  const emergingFactorDataSet: ChartDataset<"line", number[]> = {
    backgroundColor: addOpacity(color.YELLOW, opacity),
    borderColor: color.YELLOW,
    data: [0],
    label: "emergingFactor",
    pointStyle: "rect",
    pointBackgroundColor: ctx =>
      determinePointBackgroundColor(ctx, color.YELLOW),
    pointRadius: ctx => determinePointRadius(ctx)
  };

  if (pillars.size == 0) pillars.add(PillarName.NEW_INDEX);
  const datasets = [...pillars].map(pillar => {
    switch (pillar) {
      case PillarName.NEW_INDEX:
        indexDataSet.data = [
          ...getPrependedDataPoints(
            activeIndex,
            dates.length,
            indexDataSet.data[0]
          ),
          ...indexDataSet.data
        ];
        return indexDataSet;
      case PillarName.CONNECTEDNESS:
        connectednessDataSet.data = [
          ...getPrependedDataPoints(
            activeIndex,
            dates.length,
            connectednessDataSet.data[0]
          ),
          ...connectednessDataSet.data
        ];
        return connectednessDataSet;
      case PillarName.CLARITY:
        clarityDataSet.data = [
          ...getPrependedDataPoints(
            activeIndex,
            dates.length,
            clarityDataSet.data[0]
          ),
          ...clarityDataSet.data
        ];
        return clarityDataSet;
      case PillarName.EMOTIONAL_BALANCE:
        emotionalBalanceDataSet.data = [
          ...getPrependedDataPoints(
            activeIndex,
            dates.length,
            emotionalBalanceDataSet.data[0]
          ),
          ...emotionalBalanceDataSet.data
        ];
        return emotionalBalanceDataSet;
      case PillarName.EMERGING_FACTOR:
        emergingFactorDataSet.data = [
          ...getPrependedDataPoints(
            activeIndex,
            dates.length,
            emergingFactorDataSet.data[0]
          ),
          ...emergingFactorDataSet.data
        ];
        return emergingFactorDataSet;
    }
  });

  const data: ChartData<"line"> = {
    labels: [
      ...getPrependedPlaceholders(activeIndex, dates.length),
      ...dates,
      ...getAppendedPlaceholders(activeIndex, dates.length)
    ],
    datasets
  };
  const plugins = getPlugins(activeIndex, dates.length);

  return (
    <LineWrapper>
      <Line data={data} options={getOptions(maxScore)} plugins={plugins} />
    </LineWrapper>
  );
};

export default CustomLine;
