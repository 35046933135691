import React, { useMemo, useState } from "react";
import { Button, StyledGrid, StyledP } from "components/simple";
import { fontSize, color, spacingDefaults } from "style/constants";
import { Link, useHistory, useLocation } from "react-router-dom";
import Availability from "./Availability";
import { format } from "date-fns";
import { AvailableTime } from "..";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { showErrorNotification } from "state";
import { useDispatch } from "react-redux";
import {
  GetAppointmentDuration,
  RescheduleCoachingSession,
  RescheduleImagingSession,
  ScheduleCoachingSession,
  ScheduleImagingSession,
  GetIsEligibleForCoachingSession
} from "graphql/sessions/sessions.gql";

interface AppointmentDurations {
  duration: string;
  name: string;
}

interface Props {
  currentDate: string;
  appointmentType: string;
  availableTimes: AvailableTime[];
}

const Details: React.FC<Props> = ({
  currentDate,
  appointmentType,
  availableTimes
}) => {
  const dispatch = useDispatch();
  const appHistory = useHistory();
  const [selectedTime, setSelectedTime] = useState<Date>();
  const [appointmentDurations, setAppointmentDuration] = useState<
    AppointmentDurations[]
  >();
  const [isEligibleForCoaching, setIsEligibleForCoaching] = useState<boolean>(
    false
  );

  const timezoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const acuityConfirmationNumber = params?.get("reschedule");

  useQuery(GetAppointmentDuration, {
    fetchPolicy: "no-cache",
    onCompleted: data =>
      setAppointmentDuration(
        data?.getAppointmentDuration.map((st: any) => ({
          ...st,
          name: st.name.toLowerCase(),
          duration:
            +st.duration < 60
              ? `${+st.duration} minutes`
              : `${+st.duration / 60} ${
                  +st.duration / 60 > 1 ? "hours" : "hour"
                }`
        }))
      )
  });

  useQuery(GetIsEligibleForCoachingSession, {
    onCompleted: data =>
      setIsEligibleForCoaching(data.isEligibleForCoachingSession)
  });

  const createErrorDispatchNotification = (error: string) => {
    const errorMessage = error.replace("GraphQL error: ", "");
    dispatch(showErrorNotification("Error", errorMessage));
  };

  const [scheduleCoachingAppointment] = useMutation(ScheduleCoachingSession, {
    variables: {
      input: {
        sessionTime: selectedTime,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const coachingData = data.createCoachingSessionAsParticipant; //
      appHistory.push(`/coachingConfirmation/${coachingData.id}`);
    },
    onError: error => createErrorDispatchNotification(error.message)
  });

  const [scheduleImagingAppointment] = useMutation(ScheduleImagingSession, {
    variables: {
      input: {
        sessionTime: selectedTime,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const imagingData = data.createImagingSessionAsParticipant;
      appHistory.push(`/imagingConfirmation/${imagingData.id}`);
    },
    onError: error => createErrorDispatchNotification(error.message)
  });

  const [rescheduleCoachingSession] = useMutation(RescheduleCoachingSession, {
    variables: {
      input: {
        acuityConfirmationNumber: acuityConfirmationNumber
          ? +acuityConfirmationNumber
          : 0,
        rescheduleTime: selectedTime,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const coachingData = data.rescheduleCoachingSessionAsParticipant;
      appHistory.push(`/coachingConfirmation/${coachingData.id}`);
    },
    onError: error => createErrorDispatchNotification(error.message)
  });

  const [rescheduleImagingSession] = useMutation(RescheduleImagingSession, {
    variables: {
      input: {
        acuityConfirmationNumber: acuityConfirmationNumber
          ? +acuityConfirmationNumber
          : 0,
        rescheduleTime: selectedTime,
        ianaTimeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const imagingData = data.rescheduleImagingSessionAsParticipant;
      appHistory.push(`/imagingConfirmation/${imagingData.id}`);
    },
    onError: error => createErrorDispatchNotification(error.message)
  });

  // This sets the selectedTime to undefined whenever the times array changes.
  // So if a user selects a time and then clicks a new day, the selectedTime will
  // be set to undefined instead of the currently selected time.
  useMemo(() => {
    setSelectedTime(undefined);
  }, [availableTimes]);

  const currentSessionType = appointmentDurations?.find(
    (appointment: AppointmentDurations) => appointment.name === appointmentType
  );

  const handleScheduleAppointment = () => {
    if (!acuityConfirmationNumber) {
      appointmentType === "coaching"
        ? isEligibleForCoaching //check for eligibility
          ? scheduleCoachingAppointment()
          : null
        : scheduleImagingAppointment();
    } else if (acuityConfirmationNumber) {
      appointmentType === "coaching"
        ? rescheduleCoachingSession()
        : rescheduleImagingSession();
    }
  };

  return (
    <>
      <StyledP fontWeight="600" textAlign="center">
        Availability
      </StyledP>
      <StyledP
        textAlign="center"
        fontSize={fontSize.medium}
        color={color.BLUE}
        fontWeight="400"
        marginbottom={spacingDefaults.normal}
      >
        {format(new Date(currentDate), "MMMM do, yyyy")}
      </StyledP>
      <StyledP
        style={{ fontStyle: "italic" }}
        textAlign="center"
        fontSize={fontSize.xsmall}
      >
        {appointmentType === "coaching" ? `Coaching` : `Imaging`} sessions last{" "}
        {currentSessionType?.duration}
      </StyledP>
      <StyledP
        style={{ fontStyle: "italic" }}
        textAlign="center"
        fontSize={fontSize.xsmall}
        marginbottom={spacingDefaults.xlarge}
      >
        Times shown in {timezoneAbbreviation}
      </StyledP>

      <Availability
        availableTimes={availableTimes}
        setSelectedTime={setSelectedTime}
        selectedTime={selectedTime}
      />

      {/* Button Container */}
      <StyledGrid container spacing={1} justifyContent={"center"}>
        <StyledGrid item md={6}>
          <Link to="/dashboard">
            <Button
              inverted={true}
              background={"transparent"}
              width="100%"
              borderRadius="23px"
              color="secondary"
              type="button"
            >
              {"Cancel"}
            </Button>
          </Link>
        </StyledGrid>

        <StyledGrid item md={6}>
          <Button
            width="100%"
            borderRadius="23px"
            disabled={!selectedTime}
            type="button"
            onClick={() => handleScheduleAppointment()}
          >
            Schedule
          </Button>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export default Details;
