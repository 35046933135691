import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import track from "react-tracking";

import React, { useState, useEffect } from "react";
import { isFeatureEnabled } from "graphql/featureFlags/featureFlags.gql";
import SpouseModal from "components/simple/SpouseModals";

import { ReactComponent as DownloadIcon } from "assets/download_icon.svg";
import {
  AboutMeSurveyBanner,
  AssessmentsResetBanner,
  CoachingSessionReflectionBanner,
  CorporateSurveyBanner,
  HabitsBanner,
  MoreContentBanner,
  NotConsentedBanner,
  NotificationPreferencesBanner,
  ResearchSurveyBanner,
  RetakeAssessmentsBanner,
  StyledGrid,
  StyledP,
  SurveyBanner,
  WaitlistBanner,
  WinterBreakBanner,
  InactiveBanner,
  UXSurveyBanner
} from "components/simple";
import {
  DashboardQuery,
  DismissAssessmentsReset,
  DismissNotificationPreferencesBanner,
  Me,
  GetPartnerModalsData,
  UpdateFirstTimeDashboard,
  GetOrganizationConsentFormInfo
} from "graphql/user/user.gql";
import { DismissUserSurvey } from "graphql/userSurveys/userSurveys.gql";
import { getUser, getUserStatus, UserStatus } from "state";
import {
  appWidth,
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";
import AssessmentProgress from "./AssessmentProgress";
import CycleSummary from "./CycleSummary";
import Explore from "./Explore";
import OverallProgress from "./OverallProgress";
import { differenceInYears, isFuture, isPast } from "date-fns";
import BrainGauge from "./BrainGauge";
import { GetGroupSessions } from "graphql/groupCoaching/groupCoaching.gql";
import { CoachingSessionStatus } from "./CycleSummary/types";
import Sessions from "./Sessions";
import { GetUpcomingCoachingSessions } from "graphql/sessions/sessions.gql";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";

const url: string = routesConfig.dashboard.path;

const Dashboard: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  const user = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);
  // NOTE - Functionality is disabled when the participant has not consented.
  const shouldDisable = userStatus !== UserStatus.QUALIFIED_AND_CONSENTED;

  const { data, refetch } = useQuery(DashboardQuery, {
    fetchPolicy: "no-cache"
  });

  const { data: imagingFeatureFlagData } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "Imaging" } }
  });
  const { data: partnerLinkingFeatureFlagData } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "PartnerLinking" } }
  });
  const { data: aceFeatureFlagData } = useQuery(isFeatureEnabled, {
    variables: { input: { featureName: "ACE" } }
  });

  const { data: partnerModalsData } = useQuery(GetPartnerModalsData, {
    fetchPolicy: "no-cache"
  });

  const [selectedCycleIndex, setSelectedCycleIndex] = useState(0);

  const { data: upcomingCoachingSessions } = useQuery(
    GetUpcomingCoachingSessions,
    {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all"
    }
  );

  const hasUpcomingCoachingSession = upcomingCoachingSessions !== undefined;

  const { data: groupSession } = useQuery(GetGroupSessions, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  });

  const [dismissSurveyMutation] = useMutation(DismissUserSurvey);
  const [dismissAssessmentsResetMutation] = useMutation(
    DismissAssessmentsReset
  );
  const [dismissNotificationPreferencesBannerMutation] = useMutation(
    DismissNotificationPreferencesBanner
  );

  const [updateFirstTimeDashboardMutation] = useMutation(
    UpdateFirstTimeDashboard
  );

  const [isWelcomeModalOneOpen, setIsWelcomeModalOneOpen] = useState(false);
  const [isWelcomeModalTwoOpen, setIsWelcomeModalTwoOpen] = useState(false);
  const [isInviteSpouseModalOpen, setIsInviteSpouseModalOpen] = useState(false);

  const [shouldHideConsentForm, setShouldHideConsentForm] = useState(false);
  useQuery(GetOrganizationConsentFormInfo, {
    onCompleted: data => {
      setShouldHideConsentForm(data?.me?.organization?.hideConsentForm);
    }
  });

  const dashboard = data?.me;
  const userId = dashboard?.id;
  const isImagingEnabled = imagingFeatureFlagData?.isFeatureEnabled?.enabled;

  useEffect(() => {
    if (data) {
      setSelectedCycleIndex(data.me?.currentCycle?.cycle?.cycleNumber - 1 ?? 0);
    }
  }, [data]);

  useEffect(() => {
    if (partnerLinkingFeatureFlagData && partnerModalsData && userId) {
      const firstTimeDashboard = partnerModalsData?.me?.firstTimeDashboard;
      const isSpouseAlreadyConnected = partnerModalsData?.me?.spouseId;
      const isPartnerLinkingEnabled =
        partnerLinkingFeatureFlagData?.isFeatureEnabled?.enabled;

      if (
        firstTimeDashboard &&
        isPartnerLinkingEnabled &&
        !isSpouseAlreadyConnected
      ) {
        setIsWelcomeModalOneOpen(true);
        updateFirstTimeDashboardHelper(userId);
      } else if (firstTimeDashboard) {
        updateFirstTimeDashboardHelper(userId);
      }
    }
  }, [
    imagingFeatureFlagData,
    partnerLinkingFeatureFlagData,
    partnerModalsData,
    data
  ]);

  useEffect(() => {
    if (userStatus === UserStatus.QUALIFIED && !shouldHideConsentForm) {
      history.push("/consent/form");
    }
  }, [userStatus]);

  if (
    !user ||
    !data?.me?.currentCycle ||
    !partnerLinkingFeatureFlagData ||
    !aceFeatureFlagData ||
    !partnerModalsData
  ) {
    return null;
  }

  const accountAge = differenceInYears(
    new Date(),
    new Date(dashboard.createdAt)
  );
  const cycleNumber = dashboard.currentCycle?.cycle?.cycleNumber;
  const cycleStatus = dashboard.currentCycle?.userAssessmentCycle?.status;
  const aceCompleted =
    dashboard.currentCycle?.userAssessmentCycle?.aceCompleted;

  // show winter break banner starting on 12/20/21 and ending on 1/3/22.
  const isWinterBreak =
    isPast(new Date(2021, 11, 20)) && isFuture(new Date(2022, 0, 3));

  const upcomingGroupCoachingSession = groupSession?.groupCoachingSessions?.find(
    (x: { status: CoachingSessionStatus }) =>
      x.status === CoachingSessionStatus.SCHEDULED
  );
  const hasUpcomingGroupCoachingSession =
    upcomingGroupCoachingSession !== undefined;

  return (
    <>
      <SpouseModal
        isWelcomeModalOneOpen={isWelcomeModalOneOpen}
        setIsWelcomeModalOneOpen={setIsWelcomeModalOneOpen}
        setIsWelcomeModalTwoOpen={setIsWelcomeModalTwoOpen}
        isNewUser={true}
        userId={userId}
      />
      <SpouseModal
        isWelcomeModalTwoOpen={isWelcomeModalTwoOpen}
        setIsWelcomeModalTwoOpen={setIsWelcomeModalTwoOpen}
        setIsInviteSpouseModalOpen={setIsInviteSpouseModalOpen}
        isNewUser={true}
        userId={userId}
      />
      <SpouseModal
        isInviteSpouseModalOpen={isInviteSpouseModalOpen}
        setIsInviteSpouseModalOpen={setIsInviteSpouseModalOpen}
        isNewUser={true}
        userId={userId}
      />
      <HabitsBanner />
      <StyledGrid
        backgroundColor={color.BLUEGRAY}
        container
        justifyContent="center"
        zIndex="1"
      >
        <StyledGrid
          alignSelf="center"
          container
          item
          maxWidth={appWidth.max}
          padding={spacingDefaults.small}
        >
          <StyledGrid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            marginTop={mobile ? "1rem" : "3rem"}
            xs={12}
          >
            <StyledP
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={mobile ? "26px" : "30px"}
              letterSpacing="-0.45px"
              lineHeight="38px"
              textAlign={mobile ? "center" : undefined}
            >
              Welcome to Your BrainHealth® Dashboard, {user.firstName}!
            </StyledP>

            {!mobile &&
              cycleStatus === UserAssessmentCycleStatus.SCORE_FINALIZED && (
                <Link
                  to={routesConfig.pdf.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledGrid alignItems="center" container itemMargin="5px">
                    <DownloadIcon />
                    <StyledP color={color.BLACK}>
                      Download Latest Report
                    </StyledP>
                  </StyledGrid>
                </Link>
              )}
          </StyledGrid>

          {isWinterBreak && (
            <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
              <WinterBreakBanner />
            </StyledGrid>
          )}

          {getCurrentBanner()}

          <StyledGrid
            container
            filter={shouldDisable ? "saturate(0)" : undefined}
            item
            marginTop={spacingDefaults.xlarge}
            xs={12}
          >
            {cycleStatus !== UserAssessmentCycleStatus.SCORE_FINALIZED && (
              <>
                <StyledGrid
                  item
                  lg={6}
                  paddingRight={mobile ? undefined : spacingDefaults.xsmall}
                  xs={12}
                >
                  <OverallProgress
                    cycleStatus={cycleStatus}
                    daysToComplete={
                      dashboard.currentCycle?.userAssessmentCycle
                        ?.daysToComplete || 1
                    }
                    daysRemaining={dashboard.assessmentsDaysLeft || 0}
                    assessmentsRemaining={dashboard.assessmentsLeft || 0}
                    disabled={shouldDisable}
                  />
                </StyledGrid>

                <StyledGrid
                  item
                  lg={6}
                  paddingLeft={mobile ? undefined : spacingDefaults.xsmall}
                  paddingTop={mobile ? spacingDefaults.normal : undefined}
                  xs={12}
                >
                  <AssessmentProgress
                    userAssessments={
                      dashboard.currentCycle?.userAssessmentCycle
                        ?.userAssessments
                    }
                    userSurveys={
                      dashboard?.currentCycle?.userSurveyCycle?.userSurveys
                    }
                    disabled={shouldDisable}
                  />
                </StyledGrid>
              </>
            )}

            {(cycleStatus === UserAssessmentCycleStatus.SCORE_FINALIZED ||
              cycleNumber > 1) && (
              <StyledGrid
                item
                marginTop={
                  cycleStatus === UserAssessmentCycleStatus.SCORE_FINALIZED
                    ? undefined
                    : spacingDefaults.medium
                }
                xs={12}
              >
                <CycleSummary
                  disabled={shouldDisable}
                  selectedCycleIndex={selectedCycleIndex}
                  setSelectedCycleIndex={setSelectedCycleIndex}
                  hasImagingEnabled={isImagingEnabled}
                />
              </StyledGrid>
            )}
            {(cycleStatus === UserAssessmentCycleStatus.SCORE_FINALIZED ||
              hasUpcomingCoachingSession ||
              hasUpcomingGroupCoachingSession ||
              isImagingEnabled) && (
              <StyledGrid item marginTop={spacingDefaults.medium} xs={12}>
                <Sessions
                  selectedCycleIndex={selectedCycleIndex}
                  setSelectedCycleIndex={setSelectedCycleIndex}
                />
              </StyledGrid>
            )}
            {cycleStatus === UserAssessmentCycleStatus.SCORE_FINALIZED &&
              dashboard?.brainGaugeAccessCode && (
                <StyledGrid item marginTop={spacingDefaults.medium} xs={12}>
                  <BrainGauge />
                </StyledGrid>
              )}
            {[
              UserAssessmentCycleStatus.READY_TO_SCORE,
              UserAssessmentCycleStatus.SCORING_IN_PROGRESS,
              UserAssessmentCycleStatus.SCORE_FINALIZED
            ].includes(cycleStatus) && (
              <StyledGrid item marginTop={spacingDefaults.medium} xs={12}>
                <Explore disabled={shouldDisable} />
              </StyledGrid>
            )}
          </StyledGrid>
          <StyledGrid container item justifyContent="center" xs={12}>
            <MoreContentBanner />
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </>
  );

  function getCurrentBanner(): JSX.Element | null {
    const banner = getCurrentBannerHelper();

    return banner ? (
      <StyledGrid item marginTop={spacingDefaults.xlarge} xs={12}>
        {banner}
      </StyledGrid>
    ) : null;
  }

  function getCurrentBannerHelper(): JSX.Element | null {
    // NOTE - We only show one banner at a time, so the order below provides
    // the precedence for which banner should appear.

    if (dashboard.currentSurveyId) {
      // NOTE - The coaching session reflection and about me are surveys, but need to
      // display a different banner.

      if (dashboard.currentSurveyName === "Coaching Session Survey") {
        return (
          <CoachingSessionReflectionBanner dismiss={dismissSurveyBanner} />
        );
      }

      if (dashboard.currentSurveyName === "About Me Survey") {
        return (
          <AboutMeSurveyBanner
            dismiss={dismissSurveyBanner}
            years={accountAge > 0 ? accountAge : 1}
          />
        );
      }

      if (
        dashboard.currentSurveyName === "COVID-19 Survey" ||
        dashboard.currentSurveyName === "Warrior Survey" ||
        dashboard.currentSurveyName === "COVID-19 Survey 2" ||
        dashboard.currentSurveyName === "COVID-19 Impact Survey"
      ) {
        return (
          <ResearchSurveyBanner
            dismiss={dismissSurveyBanner}
            firstName={user?.firstName}
          />
        );
      }

      if (
        dashboard.currentSurveyName === "Workplace Pulse Survey" ||
        dashboard.currentSurveyName === "Workplace Impact Survey"
      ) {
        return (
          <CorporateSurveyBanner
            dismiss={dismissSurveyBanner}
            firstName={user?.firstName}
          />
        );
      }

      return (
        <UXSurveyBanner
          dismiss={dismissSurveyBanner}
          firstName={user?.firstName}
        />
      );
    }

    if (userStatus === UserStatus.WAITLISTED) {
      return <WaitlistBanner showButton />;
    }

    if (userStatus === UserStatus.QUALIFIED_AND_UNCONSENTED) {
      return <NotConsentedBanner />;
    }

    if (userStatus === UserStatus.INACTIVE) {
      return <InactiveBanner showButton />;
    }

    if (dashboard.showNotificationPreferenceBanner) {
      return (
        <NotificationPreferencesBanner
          dismiss={dismissNotificationPreferencesBanner}
        />
      );
    }

    if (dashboard.assessmentsReset) {
      return <AssessmentsResetBanner dismiss={dismissAssessmentsResetBanner} />;
    }

    if (
      cycleNumber > 1 &&
      cycleStatus === UserAssessmentCycleStatus.NOT_STARTED
    ) {
      return <RetakeAssessmentsBanner />;
    }

    return null;
  }

  async function dismissSurveyBanner(): Promise<void> {
    const response = await dismissSurveyMutation({
      variables: { input: { userSurveyId: dashboard.currentSurveyId } }
    });

    if (response?.data?.dismissUserSurvey?.success) {
      refetch();
    }
  }

  async function dismissAssessmentsResetBanner(): Promise<void> {
    const response = await dismissAssessmentsResetMutation({
      variables: { id: dashboard.id }
    });

    if (response?.data?.dismissAssessmentsReset?.success) {
      refetch();
    }
  }

  async function dismissNotificationPreferencesBanner(): Promise<void> {
    const response = await dismissNotificationPreferencesBannerMutation({
      variables: { id: dashboard.id }
    });

    if (response?.data?.dismissNotificationPreferencesBanner?.success) {
      refetch();
    }
  }

  async function updateFirstTimeDashboardHelper(id: string): Promise<void> {
    try {
      const response = await updateFirstTimeDashboardMutation({
        variables: { id }
      });
    } catch (error) {
      console.error("Problem updating firstTimeDashboard");
    }
  }
};

export default track({
  url
})(Dashboard);
