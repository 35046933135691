import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token")
  }
});

export default client;
