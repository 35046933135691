import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import { Button } from "components/simple";
import { color } from "style/constants";

export const CircularProgressUnderlay = styled(CircularProgress)`
  && {
    color: ${color.MUTEDGRAY};
  }
`;

export const StyledButton = styled(Button)<{ height?: string; width?: string }>`
  && {
    height: ${props => props.height || "auto"};
    padding: 0;
    text-transform: none;
    width: ${props => props.width || "auto"};
  }
`;
