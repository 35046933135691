import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { useEffect } from "react";

import {
  FindProductTour,
  GetProductTour
} from "graphql/productTours/productTours.gql";

interface UserProductTour {
  id: string;
  userId?: string;
  name?: string;
}

export interface FindProductTourResponse {
  findProductTour: UserProductTour;
}

interface ProductTourStep {
  title: string;
  description: string;
  component?: string;
}

interface GetProductTourData {
  title: string;
  steps: ProductTourStep[];
}

export interface GetProductTourResponse {
  getProductTour?: GetProductTourData;
}

export interface UserProductToursData {
  productTour?: GetProductTourData;
}

const useProductTour = (name: string): UserProductToursData => {
  const variables = { input: { name } };

  const { data: findProductTourResponse, loading: findLoading } = useQuery<
    FindProductTourResponse
  >(FindProductTour, {
    variables
  });

  const [
    getProductTour,
    { data: getProductTourResponse, loading: getLoading }
  ] = useLazyQuery<GetProductTourResponse>(GetProductTour, {
    variables
  });

  useEffect(() => {
    if (!findLoading && !findProductTourResponse?.findProductTour?.id) {
      getProductTour();
    }
  }, [findLoading]);

  if (!findLoading) {
    if (findProductTourResponse?.findProductTour?.id) {
      return { productTour: undefined };
    }
    if (!getLoading && getProductTourResponse) {
      return { productTour: getProductTourResponse.getProductTour };
    }
  }
  return { productTour: undefined };
};

export default useProductTour;
