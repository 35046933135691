import React from "react";

import SmallBlueTree from "assets/MyJourney/myjourney-smallbluetree.svg";
import BlueTree from "assets/MyJourney/myjourney-bluetree-empty.svg";
import GreenTree from "assets/MyJourney/myjourney-greentree-empty.svg";
import GreenTree_FruitNutFlower from "assets/MyJourney/myjourney-greentree-fruitnutflower.svg";
import GreenTree_FruitNut from "assets/MyJourney/myjourney-greentree-fruitnut.svg";
import GreenTree_FruitFlower from "assets/MyJourney/myjourney-greentree-fruitflower.svg";
import GreenTree_NutFlower from "assets/MyJourney/myjourney-greentree-nutflower.svg";
import GreenTree_Fruit from "assets/MyJourney/myjourney-greentree-fruit.svg";
import GreenTree_Nut from "assets/MyJourney/myjourney-greentree-nut.svg";
import GreenTree_Flower from "assets/MyJourney/myjourney-greentree-flower.svg";
import BlueTree_FruitNutFlower from "assets/MyJourney/myjourney-bluetree-fruitnutflower.svg";
import BlueTree_FruitNut from "assets/MyJourney/myjourney-bluetree-fruitnut.svg";
import BlueTree_FruitFlower from "assets/MyJourney/myjourney-bluetree-fruitflower.svg";
import BlueTree_NutFlower from "assets/MyJourney/myjourney-bluetree-nutflower.svg";
import BlueTree_Fruit from "assets/MyJourney/myjourney-bluetree-fruit.svg";
import BlueTree_Nut from "assets/MyJourney/myjourney-bluetree-nut.svg";
import BlueTree_Flower from "assets/MyJourney/myjourney-bluetree-flower.svg";
import GrayTree from "assets/MyJourney/myjourney-smallgraytree.svg";
import EmptyPlaceholder from "assets/MyJourney/myjourney-graycircle.svg";
import { UserStreak } from "models/userStreak";
import { JourneyTreeImg } from "./styled";
import { isAfter, isBefore, isSameDay } from "date-fns";

interface JourneyTreeProps {
  userStreak: UserStreak;
}

export const MyJourneyTree = ({ userStreak }: JourneyTreeProps) => {
  const [year, month, day] = userStreak.date.split("-"); // yyyy-MM-dd (e.g. 2022-07-11)
  const currentDate = new Date(+year, +month - 1, +day); // month is 0-indexed (e.g. Jan = 0)

  const isInPast = isBefore(currentDate, new Date());
  const isToday = isSameDay(currentDate, new Date());
  const isInFuture = isAfter(currentDate, new Date());

  if (isToday) {
    if (!userStreak) {
      return <JourneyTreeImg src={SmallBlueTree} />;
    }
    if (userStreak?.freeDayPassApplied || userStreak?.screenBreakApplied) {
      return <JourneyTreeImg src={BlueTree} />;
    }
    if (
      userStreak?.habitCompleted &&
      userStreak?.trainingCompleted &&
      userStreak?.resourceViewed
    ) {
      return <JourneyTreeImg src={BlueTree_FruitNutFlower} />;
    }
    if (userStreak?.habitCompleted && userStreak?.trainingCompleted) {
      return <JourneyTreeImg src={BlueTree_FruitFlower} />;
    }
    if (userStreak?.habitCompleted && userStreak?.resourceViewed) {
      return <JourneyTreeImg src={BlueTree_FruitNut} />;
    }
    if (userStreak?.trainingCompleted && userStreak?.resourceViewed) {
      return <JourneyTreeImg src={BlueTree_NutFlower} />;
    }
    if (userStreak?.habitCompleted) {
      return <JourneyTreeImg src={BlueTree_Fruit} />;
    }
    if (userStreak?.trainingCompleted) {
      return <JourneyTreeImg src={BlueTree_Flower} />;
    }
    if (userStreak?.resourceViewed) {
      return <JourneyTreeImg src={BlueTree_Nut} />;
    }

    return <JourneyTreeImg src={SmallBlueTree} />;
  } else if (isInPast) {
    if (userStreak?.freeDayPassApplied || userStreak?.screenBreakApplied) {
      return <JourneyTreeImg src={GreenTree} />;
    }
    if (
      userStreak?.habitCompleted &&
      userStreak?.trainingCompleted &&
      userStreak?.resourceViewed
    ) {
      return <JourneyTreeImg src={GreenTree_FruitNutFlower} />;
    }
    if (userStreak?.habitCompleted && userStreak?.trainingCompleted) {
      return <JourneyTreeImg src={GreenTree_FruitFlower} />;
    }
    if (userStreak?.habitCompleted && userStreak?.resourceViewed) {
      return <JourneyTreeImg src={GreenTree_FruitNut} />;
    }
    if (userStreak?.trainingCompleted && userStreak?.resourceViewed) {
      return <JourneyTreeImg src={GreenTree_NutFlower} />;
    }
    if (userStreak?.habitCompleted) {
      return <JourneyTreeImg src={GreenTree_Fruit} />;
    }
    if (userStreak?.trainingCompleted) {
      return <JourneyTreeImg src={GreenTree_Flower} />;
    }
    if (userStreak?.resourceViewed) {
      return <JourneyTreeImg src={GreenTree_Nut} />;
    }
    return <JourneyTreeImg src={GrayTree} />;
  } else if (isInFuture) {
    return <JourneyTreeImg src={EmptyPlaceholder} />;
  }

  // should not get here
  return <JourneyTreeImg src={EmptyPlaceholder} />;
};
