import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { StyledGrid } from "components/simple";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";
import { CycleSummaryQuery } from "graphql/user/user.gql";
import { spacingDefaults } from "style/constants";
import { GetGroupSessions } from "graphql/groupCoaching/groupCoaching.gql";
import {
  CoachingSessionStatus,
  Cycle
} from "components/pages/Dashboard/CycleSummary/types";
import ScheduleSessions from "./ScheduleSessions";
import UpcomingSessions from "./UpcomingSessions";
import {
  GetIsEligibleForCoachingSession,
  GetIsEligibleForImagingSession
} from "graphql/sessions/sessions.gql";

const Sessions: React.FC = () => {
  const [selectedCycleIndex, setSelectedCycleIndex] = useState(0);
  const [cycles, setCycles] = useState<Cycle[]>([]);
  const [isEligibleForCoaching, setIsEligibleForCoaching] = useState<boolean>(
    false
  );
  const [isEligibleForImaging, setIsEligibleForImaging] = useState<boolean>(
    false
  );

  const { loading, data, error } = useQuery(CycleSummaryQuery, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  });

  const { data: groupSession } = useQuery(GetGroupSessions, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  });

  useQuery(GetIsEligibleForCoachingSession, {
    onCompleted: data =>
      setIsEligibleForCoaching(data.isEligibleForCoachingSession)
  });

  useQuery(GetIsEligibleForImagingSession, {
    onCompleted: data =>
      setIsEligibleForImaging(data.isEligibleForImagingSession)
  });

  useEffect(() => {
    if (data) {
      const allCycles = data.participantAssessmentCycles.sort(
        (a: Cycle, b: Cycle) => a.cycleNumber - b.cycleNumber
      );

      const finalizedCycles = allCycles
        .filter(
          (x: Cycle) => x.status === UserAssessmentCycleStatus.SCORE_FINALIZED
        )
        ?.sort((a: Cycle, b: Cycle) => a.cycleNumber - b.cycleNumber);

      if (finalizedCycles.length > 0) {
        setCycles(finalizedCycles);
        setSelectedCycleIndex(finalizedCycles.length - 1);
      } else {
        setCycles(allCycles);
        setSelectedCycleIndex(allCycles.length - 1);
      }
    }
  }, [data]);

  if (loading || error || !cycles.length) {
    return null;
  }

  const selectedCycle = cycles[selectedCycleIndex];

  const anyScheduledCoachingSessions = cycles.flatMap(cycle =>
    cycle.coachingSessions.filter(
      session => session.status === CoachingSessionStatus.SCHEDULED
    )
  );

  const scheduledImagingSessions = selectedCycle.imagingSessions
    .filter(x => x.status === CoachingSessionStatus.SCHEDULED)
    .sort(
      (a, b) =>
        new Date(b.sessionTime).getTime() - new Date(a.sessionTime).getTime()
    );

  const scheduledGroupCoachingSessions = groupSession?.groupCoachingSessions
    ?.filter(
      (x: { status: CoachingSessionStatus }) =>
        x.status === CoachingSessionStatus.SCHEDULED
    )
    .sort(
      (a: any, b: any) =>
        new Date(b.sessionTime).getTime() - new Date(a.sessionTime).getTime()
    );

  return (
    <>
      {(isEligibleForCoaching || isEligibleForImaging) && (
        <StyledGrid item xs={12} marginBottom={spacingDefaults.medium}>
          <ScheduleSessions
            allowCoachScheduling={isEligibleForCoaching}
            allowImageScheduling={isEligibleForImaging}
          />
        </StyledGrid>
      )}

      {(anyScheduledCoachingSessions.length > 0 ||
        scheduledImagingSessions.length > 0 ||
        scheduledGroupCoachingSessions.length > 0) && (
        <StyledGrid item xs={12} marginBottom={spacingDefaults.medium}>
          <UpcomingSessions
            groupSessionList={scheduledGroupCoachingSessions}
            imagingSessionList={scheduledImagingSessions}
            coachingSessionList={anyScheduledCoachingSessions}
          />
        </StyledGrid>
      )}
    </>
  );
};

export default Sessions;
