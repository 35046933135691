import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { Box, Modal, IconButton, useMediaQuery } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";

import { StyledGrid, StyledP, Button } from "components/simple";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import { showSuccessNotification } from "state";
import { ReactComponent as CloseButton } from "assets/close.svg";
import { UpcomingSession } from "..";
import { CancelImagingSession } from "graphql/sessions/sessions.gql";
import { CycleSummaryQuery } from "graphql/user/user.gql";
import { StyledCard } from "components/pages/DashboardV2/styled";

interface UpcomingSessionProps {
  session: UpcomingSession;
}

const UpcomingImagingSession: React.FC<UpcomingSessionProps> = ({
  session
}: UpcomingSessionProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const date = format(new Date(session.sessionTime), "MMMM d, yyyy");
  const time = format(new Date(session.sessionTime), "p");
  const timezoneAbbreviation = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  const [cancelImagingSession] = useMutation(CancelImagingSession, {
    variables: {
      input: { acuityConfirmationNumber: session.acuityConfirmationNumber }
    },
    onCompleted: () => {
      dispatch(
        showSuccessNotification(
          "Success",
          "Successfully cancelled imaging appointment"
        )
      );
    },
    refetchQueries: [{ query: CycleSummaryQuery }]
  });

  const dateAndTime = `${time} ${timezoneAbbreviation} on ${date}`;
  const message = "Please ensure your assessments are completed by that time.";

  return (
    <StyledGrid item xs={12}>
      <StyledCard
        alignItems="center"
        container
        justifyContent="space-between"
        minHeight="100px"
        padding={spacingDefaults.normal}
      >
        <StyledGrid item>
          <Box fontSize={fontSize.medium}>Imaging Session Scheduled</Box>
          <Box fontSize={fontSize.small} margin={`${spacingDefaults.xsmall} 0`}>
            {dateAndTime} <br />
            {message}
          </Box>
        </StyledGrid>

        <StyledGrid item>
          {session.acuityConfirmationNumber && (
            <StyledGrid container spacing={1}>
              <StyledGrid item>
                <Button
                  aria-label="cancel-session"
                  inverted
                  width="150px"
                  onClick={() => setShowModal(true)}
                >
                  Cancel
                </Button>
              </StyledGrid>
              <StyledGrid item>
                <Link
                  to={`/scheduling/${session.type}?reschedule=${session.acuityConfirmationNumber}`}
                >
                  <Button aria-label="reschedule-session" width="150px">
                    Reschedule
                  </Button>
                </Link>
              </StyledGrid>
              <Modal open={showModal}>
                <>
                  <StyledGrid
                    container
                    absoluteCenter
                    backgroundColor={color.WHITE}
                    borderRadius="8px"
                    direction="column"
                    maxWidth="500px"
                    mobileMaxWidth="98%"
                    padding={spacingDefaults.normal}
                  >
                    <StyledGrid item alignSelf="flex-end" position="fixed">
                      <IconButton
                        aria-label="close-modal"
                        onClick={() => setShowModal(false)}
                      >
                        <CloseButton
                          strokeWidth="1px"
                          height="15px"
                          width="15px"
                        />
                      </IconButton>
                    </StyledGrid>
                    <StyledGrid container item height="100%" width="100%">
                      <StyledGrid item width="100%" textAlign="center">
                        <StyledP
                          textAlign="center"
                          fontSize={fontSize.large}
                          fontWeight="500"
                          marginbottom={spacingDefaults.normal}
                        >
                          Cancel {session.type} session
                        </StyledP>
                      </StyledGrid>
                      <StyledGrid
                        item
                        width="100%"
                        textAlign="center"
                        marginBottom={spacingDefaults.normal}
                      >
                        <StyledGrid paddingBottom="16px">
                          <StyledP width="100%" textAlign="center">
                            Are you sure you want to cancel this {session.type}{" "}
                            session?
                          </StyledP>
                        </StyledGrid>
                      </StyledGrid>
                      <StyledGrid item width="100%" textAlign="center">
                        <Button
                          aria-label="cancel-session"
                          fullWidth={mobile}
                          onClick={() => {
                            cancelImagingSession(), setShowModal(false);
                          }}
                        >
                          Cancel Session
                        </Button>
                      </StyledGrid>
                    </StyledGrid>
                  </StyledGrid>
                </>
              </Modal>
            </StyledGrid>
          )}
        </StyledGrid>
      </StyledCard>
    </StyledGrid>
  );
};

export default UpcomingImagingSession;
