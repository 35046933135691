import styled from "styled-components";

import { color } from "style/constants";
import { StyledGrid } from "components/simple";

export const StyledCard = styled(StyledGrid)`
  background: ${color.WHITE};
  border: 1px solid ${color.CARDBORDER};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
