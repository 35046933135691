import { gql } from "apollo-boost";
import { Dispatch } from "redux";

import client from "graphql/client";
import {
  hideLoader,
  setSubdomain,
  setUser,
  showErrorNotification,
  showLoader
} from "./actions";
import { Action, Subdomains } from "./types";

const UserQuery = gql`
  query User {
    me {
      id
      firstName
      lastName
      email
      eligibleForStudy
      organization {
        name
      }
      waitlisted: waitListed
      enrollmentConsent
      forcedConsent
      profileImageKey
      active
    }
  }
`;

export const fetchUser = () => async (
  dispatch: Dispatch<Action>
): Promise<void> => {
  try {
    dispatch(showLoader());

    const response = await client.query({
      query: UserQuery,
      fetchPolicy: "no-cache"
    });

    if (response?.data) {
      dispatch(setUser(response.data.me));
      if (response.data.me.organization.name.includes("dod")) {
        dispatch(setSubdomain(Subdomains.DOD));
      }
    }
  } catch {
    dispatch(showErrorNotification());
  } finally {
    dispatch(hideLoader());
  }
};
