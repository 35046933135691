import React, { useState } from "react";
import { Collapse, Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { StyledGrid, StyledP, Button } from "components/simple";
import { color, spacingDefaults, fontFamily } from "style/constants";
import { FaqData } from "./index";
import RichText from "../Contentful/Unit/RichText";

interface Props {
  mobile: boolean;
  data: FaqData[];
  activeSection: string;
}
const Content: React.FC<Props> = ({ data, mobile, activeSection }: Props) => {
  const [activeQuestion, setActiveQuestion] = useState("");

  return (
    <>
      {data.map((category, index) => {
        if (activeSection !== category.title) return null;

        return (
          <StyledGrid key={index}>
            {mobile && (
              <StyledP
                color={color.BLACK}
                fontFamily={fontFamily.secondary}
                fontSize="30px"
                letterSpacing="-0.45px"
                textAlign="center"
                marginbottom={spacingDefaults.large}
              >
                {category.title}
              </StyledP>
            )}

            <StyledGrid
              backgroundColor={!mobile ? undefined : color.WHITE}
              border={!mobile ? undefined : `1px solid ${color.MUTEDGRAY}`}
              borderRadius="8px"
              padding={spacingDefaults.normal}
            >
              {category.questions.map(question => {
                const isActive = activeQuestion === question.title;
                const handleClick = (): void => {
                  if (isActive) {
                    setActiveQuestion("");
                  } else {
                    setActiveQuestion(question.title);
                  }
                };
                return (
                  <StyledGrid
                    key={question.title}
                    marginTop={spacingDefaults.normal}
                    marginBottom={spacingDefaults.large}
                  >
                    <Button
                      plain
                      startIcon={
                        isActive ? (
                          <RemoveIcon color="secondary" />
                        ) : (
                          <AddIcon color="secondary" />
                        )
                      }
                      onClick={handleClick}
                    >
                      <StyledP weight="600" fontSize="1.15rem">
                        {question.title}
                      </StyledP>
                    </Button>
                    <StyledGrid
                      marginTop={spacingDefaults.small}
                      marginBottom={spacingDefaults.small}
                    >
                      <Divider />
                    </StyledGrid>
                    <Collapse in={isActive}>
                      <RichText
                        textColor={color.DARKERGRAY}
                        data={question.answer}
                      />
                    </Collapse>
                  </StyledGrid>
                );
              })}
            </StyledGrid>
          </StyledGrid>
        );
      })}
    </>
  );
};

export default Content;
