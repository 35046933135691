import { gql } from "apollo-boost";

export const GetSessions = gql`
  query GetSessions {
    participantAssessmentCycles {
      id
      coachingSessions {
        id
        coach {
          firstName
        }
        status
        acuityConfirmationNumber
        sessionTime
        updatedAt
        participantNotes
        reflectionSurvey {
          completionDate
          dismissalDate
          answers {
            answerData
          }
        }
      }
      imagingSessions {
        id
        status
        acuityConfirmationNumber
        sessionTime
        updatedAt
        participantNotes
      }
    }
  }
`;

export const GetIndexData = gql`
  query GetIndexData {
    me {
      id
      brainGaugeAccessCode
      currentCycle {
        userAssessmentCycle {
          status
        }
      }
    }
  }
`;

export const GetCycles = gql`
  query GetCycles {
    participantAssessmentCycles {
      id
      bhiRefactorPillarScores {
        pillarName
        value
        bhiRefactorUserAssessments {
          name
          scores {
            scoreName
            scoreType
            value
          }
        }
      }
      cycleCompletionDate
      cycleNumber
      status
      statusDates {
        score_finalized
      }
      aceCompleted
    }
  }
`;

export const GetFactors = gql`
  query GetFactors {
    factors {
      name
      description
      subFactors {
        name
        description
        longDescription
      }
    }
  }
`;
