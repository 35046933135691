import React from "react";
import { Hidden } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";

import PageOne from "./Pages/PageOne";
import PageTwo from "./Pages/PageTwo";
import PageThree from "./Pages/PageThree";
import PageFour from "./Pages/PageFour";
import { StyledHeader } from "../../styled";
import {
  BackButton,
  Button,
  ContentCarousel,
  StyledGrid
} from "components/simple";
import { color } from "style/constants";
import { StartUserAssessments } from "graphql/userAssessments/userAssessments.gql";

const Carousel: React.FC = () => {
  const [startUserAssessments] = useMutation(StartUserAssessments);

  const pages = [
    {
      component: <PageOne />,
      buttonText: "Next"
    },
    {
      component: <PageTwo />,
      buttonText: "Next",
      showPrevious: true
    },
    {
      component: <PageThree />,
      buttonText: "Next",
      showPrevious: true
    },
    {
      component: <PageFour />,
      buttonText: "I'm ready!",
      showPrevious: true,
      link: "/assessments/group",
      callback: startUserAssessments
    }
  ];

  return (
    <>
      <Hidden mdDown>
        <BackButton location="/dashboard" />
      </Hidden>
      <StyledGrid
        alignItems="center"
        backgroundColor={color.WHITE}
        container
        direction="column"
        fillRemainingHeight
        justifyContent="center"
      >
        <Hidden lgUp>
          <ContentCarousel content={pages} />
        </Hidden>
        <Hidden mdDown>
          <StyledGrid item>
            <StyledHeader>Before you begin...</StyledHeader>
          </StyledGrid>
          <StyledGrid container item justifyContent="space-around">
            <StyledGrid
              alignContent="center"
              container
              direction="column"
              item
              maxWidth="225px"
            >
              <PageOne />
            </StyledGrid>
            <StyledGrid
              alignContent="center"
              container
              direction="column"
              item
              maxWidth="225px"
            >
              <PageTwo />
            </StyledGrid>
            <StyledGrid
              alignContent="center"
              container
              direction="column"
              item
              maxWidth="225px"
            >
              <PageThree />
            </StyledGrid>
            <StyledGrid
              alignContent="center"
              container
              direction="column"
              item
              maxWidth="225px"
            >
              <PageFour />
            </StyledGrid>
          </StyledGrid>
          <StyledGrid item>
            <Link to="/assessments/group">
              <Button onClick={() => startUserAssessments()} wide>
                I&apos;m ready!
              </Button>
            </Link>
          </StyledGrid>
        </Hidden>
      </StyledGrid>
    </>
  );
};

export default Carousel;
