import { color } from "style/constants";

export default {
  root: {
    paddingBottom: "10px",
    paddingLeft: "13px"
  },
  underline: {
    "&:after": {
      borderBottomColor: "black",
      // easily overrides all different states of underline to maintain 1px width
      borderBottomWidth: "1px !important",
      transform: "scaleX(1)"
    },
    "&:before": {
      borderBottomWidth: "1px !important"
    },
    "&$disabled": {
      "&:after": {
        borderBottomColor: color.LIGHTERGRAY
      }
    },
    "&$error": {
      "&:after": {
        borderBottomColor: color.LIGHTRED
      }
    },
    "&$focused": {
      "&:after": {
        borderBottomColor: color.BLUE
      }
    }
  }
};
