import React from "react";
import { StyledGrid, Tooltip } from "components/simple";
import { ReactComponent as ChevronDown } from "assets/chevron-down.svg";
import { color, fontSize, spacingDefaults, iconSizes } from "style/constants";
import styled from "styled-components";

interface Props {
  subtext: string;
  additionalInfo?: string | string[];
}

export const StyledH2 = styled.h2`
  color: ${color.DARKERGRAY};
  font-size: ${fontSize.normal};
  font-weight: normal;
  line-height: normal;
  white-space: pre-line;
`;

const Subtext: React.FC<Props> = ({ subtext, additionalInfo }: Props) => {
  return (
    <StyledGrid
      item
      marginBottom={spacingDefaults.normal}
      mobileMarginTop="0"
      mobileMaxWidth={"274px"}
    >
      {additionalInfo ? (
        <Tooltip
          content={additionalInfo}
          transformVertical={"top"}
          anchorVertical={"bottom"}
          maxWidth={"400px"}
        >
          <>
            <StyledH2>{subtext}</StyledH2>
            <StyledGrid item marginLeft={spacingDefaults.xsmall}>
              <ChevronDown fill={color.CARDGRAY} height={iconSizes.smaller} />
            </StyledGrid>
          </>
        </Tooltip>
      ) : (
        <StyledH2>{subtext}</StyledH2>
      )}
    </StyledGrid>
  );
};

export default Subtext;
