import { Box, useMediaQuery } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as ImagingSessionImage } from "assets/mrimachine.svg";
import { Avatar, StyledGrid } from "components/simple";
import Logo from "components/simple/Logo";
import { getUser } from "state";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import {
  CoachingSessionStatus,
  ImagingSession
} from "components/pages/Index/CycleSummary/types";

export interface ImagingSessionSummaryProps {
  index: number;
  imagingSession: ImagingSession;
  acuityConfirmationNumber: number;
}

const ImagingSessionSummary: React.FC<ImagingSessionSummaryProps> = ({
  index,
  imagingSession,
  acuityConfirmationNumber
}: ImagingSessionSummaryProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const user = useSelector(getUser);

  const sessionDate = new Date(imagingSession.sessionTime);

  const showSummary = imagingSession.status === CoachingSessionStatus.COMPLETED;

  const summaryDate = format(
    new Date(imagingSession.updatedAt),
    "MMMM d, yyyy"
  );
  const summary = imagingSession.participantNotes;

  return (
    <StyledGrid
      backgroundColor={color.WHITE}
      border={`1px solid ${color.MUTEDGRAY}`}
      borderRadius="8px"
      container
      overflow="hidden"
    >
      {imagingSession.status === CoachingSessionStatus.MISSED ? (
        <StyledGrid container>
          <StyledGrid
            alignItems="center"
            backgroundColor={color.LIGHTERGRAY}
            container
            item
            padding={spacingDefaults.medium}
            style={{ gap: spacingDefaults.medium }}
            xs={12}
          >
            <ImagingSessionImage
              display="flex"
              height="100%"
              style={{ flex: "1" }}
            />
            <StyledGrid>
              <Box
                color={color.BLACK}
                component="p"
                fontSize={fontSize.medium}
                fontWeight="600"
                lineHeight={fontSize.large}
                margin="0"
              >
                Imaging Session
              </Box>
              <Box
                color={color.BLACK}
                component="p"
                fontWeight="400"
                lineHeight={fontSize.large}
                margin="0"
              >
                Skipped
              </Box>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      ) : (
        <StyledGrid width="100%">
          <StyledGrid
            alignItems="center"
            backgroundColor={color.LIGHTERBLUE}
            container
            item
            padding={spacingDefaults.medium}
            style={{ gap: spacingDefaults.medium }}
            xs={12}
          >
            <ImagingSessionImage
              display="flex"
              height="100%"
              style={{ flex: "1" }}
            />
            <StyledGrid item xs={9}>
              <Box
                color={color.BLACK}
                component="p"
                fontSize={fontSize.medium}
                fontWeight="600"
                lineHeight={fontSize.large}
                margin="0"
              >
                Imaging Session
              </Box>

              <Box
                color={color.LIGHTGRAY}
                component="p"
                fontSize={fontSize.small}
                margin="0"
              >
                {`${format(sessionDate, "MMMM d, yyyy")}`}
              </Box>
            </StyledGrid>
          </StyledGrid>

          <StyledGrid
            container
            item
            padding={mobile ? spacingDefaults.medium : spacingDefaults.xlarge}
            xs={12}
          >
            <>
              <StyledGrid container item xs={12}>
                <StyledGrid item width="45px">
                  <Logo styleType="coachingCall" height="5px" width="20px" />
                </StyledGrid>

                <StyledGrid item paddingTop="7px" xs>
                  <Box
                    color={color.BLACK}
                    component="p"
                    fontSize={fontSize.normal}
                    fontWeight="600"
                    lineHeight={fontSize.large}
                    margin="0"
                  >
                    Thank you for completing your imaging session!
                  </Box>

                  {showSummary && (
                    <>
                      <Box
                        color={color.LIGHTGRAY}
                        component="p"
                        fontSize={fontSize.small}
                        margin={`0 0 ${spacingDefaults.normal} 0`}
                      >
                        {summaryDate}
                      </Box>

                      <Box
                        color={color.BLACK}
                        component="p"
                        fontSize={fontSize.small}
                        lineHeight={fontSize.large}
                        margin="0"
                      >
                        {summary}
                      </Box>
                    </>
                  )}
                </StyledGrid>

                {!showSummary && (
                  <StyledGrid container item justifyContent="center" xs={12}>
                    <Box
                      component="p"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="25px"
                      maxWidth="250px"
                      textAlign="center"
                    >
                      <em>
                        You will receive an email when your imaging summary is
                        available.
                      </em>
                    </Box>
                  </StyledGrid>
                )}

                <StyledGrid
                  container
                  item
                  marginTop={spacingDefaults.large}
                  xs={12}
                >
                  <StyledGrid item width="45px">
                    <Avatar
                      profileImageKey={user?.profileImageKey || undefined}
                      firstName={user?.firstName}
                      lastName={user?.lastName}
                    />
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </>
          </StyledGrid>
        </StyledGrid>
      )}
    </StyledGrid>
  );
};

export default ImagingSessionSummary;
