import React from "react";
import { Link } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";

import { ReactComponent as Clock } from "assets/clock.svg";
import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import routesConfig from "utils/routesConfig";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";
import { StyledCoin, StyledTimeEstimate } from "../styled";
import { Button, StyledGrid } from "components/simple";
import { toLowerDashCase } from "components/pages/Contentful/utils";
import { StyledCard } from "../../styled";
import OrnamentIcon from "components/simple/Ornament";

export interface TaskItemType {
  title: string;
  subtitle?: string;
  ornament?: string;
  coinsAmount?: number;
  estimatedTimeMinutes?: number;
  type: TaskListType;
  isCompleted: boolean;
  hasCompletedHabit?: boolean;
}

export type TaskListType =
  | "assessments"
  | "training"
  | "resource"
  | "habit"
  | "survey"
  | "optedOut"
  | "optedOutHideConsentForm"
  // BrainHQ Training
  | "brainHq"
  | "brainGauge"
  | "brainHqAssessment"
  | "transferToBHP"
  | "NotransferToBHP"
  | "updateApp";

interface TaskProps {
  task: TaskItemType;
  disabled: boolean;
  hasCompletedHabit?: boolean;
  allowTransfer?: boolean;
  notAllowTransfer?: boolean;
}

const CompletedTask = ({ task }: Partial<TaskProps>) => {
  return (
    <StyledGrid
      container
      minHeight="60px"
      alignItems="center"
      padding={`0 ${spacingDefaults.normal}`}
      justifyContent="space-between"
      textAlign="left"
    >
      <StyledGrid
        container
        item
        width="75%"
        style={{ gap: spacingDefaults.xsmall }}
      >
        {task?.ornament && (
          <StyledGrid item marginTop="3px">
            <OrnamentIcon
              ornament={task.ornament}
              isCompleted={task.isCompleted}
            />
          </StyledGrid>
        )}
        <Box color={color.GRAPHITE} fontSize={fontSize.medium}>
          {`${task?.title}` + (task?.subtitle ? ` - ${task?.subtitle}` : "")}
        </Box>
      </StyledGrid>
      <StyledGrid item alignSelf="center">
        <CheckIcon fill={color.GREEN} height={40} width={40} />
      </StyledGrid>
    </StyledGrid>
  );
};

const TaskItem: React.FC<TaskProps> = ({
  task,
  disabled,
  hasCompletedHabit,
  allowTransfer,
  notAllowTransfer
}: TaskProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const linkTo = (taskType: TaskListType) => {
    switch (taskType) {
      case "assessments":
        return "/assessment-overview";
      case "habit":
        return hasCompletedHabit ? "/track-your-habits" : "/habit-setup";
      case "resource":
        return "/resources";
      case "survey":
        return routesConfig.surveys.survey.path;
      case "training":
        return task?.subtitle
          ? `/course/${toLowerDashCase(task.subtitle.split(":")[0])}`
          : "/training";
      case "optedOut":
        return "/consent/form?page=1";
      case "optedOutHideConsentForm":
        return "/consent/join";
      case "brainHq":
        return "/training/brain_hq";
      case "brainGauge":
        return "/braingauge";
      case "brainHqAssessment":
        return "/training/brain_hq_assessment";
      case "transferToBHP":
        return "/consent/form?page=1&allowTransfer=True";
      default:
        return "/";
    }
  };

  // helper function to bold text if double asterisks are present
  const boldText = (text: string) => {
    const textArray = text.split("**");
    return (
      <>
        {textArray[0]}
        <b>{textArray[1]}</b>
        {textArray[2]}
      </>
    );
  };

  return (
    <StyledCard>
      {task.isCompleted ? (
        <CompletedTask task={task} />
      ) : (
        <StyledGrid
          container
          minHeight="100px"
          justifyContent="space-between"
          padding={spacingDefaults.normal}
        >
          <StyledGrid item textAlign="left" alignSelf="center">
            <Box fontSize={fontSize.medium}>{task.title}</Box>
            {task?.subtitle && (
              <Box
                fontSize={fontSize.small}
                margin={`${spacingDefaults.xsmall} 0`}
                style={{
                  width: mobile ? "180px" : "100%"
                }}
              >
                {task?.subtitle.includes("**")
                  ? boldText(task?.subtitle)
                  : task.subtitle}
              </Box>
            )}
            <StyledGrid
              container
              alignItems="center"
              style={{ gap: spacingDefaults.xsmall }}
            >
              {task?.ornament && (
                <OrnamentIcon
                  ornament={task.ornament}
                  isCompleted={task.isCompleted}
                />
              )}
              {task?.coinsAmount && (
                <StyledCoin
                  label={`${task.coinsAmount} coins`}
                  size="small"
                  color="primary"
                />
              )}
              {task?.estimatedTimeMinutes && (
                <StyledTimeEstimate
                  icon={<Clock />}
                  label={`${task.estimatedTimeMinutes} min`}
                  size="small"
                  color="primary"
                />
              )}
            </StyledGrid>
          </StyledGrid>
          <StyledGrid
            item
            alignSelf={mobile ? "top" : "center"}
            margin={`${spacingDefaults.normal} 0`}
          >
            <Link to={linkTo(task.type)}>
              {task.type !== "NotransferToBHP" && task.type !== "updateApp" && (
                <Button
                  inverted
                  width={mobile ? "100px" : "100%"}
                  disabled={disabled}
                >
                  Start
                </Button>
              )}
            </Link>
          </StyledGrid>
        </StyledGrid>
      )}
    </StyledCard>
  );
};
export default TaskItem;
