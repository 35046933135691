import React from "react";
import { useHistory } from "react-router";
import routesConfig from "utils/routesConfig";
import { Video } from "components/simple";
import { useMediaQuery } from "@material-ui/core";
import { breakpoints } from "style/constants";
import { useSelector } from "react-redux";
import { getUser } from "state";

const getVideoUrl = (orgName: string | undefined) => {
  switch (orgName) {
    case "dod-controlSmart":
    case "dod-controlBrainHQ":
      return "https://videos.ctfassets.net/n7r832fbv3mz/3c2VWwfczwf9LwwLmREJhf/98bd0df1ea2dd0794b7fc35e36ac238a/WLCIntro_01.mp4";
    case "dod-activeSmart":
    case "dod-activeBrainHQ":
      return "https://videos.ctfassets.net/n7r832fbv3mz/5TjvYOig5iZCioTNt5kqc2/eb701463f31e7a1b57a1dd5d6cdd0a90/ActiveGroups_A_B_01.mp4";
    default:
      return "https://videos.ctfassets.net/n7r832fbv3mz/yjlXUfoqo8P4fU35cg2GW/7f7215e453449b1d7249e89ae8d76fd8/Introduction_to_the_BrainHealth_Index.mp4";
  }
};

const IntroVideo: React.FC = () => {
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const user = useSelector(getUser);
  const orgName = user?.organization?.name;

  return (
    <>
      <Video
        assessmentTitle={mobile ? "Introduction to the BrainHealth Index™" : ""}
        title={mobile ? "" : "Introduction to the BrainHealth Index™"}
        url={getVideoUrl(orgName)}
        actionLabel="Continue"
        onAction={() =>
          user?.enrollmentConsent
            ? history.push(routesConfig.notificationPreferences.path)
            : history.push(routesConfig.dashboard.path)
        }
      />
    </>
  );
};

export default IntroVideo;
