import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, StyledGrid } from "components/simple";
import {
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import { BrainGaugeData } from ".";

const BrainGaugeCard: React.FC<BrainGaugeData> = ({
  accessCode,
  siteCoordinator,
  userLocation
}: BrainGaugeData) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid container>
      <StyledGrid item marginBottom={spacingDefaults.normal} xs={12}>
        <Box
          color={color.BLACK}
          component="h2"
          fontSize="20px"
          fontWeight="normal"
          lineHeight="24px"
          margin="0"
        >
          More Cortical Metrics
        </Box>
      </StyledGrid>

      <StyledGrid
        container
        backgroundColor={color.WHITE}
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        padding={spacingDefaults.large}
        xs={12}
      >
        <StyledGrid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledGrid item width={mobile ? "100%" : "70%"}>
            <Box fontFamily={fontFamily.secondary} fontSize="22px">
              Begin your <b> Brain Gauge Assessment</b>
            </Box>
            <StyledGrid item marginTop={spacingDefaults.normal}>
              Use the code{" "}
              <Box component="span" fontWeight="bold">
                {accessCode}
              </Box>{" "}
              to access Cortical Metrics Brain Gauge Platform. This is the
              fastest most accurate way to track improvements to your mental
              fitness. To find a Brain Gauge sensor, please contact your site
              coordinator
              <span>{userLocation && ` for ${userLocation}`}</span>:
            </StyledGrid>
            {siteCoordinator && (
              <StyledGrid item marginTop={spacingDefaults.small}>
                <address>
                  <Box>{siteCoordinator.name}</Box>
                  <Box>{siteCoordinator.email}</Box>
                  <Box>{siteCoordinator.phone}</Box>
                </address>
              </StyledGrid>
            )}
            <StyledGrid item marginTop="0"></StyledGrid>
            <StyledGrid item marginTop="0"></StyledGrid>
          </StyledGrid>
          <StyledGrid item margin={spacingDefaults.normal}>
            <Link to="/braingauge">
              <Button>Learn More</Button>
            </Link>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default BrainGaugeCard;
