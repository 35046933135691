import React from "react";
import { Grid, Hidden } from "@material-ui/core";

import GreenClock from "assets/green-clock.png";
import { StyledText, StyledHeader } from "../styled";

const Begin: React.FC = () => {
  return (
    <>
      <Hidden mdUp>
        <Grid style={{ height: "3.5rem" }} />
      </Hidden>
      <Grid item>
        <img alt="DietID Logo" height={206} src={GreenClock} width={206} />
      </Grid>
      <Grid item>
        <StyledHeader>How long will it take?</StyledHeader>
        <Hidden smDown>
          <Grid
            item
            style={{
              width: "620px",
              padding: "0 1rem",
              marginBottom: "2rem"
            }}
          >
            {" "}
            <StyledText>
              The assessment usually takes less than 5 minutes. You will take
              the DietID assessment 3 times: today, in 3 months, and then in 6
              months. <br /> <br />
              Weekly challenges will be emailed to you, and you will have access
              to digital coaching and tracking within the website.
            </StyledText>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item style={{ padding: "0 1rem" }}>
            <StyledText>
              The assessment usually takes less than 5 minutes. You will take
              the DietID assessment 3 times: today, in 3 months, and then in 6
              months. <br /> <br />
              Weekly challenges will be emailed to you, and you will have access
              to digital coaching and tracking within the website.
            </StyledText>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default Begin;
