import React from "react";

import { StyledP } from "./styled";
import { breakpoints, spacingDefaults } from "style/constants";
import { Ruler, StyledGrid } from "components/simple";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getNativeExperience } from "state";

const currentYear = new Date().getFullYear();

const Footer: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const mobileNavFooterHeight =
    document.querySelector(".mobile-nav-footer")?.clientHeight || 60;

  const isNativeExperience = useSelector(getNativeExperience);

  if (isNativeExperience) {
    return <></>;
  }

  return (
    <StyledGrid
      className="app-footer"
      container
      direction="column"
      item
      marginBottom={mobile ? `${mobileNavFooterHeight}px` : undefined}
      padding={`${spacingDefaults.normal} 0`}
      textAlign="center"
      zIndex="1"
    >
      <Ruler margins={spacingDefaults.xsmall} />
      <StyledP>
        &copy;{currentYear} Center for BrainHealth (UTD), all rights reserved.
      </StyledP>
    </StyledGrid>
  );
};

export default Footer;
