import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, useMediaQuery } from "@material-ui/core";

import { ReactComponent as CheckIcon } from "assets/check-filled.svg";
import {
  breakpoints,
  color,
  fontFamily,
  fontSize,
  spacingDefaults
} from "style/constants";
import { Button, Confetti, StyledGrid } from "components/simple";
import { awardCoinType } from "graphql/types/Contentful";
import CoinModal from "components/simple/CoinModal";

const ResourceSurveyComplete: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();

  const { state } = useLocation<{
    coinsAwarded: awardCoinType[];
  }>();
  if (!state) history.push("/dashboard");

  const coinsAwarded = state?.coinsAwarded;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(
    coinsAwarded?.length > 0
  );

  return (
    <>
      <StyledGrid
        container
        centerContent
        padding={`0 ${spacingDefaults.normal}`}
      >
        <Confetti />
        <StyledGrid maxWidth="450px">
          <StyledGrid marginTop="30%" marginBottom={spacingDefaults.medium}>
            {<CheckIcon fill={color.GREEN} height={120} width={120} />}
          </StyledGrid>
          <StyledGrid margin={`${spacingDefaults.xsmall} 0`}>
            <Box
              color={color.BLACK}
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.header}
            >
              <strong>{"Resource Completed!"}</strong>
            </Box>
          </StyledGrid>
          <StyledGrid margin={`${spacingDefaults.normal} 0`}>
            <Button onClick={() => history.push("/dashboard")}>
              Back to dashboard
            </Button>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
      <CoinModal
        openModal={isModalOpen}
        coinsAwarded={coinsAwarded}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
export default ResourceSurveyComplete;
