import { useApolloClient } from "@apollo/react-hooks";
import { Box, IconButton } from "@material-ui/core";
import { CheckCircle, Facebook, LinkedIn, Twitter } from "@material-ui/icons";
import format from "date-fns/format";
import React from "react";
import { useDispatch } from "react-redux";

import { CircularProgress, StyledGrid, StyledP } from "components/simple";
import { showErrorNotification } from "state";
import { color, fontSize, spacingDefaults } from "style/constants";
import {
  handleFacebookClick,
  handleLinkedInClick,
  handleTwitterClick
} from "./util";

interface FullBadge {
  achievedAt: Date;
  badgeImageKey: string;
  description?: string;
  displayName: string;
  name: string;
  backgroundColor?: string;
  userHabit?: {
    streakCount: number;
    maxStreakCount: number;
  };
  referralLink: string;
}

const FullBadge: React.FC<FullBadge> = ({
  achievedAt,
  badgeImageKey,
  description,
  displayName,
  name,
  backgroundColor,
  userHabit,
  referralLink
}: FullBadge) => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const encodedUri = encodeURI(referralLink);

  const level = name.includes("habit_") ? name.split("_")[2] : null;

  return (
    <StyledGrid container item centerContent direction="row">
      <StyledGrid
        border={`1px solid ${color.MUTEDGRAY}`}
        borderRadius="8px"
        justifyContent="space-between"
        container
        alignItems="center"
        height="100%"
        item
        overflow="hidden"
        position="relative"
      >
        <StyledGrid
          container
          item
          width="40%"
          height="100%"
          centerContent
          alignContent="center"
          padding={spacingDefaults.large}
          style={{ backgroundColor: `${backgroundColor}0D` ?? color.ICON }}
        >
          <img src={badgeImageKey} alt={`${displayName} badge`} width="100%" />
        </StyledGrid>

        {userHabit && (
          <StyledGrid position="absolute" left="8px" bottom="8px">
            <CircularProgress
              value={userHabit.streakCount}
              total={userHabit.maxStreakCount}
              color={backgroundColor}
              size="70px"
              thickness={5}
            >
              <Box color={color.BLACK} component="p" margin="0">
                {userHabit.streakCount}/{userHabit.maxStreakCount}
              </Box>
            </CircularProgress>
          </StyledGrid>
        )}

        <StyledGrid
          item
          width="60%"
          padding={`${spacingDefaults.large} ${spacingDefaults.xsmall} ${spacingDefaults.xsmall} ${spacingDefaults.large}`}
        >
          <StyledP
            color={color.DARKERGRAY}
            fontSize={fontSize.xsmall}
            margin={`0 0 ${spacingDefaults.xsmall} 0`}
          >
            BrainHealth Badge
          </StyledP>
          <StyledP
            color={color.BLACK}
            fontSize={fontSize.small}
            fontWeight="500"
          >
            {displayName}
          </StyledP>
          {level && (
            <StyledP
              color={color.BLACK}
              fontSize={fontSize.small}
              fontWeight="300"
            >
              Level {level}
            </StyledP>
          )}
          {description && (
            <StyledP
              color={color.DARKERGRAY}
              fontSize={fontSize.xsmall}
              fontWeight="300"
              margin={`${spacingDefaults.xsmall} 0 0 0`}
            >
              {description}
            </StyledP>
          )}

          <StyledGrid
            container
            justifyContent="flex-start"
            marginTop={spacingDefaults.medium}
            marginBottom={spacingDefaults.small}
          >
            <CheckCircle fontSize="small" htmlColor={color.GREEN} />
            <StyledP
              fontSize={fontSize.xsmall}
              fontWeightStrong="500"
              margin={`0 0 0 ${spacingDefaults.xsmall}`}
            >
              <strong>Achieved </strong> on{" "}
              {format(new Date(achievedAt), "MMM d, yyyy")}
            </StyledP>
          </StyledGrid>

          <StyledGrid container justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                try {
                  handleLinkedInClick(client, badgeImageKey, referralLink);
                } catch (error) {
                  dispatch(showErrorNotification("", error));
                }
              }}
            >
              <LinkedIn />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => handleFacebookClick(referralLink)}
            >
              <Facebook />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => handleTwitterClick(referralLink)}
            >
              <Twitter />
            </IconButton>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default FullBadge;
