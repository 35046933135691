import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, IconButton, useMediaQuery } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as Warning } from "assets/warning-red.svg";
import {
  addBackgroundColorToRoot,
  breakpoints,
  color,
  fontFamily,
  fontSize,
  removeBackgroundColorFromRoot,
  spacingDefaults
} from "style/constants";
import {
  Button,
  ConfirmationModal,
  Loading,
  RadioCard,
  StyledGrid
} from "components/simple";
import {
  GetHabitSurvey,
  MarkUserHabitComplete,
  SaveHabitSurveyAnswers
} from "graphql/userHabits/userHabits.gql";
import {
  HabitSurveyData,
  HabitSurvey as HabitSurveyType,
  UserHabit
} from "models/userHabits";
import routesConfig from "utils/routesConfig";
import { SurveyAnswers } from "graphql/types/Contentful";
import { showErrorNotification } from "state";
import { useDispatch } from "react-redux";
import HabitSurveyProgressBar from "./HabitSurveyProgressBar";
import { GetSubHeaderData } from "graphql/user/user.gql";

const HabitSurvey: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const history = useHistory();
  const dispatch = useDispatch();

  const { state } = useLocation<{ state: UserHabit }>();
  if (!state) history.push(routesConfig.habitSetup.trackYourHabits.path);

  const userHabit = state.state;

  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswers[]>([]);
  const [habitSurvey, setHabitSurvey] = useState<HabitSurveyType>();
  const [selectedAnswer, setSelectedAnswer] = useState<SurveyAnswers>({
    questionId: "",
    answer: ""
  });
  const hasSelectedAnswer = selectedAnswer?.answer !== "";

  const { loading } = useQuery<HabitSurveyData>(GetHabitSurvey, {
    onCompleted: data => setHabitSurvey(data?.habitSurvey)
  });

  const [saveSurveyAnswers] = useMutation(SaveHabitSurveyAnswers, {
    onCompleted: () => {
      setQuestionIndex(0);
      setSurveyAnswers([]);
    }
  });
  const [markHabitAsCompleted] = useMutation(MarkUserHabitComplete, {
    refetchQueries: [{ query: GetSubHeaderData }]
  });

  const nextQuestion = (selectedAnswer: SurveyAnswers) => {
    setQuestionIndex(questionIndex + 1);
    setSurveyAnswers([
      ...surveyAnswers,
      { questionId: selectedAnswer.questionId, answer: selectedAnswer.answer }
    ]);
  };

  const completeSurvey = (selectedAnswer: SurveyAnswers) => {
    setSurveyAnswers([
      ...surveyAnswers,
      { questionId: selectedAnswer.questionId, answer: selectedAnswer.answer }
    ]);
  };

  useEffect(() => {
    if (habitSurvey && surveyAnswers.length === habitSurvey?.questions.length)
      saveAndCompleteSurvey();
  }, [surveyAnswers]);

  const saveAndCompleteSurvey = async () => {
    try {
      await saveSurveyAnswers({
        variables: {
          input: {
            userHabitsId: userHabit.id,
            surveyAnswers
          }
        }
      });
    } catch (e) {
      dispatch(showErrorNotification("Error", e));
    }

    try {
      const { data } = await markHabitAsCompleted({
        variables: {
          input: {
            userHabitId: userHabit.id
          }
        }
      });
      history.push("/habit-survey/complete", {
        coinsAwarded: data?.markUserHabitComplete?.coinsAwarded,
        userHabit: userHabit
      });
    } catch (e) {
      dispatch(showErrorNotification("Error", e));
    }
  };

  addBackgroundColorToRoot(color.WHITE);
  useEffect(() => {
    return () => {
      removeBackgroundColorFromRoot();
    };
  }, []);

  useEffect(() => {
    setSelectedAnswer({ questionId: currentQuestion?.id || "", answer: "" });
  }, [questionIndex]);

  const currentQuestion = habitSurvey?.questions[questionIndex];

  if (loading) return <Loading />;

  return (
    <>
      <HabitSurveyProgressBar
        numberCompleted={questionIndex}
        numberOfQuestions={habitSurvey?.questions?.length || 0}
      />
      <StyledGrid position="absolute" margin={spacingDefaults.normal}>
        <IconButton size="medium" onClick={() => setShowExitModal(true)}>
          <CloseIcon />
        </IconButton>
      </StyledGrid>
      <StyledGrid container centerContent>
        <StyledGrid
          item
          style={{
            maxWidth: "500px",
            padding: `0 ${spacingDefaults.normal}`
          }}
        >
          <StyledGrid item margin="4rem 0">
            <img
              src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                userHabit.streakLevel +
                (userHabit?.streakCount === userHabit?.maxStreakCount ? 0 : -1)
              }.png`}
              height="200px"
            />
            <Box fontFamily={fontFamily.secondary} fontSize={fontSize.header}>
              {userHabit.habit?.name}
            </Box>
            <Box
              fontSize={fontSize.normal}
              margin={`${spacingDefaults.normal} 0`}
              color={color.GRAPHITE}
            >
              {userHabit.habit?.content}
            </Box>
          </StyledGrid>
          <StyledGrid item>
            <Box
              fontFamily={fontFamily.secondary}
              fontSize={fontSize.large}
              margin={`${spacingDefaults.normal} 0`}
            >
              {currentQuestion?.question}
            </Box>
            <RadioCard
              column
              icons={
                currentQuestion?.possibleAnswers?.map(
                  item => item?.iconImage || ""
                ) || []
              }
              selections={
                currentQuestion?.possibleAnswers?.map(item => item.answer) || []
              }
              onChange={(_, value) => {
                setSelectedAnswer({
                  questionId: currentQuestion?.id || "",
                  answer: value
                });
              }}
              value={selectedAnswer.answer}
            />
          </StyledGrid>
          <StyledGrid item margin={`${spacingDefaults.medium} 0`}>
            <Button
              stickyFooter={mobile ? true : false}
              borderRadius={mobile ? "0px" : undefined}
              zIndex={2}
              disabled={!hasSelectedAnswer}
              width={mobile ? undefined : "200px"}
              onClick={() => {
                if (habitSurvey) {
                  questionIndex === habitSurvey?.questions.length - 1
                    ? completeSurvey(selectedAnswer)
                    : nextQuestion(selectedAnswer);
                }
              }}
            >
              {habitSurvey &&
              questionIndex === habitSurvey?.questions.length - 1
                ? "Complete"
                : "Next"}
            </Button>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
      {showExitModal && (
        <ConfirmationModal
          icon={<Warning width={"150px"} height={"150px"} />}
          title={"Are you sure you want to exit?"}
          content={`You will lose all of your progress if you exit now.`}
          confirmText={"Yes, exit survey"}
          onConfirm={() => {
            history.push("/track-your-habits");
          }}
          cancelText={"Oh, wait. Never mind"}
          onCancel={() => setShowExitModal(false)}
        />
      )}
    </>
  );
};
export default HabitSurvey;
