import { Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";

const StyledCircularProgress = styled(CircularProgress)`
  && {
    color: ${color.MUTEDGRAY};
  }
`;

interface AssessmentsInProgressProps {
  daysToComplete: number;
  daysRemaining: number;
  assessmentsRemaining: number;
}
const AssessmentsInProgress: React.FC<AssessmentsInProgressProps> = ({
  daysToComplete,
  daysRemaining,
  assessmentsRemaining
}: AssessmentsInProgressProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <>
      <StyledGrid
        alignItems="center"
        container
        direction="column"
        height={mobile ? "180px" : "245px"}
        justifyContent="center"
        marginBottom={mobile ? spacingDefaults.medium : spacingDefaults.large}
        position="relative"
      >
        <Box position="absolute">
          <StyledCircularProgress
            size={mobile ? "180px" : "245px"}
            thickness={2}
            variant="determinate"
            value={100}
          />
        </Box>

        <Box position="absolute">
          <CircularProgress
            color="secondary"
            size={mobile ? "180px" : "245px"}
            thickness={2}
            variant="determinate"
            value={(daysRemaining / daysToComplete) * 100}
          />
        </Box>

        <Box
          color={color.DARKBLUE}
          component="p"
          fontSize="60px"
          lineHeight="60px"
          margin={`0 0 ${spacingDefaults.xsmall} 0`}
        >
          {daysRemaining}
        </Box>

        <Box
          color={color.DARKBLUE}
          component="p"
          fontSize="20px"
          lineHeight="20px"
          margin="0"
        >
          day{daysRemaining > 1 ? "s" : ""} left
        </Box>
      </StyledGrid>

      <Box
        color={color.DARKERGRAY}
        component="p"
        fontSize="14px"
        lineHeight="21px"
        margin="0"
        textAlign="center"
      >
        You have <strong>{assessmentsRemaining}</strong> assessment
        {assessmentsRemaining > 1 ? "s" : ""} left to complete
      </Box>
    </>
  );
};

export default AssessmentsInProgress;
