import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Redirect,
  RouteComponentProps,
  useHistory
} from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import track, { useTracking } from "react-tracking";
import ReactPlayer from "react-player";
import * as Yup from "yup";
import qs from "qs";

import { getSubdomain, showErrorNotification } from "state";
import DashboardHeader from "assets/login-dashboard-header.svg";
import Form from "components/pages/Login/Form";
import { Button, Footer, StyledGrid } from "components/simple";
import { Login, LoginWithToken } from "graphql/user/user.gql";
import { LoginFormValues, User } from "models/user";
import { breakpoints, color, spacingDefaults } from "style/constants";
import { checkAuth } from "utils/auth";
import routesConfig from "utils/routesConfig";
import {
  AccountCallout,
  Callout,
  ImageOverlay,
  LeftImage,
  StyledHr,
  Title
} from "./styled";
import Logo from "components/simple/Logo";
import { isRefactorEnabled } from "graphql/featureFlags/featureFlags.gql";

const initialFormValues = {
  email: "",
  password: "",
  captchaToken: ""
};

const url = routesConfig.login.path;

const LoginForm: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const dispatch = useDispatch();
  const tracking = useTracking();
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const [login] = useMutation(Login);
  const [loginWithToken] = useMutation(LoginWithToken);
  const subdomain = useSelector(getSubdomain);
  const showVideo = subdomain?.name === "dod";

  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });

  const [isRefactorFeatureEnabled, setIsRefactorEnabled] = useState<boolean>(
    false
  );
  useQuery(isRefactorEnabled, {
    onCompleted: data => setIsRefactorEnabled(data.isRefactorEnabled.enabled)
  });

  const expired = queryParams["expired"];
  const toPath = queryParams["to"] ?? (history.location.state as any)?.to;
  const loginToken = queryParams["loginToken"];

  useEffect(() => {
    const handleLoginWithToken = async () => {
      const loginResponse = await loginWithToken({
        variables: { token: loginToken }
      });
      if (loginResponse.data && loginResponse.data.loginWithToken) {
        tracking.trackEvent({
          url,
          actions: ["Session", "Login With Token Success"]
        });
        localStorage.setItem("token", loginResponse.data.loginWithToken.token);
        history.push(toPath ?? "/dashboard");
      }
    };
    if (loginToken) {
      handleLoginWithToken().catch(() => {
        dispatch(showErrorNotification("Please login to continue"));
      });
    }
  }, [loginToken]);

  useEffect(() => {
    if (expired) {
      dispatch(
        showErrorNotification(
          "Your session has timed out",
          "Log in to continue"
        )
      );
      history.push(url, {
        to: toPath
      });
    }
  }, [expired]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email"),
    password: Yup.string()
  });

  const assumedIdentityToken = queryParams["assumedIdentityToken"];
  if (assumedIdentityToken) {
    localStorage.setItem("assumedIdentityToken", assumedIdentityToken);
    return <Redirect push from="" to="/dashboard" />;
  }

  const isSignedIn = checkAuth();
  if (isSignedIn) {
    return <Redirect push from="" to="/dashboard" />;
  }

  async function submitForm(
    values: LoginFormValues,
    helpers: FormikHelpers<LoginFormValues>
  ): Promise<User | null> {
    const { email, password, captchaToken } = values;
    const loginInput = { email, password, captchaToken };
    try {
      const loginResponse = await login({ variables: { input: loginInput } });
      if (loginResponse.data && loginResponse.data.login) {
        tracking.trackEvent({
          url,
          actions: ["Session", "Login Success"]
        });
        localStorage.setItem("token", loginResponse.data.login.token);
        history.push(toPath ?? "/dashboard");
      }
    } catch (err) {
      if (
        (err as any)?.graphQLErrors[0]?.extensions?.code ===
        "REQUIRE_RESET_PASSWORD"
      ) {
        dispatch(
          showErrorNotification(
            "Your password has expired",
            "Please reset your password"
          )
        );
        history.push("/forgot_password");
      } else if (
        (err as any)?.graphQLErrors[0]?.extensions?.code ===
        "User Account deactivated"
      ) {
        dispatch({
          type: "error",
          title: "Account deactivated",
          description:
            "Your account has been deactivated, please contact support"
        });
      } else {
        const message = (err as any).message.replace("GraphQL error: ", "");
        dispatch(showErrorNotification("Error", message));
        tracking.trackEvent({
          url,
          actions: ["Session", "Login Failed", message]
        });
        return null;
      }
    } finally {
      helpers.setSubmitting(false);
    }
    return null;
  }

  return (
    <StyledGrid backgroundColor={color.WHITE} container minHeight="100vh">
      <Hidden mdDown>
        <LeftImage item lg={6}>
          {showVideo && (
            <StyledGrid
              top="15vh"
              position="absolute"
              padding="3rem"
              width="100%"
              height="50%"
            >
              <ReactPlayer
                controls
                width="100%"
                height="100%"
                light="https://images.ctfassets.net/n7r832fbv3mz/6wUtPwIHp5Myu78dIwj1cD/135156dbb38a2048ab2ae23140f6dbb8/MTEC_Intro_Video_Cover.png"
                url="https://videos.ctfassets.net/n7r832fbv3mz/4z1hi5BGh6RnZGzS18TEAY/29082fa8455fd2f3342da32842835c21/Jz_Mike_MTEC2022_smaller.mp4"
                config={{
                  file: {
                    attributes: {
                      autoplay: "autoplay"
                    }
                  }
                }}
              />
            </StyledGrid>
          )}
          <ImageOverlay />
          <StyledGrid container item justifyContent="center" marginTop="100px">
            <Logo styleType="dark" />
          </StyledGrid>
        </LeftImage>
      </Hidden>
      <StyledGrid container item lg={6} xs={12} margin="auto">
        {isRefactorFeatureEnabled && (
          <StyledGrid
            backgroundImage={DashboardHeader}
            height={mobile ? "160px" : "200px"}
            item
            width="100%"
          />
        )}
        <StyledGrid
          container
          direction="column"
          item
          lg={6}
          xs={12}
          margin="auto"
          height="85%"
          maxWidth={mobile ? "70%" : "553px"}
          padding="0 20px"
          mobilePadding="0"
        >
          <StyledGrid item centerContent={mobile}>
            <Title>Sign In</Title>
            <Callout>Sign into your personal BrainHealth Dashboard</Callout>
          </StyledGrid>
          <Formik
            component={Form}
            initialValues={initialFormValues}
            onSubmit={submitForm}
            validationSchema={LoginSchema}
            validateOnBlur
          />
          <StyledHr />
          <StyledGrid
            alignItems={mobile ? "stretch" : "center"}
            container
            item
            direction={mobile ? "column" : "row"}
          >
            <StyledGrid
              alignSelf={mobile ? "center" : "left"}
              item
              md={5}
              xs={12}
            >
              <AccountCallout>Don&apos;t have an account?</AccountCallout>
            </StyledGrid>
            <StyledGrid item>
              <Link to={"/register"}>
                <Button fullWidth={mobile} inverted>
                  Register
                </Button>
              </Link>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid item marginTop={spacingDefaults.medium}>
            <Footer />
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default track({
  url
})(LoginForm);
