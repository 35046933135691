import React from "react";
import get from "lodash/get";
import track from "react-tracking";

import { GroupQuery } from "graphql/userAssessments/userAssessments.gql";
import { UserAssessment } from "graphql/types/User";
import { useQueryCustom as useQuery } from "hooks";
import Group from "./Group";
import routesConfig from "utils/routesConfig";

const url: string = routesConfig.assessments.group.path;

const GroupPage: React.FC = () => {
  const { data } = useQuery({ input: GroupQuery });
  if (!data) return null;

  const userAssessments: UserAssessment[] = get(data, "getCurrentGroup");
  const groupTitle = get(
    userAssessments[0],
    "assessment.assessmentGrouping.name"
  );
  return <Group userAssessments={userAssessments} groupTitle={groupTitle} />;
};

export default track({
  url
})(GroupPage);
