import { Box, useMediaQuery } from "@material-ui/core";
import React from "react";

import { ReactComponent as ChevronRightIcon } from "assets/chevron-right.svg";
import { Button, StyledGrid } from "components/simple";
import { breakpoints, color, fontSize, spacingDefaults } from "style/constants";

interface ActionButtonProps {
  icon: JSX.Element;
  text: string;
  handleClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  text,
  handleClick
}: ActionButtonProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <Button fullWidth onClick={handleClick} plain>
      <StyledGrid
        alignItems="center"
        container
        justifyContent="space-between"
        padding={`0 ${spacingDefaults.medium}`}
      >
        <StyledGrid
          alignItems="center"
          container
          item
          justifyContent={mobile ? "flex-start" : "center"}
          xs
        >
          {icon}

          <Box
            color={color.DARKERGRAY}
            component="span"
            fontSize={fontSize.small}
            marginLeft={spacingDefaults.normal}
          >
            {text}
          </Box>
        </StyledGrid>

        {mobile && <ChevronRightIcon />}
      </StyledGrid>
    </Button>
  );
};

export default ActionButton;
