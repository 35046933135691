import React from "react";
import ReactMarkdown from "react-markdown";

interface Props {
  children?: string | JSX.Element;
}

const MarkdownContent: React.FC<Props> = ({ children }: Props) => {
  return <ReactMarkdown>{children}</ReactMarkdown>;
};

export default MarkdownContent;
