import { IconButton } from "@material-ui/core";
import { color, spacingDefaults } from "style/constants";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)`
  && {
    background-color: ${color.BLACK};
    margin: ${spacingDefaults.xsmall};
    padding: 0;
    :hover {
      background-color: rgb(36, 69, 86);
    }
  }
`;
