import { Grid } from "@material-ui/core";
import React from "react";

import { StyledGrid } from "components/simple";
import { spacingDefaults } from "style/constants";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import { isMobile } from "../../utils";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import SettingsButton from "../Buttons/SettingsButton/SettingsButton";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleChatButton from "../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleScreenShareButton from "../Buttons/ToggleScreenShareButton/ToggleScreenShareButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";

export default function MenuBar() {
  const roomState = useRoomState();
  const isReconnecting = roomState === "reconnecting";

  return (
    <StyledGrid
      container
      alignItems="center"
      direction={isMobile ? "column" : "row"}
      spacing={1}
      marginTop={spacingDefaults.normal}
    >
      <Grid item xs />
      <Grid container item justifyContent="center" xs>
        <ToggleAudioButton disabled={isReconnecting} />
        <ToggleVideoButton disabled={isReconnecting} />

        {!isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}

        <ToggleChatButton />

        <SettingsButton />
      </Grid>
      <Grid container item justifyContent={isMobile ? "center" : "flex-end"} xs>
        <EndCallButton />
      </Grid>
    </StyledGrid>
  );
}
