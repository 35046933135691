import { useQuery } from "@apollo/react-hooks";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { CheckCircle, ChevronRight } from "@material-ui/icons";
import { isToday } from "date-fns";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";

import { Button, StyledGrid } from "components/simple";
import { GetCurrentUserHabit } from "graphql/user/user.gql";
import { getUserStatus, UserStatus } from "state";
import {
  appWidth,
  breakpoints,
  color,
  fontFamily,
  spacingDefaults
} from "style/constants";
import routesConfig from "utils/routesConfig";
import HabitModal from "./HabitModal";
import StreakStatusPills from "./StreakStatusPills";
import { getComponentColor, getComponentDisplayValue } from "./utils";

export interface UserHabit {
  id: string;
  streakCount: number;
  maxStreakCount: number;
  totalHabitsCompleted: number;
  lastCompletionDate: number;
  streakLevel: number;
  lostStreak: boolean;
  habit: {
    id: string;
    name: string;
    dashboardPreview: string;
    component: string;
    content: string;
    science: string;
  };
}

const url = routesConfig.dashboard.path;

const HabitsBanner: React.FC = () => {
  const location = useLocation();
  const tracking = useTracking();

  const userStatus = useSelector(getUserStatus);

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const [showModal, setShowModal] = useState(queryParams?.habitsModal || false);

  const { data, loading, error, refetch } = useQuery(GetCurrentUserHabit, {
    fetchPolicy: "no-cache"
  });

  if (
    loading ||
    error ||
    !data ||
    !data?.currentUserHabit ||
    userStatus !== UserStatus.QUALIFIED_AND_CONSENTED
  ) {
    return null;
  }

  const userHabit: UserHabit = data.currentUserHabit;

  const {
    streakLevel,
    streakCount,
    maxStreakCount,
    lastCompletionDate,
    habit: { name, component }
  } = userHabit;

  const isCompleted = lastCompletionDate
    ? isToday(new Date(lastCompletionDate))
    : false;

  return (
    <StyledGrid backgroundColor={color.BLACK} container justifyContent="center">
      <StyledGrid
        alignItems="center"
        container
        item
        maxWidth={appWidth.max}
        onClick={mobile ? () => setShowModal(true) : undefined}
        paddingRight={mobile ? "40px" : undefined}
        position="relative"
      >
        {mobile && (
          <Box
            color={color.WHITE}
            position="absolute"
            top="calc(50% - 12px)"
            right="0"
          >
            <ChevronRight />
          </Box>
        )}

        <StyledGrid container>
          <StyledGrid
            height="100%"
            item
            position="relative"
            width={mobile ? "115px" : "145px"}
          >
            <Box position="absolute" top={mobile ? "10px" : "20px"}>
              <img
                src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                  streakLevel + (streakCount === maxStreakCount ? 0 : -1)
                }.png`}
                alt={`The level ${
                  streakLevel + (streakCount === maxStreakCount ? 0 : -1)
                } habit badge`}
                width="100%"
              />
            </Box>
          </StyledGrid>

          <StyledGrid
            container
            item
            margin={`${spacingDefaults.normal} 0 ${spacingDefaults.medium} 0`}
            xs
          >
            <StyledGrid
              alignItems="center"
              container
              item
              marginBottom={spacingDefaults.normal}
            >
              <Grid container direction="column" item xs>
                <Box
                  color={color.WHITE}
                  fontFamily={fontFamily.secondary}
                  fontSize={mobile ? "20px" : "26px"}
                  letterSpacing="-0.5px"
                >
                  BrainHealth Habit Mastery
                </Box>

                <Box color={color.WHITE} fontWeight="700" marginBottom="2px">
                  {getComponentDisplayValue(component)}, Level {streakLevel}
                </Box>

                {!mobile && (
                  <Box color={color.WHITE} fontStyle="italic" fontWeight="400">
                    {name}
                  </Box>
                )}
              </Grid>

              {!mobile && (
                <StyledGrid
                  alignSelf="flex-start"
                  item
                  marginRight="10px"
                  marginTop={spacingDefaults.normal}
                >
                  <Button
                    background={isCompleted ? color.GREEN : undefined}
                    longText
                    onClick={() => {
                      setShowModal(true);

                      tracking.trackEvent({
                        url,
                        actions: ["Habits", "Open Habits Modal"]
                      });
                    }}
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      height: "36px",
                      width: "185px"
                    }}
                  >
                    {isCompleted ? (
                      <>
                        <CheckCircle />
                        <Box marginLeft="8px">Today Completed!</Box>
                      </>
                    ) : (
                      <>
                        <Box marginRight={spacingDefaults.normal}>
                          Complete Habit
                        </Box>
                        &gt;
                      </>
                    )}
                  </Button>
                </StyledGrid>
              )}
            </StyledGrid>

            <StreakStatusPills
              componentColor={getComponentColor(component)}
              streakCount={streakCount}
              maxStreakCount={maxStreakCount}
              isCompleted={isCompleted}
            />
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>

      <HabitModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          tracking.trackEvent({
            url,
            actions: ["Habits", "Close Habits Modal"]
          });
        }}
        userHabit={userHabit}
        isCompleted={isCompleted}
        refetch={refetch}
      />
    </StyledGrid>
  );
};

export default HabitsBanner;
