import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useLayoutEffect, useState } from "react";

import { Ruler, StyledGrid, StyledP } from "components/simple";
import { Logo } from "components/simple/Logo";
import { PillarScore } from "graphql/types/Cycle";
import { UserAssessment } from "graphql/types/User";
import { GetAllCyclesScores } from "graphql/userAssessments/userAssessments.gql";
import { color, fontFamily } from "style/constants";
import { Pillar } from "../../types";
import BarGraph from "../BarGraph";
import MoreInfo from "../MoreInfo";
import DonutPillarGraph from "../Overview/PillarDonutGraph";
import Stepper from "../Stepper";
import { mapScores } from "../utils";

const Header: React.FC = () => {
  return (
    <StyledGrid item container height="50px">
      <StyledGrid item container>
        <StyledGrid item container xs={4}>
          <Logo styleType="pdfSmall" />
        </StyledGrid>
        <StyledGrid item container alignItems="center" xs={8}>
          <StyledP
            fontSize="12px"
            fontFamily={fontFamily.secondary}
            color={color.BLACK}
          >
            Your BrainHealth Index Report
          </StyledP>
        </StyledGrid>
      </StyledGrid>
      <StyledGrid item width="100%">
        <Ruler />
      </StyledGrid>
    </StyledGrid>
  );
};

interface PageProps {
  children: React.ReactNode;
  hideHeader?: boolean;
}
const Page: React.FC<PageProps> = ({ children, hideHeader }: PageProps) => {
  return (
    <StyledGrid
      container
      alignContent="flex-start"
      width="210mm"
      height="297mm"
      border={`solid 1px ${color.PALEGRAY}`}
      backgroundColor={color.WHITE}
      padding="5mm"
      overflow="hidden"
    >
      {hideHeader ? null : <Header />}
      {children}
    </StyledGrid>
  );
};

const PDF: React.FC = () => {
  const { data, loading, error } = useQuery(GetAllCyclesScores);

  const [buildingPDF, setBuildingPDF] = useState(false);

  const downloadPDF = async (): Promise<void> => {
    const pages = document.getElementById("toPrint");
    if (!pages) return;
    setBuildingPDF(true);

    const canvas = await html2canvas(pages, { scale: 1 });

    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const doc = new jsPDF("p", "mm", undefined, true);

    let position = 0;
    let heightLeft = imgHeight;

    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    setBuildingPDF(false);
    doc.save("BrainHealth_Index_&_Assessment_Report.pdf");
  };

  useLayoutEffect(() => {
    if (!loading) downloadPDF();
  }, [loading]);

  if (loading || error) return null;

  const cycleNumber = data.participantAssessmentCycles.length - 1;

  const allCycles = data.participantAssessmentCycles.sort(
    (a: any, b: any) => a.cycleNumber - b.cycleNumber
  );

  const pillarScores = allCycles[cycleNumber].pillarScores;

  const scoresData: Pillar[] = [];
  let BHI: Pillar = mapScores("index", 0, []);

  // builds data structure needed for the graphs
  pillarScores.forEach((pillar: PillarScore) => {
    if (pillar.pillarName === "index") {
      BHI = mapScores(pillar.pillarName, pillar.value, []);
    } else {
      scoresData.push(
        mapScores(
          pillar.pillarName,
          pillar.value,
          pillar.userAssessments as Partial<UserAssessment>[]
        )
      );
    }
  });

  scoresData.sort((a, b) => a.order - b.order);

  return (
    <>
      {buildingPDF && (
        <StyledGrid
          container
          textAlign="center"
          margin="15px"
          itemMargin="5px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={22} color="secondary" />
          Creating PDF...
        </StyledGrid>
      )}

      <StyledGrid id="toPrint" margin="auto" width="210mm">
        <Page hideHeader>
          <StyledGrid item container margin="30px">
            <StyledGrid item margin="0px 100px 100px 100px">
              <StyledGrid
                container
                item
                justifyContent="center"
                marginBottom="64px"
                marginTop="24px"
              >
                <Logo styleType="pdfLarge" />
              </StyledGrid>
              <StyledP
                fontSize="60px"
                lineHeight="60px"
                fontFamily={fontFamily.secondary}
                color={color.BLACK}
                textAlign="center"
              >
                Your BrainHealth Index Report
              </StyledP>
            </StyledGrid>
            <StyledGrid item width="100%">
              <StyledP color={color.BLACK}>
                Your BrainHealth Index &amp; Component Scores
              </StyledP>
              <Ruler margins="10px 0px" />
            </StyledGrid>
            <StyledGrid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
              marginTop="40px"
            >
              <StyledGrid item width="45%" textAlign="center">
                <StyledP
                  textAlign="center"
                  color={color.DARKBLUE}
                  fontSize="50px"
                >
                  {BHI.value}
                </StyledP>
              </StyledGrid>
              <StyledGrid item height="100%">
                <Ruler orientation="vertical" />
              </StyledGrid>
              <StyledGrid
                item
                width="45%"
                justifyContent="center"
                margin="20px"
              >
                <StyledGrid item textAlign="center" margin="55px">
                  <DonutPillarGraph pillars={scoresData} />
                </StyledGrid>
                <StyledGrid
                  item
                  container
                  itemMargin="15px"
                  justifyContent="center"
                >
                  {scoresData.map(data => {
                    return (
                      <StyledGrid item key={data.pillarName}>
                        <StyledP textAlign="center" color={data.color as color}>
                          {data.value}
                        </StyledP>
                        <StyledP textAlign="center">{data.pillarName}</StyledP>
                      </StyledGrid>
                    );
                  })}
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </Page>

        {scoresData.map(data => (
          <>
            <Page>
              <StyledGrid height="50%">
                <BarGraph
                  print
                  pillar={data}
                  isMobile={false}
                  stepperValue={cycleNumber}
                />
              </StyledGrid>

              <StyledGrid marginTop="100px">
                <Stepper print pillar={data} isMobile={false} />
              </StyledGrid>
            </Page>
            <Page>
              <MoreInfo
                print
                pillarName={data.pillarName}
                pillarColor={data.color as color}
                data={data.moreInfo}
              />
            </Page>
          </>
        ))}
      </StyledGrid>
    </>
  );
};

export default PDF;
