export default {
  root: {
    background: "none",
    borderRadius: "5px",
    padding: "0px",
    "&:hover": {
      background: "none",
      boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.3)"
    }
  }
};
