import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

import { StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import { StyledTile } from "../Stats";
import { UserBadge } from "models/badge";

interface BadgesProps {
  currentUserBadge: UserBadge;
}

const Badges: React.FC<BadgesProps> = ({ currentUserBadge }) => {
  return (
    <StyledTile centerContent container direction="column" item xs={12}>
      <StyledGrid item>
        <Box fontSize={fontSize.large} color={color.GRAPHITE}>
          Badges
        </Box>
      </StyledGrid>
      <StyledGrid item style={{ gap: spacingDefaults.normal }}>
        <img src={currentUserBadge.badge.badgeImageKey} height="50px" />
      </StyledGrid>
      <StyledGrid item>
        <Link
          to="/badges"
          style={{ color: color.DARKBLUE }}
        >{`View All >`}</Link>
      </StyledGrid>
    </StyledTile>
  );
};

export default Badges;
