export const designateSpouseData = {
  title: "Designate your Partner",
  content:
    "Enter your spouse's email to link their Brain Health Account to yours.",
  onSubmitText: "Submit",
  skip: true
};

export const designateSpouseSuccessData = {
  title: "Designate your Partner",
  successText: "Email submitted",
  successIcon: true,
  content:
    "You will recieve an email when your partner’s account is successfuly linked to yours.",
  onSubmitText: "Let's get started",
  divideLine: true,
  contentTwo: "Now it's time to take a look at your first assessment!"
};

export const inviteSpouseData = {
  title: "Invite your Partner",
  content:
    "Enter your partner's email to invite them to participate in The BrainHealth Project™.",
  onSubmitText: "Submit",
  extraSpace: true,
  skip: true
};

export const inviteSpouseSuccessData = {
  title: "Invite your Partner",
  successText: "Invitation sent",
  noButtonProfile: false,
  successIcon: true,
  content:
    "You will recieve an email when your partner’s account is successfuly linked to yours.",
  onSubmitText: "Let's get started",
  divideLine: true,
  contentTwo: "Now it's time to take a look at your first assessment!"
};

export const inviteSpouseWelcomeOneModalData = {
  title: "Welcome",
  image: "WalkInThePark",
  skip: false,
  dotsFirst: true,
  spaceBottom: true,
  contentLarger: "Welcome to The BrainHealth Project™!",
  content:
    "It's time to start your brain health journey. You will complete assessments, receive your BrainHealth Index®, engage in training, and learn brain healthy habits."
};

export const inviteSpouseWelcomeTwoModalData = {
  title: "Welcome",
  image: "WorkTime",
  spaceBottom: true,
  onSubmitText: "Invite Partner",
  skip: false,
  dotsSecond: true,
  contentLarger: "One last step."
};

export const unlinkPartnerData = {
  title: "Unlink Partner",
  contentLarger: "Are you sure you want to unlink?",
  onSubmitText: "Unlink Partner",
  skip: true
};
