import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, CircularProgress, useMediaQuery } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { startOfDay } from "date-fns";
import { useDispatch } from "react-redux";

import { ReactComponent as PinIcon } from "assets/pin-icon.svg";
import { ReactComponent as UnPinIcon } from "assets/unpin-icon.svg";
import {
  addOpacity,
  addBackgroundColorToRoot,
  breakpoints,
  color,
  fontFamily,
  fontSize,
  removeBackgroundColorFromRoot,
  spacingDefaults
} from "style/constants";
import { BackButton, Button, StyledGrid } from "components/simple";
import { CircularProgressBackground } from "../HabitCard";
import { UserHabit } from "models/userHabits";
import routesConfig from "utils/routesConfig";
import {
  MarkUserHabitComplete,
  TogglePinnedHabit
} from "graphql/userHabits/userHabits.gql";
import { GetSubHeaderData } from "graphql/user/user.gql";
import { showErrorNotification } from "state";
import PinningHabitModal from "../PinningHabitModal";

const SelectedHabit: React.FC = () => {
  const history = useHistory();
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);
  const dispatch = useDispatch();

  const { state } = useLocation<{
    habit: UserHabit;
    showHabitSurvey: boolean;
    pinnedHabits: number;
  }>();

  if (!state) history.push(routesConfig.habitSetup.trackYourHabits.path);
  const userHabit = state.habit;
  const displayHabitSurvey = state.showHabitSurvey;
  const isPinned = userHabit.isPinned;

  const [showModel, setShowModel] = useState(false);
  const [habitNotFinishedToday, setHabitNotFinishedToday] = useState<Boolean>(
    !userHabit.lastCompletionDate ||
      (userHabit.lastCompletionDate &&
        new Date(userHabit.lastCompletionDate) < startOfDay(new Date()))
  );

  addBackgroundColorToRoot(color.WHITE);
  useEffect(() => {
    return () => {
      removeBackgroundColorFromRoot();
    };
  }, []);

  const [markHabitAsCompleted] = useMutation(MarkUserHabitComplete, {
    refetchQueries: [{ query: GetSubHeaderData }],
    onCompleted: data => {
      setHabitNotFinishedToday(data?.data?.markUserHabitComplete.success);
    }
  });

  const [togglePinnedHabit] = useMutation(TogglePinnedHabit);

  const handleHabitClick = async () => {
    if (displayHabitSurvey) {
      history.push("/habit-survey", { state: userHabit });
    } else
      try {
        const { data } = await markHabitAsCompleted({
          variables: {
            input: {
              userHabitId: userHabit.id
            }
          }
        });
        history.push("/habit-survey/complete", {
          coinsAwarded: data?.markUserHabitComplete?.coinsAwarded,
          userHabit: userHabit
        });
      } catch (e) {
        dispatch(showErrorNotification("Error", e));
      }
  };

  return (
    <>
      <BackButton location="/track-your-habits" />
      <StyledGrid container centerContent>
        <div
          style={{
            maxWidth: "600px",
            width: "100%",
            padding: `0 ${spacingDefaults.normal}`
          }}
        >
          <Box
            fontSize={fontSize.normal}
            color={color.GRAPHITE}
            margin={`${spacingDefaults.xxlarge} 0`}
          >
            Complete Habit
          </Box>

          <StyledGrid item height="250px">
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)"
              }}
            >
              <CircularProgressBackground
                color="secondary"
                size={"250px"}
                thickness={2}
                value={100}
                variant="determinate"
              />
            </div>
            {userHabit?.maxStreakCount && (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)"
                }}
              >
                <CircularProgress
                  color="secondary"
                  size={"250px"}
                  thickness={2}
                  value={
                    userHabit.streakCount > userHabit.maxStreakCount //circle should show full if max streak is reached or surpassed
                      ? 100
                      : (userHabit.streakCount / userHabit.maxStreakCount) * 100
                  }
                  variant="determinate"
                />
              </div>
            )}
            {userHabit?.habit?.component && userHabit?.streakLevel && (
              <div
                style={{
                  position: "absolute",
                  top: mobile ? "240px" : "255px",
                  left: "50%",
                  transform: "translateX(-50%)"
                }}
              >
                <img
                  src={`https://bhp-images.s3.amazonaws.com/badges/habit_${
                    userHabit.streakLevel +
                    (userHabit.maxStreakCount //userHabit.maxStreakCount can be undefined so we first check its existence
                      ? userHabit.streakCount >= userHabit.maxStreakCount //current users with a count above 14 should see a completed badge
                        ? 0
                        : -1
                      : 0)
                  }.png`}
                  height="200px"
                />
              </div>
            )}
          </StyledGrid>

          <Box
            fontSize={fontSize.normal}
            color={color.GRAPHITE}
            fontWeight="300"
            margin={`${spacingDefaults.normal} 0`}
            fontStyle="italic"
          >
            {`${userHabit?.streakCount}/${userHabit?.maxStreakCount} Complete`}
          </Box>
          <StyledGrid item margin={`${spacingDefaults.large} 0`}>
            <Box fontFamily={fontFamily.secondary} fontSize={fontSize.header}>
              {userHabit.habit?.name}
            </Box>
            <Box
              fontSize={fontSize.normal}
              color={color.GRAPHITE}
              fontWeight="300"
              fontStyle="italic"
            >
              Level {userHabit.streakLevel}
            </Box>
          </StyledGrid>
          <Box
            fontSize={fontSize.normal}
            margin={`${spacingDefaults.normal} 0`}
          >
            {userHabit.habit?.content}
          </Box>
          {userHabit?.habit?.science && (
            <StyledGrid
              container
              direction="column"
              margin={`${spacingDefaults.large} 0`}
              borderRadius="20px"
              padding={spacingDefaults.normal}
              style={{
                backgroundColor: addOpacity(color.LIGHTGRAY, 0.1),
                gap: "1rem"
              }}
            >
              <Box
                fontSize={fontSize.medium}
                color={color.GRAPHITE}
                fontWeight="bold"
              >
                The Science
              </Box>
              <Box fontSize={fontSize.medium} color={color.GRAPHITE}>
                {userHabit?.habit?.science}
              </Box>
            </StyledGrid>
          )}
          {userHabit.streakLevel !== 5 && (
            <Box
              fontSize={fontSize.normal}
              color={color.GRAPHITE}
              fontWeight="300"
              margin={`${spacingDefaults.normal} 0`}
              fontStyle="italic"
            >
              {`Complete this habit ${
                userHabit?.maxStreakCount
              } times to reach level ${userHabit.streakLevel + 1}`}
            </Box>
          )}

          <StyledGrid container direction="column">
            {(!userHabit.lastCompletionDate || habitNotFinishedToday) &&
            userHabit.maxStreakCount //userHabit.maxStreakCount can be undefined so we first check its existence
              ? userHabit.streakCount < userHabit?.maxStreakCount && ( //current users with a count above 14 won't see Mark as Complete
                  <StyledGrid item>
                    <Button onClick={handleHabitClick}>
                      <CheckCircle />
                      <Box marginLeft={spacingDefaults.xsmall}>
                        Mark as Complete
                      </Box>
                    </Button>
                  </StyledGrid>
                )
              : 0}

            <StyledGrid item marginTop={spacingDefaults.large}>
              <Button
                plain
                style={{ height: "auto", textDecoration: "underline" }}
                textColor={color.DARKBLUE}
                onClick={() => setShowModel(true)}
              >
                {isPinned ? "Unpin This Habit" : "Pin This Habit"}
              </Button>
            </StyledGrid>
          </StyledGrid>
        </div>
      </StyledGrid>
      {showModel && (
        <PinningHabitModal
          icon={isPinned ? <UnPinIcon /> : <PinIcon />}
          title={`Are you sure you want to ${
            isPinned ? "unpin" : "pin"
          } this habit?`}
          content={
            isPinned
              ? "This will remove this habit from the top of your habits list."
              : "This habit will appear at the top of your habits list."
          }
          confirmText={`${isPinned ? "Unpin" : "Pin"} Habit`}
          cancelText="Cancel"
          onConfirm={async () => {
            if (userHabit.id) {
              try {
                const response = await togglePinnedHabit({
                  variables: { input: { userHabitId: userHabit.id } }
                });
                if (isPinned) state.pinnedHabits -= 1;
                userHabit.isPinned = !userHabit.isPinned;
              } catch (e: any) {
                dispatch(
                  showErrorNotification("Error", e.graphQLErrors[0].message)
                );
              }
            }

            setShowModel(false);
          }}
          onCancel={() => setShowModel(false)}
        />
      )}
    </>
  );
};
export default SelectedHabit;
