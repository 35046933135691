import { createTheme } from "@material-ui/core/styles";

import MuiInput from "style/theme/overrides/MuiInput";
import MuiInputLabel from "style/theme/overrides/MuiInputLabel";
import { color } from "style/constants";
import globalTheme from "style/theme";

// Date pickers are not part of MaterialUI core and cannot be override
const newTheme = createTheme({
  ...globalTheme,
  overrides: {
    MuiInput: MuiInput,
    MuiInputLabel: MuiInputLabel,
    MuiPickersDay: {
      daySelected: {
        backgroundColor: color.DARKBLUE,
        color: color.WHITE,
        "&:hover": {
          backgroundColor: color.BLUE
        }
      },
      current: {
        color: color.DARKBLUE
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: color.BLUE
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: color.DARKBLUE
      },
      thumb: {
        borderColor: color.DARKBLUE,
        backgroundColor: `${color.BLUE} !important`
      }
    },
    MuiPickersYear: {
      root: {
        "&:hover": {
          backgroundColor: color.MUTEDGRAY
        },
        "&:active": {
          backgroundColor: color.MUTEDGRAY
        }
      },

      yearSelected: {
        backgroundColor: color.DARKBLUE
      }
    }
  }
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
} as any);

export default newTheme;
