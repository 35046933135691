import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";

import { StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import { StyledTile } from "../Stats";
import { GetRanks, GetUserCurrentRank } from "graphql/userRanks/userRanks.gql";
import {
  CurrentRankData,
  Rank as RankType,
  RanksData
} from "graphql/types/UserRanks";

const Rank: React.FC = () => {
  const [currentRank, setCurrentRank] = useState<RankType>();
  const [defaultRankImage, setDefaultRankImage] = useState<string>();

  useQuery<CurrentRankData>(GetUserCurrentRank, {
    onCompleted: data => setCurrentRank(data.currentUserRank?.rank)
  });

  useQuery<RanksData>(GetRanks, {
    onCompleted: data => setDefaultRankImage(data?.ranks[0].rankImageKey)
  });

  return (
    <StyledTile centerContent container direction="column" item xs={12}>
      <StyledGrid item>
        <Box fontSize={fontSize.large} color={color.GRAPHITE}>
          Rank
        </Box>
      </StyledGrid>
      <StyledGrid item style={{ gap: spacingDefaults.normal }}>
        <img
          src={currentRank?.rankImageKey ?? defaultRankImage}
          height="50px"
          style={{
            filter: currentRank?.rankImageKey ? "none" : "grayscale(1)"
          }}
        />
      </StyledGrid>
      <StyledGrid item>
        <Link
          to="/ranks"
          style={{ color: color.DARKBLUE }}
        >{`View All >`}</Link>
      </StyledGrid>
    </StyledTile>
  );
};

export default Rank;
