import React from "react";
import { StyledGrid } from "components/simple";
import { CoachingSession } from "../../CycleSummary/types";
import CoachingSessionSummary from "./Summary";

interface CoachingSessionsProps {
  coachingSessions: CoachingSession[];
}

const CoachingSessions: React.FC<CoachingSessionsProps> = ({
  coachingSessions
}: CoachingSessionsProps) => {
  return (
    <StyledGrid container>
      {coachingSessions
        .sort(
          (a: CoachingSession, b: CoachingSession) =>
            new Date(a.sessionTime).getTime() -
            new Date(b.sessionTime).getTime()
        )
        .map((session, index) => {
          return (
            <StyledGrid key={index} item xs={12}>
              <CoachingSessionSummary
                index={index}
                coachingSession={session}
                acuityConfirmationNumber={session.acuityConfirmationNumber}
              />
            </StyledGrid>
          );
        })}
    </StyledGrid>
  );
};

export default CoachingSessions;
