import React from "react";

import { App } from "./App";
import UnsupportedBrowserWarning from "./components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
import AppStateProvider from "./state";
import "./types";

type Props = {
  user: {
    displayName: string;
    profileImageUrl: string;
    askRecordingConsent: boolean;
    showFirstVideoCallInfo: boolean;
  };
};

export const VideoCall: React.FC<Props> = ({ user }: Props) => (
  <UnsupportedBrowserWarning>
    <AppStateProvider user={user}>
      <App />
    </AppStateProvider>
  </UnsupportedBrowserWarning>
);
