import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Paper } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Ruler, StyledGrid } from "components/simple";
import { color, fontSize, spacingDefaults } from "style/constants";
import BrainGaugeMetric from "./BrainGaugeMetric";
import { BrainGaugeResults } from ".";

interface Props {
  results: BrainGaugeResults[];
}

const BrainGaugeMetrics: React.FC<Props> = ({ results }: Props) => {
  const sortedResults = useMemo(() => {
    // bring "corticalmetric" obj to front of scores array
    results.forEach(item => {
      const first = "corticalmetric"; // A.K.A. Total
      item.scores.sort((x, y) =>
        x.name === first ? -1 : y.name == first ? 1 : 0
      );
    });
    return (
      results.sort((a, b) =>
        b.completionDateTime.localeCompare(a.completionDateTime)
      ) || []
    );
  }, [results]);

  // pagination
  const PAGE_LIMIT = 4;
  const [numOfPages, setNumOfPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [indexOfFirstItem, setIndexOfFirstItem] = useState<number>(0);
  const [indexOfLastItem, setIndexOfLastItem] = useState<number>(0);
  useEffect(() => {
    setNumOfPages(Math.ceil(sortedResults?.length / PAGE_LIMIT));
    setIndexOfLastItem(currentPage * PAGE_LIMIT);
    setIndexOfFirstItem(currentPage * PAGE_LIMIT - PAGE_LIMIT);
  }, [sortedResults]);

  const paginateIncrease = () => {
    // set current page
    setCurrentPage(currentPage + 1);
    // modify first and last indices
    setIndexOfLastItem((currentPage + 1) * PAGE_LIMIT);
    setIndexOfFirstItem((currentPage + 1) * PAGE_LIMIT - PAGE_LIMIT);
  };

  const paginateDecrease = () => {
    // set current page
    setCurrentPage(currentPage - 1);
    // modify first and last indices
    setIndexOfLastItem((currentPage - 1) * PAGE_LIMIT);
    setIndexOfFirstItem((currentPage - 1) * PAGE_LIMIT - PAGE_LIMIT);
  };

  if (!results || !results.length)
    return (
      <Paper>
        <StyledGrid textAlign={"center"} padding={spacingDefaults.medium}>
          <p>No Brain Gauge Results!</p>
        </StyledGrid>
      </Paper>
    );

  return (
    <StyledGrid container padding={spacingDefaults.normal}>
      <StyledGrid container justifyContent="center" alignItems="center">
        <StyledGrid item>
          <Box
            color={color.BLACK}
            fontSize={fontSize.large}
            fontWeight="normal"
          >
            Assessment Results
          </Box>
        </StyledGrid>
      </StyledGrid>
      <StyledGrid
        container
        justifyContent="space-between"
        textAlign="center"
        alignItems="center"
        margin="30px 0"
        padding="0 20px"
        backgroundColor={color.GRAY}
      >
        <StyledGrid md={1} xs={1}></StyledGrid>
        {sortedResults[0]?.scores.map((item, idx: number) => (
          <StyledGrid item key={idx} padding="5px 0" md={1} xs={1}>
            {item?.name === "corticalmetric" ? "Total" : item?.name}
          </StyledGrid>
        ))}
      </StyledGrid>
      {sortedResults
        .slice(indexOfFirstItem, indexOfLastItem)
        .map((item, idx: number) => (
          <>
            {idx !== 0 && <Ruler margins={spacingDefaults.xsmall} />}
            <BrainGaugeMetric {...item} />
          </>
        ))}
      <StyledGrid container justifyContent="space-between" alignItems="center">
        <StyledGrid item>
          <StyledGrid container alignItems="center">
            <StyledGrid item>
              <IconButton
                aria-label="Previous"
                color="secondary"
                onClick={paginateDecrease}
                disabled={currentPage === 1}
              >
                <ChevronLeftIcon />
              </IconButton>
            </StyledGrid>
            <StyledGrid item>
              <Box
                color={color.BLACK}
                fontSize={fontSize.normal}
                fontWeight="normal"
              >
                More Recent Results
              </Box>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
        <StyledGrid item>
          <StyledGrid container alignItems="center">
            <StyledGrid item>
              <Box
                color={color.BLACK}
                fontSize={fontSize.normal}
                fontWeight="normal"
              >
                Older Results
              </Box>
            </StyledGrid>
            <StyledGrid item>
              <IconButton
                aria-label="Next"
                color="secondary"
                onClick={paginateIncrease}
                disabled={currentPage === numOfPages}
              >
                <ChevronRightIcon />
              </IconButton>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default BrainGaugeMetrics;
