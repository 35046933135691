import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from "@material-ui/core";
import { getUser, getUserStatus, UserStatus } from "state";

import { appWidth, breakpoints, color, spacingDefaults } from "style/constants";
import { BackButton, Loading, StyledGrid } from "components/simple";
import { DashboardQuery } from "graphql/user/user.gql";
import { Dashboard } from "graphql/types/Dashboard";
import AssessmentProgress from "./AssessmentProgress";
import OverallProgress from "./OverallProgress";
import { UserAssessmentCycleStatus } from "graphql/types/Cycle";

const AssessmentOverview: React.FC = () => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  const user = useSelector(getUser);
  const userStatus = useSelector(getUserStatus);

  const [dashboardData, setDashboardData] = useState<Dashboard>();

  const shouldDisable = userStatus !== UserStatus.QUALIFIED_AND_CONSENTED;

  const { loading } = useQuery(DashboardQuery, {
    fetchPolicy: "no-cache",
    onCompleted: data => setDashboardData(data?.me)
  });

  const cycleStatus = dashboardData?.currentCycle?.userAssessmentCycle
    ?.status as UserAssessmentCycleStatus;

  if (loading) return <Loading />;

  if (!dashboardData || !user) return null;

  return (
    <div
      style={{
        minHeight: mobile ? "calc(100vh - 187px)" : "calc(100vh - 146px)",
        backgroundColor: color.WHITE
      }}
    >
      <StyledGrid
        container
        justifyContent="center"
        backgroundColor={color.WHITE}
      >
        <BackButton location="/dashboard" />
        <StyledGrid
          alignSelf="center"
          container
          margin={`${spacingDefaults.normal} 0`}
          maxWidth={appWidth.max}
          padding={spacingDefaults.medium}
          spacing={2}
        >
          <StyledGrid item xs={12}>
            <OverallProgress
              cycleStatus={cycleStatus}
              daysToComplete={
                dashboardData.currentCycle?.userAssessmentCycle
                  ?.daysToComplete || 1
              }
              daysRemaining={dashboardData.assessmentsDaysLeft || 0}
              assessmentsRemaining={dashboardData.assessmentsLeft || 0}
              disabled={shouldDisable}
            />
          </StyledGrid>
          <StyledGrid item xs={12}>
            <AssessmentProgress
              userAssessments={
                dashboardData?.currentCycle?.userAssessmentCycle
                  ?.userAssessments as any
              }
              userSurveys={
                dashboardData?.currentCycle?.userSurveyCycle?.userSurveys
              }
              disabled={shouldDisable}
            />
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </div>
  );
};
export default AssessmentOverview;
