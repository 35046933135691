import React from "react";
import { Divider, useMediaQuery } from "@material-ui/core";

import { StyledGrid } from "components/simple";
import { breakpoints, color, spacingDefaults } from "style/constants";
import PillarDonutGraph from "./PillarDonutGraph";
import PillarScore from "./PillarScore";
import ScoreHistory from "./ScoreHistory";
import { Cycle, Pillar } from "../../types";

interface OverviewProps {
  currentScore: number;
  cycles: Cycle[];
  pillars: Pillar[];
  selectedCycleIndex: number;
}

const Overview: React.FC<OverviewProps> = ({
  currentScore,
  cycles,
  pillars,
  selectedCycleIndex
}: OverviewProps) => {
  const mobile = useMediaQuery(`(max-width:${breakpoints.lg}px)`);

  return (
    <StyledGrid container>
      <StyledGrid container item justifyContent="center" lg xs={12}>
        <ScoreHistory
          allCycles={cycles}
          currentScore={currentScore}
          selectedCycle={selectedCycleIndex}
        />
      </StyledGrid>

      {!mobile && (
        <StyledGrid
          item
          height="337px" // 100% not working. had to hard-code
          margin="auto 3.5rem"
        >
          <Divider orientation="vertical" style={{ color: color.MUTEDGRAY }} />
        </StyledGrid>
      )}

      <StyledGrid
        alignContent="center"
        container
        item
        justifyContent="center"
        lg
        xs={12}
      >
        <PillarDonutGraph pillars={pillars} />

        <StyledGrid container item justifyContent="space-evenly">
          {pillars.map((pillar, index) => (
            <StyledGrid
              item
              key={index}
              lg={3}
              marginTop={mobile ? spacingDefaults.normal : undefined}
              xs={6}
            >
              <PillarScore {...pillar} key={index} />
            </StyledGrid>
          ))}
        </StyledGrid>
      </StyledGrid>
    </StyledGrid>
  );
};

export default Overview;
