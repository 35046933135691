import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";

import { ReactComponent as Tree } from "assets/bhi-tree.svg";
import { ChartContainer, ChartContainerWrapper } from "./styled";
import Radar from "./radar";
import { PillarName } from "components/pages/Index/CycleSummary/types";

export enum BHIChartType {
  Tree,
  Radar
}
export interface BHIChartProps {
  scores: number[];
  labels: string[];
  origin?: Origin;
  selectedFactor?: PillarName;
}

export interface ChartProps extends BHIChartProps {
  chartType: BHIChartType;
  stepIndex: number;
  setChartType: Dispatch<SetStateAction<BHIChartType>>;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
}

interface Origin {
  x: number;
  y: number;
}

const animationDuration = 0.2;

export const scaleFactorScores = (factors: number[]): number[] => {
  const minimumScale = 0.25639;
  const maximumScale = 1;
  const range = maximumScale - minimumScale;
  const maxScore = 900;
  const scaledFactors = factors.map(factor => {
    return (factor / maxScore) * range + minimumScale;
  });
  return scaledFactors;
};

const BHIChart: React.FC<ChartProps> = ({
  scores,
  selectedFactor,
  labels,
  chartType
}) => {
  const scaledFactors = scaleFactorScores(scores);
  const [emotionalBalance, connectedness, clarity] = scaledFactors;
  const [rotate, setRotate] = useState(false);
  const [scaleIn, setScaleIn] = useState(false);
  const [origin, setOrigin] = useState<Origin>({ x: 0, y: 0 });
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      setOrigin({
        x: ref.current.getBoundingClientRect().width / 2,
        y: ref.current.getBoundingClientRect().height / 2
      });
    }
  }, [ref]);

  useEffect(() => {
    setScaleIn(false);
    setTimeout(() => {
      setScaleIn(true);
    }, 1);
  }, [chartType, scores]);

  const displayChartType = (type: BHIChartType) => {
    switch (type) {
      case BHIChartType.Radar:
        return (
          <Radar
            labels={labels}
            origin={origin}
            scores={scaledFactors}
            selectedFactor={selectedFactor}
          />
        );
      case BHIChartType.Tree:
        return <Tree className="bhi-tree" />;
    }
  };

  const rotateClass = rotate && "rotateIn";
  const scaleInClass = scaleIn && "scaleIn";

  return (
    <ChartContainerWrapper>
      <ChartContainer
        animationDuration={animationDuration}
        clarity={clarity}
        className={`${rotateClass} ${scaleInClass}`}
        emotionalBalance={emotionalBalance}
        connectedness={connectedness}
        ref={ref}
        type={chartType}
      >
        {displayChartType(chartType)}
      </ChartContainer>
    </ChartContainerWrapper>
  );
};

export default BHIChart;
