import React from "react";
import { Grid, Hidden } from "@material-ui/core";

import Logo from "assets/dietid-logo.png";
import { StyledText, StyledHeader } from "../styled";
import { BackButton } from "components/simple";

const Begin: React.FC = () => {
  return (
    <>
      <BackButton location="/dashboard" />
      <Hidden mdUp>
        <Grid style={{ height: "3.5rem" }} />
      </Hidden>
      <Grid item>
        <img alt="DietID Logo" height={139} src={Logo} width={366} />
      </Grid>
      <Grid item>
        <StyledHeader>What is Diet ID?</StyledHeader>
        <Hidden smDown>
          <Grid
            item
            style={{
              width: "620px",
              padding: "0 1rem",
              marginBottom: "2rem"
            }}
          >
            <StyledText>
              DietD is a digital toolkit that assesses and supports healthy
              eating. Diet ID derives its diet quality scores from a validated
              tool, the Healthy Eating Index. During the assessment, you will
              see a series of images of different diets, and you will narrow
              down your options in just a few minutes to the one that best
              depicts your current diet. Then, a personalized route of daily
              nutrition and health challenges is designed for you to follow over
              the next 6 months. <br />
              <br />
              <a href={"https://www.dietid.com/"}>
                <u>For more information about DietID, click here.</u>
              </a>
            </StyledText>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item style={{ padding: "0 1rem" }}>
            <StyledText>
              DietD is a digital toolkit that assesses and supports healthy
              eating. Diet ID derives its diet quality scores from a validated
              tool, the Healthy Eating Index. During the assessment, you will
              see a series of images of different diets, and you will narrow
              down your options in just a few minutes to the one that best
              depicts your current diet. Then, a personalized route of daily
              nutrition and health challenges is designed for you to follow over
              the next 6 months. <br />
              <br />
              <a href={"https://www.dietid.com/"}>
                <u>For more information about DietID, click here.</u>
              </a>
            </StyledText>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default Begin;
